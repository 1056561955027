




import { defineComponent, PropType, ref, watchEffect } from '@vue/composition-api'
import hljs from 'highlight.js/lib/core'

export default defineComponent({
  name: 'AppTextHighlight',
  props: {
    text: {
      type: String,
      default: ''
    },
    positiveWords: {
      type: Array as PropType<string[]>,
      default: () => []
    },
    negativeWords: {
      type: Array as PropType<string[]>,
      default: () => []
    }
  },
  setup(props) {
    const root = ref<HTMLElement | null>(null)
    const html = ref<string>('')

    watchEffect(() => {
      if (!root.value) return

      const contains = [
        ...props.positiveWords.map(word => ({ className: 'positive', begin: word })),
        ...props.negativeWords.map(word => ({ className: 'negative', begin: word }))
      ]

      hljs.registerLanguage('atelu', () => ({ contains }))

      const result = hljs.highlight(props.text, { language: 'atelu' })

      html.value = result.value
    })

    return {
      root,
      html
    }
  }
})
