/* @flow */
import { mapActions, mapState } from 'vuex'

import { TrackingService } from '@/client/services'

export default {
  created() {
    this.prizeId = this.params.prizeId
    this.postType = this.params.postType
    this.isFollowing = this.params.isFollowing
    this.isCandidate = this.params.isCandidate
    this.isWinner = this.params.isWinner
    this.isWebInstantwinWinner = this.params.isWebInstantwinWinner
    this.isMedia = this.params.isMedia
    this.isWidget = this.params.isWidget
    this.accountName = this.params.accountName
  },
  data() {
    return {
      prizeId: '',
      postType: '',
      isFollowing: '',
      isCandidate: '',
      isWinner: '',
      isWebInstantwinWinner: '',
      isMedia: '',
      isWidget: '',
      accountName: '',
      typeOptions: [
        { value: '', label: '指定なし' },
        { value: 'tweet', label: 'ポスト' },
        { value: 'retweet', label: '公式リポスト' },
        { value: 'quote_tweet', label: '引用' },
        { value: 'mention', label: 'メンション' },
        { value: 'reply', label: '返信' }
      ],
      isFollowerOptions: [
        { value: '', label: '指定なし' },
        { value: 1, label: 'フォローあり' },
        { value: 0, label: 'フォローなし' }
      ],
      isMediaOptions: [
        { value: '', label: '指定なし' },
        { value: 1, label: 'メディアあり' },
        { value: 0, label: 'メディアなし' }
      ],
      isCandidateOptions: [
        { value: '', label: '指定なし' },
        { value: 1, label: '候補者フラグあり' },
        { value: 0, label: '候補者フラグなし' }
      ],
      isWidgetOptions: [
        { value: '', label: '指定なし' },
        { value: 1, label: 'ウイジェット表示あり' },
        { value: 0, label: 'ウイジェット表示なし' }
      ],
      isWinnerOptions: [
        { value: '', label: '指定なし' },
        { value: 1, label: '当選フラグあり' },
        { value: 0, label: '当選フラグなし' }
      ],
      isWebInstantwinOptions: [
        { value: '', label: '指定なし' },
        { value: 1, label: 'WEB抽選で当選' },
        { value: 0, label: 'WEB抽選で落選' }
      ]
    }
  },
  computed: {
    ...mapState('twitterPosts', {
      prizes: state => state.prizes,
      params: state => state.params,
      campaignType: state => state.campaign?.type,
      loading: state => state.loading
    }),

    prizeOptions() {
      return [{ id: '', name: '指定なし' }, ...this.prizes]
    }
  },
  methods: {
    ...mapActions('twitterPosts', ['setParams']),

    onChangeFilter(value: string) {
      switch (value) {
        case 'prizeId':
          TrackingService.sendEvent('select:応募履歴（X）|応募グループ')
          break
        case 'postType':
          TrackingService.sendEvent('select:応募履歴（X）|ポスト種別')
          break
        case 'isFollowing':
          TrackingService.sendEvent('select:応募履歴（X）|フォロー')
          break
        case 'isCandidate':
          TrackingService.sendEvent('select:応募履歴（X）|候補者')
          break
        case 'isWinner':
          TrackingService.sendEvent('select:応募履歴（X）|当選者')
          break
        case 'isWebInstantwinWinner':
          TrackingService.sendEvent('select:応募履歴（X）|Web当選者')
          break
        case 'isMedia':
          TrackingService.sendEvent('select:応募履歴（X）|メディア')
          break
        case 'isWidget':
          TrackingService.sendEvent('select:応募履歴（X）|ウィジェット表示')
          break
        case 'accountName':
          TrackingService.sendEvent('select:応募履歴（X）|アカウント名')
          break
        default:
          break
      }
    },

    async changeFilter() {
      TrackingService.sendEvent('click:応募履歴（X）|検索')

      await this.setParams({
        prizeId: this.prizeId,
        postType: this.postType,
        isFollowing: this.isFollowing,
        isCandidate: this.isCandidate,
        isWebInstantwinWinner: this.isWebInstantwinWinner,
        isWinner: this.isWinner,
        isMedia: this.isMedia,
        isWidget: this.isWidget,
        accountName: this.accountName
      })
    }
  }
}
