/* @flow */
import Util from '@/client/core/util'
export default {
  props: {
    entries: Array,
    sortBy: String
  },
  methods: {
    /**
     * ヘッダー描画
     * @param {*} createElement
     * @param {*} { column }
     */
    renderHeader(createElement: any, { column }: any) {
      return Util.renderHeader(createElement, column.label, Util.getSortClass(this.sortBy, column.sortBy))
    },

    /**
     * ヘッダーをクリックするとソートする
     * @param {*} column
     */
    headerClick(column: any) {
      if (!column.sortBy) {
        return
      }
      let sortBy = column.sortBy

      if (column.sortBy.indexOf('-') === 0) {
        if (this.sortBy === column.sortBy) {
          sortBy = column.sortBy.substring(1)
          this.$emit('sorting', { sortBy })
        } else {
          sortBy = column.sortBy
          this.$emit('sorting', { sortBy })
        }
      } else {
        if (this.sortBy === column.sortBy) {
          sortBy = '-' + column.sortBy
          this.$emit('sorting', { sortBy })
        } else {
          this.$emit('sorting', { sortBy })
        }
      }
    }
  }
}
