




























































import { isValidHashtag } from 'twitter-text'
import { Component, Emit, Vue } from 'vue-property-decorator'
import { mapActions, mapState } from 'vuex'

import Message from '@/client/components/_atoms/Message.vue'
import Panel from '@/client/components/_atoms/Panel.vue'
import Tips from '@/client/components/_atoms/Tips.vue'
import Tags from '@/client/components/_molecules/Tags.vue'
import { CAMPAIGN_STATUS, INSTAGRAM_ENTRY_ACTION_TYPES } from '@/client/constant'
import { TrackingService } from '@/client/services'
import { CampaignDialogActions, CampaignDialogState, CampaignForm } from '@/client/store/campaign_dialog.module'

@Component({
  components: {
    Message,
    Panel,
    Tips,
    Tags
  },
  computed: mapState('campaign_dialog', ['form', 'api_campaign', 'mode']),
  methods: mapActions('campaign_dialog', ['setForm'])
})
export default class CampaignCreateInstagramCondition extends Vue {
  api_campaign!: CampaignDialogState['api_campaign']
  form!: CampaignDialogState['form']
  mode!: CampaignDialogState['mode']
  setForm!: CampaignDialogActions['setForm']

  get campaign_type() {
    return this.form.entry_action_type
  }

  get keywords() {
    return this.form.keywords
  }

  get campaign_type_options() {
    return INSTAGRAM_ENTRY_ACTION_TYPES
  }

  get is_mention_type() {
    return this.form.entry_action_type === 'mention'
  }

  get is_condition_disabled() {
    return (
      this.api_campaign &&
      [CAMPAIGN_STATUS.DURING_FINAL_COLLECTION, CAMPAIGN_STATUS.COLLECTION_COMPLETED].includes(
        this.api_campaign.status
      ) &&
      this.mode === 'edit'
    )
  }

  @Emit('cancel')
  cancel(payload) {
    return payload
  }

  created() {
    if (!INSTAGRAM_ENTRY_ACTION_TYPES.map(v => v.value).includes(this.form.entry_action_type)) {
      this.changeCampaignType('mention')
    }
  }

  /**
   * キャンペーンタイプを変更
   */
  async changeCampaignType(value: CampaignForm['entry_action_type']) {
    TrackingService.sendEvent('select:キャンペーンの新規作成|応募条件(IG)|キャンペーンタイプ')

    const form = {
      ...this.form,
      entry_action_type: value,
      campaign_type: 'keyword'
    }

    await this.setForm(form)
  }

  /**
   * キーワードを変更
   */
  async changeKeywords(value: string[]) {
    if (this.form.keywords?.length < value.length) {
      TrackingService.sendEvent('click:キャンペーンの新規作成|応募条件(IG)|全てを含む|追加')
    } else TrackingService.sendEvent('click:キャンペーンの新規作成|応募条件(IG)|全てを含む|削除')

    const is_valid = this.checkoutValidKeyword(value)

    if (!is_valid) {
      return
    }

    const form = {
      ...this.form,
      keywords: value
    }

    await this.setForm(form)
  }

  /**
   * キーワードをチェック
   */
  checkoutValidKeyword(keywords: string[]) {
    const is_valid = keywords.every(keyword => isValidHashtag(keyword))

    if (!is_valid) {
      this.$notify({
        title: 'ハッシュタグに利用できない文字列が含まれています。',
        message: '',
        customClass: 'danger'
      })
    }

    return is_valid
  }
}
