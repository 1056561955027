/* @flow */

class TwitterAuth {
  /**
   * Twitter認証を行う
   * @return {any} Twitter認証情報
   */
  static execAuth(): Promise<any> {
    const authWindow = window.open('/api/oauth/twitter', 'twitter_oauth', 'width=600, height=500')

    return new Promise(resolve => {
      window.onAuth = (auth: any) => {
        try {
          const authData = JSON.parse(JSON.stringify(auth))
          delete window.onAuth
          if (authData && authData.id) {
            return resolve(authData)
          }
          resolve(null)
        } catch (err) {
          resolve(null)
        } finally {
          authWindow.close()
        }
      }
    })
  }

  /**
   * Enterpriseアプリ認証を行う
   */
  static execEnterpriseAuth(accountId: string): Promise<any> {
    const authWindow = window.open(
      '/api/oauth/twitter_enterprise?id=' + accountId,
      'twitter_enterprise_oauth',
      'width=600, height=500'
    )

    return new Promise(resolve => {
      window.onAuth = (auth: any) => {
        try {
          const authData = JSON.parse(JSON.stringify(auth))
          delete window.onAuth
          if (authData && authData.id) {
            return resolve(authData)
          }
          resolve(null)
        } catch (err) {
          resolve(null)
        } finally {
          authWindow.close()
        }
      }
    })
  }
}

export default TwitterAuth
