











































import { Component, Emit, Vue } from 'vue-property-decorator'
import { mapActions, mapState } from 'vuex'

import Icon from '@/client/components/_atoms/Icon.vue'
import Message from '@/client/components/_atoms/Message.vue'
import Panel from '@/client/components/_atoms/Panel.vue'
import Tips from '@/client/components/_atoms/Tips.vue'
import { CampaignDialogActions, CampaignDialogState } from '@/client/store/campaign_dialog.module'

@Component({
  components: {
    Icon,
    Message,
    Panel,
    Tips
  },
  computed: mapState('campaign_dialog', ['campaign_id', 'form']),
  methods: mapActions('campaign_dialog', ['reset'])
})
export default class CampaignCreateCompleted extends Vue {
  campaign_id!: CampaignDialogState['campaign_id']
  form!: CampaignDialogState['form']
  reset!: CampaignDialogActions['reset']

  /**
   * 応募グループ画面へ
   */
  goToApplicants() {
    const target_sns_maps = {
      twitter: 'twitter_campaigns_prizes',
      instagram: 'instagram_campaigns_prizes',
      tiktok: 'tiktok_campaigns_prizes'
    }

    this.$router.push({
      name: target_sns_maps[this.form.target_sns],
      params: {
        campaignId: String(this.campaign_id)
      }
    })

    this.reset()
  }

  @Emit('close')
  close(payload) {
    return payload
  }
}
