/* @flow */
import api from '@/client/core/api'
import { type TCampaign } from '@/typings/api/campaigns.type'
import { type TGetPrizesParams, type TPrize } from '@/typings/api/twitter_campaign_prizes.type'

// mutations type
const RESET = 'RESET'
const SET_PRIZE_DATA = 'SET_PRIZE_DATA'
const SET_CAMPAIGN = 'SET_CAMPAIGN'
const SET_FIRST_LOADED = 'SET_FIRST_LOADED'
const SET_ERROR = 'SET_ERROR'
const SET_PARAMS = 'SET_PARAMS'

// 応募者ストア
type PrizeStore = {
  firstLoaded: boolean,
  error: boolean,
  total: number,
  prizes: TPrize[],
  params: TGetPrizesParams,
  campaign: TCampaign | null
}
// ストアーcontext
type Context = {
  commit: Function,
  getters: any,
  state: PrizeStore,
  dispatch: Function
}

// init state
const initState: PrizeStore = {
  firstLoaded: false,
  error: false,
  campaign: null,
  prizes: [],
  total: 0,
  params: {
    campaignId: 0,
    sortBy: '-applicantCount',
    pagingNo: 1,
    limitCount: 25,
    forCsv: 0
  }
}
const state = Object.assign({}, initState)

// getters
const getters = {}

// actions
const actions = {
  /**
   * ストアをリセット
   * @param {Context} context
   */
  async reset(context: Context) {
    context.commit(RESET)
  },

  /**
   * 応募グループ一覧を取得
   * @param {Context} context
   */
  async fetchPrizes(context: Context) {
    context.commit(SET_ERROR, false)
    // システムストアのローディングをセット
    context.dispatch('system/setLoading', true, { root: true })
    const result = await api.get('/twitter_campaign_prizes', {
      params: context.state.params
    })

    // システムストアのローディングをセット
    context.dispatch('system/setLoading', false, { root: true })
    context.commit(SET_FIRST_LOADED, true)
    // データがない場合はエラーフラグをセット
    if (!result.data) {
      context.commit(SET_ERROR, true)
      return
    }

    // 応募グループ一覧をセット
    context.commit(SET_PRIZE_DATA, {
      prizes: result.data.prizes,
      total: result.data.total
    })
  },

  /**
   * キャンペーンデータを取得
   * @param {Context} context
   */
  async fetchCampaign(context: Context) {
    const result = await api.get('/campaigns/' + context.state.params.campaignId)
    if (result.data) {
      context.commit(SET_CAMPAIGN, result.data)
    }
  },

  /**
   * パラメーターをセット
   * @param {Context} context
   * @param {TGetPrizesParams} params パラメーター
   */
  setParams(context: Context, params: TGetPrizesParams) {
    if (!params.pagingNo) {
      params.pagingNo = 1
    }
    context.commit(SET_PARAMS, Object.assign({}, context.state.params, params))
  }
}

// mutations
const mutations = {
  /**
   * ストアをリセット
   * @param {PrizeStore} state
   */
  [RESET](state: PrizeStore) {
    const initStateData: any = initState
    Object.keys(initStateData).forEach(key => {
      state[key] = initStateData[key]
    })
  },

  /**
   * 応募グループデータをセット
   * @param {PrizeStore} state
   * @param {TPrize[]} prizeData 応募者一覧
   */
  [SET_PRIZE_DATA](state: PrizeStore, prizeData: { total: number, prizes: TPrize[] }) {
    state.prizes = prizeData.prizes
    state.total = prizeData.total
  },

  /**
   * 応募グループデータをセット
   * @param {PrizeStore} state
   * @param {TCampaign} campaign キャンペーンデータ
   */
  [SET_CAMPAIGN](state: PrizeStore, campaign: TCampaign) {
    state.campaign = campaign
  },

  /**
   * 応募グループパラメーターをセット
   * @param {PrizeStore} state
   * @param {TPrizeParams} params パラメーター
   */
  [SET_PARAMS](state: PrizeStore, params: TGetPrizesParams) {
    state.params = params
  },

  /**
   * 初回ロード終了フラグをセット
   * @param {PrizeStore} state
   * @param {boolean} loaded 初回ロード終了フラグ
   */
  [SET_FIRST_LOADED](state: PrizeStore, loaded: boolean) {
    state.firstLoaded = loaded
  },

  /**
   * エラーフラグをセット
   * @param {PrizeStore} state
   * @param {boolean} error エラーフラグ
   */
  [SET_ERROR](state: PrizeStore, error: boolean) {
    state.error = error
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
