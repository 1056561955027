/* @flow */
import moment from 'moment'
import twitterText from 'twitter-text'
import { required } from 'vuelidate/lib/validators'
import { mapActions, mapState } from 'vuex'

import Message from '@/client/components/_atoms/Message.vue'
import Editable from '@/client/components/basic/editable'
import MessageConvert from '@/client/components/basic/message_convert'
import api from '@/client/core/api'
import TwitterUpload from '@/client/core/twitter_upload'
import Util from '@/client/core/util'
import { TrackingService } from '@/client/services'

export default {
  components: {
    Editable,
    MessageConvert,
    Message
  },
  props: {
    messageQueueId: Number,
    messageQueues: Array,
    messageTemplates: Array,
    successMessageQueueEdit: Function,
    isChangeWithoutSubmit: Boolean,
    isCopy: Boolean
  },
  data() {
    return {
      types: [
        {
          label: this.$gettext('当選通知'),
          value: 0,
          text: this.$gettext(' 一度も当選メッセージを配信していない未回答の当選者全員')
        },
        {
          label: this.$gettext('リマインド'),
          value: 2,
          text: this.$gettext('当選通知を配信したことのある、未回答の当選者全員')
        },
        {
          label: this.$gettext('シリアルコード未使用のリマインド'),
          value: 3,
          text: this.$gettext(
            'シリアルコードが未使用の当選者全員(使用済みシリアルコードをシリアルコードの管理にインポートできます。)'
          )
        }
      ],
      type: null,
      sentAccountFilterType: 0,
      answeredAccountFilterType: 1,
      body: '',
      sendType: 'DM',
      sendLimitPerDay: 0,
      startDate: null,
      startDateOption: {
        disabledDate(time: any) {
          return moment(time.getTime()).format('YYYY-MM-DD') < moment().format('YYYY-MM-DD')
        }
      },
      startTime: null,
      startTimeOption: {
        start: '00:00',
        step: '00:30',
        end: '23:30'
      },
      endTime: null,
      endTimeOption: {
        start: '00:00',
        step: '00:30',
        end: '23:30'
      },
      isDisabledInput: false,
      isSubmit: false,
      oldTemplate: '',
      template: '',
      media: null,
      isLoadMedia: false,
      uploadTimer: null,
      remainMonthlyDmCount: 0,
      remainMonthlyDmCountDefault: 0,
      maxMonthlyDmCount: 0,
      sendCount: 0,
      isSendCountLoading: false,
      rawData: {}
    }
  },
  beforeDestroy() {
    if (this.uploadTimer) {
      clearInterval(this.uploadTimer)
    }
  },
  created() {
    this.reset()

    if (this.messageQueueId === 0) {
      this.rawData = this.getMessageData()
      return
    }

    let current = null

    this.messageQueues.forEach(messageQueue => {
      if (messageQueue.id === this.messageQueueId) {
        current = Object.assign({}, messageQueue)
      }
    })

    if (current === null) {
      this.rawData = this.getMessageData()
      return
    }

    const { status, type, sendType, body, actionDatetime, media, endTime, sendLimitPerDay } = current

    this.isDisabledInput = this.isEdit && ['sent', 'sending', 'paused'].includes(status)
    this.type = type
    this.sendType = sendType
    this.body = body
    this.sendLimitPerDay = sendLimitPerDay
    this.startDate = moment(actionDatetime).format('YYYY-MM-DD')
    this.startTime = moment(actionDatetime).format('HH:mm')
    this.endTime = endTime ? moment(endTime, 'HH:mm:ss').format('HH:mm') : null

    this.media = media ? Object.assign({}, media) : null

    if (!this.isDisabledInput) {
      this.onChangeType()
    }

    if (this.type !== null) {
      this.getSendCount()
    }

    this.rawData = this.getMessageData()
    this.$nextTick(() => {
      this.$emit('update:isChangeWithoutSubmit', false)
    })

    if (this.isCopy) {
      this.startDate = null
      this.startTime = null
      this.endTime = null
    }
  },

  watch: {
    isFormChanged(value: boolean) {
      this.$emit('update:isChangeWithoutSubmit', value)
    },

    startDate(value: string) {
      if (value) {
        this.updateRemainMonthlyDmCount()
      }
    },

    maxSendLimitPerDay(value: number) {
      if (value < this.sendLimitPerDay) this.sendLimitPerDay = 0
    }
  },

  computed: {
    ...mapState('twitterDirectMessageDialog', {
      campaignId: state => state.campaignId,
      prizeId: state => state.prizeId
    }),
    ...mapState('twitterSerialCodeDialog', {
      serialCode: state => state.serialCode
    }),
    ...mapState('twitterApplicant', {
      verifiedType: state => {
        return state.campaign.account.verifiedType
      },
      isVerifiedAccount: state => {
        return state.campaign.account.isVerified
      },
      campaign: state => state.campaign
    }),
    /**
     * ハイライト定義データ
     */
    highlights() {
      return [{ type: 'info', keywords: ['_account_name_', '_screen_name_', '_account_id_', '_serial_code_'] }]
    },

    /**
     * ヘルプテキスト取得
     */
    typeText() {
      let text = null
      this.types.forEach(type => {
        if (type.value === this.type) text = type.text
      })
      return text
    },

    /**
     * メッセージ内容の文字数取得
     */
    bodyLength() {
      if (!this.body) {
        return 0
      }

      return twitterText.parseTweet(this.body).weightedLength
    },

    hasSerialCodeInMessageBody() {
      return /_serial_code_/.test(this.body)
    },

    /**
     * 新規作成か編集かのチェック
     */
    isEdit() {
      return this.messageQueueId > 0 && !this.isCopy
    },

    disabledSubmit() {
      if (
        !this.startDate ||
        !this.startTime ||
        this.body === '' ||
        this.type === null ||
        this.bodyLength > this.maxLength ||
        this.body.trim().length === 0 ||
        this.isLoadMedia
      ) {
        return true
      }

      if (this.isDateInput || this.isStartTimeInput || this.isEndTimeInput || this.isTimeOver || this.isDatetimeOver) {
        return true
      }

      return false
    },

    isImage() {
      const reg = /[.](jpeg|png|gif|pjpeg|jpg)$/i
      return reg.test(this.media.mediaUrl)
    },

    isTypeSelected() {
      return this.type !== null
    },

    isSendCountEmpty() {
      return this.sendCount === 0
    },

    isLimitSendCount() {
      return this.remainMonthlyDmCount !== null
    },

    isRemainEmpty() {
      return this.remainMonthlyDmCount === 0
    },

    isSendCountOver() {
      if (this.remainMonthlyDmCount === null) return false
      return this.sendCount > this.remainMonthlyDmCount
    },

    isDateInput() {
      if (!this.startDate) {
        return true
      }

      return false
    },

    isDatetimeOver() {
      const datetime = moment(`${this.startDate} ${this.startTime}`, 'YYYY-MM-DD HH:mm')
      const limitDatetime = moment().add(5, 'minute')

      if (datetime.unix() < limitDatetime.unix()) {
        return true
      }

      return false
    },

    isStartTimeInput() {
      if (!this.startTime) {
        return true
      }

      return false
    },

    isEndTimeInput() {
      if (this.startTime && !this.endTime) {
        return true
      }

      return false
    },

    isTimeOver() {
      if (this.startTime && this.endTime && this.startTime >= this.endTime) {
        return true
      }

      return false
    },

    messageData() {
      return this.getMessageData()
    },

    isFormChanged() {
      return JSON.stringify(this.messageData) !== JSON.stringify(this.rawData)
    },

    maxSendLimitPerDay() {
      if (this.startTime && this.endTime) {
        const startTime = moment(this.startTime, 'HH:mm')
        const endTime = moment(this.endTime, 'HH:mm')

        const differenceInMinutes = endTime.diff(startTime, 'minutes')
        // 30分と比較して
        if (differenceInMinutes <= 30) {
          return 20
        }

        // 1時間と比較
        if (differenceInMinutes <= 60) {
          return 40
        }

        // 1.5時間と比較して
        if (differenceInMinutes <= 90) {
          return 60
        }

        // 2時間と比較してください
        if (differenceInMinutes <= 120) {
          return 80
        }
      }

      return 100
    },

    isUnlimitedOrLimit100() {
      return [0, 100].includes(this.sendLimitPerDay)
    },

    isLimitOneHourToTwoHoursMessage() {
      if ([20, 40, 60, 80].includes(this.maxSendLimitPerDay)) {
        return this.sendLimitPerDay === this.maxSendLimitPerDay
      }

      return false
    },

    limitOneHourToTwoHoursMessage() {
      let time = ''

      if (this.maxSendLimitPerDay === 20) {
        time = '30分間'
      }

      if (this.maxSendLimitPerDay === 40) {
        time = '1時間'
      }

      if (this.maxSendLimitPerDay === 60) {
        time = '1時間半'
      }

      if (this.maxSendLimitPerDay === 80) {
        time = '2時間'
      }

      return `リスク回避のため、ATELUでは${time}で最大${this.maxSendLimitPerDay}件のペースで送信する制限を設けてます。`
    }
  },
  methods: {
    ...mapActions('twitterDirectMessageDialog', ['fetchQueueList', 'setQueueListIsShowDetail']),

    /**
     * 値のリセット
     */
    reset() {
      this.type = null
      this.sentAccountFilterType = 0
      this.answeredAccountFilterType = 1
      this.body = ''
      this.startDate = null
      this.startTime = null
      this.endTime = null
      this.sendLimitPerDay = 0
      this.isDisabledInput = false
      this.isSubmit = false
      this.$emit('update:isChangeWithoutSubmit', false)
      this.$v.body.$reset()
    },

    /**
     * 送信完了イベント
     */
    onSubmitSuccess() {
      this.reset()
      this.fetchQueueList()
      this.setQueueListIsShowDetail(false)
      this.successMessageQueueEdit()
    },

    /**
     * 種別の変更
     */
    onChangeType(value: string) {
      if (value === '種別') TrackingService.sendEvent('select:当選メッセージの管理|メッセージの新規作成|種別')

      this.getSendCount()

      switch (this.type) {
        case 0:
          this.sentAccountFilterType = 0
          this.answeredAccountFilterType = 1
          break
        case 1:
          this.sentAccountFilterType = 1
          this.answeredAccountFilterType = 1
          break
        case 2:
          this.sentAccountFilterType = 2
          this.answeredAccountFilterType = 1
          break
      }
    },

    /**
     * @param {number} templateId
     */
    onChangeTemplate(templateId: number) {
      TrackingService.sendEvent('select:当選メッセージの管理|メッセージの新規作成|テンプレート')

      const currentTemplate = this.messageTemplates.find(template => template.id === templateId)
      const body = currentTemplate.body

      if (!this.body) {
        this.body = body
        this.oldTemplate = templateId
        return
      }

      const confirmChange = confirm(
        this.$gettext('メッセージがすでに入力されています。テンプレートの内容に置き換えてよろしいですか?')
      )
      if (!confirmChange) {
        this.template = this.oldTemplate
        return
      }
      this.oldTemplate = templateId
      this.body = body
    },

    /**
     * 配信対象数と残り配信可能数の取得処理
     * @returns {void}
     */
    async getSendCount() {
      this.sendCount = 0
      this.remainMonthlyDmCount = 0
      this.maxMonthlyDmCount = 0

      const params = {
        type: this.type,
        campaignId: this.campaignId,
        prizeId: this.prizeId
      }

      this.isSendCountLoading = true

      const result = await api.get(`/twitter_message_queues/send_counts`, { params })

      if (!result || !result.data) {
        this.isSendCountLoading = false
        return
      }

      const { sendCount, remainMonthlyDmCount, maxMonthlyDmCount } = result.data

      this.sendCount = sendCount
      this.remainMonthlyDmCount = remainMonthlyDmCount
      this.remainMonthlyDmCountDefault = remainMonthlyDmCount
      this.maxMonthlyDmCount = maxMonthlyDmCount
      this.updateRemainMonthlyDmCount()
      this.isSendCountLoading = false
    },

    /**
     * 残り配信可能数を更新
     */
    updateRemainMonthlyDmCount() {
      if (this.maxMonthlyDmCount === 0) return

      const today = moment()
      const isAfter = moment(this.startDate, 'YYYY-MM').isAfter(today, 'month')

      this.remainMonthlyDmCount = isAfter ? this.maxMonthlyDmCount : this.remainMonthlyDmCountDefault
    },

    /**
     * メッセージ配信開始日時の取得
     */
    getActionDatetime() {
      return moment.tz(`${this.startDate} ${this.startTime}`, 'YYYY-MM-DD HH:mm', 'Asia/Tokyo').format()
    },

    /**
     * 下書き保存（既存）
     */
    saveDraft() {
      if (this.isSubmit) return

      this.updateQueue({ ...this.messageData, status: 'draft' })
    },

    /**
     * スケジュール保存（既存）
     */
    saveScheduled() {
      if (this.isSubmit) return

      this.updateQueue({ ...this.messageData, status: 'scheduled' })
    },

    /**
     * 下書き保存（新規）
     */
    createDraft() {
      TrackingService.sendEvent('click:当選メッセージの管理|メッセージの新規作成|下書き保存')

      if (this.isSubmit) return

      this.createQueue({ ...this.messageData, status: 'draft' })
    },

    /**
     * スケジュール保存（新規）
     */
    createScheduled() {
      TrackingService.sendEvent('click:当選メッセージの管理|メッセージの新規作成|新規作成')

      if (this.isSubmit) return

      this.createQueue({ ...this.messageData, status: 'scheduled' })
    },

    /**
     * エラーを表示
     * @param {string} title
     * @param {string} message
     */
    showError(title: string, message: string) {
      this.$notify({
        title,
        message,
        customClass: 'danger',
        dangerouslyUseHTMLString: true,
        duration: 5000
      })
    },

    /**
     * ファイルのメンションを変更
     */
    async handleFileChange(e) {
      TrackingService.sendEvent('click:当選メッセージの管理|メッセージの新規作成|新規作成')

      if (!e.target.files || !e.target.files[0]) {
        return
      }

      const file = e.target.files[0]
      this.$refs.mediaInput.value = null

      const validFormat = ['video/mp4', 'image/jpeg', 'image/png', 'image/gif', 'image/pjpeg', 'image/gif']

      // メディアをチェック
      const checkValidMedia = await TwitterUpload.checkValidMedia(file, validFormat)

      if (checkValidMedia.error) {
        const error = checkValidMedia.error

        let title = ''
        let message = ''

        switch (error) {
          case 'INVALID_MEDIA':
            title = this.$gettext('Please upload valid format!')
            message = validFormat.join(', ')
            break
          case 'MAX_SIZE_VIDEO':
            title = this.$gettext(
              '25MB以上のビデオを利用することはできません。 サイズを調整してからアップロードしてください。'
            )
            break
          case 'MAX_SIZE_IMAGE':
            title = this.$gettext(
              '5MB以上の画像を利用することはできません。サイズを調整してからアップロードしてください。'
            )
            break
          case 'INVALID_DIMENSION':
            title = this.$gettext('画像・動画アップロードに失敗しました。')
            message = this.$gettext(
              '詳しくは<a target="_blank" href="https://help-atelu.comnico.jp/available-image-and-video">こちら</a>をご確認ください。'
            )
            break
        }

        this.showError(title, message)

        return
      }

      this.isLoadMedia = true

      const formData = new FormData()
      formData.append('file', file)

      if (file.type.includes('video')) {
        const mediaCheck = await TwitterUpload.checkMedia(formData)

        if (!mediaCheck) {
          const title = this.$gettext('画像・動画アップロードに失敗しました。')
          const message = this.$gettext(
            '詳しくは<a target="_blank" href="https://help-atelu.comnico.jp/available-image-and-video">こちら</a>をご確認ください。'
          )

          this.showError(title, message)
          this.isLoadMedia = false

          return
        }
      }

      formData.append('accountId', this.campaign.account.id)

      formData.append('forDM', 'true')

      const result = await TwitterUpload.uploadMedia(formData)

      // アップロードプログレスを取得
      const progressResult = await this.getUploadProgress(result.data.filename)

      if (progressResult) {
        const media = {
          mediaOriginName: file.name,
          mediaUrl: progressResult.mediaUrl,
          twMediaId: progressResult.mediaId,
          expiresAfterSecs: progressResult.expiresAfterSecs
        }

        this.media = this.media ? Object.assign(this.media, media) : media
      }

      this.isLoadMedia = false
    },

    /**
     * アップロードプログレスを取得
     * @param {string} filename
     */
    getUploadProgress(filename: string) {
      const startUploadTime = moment()
      return new Promise((resolve, reject) => {
        this.uploadTimer = setInterval(async () => {
          const duration = moment.duration(moment().diff(startUploadTime))
          if (duration.asSeconds() > 300) {
            const title = this.$gettext('画像・動画アップロードに失敗しました。')
            const message = this.$gettext('恐れ入りますが、時間をおいて再度お試しください。')

            this.showError(title, message)

            clearInterval(this.uploadTimer)
            resolve(null)
          }

          const progress = await api.get(`/twitter_uploads/progresses?filename=${filename}`)
          if (progress.data) {
            clearInterval(this.uploadTimer)
            resolve(progress.data)
          }

          if (progress.error) {
            if (progress.error.statusCode === 400) {
              const title = this.$gettext('画像・動画アップロードに失敗しました。')
              const message = this.$gettext(
                '詳しくは<a target="_blank" href="https://help-atelu.comnico.jp/available-image-and-video">こちら</a>をご確認ください。'
              )

              this.showError(title, message)
            } else {
              const title = this.$gettext('画像・動画アップロードに失敗しました。')
              const message = this.$gettext('恐れ入りますが、時間をおいて再度お試しください。')

              this.showError(title, message)
            }

            clearInterval(this.uploadTimer)
            resolve(null)
          }
        }, 5000)
      })
    },

    /**
     * メンションのメディアファイル削除
     */
    removeMedia() {
      this.media = null
    },

    /**
     * メッセージキューの更新
     * @param {Object} params 配信データ
     */
    async updateQueue(params: any) {
      this.isSubmit = true

      const response = await api.put(`/twitter_message_queues/${this.messageQueueId}`, params)

      setTimeout(() => {
        this.isSubmit = false
      }, 2000)

      if (response.data) {
        this.$notify({
          title: this.$gettext('メッセージを保存しました。'),
          customClass: 'success',
          duration: 5000
        })
        this.onSubmitSuccess()
        return
      }

      if (response.error && response.error.type === 'NOT_EXISTS') {
        this.$notify({
          title: this.$gettext('対象のメッセージが見つかりません。'),
          message: this.$gettext('恐れ入りますが、時間をおいて再度お試しください。'),
          customClass: 'danger',
          duration: 5000
        })
        return
      }

      if (response.error && response.error.type === 'MESSAGE_QUEUE_COUNT_OVER') {
        this.$notify({
          title: this.$gettext('同じ時刻にスケジュールされている一括配信があります。'),
          message: this.$gettext('30分以上間隔を空けるようにしてください。'),
          customClass: 'danger',
          duration: 5000
        })
        return
      }

      this.$notify({
        title: this.$gettext('メッセージの保存に失敗しました。'),
        message: this.$gettext('恐れ入りますが、時間をおいて再度お試しください。'),
        customClass: 'danger',
        duration: 5000
      })
    },

    /**
     * メッセージキューの新規作成
     * @param {Object} params 配信データ
     */
    async createQueue(params: any) {
      this.isSubmit = true

      const response = await api.post(`/twitter_message_queues`, params)

      setTimeout(() => {
        this.isSubmit = false
      }, 2000)

      if (response.data) {
        this.$notify({
          title: this.$gettext(
            this.isCopy ? 'メッセージ配信スケジュールを複製しました。' : 'メッセージを作成しました。'
          ),
          customClass: 'success',
          duration: 5000
        })
        this.onSubmitSuccess()
        return
      }

      if (response.error && response.error.type === 'MESSAGE_QUEUE_COUNT_OVER') {
        this.$notify({
          title: this.$gettext('同じ時刻にスケジュールされている一括配信があります。'),
          message: this.$gettext('30分以上間隔を空けるようにしてください。'),
          customClass: 'danger',
          duration: 5000
        })
        return
      }

      this.$notify({
        title: this.$gettext(
          this.isCopy ? 'メッセージ配信スケジュールの複製に失敗しました。' : 'メッセージの作成に失敗しました。'
        ),
        message: this.$gettext('恐れ入りますが、時間をおいて再度お試しください。'),
        customClass: 'danger',
        duration: 5000
      })
    },

    copyToClipboard(variable: string) {
      this.$message({
        message: `${variable}をクリップボードにコピーしました。`,
        type: 'success'
      })

      Util.copyToClipboard(variable)
    },

    /**
     * 戻る
     */
    back() {
      TrackingService.sendEvent('click:当選メッセージの管理|メッセージの新規作成|戻る')

      if (!this.isChangeWithoutSubmit) {
        return this.successMessageQueueEdit()
      }

      const confirm = window.confirm(this.$gettext('編集内容が保存されていません。ダイアログを閉じてよろしいですか?'))

      if (!confirm) {
        return
      }

      this.$emit('update:isChangeWithoutSubmit', false)
      return this.successMessageQueueEdit()
    },

    /**
     * メッセージのデータを取得
     */
    getMessageData() {
      return {
        twCampaignPrizeId: this.prizeId,
        campaignId: this.campaignId,
        type: this.type,
        sendType: this.sendType,
        body: this.body,
        actionDatetime: this.getActionDatetime(),
        endTime: this.endTime,
        sendLimitPerDay: this.sendLimitPerDay,
        sentAccountFilterType: this.sentAccountFilterType,
        answeredAccountFilterType: this.answeredAccountFilterType,
        media: this.media
      }
    },

    clickLink() {
      TrackingService.sendEvent('click:当選メッセージの管理|メッセージの新規作成|テンプレート管理')
    },

    onChangeMessage() {
      TrackingService.sendEvent('input:当選メッセージの管理|メッセージの新規作成|メッセージ内容')
    },

    onChangeStartDate() {
      TrackingService.sendEvent('select:当選メッセージの管理|メッセージの新規作成|メッセージ配信開始日')
    },

    onChangeStartTime() {
      TrackingService.sendEvent('select:当選メッセージの管理|メッセージの新規作成|開始時間')
    },

    onChangeEndTime() {
      TrackingService.sendEvent('select:当選メッセージの管理|メッセージの新規作成|終了時間')
    }
  },

  validations: {
    body: {
      required
    }
  }
}
