/* @flow */
import { mapActions } from 'vuex'

import ApplicantGridItem from '@/client/components/basic/applicant_grid_item'
import ApplicantName from '@/client/components/basic/applicant_name'
import Icon from '@/client/components/basic/icon'
import Pagination from '@/client/components/basic/pagination'

export default {
  props: {
    applicants: Array,
    snsType: {
      type: String,
      default: 'twitter'
    },
    campaignType: String,
    options: Object
  },
  components: {
    Pagination,
    ApplicantName,
    Icon,
    ApplicantGridItem
  },
  methods: {
    ...mapActions('twitterApplicant', ['fetchApplicants']),

    /**
     * フラグ変更処理
     * @param {{type: string, value: any}} payload
     */
    changeFlag(payload: { type: string, value: any }) {
      this.$emit('change_flag', payload)
    },

    /**
     * メッセージ配信履歴を開く
     * @param {{applicantId: number}} applicantId
     */
    openMessageHistory(payload: { applicantId: number }) {
      this.$emit('open_message_history', payload)
    },

    onPaging(payload: { pagingNo: number, limitCount?: number }) {
      this.$emit('paging', payload)

      this.fetchApplicants()
    }
  }
}
