/* @flow */
import { minLength, required } from 'vuelidate/lib/validators'

import api from '@/client/core/api'

const NEW_USER = 'new_user'

export default {
  props: {
    invitation: Object
  },
  data() {
    return {
      name: '',
      password: '',
      isCheckPolicy: false
    }
  },
  methods: {
    /**
     * ログインサブミット
     * @return {void}
     */
    submit: async function($v: any) {
      // validations
      $v.$touch()
      if ($v.$invalid === true) {
        return
      }

      const params = {
        code: this.invitation.code,
        password: this.password,
        name: this.name,
        type: NEW_USER,
        isGuest: this.invitation.isGuest
      }

      const result = await api.post('/invitations/accept', params)
      if (result.error && result.error.type === 'GROUP_MAX_USER_COUNT_OVER') {
        this.$notify({
          title: this.$gettext('これ以上ユーザーを追加できません。'),
          message: this.$gettext('グループの登録可能ユーザー数を超えています。'),
          customClass: 'danger',
          duration: 10000
        })
        return
      }

      if (result.error) {
        this.$notify({
          title: this.$gettext('グループ参加処理に失敗しました。'),
          customClass: 'danger',
          duration: 10000
        })
        return
      }

      // 登録が成功した場合は、キャンペーン画面に移動する
      if (result.data) {
        this.$notify({
          title: this.$gettextInterpolate(this.$gettext('グループ %{groupName} に参加しました。'), {
            groupName: this.invitation.group.name
          }),
          customClass: 'success',
          duration: 5000
        })
        // 契約情報入力画面に移動する
        this.$router.push('/settings/group_billing')
        return
      }

      // エラーを表示する
      this.$notify({
        title: this.$gettext('グループ参加処理に失敗しました。'),
        customClass: 'danger',
        duration: 10000
      })
    }
  },
  validations: {
    name: {
      required
    },
    password: {
      required,
      // パスワードは8文字以上が必要
      minLength: minLength(8)
    }
  }
}
