/* @flow */
import api from '@/client/core/api'
import { TrackingService } from '@/client/services'
import { type TCampaign } from '@/typings/api/campaigns.type'
import type {
  TApplicant,
  TGetApplicantsParams,
  TPutCandidateParams,
  TPutFollowerParams,
  TPutResponderParams,
  TPutWinnerContactParams,
  TPutWinnerParams
} from '@/typings/api/tiktok_applicants.type'
import { type TPrize } from '@/typings/api/tiktok_campaign_prizes.type'

// mutations type
const RESET = 'RESET'
const SET_APPLICANT_DATA = 'SET_APPLICANT_DATA'
const SET_CAMPAIGN = 'SET_CAMPAIGN'
const SET_CAMPAIGN_PRIZE = 'SET_CAMPAIGN_PRIZE'
const SET_FIRST_LOADED = 'SET_FIRST_LOADED'
const SET_ERROR = 'SET_ERROR'
const SET_PARAMS = 'SET_PARAMS'
const TOGGLE_SHOW_ADVANCE_FILTER = 'TOGGLE_SHOW_ADVANCE_FILTER'
const UPDATE_FOLLOWER = 'UPDATE_FOLLOWER'
const UPDATE_CANDIDATE = 'UPDATE_CANDIDATE'
const UPDATE_WINNER = 'UPDATE_WINNER'
const UPDATE_RESPONDER = 'UPDATE_RESPONDER'
const UPDATE_WINNER_CONTACT = 'UPDATE_WINNER_CONTACT'
const SET_IS_LOADING = 'SET_IS_LOADING'

// 応募者ストア
export type ApplicantStore = {
  firstLoaded: boolean,
  error: boolean,
  total: number,
  applicants: TApplicant[],
  params: TGetApplicantsParams,
  campaign: TCampaign | null,
  campaignPrize: TPrize | null,
  showAdvanceFilter: boolean,
  isLoading: boolean
}
// ストアーcontext
type Context = {
  commit: Function,
  getters: any,
  state: ApplicantStore,
  dispatch: Function
}

// init state
const initState: ApplicantStore = {
  firstLoaded: false,
  error: false,
  campaign: null,
  campaignPrize: null,
  applicants: [],
  total: 0,
  params: {
    campaignId: 0,
    prizeId: 0,
    sortBy: '-publishDatetime',
    pagingNo: 1,
    limitCount: 25,
    isFollowing: '',
    isWinner: '',
    wasWinner: -1,
    isResponder: '',
    isWinnerContact: '',
    isCandidate: '',
    totalCampaignEntryCount: ['', ''],
    totalCampaignWinningCount: ['', ''],
    postsCount: ['', ''],
    accountName: '',
    forCsv: 0
  },
  showAdvanceFilter: false,
  isLoading: false
}
const state = { ...initState }

// getters
const getters = {}

// actions
const actions = {
  /**
   * ストアをリセット
   * @param {Context} context
   */
  async reset(context: Context) {
    context.commit(RESET)
  },

  /**
   * 応募者一覧を取得
   * @param {Context} context
   */
  async fetchApplicants(context: Context) {
    context.commit(SET_IS_LOADING, true)

    const params = context.state.params
    context.commit(SET_ERROR, false)

    // システムストアのローディングをセット
    context.dispatch('system/setLoading', true, { root: true })

    const result = await api.get('/tiktok_applicants', { params })

    // システムストアのローディングをセット
    context.dispatch('system/setLoading', false, { root: true })
    context.commit(SET_FIRST_LOADED, true)
    context.commit(SET_IS_LOADING, false)

    // データがない場合はエラーフラグをセット
    if (!result.data) {
      context.commit(SET_ERROR, true)
      return
    }

    // 応募者一覧をセット
    const applicantData: any = {
      applicants: result.data.applicants
    }

    if (params.pagingNo === 1) {
      applicantData.total = result.data.dataCount
    }
    context.commit(SET_APPLICANT_DATA, applicantData)

    context.commit(SET_IS_LOADING, false)
  },

  /**
   * キャンペーンデータを取得
   * @param {Context} context
   */
  async fetchCampaign(context: Context) {
    const result = await api.get('/campaigns/' + context.state.params.campaignId)
    if (result.data) {
      context.commit(SET_CAMPAIGN, result.data)
    }
  },

  /**
   * fetch campaign prize
   * @param {Context} context
   */
  async fetchCampaignPrize(context: Context) {
    const result = await api.get('/tiktok_campaign_prizes/' + context.state.params.prizeId)
    if (result.data) {
      context.commit(SET_CAMPAIGN_PRIZE, result.data)
    }
  },

  /**
   * パラメーターをセット
   * @param {Context} context
   * @param {TGetApplicantsParams} params パラメーター
   */
  setParams(context: Context, params: TGetApplicantsParams) {
    if (!params.pagingNo) {
      params.pagingNo = 1
    }
    context.commit(SET_PARAMS, { ...context.state.params, ...params })
  },

  /**
   * 詳細フィルターをトグル
   * @param {Context} context
   */
  toggleShowAdvanceFilter(context: Context) {
    TrackingService.sendEvent('click:応募一覧（IG）|詳細検索を終了')

    context.commit(TOGGLE_SHOW_ADVANCE_FILTER)
  },

  /**
   * 対象応募者の当選連絡フラグの変更
   * @param {Context} context
   * @param {TPutWinnerContactParams} payload キャンペーン応募者ID
   */
  async updateWinnerContact(context: Context, payload: TPutWinnerContactParams) {
    const result = await api.put('/tiktok_applicants/' + payload.applicantId, {
      isWinnerContact: payload.isWinnerContact
    })

    if (result.data) {
      context.commit(UPDATE_WINNER_CONTACT, payload)
    }

    if (context.state.applicants.length === 0 && context.state.total > 0) {
      context.dispatch('fetchApplicants')
    }

    return result
  },

  /**
   * 対象応募者のフォローフラグの変更
   * @param {Context} context
   * @param {TPutFollowerParams} payload キャンペーン応募者ID
   */
  async updateFollower(context: Context, payload: TPutFollowerParams) {
    const result = await api.put('/tiktok_applicants/' + payload.applicantId, {
      isFollower: payload.isFollowing
    })

    if (result.data) {
      context.commit(UPDATE_FOLLOWER, payload)
    }

    if (context.state.applicants.length === 0 && context.state.total > 0) {
      context.dispatch('fetchApplicants')
    }

    return result
  },

  /**
   * 対象応募者の候補者フラグの変更
   * @param {Context} context
   * @param {TPutCandidateParams} payload キャンペーン応募者ID
   */
  async updateCandidate(context: Context, payload: TPutCandidateParams) {
    const result = await api.put('/tiktok_applicants/' + payload.applicantId, {
      isCandidate: payload.isCandidate
    })

    if (result.data) {
      context.commit(UPDATE_CANDIDATE, payload)
    }

    if (context.state.applicants.length === 0 && context.state.total > 0) {
      context.dispatch('fetchApplicants')
    }

    return result
  },

  /**
   * 対象応募者の当選者フラグの変更
   * @param {Context} context
   * @param {TPutWinnerParams} payload キャンペーン応募者ID
   */
  async updateWinner(context: Context, payload: TPutWinnerParams) {
    if (!context.state.campaignPrize) {
      return
    }
    const result = await api.put('/tiktok_applicants/' + payload.applicantId, {
      prizeId: context.state.campaignPrize.id,
      isWinner: payload.isWinner,
      wasWinner: payload.wasWinner
    })

    if (result.data) {
      context.commit(UPDATE_WINNER, payload)
    }

    if (context.state.applicants.length === 0 && context.state.total > 0) {
      context.dispatch('fetchApplicants')
    }

    return result
  },

  /**
   * 対象応募者の回答者フラグの変更
   * @param {Context} context
   * @param {TPutResponderParams} payload キャンペーン応募者ID
   */
  async updateResponder(context: Context, payload: TPutResponderParams) {
    const result = await api.put('/tiktok_applicants/' + payload.applicantId, {
      isResponder: payload.isResponder
    })

    if (result.data) {
      context.commit(UPDATE_RESPONDER, payload)
    }

    if (context.state.applicants.length === 0 && context.state.total > 0) {
      context.dispatch('fetchApplicants')
    }

    return result
  }
}

// mutations
const mutations = {
  /**
   * ストアをリセット
   * @param {ApplicantStore} state
   */
  [RESET](state: ApplicantStore) {
    const initStateData: any = initState
    Object.keys(initStateData).forEach(key => {
      state[key] = initStateData[key]
    })
  },

  /**
   * 応募者データをセット
   * @param {ApplicantStore} state
   * @param {TApplicant[]} applicantData 応募者一覧
   */
  [SET_APPLICANT_DATA](state: ApplicantStore, applicantData: { total: number, applicants: TApplicant[] }) {
    state.applicants = applicantData.applicants
    if (applicantData.total !== undefined) {
      state.total = applicantData.total
    }
  },

  /**
   * 応募者データをセット
   * @param {ApplicantStore} state
   * @param {TCampaign} campaign キャンペーンデータ
   */
  [SET_CAMPAIGN](state: ApplicantStore, campaign: TCampaign) {
    if (!campaign.account) {
      campaign.account = {
        id: null,
        name: '削除されたアカウント',
        pictureUrl: ''
      }
    }
    state.campaign = campaign
  },

  /**
   * set campaign prize
   * @param {ApplicantStore} state
   * @param {TCampaignPrize} campaignPrize set campaign prize
   */
  [SET_CAMPAIGN_PRIZE](state: ApplicantStore, campaignPrize: TPrize) {
    state.campaignPrize = campaignPrize
  },

  /**
   * 応募者パラメーターをセット
   * @param {ApplicantStore} state
   * @param {TApplicantParams} params パラメーター
   */
  [SET_PARAMS](state: ApplicantStore, params: TGetApplicantsParams) {
    state.params = params
  },

  /**
   * 初回ロード終了フラグをセット
   * @param {ApplicantStore} state
   * @param {boolean} loaded 初回ロード終了フラグ
   */
  [SET_FIRST_LOADED](state: ApplicantStore, loaded: boolean) {
    state.firstLoaded = loaded
  },

  /**
   * エラーフラグをセット
   * @param {ApplicantStore} state
   * @param {boolean} error エラーフラグ
   */
  [SET_ERROR](state: ApplicantStore, error: boolean) {
    state.error = error
  },

  /**
   * 詳細フィルターをトグル
   * @param {ApplicantStore} state
   */
  [TOGGLE_SHOW_ADVANCE_FILTER](state: ApplicantStore) {
    state.showAdvanceFilter = !state.showAdvanceFilter
  },

  /**
   * ロードデータフラグをセット
   * @param {ApplicantStore} state
   * @param {boolean} payload
   */
  [SET_IS_LOADING](state: ApplicantStore, payload: boolean) {
    state.isLoading = payload
  },

  /**
   * 候補者を更新
   * @param {ApplicantStore} state
   * @param {TPutCandidateParams} payload
   */
  [UPDATE_CANDIDATE](state: ApplicantStore, payload: TPutCandidateParams) {
    state.applicants.forEach((applicant, index) => {
      if (applicant.id === payload.applicantId) {
        applicant.isCandidate = payload.isCandidate
        if (
          (state.params.isCandidate === 0 && payload.isCandidate === 1) ||
          (state.params.isCandidate === 1 && payload.isCandidate === 0)
        ) {
          state.applicants.splice(index, 1)
          state.total--
        }
        return true
      }
    })

    if (state.campaignPrize && payload.isCandidate === 1) {
      state.campaignPrize.candidateCount++
    }

    if (state.campaignPrize && payload.isCandidate === 0) {
      state.campaignPrize.candidateCount--
    }
  },

  /**
   * 回答者を更新
   * @param {ApplicantStore} state
   * @param {TPutResponderParams} payload
   */
  [UPDATE_RESPONDER](state: ApplicantStore, payload: TPutResponderParams) {
    state.applicants.forEach((applicant, index) => {
      if (applicant.id === payload.applicantId) {
        applicant.isResponder = payload.isResponder
        if (
          (state.params.isResponder === 0 && payload.isResponder === 1) ||
          (state.params.isResponder === 1 && payload.isResponder === 0)
        ) {
          state.applicants.splice(index, 1)
          state.total--
        }
        return true
      }
    })

    if (state.campaignPrize && payload.isResponder === 1) {
      state.campaignPrize.responderCount++
    }

    if (state.campaignPrize && payload.isResponder === 0) {
      state.campaignPrize.responderCount--
    }
  },

  /**
   * 当選連絡を更新
   * @param {ApplicantStore} state
   * @param {TPutWinnerContactParams} payload
   */
  [UPDATE_WINNER_CONTACT](state: ApplicantStore, payload: TPutWinnerContactParams) {
    state.applicants.forEach((applicant, index) => {
      if (applicant.id === payload.applicantId) {
        applicant.isWinnerContact = payload.isWinnerContact
        if (
          (state.params.isWinnerContact === 0 && payload.isWinnerContact === 1) ||
          (state.params.isWinnerContact === 1 && payload.isWinnerContact === 0)
        ) {
          state.applicants.splice(index, 1)
          state.total--
        }
        return true
      }
    })

    if (state.campaignPrize && payload.isWinnerContact === 1) {
      state.campaignPrize.winnerContactCount++
    }

    if (state.campaignPrize && payload.isWinnerContact === 0) {
      state.campaignPrize.winnerContactCount--
    }
  },

  /**
   * フォローを更新
   * @param {ApplicantStore} state
   * @param {TPutFollowerParams} payload
   */
  [UPDATE_FOLLOWER](state: ApplicantStore, payload: TPutFollowerParams) {
    state.applicants.forEach((applicant, index) => {
      if (applicant.id === payload.applicantId) {
        applicant.isFollowing = payload.isFollowing
        if (
          (state.params.isFollowing === 0 && payload.isFollowing === 1) ||
          (state.params.isFollowing === 1 && payload.isFollowing === 0)
        ) {
          state.applicants.splice(index, 1)
          state.total--
        }
        return true
      }
    })

    if (state.campaignPrize && payload.isFollowing === 1) {
      state.campaignPrize.followersCount++
    }

    if (state.campaignPrize && payload.isFollowing === 0) {
      state.campaignPrize.followersCount--
    }
  },

  /**
   * 当選者を更新
   * @param {ApplicantStore} state
   * @param {TPutWinnerParams} payload
   */
  [UPDATE_WINNER](state: ApplicantStore, payload: TPutWinnerParams) {
    state.applicants.forEach((applicant, index) => {
      if (applicant.id === payload.applicantId) {
        applicant.isWinner = payload.isWinner

        if (
          (state.params.isWinner === 0 && payload.isWinner === 1) ||
          (state.params.isWinner === 1 && payload.isWinner === 0)
        ) {
          state.applicants.splice(index, 1)
          state.total--
        }
        return true
      }
    })

    if (state.campaignPrize && payload.isWinner === 1) {
      state.campaignPrize.winnerCount++
    }

    if (state.campaignPrize && payload.isWinner === 0) {
      state.campaignPrize.winnerCount--
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
