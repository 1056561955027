/* @flow */
import { filterCountryName } from '@/client/utils/filters'

export default {
  props: {
    groupBilling: { type: Object }
  },
  computed: {
    showGroupName() {
      return Boolean(this.groupBilling?.group?.name)
    },

    groupName() {
      return {
        title: 'グループ名',
        content: this.groupBilling?.group?.name
      }
    },

    getAllAddress() {
      const address: string[] = []
      if (this.groupBilling.countryCode) address.push(filterCountryName(this.groupBilling.countryCode))

      if (this.groupBilling.zipCode) {
        const zipCode = this.groupBilling.zipCode.replace(/-/g, '')

        // 日本の郵便番号の先頭に「〒」を追加し、ハイフンで区切られた形式にフォーマットする
        // 例: '1234567' => '〒123-4567'
        address.push('〒' + zipCode.slice(0, 3) + '-' + zipCode.slice(3))
      }

      if (this.groupBilling.address) address.push(this.groupBilling.address)

      return address.join('\n')
    },

    groupBillingDetail() {
      return [
        {
          title: '会社名',
          content: this.groupBilling.companyName
        },
        {
          title: '担当部署名',
          content: this.groupBilling.departName
        },
        {
          title: '担当者名',
          content: this.groupBilling.personName
        },
        {
          title: 'メールアドレス',
          content: this.groupBilling.email
        },
        {
          title: '住所',
          content: this.getAllAddress
        },
        {
          title: '電話番号',
          content: this.groupBilling.telephone
        }
      ]
    }
  }
}
