/* @flow */
import moment from 'moment'
import { mapActions } from 'vuex'

import ApplicantName from '@/client/components/basic/applicant_name'
import CsvDownload from '@/client/components/basic/csv_download'
import Pagination from '@/client/components/basic/pagination'
import api from '@/client/core/api'
import Util from '@/client/core/util'
import { type TGetMessageQueueMessages } from '@/typings/api/twitter_message_queues_message.type'

// パラメーター
type Params = {
  sortBy: string,
  limitCount: number,
  pagingNo: number,
  status: string
}

export default {
  props: {
    messageQueueId: Number
  },
  components: {
    ApplicantName,
    CsvDownload,
    Pagination
  },
  data() {
    return {
      messages: [],
      params: {
        status: '',
        sortBy: '-actionDatetime',
        pagingNo: 1,
        limitCount: 25
      },
      loading: false,
      total: 0,
      status: {
        value: '',
        options: [
          {
            label: this.$gettext('指定なし'),
            value: 'none'
          },
          {
            label: this.$gettext('配信成功'),
            value: 'sent'
          },
          {
            label: this.$gettext('配信失敗'),
            value: 'failed'
          },
          {
            label: this.$gettext('配信待ち'),
            value: 'unsent'
          }
        ],
        onChange: (value: string) => {
          this.status.value = value
          if (this.params.status === value) {
            return
          }
          if (value === 'none') {
            value = ''
          }
          this.changeParams({
            status: value
          })
        }
      }
    }
  },
  created() {
    // メッセージ一覧を取得
    this.getMessages()
  },
  watch: {
    // メッセージキューIDを変更する際、メッセージ一覧を取得
    messageQueueId() {
      this.getMessages()
    },
    // パラメータを変更する際、メッセージ一覧を取得
    params() {
      this.getMessages()
    }
  },
  methods: {
    ...mapActions('twitterDirectMessageDialog', ['setQueueListIsShowDetail']),
    /**
     * メッセージ一覧を取得
     */
    async getMessages() {
      if (!this.messageQueueId) {
        return
      }
      this.loading = true
      const result: TGetMessageQueueMessages = await api.get(
        `/twitter_message_queues/${this.messageQueueId}/messages`,
        {
          params: this.params
        }
      )
      this.loading = false
      if (!result.data) {
        this.messages = []
        this.total = 0
        return
      }

      this.messages = result.data.messages
      this.total = result.data.dataCount
    },

    /**
     * メッセージキューに戻る
     */
    backToQueueList() {
      this.setQueueListIsShowDetail(false)
    },

    /**
     * パラメーターを変更
     * @param {Params} params パラメーター
     */
    changeParams(params: Params) {
      if (!params.pagingNo) {
        params.pagingNo = 1
      }
      this.params = Object.assign({}, this.params, params)
    },

    /**
     * ソートを変更イベント
     * @param {string} sortBy ソート
     */
    onChangeSortBy(sortBy: string) {
      this.changeParams({
        sortBy
      })
    },

    /**
     * ページを変更イベント
     * @param {number} pagingNo ページング
     */
    onChangePagingNo(pagingNo: number) {
      this.changeParams({
        pagingNo
      })
    },

    /**
     * ページを変更イベント
     * @param {number} limitCount リミット数
     */
    onChangeLimitCount(limitCount: number) {
      this.changeParams({
        limitCount
      })
    },

    /**
     * CSVダウンロード
     */
    async downloadCsv() {
      const params = Object.assign({}, this.params, {
        limitCount: -1
      })
      const result: TGetMessageQueueMessages = await api.get(
        `/twitter_message_queues/${this.messageQueueId}/messages`,
        {
          params
        }
      )
      if (!result.data) {
        return {
          error: 'ERROR_LOADING'
        }
      }
      const messages = result.data.messages
      const filename = `campaign_messages_${moment().format('YYYY_MM_DD')}`
      const csvData = []
      csvData.push([
        this.$gettext('配信開始日時'),
        this.$gettext('配信結果'),
        this.$gettext('アカウント名'),
        this.$gettext('アカウントスクリーンネーム'),
        this.$gettext('アカウント画像'),
        this.$gettext('フォロー中'),
        this.$gettext('警告アカウント')
      ])

      // CSVデータを格納
      messages.forEach(message => {
        let status = ''
        switch (message.status) {
          case 'sent':
            status = this.$gettext('配信成功')
            break
          case 'failed':
            status = this.$gettext('配信失敗')
            break
          case 'unsent':
            status = this.$gettext('配信待ち')
            break
        }
        csvData.push([
          this.$options.filters.datetime(message.actionDatetime),
          status,
          message.applicant.name,
          message.applicant.screenName,
          message.applicant.pictureUrl,
          message.applicant.isFollowing,
          message.applicant.isWarning
        ])
      })
      return {
        filename,
        data: csvData
      }
    },

    /**
     * ヘッダーをクリックするとソートする
     * @param {*} column
     */
    headerClick(column: any) {
      if (!column.sortBy) {
        return
      }
      if (column.sortBy.indexOf('-') === 0) {
        if (this.params.sortBy === column.sortBy) {
          this.onChangeSortBy(column.sortBy.substring(1))
        } else {
          this.onChangeSortBy(column.sortBy)
        }
      } else {
        if (this.params.sortBy === column.sortBy) {
          this.onChangeSortBy('-' + column.sortBy)
        } else {
          this.onChangeSortBy(column.sortBy)
        }
      }
    },

    /**
     * ヘッダー描画
     * @param {*} createElement
     * @param {*} { column }
     */
    renderHeader(createElement: any, { column }: any) {
      return Util.renderHeader(createElement, column.label, Util.getSortClass(this.params.sortBy, column.sortBy))
    }
  }
}
