/* @flow */
import moment from 'moment'
import twitterText from 'twitter-text'
import { mapActions, mapState } from 'vuex'

import ApplicantName from '@/client/components/basic/applicant_name'
import CsvDownload from '@/client/components/basic/csv_download'
import Icon from '@/client/components/basic/icon'
import Pagination from '@/client/components/basic/pagination'
import SelectForm from '@/client/components/basic/select_form'
import SerialCodeImportReset from '@/client/components/twitter_applicant/serial_code_import_reset'
import api from '@/client/core/api'
import Util from '@/client/core/util'
import { TrackingService } from '@/client/services'

export default {
  props: {
    onChangeSortBy: Function,
    onChange: Function,
    onClickSerialCodeCreate: Function,
    isChangeWithoutSubmit: Boolean
  },
  components: {
    Icon,
    CsvDownload,
    SelectForm,
    Pagination,
    ApplicantName,
    SerialCodeImportReset
  },
  data() {
    return {
      screen: '',
      // ステータス
      status: {
        value: '',
        placeholder: 'ステータス',
        options: [
          {
            label: this.$gettext('指定なし'),
            value: ''
          },
          {
            label: this.$gettext('割り当て済'),
            value: 'assign'
          },
          {
            label: this.$gettext('未割り当て'),
            value: 'unassigned'
          }
        ],
        onChange: (value: number) => {
          TrackingService.sendEvent('select:当選メッセージの管理|シルアルコード管理|割当指定')

          this.status.value = value
          if (this.options.filter === value) {
            return
          }

          // オプションを切り替えるときは 1 ページに戻る
          this.setSerialCodeListParams({ filter: value, pagingNo: 1 })
        }
      },
      assignTiming: {
        value: '',
        options: [
          { label: '指定なし', value: '' },
          { label: '当選DM', value: 'DM' },
          { label: 'WEBページ', value: 'web' }
        ],
        onChange: (value: 'DM' | 'web') => {
          // オプションを切り替えるときは 1 ページに戻る
          this.setSerialCodeListParams({ type: value, pagingNo: 1 })
        }
      },
      action: {
        options: [
          {
            label: this.$gettext('使用済みコードインポート'),
            value: 'usedCodeImport'
          },
          {
            label: this.$gettext('使用済みコード解除'),
            value: 'usedCodeReset'
          },
          {
            label: this.$gettext('未割り当てコード削除'),
            value: 'serialCodeReset'
          }
        ]
      }
    }
  },
  computed: {
    ...mapState('twitterSerialCodeDialog', {
      campaignId: state => state.campaignId,
      prizeId: state => state.prizeId,
      serialCodeList: state => state.serialCode.list,
      options: state => {
        return {
          filter: state.serialCode.params.filter,
          sortBy: state.serialCode.params.sortBy,
          pagingNo: state.serialCode.params.pagingNo,
          limitCount: state.serialCode.params.limitCount,
          total: state.serialCode.total,
          countAssign: state.serialCode.countAssign,
          countUnassign: state.serialCode.countUnassign,
          type: state.serialCode.params.type
        }
      }
    }),
    ...mapState('system', {
      contractStatus: state => state.currentGroup.contractStatus
    }),
    ...mapState('twitterApplicant', {
      campaign: state => {
        if (state.campaign) {
          return state.campaign
        }
        return {}
      },
      campaignPrize: state => {
        if (state.campaignPrize) {
          return state.campaignPrize
        }
        return {}
      }
    }),
    is_table_screen() {
      return this.screen === ''
    }
  },
  watch: {
    options: function() {
      this.status.options[0].label = this.$gettext('指定なし')
      this.status.options[1].label = this.$gettext('割り当て済')
      this.status.options[2].label = this.$gettext('未割り当て')
    }
  },
  methods: {
    ...mapActions('twitterSerialCodeDialog', ['setSerialCodeListParams']),
    createNewSerialCode() {
      TrackingService.sendEvent('click:当選メッセージの管理|シルアルコード管理|追加')

      this.onClickSerialCodeCreate()
    },

    /**
     * ページを変更イベント
     * @param {number} pagingNo ページング
     */
    onChangePagingNo(pagingNo: number) {
      this.setSerialCodeListParams({
        pagingNo
      })
    },

    /**
     * ページを変更イベント
     * @param {number} limitCount リミット数
     */
    onChangeLimitCount(limitCount: number) {
      this.setSerialCodeListParams({
        limitCount,
        pagingNo: 1
      })
    },

    /**
     * 選択した一括操作を実行
     * @param {string} command コマンド
     */
    async selectAction(command: string) {
      if (command) TrackingService.sendEvent('select:当選メッセージの管理|シルアルコード管理|一括操作')

      switch (command) {
        case 'usedCodeImport':
          this.screen = 'import'
          break
        case 'usedCodeReset':
          this.screen = 'used_code_reset'
          break
        case 'serialCodeReset':
          this.screen = 'code_reset'
          break
        default:
          this.screen = ''
      }
    },

    /**
     * シリアルコードを削除
     * @param {number} serialCode メッセージキューID
     */
    async deleteSerialCode(serialCode: number) {
      TrackingService.sendEvent('click:当選メッセージの管理|シルアルコード管理|削除')

      const confirm = window.confirm(this.$gettext('シリアルコードを削除してよろしいですか?'))
      if (!confirm) {
        return
      }
      const result = await api.delete(`/twitter_serial_codes/${serialCode}`)
      if (result.error && result.error.type === 'NOT_EXISTS') {
        this.$notify({
          title: this.$gettext('対象のシリアルコードはすでに削除されています。'),
          customClass: 'danger',
          duration: 5000
        })
        if (typeof this.onChange === 'function') {
          this.onChange()
        }
        return
      }

      // エラーがある場合はエラーメッセージを表示
      if (!result.data) {
        this.$notify({
          title: this.$gettext('シリアルコードの削除に失敗しました。'),
          message: this.$gettext('恐れ入りますが、時間をおいて再度お試しください。'),
          customClass: 'danger',
          duration: 5000
        })
        return
      }

      // 成功メッセージを表示
      this.$notify({
        title: this.$gettext('シリアルコードを削除しました。'),
        customClass: 'success',
        duration: 5000
      })

      if (typeof this.onChange === 'function') {
        this.onChange()
      }
    },

    async downloadCsv() {
      TrackingService.sendEvent('click:当選メッセージの管理|シルアルコード管理|CSVダウンロード')

      const campaignId = this.campaignId
      const prizeId = this.prizeId
      const params = Object.assign({ campaignId, prizeId }, this.options)
      params.limitCount = -1
      const result = await api.get('/twitter_serial_codes', {
        params
      })
      if (!result.data) {
        return {
          error: 'ERROR_LOADING'
        }
      }

      const serialCodeList = result.data.serialCode
      const filename = `campaign_serial_codes_${moment().format('YYYY_MM_DD')}`
      const csvData = []
      csvData.push([
        this.$gettext('No'),
        this.$gettext('ステータス'),
        this.$gettext('割り当てタイミング'),
        this.$gettext('使用済み'),
        this.$gettext('シリアルコード'),
        this.$gettext('アカウント名'),
        this.$gettext('アカウントスクリーン名'),
        this.$gettext('アカウント画像'),
        this.$gettext('フォロー中'),
        this.$gettext('警告アカウント')
      ])

      serialCodeList.forEach((serialCode, index) => {
        csvData.push([
          index + 1,
          serialCode.twCampaignApplicantId ? this.$gettext('割り当て済') : this.$gettext('未割り当て'),
          serialCode.type === 0 ? 'DM' : 'WEBページ',
          serialCode.userIsUsed,
          serialCode.serialCode,
          serialCode.applicant.name,
          serialCode.applicant.screenName,
          serialCode.applicant.pictureUrl,
          serialCode.applicant.name ? (serialCode.applicant.isFollowing ? 1 : 0) : null,
          serialCode.applicant.name ? (serialCode.applicant.isWarning ? 1 : 0) : null
        ])
      })

      return {
        filename,
        data: csvData
      }
    },

    /**
     * シリアルコードにURLが含まれる場合、アンカーリンクにする
     * @param {string} serialCode
     * @returns {string}
     */
    convertSerialCode(serialCode: string): string {
      const escapeSerialCode = this.htmlEscape(serialCode)
      const entities = twitterText.extractEntitiesWithIndices(escapeSerialCode, {
        extractUrlsWithoutProtocol: true
      })

      return twitterText.autoLinkEntities(escapeSerialCode, entities, {
        targetBlank: true
      })
    },
    /**
     * HTMLで使用する特殊文字を除去する
     */
    htmlEscape(text: string): string {
      const entities = {
        '>': '&gt;',
        '<': '&lt;',
        '"': '&quot;',
        "'": '&#39;'
      }

      return text.replace(/["'><]/g, character => entities[character])
    },
    /**
     * ヘッダーをクリックするとソートする
     * @param {*} column
     */
    headerClick(column: any) {
      switch (column.label) {
        case 'ステータス':
          TrackingService.sendEvent('sort:当選メッセージの管理|シルアルコード管理|ステータス')
          break
        case 'シリアルコード':
          TrackingService.sendEvent('sort:当選メッセージの管理|シルアルコード管理|シルアルコード')
          break
        case '割り当てアカウント':
          TrackingService.sendEvent('sort:当選メッセージの管理|シルアルコード管理|割当アカウント')
          break
        default:
          break
      }

      if (column.sortBy.indexOf('-') === 0) {
        if (this.options.sortBy === column.sortBy) {
          this.onChangeSortBy(column.sortBy.substring(1))
        } else {
          this.onChangeSortBy(column.sortBy)
        }
      } else {
        if (this.options.sortBy === column.sortBy) {
          this.onChangeSortBy('-' + column.sortBy)
        } else {
          this.onChangeSortBy(column.sortBy)
        }
      }
    },
    /**
     * ヘッダー描画
     * @param {*} createElement
     * @param {*} { column }
     */
    renderHeader(createElement: any, { column }: any) {
      return Util.renderHeader(createElement, column.label, Util.getSortClass(this.options.sortBy, column.sortBy))
    },
    reset() {
      this.screen = ''
    }
  }
}
