


























































import { Component, Emit, Vue } from 'vue-property-decorator'
import { mapActions, mapState } from 'vuex'

import Icon from '@/client/components/_atoms/Icon.vue'
import Message from '@/client/components/_atoms/Message.vue'
import Account from '@/client/components/_molecules/Account.vue'
import ButtonIcon from '@/client/components/_molecules/ButtonIcon.vue'
import Keyword from '@/client/components/_molecules/Keyword.vue'
import { TrackingService } from '@/client/services'
import { SettingGroupActions, SettingGroupState } from '@/client/store/setting_group.module'
import { ProfileKeywordSetting } from '@/typings/profile_keyword_settings'

@Component({
  components: {
    Icon,
    Message,
    Account,
    ButtonIcon,
    Keyword
  },
  computed: mapState('setting_group', ['api_profile_keyword_settings']),
  methods: mapActions('setting_group', ['updateProfileKeyword'])
})
export default class ProfileKeywordSettingTable extends Vue {
  api_profile_keyword_settings!: SettingGroupState['api_profile_keyword_settings']
  updateProfileKeyword!: SettingGroupActions['updateProfileKeyword']

  get is_exist() {
    return this.api_profile_keyword_settings.length > 0
  }

  @Emit('add')
  add(payload) {
    TrackingService.sendEvent('click:グループ設定|自己紹介キーワード設定|キーワード判定セットの追加')

    return payload
  }

  @Emit('edit')
  edit(payload) {
    TrackingService.sendEvent('click:グループ設定|自己紹介キーワード設定|編集')

    return payload
  }

  @Emit('copy')
  copy(payload) {
    TrackingService.sendEvent('click:グループ設定|自己紹介キーワード設定|複製')

    return payload
  }

  /**
   * 自己紹介キーワード判定のデフォルト設定を変更
   */
  async changeDefaultConfig(data: ProfileKeywordSetting) {
    TrackingService.sendEvent('switch:グループ設定|自己紹介キーワード設定|デフォルト判定')

    const value = {
      keyword_setting_id: data.id,
      is_active: !data.is_active
    }

    const response = await this.updateProfileKeyword(value)

    if (response.error && response.error.type === 'MAX_COUNT_OVER') {
      this.$notify({
        title: 'デフォルト設定の変更に失敗しました。',
        message: 'ポジティブキーワードまたはネガティブキーワードの合計が200文字以内になるように設定してください。',
        customClass: 'danger'
      })

      return
    }

    if (response.data) {
      this.$notify({
        title: '設定を変更しました。',
        message: '',
        customClass: 'success'
      })

      return
    }

    this.$notify({
      title: 'デフォルト設定の変更に失敗しました。',
      message: '恐れ入りますが、時間をおいて再度お試しください。',
      customClass: 'danger'
    })
  }

  sortChange() {
    TrackingService.sendEvent('sort:グループ設定|自己紹介キーワード設定|キーワード判定セット')
  }
}
