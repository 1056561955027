import { render, staticRenderFns } from "./direct_message_history_dialog.vue?vue&type=template&id=51fd0854&scoped=true"
import script from "./direct_message_history_dialog.component.js?vue&type=script&lang=js&external"
export * from "./direct_message_history_dialog.component.js?vue&type=script&lang=js&external"
import style0 from "./direct_message_history_dialog.scss?vue&type=style&index=0&id=51fd0854&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "51fd0854",
  null
  
)

export default component.exports