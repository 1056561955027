/* @flow */
import { mapState } from 'vuex'

import api from '@/client/core/api'
import Util from '@/client/core/util'
import { TrackingService } from '@/client/services'

export default {
  props: {
    messageQueues: Array,
    options: Object,
    onChangeSortBy: Function,
    onChange: Function,
    onClickMessageQueueEdit: Function,
    onClickGetMessageQueuesId: Function,
    onClickMessageQueueCreate: Function,
    onClickMessageQueueDetail: {
      type: Function,
      default: () => {}
    }
  },
  computed: {
    ...mapState('twitterDirectMessageDialog', {
      campaignId: state => state.campaignId,
      prizeId: state => state.prizeId
    })
  },
  methods: {
    /**
     * メッセージキューを更新
     * @param {number} messageQueueId メッセージキューId
     * @param {string} status
     */
    getMessageQueuesId(messageQueueId: number, status: string) {
      TrackingService.sendEvent('click:当選メッセージの管理|メッセージ管理|配信対象')

      this.onClickGetMessageQueuesId(messageQueueId, status)
    },

    /**
     * 配信対象を取得する
     * @param {number} messageQueueId メッセージキューID
     */
    updateMessageQueue(messageQueueId: number, action: string) {
      TrackingService.sendEvent(`click:当選メッセージの管理|メッセージ管理|${action}`)

      const isCopy = action === '複製'

      this.onClickMessageQueueEdit(messageQueueId, isCopy)
    },

    /**
     * メッセージキューを削除
     * @param {number} messageQueueId メッセージキューID
     */
    async deleteMessageQueue(messageQueueId: number) {
      TrackingService.sendEvent('click:当選メッセージの管理|メッセージ管理|削除')

      const confirm = window.confirm(this.$gettext('メッセージ配信スケジュールを削除してもよろしいですか?'))
      if (!confirm) {
        return
      }
      const result = await api.delete('/twitter_message_queues/' + messageQueueId)

      if (result.error && result.error.type === 'NOT_EXISTS') {
        this.$notify({
          title: this.$gettext('対象のメッセージ配信スケジュールはすでに削除されています。'),
          customClass: 'danger',
          duration: 5000
        })
        if (typeof this.onChange === 'function') {
          this.onChange()
        }
        return
      }

      // エラーがある場合はエラーメッセージを表示
      if (!result.data) {
        this.$notify({
          title: this.$gettext('メッセージ配信スケジュールの削除に失敗しました。'),
          message: this.$gettext('恐れ入りますが、時間を置いて再度お試しください。'),
          customClass: 'danger',
          duration: 5000
        })
        return
      }

      // 成功メッセージを表示
      this.$notify({
        title: this.$gettext('メッセージ配信スケジュールを削除しました。'),
        customClass: 'success',
        duration: 5000
      })

      if (typeof this.onChange === 'function') {
        this.onChange()
      }
    },

    /**
     * メッセージ配信停止
     * @param {number} id
     * @returns {void}
     */
    async stopSend(id: number) {
      let message = `${this.$gettext('メッセージの配信を停止してもよろしいですか？')}\n`
      message += this.$gettext('配信停止の取り消しは行なえません。')

      const confirm = window.confirm(message)

      if (!confirm) {
        return
      }

      const params = {
        campaignId: this.campaignId,
        twCampaignPrizeId: this.prizeId,
        isSendStop: 1
      }
      const result = await api.put(`/twitter_message_queues/${id}`, params)

      // エラーがある場合はエラーメッセージを表示
      if (!result.data) {
        this.$notify({
          title: this.$gettext('配信停止に失敗しました。'),
          message: this.$gettext('恐れ入りますが、時間をおいて再度お試しください。'),
          customClass: 'danger',
          duration: 5000
        })
        return
      }

      // 成功メッセージを表示
      this.$notify({
        title: this.$gettext('メッセージを配信停止しました。'),
        message: this.$gettext('配信停止まで、5分ほどかかる場合がございます。'),
        customClass: 'success',
        duration: 5000
      })

      if (typeof this.onChange === 'function') {
        this.onChange()
      }
    },

    /**
     * ヘッダーをクリックするとソートする
     * @param {*} column
     */
    headerClick(column: any) {
      switch (column.label) {
        case 'ステータス':
          TrackingService.sendEvent('sort:当選メッセージの管理|メッセージ管理|ステータス')
          break
        case '種別':
          TrackingService.sendEvent('sort:当選メッセージの管理|メッセージ管理|種別')
          break
        case '配信方法':
          TrackingService.sendEvent('sort:当選メッセージの管理|メッセージ管理|配信方法')
          break
        case '配信開始日時':
          TrackingService.sendEvent('sort:当選メッセージの管理|メッセージ管理|配信開始日時')
          break
        default:
          break
      }

      if (!column.sortBy) {
        return
      }

      if (column.sortBy.indexOf('-') === 0) {
        if (this.options.sortBy === column.sortBy) {
          this.onChangeSortBy(column.sortBy.substring(1))
        } else {
          this.onChangeSortBy(column.sortBy)
        }
      } else {
        if (this.options.sortBy === column.sortBy) {
          this.onChangeSortBy('-' + column.sortBy)
        } else {
          this.onChangeSortBy(column.sortBy)
        }
      }
    },
    /**
     * ヘッダー描画
     * @param {*} createElement
     * @param {*} { column }
     */
    renderHeader(createElement: any, { column }: any) {
      return Util.renderHeader(createElement, column.label, Util.getSortClass(this.options.sortBy, column.sortBy))
    },
    /**
     * ステータスをチェック
     * @param {string} status
     */
    isUnsent(status: string) {
      return status === 'scheduled' || status === 'draft'
    }
  }
}
