/* @flow */
import { mapActions, mapState } from 'vuex'

import Panel from '@/client/components/_atoms/Panel.vue'
import FormItemLabel from '@/client/components/_molecules/FormItemLabel.vue'
import EditableComponent from '@/client/components/basic/editable/editable.vue'
import MessageConvert from '@/client/components/basic/message_convert'
import TagInput from '@/client/components/basic/tag_input'
import {
  CAMPAIGN_STATUS,
  WEB_INSTANTWIN_DEFAULT_LOADING_MESSAGE,
  WEB_INSTANTWIN_MEDIA_LOADING_URL
} from '@/client/constant'
import api from '@/client/core/api'
import TwitterUpload from '@/client/core/twitter_upload'
import { TrackingService } from '@/client/services'

export default {
  props: {
    prizeCount: Number
  },
  components: {
    TagInput,
    Panel,
    FormItemLabel,
    MessageConvert,
    EditableComponent
  },
  data() {
    return {
      title: '',
      visible: false,
      loading: false,
      isLoading: false,
      campaignPrize: {
        id: 0,
        campaignId: 0,
        name: '',
        maxWinnerCount: 0,
        oldMaxWinnerCount: 0,
        dailyWinnerCount: 0,
        winningRateChangeType: 'none',
        winningRate: 0,
        isDailyLottery: false,
        dailyWinnerChangeTime: '00:00',
        entryKeywords: [],
        commentKeywords: [],
        pageLoadingMediaType: 0,
        pageLoadingMediaUrl: WEB_INSTANTWIN_MEDIA_LOADING_URL,
        pageLoadingText: WEB_INSTANTWIN_DEFAULT_LOADING_MESSAGE
      },
      rawCampaignPrizeData: {},
      errorEntryKeywordMessage: '',
      isSubmitting: false,
      isSubmited: false,
      timePickerOption: {
        start: '00:00',
        step: '01:00',
        end: '23:00'
      }
    }
  },
  computed: {
    ...mapState('twitterCampaignPrize', {
      campaign: state => {
        if (state.campaign) {
          return state.campaign
        }
        return {}
      },
      searchKeyword: state => {
        if (state.campaign && state.campaign.setting) {
          return JSON.parse(state.campaign.setting.searchKeyword)
        }
        return null
      },
      orKeyword: state => {
        if (state.campaign && state.campaign.setting && state.campaign.setting.orKeyword) {
          return JSON.parse(state.campaign.setting.orKeyword)
        }
        return null
      },
      commentKeyword: state => {
        if (state.campaign && state.campaign.setting && state.campaign.setting.commentKeyword) {
          return JSON.parse(state.campaign.setting.commentKeyword)
        }
        return null
      }
    }),

    isFormValid() {
      if (!this.campaignPrize.name || !this.campaignPrize.pageLoadingText) {
        return false
      }

      return true
    },

    isCheckEmptyNameText() {
      return !this.campaignPrize.name
    },
    isCheckEmptyLoadingText() {
      return !this.campaignPrize.pageLoadingText
    },

    isCampaignFinished() {
      return [CAMPAIGN_STATUS.DURING_FINAL_COLLECTION, CAMPAIGN_STATUS.COLLECTION_COMPLETED].includes(
        this.campaign.status
      )
    },

    isDeleteDisabled() {
      return this.isCampaignFinished || this.prizeCount === 1
    },

    isFormChanged(): boolean {
      return JSON.stringify(this.campaignPrizeData) !== JSON.stringify(this.rawCampaignPrizeData)
    },

    campaignPrizeData() {
      return this.setCampaignPrizeData()
    },

    isIncludeQuoteTweets() {
      if (
        !this.campaign ||
        !this.campaign.setting ||
        !this.campaign.setting.entryActionType ||
        this.campaign.type === 'web_instantwin'
      ) {
        return false
      }

      return ['retweet_and_quote_tweet', 'quote_tweet'].includes(this.campaign.setting.entryActionType)
    },

    quoteKeywordText() {
      if (!this.campaign || !this.campaign.setting || !this.campaign.setting.entryActionType) {
        return ''
      }

      return this.campaign.setting.entryActionType === 'quote_tweet'
        ? '引用のコメントに以下のキーワードを含む'
        : '引用の場合、コメントに以下のキーワードを含む'
    },

    isUploadMediaLoadingType() {
      return !!this.campaignPrize.pageLoadingMediaType
    }
  },
  methods: {
    ...mapActions('twitterCampaignPrize', ['fetchPrizes', 'fetchCampaign']),

    /**
     * メディアファイル削除
     */
    async removeMedia() {
      this.campaignPrize.pageLoadingMediaUrl = null
      this.campaignPrize.pageLoadingMediaType = 0
    },

    /**
     * ファイルを変更
     */
    async handleFileChange(e) {
      TrackingService.sendEvent('click:応募グループ一覧|応募グループの編集(X)|アップロード')

      if (!e.target.files || !e.target.files[0]) {
        return
      }

      this.isLoading = true

      const validFormat = ['video/mp4', 'image/jpeg', 'image/png', 'image/gif', 'image/pjpeg', 'image/gif']

      // メディアをチェック

      const file = e.target.files[0]

      const checkValidMedia = await TwitterUpload.checkValidMedia(file, validFormat)

      if (checkValidMedia.error) {
        const error = checkValidMedia.error

        let title = ''
        let message = ''

        switch (error) {
          case 'INVALID_MEDIA':
            title = this.$gettext('Please upload valid format!')
            message = validFormat.join(', ')
            break
          case 'MAX_SIZE_VIDEO':
            title = this.$gettext(
              '25MB以上のビデオを利用することはできません。 サイズを調整してからアップロードしてください。'
            )
            break
          case 'MAX_SIZE_IMAGE':
            title = this.$gettext(
              '5MB以上の画像を利用することはできません。サイズを調整してからアップロードしてください。'
            )
            break
          case 'INVALID_DIMENSION':
            title = this.$gettext('画像・動画アップロードに失敗しました。')
            message = this.$gettext(
              '詳しくは<a target="_blank" href="https://help-atelu.comnico.jp/available-image-and-video">こちら</a>をご確認ください。'
            )
            break
        }

        this.pageLoadingMediaType = null
        this.showError(title, message)

        return
      }

      const formData = new FormData()
      formData.append('file', file)

      const data = await api.post('/media_uploads', formData, {
        headers: {
          'Content-Type': undefined
        }
      })

      this.page_main_image_url = data.data.media_url

      const mediaType = file.type === 'video/mp4' ? 1 : 0

      this.campaignPrize.pageLoadingMediaUrl = data.data.media_url
      this.campaignPrize.pageLoadingMediaType = mediaType

      this.isLoading = false
    },

    /**
     * ページ落選時テキストを設定
     */
    async setPageLoadingText(value: string) {
      TrackingService.sendEvent('input:応募グループ一覧|応募グループの編集(X)|抽選中テキスト')
      this.campaignPrize.pageLoadingText = value
    },

    /**
     * Set campaign prize data
     * @returns {Object} campaign prize data
     */
    setCampaignPrizeData() {
      return {
        campaignId: this.campaignPrize.campaignId,
        name: this.campaignPrize.name,
        maxWinnerCount: this.campaignPrize.maxWinnerCount,
        oldMaxWinnerCount: this.campaignPrize.maxWinnerCount,
        dailyWinnerCount: this.campaignPrize.dailyWinnerCount,
        winningRateChangeType: this.campaignPrize.winningRateChangeType,
        winningRate: this.campaignPrize.winningRateChangeType === 'auto' ? 0 : this.campaignPrize.winningRate,
        isDailyLottery: this.campaignPrize.isDailyLottery,
        dailyWinnerChangeTime: this.campaignPrize.dailyWinnerChangeTime,
        entryKeyword: JSON.stringify(this.campaignPrize.entryKeywords),
        commentKeyword: JSON.stringify(this.campaignPrize.commentKeywords),
        pageLoadingMediaType: this.campaignPrize.pageLoadingMediaType,
        pageLoadingMediaUrl: this.campaignPrize.pageLoadingMediaUrl ?? WEB_INSTANTWIN_MEDIA_LOADING_URL,
        pageLoadingText: this.campaignPrize.pageLoadingText ?? WEB_INSTANTWIN_DEFAULT_LOADING_MESSAGE
      }
    },

    /**
     * Get data from api
     */
    async getCampaignPrize() {
      this.loading = true
      const result = await api.get('/twitter_campaign_prizes/' + this.campaignPrize.id)
      this.loading = false
      const campaignPrize = result.data

      if (!campaignPrize) {
        this.$notify({
          title: this.$gettext('対象の応募グループはすでに削除されています。'),
          customClass: 'danger',
          duration: 5000
        })
        this.close()
        return
      }

      this.campaignPrize = {
        id: campaignPrize.id,
        campaignId: campaignPrize.campaignId,
        name: campaignPrize.name,
        maxWinnerCount: campaignPrize.maxWinnerCount,
        oldMaxWinnerCount: campaignPrize.maxWinnerCount,
        dailyWinnerCount: campaignPrize.dailyWinnerCount ?? 0,
        winningRateChangeType: campaignPrize.winningRateChangeType ?? 'none',
        winningRate: campaignPrize.winningRate ?? 0,
        isDailyLottery: campaignPrize.isDailyLottery ?? false,
        dailyWinnerChangeTime: campaignPrize.dailyWinnerChangeTime
          ? campaignPrize.dailyWinnerChangeTime.substring(0, 5)
          : null,
        pageLoadingMediaUrl: campaignPrize.pageLoadingMediaUrl ?? WEB_INSTANTWIN_MEDIA_LOADING_URL,
        pageLoadingText: campaignPrize.pageLoadingText ?? WEB_INSTANTWIN_DEFAULT_LOADING_MESSAGE,
        pageLoadingMediaType: campaignPrize.pageLoadingMediaType,
        entryKeywords: campaignPrize.entryKeyword ? JSON.parse(campaignPrize.entryKeyword) : [],
        commentKeywords: campaignPrize.commentKeyword ? JSON.parse(campaignPrize.commentKeyword) : []
      }
    },

    async createOrUpdate() {
      if (this.campaignPrize.id) {
        TrackingService.sendEvent('click:応募グループ一覧|応募グループの編集(X)|保存')

        await this.updateCampaignPrize()
      } else {
        await this.createCampaignPrize()
      }
      this.close()
    },

    /**
     * 応募グループの新規作成
     */
    async createCampaignPrize() {
      this.isSubmitting = true
      if (!this.isFormValid) {
        return
      }

      // キャンペーンを保存する
      const result = await api.post('/twitter_campaign_prizes', this.campaignPrizeData)

      setTimeout(() => {
        this.isSubmitting = false
      }, 2000)

      if (result && result.data) {
        // 成功メッセージを表示
        this.$notify({
          title: this.$gettext('応募グループを作成しました。'),
          customClass: 'success',
          duration: 5000
        })
        return
      }

      this.$notify({
        title: this.$gettext('応募グループの新規作成に失敗しました。'),
        message: this.$gettext('恐れ入りますが、時間をおいて再度お試しください。'),
        customClass: 'danger',
        duration: 5000
      })
    },

    /**
     * 応募グループの編集
     */
    async updateCampaignPrize() {
      this.isSubmitting = true
      if (!this.isFormValid) {
        return
      }

      // キャンペーンを保存する
      const result = await api.put('/twitter_campaign_prizes/' + this.campaignPrize.id, this.campaignPrizeData)

      setTimeout(() => {
        this.isSubmitting = false
      }, 2000)

      if (result && result.data) {
        // 成功メッセージを表示
        this.$notify({
          title: this.$gettext('応募グループを保存しました。'),
          customClass: 'success',
          duration: 5000
        })

        return
      }

      if (result.error && result.error.type === 'NOT_EXISTS') {
        this.$notify({
          title: this.$gettext('対象の応募グループはすでに削除されています。'),
          customClass: 'danger',
          duration: 5000
        })

        return
      }

      this.$notify({
        title: this.$gettext('応募グループの保存に失敗しました。'),
        message: this.$gettext('恐れ入りますが、時間をおいて再度お試しください。'),
        customClass: 'danger',
        duration: 5000
      })
    },

    /**
     * 応募グループの削除
     */
    async deleteCampaignPrize() {
      TrackingService.sendEvent('click:応募グループ一覧|応募グループの編集(X)|削除')

      const confirmDelete = confirm(this.$gettext('応募グループを削除してよろしいですか？'))
      if (!confirmDelete) {
        return
      }

      const result = await api.delete('/twitter_campaign_prizes/' + this.campaignPrize.id)

      if (result && result.data) {
        this.$notify({
          title: this.$gettext('応募グループを削除しました。'),
          customClass: 'success',
          duration: 5000
        })
        this.close()
        return
      }

      if (result.error && result.error.type === 'NOT_EXISTS') {
        this.$notify({
          title: this.$gettext('対象の応募グループはすでに削除されています。'),
          customClass: 'danger',
          duration: 5000
        })
        this.close()
        return
      }

      this.$notify({
        title: this.$gettext('応募グループの削除に失敗しました。'),
        message: this.$gettext('恐れ入りますが、時間をおいて再度お試しください。'),
        customClass: 'danger',
        duration: 5000
      })
      this.close()
    },

    /**
     * Execute when max winner count is changed
     * @param {number} maxWinnerCount
     */
    onChangeMaxWinnerCount(maxWinnerCount: number) {
      if (maxWinnerCount === this.campaignPrize.oldMaxWinnerCount + 1) {
        TrackingService.sendEvent('click:応募グループ一覧|応募グループの編集(X)|当選数|UP')
      } else if (maxWinnerCount === this.campaignPrize.oldMaxWinnerCount - 1) {
        TrackingService.sendEvent('click:応募グループ一覧|応募グループの編集(X)|当選数|DOWN')
      } else TrackingService.sendEvent('input:応募グループ一覧|応募グループの編集(X)|当選数')

      this.campaignPrize.oldMaxWinnerCount = this.campaignPrize.maxWinnerCount

      if (!maxWinnerCount) {
        setTimeout(() => {
          this.campaignPrize.maxWinnerCount = 0
        }, 100)
      }

      if (maxWinnerCount < this.campaignPrize.dailyWinnerCount) {
        setTimeout(() => {
          this.campaignPrize.dailyWinnerCount = 0
        }, 100)
      }
    },

    /**
     * Execute when daily winner count is changed
     * @param {number} dailyWinnerCount
     */
    onChangeDailyWinnerCount(dailyWinnerCount: number) {
      if (!dailyWinnerCount) {
        setTimeout(() => {
          this.campaignPrize.dailyWinnerCount = 0
        }, 100)

        return
      }

      if (dailyWinnerCount > this.campaignPrize.maxWinnerCount) {
        setTimeout(() => {
          this.campaignPrize.dailyWinnerCount = 0
        }, 100)
      }
    },

    onChangeWinningRate(winningRate: number) {
      if (!winningRate) {
        setTimeout(() => {
          this.campaignPrize.winningRate = 0
        }, 100)
      }
    },

    /**
     * Valid entry keyword
     * @param {string[]} keywords list keyword
     */
    onChangeEntryKeywords(keywords: string[]) {
      const origin = [...this.campaignPrize.entryKeywords]

      if (!this.isValidKeyword(keywords, origin)) {
        this.campaignPrize.entryKeywords = origin
        return
      }
      this.errorEntryKeywordMessage = ''
      this.campaignPrize.entryKeywords = keywords
    },

    /**
     * コメントキーワードの更新
     */
    onChangeCommentKeywords(keywords: string[]) {
      const origin = [...this.campaignPrize.commentKeywords]

      if (!this.isValidKeyword(keywords, origin)) {
        this.campaignPrize.commentKeywords = origin
        return
      }
      this.errorEntryKeywordMessage = ''
      this.campaignPrize.commentKeywords = keywords
    },

    /**
     * Check if keyword is valid
     * @param {string[]} keywords list keyword
     * @param {string} origin
     * @returns {boolean}
     */
    isValidKeyword(keywords: string[], origin: string[]): boolean {
      const keyword = keywords.join('')
      if (keyword.length > 120) {
        this.errorEntryKeywordMessage = this.$gettext('応募キーワードの合計を120文字以下にしてください。')

        this.$notify({
          title: this.errorEntryKeywordMessage,
          customClass: 'danger',
          duration: 5000
        })

        return false
      }

      if (origin.length > keywords.length) {
        return true
      }

      if (origin.includes(keywords[keywords.length - 1])) {
        return false
      }

      return true
    },

    /**
     * ダイアログを開く
     */
    async open(campaignId: number, id: number) {
      this.visible = true
      if (id) {
        this.campaignPrize.id = id
        this.title = this.$gettext('応募グループの編集')
        await this.getCampaignPrize()
      } else {
        this.title = this.$gettext('応募グループの新規作成')
      }

      this.campaignPrize.campaignId = campaignId
      this.rawCampaignPrizeData = this.setCampaignPrizeData()
    },

    /**
     * Close dialog after submit
     * @returns {Promise<void>} void
     */
    async close(): Promise<void> {
      this.isSubmited = true
      this.visible = false
      await this.fetchPrizes()
      await this.fetchCampaign()
    },

    /**
     * Close dialog
     * @returns {void}: void
     */
    hide(): void {
      TrackingService.sendEvent('click:応募グループ一覧|応募グループの編集(X)|閉じる')

      this.resetForm()
      this.visible = false
      this.loading = false
      this.isSubmited = false
    },

    resetForm() {
      this.campaignPrize = {
        id: 0,
        campaignId: 0,
        name: '',
        maxWinnerCount: 0,
        dailyWinnerCount: 0,
        winningRateChangeType: 'none',
        winningRate: 0,
        isDailyLottery: false,
        entryKeywords: [],
        commentKeywords: [],
        pageLoadingMediaType: 0,
        pageLoadingMediaUrl: WEB_INSTANTWIN_MEDIA_LOADING_URL,
        pageLoadingText: WEB_INSTANTWIN_DEFAULT_LOADING_MESSAGE
      }
      this.errorEntryKeywordMessage = ''
    }
  }
}
