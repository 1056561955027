import moment from 'moment'
import { mapActions, mapState } from 'vuex'

import CsvDownload from '@/client/components/basic/csv_download/csv_download'
import InstagramPrizeDialog from '@/client/components/instagram_campaign_prize/instagram_prize_dialog'
import InstagramPrizeTable from '@/client/components/instagram_campaign_prize/instagram_prize_table'
import { CAMPAIGN_STATUS } from '@/client/constant'
import api from '@/client/core/api'
import util from '@/client/core/util'
import { TrackingService } from '@/client/services'

export default {
  components: {
    CsvDownload,
    InstagramPrizeTable,
    InstagramPrizeDialog
  },
  data: () => {
    return {
      isLeave: false
    }
  },
  computed: {
    ...mapState('system', {
      currentGroupId: state => state.currentGroup.id
    }),
    ...mapState('instagramCampaignPrize', {
      prizes: state => state.prizes,
      error: state => state.error,
      params: state => state.params,
      loaded: state => state.firstLoaded,
      campaignInfo: state => state.campaignInfo,
      campaign: state => {
        if (state.campaign) {
          return state.campaign
        }
        return {}
      },
      options: state => {
        return {
          total: state.total,
          pagingNo: state.params.pagingNo,
          limitCount: state.params.limitCount,
          sortBy: state.params.sortBy
        }
      }
    }),

    lastProcessedDatetime() {
      if (!this.campaign.lastProcessedDatetime) {
        return '-'
      }

      return moment(this.campaign.lastProcessedDatetime).format('YYYY年MM月DD日 HH:mm')
    },

    campaignStartDatetime() {
      return moment(this.campaign.startDatetime).format('YYYY年MM月DD日 HH:mm')
    },

    collectCompletedAt() {
      // 分析バッチの実行完了を考慮し、キャンペーン終了時刻の1時間後を表示する
      return moment(this.campaign.endDatetime)
        .add(1, 'hours')
        .format('YYYY年MM月DD日 HH:mm')
    },

    isCampaignBeforeStart() {
      return this.campaign.status === CAMPAIGN_STATUS.BEFORE_EVENT
    },

    isCampaignBeforeCollect() {
      return this.campaign.status === CAMPAIGN_STATUS.BEFORE_COLLECTION
    },

    isCampaignFinalCollection() {
      return this.campaign.status === CAMPAIGN_STATUS.DURING_FINAL_COLLECTION
    },

    isCampaignCollectionCompleted() {
      return this.campaign.status === CAMPAIGN_STATUS.COLLECTION_COMPLETED
    }
  },
  async mounted() {
    const params = {
      campaignId: this.$route.params.campaignId
    }

    this.setParams(params)

    await this.fetchCampaign()

    if (this.isLeave || !this.campaign || !this.campaign.id) {
      return
    }

    if (this.currentGroupId !== this.campaign.groupId) {
      this.setCurrentGroup(this.campaign.groupId)
    }

    this.setBreadcrumb([
      {
        name: this.$gettext('キャンペーン一覧'),
        link: '/campaigns'
      },
      {
        name: this.campaign.title,
        link: null,
        iconName: this.campaign.account ? this.campaign.account.name : '削除されたアカウント',
        iconUrl: this.campaign.account ? this.campaign.account.pictureUrl : null,
        iconType: this.campaign.snsType,
        isIcon: true
      }
    ])
  },
  watch: {
    params() {
      this.fetchPrizes()
    }
  },
  methods: {
    ...mapActions('system', ['setCurrentGroup']),
    ...mapActions('instagramCampaignPrize', ['fetchPrizes', 'setParams', 'fetchCampaign', 'reset']),
    ...mapActions('navbar', ['setBreadcrumb']),

    /**
     * CSVダウンロード
     */
    async downloadCsv() {
      TrackingService.sendEvent('click:応募グループ一覧（IG）|CSVダウンロード')

      const params = Object.assign({}, this.params, {
        forCsv: 1
      })
      const result = await api.get('/instagram_campaign_prizes', {
        params
      })

      if (!result.data) {
        return {
          error: 'ERROR_LOADING'
        }
      }

      const prizes = result.data.prizes
      const filename = `campaign_prize_${this.campaign.title}_applicants_${moment().format('YYYY_MM_DD')}`
      const csvData = []
      csvData.push([
        this.$gettext('応募グループ名'),
        this.$gettext('応募キーワード'),
        this.$gettext('応募者数'),
        this.$gettext('当選者数'),
        this.$gettext('当選者数(残り)')
      ])

      // CSVデータを格納
      prizes.forEach(prize => {
        csvData.push([
          prize.name,
          prize.entryKeyword,
          prize.applicantCount,
          prize.winnerCount,
          prize.maxWinnerCount - prize.winnerCount
        ])
      })

      return {
        filename,
        data: csvData
      }
    },

    /**
     * ページを変更イベント
     * @param {number} page ページ
     */
    onChangePagingNo(page: number) {
      if (this.params.pagingNo === page) {
        return
      }
      this.setParams({ pagingNo: page })
      util.scrollToTop()
    },

    /**
     * リミット数を変更イベント
     * @param {number} limitCount リミット数
     */
    onChangeLimitCount(limitCount: number) {
      if (this.params.limitCount === limitCount) {
        return
      }
      this.setParams({ limitCount })
    },

    /**
     * ソートを変更イベント
     * @param {string} sortBy ソート
     */
    onChangeSortBy(sortBy: string) {
      if (this.params.sortBy === sortBy) {
        return
      }
      this.setParams({ sortBy })
    },

    onChangeBackEvent() {
      TrackingService.sendEvent('click:応募グループ一覧（IG）|戻る')

      this.$router.push('/campaigns')
    },

    onChangeInstagramPrizeDialogEvent() {
      TrackingService.sendEvent('click:応募グループ一覧（IG）|応募グループの新規作成')

      this.$refs.instagramPrizeDialog.open(this.campaign.id)
    },

    onChangeInstagramPostEvent() {
      TrackingService.sendEvent('click:応募グループ一覧（IG）|応募履歴')

      this.$router.push('/campaigns/' + this.campaign.id + '/instagram_posts')
    }
  },
  beforeRouteLeave(to: any, from: any, next: any) {
    // キャンペーンストアをリセット
    this.reset()
    this.isLeave = true
    next()
  }
}
