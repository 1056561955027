/* @flow */

export default {
  props: {
    label: String,
    value: Array
  },
  data() {
    return {
      more: this.value[0],
      less: this.value[1]
    }
  },
  methods: {
    onChangeMore(val: string) {
      const more = this.convertNumber(val)

      this.$emit('input', [more, this.value[1]])

      this.more = more
    },
    onChangeLess(val: string) {
      const less = this.convertNumber(val)

      this.$emit('input', [this.value[0], less])

      this.less = less
    },
    convertNumber(val: string) {
      return val === '' ? val : Number(val) > 0 ? Number(val) : 0
    }
  }
}
