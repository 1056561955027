/* @flow */
import { minLength, required } from 'vuelidate/lib/validators'

import api from '@/client/core/api'

export default {
  props: {
    code: String
  },
  data() {
    return {
      password: ''
    }
  },
  created() {
    // パスワードリクエストコードをチェック
    this.checkRequestPasswordCode()
  },
  methods: {
    /**
     * ログインサブミット
     * @return {void}
     */
    submit: async function($v: any) {
      // validations
      $v.$touch()
      if ($v.$invalid === true) {
        return
      }

      const params = {
        code: this.code,
        password: this.password
      }
      const result = await api.post('/users/password_resets', params)
      // エラーがある場合は表示
      if (result.error && result.error.type === 'NOT_EXISTS') {
        this.$notify({
          title: this.$gettext('パスワードの再設定に失敗しました。'),
          message: this.$gettext('恐れ入りますが、時間をおいて再度お試しください。'),
          customClass: 'danger',
          duration: 10000
        })
        this.$router.push('/login')
        return
      }
      if (result.data !== true) {
        this.$notify({
          title: this.$gettext('パスワードの再設定に失敗しました。'),
          message: this.$gettext('恐れ入りますが、時間をおいて再度お試しください。'),
          customClass: 'danger',
          duration: 10000
        })
        return
      }

      // 成功する場合
      this.$notify({
        title: this.$gettext('パスワードを再設定しました。'),
        customClass: 'success',
        duration: 10000
      })
      this.$router.push('/login')
    },

    /**
     * パスワードリクエストコードをチェック
     */
    async checkRequestPasswordCode() {
      const result = await api.get('/users/new_password_requests', {
        params: {
          code: this.code
        }
      })
      // 存在しない場合
      if (result.error && result.error.type === 'NOT_EXISTS') {
        this.$notify({
          title: this.$gettext('パスワード再設定URLが無効です。'),
          customClass: 'danger',
          duration: 10000
        })
        this.$router.push('/login')
        return
      }

      // 有効期限が切れている場合
      if (result.error && result.error.type === 'CODE_EXPIRED') {
        this.$notify({
          title: this.$gettext('パスワードリセットの有効期限が切れています。'),
          message: this.$gettext('恐れ入りますが、再度パスワードリセットを冗長な表現になっていないか確認てください。'),
          customClass: 'danger',
          duration: 10000
        })
        this.$router.push('/login')
        return
      }

      // エラーがある場合
      if (!result.data || !result.data.userId) {
        this.$notify({
          title: this.$gettext('エラーが発生しました。'),
          message: this.$gettext('恐れ入りますが、時間をおいて再度お試しください。'),
          customClass: 'danger',
          duration: 10000
        })
        this.$router.push('/login')
      }
    }
  },
  validations: {
    password: {
      required,
      // パスワードは8文字以上が必要
      minLength: minLength(8)
    }
  }
}
