/* @flow */
import { required } from 'vuelidate/lib/validators'
import { mapActions, mapState } from 'vuex'

import api from '@/client/core/api'
import { TrackingService } from '@/client/services'

export default {
  props: {
    screen: String,
    isChangeWithoutSubmit: Boolean
  },
  data() {
    return {
      body: '',
      loading: false
    }
  },
  watch: {
    screen() {
      this.reset()
    },
    isFormChanged(value: boolean) {
      this.$emit('update:isChangeWithoutSubmit', value)
    }
  },
  computed: {
    ...mapState('twitterSerialCodeDialog', ['prizeId', 'campaignId']),
    is_import() {
      return this.screen === 'import'
    },
    is_used_code_reset() {
      return this.screen === 'used_code_reset'
    },
    is_code_reset() {
      return this.screen === 'code_reset'
    },
    isFormChanged() {
      return this.body !== ''
    },
    maxSerialCodeError() {
      if (!this.body.trim()) {
        return false
      }

      return this.body.trim().split('\n').length > 10000
    },
    disabled() {
      return !this.body || !this.body.trim() || this.maxSerialCodeError || this.loading
    }
  },
  methods: {
    ...mapActions('twitterSerialCodeDialog', ['fetchSerialCodeList']),

    /**
     * 一覧に戻る
     */
    back() {
      TrackingService.sendEvent(
        `click:当選メッセージの管理|${this.screen === 'used_code_reset' ? '使用済み' : '未割り当て'}シリアルコード|戻る`
      )

      if (!this.isChangeWithoutSubmit) {
        return this.$emit('back')
      }

      const confirm = window.confirm(this.$gettext('編集内容が保存されていません。ダイアログを閉じてよろしいですか?'))

      if (!confirm) {
        return
      }

      this.$emit('update:isChangeWithoutSubmit', false)
      this.$emit('back')
    },

    /**
     * 使用済みコードのインポート
     */
    async importUsedCodes() {
      const serialCodes = this.body.split('\n').filter(code => code)

      let confirmMessage = this.$gettextInterpolate(this.$gettext('%{count}件の使用済みコードをインポートします。'), {
        count: serialCodes.length
      })

      confirmMessage += `\n${this.$gettext('処理を実行してよろしいですか?')}`
      confirmMessage += `\n${this.$gettext('この操作は取り消しできません。')}`
      const confirm = window.confirm(confirmMessage)

      if (!confirm) {
        return
      }

      this.loading = true

      const result = await api.post('/twitter_serial_codes/used_imports', {
        prizeId: this.prizeId,
        serialCodes
      })

      if (result && result.data !== undefined) {
        const title = this.$gettextInterpolate(this.$gettext('%{count}件の使用済みコードをインポートしました。'), {
          count: result.data
        })

        this.$notify({
          title,
          customClass: 'success',
          duration: 5000
        })

        this.loading = false

        this.$emit('update:isChangeWithoutSubmit', false)
        this.fetchSerialCodeList()
        this.$nextTick(() => {
          this.back()
        })
        return
      }

      const title = this.$gettext('使用済みコードのインポートに失敗しました。')
      const message = this.$gettext('恐れ入りますが、時間を置いて再度お試しください。')

      this.$notify({
        title,
        message,
        customClass: 'danger',
        duration: 5000
      })

      this.loading = false
    },

    /**
     * 使用済みコード解除
     */
    async resetUsedCode() {
      TrackingService.sendEvent('click:当選メッセージの管理|使用済みシリアルコード|一括解除を実行')

      this.loading = true

      const result = await api.post('/twitter_serial_codes/used_resets', {
        prizeId: this.prizeId
      })

      if (result && result.data) {
        const title = this.$gettext('使用済みシリアルコードの割り当てを一括解除しました。')

        this.$notify({
          title,
          customClass: 'success',
          duration: 5000
        })

        this.loading = false
        this.fetchSerialCodeList()
        this.back()
        return
      }

      const title = this.$gettext('使用済みシリアルコードの割り当て解除に失敗しました。')
      const message = this.$gettext('恐れ入りますが、時間をおいて再度お試しください。')

      this.$notify({
        title,
        message,
        customClass: 'danger',
        duration: 5000
      })

      this.loading = false
    },

    /**
     * 未割り当てコード削除
     */
    async resetCode() {
      TrackingService.sendEvent('click:当選メッセージの管理|未割り当てシリアルコード|一括解除を実行')

      this.loading = true

      const result = await api.post('/twitter_serial_codes/resets', {
        prizeId: this.prizeId
      })

      if (result && result.data) {
        const title = this.$gettext('シリアルコードを削除しました。')

        this.$notify({
          title,
          customClass: 'success',
          duration: 5000
        })

        this.loading = false
        this.fetchSerialCodeList()
        this.back()
        return
      }

      const title = this.$gettext('シリアルコードの削除に失敗しました。')
      const message = this.$gettext('恐れ入りますが、時間をおいて再度お試しください。')

      this.$notify({
        title,
        message,
        customClass: 'danger',
        duration: 5000
      })

      this.loading = false
    },

    /**
     * リセット
     */
    reset() {
      this.body = ''
    }
  },
  validations: {
    body: {
      required
    }
  }
}
