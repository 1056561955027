/* @flow */
import { mapActions, mapState } from 'vuex'

import Icon from '@/client/components/basic/icon'
import api from '@/client/core/api'
import InstagramAuth from '@/client/core/instagram_auth'

export default {
  components: {
    Icon
  },
  data() {
    return {
      visible: false,
      accountId: '',
      instagramAccounts: [],
      selectedInAccount: '',
      instagramAccessToken: ''
    }
  },
  computed: {
    ...mapState('system', {
      currentGroupId: state => state.currentGroup.id
    }),
    title() {
      return this.$gettext('キャンペーン実施アカウントの追加')
    },
    canAddAccount() {
      if (this.selectedInAccount) {
        return true
      }

      return false
    }
  },
  methods: {
    ...mapActions('settingGroup', ['fetchAccounts']),

    /**
     * キャンペーン実施INアカウントを追加
     */
    async addInstagramAccount() {
      const account = this.instagramAccounts.find(account => account.id === this.selectedInAccount)

      if (!account) {
        return
      }

      const data = {
        id: account.id,
        groupId: this.currentGroupId,
        igId: account.ig_id,
        name: account.name,
        screenName: account.username,
        pictureUrl: account.profile_picture_url,
        accessToken: this.instagramAccessToken
      }

      const result = await api.post('/instagram_campaign_accounts', data)

      if (result.error && result.error.type === 'MAX_ACCOUNT_OVER') {
        return this.$notify({
          title: this.$gettext('これ以上アカウントを追加できません。'),
          message: this.$gettext('グループの登録可能アカウント数を超えています。'),
          customClass: 'danger',
          duration: 5000
        })
      }

      if (result.error && result.error.type === 'DUPLICATE') {
        return this.$notify({
          title: this.$gettextInterpolate(this.$gettext('%{name}はすでに登録済みです。'), { name: account.name }),
          customClass: 'danger',
          duration: 5000
        })
      }

      if (result.error && result.error.type === 'FACEBOOK_PAGE_NOT_FOUND') {
        return this.$notify({
          title: this.$gettext('Instagramアカウントの情報を取得できませんでした。'),
          message: this.$gettext('管理されているFacebookページが存在しません。'),
          customClass: 'danger',
          duration: 5000
        })
      }

      if (result.error || !result.data) {
        return this.$notify({
          title: this.$gettext('アカウント追加処理に失敗しました。'),
          message: this.$gettext('恐れ入りますが、時間を置いて再度お試しください。'),
          customClass: 'danger',
          duration: 5000
        })
      }

      // アカウント一覧を取得
      this.fetchAccounts(this.currentGroupId)
      this.close()

      this.$notify({
        title: this.$gettext('アカウントを追加しました。'),
        message: this.$gettextInterpolate(this.$gettext('%{name}を追加しました。'), { name: account.name }),
        customClass: 'success',
        duration: 5000
      })
    },

    /**
     * Instagram2回目の認証
     */
    async startInstagramAuth() {
      const data = await InstagramAuth.getAccounts()

      if (!data || !data.accounts || data.error) {
        return this.$notify({
          title: this.$gettext('Facebook認証に失敗しました。'),
          message: this.$gettext('恐れ入りますが時間を置いて再度お試しください。'),
          customClass: 'danger',
          duration: 5000
        })
      }

      this.instagramAccessToken = data.token

      const accountIds = data.accounts.map(account => account.id)
      const existedAccounts = await api.get('/instagram_campaign_accounts/exists', {
        params: { accountId: accountIds.join(',') }
      })

      this.instagramAccounts = data.accounts
        .map(account => {
          account.existed = existedAccounts.data.indexOf(account.id) !== -1
          return account
        })
        .sort((accountA, accountB) => {
          if (accountA.existed) {
            return 1
          }
          return -1
        })

      this.visible = true
    },

    /**
     * Instagramアカウントトークンの更新
     */
    async updateInstagramAccountToken() {
      const accountToken = await this.getInstagramAccountToken()

      if (!accountToken) {
        this.close()
        return
      }

      const account = accountToken.account
      if (!account) {
        this.$notify({
          title: this.$gettext('認証したアカウントが違うため、アクセストークンの更新に失敗しました。'),
          customClass: 'danger',
          duration: 5000
        })
        this.close()
        return
      }

      const result = await api.put(`/instagram_campaign_accounts/${this.accountId}`, {
        accessToken: accountToken.token,
        name: account.name,
        screenName: account.username,
        pictureUrl: account.profile_picture_url
      })
      if (result.error || !result.data) {
        this.$notify({
          title: this.$gettext(
            'アクセストークン更新処理に失敗しました。恐れ入りますが時間を置いて再度お試しください。'
          ),
          customClass: 'danger',
          duration: 5000
        })
        this.close()
        return
      }

      // アカウント一覧を取得
      this.fetchAccounts(this.currentGroupId)
      this.close()

      this.$notify({
        title: this.$gettextInterpolate(this.$gettext('%{name}のアクセストークンを更新しました。'), {
          name: account.name
        }),
        customClass: 'success',
        duration: 5000
      })
    },

    /**
     * Instagramアカウントトークンを取得
     */
    async getInstagramAccountToken() {
      const data = await InstagramAuth.getAccounts()

      if (!data || !data.accounts || data.error) {
        this.$notify({
          title: this.$gettext('Facebook認証に失敗しました。'),
          message: this.$gettext('恐れ入りますが時間を置いて再度お試しください。'),
          customClass: 'danger',
          duration: 5000
        })

        return null
      }

      const accounts = data.accounts
      const updateAccount = accounts.find(account => account.id === this.accountId)

      return { token: data.token, account: updateAccount }
    },

    /**
     * ダイアログをあく
     */
    async open(options: { type: 'add' | 'update', accountId: string }) {
      this.accountId = options.accountId || ''
      this.selectedInAccount = ''
      this.instagramAccessToken = ''
      this.instagramAccounts = []

      if (options.type === 'add') {
        await this.startInstagramAuth()
        return
      }

      await this.updateInstagramAccountToken()
    },

    /**
     * ダイアログを閉じる
     */
    close() {
      this.visible = false
    }
  }
}
