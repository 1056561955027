/* @flow */
import { mapState } from 'vuex'

import Icon from '@/client/components/basic/icon'
import Pagination from '@/client/components/basic/pagination'
import InstagramPrizeDialog from '@/client/components/instagram_campaign_prize/instagram_prize_dialog'
import Util from '@/client/core/util'
import { TrackingService } from '@/client/services'

export default {
  props: {
    prizes: Array,
    options: Object,
    onChangePagingNo: Function,
    onChangeLimitCount: Function,
    onChangeSortBy: Function
  },
  components: {
    Icon,
    Pagination,
    InstagramPrizeDialog
  },
  computed: {
    ...mapState('instagramCampaignPrize', {
      campaign: state => {
        if (state.campaign) {
          return state.campaign
        }
        return {}
      }
    })
  },
  methods: {
    /**
     * ヘッダーをクリックするとソートする
     * @param {*} column
     */
    headerClick(column: any) {
      switch (column.label) {
        case '応募グループ名':
          TrackingService.sendEvent('sort:応募グループ一覧（IG）|応募グループ名')
          break
        case '応募キーワード':
          TrackingService.sendEvent('sort:応募グループ一覧（IG）|応募キーワード')
          break
        case '応募者数':
          TrackingService.sendEvent('sort:応募グループ一覧（IG）|応募者数')
          break
        case '当選者数':
          TrackingService.sendEvent('sort:応募グループ一覧（IG）|当選者数')
          break
        default:
          break
      }

      if (!column.sortBy) {
        return
      }
      if (column.sortBy.indexOf('-') === 0) {
        if (this.options.sortBy === column.sortBy) {
          this.onChangeSortBy(column.sortBy.substring(1))
        } else {
          this.onChangeSortBy(column.sortBy)
        }
      } else {
        if (this.options.sortBy === column.sortBy) {
          this.onChangeSortBy('-' + column.sortBy)
        } else {
          this.onChangeSortBy(column.sortBy)
        }
      }
    },

    /**
     * ヘッダー描画
     * @param {*} createElement
     * @param {*} { column }
     */
    renderHeader(createElement: any, { column }: any) {
      return Util.renderHeader(createElement, column.label, Util.getSortClass(this.options.sortBy, column.sortBy))
    },

    /**
     * 応募キーワード取得
     * @param {string[]} prizeKeyword
     */
    getKeyword(prizeKeyword: string[]) {
      if (!prizeKeyword) {
        prizeKeyword = []
      }
      if (this.campaign.setting && this.campaign.setting.entryActionType !== 'comment') {
        prizeKeyword = JSON.parse(this.campaign.setting.searchKeyword).concat(prizeKeyword)
      }

      return prizeKeyword.join(' ')
    },

    openCampaignApplicant(id: number) {
      TrackingService.sendEvent('click:応募グループ一覧（IG）|グループ名')

      this.$router.push('/instagram_campaigns/' + this.campaign.id + '/prizes/' + id + '/applicants')
    },

    openCampaignDialogEdit(id: number) {
      TrackingService.sendEvent('click:応募グループ一覧（IG）|編集')

      this.$refs.instagramPrizeDialog.open(this.campaign.id, id)
    }
  }
}
