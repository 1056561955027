/* @flow */
import SignupForm from '@/client/components/invitation/signup_form'
import LoginForm from '@/client/components/login/login_form'
import api from '@/client/core/api'
import LocalStorage from '@/client/core/local_storage'

export default {
  components: {
    LoginForm,
    SignupForm
  },
  data() {
    return {
      code: '',
      invitation: {},
      invitationCheck: false
    }
  },
  async created() {
    if (!this.$route.query.code) {
      this.$router.push('/')
      return
    }
    this.code = this.$route.query.code
    const result = await api.get('/invitations', {
      params: {
        code: this.code
      }
    })
    if (!result || !result.data) {
      this.$notify({
        title: this.$gettext('招待情報が存在しません。'),
        message: this.$gettext('すでにグループに参加済みか、グループが削除されている可能性があります。'),
        customClass: 'danger',
        duration: 10000
      })
      this.$router.push('/')
      return
    }
    this.invitation = result.data
    if (!this.invitation.user || !this.invitation.user.id) {
      this.invitationCheck = true
      return
    }
    const userData = await api.get('/me')
    if (!userData.data || userData.data.id !== this.invitation.user.id) {
      this.invitationCheck = true
      return
    }

    // 招待したユーザーが既にログインした場合は招待処理を行う
    this.invitationAccept(userData.data.id)
  },
  methods: {
    /**
     * 招待処理を行う
     * @param {number} userId ユーザーID
     * @return {void}
     */
    async invitationAccept(userId: number) {
      const params = {
        code: this.invitation.code,
        isGuest: this.invitation.isGuest,
        type: 'exists_user'
      }
      const result = await api.post('/invitations/accept', params)
      if (result.error && result.error.type === 'GROUP_MAX_USER_COUNT_OVER') {
        this.$notify({
          title: this.$gettext('これ以上ユーザーを追加できません。'),
          message: this.$gettext('グループの登録可能ユーザー数を超えています。'),
          customClass: 'danger',
          duration: 10000
        })
        this.$router.push('/')
        return
      }
      if (result.error) {
        this.$notify({
          title: this.$gettext('グループ参加処理に失敗しました。'),
          customClass: 'danger',
          duration: 10000
        })
        this.$router.push('/')
        return
      }

      if (result.data) {
        this.$notify({
          title: this.$gettextInterpolate(this.$gettext('グループ %{groupName} に参加しました。'), {
            groupName: this.invitation.group.name
          }),
          customClass: 'success',
          duration: 5000
        })
        // ローカルストレージに現在のグループを保存する
        let currentGroupLocal = LocalStorage.getCurrentGroup()
        if (!currentGroupLocal) {
          currentGroupLocal = {}
        }
        currentGroupLocal[userId] = this.invitation.group.id
        LocalStorage.setCurrentGroup(currentGroupLocal)
        // 契約情報入力画面に移動する
        this.$router.push('/settings/group_billing')
        return
      }

      // エラーを表示する
      this.$notify({
        title: this.$gettext('グループ参加処理に失敗しました。'),
        customClass: 'danger',
        duration: 10000
      })
    }
  }
}
