



































































































































































import moment from 'moment-timezone'
import { Component, Emit, Vue } from 'vue-property-decorator'
import { mapActions, mapState } from 'vuex'

import Icon from '@/client/components/_atoms/Icon.vue'
import Message from '@/client/components/_atoms/Message.vue'
import Panel from '@/client/components/_atoms/Panel.vue'
import Account from '@/client/components/_molecules/Account.vue'
import FormItemLabel from '@/client/components/_molecules/FormItemLabel.vue'
import Keyword from '@/client/components/_molecules/Keyword.vue'
import Tags from '@/client/components/_molecules/Tags.vue'
import {
  CAMPAIGN_APPLICATION_TYPES,
  CAMPAIGN_TYPES,
  TWITTER_ENTRY_ACTION_TYPES,
  WEB_INSTANTWIN_APPLICATION_TYPES
} from '@/client/constant'
import { TrackingService } from '@/client/services'
import { CampaignDialogActions, CampaignDialogState } from '@/client/store/campaign_dialog.module'

@Component({
  components: {
    Account,
    FormItemLabel,
    Icon,
    Keyword,
    Message,
    Panel,
    Tags
  },
  computed: mapState('campaign_dialog', ['form', 'api_accounts']),
  methods: mapActions('campaign_dialog', ['createCampaign', 'prevStep'])
})
export default class CampaignCreateTwitterConfirm extends Vue {
  api_accounts!: CampaignDialogState['api_accounts']
  form!: CampaignDialogState['form']
  createCampaign!: CampaignDialogActions['createCampaign']
  prevStep!: CampaignDialogActions['prevStep']

  is_submit = false
  is_check = false

  get account() {
    const account = this.api_accounts.find(account => account.id === this.form.account_id)
    return account || { name: '', picture_url: '', target_sns: 'twitter' }
  }

  get campaign_period() {
    const start_date = this.form.start_date
      ? moment(this.form.start_date).format('YYYY年MM月DD日') + ' ' + this.form.start_time
      : ''

    const end_date = this.form.end_date
      ? moment(this.form.end_date).format('YYYY年MM月DD日') + ' ' + this.form.end_time
      : ''

    return start_date + ' - ' + end_date
  }

  get is_keyword_campaign() {
    return this.form.campaign_type === 'keyword'
  }

  get is_web_instantwin_campaign() {
    return this.form.campaign_type === 'web_instantwin'
  }

  get campaign_type() {
    const campaign_type_text = CAMPAIGN_TYPES.find(v => v.value === this.form.campaign_type)
    return campaign_type_text ? campaign_type_text.label : ''
  }

  get application_type() {
    let application_type = ''

    switch (this.form.entry_action_type) {
      case 'retweet':
      case 'quote_tweet':
      case 'retweet_and_quote_tweet':
        application_type = 'retweet'
        break
      case 'tweet':
      case 'tweet_media':
      case 'tweet_conversational':
        application_type = 'tweet'
        break
      case 'mention':
        application_type = 'mention'
        break
      case 'web_instantwin':
        application_type = 'web_instantwin'
        break
      default:
        application_type = 'instantwin'
    }

    const applicant_types = this.is_web_instantwin_campaign
      ? WEB_INSTANTWIN_APPLICATION_TYPES
      : CAMPAIGN_APPLICATION_TYPES
    const applicant_type_text = applicant_types.find(v => v.value === application_type)
    const entry_type = TWITTER_ENTRY_ACTION_TYPES.find(v => v.value === this.form.entry_action_type)
    let entry_type_text = entry_type ? entry_type.label : ''

    if (this.form.entry_action_type === 'retweet_and_quote_tweet') {
      entry_type_text = '公式リポスト&引用'
    }

    let type = applicant_type_text ? applicant_type_text.label : ''

    if (entry_type_text) {
      type += `(${entry_type_text})`
    }

    return type
  }

  get combinations() {
    if (
      (!this.form.keywords || !this.form.keywords.length) &&
      (!this.form.or_keywords || !this.form.or_keywords.length)
    ) {
      return []
    }

    return this.form.or_keywords && this.form.or_keywords.length
      ? this.form.or_keywords.map(or_keyword => [...this.form.keywords, or_keyword])
      : [this.form.keywords]
  }

  get is_display_include_mention() {
    return ['tweet', 'tweet_media'].includes(this.form.entry_action_type)
  }

  get is_display_include_reply() {
    return ['tweet', 'tweet_media', 'mention'].includes(this.form.entry_action_type)
  }

  get include_mention_text() {
    return this.form.is_include_mention ? 'ON' : 'OFF'
  }

  get include_reply_text() {
    return this.form.is_include_reply ? 'ON' : 'OFF'
  }

  get positive_keyword() {
    return this.form.positive_keyword || []
  }

  get negative_keyword() {
    return this.form.negative_keyword || []
  }

  @Emit('cancel')
  cancel(payload) {
    TrackingService.sendEvent('click:キャンペーンの新規作成|確認|キャンセル')

    return payload
  }

  @Emit('complete')
  complete(payload) {
    return payload
  }

  /**
   * 保存ボタン
   */
  async save() {
    TrackingService.sendEvent('click:キャンペーンの新規作成|確認|保存')

    if (this.is_submit) return

    this.is_submit = true

    const result = await this.createCampaign()

    setTimeout(() => {
      this.is_submit = false
    }, 2000)

    if (result.data && result.data.id) {
      this.complete(result.data.id)
      return
    }

    if (result.error && result.error.type === 'MAX_CAMPAIGN_COUNT_OVER') {
      this.$notify({
        title: 'キャンペーンの作成に失敗しました。',
        message: '同時開催可能件数を超えるため、指定の期間ではキャンペーンを開催できません。',
        customClass: 'danger'
      })
      return
    }

    if (result.error && result.error.type === 'CAMPAIGN_START_DATETIME_INVALID') {
      this.$notify({
        title: 'キャンペーンの作成に失敗しました。',
        message: 'キャンペーン開始日時は、現在日時より後を指定してください。',
        customClass: 'danger'
      })
      return
    }

    return this.$notify({
      title: 'キャンペーンの作成に失敗しました。',
      message: '恐れ入りますが、時間をおいて再度お試しください。',
      customClass: 'danger'
    })
  }
}
