/* @flow */
import Pagination from '@/client/components/basic/pagination'
import PostGridItem from '@/client/components/basic/post_grid_item'

export default {
  props: {
    posts: Array,
    options: Object
  },
  components: {
    Pagination,
    PostGridItem
  },
  methods: {
    /**
     * 候補者フラグを変更
     */
    changeIsCandidate(payload: { applicantId: number, isCandidate: number }) {
      this.$emit('change_is_candidate', payload)
    },

    /**
     * 当選者フラグを変更
     */
    changeIsWinner(payload: { prizeId: number, applicantId: number, isWinner: number }) {
      this.$emit('change_is_winner', payload)
    },

    /**
     * ページング変更イベント
     */
    onPaging(payload: { pagingNo: number, limitCount?: number }) {
      this.$emit('paging', payload)
    }
  }
}
