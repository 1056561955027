/* @flow */
import Pagination from '@/client/components/basic/pagination'
import Util from '@/client/core/util'
import { TrackingService } from '@/client/services'

export default {
  components: {
    Pagination
  },
  props: {
    billingHistories: Array,
    options: Object,
    onChangeSortBy: Function,
    onChangePagingNo: Function,
    onChangeLimitCount: Function
  },
  methods: {
    /**
     * ヘッダーをクリックするとソートする
     * @param {*} column
     */
    headerClick(column: any) {
      switch (column.label) {
        case '請求月':
          TrackingService.sendEvent('sort:グループ設定|利用実績|請求月')
          break
        case 'ユーザー数':
          TrackingService.sendEvent('sort:グループ設定|利用実績|ユーザー数')
          break
        case 'アカウント数':
          TrackingService.sendEvent('sort:グループ設定|利用実績|アカウント数')
          break
        case 'キャンペーン同時開催数':
          TrackingService.sendEvent('sort:グループ設定|利用実績|キャンペーン同時開催数')
          break
        case '収集応募者数':
          TrackingService.sendEvent('sort:グループ設定|利用実績|収集応募者数')
          break
        case 'DM配信数':
          TrackingService.sendEvent('sort:グループ設定|利用実績|DM配信数')
          break
        default:
          break
      }

      if (!column.sortBy) {
        return
      }
      if (column.sortBy.indexOf('-') === 0) {
        if (this.options.sortBy === column.sortBy) {
          this.onChangeSortBy(column.sortBy.substring(1))
        } else {
          this.onChangeSortBy(column.sortBy)
        }
      } else {
        if (this.options.sortBy === column.sortBy) {
          this.onChangeSortBy('-' + column.sortBy)
        } else {
          this.onChangeSortBy(column.sortBy)
        }
      }
    },

    /**
     * ヘッダー描画
     * @param {*} createElement
     * @param {*} { column }
     */
    renderHeader(createElement: any, { column }: any) {
      return Util.renderHeader(createElement, column.label, Util.getSortClass(this.options.sortBy, column.sortBy))
    }
  }
}
