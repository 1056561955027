


























import { Component, Vue } from 'vue-property-decorator'
import { mapActions, mapState } from 'vuex'

import Panel from '@/client/components/_atoms/Panel.vue'
import FormItemLabel from '@/client/components/_molecules/FormItemLabel.vue'
import { TrackingService } from '@/client/services'
import { CampaignDialogActions, CampaignDialogState } from '@/client/store/campaign_dialog.module'

@Component({
  components: { FormItemLabel, Panel },
  computed: mapState('campaign_dialog', ['form']),
  methods: mapActions('campaign_dialog', ['setForm'])
})
export default class CampaignCreateTitle extends Vue {
  form!: CampaignDialogState['form']
  setForm!: CampaignDialogActions['setForm']

  is_change = false

  get is_title_empty() {
    return this.is_change && !this.form.title.trim()
  }

  get is_title_max_length() {
    return !!this.form.title.trim() && this.form.title.trim().length > 50
  }

  /**
   * 変化
   */
  change() {
    this.is_change = true
  }

  /**
   * フォームタイトルを設定
   */
  async setFormTitle(value: string) {
    TrackingService.sendEvent('input:キャンペーンの新規作成|タイトル|キャンペーンタイトル')

    this.change()

    await this.setForm({ ...this.form, title: value })
  }
}
