/* @flow */
import NewPasswordForm from '@/client/components/password/new_password_form'
import NewPasswordRequestForm from '@/client/components/password/new_password_request_form'

export default {
  components: {
    NewPasswordRequestForm,
    NewPasswordForm
  },
  data() {
    return {
      mode: '',
      code: ''
    }
  },
  created() {
    this.mode = this.$route.query.mode
    this.code = this.$route.query.code
    if (this.mode !== 'reset' && this.mode !== 'requestNew') {
      this.$router.push('/login')
    }
    if (this.mode === 'reset' && !this.code) {
      this.$router.push('/login')
    }
  }
}
