/* @flow */
import moment from 'moment-timezone'
import { mapState } from 'vuex'

import Icon from '@/client/components/basic/icon'
import { CAMPAIGN_STATUS } from '@/client/constant'

export default {
  components: { Icon },
  computed: {
    ...mapState('twitterAnalyticsPublic', {
      campaign: function(state) {
        const campaign = { ...state.campaign }

        if (!campaign.account || !campaign.account.name) {
          campaign.account = {
            name: this.$gettext('削除されたアカウント'),
            pictureUrl: require('@/client/assets/images/twitter_default_profile.png')
          }
        }

        return campaign
      }
    }),

    isCampaignStartingIncludeRetweets() {
      return (
        this.campaign.status === CAMPAIGN_STATUS.DURING_COLLECTION &&
        ['keyword', 'web_instantwin'].includes(this.campaign.type) &&
        ['retweet_and_quote_tweet', 'retweet'].includes(this.campaign.entryActionType)
      )
    },

    isCampaignFinalCollection() {
      return this.campaign.status === CAMPAIGN_STATUS.DURING_FINAL_COLLECTION
    },

    isCampaignCollectionCompleted() {
      return this.campaign.status === CAMPAIGN_STATUS.COLLECTION_COMPLETED
    },

    collectCompletedAt() {
      // フォロワー取得バッチが深夜1時に実行されるため、次のバッチ実行日の午前10時を表示する
      return moment(this.campaign.endDatetime)
        .subtract(1, 'hours')
        .add(1, 'days')
        .format('YYYY年MM月DD日 10:00')
    }
  }
}
