import { defineComponent } from '@vue/composition-api'
import { mapActions, mapState } from 'vuex'

import Icon from '@/client/components/_atoms/Icon.vue'
import Panel from '@/client/components/_atoms/Panel.vue'
import FormItemLabel from '@/client/components/_molecules/FormItemLabel.vue'
import EditableComponent from '@/client/components/basic/editable/editable.vue'
import MessageConvert from '@/client/components/basic/message_convert'
import Pagination from '@/client/components/basic/pagination'
import api from '@/client/core/api'
import TwitterUpload from '@/client/core/twitter_upload'
import { TrackingService } from '@/client/services'
import { WEB_INSTANTWIN_MEDIA_WIN_URL } from '@/common/constants'

export default defineComponent<any>({
  name: 'LotteryPageWin',
  components: {
    Pagination,
    MessageConvert,
    Icon,
    Panel,
    FormItemLabel,
    EditableComponent
  },
  data() {
    return {
      isUploadMediaLoading: false,
      isMedia: true,
      isChangePageWinText: false,
      isChangePageName: false,
      highlights: [
        {
          type: 'info',
          keywords: ['_account_name_', '_screen_name_', '_account_id_', '_serial_code_']
        }
      ]
    }
  },
  computed: {
    ...mapState('lotteryPageSettingDialog', ['form']),
    isNameTextEmpty(): boolean {
      return this.isChangePageName && !this.form.name.trim()
    },
    isPageWinTextEmpty(): boolean {
      return this.isChangePageWinText && !this.form.lotteryText.trim()
    },
    isCreateWinPageDisabled(): boolean {
      return !this.form.lotteryText || !this.form.name || this.isNameTextEmpty || this.isPageWinTextEmpty
    }
  },

  methods: {
    ...mapActions('lotteryPageSettingDialog', ['setForm', 'reset', 'setMode', 'fetchLotteryPages']),

    /**
     * ファイルを変更
     */
    async handleFileChange(e: any) {
      TrackingService.sendEvent('click:当落選ページの管理|当選ページの作成|アップロード')

      if (!e.target.files || !e.target.files[0]) {
        return
      }

      this.isUploadMediaLoading = true

      const validFormat = ['video/mp4', 'image/jpeg', 'image/png', 'image/gif', 'image/pjpeg', 'image/gif']

      const file = e.target.files[0]

      const checkValidMedia = await TwitterUpload.checkValidMedia(file, validFormat)

      if (checkValidMedia.error) {
        const error = checkValidMedia.error

        switch (error) {
          case 'INVALID_MEDIA':
            this.notify('Please upload valid format!', validFormat.join(', '))
            break
          case 'MAX_SIZE_VIDEO':
            this.notify('25MB以上のビデオを利用することはできません。 サイズを調整してからアップロードしてください。')
            break
          case 'MAX_SIZE_IMAGE':
            this.notify('5MB以上の画像を利用することはできません。サイズを調整してからアップロードしてください。')
            break
          case 'INVALID_DIMENSION':
            this.notify(
              '画像・動画アップロードに失敗しました。',
              '詳しくは<a target="_blank" href="https://help-atelu.comnico.jp/available-image-and-video">こちら</a>をご確認ください。'
            )
            break
        }

        this.isMedia = false
        this.isUploadMediaLoading = false

        return
      }

      const formData = new FormData()
      formData.append('file', file)

      const data = await api.post('/media_uploads', formData, {
        headers: {
          'Content-Type': undefined
        }
      })

      this.isMedia = true

      const mediaType = file.type === 'video/mp4' ? 1 : 0

      this.setForm({
        ...this.form,
        mediaUrl: data.data.media_url,
        mediaType
      })

      this.isUploadMediaLoading = false
    },

    /**
     * メディアファイル削除
     */
    async removeMedia() {
      this.isMedia = false

      const removeData = {
        ...this.form,
        mediaUrl: null,
        mediaType: 0
      }

      await this.setForm(removeData)
    },

    /**
     * タイトルを設定
     */
    async setPageName(value: string) {
      TrackingService.sendEvent('input:当選ページの作成||当選時テキスト')
      await this.setForm({ ...this.form, name: value })
      this.change('page_name')
    },
    /**
     * ページ当選時テキストを設定
     */
    async setPageLotteryText(value: string) {
      TrackingService.sendEvent('input:当選ページの作成||当選時テキスト')
      this.change('page_win_text')
      await this.setForm({ ...this.form, lotteryText: value })
    },

    /**
     * テキストを変更チェック
     */
    async change(key: string) {
      switch (key) {
        case 'page_win_text':
          this.isChangePageWinText = true
          break
        case 'page_name':
          this.isChangePageName = true
          break
        default:
          break
      }
    },

    /**
     * ページタイトルを設定
     */
    async prevStep() {
      this.setMode('settings')
    },

    /**
     * エラーを表示
     */
    notify(title: string, message?: any, customClass = 'danger') {
      this.$notify({
        title,
        message,
        customClass,
        dangerouslyUseHTMLString: true,
        duration: 5000
      })
    },

    /**
     * ページを作成
     */
    async updateWinPage() {
      TrackingService.sendEvent('click:当選ページの作成|作成')

      if (this.isCreateWinPageDisabled) {
        return
      }

      const body = {
        campaignId: this.form.campaignId,
        mediaUrl: this.form.mediaUrl || WEB_INSTANTWIN_MEDIA_WIN_URL,
        name: this.form.name,
        lotteryText: this.form.lotteryText,
        mediaType: this.form.mediaType
      }

      const response = await api.put(`/twitter_lottery_pages/${this.form.twWebInstantwinLotteryPageSettingId}`, body)

      if (!response || !response.data) {
        this.notify('当選ページの保存に失敗しました。', '恐れ入りますが、時間をおいて再度お試しください。')
        return
      }

      this.notify('当選ページを保存しました。', null, 'success')
      this.fetchLotteryPages()
    }
  }
})
