import { defineComponent } from '@vue/composition-api'
import { mapActions, mapState } from 'vuex'

import Pagination from '@/client/components/basic/pagination'
import Util from '@/client/core/util'
import { TrackingService } from '@/client/services'
import { GetLotteryPagesLotteryPagesResponse } from '@/client/store/lottery_page_setting_dialog.store'
import { API } from '@/client/utils/api'
import { WEB_INSTANTWIN_MEDIA_LOSE_URL } from '@/common/constants'

export default defineComponent({
  name: 'LotteryPageTable',
  components: {
    Pagination
  },
  computed: {
    ...mapState('lotteryPageSettingDialog', ['lotteryPages', 'total', 'params', 'error', 'mode'])
  },

  methods: {
    ...mapActions('lotteryPageSettingDialog', ['fetchLotteryPages', 'reset', 'setParams', 'setMode', 'setForm']),

    /**
     * ヘッダークリックイベント
     */
    headerClick(column: any) {
      TrackingService.sendEvent('sort:当落選ページの管理|タイトル')

      if (!column.sortBy) {
        return
      }

      const sort = this.getSortParameter(this.params.sort)

      if (column.sortBy.indexOf('-') === 0) {
        if (sort === column.sortBy) {
          this.onChangeSortBy('ASC')
        } else {
          this.onChangeSortBy('DESC')
        }
      } else {
        if (sort === column.sortBy) {
          this.onChangeSortBy('DESC')
        } else {
          this.onChangeSortBy('ASC')
        }
      }
    },

    /**
     * 当選の編集・落選の作成・編集・コピー用共通関数
     */
    async openCreateOrUpdateOrCopy(value: 'create' | 'copy' | 'edit_win' | 'edit_lose', id?: number) {
      if (!['create', 'copy', 'edit_win', 'edit_lose'].includes(value)) return

      const text = this.mode === 'edit_win' ? '当選' : '落選'

      if (value !== 'create') {
        if (!id) {
          return this.$notify({
            title: `${text}ページの取得に失敗しました。`,
            message: '恐れ入りますが、時間をおいて再度お試しください。',
            customClass: 'danger'
          })
        }

        TrackingService.sendEvent(`click:当落選ページの管理|${text}|${value === 'copy' ? '複製' : '編集'}`)
        await this.fetchLotteryPageById(value, id)
      } else {
        this.setForm({
          campaignId: this.params.campaignId,
          twCampaignPrizeId: this.params.prizeId,
          mediaUrl: WEB_INSTANTWIN_MEDIA_LOSE_URL,
          lotteryType: 0,
          mediaType: 0,
          name: '',
          lotteryText: ''
        })

        TrackingService.sendEvent('click:当落選ページの管理|落選|新規作成')
      }

      this.setMode(value)
    },

    /**
     * 当落選ページの取得
     */
    async fetchLotteryPageById(mode: 'edit_win' | 'edit_lose' | 'copy', id?: number) {
      const response = (await API.get(`/twitter_lottery_pages/${id}`, {
        params: { campaignId: this.params.campaignId }
      })) as GetLotteryPagesLotteryPagesResponse

      if (!response.data || !response.data) {
        this.$notify({
          title: `${mode === 'edit_win' ? '当選' : '落選'}ページの取得に失敗しました。`,
          message: '恐れ入りますが、時間をおいて再度お試しください。',
          customClass: 'danger'
        })

        return
      }

      this.setForm({
        campaignId: this.params.campaignId,
        twCampaignPrizeId: this.params.prizeId,
        twWebInstantwinLotteryPageSettingId: id,
        mediaUrl: response.data.mediaUrl,
        lotteryType: response.data.lotteryType,
        mediaType: response.data.mediaType,
        name: response.data.name,
        lotteryText: response.data.lotteryText
      })
    },

    /**
     * ソート変更イベント
     */
    async onChangeSortBy(sort: 'ASC' | 'DESC') {
      this.setParams({ sort })
      await this.fetchLotteryPages()
    },

    /**
     * テーブルのヘッダーを描画
     */
    renderHeader(createElement: any, { column }: any) {
      return Util.renderHeader(
        createElement,
        column.label,
        Util.getSortClass(this.getSortParameter(this.params.sort), this.getSortParameter(column.sortBy))
      )
    },

    /**
     * ソートパラメータを取得
     */
    getSortParameter(sort: 'ASC' | 'DESC') {
      return sort === 'ASC' ? 'name' : '-name'
    },

    /**
     * ページを変更イベント
     */
    onChangePagingNo(page: number) {
      this.setParams({ pagingNo: page })
      this.fetchLotteryPages()
    },

    /**
     * 1ページあたりの表示件数変更イベント
     */
    onChangeLimitCount(limitCount: number) {
      this.setParams({ limitCount, pagingNo: 1 })
      this.fetchLotteryPages()
    },

    /**
     * Webプレビュー確認イベント
     */
    clickSnsConfirmEvent() {
      TrackingService.sendEvent('click:当落選ページの管理|Webプレビュー')
    }
  }
})
