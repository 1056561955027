import { mapActions, mapState } from 'vuex'

import LoseReasonTable from '@/client/components/twitter_instantwin_message_history/lose_reason_table'
import Util from '@/client/core/util'

export default {
  data() {
    return {
      title: '落選理由ごとの件数',
      visible: false
    }
  },
  components: {
    LoseReasonTable
  },
  computed: {
    ...mapState('system', {
      groupId: state => state.currentGroup.id
    }),
    ...mapState('twitterInstantwinLoseReason', {
      params: state => state.params,
      options: state => {
        return {
          sortBy: state.params.sortBy
        }
      },
      loading: state => state.loading,
      loseReasons: state => state.loseReasons
    })
  },
  watch: {
    params() {
      this.fetchLoseReasons()
    }
  },
  methods: {
    ...mapActions('twitterInstantwinLoseReason', ['reset', 'fetchLoseReasons', 'setParams']),
    /**
     * 変更イベント
     * @param {any} payload ページ
     */
    onChangePaging(payload: any) {
      this.setParams(payload)
      Util.scrollToTop()
    },

    /**
     * ソートを変更イベント
     * @param {any} payload ソート
     */
    onChangeSortBy(payload: any) {
      if (this.params.sortBy === payload.sortBy) {
        return
      }
      this.setParams(payload)
    },

    /**
     * ダイアログを開く
     */
    open(campaignId: number) {
      this.setParams({ campaignId })
      this.visible = true
    },

    /**
     * ダイアログ閉じるイベント
     */
    beforeClose(done) {
      this.reset()
      done()
    }
  }
}
