import Vue, { VueConstructor } from 'vue'
import { mapState } from 'vuex'

import { DialogTikTokCampaignEdit, TwitterWebInstantwinPreview } from '@/client/components/_organisms'
import DialogCampaignCreate from '@/client/components/_organisms/DialogCampaignCreate.vue'
import DialogInstagramCampaignEdit from '@/client/components/_organisms/DialogInstagramCampaignEdit.vue'
import DialogTwitterCampaignEdit from '@/client/components/_organisms/DialogTwitterCampaignEdit.vue'
import Icon from '@/client/components/basic/icon'
import Pagination from '@/client/components/basic/pagination'
import CampaignAddEditDialog from '@/client/components/campaign/campaign_add_edit_dialog'
import CampaignStatus from '@/client/components/campaign/campaign_status'
import InstagramCampaignAnalyticsDialog from '@/client/components/campaign/instagram_campaign_analytics_dialog'
import TiktokCampaignAnalyticsDialog from '@/client/components/campaign/tiktok_campaign_analytics_dialog'
import TwitterCampaignAnalyticsDialog from '@/client/components/campaign/twitter_campaign_analytics_dialog'
import { CAMPAIGN_STATUS } from '@/client/constant'
import Util from '@/client/core/util'
import { TrackingService } from '@/client/services'

type WebInstantwinPreview = {
  beginUrl: string
  endUrl: string
  currentUrl: string
}

export default (Vue as VueConstructor<
  Vue & {
    $refs: {
      campaignAddEditDialog: HTMLElement & { open: (mode: string, id: number, type: string, isCopy: boolean) => void }
      instagramCampaignAnalyticsDialog: HTMLElement & { open: (id: number) => void }
      tiktokCampaignAnalyticsDialog: HTMLElement & { open: (id: number) => void }
      twitterCampaignAnalyticsDialog: HTMLElement & { open: (id: number) => void }
      DialogCampaignCreate: HTMLElement & { open: (id?: number) => void }
      DialogInstagramCampaignEdit: HTMLElement & { open: (id: number) => void }
      DialogTwitterCampaignEdit: HTMLElement & { open: (id: number) => void }
      DialogTikTokCampaignEdit: HTMLElement & { open: (id: number) => void }
      TwitterWebInstantwinPreview: HTMLElement & { open: (preview: WebInstantwinPreview) => void }
    }
  }
>).extend({
  props: {
    campaigns: Array,
    options: Object,
    onChangePagingNo: Function,
    onChangeLimitCount: Function,
    onChangeSortBy: Function
  },
  components: {
    CampaignStatus,
    Pagination,
    Icon,
    CampaignAddEditDialog,
    TwitterCampaignAnalyticsDialog,
    InstagramCampaignAnalyticsDialog,
    TiktokCampaignAnalyticsDialog,
    DialogCampaignCreate,
    DialogInstagramCampaignEdit,
    DialogTwitterCampaignEdit,
    DialogTikTokCampaignEdit,
    TwitterWebInstantwinPreview
  },
  computed: {
    ...mapState<any>('system', {
      contractStatus: state => state.currentGroup.contractStatus,
      allowCreateInstantwin: state => Boolean(state.currentGroup.allowCreateInstantwin),
      isSystemAdmin: state => Boolean(state.user.isSystemAdmin)
    })
  },
  methods: {
    /**
     * Open edit dialog
     * @param {string} snsType target_sns
     * @param {number} id campaign id
     * @param {string} type type campaign
     * @param {boolean} isCopy is duplicate
     */
    openEditDialog(snsType: string, id: number, type: string, isCopy = false) {
      if (!isCopy) {
        TrackingService.sendEvent('click:キャンペーン一覧|編集')
      } else TrackingService.sendEvent('click:キャンペーン一覧|複製')

      let mode = ''
      if (snsType === 'instagram') {
        mode = isCopy ? 'copyInstagram' : 'editInstagram'
      } else if (snsType === 'tiktok') {
        mode = isCopy ? 'copyTikTok' : 'editTikTok'
      } else {
        mode = isCopy ? 'copyTwitter' : 'editTwitter'
      }

      if (isCopy && type !== 'instantwin') {
        this.$refs.DialogCampaignCreate.open(id)
      } else if (mode === 'editTwitter' && type !== 'instantwin') {
        this.$refs.DialogTwitterCampaignEdit.open(id)
      } else if (mode === 'editInstagram') {
        this.$refs.DialogInstagramCampaignEdit.open(id)
      } else if (mode === 'editTikTok') {
        this.$refs.DialogTikTokCampaignEdit.open(id)
      } else {
        this.$refs.campaignAddEditDialog.open(mode, id, type, isCopy)
      }
    },
    /**
     * ヘッダーをクリックするとソートする
     * @param {*} column
     */
    headerClick(column: any) {
      switch (column.label) {
        case 'ステータス':
          TrackingService.sendEvent('sort:キャンペーン一覧|ステータス')
          break
        case 'タイトル':
          TrackingService.sendEvent('sort:キャンペーン一覧|タイトル')
          break
        case '期間':
          TrackingService.sendEvent('sort:キャンペーン一覧|期間')
          break
        default:
          break
      }

      if (!column.sortBy) {
        return
      }
      if (column.sortBy.indexOf('-') === 0) {
        if (this.options.sortBy === column.sortBy) {
          this.onChangeSortBy(column.sortBy.substring(1))
        } else {
          this.onChangeSortBy(column.sortBy)
        }
      } else {
        if (this.options.sortBy === column.sortBy) {
          this.onChangeSortBy('-' + column.sortBy)
        } else {
          this.onChangeSortBy(column.sortBy)
        }
      }
    },
    /**
     * ヘッダー描画
     * @param {*} createElement
     * @param {*} { column }
     */
    renderHeader(createElement: any, { column }: any) {
      return Util.renderHeader(
        createElement,
        column.label,
        Util.getSortClass(this.options.sortBy, column.sortBy),
        !!column.sortBy
      )
    },

    /**
     * 分析ボタンの表示非表示を判定する
     */
    isEnabledAnalytics(status: number) {
      return [
        CAMPAIGN_STATUS.DURING_COLLECTION,
        CAMPAIGN_STATUS.DURING_FINAL_COLLECTION,
        CAMPAIGN_STATUS.COLLECTION_COMPLETED
      ].includes(status)
    },
    /**
     * Open analytics dialog
     * @param {number} id
     * @param {string} targetSns
     */
    openAnalyticsDialog(id: number, targetSns: string) {
      // TODO: TikTOkの場合の処理を追加
      TrackingService.sendEvent('click:キャンペーン一覧|分析')
      if (targetSns === 'instagram') {
        this.$refs.instagramCampaignAnalyticsDialog.open(id)
      } else if (targetSns === 'tiktok') {
        this.$refs.tiktokCampaignAnalyticsDialog.open(id)
      } else {
        this.$refs.twitterCampaignAnalyticsDialog.open(id)
      }
    },
    /**
     * SNSで確認イベントの導入
     */
    clickSnsConfirmEvent(targetSns: string, type: string) {
      if (targetSns === 'twitter' && type === 'web_instantwin') {
        TrackingService.sendEvent('click:キャンペーン一覧|キャンペーン応募ページ')
      } else if (targetSns === 'instagram') {
        TrackingService.sendEvent('click:キャンペーン一覧|Instagramで確認')
      } else if (targetSns === 'tiktok') {
        TrackingService.sendEvent('click:キャンペーン一覧|TikTokで確認')
      } else if (targetSns === 'twitter') {
        TrackingService.sendEvent('click:キャンペーン一覧|Xで確認')
      }
    },
    /**
     * キャンペーンタイトルのイベントの導入
     */
    clickCampaignTitleEvent(targetSns: string, type: string, id: number) {
      TrackingService.sendEvent('click:キャンペーン一覧|キャンペーンタイトル')
      if (targetSns === 'twitter' && type === 'instantwin') {
        this.$router.push('/twitter_instantwins/' + id)
      } else if (targetSns === 'twitter') {
        this.$router.push('/twitter_campaigns/' + id + '/prizes')
      } else if (targetSns === 'instagram') {
        this.$router.push('/instagram_campaigns/' + id + '/prizes')
      } else if (targetSns === 'tiktok') {
        this.$router.push('/tiktok_campaigns/' + id + '/prizes')
      }
    },

    /**
     * Web応募ページのメニュークリック時処理
     */
    selectActionMenu(command: string) {
      if (command === 'applicant_page_url') {
        return TrackingService.sendEvent('click:キャンペーン一覧|実際の応募ページを確認する')
      }

      if (command === 'applicant_page_sample_dialog') {
        return TrackingService.sendEvent('click:キャンペーン一覧|応募ページプレビューダイアログを開く')
      }
    },

    /**
     * Web応募ページのプレビューを開く
     */
    openWebInstantwinPreviewDialog(preview: WebInstantwinPreview) {
      this.$refs.TwitterWebInstantwinPreview.open(preview)
    }
  }
})
