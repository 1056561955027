import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'

import { API } from '@/client/utils/api'
import {
  DeleteProfileKeywordSettingResponse,
  GetProfileKeywordSettingsResponse,
  PostProfileKeywordSettingResponse,
  ProfileKeywordSetting,
  PutProfileKeywordSettingResponse
} from '@/typings/profile_keyword_settings'

import { RootState } from './index'

const SET_API_PROFILE_KEYWORDS = 'SET_API_PROFILE_KEYWORDS'

type CreateProfileKeywordPayload = {
  name: string
  positive_keyword: string[]
  negative_keyword: string[]
  is_active: boolean
}

type UpdateProfileKeywordPayload = {
  keyword_setting_id: number
  name?: string
  positive_keyword?: string[]
  negative_keyword?: string[]
  is_active?: boolean
}

export type SettingGroupState = {
  api_profile_keyword_settings: ProfileKeywordSetting[]
}

export type SettingGroupActions = {
  fetchProfileKeywords: () => Promise<void>
  createProfileKeyword: (payload: CreateProfileKeywordPayload) => Promise<PostProfileKeywordSettingResponse>
  updateProfileKeyword: (payload: UpdateProfileKeywordPayload) => Promise<PutProfileKeywordSettingResponse>
  deleteProfileKeyword: (payload: number) => Promise<DeleteProfileKeywordSettingResponse>
}

const state: SettingGroupState = {
  api_profile_keyword_settings: []
}

const getters: GetterTree<SettingGroupState, RootState> = {}

const mutations: MutationTree<SettingGroupState> = {
  [SET_API_PROFILE_KEYWORDS](state, payload: ProfileKeywordSetting[]) {
    state.api_profile_keyword_settings = payload
  }
}

const actions: ActionTree<SettingGroupState, RootState> = {
  /**
   * 自己紹介キーワード判定一覧を取得
   */
  async fetchProfileKeywords(context) {
    const response: GetProfileKeywordSettingsResponse = await API.get('v1/profile_keyword_settings', {
      params: {
        group_id: context.rootState.system.currentGroup.id
      }
    })

    if (response && response.data) {
      context.commit(SET_API_PROFILE_KEYWORDS, response.data)
    }
  },

  /**
   * 自己紹介キーワード判定を作成する
   */
  async createProfileKeyword(context, payload: CreateProfileKeywordPayload) {
    const params = {
      group_id: context.rootState.system.currentGroup.id,
      ...payload
    }

    const response: PostProfileKeywordSettingResponse = await API.post('v1/profile_keyword_settings', params)

    if (response && response.data) {
      await context.dispatch('fetchProfileKeywords')
    }

    return response
  },

  /**
   * 自己紹介キーワード判定を変更する
   */
  async updateProfileKeyword(context, payload: UpdateProfileKeywordPayload) {
    const params = {
      group_id: context.rootState.system.currentGroup.id,
      name: payload.name,
      positive_keyword: payload.positive_keyword,
      negative_keyword: payload.negative_keyword,
      is_active: payload.is_active
    }

    const response: PutProfileKeywordSettingResponse = await API.put(
      `v1/profile_keyword_settings/${payload.keyword_setting_id}`,
      params
    )

    if (response && response.data) {
      await context.dispatch('fetchProfileKeywords')
    }

    return response
  },

  /**
   * 自己紹介キーワード判定を削除する
   */
  async deleteProfileKeyword(context, payload: number) {
    const response: DeleteProfileKeywordSettingResponse = await API.delete(`v1/profile_keyword_settings/${payload}`)

    if (response && response.data) {
      await context.dispatch('fetchProfileKeywords')
    }

    return response
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
} as Module<SettingGroupState, RootState>
