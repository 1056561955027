/* @flow */
import Vue from 'vue'

export default class SessionError {
  static isShowNotify: boolean
  static showNotify() {
    // trueだった場合はリターンして繰り返さない
    if (SessionError.isShowNotify) {
      return
    }
    SessionError.isShowNotify = true
    Vue.prototype.$notify({
      title: Vue.prototype.$gettext('セッションの接続が切れました。'),
      dangerouslyUseHTMLString: true,
      message:
        '恐れ入りますが、再度ログインしてセッションを有効にしてください。<br> <button type="button" class="mt-x1 el-button el-button--primary"><span>ログイン</span></button>',
      customClass: 'danger pointer',
      onClick: () => {
        window.location.reload()
      },
      duration: 0
    })
  }
}
