/* @flow */
import moment from 'moment'
import { mapActions, mapState } from 'vuex'

import InstagramAnalyticsPublicCampaign from '@/client/components/InstagramAnalyticsPublic/InstagramAnalyticsPublicCampaign'
import InstagramAnalyticsPublicFilter from '@/client/components/InstagramAnalyticsPublic/InstagramAnalyticsPublicFilter'
import InstagramAnalyticsPublicHeader from '@/client/components/InstagramAnalyticsPublic/InstagramAnalyticsPublicHeader'
import InstagramAnalyticsPublicSummary from '@/client/components/InstagramAnalyticsPublic/InstagramAnalyticsPublicSummary'

export default {
  components: {
    InstagramAnalyticsPublicCampaign,
    InstagramAnalyticsPublicFilter,
    InstagramAnalyticsPublicHeader,
    InstagramAnalyticsPublicSummary
  },
  computed: {
    ...mapState('instagramAnalyticsPublic', {
      params: state => state.params,
      campaign: state => state.campaign,
      loading: state => state.loading
    })
  },
  async created() {
    const hash = this.$route.query.hash

    if (!hash) {
      return
    }

    const params = { hash }

    this.setParams(params)

    await this.fetchCampaign()
    await this.fetchPrizes()

    if (!this.campaign || !this.campaign.id) {
      return
    }

    this.setParams({
      startDatetime: moment(this.campaign.startDatetime).format('YYYY-MM-DD'),
      endDatetime: moment(this.campaign.endDatetime)
        .subtract(1, 'hour')
        .format('YYYY-MM-DD')
    })

    await this.fetchCampaignAnalytics()
  },
  methods: {
    ...mapActions('instagramAnalyticsPublic', [
      'fetchCampaignAnalytics',
      'fetchFollowerAnalytics',
      'fetchCampaign',
      'fetchPrizes',
      'setParams'
    ])
  }
}
