/* @flow */
import { mapState } from 'vuex'

import AnalyticsChartDoughnut from '@/client/components/basic/analytics_chart_doughnut'
import CsvDownload from '@/client/components/basic/csv_download'
import { CAMPAIGN_STATUS } from '@/client/constant'

export default {
  components: {
    CsvDownload,
    AnalyticsChartDoughnut
  },
  data() {
    return {
      chartStyles: {
        margin: '0 auto'
      }
    }
  },
  computed: {
    ...mapState('tiktokAnalyticsPublic', {
      trafficSource: state => state.trafficSource,
      campaign: state => state.campaign
    }),
    isEmpty() {
      return this.trafficSource.every(v => v.count === 0)
    },
    campaignStatusText() {
      if (this.campaign.status === CAMPAIGN_STATUS.DURING_FINAL_COLLECTION) {
        return '最終データ取得待ち'
      }

      if (this.campaign.status === CAMPAIGN_STATUS.COLLECTION_COMPLETED) {
        return '集計完了'
      }

      return '開催中、24~48時間ごとに更新されます。'
    }
  },
  methods: {
    downloadCsv() {
      const filename = `${this.campaign.title}_トラフィックソース`
      const data = []
      const header = [this.$gettext('ラベル'), this.$gettext('値'), this.$gettext('割合')]

      data.push(header)
      const totalData = this.trafficSource
        .map(follower => follower.count)
        .reduce((count, current) => count + current, 0)

      this.trafficSource.forEach(follower => {
        const percentage = !totalData ? 0 : Math.round((follower.count / totalData) * Math.pow(100, 2)) / 100
        data.push([follower.label, follower.count, percentage])
      })

      return { filename, data }
    }
  }
}
