/* @flow */
import { mapActions, mapState } from 'vuex'

import Panel from '@/client/components/_atoms/Panel.vue'
import TagInput from '@/client/components/basic/tag_input'
import { CAMPAIGN_STATUS } from '@/client/constant'
import api from '@/client/core/api'
import { TrackingService } from '@/client/services'

export default {
  props: {
    prizeCount: Number
  },
  components: {
    TagInput,
    Panel
  },
  data() {
    return {
      title: '',
      visible: false,
      loading: false,
      campaignPrize: {
        id: 0,
        campaignId: 0,
        name: '',
        maxWinnerCount: 0,
        oldMaxWinnerCount: 0,
        entryKeywords: []
      },
      rawCampaignPrizeData: {},
      errorEntryKeywordMessage: '',
      isSubmitting: false,
      isSubmited: false
    }
  },
  computed: {
    ...mapState('instagramCampaignPrize', {
      campaign: state => {
        if (state.campaign) {
          return state.campaign
        }
        return {}
      },
      searchKeyword: state => {
        if (state.campaign && state.campaign.setting) {
          return JSON.parse(state.campaign.setting.searchKeyword)
        }
        return null
      },
      orKeyword: state => {
        if (state.campaign && state.campaign.setting && state.campaign.setting.orKeyword) {
          return JSON.parse(state.campaign.setting.orKeyword)
        }
        return null
      }
    }),

    isFormValid() {
      if (!this.campaignPrize.name) {
        return false
      }

      return true
    },

    isShowCampaignHashtag() {
      return this.campaign.setting && this.campaign.setting.entryActionType !== 'comment'
    },

    isCampaignFinished() {
      return [CAMPAIGN_STATUS.DURING_FINAL_COLLECTION, CAMPAIGN_STATUS.COLLECTION_COMPLETED].includes(
        this.campaign.status
      )
    },

    isDeleteDisabled() {
      return this.isCampaignFinished || this.prizeCount === 1
    },

    isFormChanged(): boolean {
      return JSON.stringify(this.campaignPrizeData) !== JSON.stringify(this.rawCampaignPrizeData)
    },

    campaignPrizeData() {
      return this.setCampaignPrizeData()
    }
  },
  methods: {
    ...mapActions('instagramCampaignPrize', ['fetchPrizes', 'fetchCampaign']),

    /**
     * Set campaign prize data
     * @returns {Object} campaign prize data
     */
    setCampaignPrizeData() {
      return {
        campaignId: this.campaignPrize.campaignId,
        name: this.campaignPrize.name,
        maxWinnerCount: this.campaignPrize.maxWinnerCount,
        oldMaxWinnerCount: this.campaignPrize.maxWinnerCount,
        entryKeyword: JSON.stringify(this.campaignPrize.entryKeywords)
      }
    },

    /**
     * Get data from api
     */
    async getCampaignPrize() {
      this.loading = true
      const result = await api.get('/instagram_campaign_prizes/' + this.campaignPrize.id)
      this.loading = false
      const campaignPrize = result.data

      if (!campaignPrize) {
        this.$notify({
          title: this.$gettext('対象の応募グループはすでに削除されています。'),
          customClass: 'danger',
          duration: 5000
        })
        this.close()
        return
      }

      this.campaignPrize = {
        id: campaignPrize.id,
        campaignId: campaignPrize.campaignId,
        name: campaignPrize.name,
        maxWinnerCount: campaignPrize.maxWinnerCount,
        oldMaxWinnerCount: campaignPrize.maxWinnerCount,
        entryKeywords: campaignPrize.entryKeyword ? JSON.parse(campaignPrize.entryKeyword) : []
      }
    },

    async createOrUpdate() {
      if (this.campaignPrize.id) {
        TrackingService.sendEvent('click:応募グループ一覧|応募グループの編集(IG)|保存')

        await this.updateCampaignPrize()
      } else {
        await this.createCampaignPrize()
      }
      this.close()
    },

    /**
     * 応募グループの新規作成
     */
    async createCampaignPrize() {
      this.isSubmitting = true
      if (!this.isFormValid) {
        return
      }

      // キャンペーンを保存する
      const result = await api.post('/instagram_campaign_prizes', this.campaignPrizeData)

      setTimeout(() => {
        this.isSubmitting = false
      }, 2000)

      if (result && result.data) {
        // 成功メッセージを表示
        this.$notify({
          title: this.$gettext('応募グループを作成しました。'),
          customClass: 'success',
          duration: 5000
        })
        return
      }

      this.$notify({
        title: this.$gettext('応募グループの新規作成に失敗しました。'),
        message: this.$gettext('恐れ入りますが、時間をおいて再度お試しください。'),
        customClass: 'danger',
        duration: 5000
      })
    },

    /**
     * 応募グループの編集
     */
    async updateCampaignPrize() {
      this.isSubmitting = true
      if (!this.isFormValid) {
        return
      }

      // キャンペーンを保存する
      const result = await api.put('/instagram_campaign_prizes/' + this.campaignPrize.id, this.campaignPrizeData)

      setTimeout(() => {
        this.isSubmitting = false
      }, 2000)

      if (result && result.data) {
        // 成功メッセージを表示
        this.$notify({
          title: this.$gettext('応募グループを保存しました。'),
          customClass: 'success',
          duration: 5000
        })

        return
      }

      if (result.error && result.error.type === 'NOT_EXISTS') {
        this.$notify({
          title: this.$gettext('対象の応募グループはすでに削除されています。'),
          customClass: 'danger',
          duration: 5000
        })

        return
      }

      this.$notify({
        title: this.$gettext('応募グループの保存に失敗しました。'),
        message: this.$gettext('恐れ入りますが、時間をおいて再度お試しください。'),
        customClass: 'danger',
        duration: 5000
      })
    },

    /**
     * 応募グループの削除
     */
    async deleteCampaignPrize() {
      TrackingService.sendEvent('click:応募グループ一覧|応募グループの編集(IG)|削除')

      const confirmDelete = confirm(this.$gettext('応募グループを削除してよろしいですか？'))
      if (!confirmDelete) {
        return
      }

      const result = await api.delete('/instagram_campaign_prizes/' + this.campaignPrize.id)

      if (result && result.data) {
        this.$notify({
          title: this.$gettext('応募グループを削除しました。'),
          customClass: 'success',
          duration: 5000
        })
        this.close()
        return
      }

      if (result.error && result.error.type === 'NOT_EXISTS') {
        this.$notify({
          title: this.$gettext('対象の応募グループはすでに削除されています。'),
          customClass: 'danger',
          duration: 5000
        })
        this.close()
        return
      }

      this.$notify({
        title: this.$gettext('応募グループの削除に失敗しました。'),
        message: this.$gettext('恐れ入りますが、時間をおいて再度お試しください。'),
        customClass: 'danger',
        duration: 5000
      })
      this.close()
    },

    /**
     * Execute when max winner count is changed
     * @param {number} maxWinnerCount
     */
    onChangeMaxWinnerCount(maxWinnerCount: number) {
      if (maxWinnerCount === this.campaignPrize.oldMaxWinnerCount + 1) {
        TrackingService.sendEvent('click:応募グループ一覧|応募グループの編集(IG)|当選数|UP')
      } else if (maxWinnerCount === this.campaignPrize.oldMaxWinnerCount - 1) {
        TrackingService.sendEvent('click:応募グループ一覧|応募グループの編集(IG)|当選数|DOWN')
      } else TrackingService.sendEvent('input:応募グループ一覧|応募グループの編集(IG)|当選数')

      this.campaignPrize.oldMaxWinnerCount = this.campaignPrize.maxWinnerCount

      if (!maxWinnerCount) {
        setTimeout(() => {
          this.campaignPrize.maxWinnerCount = 0
        }, 100)
      }
    },

    /**
     * Valid entry keyword
     * @param {string[]} keywords list keyword
     */
    onChangeEntryKeywords(keywords: string[]) {
      const origin = Object.assign([], this.campaignPrize.entryKeywords)

      if (!this.isValidKeyword(keywords, origin)) {
        this.campaignPrize.entryKeywords = origin
        return
      }
      this.errorEntryKeywordMessage = ''
      this.campaignPrize.entryKeywords = keywords
    },

    /**
     * Check if keyword is valid
     * @param {string[]} keywords list keyword
     * @param {string} origin
     * @returns {boolean}
     */
    isValidKeyword(keywords: string[], origin: string[]): boolean {
      const keyword = keywords.join('')
      if (keyword.length > 120) {
        this.errorEntryKeywordMessage = this.$gettext('応募キーワードの合計を120文字以下にしてください。')

        this.$notify({
          title: this.errorEntryKeywordMessage,
          customClass: 'danger',
          duration: 5000
        })

        return false
      }

      if (origin.length > keywords.length) {
        return true
      }

      if (origin.includes(keywords[keywords.length - 1])) {
        return false
      }

      return true
    },

    /**
     * ダイアログを開く
     */
    async open(campaignId: number, id: number) {
      this.visible = true
      if (id) {
        this.campaignPrize.id = id
        this.title = this.$gettext('応募グループの編集')
        await this.getCampaignPrize()
      } else {
        this.title = this.$gettext('応募グループの新規作成')
      }

      this.campaignPrize.campaignId = campaignId
      this.rawCampaignPrizeData = this.setCampaignPrizeData()
    },

    /**
     * Close dialog after submit
     * @returns {Promise<void>} void
     */
    async close() {
      this.isSubmited = true
      this.visible = false
      await this.fetchPrizes()
      await this.fetchCampaign()
    },

    /**
     * Close dialog
     * @returns {void}: void
     */
    hide(): void {
      TrackingService.sendEvent('click:応募グループ一覧|応募グループの編集(IG)|閉じる')

      if (this.isFormChanged && !this.isSubmited) {
        const message = this.$gettext('編集内容が保存されていません。ダイアログを閉じてよろしいですか?')
        const confirm = window.confirm(message)

        if (!confirm) {
          return
        }
      }

      this.resetForm()
      this.visible = false
      this.loading = false
      this.isSubmited = false
    },

    resetForm() {
      this.campaignPrize = {
        id: 0,
        campaignId: 0,
        name: '',
        maxWinnerCount: 0,
        entryKeywords: []
      }
      this.errorEntryKeywordMessage = ''
    }
  }
}
