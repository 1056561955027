import { render, staticRenderFns } from "./serial_code_create.vue?vue&type=template&id=441e86d0&scoped=true"
import script from "./serial_code_create.component.js?vue&type=script&lang=js&external"
export * from "./serial_code_create.component.js?vue&type=script&lang=js&external"
import style0 from "./serial_code_create.vue?vue&type=style&index=0&id=441e86d0&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "441e86d0",
  null
  
)

export default component.exports