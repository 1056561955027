/* @flow */
import { mapState } from 'vuex'

import AnalyticsTotal from '@/client/components/basic/analytics_total'
import { CAMPAIGN_STATUS } from '@/client/constant'
import { type TTikTokCampaignAnalytic } from '@/typings/api/publics/tiktok_campaign_analytics.type'

export default {
  components: { AnalyticsTotal },
  computed: {
    ...mapState('tiktokAnalyticsPublic', {
      campaignAnalytics: state => state.campaignAnalytics,
      columnChartData: state => state.campaignAnalytics.slice(0, state.campaignAnalytics.length - 1),
      campaign: state => state.campaign
    }),

    isCampaignFinalCollection() {
      return this.campaign.status === CAMPAIGN_STATUS.DURING_FINAL_COLLECTION
    },

    isCampaignCollectionCompleted() {
      return this.campaign.status === CAMPAIGN_STATUS.COLLECTION_COMPLETED
    },

    campaignStatusText() {
      if (this.campaign.status === CAMPAIGN_STATUS.DURING_FINAL_COLLECTION) {
        return '最終データ取得待ち'
      }

      if (this.campaign.status === CAMPAIGN_STATUS.COLLECTION_COMPLETED) {
        return '集計完了'
      }

      return '開催中、24~48時間ごとに更新されます。'
    },

    entryCount() {
      return this.getTotal(this.columnChartData, 'entryCount')
    },

    applicantCount() {
      return this.getTotal(this.columnChartData, 'applicantCount')
    },

    newApplicantCount() {
      return this.getTotal(this.columnChartData, 'newApplicantCount')
    },

    followerUpDown() {
      const firstFollowerCount = this.getFirstValueExceptNull(this.campaignAnalytics, 'followerCount')

      return this.getLastValue(this.campaignAnalytics, 'followerCount') - firstFollowerCount
    },

    impressionTotalCount() {
      return this.getLastValue(this.campaignAnalytics, 'impressionTotalCount')
    },

    reachCount() {
      return this.getLastValue(this.campaignAnalytics, 'reachCount')
    },

    watchedFullVideoCount() {
      return this.getLastValue(this.campaignAnalytics, 'watchedFullVideoCount')
    },

    winnerCount() {
      return this.getTotal(this.columnChartData, 'winner_count')
    }
  },
  methods: {
    /**
     * 合計値を取得
     */
    getTotal(data: TTikTokCampaignAnalytic[], metric: string) {
      return this.addition(this.getMetricArray(data, metric))
    },

    /**
     * 足し算
     */
    addition(numbers: number[] = []): number | null {
      const values = numbers.filter(v => v !== null)

      if (!values.length) {
        return null
      }

      return numbers.map(v => this.toNumber(v)).reduce((a, c) => a + c, 0)
    },

    /**
     * 分析データから値の配列を取得
     */
    getMetricArray(insights: any[], metric: string): any[] {
      return insights.map(insight => this.getMetricValue(insight, metric))
    },

    /**
     * 分析データから値を取得
     */
    getMetricValue(insight: any, metric: string): any | null {
      return isNaN(insight[metric]) ? null : insight[metric]
    },

    getFirstValueExceptNull(analytics: any[], value: string) {
      const data = analytics.find(analytic => analytic[value] !== null)

      if (!data) {
        return 0
      }

      return data[value]
    },

    /**
     * 最後の値を取得
     */
    getLastValue(analytics: any[], value: string) {
      const data = analytics.filter(analytic => analytic[value] !== null)

      if (data.length === 0) {
        return null
      }

      return data[data.length - 1][value]
    },

    /**
     * 数値変換
     */
    toNumber(value: any): number {
      return !isNaN(value) ? value : 0
    }
  }
}
