/* @flow */
import moment from 'moment'
import { mapActions, mapState } from 'vuex'

import CsvDownload from '@/client/components/basic/csv_download'
import ApplicantImportDialog from '@/client/components/twitter_instantwin_message_history/applicant_import_dialog'
import ApplicantResetDialog from '@/client/components/twitter_instantwin_message_history/applicant_reset_dialog'
import DirectMessageHistoryDialog from '@/client/components/twitter_instantwin_message_history/direct_message_history_dialog'
import LoseReasonDialog from '@/client/components/twitter_instantwin_message_history/lose_reason_dialog'
import MessageHistoryFilter from '@/client/components/twitter_instantwin_message_history/message_history_filter'
import MessageHistoryTable from '@/client/components/twitter_instantwin_message_history/message_history_table'
import api from '@/client/core/api'
import util from '@/client/core/util'

export default {
  components: {
    MessageHistoryFilter,
    MessageHistoryTable,
    ApplicantImportDialog,
    ApplicantResetDialog,
    DirectMessageHistoryDialog,
    LoseReasonDialog,
    CsvDownload
  },
  props: {
    campaign: Object
  },
  data() {
    return {
      loading: false,
      loadingText: '',
      actionDialogs: [
        {
          label: this.$gettext('回答者インポート'),
          value: 'import_responder'
        },
        {
          label: this.$gettext('回答者解除'),
          value: 'reset_responder'
        }
      ]
    }
  },
  async created() {
    const params = {
      campaignId: this.$route.params.campaignId
    }
    this.setHistoryParams(params)
  },
  computed: {
    ...mapState('system', {
      contractStatus: state => state.currentGroup.contractStatus
    }),
    ...mapState('twitterInstantwinMessageHistory', {
      histories: state => state.histories,
      params: state => state.params,
      loaded: state => state.loaded,
      error: state => state.error,
      paginations: state => {
        return {
          pagingNo: state.params.pagingNo,
          limitCount: state.params.limitCount,
          total: state.total
        }
      }
    }),

    isDisabled() {
      if (!this.campaign || !this.campaign.account || !this.campaign.account.id) {
        return true
      }

      return this.contractStatus === 3
    }
  },
  methods: {
    ...mapActions('twitterInstantwinMessageHistory', [
      'fetchHistoryList',
      'fetchCampaign',
      'setHistoryParams',
      'updateResponder'
    ]),

    /**
     * Open dialog
     * @param {string} value
     */
    openDialog(value: string) {
      if (value.indexOf('reset') !== -1) {
        const type = value.replace('reset_', '')
        this.$refs.applicantResetDialog.open(type)
      } else {
        const type = value.replace('import_', '')
        this.$refs.applicantImportDialog.open(type)
      }
    },

    /**
     * ページを変更イベント
     * @param {number} pagingNo ページング
     */
    onChangePagingNo(pagingNo: number) {
      this.setHistoryParams({
        pagingNo
      })
      util.scrollToTop()
    },

    /**
     * ページを変更イベント
     * @param {number} limitCount リミット数
     */
    onChangeLimitCount(limitCount: number) {
      this.setHistoryParams({
        limitCount
      })
    },

    /**
     * ソートを変更イベント
     * @param {string} sortBy ソート
     */
    onChangeSortBy(sortBy: string) {
      if (this.params.sortBy === sortBy) {
        return
      }
      this.setHistoryParams({ sortBy })
    },

    /**
     * CSVダウンロード
     */
    async downloadCsv() {
      const confirm = window.confirm(
        '5万件までの当選者データをCSVダウンロードできます。\n' +
          'ダウンロードはキャンペーンごとに、1日1回のみです。\n' +
          'ダウンロードを開始してもよろしいですか？'
      )

      if (!confirm) {
        return
      }

      // CSV用履歴一覧を取得
      const histories = await this.getHistoriesForCsv()

      if (!histories.length) {
        return { error: 'ERROR_LOADING' }
      }

      await api.put(`campaigns/${this.$route.params.campaignId}/status`)

      const filename = `instantwin_message_winner_${moment().format('YYYY_MM_DD')}`
      const csvData = []

      csvData.push([
        this.$gettext('応募日時'),
        this.$gettext('種別'),
        this.$gettext('メッセージ名'),
        this.$gettext('アカウントID'),
        this.$gettext('アカウント名'),
        this.$gettext('アカウントスクリーン名'),
        this.$gettext('アカウントURL'),
        this.$gettext('アカウント画像'),
        this.$gettext('アカウント説明文'),
        this.$gettext('フォロワー'),
        this.$gettext('ツイート'),
        this.$gettext('応募ツイートURL'),
        this.$gettext('応募回数(累計)'),
        this.$gettext('応募回数(今回)'),
        this.$gettext('当選回数(累計)'),
        this.$gettext('当選回数(今回)'),
        this.$gettext('フォロー中'),
        this.$gettext('回答者')
      ])

      histories.forEach((message, index) => {
        csvData.push([
          this.$options.filters.datetime(message.entryDatetime),
          message.messageType,
          message.name,
          message.applicant.accountId,
          message.applicant.name,
          message.applicant.screenName,
          'https://twitter.com/' + message.applicant.screenName,
          message.applicant.pictureUrl,
          message.applicant.description ? message.applicant.description.replace(/(\r\n|\n|\r)/gm, '') : '',
          message.applicant.followersCount,
          message.applicant.tweetsCount,
          'https://twitter.com/' + message.applicant.accountId + '/status/' + message.entryTweetId,
          message.totalEntryCount || 0,
          message.currentEntryCount || 0,
          message.totalWinnerCount || 0,
          message.currentWinnerCount || 0,
          message.applicant.isFollowing ? 1 : 0,
          message.applicant.isResponder
        ])
      })

      this.fetchCampaign()

      return {
        filename,
        data: csvData
      }
    },

    /**
     * CSV用履歴一覧を取得
     */
    async getHistoriesForCsv() {
      this.loading = true
      const total = this.paginations.total

      const appliciantLimit = 50000
      const csvInfoTotal = Math.min(total, appliciantLimit)

      this.getTextLoading(0, csvInfoTotal)

      const params = Object.assign({}, this.params, {
        pagingNo: 1,
        limitCount: 1000,
        filterType: 'win'
      })

      let totalResults = []

      // 1000件ごとに取得する
      for (let count = 0; count < csvInfoTotal; count += 1000) {
        const result = await api.get('/twitter_instantwin_message_histories', { params })

        const surplus = (csvInfoTotal - count) / 1000

        if (surplus < 1) {
          this.getTextLoading(csvInfoTotal, csvInfoTotal)
        } else {
          this.getTextLoading(count + 1000, csvInfoTotal)
        }

        if (!result.data) {
          this.loading = false
          return []
        }

        totalResults = totalResults.concat(result.data.histories)

        params.pagingNo = params.pagingNo + 1
      }

      this.loading = false
      return totalResults
    },

    /**
     * メッセージの一括ダイアログを開く
     */
    openDirectMessageDialog() {
      this.$refs.directMessageDialog.open(this.campaignPrize.id, this.campaign.id)
    },

    /**
     * フラグ変更処理
     * @param {{type: string, value: any}} payload
     */
    async changeFlag(payload) {
      const type = payload.type
      const value = payload.value
      let responderResult

      switch (type) {
        case 'responder':
          responderResult = await this.updateResponder(value)
          // エラーがある場合はエラーメッセージを表示
          if (!responderResult.data) {
            this.showError(this.$gettext('回答者の保存に失敗しました'))
          }
          break
      }
    },

    openMessageHistory(payload) {
      this.$refs.directMessageHistoryDialog.open(payload.applicantId)
    },

    /**
     * エラーを表示
     * @params {string} title タイトル
     * @param {string} message
     */
    showError(title: string, message: string) {
      if (!message) {
        this.$notify({
          title,
          customClass: 'danger',
          duration: 5000
        })
      } else {
        this.$notify({
          title,
          message,
          customClass: 'danger',
          duration: 5000
        })
      }
    },

    /**
     * CSVダウンロードの時、進捗を表示する
     * @params {number} acquiredNumber
     * @params {number} totalNumber
     */
    getTextLoading(acquiredNumber: number, totalNumber: number) {
      if (!totalNumber) {
        this.loadingText = this.$gettext('CSVファイルの作成中です。しばらくお待ちください。')
        return
      }

      this.loadingText = this.$gettextInterpolate(
        this.$gettext('CSVファイルの作成中です。しばらくお待ちください。\n %{acquiredNumber}件 / %{totalNumber}件'),
        {
          acquiredNumber,
          totalNumber
        }
      )
    }
  },
  watch: {
    params() {
      this.fetchHistoryList()
    }
  }
}
