import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'

import { TrackingService } from '@/client/services'
import { API } from '@/client/utils/api'
import { TCampaign } from '@/typings/api/campaigns.type'
import { APIError } from '@/typings/api/error'
import { TApplicant, TGetApplicantsParams } from '@/typings/api/twitter_applicants.type'
import { TPrize } from '@/typings/api/twitter_campaign_prizes.type'

import { RootState } from './index'

const RESET = 'RESET'
const SET_APPLICANT_DATA = 'SET_APPLICANT_DATA'
const SET_CAMPAIGN = 'SET_CAMPAIGN'
const SET_CAMPAIGN_PRIZE = 'SET_CAMPAIGN_PRIZE'
const SET_FIRST_LOADED = 'SET_FIRST_LOADED'
const SET_ERROR = 'SET_ERROR'
const SET_PARAMS = 'SET_PARAMS'
const TOGGLE_SHOW_ADVANCE_FILTER = 'TOGGLE_SHOW_ADVANCE_FILTER'
const SET_IS_APPLICANT_DRAWER = 'SET_IS_APPLICANT_DRAWER'
const SET_APPLICANT_DRAWER_LOADING = 'SET_APPLICANT_DRAWER_LOADING'
const SET_DRAWER_APPLICANT_ID = 'SET_DRAWER_APPLICANT_ID'
const SET_APPLICANT_DRAWER_TAB = 'SET_APPLICANT_DRAWER_TAB'
const SET_API_APPLICANT_DRAWER = 'SET_API_APPLICANT_DRAWER'
const SET_IS_LOADING = 'SET_IS_LOADING'

type DrawerTab = '' | 'profile' | 'entryHistory' | 'winHistory' | 'streamHistory'

type UpdateApplicantOptions = {
  applicantId: number
  isWinner?: boolean
  wasWinner?: boolean
  isResponder?: boolean
  isCandidate?: boolean
  isNg?: boolean
}

type APIGetTwitterApplicantDrawerResponse = {
  id: number
  account_id: string
  screen_name: string
  name: string
  picture_url: string
  is_positive: boolean
  is_warning: boolean
  is_ng: boolean
  ng_applicant_id: number | null
  is_follower: boolean
  follows_count: number
  followers_count: number
  join_datetime: string | null
  description: string | null
  is_winner: boolean
  is_candidate: boolean
  is_responder: boolean
  positive_keywords: string[]
  negative_keywords: string[]
  posts: {
    id: number
    publish_datetime: string
  }[]
  entry_histories: {
    datetime: string | null
    campaign_name: string
  }[]
  win_histories: {
    datetime: string | null
    campaign_name: string
  }[]
  stream_histores: {
    id: number
    status: string
    body: string
    action_datetime: string
    send_type: string
  }[]
}

type GetTwitterApplicantDrawerResponse = {
  data?: APIGetTwitterApplicantDrawerResponse
  error?: APIError
}

export type TwitterApplicantState = {
  firstLoaded: boolean
  error: boolean
  total: number
  applicants: TApplicant[]
  params: TGetApplicantsParams
  campaign: TCampaign | null
  campaignPrize: TPrize | null
  showAdvanceFilter: boolean
  isApplicantDrawer: boolean
  applicantDrawerLoading: boolean
  drawerApplicantId: number | null
  applicantDrawerTab: DrawerTab
  apiApplicantDrawer: APIGetTwitterApplicantDrawerResponse | null
  isLoading: boolean
}

export type TwitterApplicantActions = {
  reset: () => Promise<void>
  fetchApplicants: () => Promise<void>
  fetchCampaign: () => Promise<void>
  fetchCampaignPrize: () => Promise<void>
  setParams: (payload: TGetApplicantsParams) => Promise<void>
  toggleShowAdvanceFilter: () => Promise<void>
  fetchApplicantDrawer: () => Promise<void>
  openApplicantDrawer: (payload: { applicantId: number; tab: string }) => Promise<void>
  closeApplicantDrawer: () => Promise<void>
  changeApplicantDrawerTab: (payload: DrawerTab) => Promise<void>
  updateApplicant: (payload: UpdateApplicantOptions) => Promise<any>
  prevApplicantDrawer: () => Promise<void>
  nextApplicantDrawer: () => Promise<void>
}

const initState: TwitterApplicantState = {
  firstLoaded: false,
  error: false,
  campaign: null,
  campaignPrize: null,
  applicants: [],
  total: 0,
  params: {
    campaignId: 0,
    prizeId: 0,
    sortBy: '-publishDatetime',
    pagingNo: 1,
    limitCount: 25,
    isNewFollower: '',
    postType: '',
    isMedia: '',
    isFollowing: '',
    isWinner: '',
    isWebInstantwinWinner: '',
    wasWinner: -1,
    isResponder: '',
    isCandidate: '',
    isWarning: '',
    isPositive: '',
    followersCount: ['', ''],
    followsRate: ['', ''],
    tweetsCount: ['', ''],
    totalCampaignEntryCount: ['', ''],
    totalCampaignWinningCount: ['', ''],
    postsCount: ['', ''],
    accountName: '',
    isJoinDatetime: '',
    isProfileImage: '',
    isDescription: '',
    isWidget: -1,
    forCsv: 0,
    hunterPoint: null
  },
  showAdvanceFilter: false,
  isApplicantDrawer: false,
  applicantDrawerLoading: false,
  drawerApplicantId: null,
  applicantDrawerTab: '',
  apiApplicantDrawer: null,
  isLoading: false
}

const state: TwitterApplicantState = { ...initState }

const getters: GetterTree<TwitterApplicantState, RootState> = {}

const mutations: MutationTree<TwitterApplicantState> = {
  [RESET](state) {
    const initStateData: any = initState
    Object.keys(initStateData).forEach(key => {
      state[key] = initStateData[key]
    })
  },

  [SET_APPLICANT_DATA](state, applicantData: { total: number; applicants: TApplicant[] }) {
    state.applicants = applicantData.applicants
    if (applicantData.total !== undefined) {
      state.total = applicantData.total
    }
  },

  [SET_CAMPAIGN](state, campaign: TCampaign) {
    if (!campaign.account) {
      campaign.account = {
        id: null,
        name: '削除されたアカウント',
        pictureUrl: ''
      }
    }
    state.campaign = campaign
  },

  [SET_CAMPAIGN_PRIZE](state, campaignPrize: TPrize) {
    state.campaignPrize = campaignPrize
  },

  [SET_PARAMS](state, params: TGetApplicantsParams) {
    state.params = params
  },

  [SET_FIRST_LOADED](state, loaded: boolean) {
    state.firstLoaded = loaded
  },

  [SET_ERROR](state, error: boolean) {
    state.error = error
  },

  [TOGGLE_SHOW_ADVANCE_FILTER](state) {
    state.showAdvanceFilter = !state.showAdvanceFilter
  },

  [SET_IS_APPLICANT_DRAWER](state, payload: boolean) {
    state.isApplicantDrawer = payload
  },

  [SET_APPLICANT_DRAWER_LOADING](state, payload: boolean) {
    state.applicantDrawerLoading = payload
  },

  [SET_DRAWER_APPLICANT_ID](state, payload: number | null) {
    state.drawerApplicantId = payload
  },

  [SET_APPLICANT_DRAWER_TAB](state, payload: DrawerTab) {
    state.applicantDrawerTab = payload
  },

  [SET_API_APPLICANT_DRAWER](state, payload: APIGetTwitterApplicantDrawerResponse | null) {
    state.apiApplicantDrawer = payload
  },

  [SET_IS_LOADING](state, payload: boolean) {
    state.isLoading = payload
  }
}

const actions: ActionTree<TwitterApplicantState, RootState> = {
  /**
   * ストアをリセット
   */
  async reset(context) {
    context.commit(RESET)
  },

  /**
   * 応募者一覧を取得
   */
  async fetchApplicants(context) {
    context.commit(SET_IS_LOADING, true)

    const params = context.state.params
    context.commit(SET_ERROR, false)

    await context.dispatch('system/setLoading', true, { root: true })
    const result = await API.get('/twitter_applicants', { params })

    await context.dispatch('system/setLoading', false, { root: true })
    context.commit(SET_FIRST_LOADED, true)
    context.commit(SET_IS_LOADING, false)

    if (!result.data) {
      context.commit(SET_ERROR, true)
      return
    }

    const applicantData: any = {
      applicants: result.data.applicants
    }

    if (params.pagingNo === 1) {
      applicantData.total = result.data.dataCount
    }
    context.commit(SET_APPLICANT_DATA, applicantData)

    context.commit(SET_IS_LOADING, false)
  },

  /**
   * キャンペーンデータを取得
   */
  async fetchCampaign(context) {
    const result = await API.get(`/campaigns/${context.state.params.campaignId}`)
    if (result.data) {
      context.commit(SET_CAMPAIGN, result.data)
    }
  },

  /**
   * 応募グループデータを取得
   */
  async fetchCampaignPrize(context) {
    const result = await API.get(`/twitter_campaign_prizes/${context.state.params.prizeId}`)
    if (result.data) {
      context.commit(SET_CAMPAIGN_PRIZE, result.data)
    }
  },

  /**
   * パラメーターをセット
   */
  async setParams(context, payload: TGetApplicantsParams) {
    if (!payload.pagingNo) {
      payload.pagingNo = 1
    }
    context.commit(SET_PARAMS, { ...context.state.params, ...payload })
  },

  /**
   * 詳細フィルターをトグル
   */
  async toggleShowAdvanceFilter(context) {
    TrackingService.sendEvent('click:応募一覧（X）|詳細検索を終了')

    context.commit(TOGGLE_SHOW_ADVANCE_FILTER)
  },

  /**
   * 応募者ドロワーデータの取得
   */
  async fetchApplicantDrawer(context) {
    if (!context.state.drawerApplicantId) return

    context.commit(SET_APPLICANT_DRAWER_LOADING, true)

    const response: GetTwitterApplicantDrawerResponse = await API.get(
      `v1/twitter_applicant_drawers/${context.state.drawerApplicantId}`
    )

    context.commit(SET_APPLICANT_DRAWER_LOADING, false)

    if (response.data) {
      context.commit(SET_API_APPLICANT_DRAWER, response.data)
    }
  },

  /**
   * 応募者ドロワーの表示
   */
  async openApplicantDrawer(
    context,
    payload: {
      applicantId: number
      tab: string
    }
  ) {
    context.commit(SET_IS_APPLICANT_DRAWER, true)

    context.commit(SET_DRAWER_APPLICANT_ID, payload.applicantId)

    context.commit(SET_APPLICANT_DRAWER_TAB, payload.tab)

    await context.dispatch('fetchApplicantDrawer')
  },

  /**
   * 応募者ドロワーを閉じる
   */
  async closeApplicantDrawer(context) {
    context.commit(SET_IS_APPLICANT_DRAWER, false)

    context.commit(SET_APPLICANT_DRAWER_LOADING, false)

    context.commit(SET_DRAWER_APPLICANT_ID, null)

    context.commit(SET_APPLICANT_DRAWER_TAB, '')

    context.commit(SET_API_APPLICANT_DRAWER, null)
  },

  /**
   * 応募者ドロワータブの変更
   */
  async changeApplicantDrawerTab(context, payload: DrawerTab) {
    context.commit(SET_APPLICANT_DRAWER_TAB, payload)
  },

  /**
   * 応募者情報の更新
   */
  async updateApplicant(context, payload: UpdateApplicantOptions) {
    if (payload.isNg === true && context.state.campaign && context.state.apiApplicantDrawer) {
      const response = await API.post('/twitter_ng_applicants', {
        groupId: context.state.campaign.groupId,
        screenNames: [context.state.apiApplicantDrawer.screen_name]
      })

      if (response.data) {
        await context.dispatch('fetchApplicants')
        await context.dispatch('fetchCampaignPrize')
        await context.dispatch('fetchApplicantDrawer')
      }

      return response
    }

    if (payload.isNg === false && context.state.apiApplicantDrawer) {
      const response = await API.delete(`/twitter_ng_applicants/${context.state.apiApplicantDrawer?.ng_applicant_id}`)

      if (response.data) {
        await context.dispatch('fetchApplicants')
        await context.dispatch('fetchCampaignPrize')
        await context.dispatch('fetchApplicantDrawer')
      }

      return response
    }

    const response = await API.put(`/twitter_applicants/${payload.applicantId}`, {
      prizeId: context.state.campaignPrize.id,
      isWinner: payload.isWinner,
      wasWinner: payload.wasWinner,
      isResponder: payload.isResponder,
      isCandidate: payload.isCandidate
    })

    if (response.data) {
      await context.dispatch('fetchApplicants')
      await context.dispatch('fetchCampaignPrize')
      await context.dispatch('fetchApplicantDrawer')
    }

    return response
  },

  /**
   * 応募者ドロワーの前ページ移動
   */
  async prevApplicantDrawer(context) {
    switch (context.state.applicantDrawerTab) {
      case 'profile':
        TrackingService.sendEvent('click:ユーザー詳細|プロフィール|ページネーション|前へ')
        break
      case 'entryHistory':
        TrackingService.sendEvent('click:ユーザー詳細|参加履歴|ページネーション|前へ')
        break
      case 'winHistory':
        TrackingService.sendEvent('click:ユーザー詳細|当選履歴|ページネーション|前へ')
        break
      case 'streamHistory':
        TrackingService.sendEvent('click:ユーザー詳細|配信履歴|ページネーション|前へ')
        break
      default:
        break
    }

    context.commit(SET_APPLICANT_DRAWER_LOADING, true)

    if (!context.state.applicants || !context.state.drawerApplicantId) {
      context.commit(SET_APPLICANT_DRAWER_LOADING, false)
      return
    }

    const applicant_ids = context.state.applicants.map(v => v.id)

    const index = applicant_ids.indexOf(context.state.drawerApplicantId)

    if (index === 0 && context.state.params.pagingNo === 1) {
      context.commit(SET_APPLICANT_DRAWER_LOADING, false)
      return
    }

    if (index === 0 && context.state.params.pagingNo > 1) {
      const prev_page_no = context.state.params.pagingNo - 1

      await context.dispatch('setParams', { pagingNo: prev_page_no })
      await context.dispatch('fetchApplicants')

      const prev_applicant_id = context.state.applicants[context.state.params.limitCount - 1]
        ? context.state.applicants[context.state.params.limitCount - 1].id
        : null

      if (!prev_applicant_id) {
        context.commit(SET_APPLICANT_DRAWER_LOADING, false)
        return
      }

      context.commit(SET_DRAWER_APPLICANT_ID, prev_applicant_id)

      await context.dispatch('fetchApplicantDrawer')

      return
    }

    const prev_applicant_id = context.state.applicants[index - 1] ? context.state.applicants[index - 1].id : null

    if (!prev_applicant_id) {
      context.commit(SET_APPLICANT_DRAWER_LOADING, false)
      return
    }

    context.commit(SET_DRAWER_APPLICANT_ID, prev_applicant_id)

    await context.dispatch('fetchApplicantDrawer')

    return
  },

  /**
   * 応募者ドロワーの次ページ移動
   */
  async nextApplicantDrawer(context) {
    switch (context.state.applicantDrawerTab) {
      case 'profile':
        TrackingService.sendEvent('click:ユーザー詳細|プロフィール|ページネーション|次へ')
        break
      case 'entryHistory':
        TrackingService.sendEvent('click:ユーザー詳細|参加履歴|ページネーション|次へ')
        break
      case 'winHistory':
        TrackingService.sendEvent('click:ユーザー詳細|当選履歴|ページネーション|次へ')
        break
      case 'streamHistory':
        TrackingService.sendEvent('click:ユーザー詳細|配信履歴|ページネーション|次へ')
        break
      default:
        break
    }

    context.commit(SET_APPLICANT_DRAWER_LOADING, true)

    if (!context.state.applicants || !context.state.drawerApplicantId) {
      context.commit(SET_APPLICANT_DRAWER_LOADING, false)
      return
    }

    const applicant_ids = context.state.applicants.map(v => v.id)

    const index = applicant_ids.indexOf(context.state.drawerApplicantId)

    if (
      index === applicant_ids.length - 1 &&
      context.state.params.pagingNo * context.state.params.limitCount >= context.state.total
    ) {
      context.commit(SET_APPLICANT_DRAWER_LOADING, false)
      return
    }

    if (
      index === applicant_ids.length - 1 &&
      context.state.params.pagingNo * context.state.params.limitCount < context.state.total
    ) {
      const next_page_no = context.state.params.pagingNo + 1

      await context.dispatch('setParams', { pagingNo: next_page_no })
      await context.dispatch('fetchApplicants')

      const next_applicant_id = context.state.applicants[0] ? context.state.applicants[0].id : null

      if (!next_applicant_id) {
        context.commit(SET_APPLICANT_DRAWER_LOADING, false)
        return
      }

      context.commit(SET_DRAWER_APPLICANT_ID, next_applicant_id)

      await context.dispatch('fetchApplicantDrawer')

      return
    }

    const next_applicant_id = context.state.applicants[index + 1] ? context.state.applicants[index + 1].id : null

    if (!next_applicant_id) {
      context.commit(SET_APPLICANT_DRAWER_LOADING, false)
      return
    }

    context.commit(SET_DRAWER_APPLICANT_ID, next_applicant_id)

    await context.dispatch('fetchApplicantDrawer')

    return
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
} as Module<TwitterApplicantState, RootState>
