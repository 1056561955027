import moment from 'moment'
import twitterText from 'twitter-text'
import { mapActions, mapState } from 'vuex'

import ApplicantName from '@/client/components/basic/applicant_name'
import CsvDownload from '@/client/components/basic/csv_download'
import Pagination from '@/client/components/basic/pagination'
import SelectForm from '@/client/components/basic/select_form'
import api from '@/client/core/api'
import Util from '@/client/core/util'

export default {
  data() {
    return {
      title: this.$gettext('シリアルコードの管理'),
      visible: false,
      messageId: '',
      status: {
        value: '',
        options: [
          {
            label: this.$gettext('指定なし'),
            value: ''
          },
          {
            label: this.$gettext('割り当て済'),
            value: 'assign'
          },
          {
            label: this.$gettext('未割り当て'),
            value: 'unassigned'
          }
        ],
        onChange: (value: number) => {
          this.status.value = value
          if (this.params.filter === value) {
            return
          }

          // オプションを切り替えるときは 1 ページに戻る
          this.setSerialCodeListParams({ filter: value, pagingNo: 1 })
        }
      },
      action: {
        options: [
          {
            label: this.$gettext('使用済みコードインポート'),
            value: 'serial-code-used-import'
          },
          {
            label: this.$gettext('使用済みコード解除'),
            value: 'serial-code-used-resets'
          },
          {
            label: this.$gettext('未割り当てコード削除'),
            value: 'serial-code-unassigned-delete'
          }
        ]
      }
    }
  },
  props: {
    isEdit: {
      type: Boolean,
      default: false
    }
  },
  components: {
    CsvDownload,
    SelectForm,
    Pagination,
    ApplicantName
  },
  watch: {
    params() {
      this.fetchSerialCodeList()
    },
    serialCodes() {
      this.status.options[0].label =
        this.$gettext('指定なし') + ` (${this.dataCount.countAssign + this.dataCount.countUnassign})`
      this.status.options[1].label = this.$gettext('割り当て済') + ` (${this.dataCount.countAssign})`
      this.status.options[2].label = this.$gettext('未割り当て') + ` (${this.dataCount.countUnassign})`
    },
    messageId() {
      this.setMessageId(this.messageId)

      // オプションを切り替えるときは 1 ページに戻る
      this.setSerialCodeListParams({ pagingNo: 1 })
    }
  },
  mounted() {
    this.messageId = this.currentMessageId
    if (this.isEdit) {
      this.action = {
        options: [
          {
            label: this.$gettext('未割り当てコードの削除'),
            value: 'serial-code-unassigned-delete'
          }
        ]
      }
    }
  },
  computed: {
    ...mapState('twitterInstantwinSerialCode', {
      currentMessageId: state => state.messageId,
      serialCodes: state => state.serialCode.list,
      params: state => state.serialCode.params,
      total: state => state.serialCode.total,
      dataCount: state => {
        return {
          countAssign: state.serialCode.countAssign,
          countUnassign: state.serialCode.countUnassign
        }
      }
    }),

    ...mapState('twitterInstantwinMessage', {
      messages: state => state.messages.filter(message => message.messageType === 'win')
    }),

    existSerialCodes() {
      return this.serialCodes.length > 0
    }
  },
  methods: {
    ...mapActions('twitterInstantwinSerialCode', ['fetchSerialCodeList', 'setSerialCodeListParams', 'setMessageId']),
    ...mapActions('twitterInstantwinMessage', ['fetchInstantwinMessages']),

    changeTab(tab: string) {
      this.$emit('change_tab', tab)
    },

    /**
     * Set message id
     * @param {number} messageId
     */
    changeMessageId(messageId: number) {
      this.messageId = messageId
    },

    /**
     * Change status value
     * @param {string} status
     */
    changeStatus(status: string) {
      this.status.value = status
    },

    /**
     * シリアルコードにURLが含まれる場合、アンカーリンクにする
     * @param {string} serialCode
     * @returns {string}
     */
    convertSerialCode(serialCode: string): string {
      const escapeSerialCode = this.htmlEscape(serialCode)
      const entities = twitterText.extractEntitiesWithIndices(escapeSerialCode, {
        extractUrlsWithoutProtocol: true
      })
      return twitterText.autoLinkEntities(escapeSerialCode, entities, {
        targetBlank: true
      })
    },
    /**
     * HTMLで使用する特殊文字を除去する
     */
    htmlEscape(text: string): string {
      const entities = {
        '>': '&gt;',
        '<': '&lt;',
        '"': '&quot;',
        "'": '&#39;'
      }

      return text.replace(/["'><]/g, character => entities[character])
    },
    /**
     * Delete serial code
     * @param {number} serialCodeId
     */
    async deleteSerialCode(serialCodeId: number) {
      const confirm = window.confirm(this.$gettext('シリアルコードを削除してよろしいですか?'))

      if (!confirm) {
        return
      }

      const result = await api.delete(`/twitter_instantwin_serial_codes/${serialCodeId}`)

      if (result.error && result.error.type === 'NOT_EXISTS') {
        this.$notify({
          title: this.$gettext('対象のシリアルコードはすでに削除されています。'),
          customClass: 'danger',
          duration: 5000
        })

        this.fetchSerialCodeList()
        this.fetchInstantwinMessages()
        return
      }

      // エラーがある場合はエラーメッセージを表示
      if (!result.data) {
        this.$notify({
          title: this.$gettext('シリアルコードの削除に失敗しました。'),
          message: this.$gettext('恐れ入りますが、時間をおいて再度お試しください。'),
          customClass: 'danger',
          duration: 5000
        })
        return
      }

      // 成功メッセージを表示
      this.$notify({
        title: this.$gettext('シリアルコードを削除しました。'),
        customClass: 'success',
        duration: 5000
      })

      this.fetchSerialCodeList()
      this.fetchInstantwinMessages()
    },

    /**
     * CSVダウンロード
     */
    async downloadCsv() {
      const campaignId = this.campaignId
      const params = Object.assign({ campaignId }, this.params)
      params.limitCount = -1

      const result = await api.get('/twitter_instantwin_serial_codes', {
        params
      })

      if (!result.data) {
        return {
          error: 'ERROR_LOADING'
        }
      }

      const serialCodeList = result.data.serialCode
      const filename = `campaign_instantwin_serial_codes_${moment().format('YYYY_MM_DD')}`
      const csvData = []

      csvData.push([
        this.$gettext('No'),
        this.$gettext('対象メッセージ'),
        this.$gettext('ステータス'),
        this.$gettext('シリアルコード'),
        this.$gettext('使用済み')
      ])

      serialCodeList.forEach((serialCode, index) => {
        csvData.push([
          index + 1,
          serialCode.messageName,
          serialCode.applicant ? this.$gettext('割り当て済') : this.$gettext('未割り当て'),
          serialCode.serialCode,
          serialCode.userIsUsed
        ])
      })

      return {
        filename,
        data: csvData
      }
    },

    /**
     * シリアルコード一覧ソートを変更イベント
     * @param {string} sortBy ソート
     */
    onChangeSortBy(sortBy: string) {
      if (this.params.sortBy === sortBy) {
        return
      }
      this.setSerialCodeListParams({ sortBy })
    },

    /**
     * ページを変更イベント
     * @param {number} pagingNo ページング
     */
    onChangePagingNo(pagingNo: number) {
      this.setSerialCodeListParams({
        pagingNo
      })
    },

    /**
     * ページを変更イベント
     * @param {number} limitCount リミット数
     */
    onChangeLimitCount(limitCount: number) {
      this.setSerialCodeListParams({
        limitCount,
        pagingNo: 1
      })
    },

    /**
     * ヘッダーをクリックするとソートする
     * @param {*} column
     */
    headerClick(column: any) {
      if (column.sortBy.indexOf('-') === 0) {
        if (this.params.sortBy === column.sortBy) {
          this.onChangeSortBy(column.sortBy.substring(1))
        } else {
          this.onChangeSortBy(column.sortBy)
        }
      } else {
        if (this.params.sortBy === column.sortBy) {
          this.onChangeSortBy('-' + column.sortBy)
        } else {
          this.onChangeSortBy(column.sortBy)
        }
      }
    },

    /**
     * ヘッダー描画
     * @param {*} createElement
     * @param {*} { column }
     */
    renderHeader(createElement: any, { column }: any) {
      return Util.renderHeader(createElement, column.label, Util.getSortClass(this.params.sortBy, column.sortBy))
    },

    /**
     * 選択した一括操作を実行
     * @param {string} action action
     */
    selectAction(action: string) {
      this.changeTab(action)
    }
  }
}
