/* @flow */
import ApplicantName from '@/client/components/basic/applicant_name'
import api from '@/client/core/api'

export default {
  components: {
    ApplicantName
  },
  data() {
    return {
      title: '',
      loading: false,
      error: false,
      visible: false,
      result: {
        applicant: {},
        messages: []
      }
    }
  },
  methods: {
    /**
     * ポスト内容をHTMLに変換
     * @param {string} text ポストテキスト
     */
    tweetText(text: string): string {
      return this.$options.filters.tweetText(text)
    },

    /**
     * ダイアログを表示
     * @param {number} applicantId 応募者ID
     */
    open(applicantId: number) {
      this.visible = true

      const params = {
        applicantId
      }

      this.getMessageHistory(params).catch(() => {
        this.alert()
      })
    },

    /**
     * ダイアログを非表示
     */
    close() {
      this.visible = false
    },

    /**
     * エラー時の処理
     */
    alert() {
      this.$notify({
        title: this.$gettext('メッセージ履歴がありません。'),
        customClass: 'danger',
        duration: 5000
      })

      this.close()
    },

    /**
     * DM配信履歴を取得する
     * @param {Object<any>} params query params
     */
    async getMessageHistory(params: any) {
      this.loading = true
      const response = await api.get('/twitter_instantwin_queue_messages', { params })

      this.loading = false
      if (!response.data) {
        this.alert()
        return
      }

      this.result = response.data
      this.title = this.$gettextInterpolate(this.$gettext('メッセージ配信履歴(%{count}件)'), {
        count: response.data.messages.length
      })
    }
  }
}
