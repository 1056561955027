import moment from 'moment'
import { mapActions, mapState } from 'vuex'

import { CampaignUnauthenticatedAccount } from '@/client/components/_organisms'
import DialogCampaignCreate from '@/client/components/_organisms/DialogCampaignCreate'
import CsvDownload from '@/client/components/basic/csv_download'
import CampaignAddEditDialog from '@/client/components/campaign/campaign_add_edit_dialog'
import CampaignFilter from '@/client/components/campaign/campaign_filter'
import CampaignTable from '@/client/components/campaign/campaign_table'
import { CAMPAIGN_STATUS } from '@/client/constant'
import api from '@/client/core/api'
import util from '@/client/core/util'
import { TrackingService } from '@/client/services'

export default {
  components: {
    CampaignTable,
    CsvDownload,
    CampaignAddEditDialog,
    DialogCampaignCreate,
    CampaignUnauthenticatedAccount,
    CampaignFilter
  },
  async created() {
    this.setBreadcrumb([
      {
        name: this.$gettext('キャンペーン一覧'),
        link: null
      }
    ])
    // キャンペーンパラメーターをセット
    if (this.currentGroupId) {
      this.setParams({ groupId: this.currentGroupId })
    }

    // ゲストグループの場合はアカウント追加画面にリダイレクト
    if (this.guestGroups.some(v => v.id === this.currentGroupId)) {
      this.$router.push('/settings/group#accounts')
    }
  },
  computed: {
    ...mapState('system', {
      currentGroupId: state => state.currentGroup.id,
      contractStatus: state => state.currentGroup.contractStatus,
      group: state => state.currentGroup,
      groups: state => state.groups,
      allowInstantwin: state => state.currentGroup.allowInstantwin,
      allowCreateInstantwin: state => state.currentGroup.allowCreateInstantwin,
      isUserAdmin: state => state.currentGroup.isUserAdmin,
      isSystemAdmin: state => Boolean(state.user.isSystemAdmin),
      guestGroups: state => state.guestGroups
    }),

    ...mapState('campaign', {
      params: state => state.params,
      campaigns: state => {
        return state.campaigns.map(campaign => {
          // キャンペーンアカウントが削除された場合はデフォルトを設定する
          if (!campaign.account) {
            campaign.account = {
              name: '削除されたアカウント'
            }
          }
          return campaign
        })
      },
      options: state => {
        return {
          total: state.total,
          pagingNo: state.params.pagingNo,
          limitCount: state.params.limitCount,
          sortBy: state.params.sortBy
        }
      },
      isFiltered: state => state.isFiltered,
      loading: state => state.loading,
      firstLoaded: state => state.firstLoaded,
      error: state => state.error
    }),

    isUnlimitedInstantwin() {
      return this.group.maxInstantwinCount !== 0
    },

    isUnlimitedWebInstantwin() {
      return this.group.maxWebInstantwinCount !== 0
    },

    isUnlimitedCampaign() {
      return this.group.maxCampaignCount !== 0
    },

    isUnlimitedMonthlyApplicant() {
      return this.group.maxMonthlyApplicantCount !== 0
    },

    isUnlimitedMonthlyDm() {
      return this.group.maxMonthlyDmCount !== 0
    },

    isAddInstantwin() {
      return this.allowInstantwin && (this.isSystemAdmin || this.allowCreateInstantwin)
    },

    currentMonth() {
      return moment().format('M')
    }
  },
  watch: {
    // 現在グループIDが変更された場合はキャンペーンパラメーターをセット
    currentGroupId() {
      this.setParams({ groupId: this.currentGroupId })
    },
    // パラメーターが変更された場合はキャンペーンデータを取得
    params() {
      this.fetchCampaigns()
    }
  },
  methods: {
    ...mapActions('navbar', ['setBreadcrumb']),
    ...mapActions('campaign', ['reset', 'fetchCampaigns', 'setParams']),

    /**
     * ページを変更イベント
     * @param {number} page ページ
     */
    onChangePagingNo(page: number) {
      if (this.params.pagingNo === page) {
        return
      }
      this.setParams({ pagingNo: page })
      util.scrollToTop()
    },

    /**
     * リミット数を変更イベント
     * @param {number} limitCount リミット数
     */
    onChangeLimitCount(limitCount: number) {
      if (this.params.limitCount === limitCount) {
        return
      }
      this.setParams({ limitCount })
    },

    /**
     * ソートを変更イベント
     * @param {string} sortBy ソート
     */
    onChangeSortBy(sortBy: string) {
      if (this.params.sortBy === sortBy) {
        return
      }
      this.setParams({ sortBy })
    },

    /**
     * キャンペーンの新規作成ダイアログを開く
     */
    openCampaignCreateDialog() {
      TrackingService.sendEvent('click:キャンペーン一覧|キャンペーンの新規作成')

      this.$refs.DialogCampaignCreate.open()
    },

    openInstantwinCreateDialog() {
      TrackingService.sendEvent('click:キャンペーン一覧|インスタントウィンの新規作成')

      this.$refs.CampaignAddEditDialog.open('addTwitter', null, 'instantwin')
    },

    /**
     * キャンペーン開催アカウントの管理
     */
    openAccountCreateCampaignSetting() {
      TrackingService.sendEvent('click:キャンペーン一覧|キャンペーン開催アカウントの管理')

      this.$router.push('/settings/group')
    },

    /**
     * CSVダウンロード
     */
    async downloadCsv() {
      TrackingService.sendEvent('click:キャンペーン一覧|CSVダウンロード')

      const params = Object.assign({}, this.params, {
        limitCount: -1
      })
      const result = await api.get('/campaigns', {
        params
      })
      if (!result.data) {
        return {
          error: 'ERROR_LOADING'
        }
      }
      const campaigns = result.data.campaigns
      const filename = `campaigns_${moment().format('YYYY_MM_DD')}`
      const csvData = []
      csvData.push([
        this.$gettext('ステータス'),
        this.$gettext('タイトル'),
        this.$gettext('開始日時'),
        this.$gettext('終了日時'),
        this.$gettext('応募者数'),
        this.$gettext('当選者数'),
        this.$gettext('当選者数(残り)'),
        this.$gettext('フォロワー増減数'),
        this.$gettext('推定リーチ')
      ])

      // CSVデータを格納
      campaigns.forEach(campaign => {
        let status = ''

        switch (campaign.status) {
          case CAMPAIGN_STATUS.BEFORE_EVENT:
            status = this.$gettext('開催前')
            break
          case CAMPAIGN_STATUS.BEFORE_COLLECTION:
            status = this.$gettext('開催中(応募収集待ち)')
            break
          case CAMPAIGN_STATUS.DURING_COLLECTION:
            status = this.$gettext('開催中(応募収集中)')
            break
          case CAMPAIGN_STATUS.DURING_FINAL_COLLECTION:
            status = this.$gettext('開催終了(最終集計中)')
            break
          case CAMPAIGN_STATUS.COLLECTION_COMPLETED:
            status = this.$gettext('開催終了(全データ集計完了)')
            break
        }

        csvData.push([
          status,
          campaign.title,
          this.$options.filters.datetime(campaign.startDatetime),
          this.$options.filters.datetime(campaign.endDatetime),
          campaign.applicantsCount,
          campaign.maxWinnerCount,
          campaign.maxWinnerCount - campaign.winnerCount,
          campaign.followerUpDown,
          campaign.estimatedReachCount
        ])
      })
      return {
        filename,
        data: csvData
      }
    }
  },
  beforeRouteLeave(to: any, from: any, next: any) {
    // キャンペーンストアをリセット
    this.reset()
    next()
  }
}
