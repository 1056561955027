/* @flow */
import { mapActions, mapState } from 'vuex'

import api from '@/client/core/api'
import { TrackingService } from '@/client/services'

export default {
  data() {
    return {
      randomCount: 1,
      visible: false,
      loading: false,
      isExcludeWasWinner: true,
      isCheckHunterAccount: true,
      isExcludeWinnerCampaigns: false,
      isExcludeWebInstantwinWinner: true,
      isSubmit: false,
      randomType: 'winner',
      oldRandomCount: 1,
      hunterFilteringStrength: 5
    }
  },
  computed: {
    ...mapState('twitterApplicant', {
      applicantCount: state => state.total,
      campaignId: state => state.campaign.id,
      campaignType: state => state.campaign?.type,
      campaignAccountId: state => state.campaign.account.id,
      prizeId: state => state.campaignPrize.id,
      applicantParams: state => state.params,
      remainWinnerCount: state => {
        if (!state.campaignPrize) {
          return 0
        }
        return state.campaignPrize.maxWinnerCount - state.campaignPrize.winnerCount
      }
    }),
    maxRandomCount() {
      if (this.randomType === 'candidate') {
        return this.applicantCount < 999 ? this.applicantCount : 999
      }

      return this.applicantCount < this.remainWinnerCount ? this.applicantCount : this.remainWinnerCount
    },
    url() {
      return this.randomType === 'candidate'
        ? '/twitter_applicants/random_candidates'
        : '/twitter_applicants/random_winners'
    }
  },
  watch: {
    randomType() {
      if (this.randomCount <= this.maxRandomCount) {
        return
      }

      this.randomCount = this.maxRandomCount
    }
  },
  methods: {
    ...mapActions('twitterApplicant', ['fetchApplicants', 'fetchCampaignPrize']),
    /**
     * ダイアログリセット
     */
    reset() {
      this.randomCount = 1
      this.loading = false
      this.isSubmit = false
      this.closeOnClickModal = true
      this.randomType = 'winner'
    },

    /**
     * フォームサブミット
     */
    async submit() {
      TrackingService.sendEvent('click:応募一覧|ランダム抽選（X）|ランダム抽選を実行')

      if (this.isSubmit) {
        return
      }
      const confirm = window.confirm(this.$gettext('この操作は取り消しできません、実行してもよろしいですか?'))
      if (!confirm) {
        return
      }
      this.isSubmit = true
      this.loading = true

      const applicantParamsClone = { ...this.applicantParams }

      // 過去に当選したことのある応募者を除外する
      if (this.isExcludeWasWinner) {
        applicantParamsClone.wasWinner = 0
      }

      // WEB抽選で当選している応募者を除外する
      if (this.campaignType === 'web_instantwin' && this.isExcludeWebInstantwinWinner) {
        applicantParamsClone.isWebInstantwinWinner = 0
      }

      const params: {
        campaignId: number,
        prizeId: number,
        randomCount: number,
        checkHunter: boolean,
        applicantParams: typeof applicantParamsClone,
        isExcludeWinnerCampaigns: boolean,
        hunterPoint?: number
      } = {
        campaignId: this.campaignId,
        prizeId: this.prizeId,
        randomCount: this.randomCount,
        checkHunter: this.isCheckHunterAccount,
        applicantParams: applicantParamsClone,
        isExcludeWinnerCampaigns: this.isExcludeWinnerCampaigns
      }

      if (this.isCheckHunterAccount) {
        params.hunterPoint = this.getHunterPoint(this.hunterFilteringStrength)
      }

      const result = await api.post(this.url, params)

      this.loading = false

      setTimeout(() => {
        this.isSubmit = false
      }, 2000)

      // 残り当選者数が足りない場合はエラーを表示
      if (result && result.error && result.error.type === 'REMAIN_WINNER_OVER') {
        this.$notify({
          title: this.$gettext('残り当選者数が足りません。'),
          message: this.$gettext('当選者数を変更して、再度お試しください。'),
          customClass: 'danger',
          duration: 5000
        })
        this.fetchCampaignPrize()
        this.fetchApplicants()
        return
      }

      // 失敗した場合はエラーを表示
      if (!result || isNaN(result.data)) {
        this.$notify({
          title: this.$gettext('ランダム抽選に失敗しました。'),
          message: this.$gettext('恐れ入りますが、時間をおいて再度お試しください。'),
          customClass: 'danger',
          duration: 5000
        })
        return
      }

      const message =
        this.randomType === 'winner'
          ? this.$gettextInterpolate(this.$gettext('%{randomCount}人の応募者を当選者にしました。'), {
              randomCount: result.data
            })
          : this.$gettextInterpolate(this.$gettext('%{randomCount}人の応募者を候補者にしました。'), {
              randomCount: result.data
            })

      // 成功メッセージを表示
      this.$notify({
        title: this.$gettext('ランダム抽選が完了しました。'),
        message,
        customClass: 'success',
        duration: 5000
      })

      this.fetchCampaignPrize()
      this.fetchApplicants()
      this.close()
    },

    /**
     * 懸賞フィルタ強度を懸賞ポイントに変換
     */
    getHunterPoint(hunterFilteringStrength: number) {
      switch (hunterFilteringStrength) {
        case 8:
          return -3
        case 7:
          return -2
        case 6:
          return -1
        case 5:
          return 0
        case 4:
          return 1
        case 3:
          return 2
        case 2:
          return 3
        case 1:
          return 4
        default:
          return 4
      }
    },

    /**
     * 応募者数変更イベント
     * @param {number} value バリュー
     */
    onChangeRandomCount(value: number) {
      if (value === this.oldRandomCount + 1) {
        TrackingService.sendEvent('click:応募一覧|ランダム抽選（X）|抽選数|UP')
      } else if (value === this.oldRandomCount - 1) {
        TrackingService.sendEvent('click:応募一覧|ランダム抽選（X）|抽選数|DOWN')
      } else TrackingService.sendEvent('input:応募一覧|ランダム抽選（X）|抽選数')

      this.oldRandomCount = value

      if (!value) {
        setTimeout(() => {
          this.randomCount = 1
        }, 100)
      }
    },

    open() {
      this.visible = true
      this.reset()
    },
    close() {
      TrackingService.sendEvent('click:応募一覧|ランダム抽選（X）|閉じる')

      this.visible = false
    },

    handleRadioChange(value: string) {
      TrackingService.sendEvent(`radio:応募一覧|ランダム抽選（X）|${value === 'winner' ? '当選者' : '候補者'}`)
    },

    changeSwitchEvent(value: string) {
      switch (value) {
        case 'isCheckHunterAccount':
          TrackingService.sendEvent('switch:応募一覧|ランダム抽選（X）|懸賞アカウントをフィルタする')
          break
        case 'isExcludeWasWinner':
          TrackingService.sendEvent('switch:応募一覧|ランダム抽選（X）|当選者した応募者を除外')
          break
        case 'isExcludeWinnerCampaigns':
          TrackingService.sendEvent('switch:応募一覧|ランダム抽選（X）|過去の当選者を除外')
          break
        case 'isExcludeWebInstantwinWinner':
          TrackingService.sendEvent('switch:応募一覧|ランダム抽選（X）|WEB抽選で当選者を除外')
          break
        default:
          break
      }
    },

    onSliderChange() {
      TrackingService.sendEvent('slider:応募一覧|ランダム抽選（X）|フィルタリング強度')
    }
  }
}
