










































































































































































































































































































































































































































































































































import { isValidHashtag } from 'twitter-text'
import { Component, Emit, Vue } from 'vue-property-decorator'
import { mapActions, mapState } from 'vuex'

import Message from '@/client/components/_atoms/Message.vue'
import Panel from '@/client/components/_atoms/Panel.vue'
import Tips from '@/client/components/_atoms/Tips.vue'
import FormItemLabel from '@/client/components/_molecules/FormItemLabel.vue'
import Tags from '@/client/components/_molecules/Tags.vue'
import EditableComponent from '@/client/components/basic/editable/editable.vue'
import MessageConvert from '@/client/components/basic/message_convert'
import TagInput from '@/client/components/basic/tag_input'
import { TWITTER_ENTRY_ACTION_TYPES } from '@/client/constant'
import {
  CAMPAIGN_APPLICATION_TYPES,
  CAMPAIGN_STATUS,
  CAMPAIGN_TYPES,
  WEB_INSTANTWIN_APPLICATION_TYPES
} from '@/client/constant'
import api from '@/client/core/api'
import TwitterUpload from '@/client/core/twitter_upload'
import { TrackingService } from '@/client/services'
import { CampaignDialogActions, CampaignDialogState } from '@/client/store/campaign_dialog.module'
import { SystemState } from '@/client/store/system.store'

@Component({
  components: {
    FormItemLabel,
    Message,
    Panel,
    Tips,
    Tags,
    TagInput,
    MessageConvert,
    EditableComponent
  },
  computed: {
    ...mapState('campaign_dialog', ['form', 'mode', 'api_campaign']),
    ...mapState('system', ['currentGroup', 'user'])
  },
  methods: {
    ...mapActions('campaign_dialog', ['setForm'])
  }
})
export default class CampaignCreateTwitterCondition extends Vue {
  api_campaign!: CampaignDialogState['api_campaign']
  form!: CampaignDialogState['form']
  mode!: CampaignDialogState['mode']
  setForm!: CampaignDialogActions['setForm']
  currentGroup!: SystemState['currentGroup']
  user!: SystemState['user']

  retweet_action_type = 'retweet'
  tweet_action_type = 'tweet'
  is_include_mention = true
  is_include_reply = true
  exclude_hunter_account = true
  exclude_account_create_date_within_period = true
  exclude_default_profile_image = true
  exclude_no_description = true
  min_followers = 0
  min_tweets = 0
  is_keyword_judgment = true
  page_main_image_url: string | null = null
  upload_media_type_loading: 'main' | null = null
  is_change_page_title = false
  is_change_page_text = false
  is_change_page_rules = false
  highlights = [
    {
      type: 'info',
      keywords: ['_account_name_', '_screen_name_', '_account_id_', '_serial_code_']
    }
  ]

  created() {
    if (!TWITTER_ENTRY_ACTION_TYPES.map(v => v.value).includes(this.form.entry_action_type)) {
      const form = {
        ...this.form,
        entry_action_type: 'retweet'
      }

      this.setForm(form)
    }

    this.is_include_mention = Boolean(this.form.is_include_mention)
    this.is_include_reply = Boolean(this.form.is_include_reply)
    this.exclude_hunter_account = Boolean(this.form.exclude_hunter_account)
    this.exclude_account_create_date_within_period = Boolean(this.form.exclude_account_create_date_within_period)
    this.exclude_default_profile_image = Boolean(this.form.exclude_default_profile_image)
    this.exclude_no_description = Boolean(this.form.exclude_no_description)
    this.min_followers = Number(this.form.min_followers)
    this.min_tweets = Number(this.form.min_tweets)
    this.is_keyword_judgment = Boolean(this.form.is_keyword_judgment)
    this.page_main_image_url = this.form.page_main_image_url || null
  }

  @Emit('cancel')
  cancel(payload) {
    return payload
  }

  get application_type() {
    switch (this.form.entry_action_type) {
      case 'retweet':
      case 'quote_tweet':
      case 'retweet_and_quote_tweet':
        this.retweet_action_type = this.form.entry_action_type
        return 'retweet'
      case 'tweet':
      case 'tweet_media':
      case 'tweet_conversational':
        this.tweet_action_type = this.form.entry_action_type
        return 'tweet'
      case 'mention':
        return 'mention'
      default:
        return 'retweet'
    }
  }

  get campaign_type_options() {
    if (this.currentGroup.allowWebInstantwin) {
      return CAMPAIGN_TYPES
    }

    return CAMPAIGN_TYPES.filter(v => v.value !== 'web_instantwin')
  }

  get application_type_options() {
    if (this.is_keyword_campaign) {
      return CAMPAIGN_APPLICATION_TYPES
    }

    return WEB_INSTANTWIN_APPLICATION_TYPES
  }

  get is_keyword_campaign() {
    return this.form.campaign_type === 'keyword'
  }

  get is_web_instantwin_campaign() {
    return this.form.campaign_type === 'web_instantwin'
  }

  get is_retweet_campaign() {
    return this.application_type === 'retweet'
  }

  get is_tweet_campaign() {
    return this.application_type === 'tweet'
  }

  get is_mention_campaign() {
    return this.application_type === 'mention'
  }

  get is_retweet_action() {
    return this.form.entry_action_type === 'retweet'
  }

  get is_quote_tweet_action() {
    return this.form.entry_action_type === 'quote_tweet'
  }

  get is_retweet_and_quote_tweet_action() {
    return this.form.entry_action_type === 'retweet_and_quote_tweet'
  }

  get is_tweet_action() {
    return this.form.entry_action_type === 'tweet'
  }

  get is_tweet_media_action() {
    return this.form.entry_action_type === 'tweet_media'
  }

  get is_tweet_conversational_action() {
    return this.form.entry_action_type === 'tweet_conversational'
  }

  get is_allow_instantwin() {
    return Boolean(this.currentGroup.allowInstantwin)
  }

  get is_condition_disabled() {
    return (
      this.api_campaign &&
      [CAMPAIGN_STATUS.DURING_FINAL_COLLECTION, CAMPAIGN_STATUS.COLLECTION_COMPLETED].includes(
        this.api_campaign.status
      ) &&
      this.mode === 'edit'
    )
  }

  /**
   * 変化
   */
  change(type) {
    switch (type) {
      case 'page_title':
        this.is_change_page_title = true
        break
      case 'page_text':
        this.is_change_page_text = true
        break
      case 'page_rules':
        this.is_change_page_rules = true
        break
      default:
        break
    }
  }

  /**
   * ページタイトルを設定
   */
  async setPageTitle(value: string) {
    TrackingService.sendEvent('input:キャンペーンの新規作成|応募条件(X)|ページタイトル')

    this.change('page_title')

    await this.setForm({ ...this.form, page_title: value })
  }

  /**
   * ページテ規約を設定
   */
  async setPageRules(value: string) {
    TrackingService.sendEvent('input:キャンペーンの新規作成|応募条件(X)|規約')

    this.change('page_rules')

    await this.setForm({ ...this.form, page_rules: value })
  }

  /**
   * ページテキストを設定
   */
  async setPageText(value: string) {
    TrackingService.sendEvent('input:キャンペーンの新規作成|応募条件(X)|ページテキスト')

    this.change('page_text')

    await this.setForm({ ...this.form, page_text: value })
  }

  /**
   * 懸賞アカウントの場合、落選にするフラグを更新する
   */
  async changeExcludeHunterAccount(value: boolean) {
    TrackingService.sendEvent('switch:キャンペーンの新規作成|応募条件(X)|懸賞アカウント落選')

    const form = {
      ...this.form,
      exclude_hunter_account: value
    }

    await this.setForm(form)
  }

  /**
   * アカウント開設日がキャンペーン期間内の場合、落選にするフラグを更新する
   */
  async changeExcludeAccountCreateDateWithinPeriod(value: boolean) {
    TrackingService.sendEvent('switch:キャンペーンの新規作成|応募条件(X)|アカウント開設日落選')

    const form = {
      ...this.form,
      exclude_account_create_date_within_period: value
    }

    await this.setForm(form)
  }

  /**
   * プロフィール画像が初期状態の場合、落選にするフラグを更新する
   */
  async changeExcludeDefaultProfileImage(value: boolean) {
    TrackingService.sendEvent('switch:キャンペーンの新規作成|応募条件(X)|プロフィール画像が初期')

    const form = {
      ...this.form,
      exclude_default_profile_image: value
    }

    await this.setForm(form)
  }

  /**
   * 自己紹介が未入力の場合、落選にするフラグを更新する
   */
  async changeExcludeNoDescription(value: boolean) {
    TrackingService.sendEvent('switch:キャンペーンの新規作成|応募条件(X)|未入力落選')

    const form = {
      ...this.form,
      exclude_no_description: value
    }

    await this.setForm(form)
  }

  /**
   * フォロワー数が指定値未満の場合、落選にするフラグを更新する
   */
  async onChangeMinFollowers(value: number) {
    if (value === this.form.min_followers + 1) {
      TrackingService.sendEvent('click:キャンペーンの新規作成|応募条件(X)|フォロワー指定値|UP')
    } else if (value === this.form.min_followers - 1) {
      TrackingService.sendEvent('click:キャンペーンの新規作成|応募条件(X)|フォロワー指定値|DOWN')
    } else TrackingService.sendEvent('input:キャンペーンの新規作成|応募条件(X)|フォロワー指定値')

    const form = {
      ...this.form,
      min_followers: value
    }

    await this.setForm(form)
  }

  /**
   * ポスト数が指定値未満の場合、落選にするフラグを更新する
   */
  async onChangeMinTweets(value: number) {
    if (value === this.form.min_tweets + 1) {
      TrackingService.sendEvent('click:キャンペーンの新規作成|応募条件(X)|ポスト指定値|UP')
    } else if (value === this.form.min_tweets - 1) {
      TrackingService.sendEvent('click:キャンペーンの新規作成|応募条件(X)|ポスト指定値|DOWN')
    } else TrackingService.sendEvent('input:キャンペーンの新規作成|応募条件(X)|ポスト指定値')

    const form = {
      ...this.form,
      min_tweets: value
    }

    await this.setForm(form)
  }

  get is_title_empty() {
    return this.is_change_page_title && !this.form.page_title.trim()
  }

  get is_title_max_length() {
    return !!this.form.page_title.trim() && this.form.page_title.trim().length > 50
  }

  get is_page_text_empty() {
    return this.is_change_page_text && !this.form.page_text.trim()
  }

  get is_page_rules_empty() {
    return this.is_change_page_rules && !this.form.page_rules.trim()
  }

  get positive_keyword() {
    return this.form.positive_keyword ? this.form.positive_keyword : []
  }

  get negative_keyword() {
    return this.form.negative_keyword ? this.form.negative_keyword : []
  }

  get and_keywords() {
    return this.form.keywords
  }

  get or_keywords() {
    return this.form.or_keywords ? this.form.or_keywords : []
  }

  get combinations() {
    if (!this.form.keywords.length && (!this.form.or_keywords || !this.form.or_keywords.length)) {
      return []
    }

    return this.form.or_keywords && this.form.or_keywords.length
      ? this.form.or_keywords.map(or_keyword => [...this.form.keywords, or_keyword])
      : [this.form.keywords]
  }

  /**
   * エラーを表示
   * @param {string} title
   * @param {string} message
   */
  showError(title: string, message: string) {
    this.$notify({
      title,
      message,
      customClass: 'danger',
      dangerouslyUseHTMLString: true,
      duration: 5000
    })
  }

  /**
   * キャンペーンタイプを選択する
   */
  async changeCampaignType(campaign_type: string) {
    TrackingService.sendEvent('select:キャンペーンの新規作成|応募条件(X)|キャンペーンタイプ')

    let form = {
      ...this.form,
      campaign_type
    }

    if (campaign_type === 'web_instantwin') {
      form = {
        ...form,
        entry_action_type: this.retweet_action_type
      }
    }

    await this.setForm(form)
  }

  /**
   * 応募アクションを選択する
   */
  async changeApplicationType(application_type: string) {
    TrackingService.sendEvent('select:キャンペーンの新規作成|応募条件(X)|応募条件')

    let entry_action_type = application_type
    if (application_type === 'retweet') {
      entry_action_type = this.retweet_action_type
    } else if (application_type === 'tweet') {
      entry_action_type = this.tweet_action_type
    }

    const form = {
      ...this.form,
      entry_action_type
    }

    await this.setForm(form)
  }

  /**
   * 応募条件を選択する
   */
  async changeActionType(value: string) {
    switch (value) {
      case 'retweet':
        TrackingService.sendEvent('radio:キャンペーンの新規作成|応募条件(X)|公式リポスト')
        break
      case 'quote_tweet':
        TrackingService.sendEvent('radio:キャンペーンの新規作成|応募条件(X)|引用')
        break
      case 'retweet_and_quote_tweet':
        TrackingService.sendEvent('radio:キャンペーンの新規作成|応募条件(X)|全て')
        break
      case 'tweet':
        TrackingService.sendEvent('radio:キャンペーンの新規作成|応募条件(X)|通常')
        break
      case 'tweet_media':
        TrackingService.sendEvent('radio:キャンペーンの新規作成|応募条件(X)|画像または動画')
        break
      default:
        break
    }

    const form = {
      ...this.form,
      entry_action_type: value
    }

    await this.setForm(form)
  }

  /**
   * 説明キーワードを除外
   * @param {string[]} keywords
   */
  async onChangeExcludeDescriptionKeyword(keywords: string[]) {
    const uniqueKeywords = Array.from(
      new Set(
        keywords.flatMap(v =>
          v
            .split(',')
            .map(v => v.trim())
            .filter(Boolean)
        )
      )
    )

    const form = {
      ...this.form,
      exclude_description_keyword: uniqueKeywords
    }

    await this.setForm(form)
  }

  /**
   * メンションを含めるフラグを更新する
   */
  async changeIsIncludeMention(value: boolean) {
    const form = {
      ...this.form,
      is_include_mention: value
    }

    await this.setForm(form)
  }

  /**
   * 返信を含めるフラグを更新する
   */
  async changeIsIncludeReply(value: boolean) {
    const form = {
      ...this.form,
      is_include_reply: value
    }

    await this.setForm(form)
  }

  /**
   * キーワード判定フラグを更新する
   */
  async changeIsKeywordJudgment(value: boolean) {
    TrackingService.sendEvent('click:キャンペーンの新規作成|応募条件(X)|アカウントを判定する')

    const form = {
      ...this.form,
      is_keyword_judgment: value
    }

    await this.setForm(form)
  }

  /**
   * ポジティブキーワードを更新する
   */
  async changePositiveKeywords(value: string[]) {
    if (this.form.positive_keyword?.length < value.length) {
      TrackingService.sendEvent('click:キャンペーンの新規作成|応募条件(X)|ポジティブキーワード|追加')
    } else TrackingService.sendEvent('click:キャンペーンの新規作成|応募条件(X)|ポジティブキーワード|削除')

    const form = {
      ...this.form,
      positive_keyword: value
    }

    await this.setForm(form)
  }

  /**
   * ネガティブキーワードを更新する
   */
  async changeNegativeKeywords(value: string[]) {
    if (this.form.negative_keyword?.length < value.length) {
      TrackingService.sendEvent('click:キャンペーンの新規作成|応募条件(X)|ネガティブキーワード|追加')
    } else TrackingService.sendEvent('click:キャンペーンの新規作成|応募条件(X)|ネガティブキーワード|削除')

    const form = {
      ...this.form,
      negative_keyword: value
    }

    await this.setForm(form)
  }

  /**
   * ANDキーワードを更新する
   */
  async changeAndKeywords(keywords: string[]) {
    if (this.form.or_keywords?.length < keywords.length) {
      TrackingService.sendEvent('click:キャンペーンの新規作成|応募条件(X)|全てを含む|追加')
    } else TrackingService.sendEvent('click:キャンペーンの新規作成|応募条件(X)|全てを含む|削除')

    const is_valid = keywords.every(keyword => isValidHashtag(keyword))

    if (!is_valid) {
      this.showError({
        title: 'ハッシュタグに利用できない文字列が含まれています。',
        message: ''
      })
      return
    }

    if (keywords.length > 0) {
      const is_duplicate = this.form.or_keywords && this.form.or_keywords.includes(keywords[keywords.length - 1])

      if (is_duplicate) {
        this.showError({
          title: 'すでに登録済みのハッシュタグです。',
          message: ''
        })

        return
      }
    }

    const form = {
      ...this.form,
      keywords: keywords
    }

    await this.setForm(form)
  }

  /**
   * ORキーワードを更新する
   */
  async changeOrKeywords(or_keywords: string[]) {
    if (this.form.or_keywords?.length < or_keywords.length) {
      TrackingService.sendEvent('click:キャンペーンの新規作成|応募条件(X)|いずれかを含む|追加')
    } else TrackingService.sendEvent('click:キャンペーンの新規作成|応募条件(X)|いずれかを含む|削除')

    const is_valid = or_keywords.every(keyword => isValidHashtag(keyword))

    if (!is_valid) {
      this.showError({
        title: 'ハッシュタグに利用できない文字列が含まれています。',
        message: ''
      })

      return
    }

    if (or_keywords.length > 0) {
      const is_duplicate = this.form.keywords.includes(or_keywords[or_keywords.length - 1])

      if (is_duplicate) {
        this.showError({
          title: 'すでに登録済みのハッシュタグです。',
          message: ''
        })

        return
      }
    }

    const form = {
      ...this.form,
      or_keywords: or_keywords
    }

    await this.setForm(form)
  }

  /**
   * メディアファイル削除
   */
  async removeMedia() {
    this.page_main_image_url = null

    let remove_data = {
      ...this.form,
      page_main_image_url: null
    }

    await this.setForm(remove_data)
  }

  /**
   * ファイルを変更
   */
  async handleFileChange(e) {
    TrackingService.sendEvent('click:キャンペーンの新規作成|応募条件(X)|アップロード')

    if (!e.target.files || !e.target.files[0]) {
      return
    }

    this.upload_media_type_loading = 'main'

    const validFormat = ['video/mp4', 'image/jpeg', 'image/png', 'image/gif', 'image/pjpeg', 'image/gif']

    // メディアをチェック

    const file = e.target.files[0]

    const checkValidMedia = await TwitterUpload.checkValidMedia(file, validFormat)

    if (checkValidMedia.error) {
      const error = checkValidMedia.error

      let title = ''
      let message = ''

      switch (error) {
        case 'INVALID_MEDIA':
          title = this.$gettext('Please upload valid format!')
          message = validFormat.join(', ')
          break
        case 'MAX_SIZE_VIDEO':
          title = this.$gettext(
            '25MB以上のビデオを利用することはできません。 サイズを調整してからアップロードしてください。'
          )
          break
        case 'MAX_SIZE_IMAGE':
          title = this.$gettext(
            '5MB以上の画像を利用することはできません。サイズを調整してからアップロードしてください。'
          )
          break
        case 'INVALID_DIMENSION':
          title = this.$gettext('画像・動画アップロードに失敗しました。')
          message = this.$gettext(
            '詳しくは<a target="_blank" href="https://help-atelu.comnico.jp/available-image-and-video">こちら</a>をご確認ください。'
          )
          break
      }

      this.upload_media_type_loading = null
      this.showError(title, message)

      return
    }

    const formData = new FormData()
    formData.append('file', file)

    const data = await api.post('/media_uploads', formData, {
      headers: {
        'Content-Type': undefined
      }
    })

    this.page_main_image_url = data.data.media_url

    this.upload_media_type_loading = null

    let upload_data = { ...this.form, page_main_image_url: this.page_main_image_url }

    await this.setForm(upload_data)
  }
}
