/* @flow */
import { mapActions, mapState } from 'vuex'

import ApplicantName from '@/client/components/basic/applicant_name'
import Icon from '@/client/components/basic/icon'
import Pagination from '@/client/components/basic/pagination'
import DuplicateEntryDialog from '@/client/components/twitter_applicant/duplicate_entry_dialog'
import EntryHistoryDialog from '@/client/components/twitter_applicant/entry_history_dialog'
import Util from '@/client/core/util'
import { TrackingService } from '@/client/services'

export default {
  props: {
    applicants: Array,
    options: Object,
    campaignAccountId: Number,
    campaignId: Number,
    campaignType: String,
    campaignStartDatetime: String
  },
  components: {
    Pagination,
    ApplicantName,
    Icon,
    EntryHistoryDialog,
    DuplicateEntryDialog
  },
  data() {
    return {
      isSubmit: false
    }
  },
  computed: {
    ...mapState('system', {
      contractStatus: state => state.currentGroup.contractStatus
    })
  },
  methods: {
    ...mapActions('twitterApplicant', ['fetchApplicants']),

    /**
     * ポストテキストをフォーマット
     * @param {string} text
     * @return {string} フォーマットしたテキスト
     */
    tweetText(text: string): string {
      return this.$options.filters.tweetText(text)
    },

    /**
     * ヘッダーをクリックするとソートする
     * @param {*} column
     */
    headerClick(column: any) {
      switch (column.label) {
        case 'アカウント':
          TrackingService.sendEvent('sort:応募一覧（X）|アカウント')
          break
        case '応募日時/ポスト内容':
          TrackingService.sendEvent('sort:応募一覧（X）|応募日時/ポスト内容')
          break
        case 'フォロワー':
          TrackingService.sendEvent('sort:応募一覧（X）|フォロワー')
          break
        case 'FF比率':
          TrackingService.sendEvent('sort:応募一覧（X）|FF比率')
          break
        case 'ポスト':
          TrackingService.sendEvent('sort:応募一覧（X）|ポスト')
          break
        case '応募数':
          TrackingService.sendEvent('sort:応募一覧（X）|応募数')
          break
        case '累計参加数':
          TrackingService.sendEvent('sort:応募一覧（X）|累計参加数')
          break
        case '累計当選数':
          TrackingService.sendEvent('sort:応募一覧（X）|累計当選数')
          break
        default:
          break
      }

      if (!column.sortBy) {
        return
      }
      if (column.sortBy.indexOf('-') === 0) {
        if (this.options.sortBy === column.sortBy) {
          this.$emit('sorting', { sortBy: column.sortBy.substring(1) })
        } else {
          this.$emit('sorting', { sortBy: column.sortBy })
        }
      } else {
        if (this.options.sortBy === column.sortBy) {
          this.$emit('sorting', { sortBy: '-' + column.sortBy })
        } else {
          this.$emit('sorting', { sortBy: column.sortBy })
        }
      }

      this.fetchApplicants()
    },

    /**
     * ヘッダー描画
     * @param {*} createElement
     * @param {*} { column }
     */
    renderHeader(createElement: any, { column }: any) {
      return Util.renderHeader(createElement, column.label, Util.getSortClass(this.options.sortBy, column.sortBy))
    },

    /**
     * ページング変更イベント
     * @param {number} page
     */
    onChangePagingNo(page: number) {
      this.$emit('paging', { pagingNo: page })

      this.fetchApplicants()
    },

    /**
     * 表示件数変更イベント
     * @param {number} limit
     */
    onChangeLimitCount(limit: number) {
      this.$emit('paging', { pagingNo: 1, limitCount: limit })

      this.fetchApplicants()
    },

    /**
     * メッセージ配信履歴を開く
     * @param {number} applicantId
     */
    openMessageHistory(applicantId: number) {
      this.$emit('open_message_history', {
        applicantId
      })
    },

    /**
     * ドロワーを開く
     */
    openApplicantDrawer(applicantId: number, tab: string, text: string) {
      switch (tab) {
        case 'profile':
          TrackingService.sendEvent(`click:応募一覧（X）|${text === 'account' ? 'ユーザーID' : '応募数'}`)
          break
        case 'entryHistory':
          TrackingService.sendEvent('click:応募一覧（X）|累計参加数')
          break
        case 'winHistory':
          TrackingService.sendEvent('click:応募一覧（X）|累計当選数')
          break
        case 'streamHistory':
          TrackingService.sendEvent('click:応募一覧（X）|メッセージ履歴')
          break
        default:
          break
      }

      this.$emit('open_applicant_drawer', {
        applicantId,
        tab
      })
    },

    /**
     * フラグ変更処理
     * @param {number} applicantId
     * @param {number} value
     * @param {string} type
     */
    updateFlag(applicantId: number, value: number, type: string) {
      switch (type) {
        case 'candidate':
          TrackingService.sendEvent('click:応募一覧（X）|候補者に追加')
          break
        case 'winner':
          TrackingService.sendEvent('click:応募一覧（X）|当選者に追加')
          break
        case 'responder':
          TrackingService.sendEvent('click:応募一覧（X）|回答者に追加')
          break
        default:
          break
      }

      const payload = {
        type,
        value: {}
      }

      switch (type) {
        case 'winner':
          payload.value = {
            applicantId,
            isWinner: value === 0 ? 1 : 0,
            wasWinner: 1
          }
          break
        case 'responder':
          payload.value = {
            applicantId,
            isResponder: value === 0 ? 1 : 0
          }
          break
        case 'candidate':
          payload.value = {
            applicantId,
            isCandidate: value === 0 ? 1 : 0
          }
          break
      }
      this.$emit('change_flag', payload)
    },

    /**
     * エラーを表示
     * @params {string} title タイトル
     */
    showError(title: string) {
      this.$notify({
        title,
        customClass: 'danger',
        duration: 5000
      })
    },

    clickTwitterConfirmation() {
      TrackingService.sendEvent('click:応募一覧（X）|Xで確認')
    },

    handleHashtagClick() {
      TrackingService.sendEvent('click:応募一覧（X）|ハッシュタグ')
    }
  }
}
