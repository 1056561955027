






























import { Component, Prop, Vue } from 'vue-property-decorator'

import Icon from '@/client/components/_atoms/Icon.vue'

@Component({ components: { Icon } })
export default class Tags extends Vue {
  @Prop({ type: Array, default: () => [] })
  value!: string[]

  @Prop({ type: Boolean, default: false })
  editable!: boolean

  @Prop({ type: Number, required: false })
  maxLength!: number

  @Prop({ type: String, required: false, default: 'hashtag' })
  type!: 'hashtag' | 'keyword'

  $refs!: {
    input: HTMLInputElement
  }

  input_visible = false
  input_content = ''

  get tags() {
    return this.value
  }

  set tags(value: string[]) {
    this.$emit('input', value)
  }

  get is_display_button() {
    return this.editable && !this.input_visible
  }

  get is_disabled() {
    return !this.input_content.trim()
  }

  /**
   * 入力を表示
   */
  showInput(): void {
    this.input_visible = true
    this.$nextTick(() => {
      this.$refs.input.focus()
    })
  }

  /**
   * インプットを非表示
   */
  hideInput(): void {
    this.input_visible = false
    this.input_content = ''
  }

  /**
   * タグを変更
   */
  createTag(): void {
    let new_tag = this.input_content.trim()

    if (!new_tag) {
      return
    }

    if (this.type === 'hashtag') {
      new_tag = new_tag.replace('＃', '#')

      if (new_tag[0] !== '#') {
        new_tag = '#' + new_tag
      }
    }

    const tag_length = this.tags.reduce((length, tag) => length + tag.length, 0)

    if (tag_length + new_tag.length > this.maxLength) {
      this.$notify({
        title: `文字数合計を${this.maxLength}文字以下にしてください。`,
        message: '',
        customClass: 'danger'
      })
      return
    }

    const is_duplicate = this.value.some(tag => tag === new_tag)

    if (is_duplicate) {
      const title = this.type === 'hashtag' ? 'すでに登録済みのハッシュタグです。' : 'すでに登録済みのキーワードです。'

      this.$notify({
        title: title,
        message: '',
        customClass: 'danger'
      })
      return
    }

    this.tags = [...this.tags, new_tag]

    this.hideInput()
  }

  /**
   * タグを削除
   */
  removeTag(removed_tag: string): void {
    this.tags = this.tags.filter(tag => tag !== removed_tag)
  }
}
