/* @flow */
import api from '@/client/core/api'
import { type TGetMessageQueuesParams, type TMessageQueue } from '@/typings/api/twitter_message_queues.type'

// mutations type
const RESET = 'RESET'
const SET_QUEUE_LIST_QUEUES = 'SET_QUEUE_LIST_QUEUES'
const SET_QUEUE_LIST_PARAMS = 'SET_QUEUE_LIST_PARAMS'
const SET_QUEUE_LIST_LOADED = 'SET_QUEUE_LIST_LOADED'
const SET_QUEUE_LIST_IS_SHOW_DETAIL = 'SET_QUEUE_LIST_IS_SHOW_DETAIL'
const SET_PRIZE_ID = 'SET_PRIZE_ID'
const SET_CAMPAIGN_ID = 'SET_CAMPAIGN_ID'

// メッセージの一括ダイアログストア
type DirectMessageDialogStore = {
  campaignId: number,
  prizeId: number,
  queueList: {
    loaded: boolean,
    queues: TMessageQueue[],
    params: TGetMessageQueuesParams,
    isShowDetail: boolean
  }
}

type StateStore = {
  campaignId: number,
  prizeId: number,
  queueList: {
    loaded: boolean,
    queues: TMessageQueue[],
    params: TGetMessageQueuesParams,
    isShowDetail: boolean
  }
}

// ストアーcontext
type Context = {
  commit: Function,
  getters: any,
  state: StateStore,
  dispatch: Function
}

// init state
const initState: DirectMessageDialogStore = {
  campaignId: 0,
  prizeId: 0,
  queueList: {
    loaded: false,
    queues: [],
    params: {
      campaignId: 0,
      prizeId: 0,
      sortBy: '-actionDatetime'
    },
    isShowDetail: false
  }
}

const state = { ...initState }

// getters
const getters = {}

// actions
const actions = {
  /**
   * ストアをリセット
   * @param {Context} context
   */
  async reset(context: Context) {
    context.commit(RESET)
  },

  /**
   * キャンペーンIDをセット
   * @param {Context} context
   * @param {number} campaignId キャンペーンID
   */
  setCampaignId(context: Context, campaignId: number) {
    context.commit(SET_CAMPAIGN_ID, campaignId)
  },

  /**
   * set campaign prize id
   * @param {Context} context
   * @param {number} prizeId campaign prize id
   */
  setPrizeId(context: Context, prizeId: number) {
    context.commit(SET_PRIZE_ID, prizeId)
  },

  /**
   * キュー一覧を取得
   * @param {Context} context
   */
  async fetchQueueList(context: Context) {
    if (!context.state.prizeId || !context.state.campaignId) {
      return
    }
    const result = await api.get('/twitter_message_queues', {
      params: context.state.queueList.params
    })
    context.commit(SET_QUEUE_LIST_LOADED, true)
    if (!result.data) {
      return
    }
    context.commit(SET_QUEUE_LIST_QUEUES, result.data.messageQueues)
  },

  /**
   * キュー一覧のパラメーターをセット
   * @param {Context} context
   * @param {TGetMessageQueuesParams} params パラメーター
   */
  setQueueListParams(context: Context, params: TGetMessageQueuesParams) {
    context.commit(SET_QUEUE_LIST_PARAMS, Object.assign({}, context.state.queueList.params, params))
  },

  /**
   * キュー一覧の詳細表示フラグをセット
   * @param {Context} context
   * @param {boolean} isShowDetail 詳細表示フラグ
   */
  setQueueListIsShowDetail(context: Context, isShowDetail: boolean) {
    context.commit(SET_QUEUE_LIST_IS_SHOW_DETAIL, isShowDetail)
  }
}

// mutations
const mutations = {
  /**
   * ストアをリセット
   * @param {StateStore} state
   */
  [RESET](state: StateStore) {
    const initStateData: any = initState
    Object.keys(initStateData).forEach(key => {
      state[key] = initStateData[key]
    })
    state.queueList = {
      loaded: false,
      queues: [],
      params: {
        campaignId: 0,
        prizeId: 0,
        sortBy: '-actionDatetime'
      },
      isShowDetail: false
    }
  },

  /**
   * キャンペーンIDをセット
   * @param {StateStore} state
   * @param {number} campaignId キャンペーンID
   */
  [SET_CAMPAIGN_ID](state: StateStore, campaignId: number) {
    state.campaignId = campaignId
    state.queueList.params.campaignId = campaignId
  },

  /**
   * Set campaign Prize id
   * @param {StateStore} state
   * @param {number} prizeId campaign prize id
   */
  [SET_PRIZE_ID](state: StateStore, prizeId: number) {
    state.prizeId = prizeId
    state.queueList.params.prizeId = prizeId
  },

  /**
   * キューのパラメーターをセット
   * @param {StateStore} state
   * @param {TGetMessageQueuesParams} params パラメーター
   */
  [SET_QUEUE_LIST_PARAMS](state: StateStore, params: TGetMessageQueuesParams) {
    state.queueList.params = params
  },

  /**
   * キューのキュー一覧をセット
   * @param {DirectMessageDialogStore} state
   * @param {TMessageQueue[]} messageQueues メッセージキュー一覧
   */
  [SET_QUEUE_LIST_QUEUES](state: DirectMessageDialogStore, messageQueues: TMessageQueue[]) {
    state.queueList.queues = messageQueues
  },

  /**
   * キューのロードされたフラグをセット
   * @param {DirectMessageDialogStore} state
   * @param {boolean} loaded ロードされたフラグ
   */
  [SET_QUEUE_LIST_LOADED](state: DirectMessageDialogStore, loaded: boolean) {
    state.queueList.loaded = loaded
  },

  /**
   * キューの詳細表示フラグをセット
   * @param {DirectMessageDialogStore} state
   * @param {boolean} isShowDetail 詳細表示フラグ
   */
  [SET_QUEUE_LIST_IS_SHOW_DETAIL](state: DirectMessageDialogStore, isShowDetail: boolean) {
    state.queueList.isShowDetail = isShowDetail
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
