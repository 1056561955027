/* @flow */
import ApplicantName from '@/client/components/basic/applicant_name'
import Pagination from '@/client/components/basic/pagination'
import Util from '@/client/core/util'
import { TrackingService } from '@/client/services'
import { type TTwitterPost } from '@/typings/api/twitter_posts.type'

export default {
  components: { ApplicantName, Pagination },
  props: {
    posts: Array,
    options: Object,
    campaignType: String
  },
  methods: {
    /**
     * Xで確認
     */
    openSns(post: TTwitterPost) {
      TrackingService.sendEvent('click:応募履歴（X）|Xで確認')

      if (post.postType === 'retweet') {
        return window.open('https://x.com/' + post.screenName, '_blank')
      }

      window.open(`https://x.com/${post.screenName}/status/${post.id}`, '_blank')
    },

    /**
     * ヘッダーをクリックするとソートする
     */
    headerClick(column: any) {
      TrackingService.sendEvent('sort:応募履歴（X）|応募日時')

      if (!column.sortBy) {
        return
      }

      if (column.sortBy.indexOf('-') === 0 && this.options.sortBy === column.sortBy) {
        this.$emit('sorting', column.sortBy.substring(1))
        return
      }

      if (column.sortBy.indexOf('-') !== 0 && this.options.sortBy === column.sortBy) {
        this.$emit('sorting', `-${column.sortBy}`)
        return
      }

      this.$emit('sorting', column.sortBy)
    },

    /**
     * ヘッダー描画
     */
    renderHeader(createElement: any, { column }: any) {
      return Util.renderHeader(createElement, column.label, Util.getSortClass(this.options.sortBy, column.sortBy))
    },

    /**
     * ページング変更イベント
     */
    onPaging(payload: any) {
      this.$emit('paging', payload)
    },

    /**
     * ウィジェットフラグを変更
     */
    changeIsWidget(post: TTwitterPost) {
      TrackingService.sendEvent('click:応募履歴（X）|ウィジェットに追加')

      if (post.isNg && !post.isWidget) {
        return
      }

      this.$emit('change_is_widget', {
        postId: post.id,
        isWidget: post.isWidget
      })
    },

    /**
     * 候補者フラグを変更
     */
    changeIsCandidate(post: TTwitterPost) {
      TrackingService.sendEvent('click:応募履歴（X）|候補者に追加')

      if (post.isNg && !post.isCandidate) {
        return
      }

      this.$emit('change_is_candidate', {
        applicantId: post.applicantId,
        isCandidate: post.isCandidate
      })
    },

    /**
     * 当選者フラグを変更
     */
    changeIsWinner(post: TTwitterPost) {
      TrackingService.sendEvent('click:応募履歴（X）|当選者に追加')

      if (post.isNg && !post.isWinner) {
        return
      }

      this.$emit('change_is_winner', {
        prizeId: post.prizeId,
        applicantId: post.applicantId,
        isWinner: post.isWinner
      })
    },

    /**
     * ポストテキストをフォーマット
     */
    tweetText(text: string): string {
      return this.$options.filters.tweetText(text)
    },

    /**
     * ウィジェットコンテンツを取得
     */
    widgetTooltipContent(post: TTwitterPost) {
      if (post.isNg && !post.isWidget) {
        return 'NGアカウント'
      }

      return post.isWidget ? 'ウィジェットから削除' : 'ウィジェットに追加'
    },

    /**
     * 候補者コンテンツを取得
     */
    candidateTooltipContent(post: TTwitterPost) {
      if (post.isNg && !post.isCandidate) {
        return 'NGアカウント'
      }

      return post.isCandidate ? '候補者を解除' : '候補者に追加'
    },

    /**
     * 当選者コンテンツを取得
     */
    winnerTooltipContent(post: TTwitterPost) {
      if (post.isNg && !post.isWinner) {
        return 'NGアカウント'
      }

      return post.isWinner ? '当選者を解除' : '当選者に追加'
    },

    /**
     * ポスト種別を取得
     */
    getType(type: string) {
      switch (type) {
        case 'tweet':
          return 'ポスト'
        case 'retweet':
          return '公式リポスト'
        case 'quote_tweet':
          return '引用'
        case 'mention':
          return 'メンション'
        case 'reply':
          return '返信'
        default:
          return ''
      }
    },

    handleHashtagClick() {
      TrackingService.sendEvent('click:応募履歴（X）|ポスト内容')
    }
  }
}
