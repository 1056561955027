import { defineComponent } from '@vue/composition-api'
import { mapActions, mapState } from 'vuex'

import Icon from '@/client/components/_atoms/Icon.vue'
import Panel from '@/client/components/_atoms/Panel.vue'
import FormItemLabel from '@/client/components/_molecules/FormItemLabel.vue'
import EditableComponent from '@/client/components/basic/editable/editable.vue'
import api from '@/client/core/api'
import TwitterUpload from '@/client/core/twitter_upload'
import { TrackingService } from '@/client/services'
import { Form } from '@/client/store/lottery_page_setting_dialog.store'
import { WEB_INSTANTWIN_MEDIA_LOSE_URL } from '@/common/constants'

export default defineComponent<any>({
  name: 'LotteryPageCreateDialog',
  components: {
    Icon,
    Panel,
    FormItemLabel,
    EditableComponent
  },
  data() {
    return {
      isUploadMediaLoading: false,
      isMedia: true,
      isChangePageLoseText: false,
      isChangePageName: false
    }
  },
  computed: {
    ...mapState('lotteryPageSettingDialog', ['form', 'mode', 'lotteryPages']),
    isNameTextEmpty(): boolean {
      return this.isChangePageName && !this.form.name.trim()
    },
    isPageLoseTextEmpty(): boolean {
      return this.isChangePageLoseText && !this.form.lotteryText.trim()
    },
    isCreatedLosePageDisabled(): boolean {
      return !this.form.lotteryText || !this.form.name || this.isNameTextEmpty || this.isPageLoseTextEmpty
    },
    isDeleteDisabled(): boolean {
      return !this.lotteryPages.length || this.lotteryPages.filter(v => v.lotteryType === 0).length <= 1
    }
  },

  methods: {
    ...mapActions('lotteryPageSettingDialog', ['setForm', 'setMode', 'fetchLotteryPages']),

    /**
     * アップロード画像・動画ファイルの変更
     */
    async handleFileChange(e: any) {
      TrackingService.sendEvent('click:当落選ページの管理|落選ページの作成|アップロード')

      if (!e.target.files || !e.target.files[0]) {
        return
      }

      this.isUploadMediaLoading = true

      const validFormat = ['video/mp4', 'image/jpeg', 'image/png', 'image/gif', 'image/pjpeg', 'image/gif']

      // メディアをチェック

      const file = e.target.files[0]

      const checkValidMedia = await TwitterUpload.checkValidMedia(file, validFormat)

      if (checkValidMedia.error) {
        const error = checkValidMedia.error

        switch (error) {
          case 'INVALID_MEDIA':
            this.notify('Please upload valid format!', validFormat.join(', '))
            break
          case 'MAX_SIZE_VIDEO':
            this.notify('25MB以上のビデオを利用することはできません。 サイズを調整してからアップロードしてください。')
            break
          case 'MAX_SIZE_IMAGE':
            this.notify('5MB以上の画像を利用することはできません。サイズを調整してからアップロードしてください。')
            break
          case 'INVALID_DIMENSION':
            this.notify(
              '画像・動画アップロードに失敗しました。',
              '詳しくは<a target="_blank" href="https://help-atelu.comnico.jp/available-image-and-video">こちら</a>をご確認ください。'
            )
            break
        }

        this.isMedia = false
        this.isUploadMediaLoading = false

        return
      }

      const formData = new FormData()
      formData.append('file', file)

      const data = await api.post('/media_uploads', formData, {
        headers: {
          'Content-Type': undefined
        }
      })

      this.isMedia = true

      const mediaType = file.type === 'video/mp4' ? 1 : 0

      this.setForm({
        ...this.form,
        mediaUrl: data.data.media_url,
        mediaType
      })

      this.isUploadMediaLoading = false
    },

    /**
     * メディアファイル削除
     */
    async removeMedia() {
      this.isMedia = false

      const removeData = {
        ...this.form,
        mediaUrl: null,
        mediaType: 0
      }

      await this.setForm(removeData)
    },

    /**
     * タイトルを設定
     */
    async setPageName(value: string) {
      TrackingService.sendEvent('input:落選ページの作成|落選時テキスト')
      await this.setForm({ ...this.form, name: value })
      this.change('page_name')
    },
    /**
     * ページ落選時テキストを設定
     */
    async setPageLotteryText(value: string) {
      TrackingService.sendEvent('input:落選ページの作成|落選時テキスト')
      this.change('page_lose_text')
      await this.setForm({ ...this.form, lotteryText: value })
    },

    /**
     * テキストを変更チェック
     */
    async change(key: string) {
      switch (key) {
        case 'page_lose_text':
          this.isChangePageLoseText = true
          break
        case 'page_name':
          this.isChangePageName = true
          break
        default:
          break
      }
    },

    /**
     * ページタイトルを設定
     */
    async prevStep() {
      this.setMode('settings')
    },

    /**
     * エラーを表示
     */
    notify(title: string, message?: any, customClass = 'danger') {
      this.$notify({
        title,
        message,
        customClass,
        dangerouslyUseHTMLString: true,
        duration: 5000
      })
    },

    /**
     * 落選ページを作成・更新・複製
     */
    async createOrUpdatePage() {
      const body: Form = {
        mediaUrl: this.form.mediaUrl || WEB_INSTANTWIN_MEDIA_LOSE_URL,
        name: this.form.name,
        lotteryText: this.form.lotteryText,
        lotteryType: 0,
        mediaType: this.form.mediaType
      }

      switch (this.mode) {
        case 'create':
          TrackingService.sendEvent('click:落選ページの作成|新規作成')
          break
        case 'edit_lose':
          TrackingService.sendEvent('click:落選ページの編集|保存')
          break
        case 'copy':
          TrackingService.sendEvent('click:落選ページの複製|複製')
          break
      }

      const response =
        this.mode === 'edit_lose' ? await this.fetchPutLotteryPages(body) : await this.fetchPostLotteryPages(body)

      if (!response || !response.data) {
        return this.notify('落選ページの保存に失敗しました。', '恐れ入りますが、時間をおいて再度お試しください。')
      }

      this.notify('落選ページを保存しました。', null, 'success')
      this.fetchLotteryPages()
    },

    /**
     * 落選ページの削除
     */
    async deletePage() {
      TrackingService.sendEvent('click:落選ページの編集|削除')

      const confirm = window.confirm('落選ページを削除してよろしいですか？')

      if (!confirm) {
        return
      }

      const response = await api.delete(`/twitter_lottery_pages/${this.form.twWebInstantwinLotteryPageSettingId}`, {
        params: {
          campaignId: this.form.campaignId
        }
      })

      if (!response || !response.data) {
        this.notify('落選ページの削除に失敗しました。', '恐れ入りますが、時間をおいて再度お試しください。')
        return
      }

      this.notify('落選ページを削除しました。', null, 'success')
      this.fetchLotteryPages()
    },

    /**
     * 落選ページの新規作成
     */
    async fetchPostLotteryPages(value: Form) {
      const body = {
        ...value,
        campaignId: this.form.campaignId,
        twCampaignPrizeId: this.form.twCampaignPrizeId
      }

      return await api.post('/twitter_lottery_pages', body)
    },

    /**
     * 落選ページの編集
     */
    async fetchPutLotteryPages(value: Form) {
      const body = {
        ...value,
        campaignId: this.form.campaignId
      }

      return await api.put(`/twitter_lottery_pages/${this.form.twWebInstantwinLotteryPageSettingId}`, body)
    }
  }
})
