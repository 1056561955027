/* @flow */
import { mapActions } from 'vuex'

import Pagination from '@/client/components/basic/pagination'
import api from '@/client/core/api'
import Util from '@/client/core/util'
import { TrackingService } from '@/client/services'

export default {
  components: {
    Pagination
  },
  props: {
    ngApplicants: Array,
    total: Number,
    options: Object,
    onChangeSortBy: Function,
    onChangePagingNo: Function,
    onChangeLimitCount: Function,
    snsType: String
  },
  methods: {
    ...mapActions('settingGroup', ['fetchNgApplicants']),
    /**
     * NGアカウントを削除
     * @param {number} id
     */
    async deleteNgApplicant(id: number) {
      TrackingService.sendEvent('click:グループ設定|NGアカウント|削除')

      const confirm = window.confirm(this.$gettext('NGアカウントを削除してよろしいですか？'))
      if (!confirm) {
        return
      }

      const result = await api.delete(`/${this.snsType}_ng_applicants/${id}`)

      if (result.error && result.error.type === 'NOT_EXISTS') {
        return this.$notify({
          title: this.$gettext('対象のNGアカウントはすでに削除されています。'),
          customClass: 'danger',
          duration: 5000
        })
      }

      if (result.error || !result.data) {
        return this.$notify({
          title: this.$gettext('NGアカウントの削除に失敗しました。'),
          message: this.$gettext('恐れ入りますが、時間をおいて再度お試しください。'),
          customClass: 'danger',
          duration: 5000
        })
      }

      this.$notify({
        title: this.$gettext('NGアカウントを削除しました。'),
        customClass: 'success',
        duration: 5000
      })

      this.fetchNgApplicants(this.snsType)
    },
    /**
     * ヘッダーをクリックするとソートする
     * @param {*} column
     */
    headerClick(column: any) {
      switch (column.label) {
        case 'ユーザー名':
          TrackingService.sendEvent('sort:グループ設定|NGアカウント|ユーザー名')
          break
        case '作成日時':
          TrackingService.sendEvent('sort:グループ設定|NGアカウント|作成日時')
          break
        default:
          break
      }

      if (!column.sortBy) {
        return
      }
      if (column.sortBy.indexOf('-') === 0) {
        if (this.options.sortBy === column.sortBy) {
          this.onChangeSortBy(column.sortBy.substring(1))
        } else {
          this.onChangeSortBy(column.sortBy)
        }
      } else {
        if (this.options.sortBy === column.sortBy) {
          this.onChangeSortBy('-' + column.sortBy)
        } else {
          this.onChangeSortBy(column.sortBy)
        }
      }
    },

    /**
     * ヘッダー描画
     * @param {*} createElement
     * @param {*} { column }
     */
    renderHeader(createElement: any, { column }: any) {
      return Util.renderHeader(createElement, column.label, Util.getSortClass(this.options.sortBy, column.sortBy))
    }
  }
}
