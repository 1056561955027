/* @flow */
import Icon from '@/client/components/basic/icon'
import { TrackingService } from '@/client/services'

export default {
  components: {
    Icon
  },
  props: {
    applicant: Object,
    snsType: {
      type: String,
      default: 'twitter'
    },
    isWhite: {
      type: Boolean,
      default: false
    },
    disabledLink: {
      type: Boolean,
      default: false
    },
    screen: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      isError: false
    }
  },
  created() {
    this.checkImage()
  },
  computed: {
    pictureUrl() {
      if (!this.isError && this.applicant.pictureUrl) {
        return this.applicant.pictureUrl
      }

      if (this.snsType === 'twitter') {
        return require('@/client/assets/images/twitter_default_profile.png')
      }

      if (this.snsType === 'instagram') {
        return require('@/client/assets/images/instagram_default_profile.png')
      }

      if (this.snsType === 'tiktok') {
        return require('@/client/assets/images/tiktok_default_profile.png')
      }
    }
  },
  methods: {
    /**
     * 画像の読み込み
     */
    checkImage() {
      this.isError = false

      const img = new Image()

      img.onerror = () => {
        this.isError = true
      }

      img.src = this.applicant.pictureUrl
    },

    clickApplicant() {
      if (this.screen === 'シルアルコード管理') {
        TrackingService.sendEvent('click:当選メッセージの管理|シルアルコード管理|ID')
      } else if (this.screen === '応募一覧（X）') {
        TrackingService.sendEvent('click:応募一覧（X）|ユーザーID')
      } else if (this.screen === '応募一覧（IG）') {
        TrackingService.sendEvent('click:応募一覧（IG）|ユーザーID')
      } else TrackingService.sendEvent(`click:応募履歴（${this.snsType === 'twitter' ? 'X' : 'IG'}）|アカウントID`)
    }
  }
}
