/* @flow */
import { required } from 'vuelidate/lib/validators'
import { mapActions, mapState } from 'vuex'

import api from '@/client/core/api'

export default {
  data() {
    return {
      snsType: 'twitter',
      visible: false,
      loading: false,
      screenNames: '',
      isSaving: false
    }
  },
  computed: {
    ...mapState('system', {
      currentGroup: state => state.currentGroup
    }),
    isFormValid() {
      if (this.screenNames.trim()) {
        return true
      }

      return false
    },
    isFormChanged() {
      return this.screenNames !== ''
    },
    displayText() {
      if (this.snsType === 'instagram') {
        return 'Instagram'
      }

      if (this.snsType === 'tiktok') {
        return 'TikTok'
      }

      return 'X(Twitter)'
    },
    maxNgApplicantError() {
      if (!this.screenNames.trim()) {
        return false
      }

      return this.screenNames.trim().split('\n').length > 10000
    },
    disabled() {
      return !this.isFormValid || this.maxNgApplicantError || this.isSaving
    }
  },
  methods: {
    ...mapActions('settingGroup', ['fetchNgApplicants']),
    /**
     * Open dialog
     */
    async open(snsType: string) {
      this.snsType = snsType
      this.$v.$reset()
      this.visible = true
    },

    /**
     * NGアカウントの新規作成
     */
    async addNgApplicants() {
      if (!this.isFormValid) {
        return
      }

      this.isSaving = true
      const params = this.setParams()

      const result = await api.post(`/${this.snsType}_ng_applicants`, params)

      setTimeout(() => {
        this.isSaving = false
      }, 2000)

      if (!result.data) {
        this.$notify({
          title: this.$gettext('NGアカウントの追加に失敗しました。'),
          message: this.$gettext('恐れ入りますが、時間をおいて再度お試しください。'),
          customClass: 'danger',
          duration: 5000
        })
        this.reset()
        return
      }

      this.$notify({
        title: this.$gettext(
          `${result.data.savedCount}件のNGアカウント(重複${params.screenNames.length -
            result.data.savedCount}件)を追加しました。`
        ),
        customClass: 'success',
        duration: 5000
      })
      this.reset()
      await this.fetchNgApplicants(this.snsType)
    },

    /**
     * Set params
     * @returns {{ groupId: number, screenNames: string }}
     */
    setParams(): { groupId: number, screenNames: string } {
      return {
        groupId: this.currentGroup.id,
        screenNames: this.screenNames
          .replace(/^\s*[\r\n]/gm, '')
          .replace(/^\s+|\s+$/g, '')
          .replace(/ /g, '')
          .replace(/@/g, '')
          .split('\n')
      }
    },

    /**
     * Reset data
     */
    reset() {
      this.screenNames = ''
      this.visible = false
    },

    /**
     * Reset data before close
     */
    beforeClose() {
      if (!this.isFormChanged) {
        return this.reset()
      }

      const message = this.$gettext('編集内容が保存されていません。ダイアログを閉じてよろしいですか?')
      const confirm = window.confirm(message)

      if (confirm) {
        return this.reset()
      }
    }
  },
  validations: {
    screenNames: {
      required
    }
  }
}
