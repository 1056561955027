/* @flow */
import Pagination from '@/client/components/basic/pagination'
import Util from '@/client/core/util'
import { TrackingService } from '@/client/services'

export default {
  components: {
    Pagination
  },
  props: {
    templates: Array,
    total: Number,
    params: Object,
    options: Object,
    onChangeSortBy: Function,
    onChangePagingNo: Function,
    onChangeLimitCount: Function,
    openMessageTemplateDialog: Function
  },
  methods: {
    /**
     * ヘッダーをクリックするとソートする
     * @param {*} column
     */
    headerClick(column: any) {
      switch (column.label) {
        case 'テンプレート名':
          TrackingService.sendEvent('sort:グループ設定|テンプレート|テンプレート名')
          break
        case '内容':
          TrackingService.sendEvent('sort:グループ設定|テンプレート|内容')
          break
        case '作成日時':
          TrackingService.sendEvent('sort:グループ設定|テンプレート|作成日時')
          break
        case '更新日時':
          TrackingService.sendEvent('sort:グループ設定|テンプレート|更新日時')
          break
        default:
          break
      }

      if (!column.sortBy) {
        return
      }
      if (column.sortBy.indexOf('-') === 0) {
        if (this.options.sortBy === column.sortBy) {
          this.onChangeSortBy(column.sortBy.substring(1))
        } else {
          this.onChangeSortBy(column.sortBy)
        }
      } else {
        if (this.options.sortBy === column.sortBy) {
          this.onChangeSortBy('-' + column.sortBy)
        } else {
          this.onChangeSortBy(column.sortBy)
        }
      }
    },

    /**
     * ヘッダー描画
     * @param {*} createElement
     * @param {*} { column }
     */
    renderHeader(createElement: any, { column }: any) {
      return Util.renderHeader(createElement, column.label, Util.getSortClass(this.options.sortBy, column.sortBy))
    }
  }
}
