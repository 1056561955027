/* @flow */
import moment from 'moment'
import { mapActions, mapState } from 'vuex'

import TikTokAnalyticsPublicChart from '@/client/components/TikTokAnalyticsPublic/TikTokAnalyticsPublicChart'
import TikTokAnalyticsPublicFilter from '@/client/components/TikTokAnalyticsPublic/TikTokAnalyticsPublicFilter'
import TikTokAnalyticsPublicHeader from '@/client/components/TikTokAnalyticsPublic/TikTokAnalyticsPublicHeader'
import TikTokAnalyticsPublicSummary from '@/client/components/TikTokAnalyticsPublic/TikTokAnalyticsPublicSummary'

export default {
  components: {
    TikTokAnalyticsPublicChart,
    TikTokAnalyticsPublicFilter,
    TikTokAnalyticsPublicHeader,
    TikTokAnalyticsPublicSummary
  },
  computed: {
    ...mapState('tiktokAnalyticsPublic', {
      params: state => state.params,
      campaign: state => state.campaign,
      loading: state => state.loading
    })
  },
  async created() {
    const hash = this.$route.query.hash

    if (!hash) {
      return
    }

    const params = { hash }

    this.setParams(params)

    await this.fetchCampaign()
    await this.fetchPrizes()

    if (!this.campaign || !this.campaign.id) {
      return
    }

    this.setParams({
      startDatetime: moment(this.campaign.startDatetime).format('YYYY-MM-DD'),
      endDatetime: moment(this.campaign.endDatetime)
        .subtract(1, 'hour')
        .format('YYYY-MM-DD')
    })

    await this.fetchCampaignAnalytics()
  },
  methods: {
    ...mapActions('tiktokAnalyticsPublic', ['fetchCampaignAnalytics', 'fetchCampaign', 'fetchPrizes', 'setParams'])
  }
}
