








import { Component, Prop, Vue } from 'vue-property-decorator'

import Icon from '@/client/components/_atoms/Icon.vue'

@Component({ components: { Icon } })
export default class Account extends Vue {
  @Prop({ type: String, required: true })
  label!: string

  @Prop({ type: Boolean, default: false })
  required!: boolean

  @Prop({ type: Boolean, default: false })
  optional!: boolean
}
