












import { Component, Emit, Prop, Vue } from 'vue-property-decorator'

import Icon from '@/client/components/_atoms/Icon.vue'

type TButtonType = 'default' | 'primary'

@Component({ components: { Icon } })
export default class ButtonIcon extends Vue {
  @Prop({ type: Boolean, default: false })
  disabled!: boolean

  @Prop({ type: String, default: '' })
  name!: string

  @Prop({ type: String, default: '' })
  href!: string

  @Prop({ type: String, default: '' })
  tooltip!: string

  @Prop({ type: String, default: 'default' })
  type!: TButtonType

  @Emit('click')
  onClick(event: Event) {
    return event
  }

  /**
   * hrefが存在する場合、渡されたURLを新規タブで開く
   */
  onButtonClick(event: Event) {
    if (this.href) {
      window.open(this.href)
    } else {
      this.onClick(event)
    }
  }
}
