/* @flow */
import moment from 'moment'
import { mapActions, mapState } from 'vuex'

import TwitterAnalyticsPublicChart from '@/client/components/TwitterAnalyticsPublic/TwitterAnalyticsPublicChart'
import TwitterAnalyticsPublicFilter from '@/client/components/TwitterAnalyticsPublic/TwitterAnalyticsPublicFilter'
import TwitterAnalyticsPublicHeader from '@/client/components/TwitterAnalyticsPublic/TwitterAnalyticsPublicHeader'
import TwitterAnalyticsPublicSummary from '@/client/components/TwitterAnalyticsPublic/TwitterAnalyticsPublicSummary'

export default {
  components: {
    TwitterAnalyticsPublicChart,
    TwitterAnalyticsPublicFilter,
    TwitterAnalyticsPublicHeader,
    TwitterAnalyticsPublicSummary
  },
  computed: {
    ...mapState('twitterAnalyticsPublic', {
      params: state => state.params,
      campaign: state => state.campaign,
      loading: state => state.loading
    })
  },
  async created() {
    const hash = this.$route.query.hash

    if (!hash) {
      return
    }

    const params = { hash }

    this.setParams(params)

    await this.fetchCampaign()
    await this.fetchPrizes()

    if (!this.campaign || !this.campaign.id) {
      return
    }

    this.setParams({
      startDatetime: moment(this.campaign.startDatetime).format('YYYY-MM-DD'),
      endDatetime: moment(this.campaign.endDatetime)
        .subtract(1, 'hour')
        .format('YYYY-MM-DD')
    })

    await this.fetchCampaignAnalytics()
    await this.fetchFollowerAnalytics()
  },
  methods: {
    ...mapActions('twitterAnalyticsPublic', [
      'fetchCampaignAnalytics',
      'fetchFollowerAnalytics',
      'fetchCampaign',
      'fetchPrizes',
      'setParams'
    ])
  }
}
