/* @flow */
import Icon from '@/client/components/basic/icon'

export default {
  components: {
    Icon
  },
  props: {
    post: Object,
    snsType: {
      type: String,
      default: 'twitter'
    },
    isWhite: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    pictureUrl() {
      if (this.post.pictureUrl) {
        return this.post.pictureUrl
      }

      if (this.snsType === 'twitter') {
        return require('@/client/assets/images/twitter_default_profile.png')
      }

      if (this.snsType === 'instagram') {
        return require('@/client/assets/images/instagram_default_profile.png')
      }
    }
  }
}
