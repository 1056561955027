import 'reflect-metadata'
import '@/client/filters/filters'
import '@/client/assets/scss/app.scss'
import '@mdi/font/css/materialdesignicons.css'

import VueCompositionAPI from '@vue/composition-api'
import ElementUI, { Message, Notification } from 'element-ui'
import ElementLocale from 'element-ui/lib/locale'
import ElementEnLocale from 'element-ui/lib/locale/lang/en'
import ElementJaLocale from 'element-ui/lib/locale/lang/ja'
import moment from 'moment-timezone'
import Vue from 'vue'
import GetTextPlugin from 'vue-gettext'
import VueI18n from 'vue-i18n'
import VueTimeago from 'vue-timeago'
import Vuelidate from 'vuelidate'
import { sync } from 'vuex-router-sync'

import App from '@/client/App.vue'
import router from '@/client/router'
import store from '@/client/store'

import translations from './locale/translation.json'

moment.locale('ja')

Vue.use(VueCompositionAPI as any)

// デフォルトロケール
const defaultLocale = 'ja'

// GetTextPlugin
Vue.use(GetTextPlugin, {
  availableLanguages: {
    ja: '日本語',
    en: 'English'
  },
  defaultLanguage: defaultLocale,
  translations: translations,
  silent: true
})

// VueI18n
Vue.use(VueI18n)
const localeMessages = {
  en: {
    ...ElementEnLocale
  },
  ja: {
    ...ElementJaLocale
  }
}
const i18n = new VueI18n({
  locale: defaultLocale,
  messages: localeMessages
})

Vue.use(ElementUI)

Vue.prototype.$notify = Notification
ElementLocale.i18n((key, value) => i18n.t(key, value))

Vue.use(Vuelidate)
Vue.use(VueTimeago, {
  name: 'timeago',
  locale: defaultLocale,
  locales: {
    ja: require('vue-timeago/locales/ja-JP.json'),
    en: require('vue-timeago/locales/en-US.json')
  }
})

Vue.prototype.$message = (options: any) => {
  options.message = Vue.prototype.$gettext(options.message)
  Message(options)
}

// 言語をストアに保存する
store.dispatch('system/setLanguage', defaultLocale)

// エラーハンドリング
Vue.config.errorHandler = err => {
  console.error(err.stack)
}

window.addEventListener('unhandledrejection', e => {
  console.error('Unhandled Rejection at:', e)
})

Vue.config.productionTip = false

sync(store, router)

const app = new Vue({ i18n, router, store, render: h => h(App) })

app.$mount('#app-root')
