


























































































import { Component, Emit, Vue, Watch } from 'vue-property-decorator'
import { mapActions, mapState } from 'vuex'

import Icon from '@/client/components/_atoms/Icon.vue'
import Message from '@/client/components/_atoms/Message.vue'
import Panel from '@/client/components/_atoms/Panel.vue'
import Account from '@/client/components/_molecules/Account.vue'
import FormItemLabel from '@/client/components/_molecules/FormItemLabel.vue'
import { TrackingService } from '@/client/services'
import { CampaignDialogActions, CampaignDialogState } from '@/client/store/campaign_dialog.module'

@Component({
  components: {
    Account,
    FormItemLabel,
    Icon,
    Message,
    Panel
  },
  computed: mapState('campaign_dialog', ['api_accounts', 'api_campaign', 'form', 'mode', 'fetchCampaign']),
  methods: mapActions('campaign_dialog', ['setForm', 'getDefaultKeywords'])
})
export default class CampaignCreateAccount extends Vue {
  api_accounts!: CampaignDialogState['api_accounts']
  api_campaign!: CampaignDialogState['api_campaign']
  form!: CampaignDialogState['form']
  mode!: CampaignDialogState['mode']
  setForm!: CampaignDialogActions['setForm']
  getDefaultKeywords!: CampaignDialogActions['getDefaultKeywords']

  account_id: string | number = ''
  target_sns = 'twitter'

  created() {
    if (this.api_accounts.length) {
      this.account_id = this.api_accounts[0].id
      this.target_sns = this.api_accounts[0].target_sns
    }

    if (this.form.account_id) {
      this.account_id = this.form.account_id
      this.target_sns = this.form.target_sns
    }

    if (this.account_id) {
      this.setForm({ ...this.form, account_id: this.account_id, target_sns: this.target_sns })
    }
  }

  @Watch('api_accounts')
  set() {
    if (this.api_accounts.length) {
      this.account_id = this.api_accounts[0].id

      const target_sns = ['instagram', 'tiktok', 'twitter'].includes(this.api_accounts[0].target_sns)
        ? this.api_accounts[0].target_sns
        : 'twitter'

      this.setForm({ ...this.form, target_sns, account_id: this.account_id })
    }
  }

  get account() {
    const account = this.api_accounts.find(account => {
      return !this.form.account_id
        ? account.id === this.account_id && account.target_sns === this.target_sns
        : account.id === this.form.account_id && account.target_sns === this.form.target_sns
    })

    return account || { name: '', picture_url: '', target_sns: 'twitter', is_token_expired: false, is_verified: true }
  }

  get is_verified() {
    return this.account.is_verified
  }

  get is_twitter() {
    return this.account.target_sns === 'twitter'
  }

  get is_instagram() {
    return this.account.target_sns === 'instagram'
  }

  get is_tiktok() {
    return this.account.target_sns === 'tiktok'
  }

  get is_token_expired() {
    return this.account.is_token_expired
  }

  get is_edit_mode() {
    return this.mode === 'edit'
  }

  get selected_account() {
    return this.api_campaign && this.api_campaign.account
      ? {
          name: this.api_campaign.account.name,
          picture_url: this.api_campaign.account.picture_url,
          target_sns: this.api_campaign.target_sns
        }
      : null
  }

  get is_account_deleted() {
    return !this.selected_account && this.mode === 'edit'
  }

  @Emit('cancel')
  cancel(payload) {
    return payload
  }

  /**
   * アカウントを変更
   */
  async changeAccount(account_key: string) {
    TrackingService.sendEvent('select:キャンペーンの新規作成|アカウント|実施アカウント')

    const account = this.api_accounts.find(account => account.key === account_key)

    if (!account) {
      return
    }

    const is_valid = ['start_date', 'start_time', 'end_date', 'end_time'].some(v => this.form[v])

    this.account_id = account.id
    this.target_sns = account.target_sns

    if (this.account.target_sns !== account.target_sns && is_valid) {
      const confirm = window.confirm('この画面以降の編集内容がリセットされます。よろしいですか？')

      if (!confirm) {
        return
      }

      await this.setForm({
        title: this.form.title,
        account_id: account.id,
        target_sns: this.target_sns,
        start_date: null,
        start_time: null,
        end_date: null,
        end_time: null,
        entry_action_type: 'retweet',
        campaign_type: 'keyword',
        keywords: [],
        or_keywords: [],
        is_include_mention: false,
        is_include_reply: false,
        is_keyword_judgment: true
      })

      await this.getDefaultKeywords()
    } else {
      await this.setForm({
        ...this.form,
        account_id: this.account_id,
        target_sns: this.target_sns
      })
    }
  }
}
