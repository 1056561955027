import { render, staticRenderFns } from "./account_add_dialog.vue?vue&type=template&id=57bd0fdd&scoped=true"
import script from "./account_add_dialog.component.js?vue&type=script&lang=js&external"
export * from "./account_add_dialog.component.js?vue&type=script&lang=js&external"
import style0 from "./account_add_dialog.vue?vue&type=style&index=0&id=57bd0fdd&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "57bd0fdd",
  null
  
)

export default component.exports