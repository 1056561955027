/* @flow */
import { mapActions, mapState } from 'vuex'

import MessageHistory from '@/client/components/twitter_instantwin_message_history/message_history'

export default {
  components: {
    MessageHistory
  },
  computed: {
    ...mapState('system', {
      currentGroupId: state => state.currentGroup.id
    }),
    ...mapState('twitterInstantwinMessageHistory', {
      campaign: state => {
        if (state.campaign) {
          return state.campaign
        }
        return {}
      }
    })
  },
  async mounted() {
    const params = {
      campaignId: this.$route.params.campaignId
    }

    this.setHistoryParams(params)
    this.fetchMessages(params)

    await this.fetchCampaign()

    if (this.campaign && this.campaign.id && this.currentGroupId !== this.campaign.groupId) {
      this.setCurrentGroup(this.campaign.groupId)
    }

    this.setBreadcrumb([
      {
        name: this.$gettext('キャンペーン一覧'),
        link: '/campaigns',
        iconUrl: null,
        iconType: null
      },
      {
        name: this.campaign.title,
        link: `/twitter_instantwins/${this.campaign.id}`,
        iconName: this.campaign.account ? this.campaign.account.name : '削除されたアカウント',
        iconUrl: this.campaign.account ? this.campaign.account.pictureUrl : null,
        iconType: this.campaign.snsType,
        label: this.campaign.type === 'instantwin' ? 'インスタントウィン' : null,
        isIcon: true
      }
    ])
  },

  methods: {
    ...mapActions('system', ['setCurrentGroup']),
    ...mapActions('navbar', ['setBreadcrumb']),
    ...mapActions('twitterInstantwinMessageHistory', [
      'resetMessageHistory',
      'fetchCampaign',
      'fetchMessages',
      'setHistoryParams'
    ])
  },

  beforeRouteLeave(to: any, from: any, next: any) {
    // 応募者ストアをリセット
    this.resetMessageHistory()
    next()
  }
}
