/* @flow */
import moment from 'moment'
import { mapActions, mapState } from 'vuex'

import CsvDownload from '@/client/components/basic/csv_download'
import InstantwinMessageDialog from '@/client/components/twitter_instantwin_message/instantwin_message_dialog'
import InstantwinMessageTable from '@/client/components/twitter_instantwin_message/instantwin_message_table'
import MessageQueueDialog from '@/client/components/twitter_instantwin_message/message_queue_dialog'
import SerialCodeManagement from '@/client/components/twitter_instantwin_message/serial_code_management'
import { CAMPAIGN_STATUS } from '@/client/constant'
import api from '@/client/core/api'
import util from '@/client/core/util'

export default {
  components: {
    CsvDownload,
    InstantwinMessageTable,
    InstantwinMessageDialog,
    MessageQueueDialog,
    SerialCodeManagement
  },
  data: () => {
    return {
      isLeave: false
    }
  },
  computed: {
    ...mapState('system', {
      currentGroupId: state => state.currentGroup.id,
      allowCreateInstantwin: state => Boolean(state.currentGroup.allowCreateInstantwin),
      isSystemAdmin: state => Boolean(state.user.isSystemAdmin)
    }),
    ...mapState('twitterInstantwinMessage', {
      messages: state => state.messages,
      error: state => state.error,
      params: state => state.params,
      campaignInfo: state => state.campaignInfo,
      isLoadingInstantwinMessages: state => state.isLoadingInstantwinMessages,
      isLoadingCampaign: state => state.isLoadingCampaign,
      campaign: state => {
        if (state.campaign) {
          return state.campaign
        }
        return {}
      },
      options: state => {
        return {
          total: state.total,
          pagingNo: state.params.pagingNo,
          limitCount: state.params.limitCount,
          sortBy: state.params.sortBy
        }
      }
    }),
    isCampaignFinished() {
      return [CAMPAIGN_STATUS.DURING_FINAL_COLLECTION, CAMPAIGN_STATUS.COLLECTION_COMPLETED].includes(
        this.campaign.status
      )
    },
    is_account_exist() {
      return typeof this.campaign.account === 'object'
    }
  },
  async mounted() {
    const params = {
      campaignId: this.$route.params.campaignId
    }

    this.setParams(params)

    await this.fetchCampaign()

    if (this.isLeave || !this.campaign || !this.campaign.id) {
      return
    }

    if (this.currentGroupId !== this.campaign.groupId) {
      this.setCurrentGroup(this.campaign.groupId)
    }

    this.setBreadcrumb([
      {
        name: this.$gettext('キャンペーン一覧'),
        link: '/campaigns',
        iconUrl: null,
        iconType: null
      },
      {
        name: this.campaign.title,
        link: null,
        iconName: this.campaign.account ? this.campaign.account.name : '削除されたアカウント',
        iconUrl: this.campaign.account ? this.campaign.account.pictureUrl : null,
        iconType: this.campaign.snsType,
        label: this.campaign.type === 'instantwin' ? 'インスタントウィン' : null,
        isIcon: true
      }
    ])
  },

  watch: {
    params() {
      this.fetchInstantwinMessages()
    }
  },

  methods: {
    ...mapActions('system', ['setCurrentGroup']),
    ...mapActions('twitterInstantwinMessage', ['fetchInstantwinMessages', 'setParams', 'fetchCampaign', 'reset']),
    ...mapActions('navbar', ['setBreadcrumb']),

    /**
     * ページを変更イベント
     * @param {number} page ページ
     */
    onChangePagingNo(page: number) {
      if (this.params.pagingNo === page) {
        return
      }
      this.setParams({ pagingNo: page })
      util.scrollToTop()
    },

    /**
     * リミット数を変更イベント
     * @param {number} limitCount リミット数
     */
    onChangeLimitCount(limitCount: number) {
      if (this.params.limitCount === limitCount) {
        return
      }
      this.setParams({ limitCount })
    },

    /**
     * ソートを変更イベント
     * @param {string} sortBy ソート
     */
    onChangeSortBy(sortBy: string) {
      if (this.params.sortBy === sortBy) {
        return
      }
      this.setParams({ sortBy })
    },

    /**
     * CSVダウンロード
     */
    async downloadCsv() {
      const params = Object.assign({}, this.params, {
        limitCount: -1
      })
      const result = await api.get('/twitter_instantwin_messages', {
        params
      })

      if (!result.data) {
        return {
          error: 'ERROR_LOADING'
        }
      }

      const messages = result.data.messages
      const filename = `instantwin_messages_${moment().format('YYYY_MM_DD')}`
      const csvData = []
      csvData.push([
        this.$gettext('ステータス'),
        this.$gettext('種別'),
        this.$gettext('メッセージ名'),
        this.$gettext('開始日時'),
        this.$gettext('終了日時'),
        this.$gettext('1日の切り替え時間'),
        this.$gettext('1日1回抽選'),
        this.$gettext('当メッセージで当選実績がある場合、落選にする'),
        this.$gettext('別メッセージで当選実績がある場合、落選にする'),
        this.$gettext('キーワード指定'),
        this.$gettext('重み付け値'),
        this.$gettext('返信方法'),
        this.$gettext('当選率'),
        this.$gettext('当選率変動'),
        this.$gettext('配信数(本日)'),
        this.$gettext('1日の最大当選数'),
        this.$gettext('配信数(本日の残り)'),
        this.$gettext('配信数'),
        this.$gettext('配信数(残り)'),
        this.$gettext('メンション内容'),
        this.$gettext('添付ファイル名(メンション)'),
        this.$gettext('ダイレクトメッセージ内容'),
        this.$gettext('添付ファイル名(DM)')
      ])

      // CSVデータを格納
      messages.forEach(message => {
        let status = ''
        switch (message.status) {
          case 2:
            status = this.$gettext('スケジュール')
            break
          case 1:
            status = this.$gettext('アクティブ')
            break
          case 0:
            status = this.$gettext('終了')
            break
        }

        let replyType = ''
        switch (message.replyType) {
          case 'mention':
            replyType = this.$gettext('メンション')
            break
          case 'dm':
            replyType = this.$gettext('DM')
            break
          case 'mention-and-dm':
            replyType = this.$gettext('メンション & DM')
            break
        }

        let mentionBody = ''
        let mentionFileName = ''
        let dmBody = ''
        let dmFileName = ''
        message.tw_instantwin_message_contents.forEach(content => {
          switch (content.replyType) {
            case 'mention':
              mentionBody = content.body
              if (content.tw_instantwin_message_content_medias.length) {
                mentionFileName = content.tw_instantwin_message_content_medias[0].mediaOriginName
              }
              break

            case 'dm':
              dmBody = content.body
              if (content.tw_instantwin_message_content_medias.length) {
                dmFileName = content.tw_instantwin_message_content_medias[0].mediaOriginName
              }
              break
          }
        })

        const entryKeyword = message.entryKeyword ? JSON.parse(message.entryKeyword) : []

        let winningRateChangeType = ''

        if (message.winningRateChangeType === 'none') {
          winningRateChangeType = this.$gettext('変動なし')
        } else if (message.winningRateChangeType === 'auto') {
          winningRateChangeType = this.$gettext('自動変動')
        } else if (message.winningRateChangeType === 'time') {
          winningRateChangeType = this.$gettextInterpolate(
            this.$gettext('配信期間の%{elapsedRate}割経過時、当選率を%{elapsedWinningRate}%にする'),
            {
              elapsedRate: message.elapsedRate,
              elapsedWinningRate: message.elapsedWinningRate
            }
          )
        }

        const isDailyLottery = message.isDailyLottery ? 'ON' : 'OFF'
        const excludeOtherMessageMultiWinner = message.excludeOtherMessageMultiWinner ? 'ON' : 'OFF'
        const excludeMessageMultiWinner = message.excludeMessageMultiWinner ? 'ON' : 'OFF'
        const winningRate =
          message.winningRateChangeType === 'auto' ? this.$gettext('自動変動') : message.winningRate + '%'
        const dailyWinnerCount = message.dailyWinnerCount ? message.dailyWinnerCount : '制限なし'

        csvData.push([
          status,
          message.messageType === 'win' ? '当選' : '落選',
          message.name,
          this.$options.filters.datetime(message.startDatetime),
          this.$options.filters.datetime(message.endDatetime),
          message.messageType === 'win' ? message.dailyWinnerChangeTime : '-',
          message.messageType === 'win' ? isDailyLottery : '-',
          message.messageType === 'win' ? excludeOtherMessageMultiWinner : '-',
          message.messageType === 'win' ? excludeMessageMultiWinner : '-',
          entryKeyword.join(','),
          message.weightCount,
          replyType,
          message.messageType === 'win' ? winningRate : '-',
          message.messageType === 'win' ? winningRateChangeType : '-',
          message.messageType === 'win' ? message.dailySendCount : '-',
          message.messageType === 'win' ? dailyWinnerCount : '-',
          message.messageType === 'win' ? message.remainDailySendCount : '-',
          message.sendCount,
          message.messageType === 'win' ? message.remainSendCount : '-',
          mentionBody ? mentionBody.replace(/(\r\n|\n|\r)/gm, '') : '',
          mentionFileName,
          dmBody ? dmBody.replace(/(\r\n|\n|\r)/gm, '') : '',
          dmFileName
        ])
      })
      return {
        filename,
        data: csvData
      }
    },

    /**
     * ダイアログを開く
     */
    openInstantwinMessageDialog() {
      this.$refs.instantwinMessageDialog.open()
    }
  },

  beforeRouteLeave(to: any, from: any, next: any) {
    // 応募者ストアをリセット
    this.reset()
    this.isLeave = true
    next()
  }
}
