































































































import { Component, Vue } from 'vue-property-decorator'
import { mapActions, mapState } from 'vuex'

import Icon from '@/client/components/_atoms/Icon.vue'
import Message from '@/client/components/_atoms/Message.vue'
import Panel from '@/client/components/_atoms/Panel.vue'
import Account from '@/client/components/_molecules/Account.vue'
import ButtonIcon from '@/client/components/_molecules/ButtonIcon.vue'
import Keyword from '@/client/components/_molecules/Keyword.vue'
import Tags from '@/client/components/_molecules/Tags.vue'
import API from '@/client/core/api'
import { TrackingService } from '@/client/services'
import { SettingGroupActions, SettingGroupState } from '@/client/store/setting_group.module'

type TProfileKeywordSettingOptions = {
  is_copy?: boolean
  setting_id?: number
}

@Component({
  components: {
    Icon,
    Message,
    Panel,
    Account,
    ButtonIcon,
    Keyword,
    Tags
  },
  computed: { ...mapState('setting_group', ['api_profile_keyword_settings']) },
  methods: {
    ...mapActions('setting_group', [
      'fetchProfileKeywords',
      'createProfileKeyword',
      'updateProfileKeyword',
      'deleteProfileKeyword'
    ])
  }
})
export default class DialogProfileKeywordSetting extends Vue {
  api_profile_keyword_settings!: SettingGroupState['api_profile_keyword_settings']
  fetchProfileKeywords!: SettingGroupActions['fetchProfileKeywords']
  createProfileKeyword!: SettingGroupActions['createProfileKeyword']
  updateProfileKeyword!: SettingGroupActions['updateProfileKeyword']
  deleteProfileKeyword!: SettingGroupActions['deleteProfileKeyword']

  visible = false
  is_submit = false
  is_copy = false
  setting_id: number | null = null
  init_name = ''
  init_positive_keyword: string[] = []
  init_negative_keyword: string[] = []
  init_is_active = false
  form: {
    id: number
    name: string
    positive_keyword: string[]
    negative_keyword: string[]
    is_active: boolean
  } = {
    id: 0,
    name: '',
    positive_keyword: [],
    negative_keyword: [],
    is_active: false
  }
  positive_keyword_count = 0
  negative_keyword_count = 0

  get is_edit() {
    return this.setting_id !== null && !this.is_copy
  }

  get title() {
    return this.is_edit ? 'キーワード判定セットの編集' : 'キーワード判定セットの追加'
  }

  get switch_text() {
    if (this.form.is_active) {
      return 'オン'
    }

    return 'オフ'
  }

  get is_error_keywords() {
    return this.form.positive_keyword.length === 0 && this.form.negative_keyword.length === 0
  }

  get is_error_name() {
    return this.api_profile_keyword_settings
      .filter(v => v.id !== this.form.id)
      .map(v => v.name)
      .includes(this.form.name.trim())
  }

  get is_disabled() {
    return !this.form.name.trim() || this.is_error_name || this.is_error_keywords || this.is_submit
  }

  /**
   * ダイアログキャンペーンを開く
   */
  async open(options: TProfileKeywordSettingOptions) {
    this.setting_id = options.setting_id ? options.setting_id : null
    this.is_copy = options.is_copy ? options.is_copy : false

    if (!this.setting_id && !this.is_copy) {
      this.reset()
      this.visible = true

      return
    }

    const response = await API.get(`/v1/profile_keyword_settings/${this.setting_id}`, {})

    if (response.error && response.error.type === 'NO_DATA_EXISTS') {
      this.$notify({
        title: '対象のキーワード判定はすでに削除されています。',
        message: '',
        customClass: 'danger'
      })

      await this.fetchProfileKeywords()

      return
    }

    if (!response.data) {
      this.$notify({
        title: '対象のキーワード判定の取得に失敗しました。',
        message: '恐れ入りますが、時間をおいて再度お試しください。',
        customClass: 'danger'
      })

      await this.fetchProfileKeywords()
      return
    }

    const keyword_settings = response.data

    this.form.id = this.is_copy ? 0 : keyword_settings.id
    this.form.name =
      this.is_copy && keyword_settings.name.length < 125 ? keyword_settings.name + 'のコピー' : keyword_settings.name
    this.form.positive_keyword = keyword_settings.positive_keyword
    this.form.negative_keyword = keyword_settings.negative_keyword
    this.form.is_active = keyword_settings.is_active

    this.init_name = this.form.name
    this.init_positive_keyword = this.form.positive_keyword
    this.init_negative_keyword = this.form.negative_keyword
    this.init_is_active = this.form.is_active

    this.visible = true
  }

  /**
   * 初期化
   */
  reset() {
    this.form.name = ''
    this.form.positive_keyword = []
    this.form.negative_keyword = []
    this.form.is_active = false
    this.init_name = ''
    this.init_positive_keyword = []
    this.init_negative_keyword = []
    this.init_is_active = false
  }

  /**
   * ダイアログを閉じる
   */
  close() {
    TrackingService.sendEvent(`click:自己紹介キーワード判定|セットの${!this.setting_id ? '追加' : '編集'}|閉じる`)

    const is_change_positive_keyword =
      this.is_edit || this.is_copy
        ? this.init_positive_keyword !== this.form.positive_keyword
        : this.form.positive_keyword.length !== 0

    const is_change_negative_keyword =
      this.is_edit || this.is_copy
        ? this.init_negative_keyword !== this.form.negative_keyword
        : this.form.negative_keyword.length !== 0

    if (
      this.init_name.trim() === this.form.name.trim() &&
      !is_change_positive_keyword &&
      !is_change_negative_keyword &&
      this.init_is_active === this.form.is_active
    ) {
      this.reset()

      this.visible = false
      return
    }

    const confirm = window.confirm('編集内容が保存されていません。ダイアログを閉じますか？')

    if (!confirm) {
      return
    }

    this.reset()

    this.visible = false
  }

  /**
   * キーワードを保存
   */
  async submit() {
    TrackingService.sendEvent(`click:自己紹介キーワード判定|セットの${!this.setting_id ? '追加' : '編集'}|保存`)

    if (this.is_disabled || this.is_submit) return

    this.is_submit = true

    if (this.setting_id && !this.is_copy) {
      const payload_update = {
        keyword_setting_id: this.setting_id,
        name: this.form.name.trim(),
        positive_keyword: this.form.positive_keyword,
        negative_keyword: this.form.negative_keyword,
        is_active: this.form.is_active
      }

      const result_update = await this.updateProfileKeyword(payload_update)

      this.is_submit = false

      if (result_update.error && result_update.error.type === 'DUPLICATE') {
        this.$notify({
          title: '入力したキーワード判定名はすでに登録されています。',
          message: '登録済みのキーワード判定名は指定できません。',
          customClass: 'danger'
        })

        return
      }

      if (result_update.error && result_update.error.type === 'MAX_COUNT_OVER') {
        this.$notify({
          title: 'デフォルト設定をONにして保存できません。',
          message: 'ポジティブキーワードまたはネガティブキーワードの合計が200文字以内になるように設定してください。',
          customClass: 'danger'
        })

        return
      }

      if (!result_update.data) {
        this.$notify({
          title: 'キーワード判定の変更に失敗しました。',
          message: '恐れ入りますが、時間をおいて再度お試しください。',
          customClass: 'danger'
        })

        return
      }

      this.$notify({
        title: 'キーワード判定を変更しました。',
        message: '',
        customClass: 'success'
      })

      this.reset()

      this.visible = false
    } else {
      const payload_create = {
        name: this.form.name.trim(),
        positive_keyword: this.form.positive_keyword,
        negative_keyword: this.form.negative_keyword,
        is_active: this.form.is_active
      }

      const result_create = await this.createProfileKeyword(payload_create)

      this.is_submit = false

      if (result_create.error && result_create.error.type === 'DUPLICATE') {
        this.$notify({
          title: '入力したキーワード判定名はすでに登録されています。',
          message: '登録済みのキーワード判定名は指定できません。',
          customClass: 'danger'
        })

        return
      }

      if (result_create.error && result_create.error.type === 'MAX_COUNT_OVER') {
        this.$notify({
          title: 'デフォルト設定をONにして保存できません。',
          message: 'ポジティブキーワードまたはネガティブキーワードの合計が200文字以内になるように設定してください。',
          customClass: 'danger'
        })

        return
      }

      if (!result_create.data) {
        this.$notify({
          title: 'キーワード判定の追加に失敗しました。',
          message: '恐れ入りますが、時間をおいて再度お試しください。',
          customClass: 'danger'
        })

        return
      }

      this.$notify({
        title: 'キーワード判定を追加しました。',
        message: '',
        customClass: 'success'
      })
    }

    this.reset()

    this.visible = false
  }

  /**
   * キーワードの削除
   */
  async deleteKeyword() {
    TrackingService.sendEvent(`click:自己紹介キーワード判定|セットの${!this.setting_id ? '追加' : '編集'}|削除`)

    if (this.is_submit || !this.setting_id) return

    let message = ''
    message += 'キーワードを削除してよろしいですか?' + '\n'
    message += 'この操作は取り消しできません。'

    const confirm = window.confirm(message)

    if (!confirm) return

    this.is_submit = true

    const result = await this.deleteProfileKeyword(this.setting_id)

    this.is_submit = false

    if (result.error && result.error.type === 'NO_DATA_EXISTS') {
      this.$notify({
        title: '対象のキーワード判定はすでに削除されています。',
        message: '',
        customClass: 'danger'
      })

      return
    }

    if (!result.data) {
      this.$notify({
        title: 'キーワード判定の削除に失敗しました。',
        message: '',
        customClass: 'danger'
      })

      return
    }

    this.$notify({
      title: 'キーワード判定を削除しました。',
      message: '',
      customClass: 'success'
    })

    this.reset()

    this.visible = false
  }

  onChangeNameEvent() {
    TrackingService.sendEvent(
      `input:自己紹介キーワード判定|セットの${!this.setting_id ? '追加' : '編集'}|キーワード判定セット名`
    )
  }

  onChangeIsActive() {
    TrackingService.sendEvent(`switch:自己紹介キーワード判定|セットの${!this.setting_id ? '追加' : '編集'}|デフォルト`)
  }

  changePositiveKeywords() {
    if (!this.positive_keyword_count) this.positive_keyword_count = this.init_positive_keyword.length

    if (this.positive_keyword_count < this.form.positive_keyword.length) {
      TrackingService.sendEvent(
        `click:自己紹介キーワード判定|セットの${!this.setting_id ? '追加' : '編集'}|ポジティブキーワード|追加`
      )
    } else
      TrackingService.sendEvent(
        `click:自己紹介キーワード判定|セットの${!this.setting_id ? '追加' : '編集'}|ポジティブキーワード|削除`
      )

    this.positive_keyword_count = this.form.positive_keyword.length
  }

  changeNegativeKeywords() {
    if (!this.negative_keyword_count) this.negative_keyword_count = this.init_negative_keyword.length

    if (this.negative_keyword_count < this.form.negative_keyword.length) {
      TrackingService.sendEvent(
        `click:自己紹介キーワード判定|セットの${!this.setting_id ? '追加' : '編集'}|ネガティブキーワード|追加`
      )
    } else
      TrackingService.sendEvent(
        `click:自己紹介キーワード判定|セットの${!this.setting_id ? '追加' : '編集'}|ネガティブキーワード|削除`
      )

    this.negative_keyword_count = this.form.negative_keyword.length
  }
}
