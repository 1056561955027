/* @flow */
import { required } from 'vuelidate/lib/validators'
import { mapActions, mapState } from 'vuex'

import SelectForm from '@/client/components/basic/select_form'
import api from '@/client/core/api'
import { TrackingService } from '@/client/services'

export default {
  props: {
    isChangeWithoutSubmit: Boolean
  },
  components: {
    SelectForm
  },
  data() {
    return {
      serialCode: '',
      isSubmit: false,
      status: {
        value: 'DM',
        options: [
          {
            label: this.$gettext('当選DM'),
            value: 'DM'
          },
          {
            label: this.$gettext('Webページ'),
            value: 'web'
          }
        ],
        onChange: (value: 'DM' | 'web') => {
          TrackingService.sendEvent('select:当選メッセージの管理|シルアルコード追加|割当タイミング指定')

          this.status.value = value
        }
      }
    }
  },

  watch: {
    isFormChanged(value: boolean) {
      this.$emit('update:isChangeWithoutSubmit', value)
    }
  },

  created() {
    if (this.campaign.type === 'web_instantwin') {
      this.status.value = 'web'
    }
  },

  computed: {
    ...mapState('twitterSerialCodeDialog', ['campaignId', 'prizeId']),
    ...mapState('twitterApplicant', {
      campaign: state => {
        if (state.campaign) {
          return state.campaign
        }
        return {}
      }
    }),

    isFormChanged() {
      return this.serialCode !== ''
    },

    maxSerialCodeError() {
      if (!this.serialCode.trim()) {
        return false
      }

      return this.serialCode.trim().split('\n').length > 10000
    },

    disabled() {
      return this.$v.$invalid || !this.serialCode.trim() || this.maxSerialCodeError || this.isSubmit
    }
  },
  methods: {
    ...mapActions('twitterSerialCodeDialog', ['fetchSerialCodeList', 'setSerialCodeIsCreated']),

    /**
     * 値のリセット
     */
    reset() {
      this.serialCode = ''
      this.isSubmit = false
      this.$emit('update:isChangeWithoutSubmit', false)
      this.$v.serialCode.$reset()
    },

    /**
     * シリアルコード一覧に戻る
     */
    backToSerialCodeList() {
      TrackingService.sendEvent('click:当選メッセージの管理|シルアルコード追加|戻る')

      if (!this.isChangeWithoutSubmit) {
        return this.setSerialCodeIsCreated(false)
      }

      const confirm = window.confirm(this.$gettext('編集内容が保存されていません。ダイアログを閉じてよろしいですか?'))

      if (!confirm) {
        return
      }

      this.$emit('update:isChangeWithoutSubmit', false)
      this.setSerialCodeIsCreated(false)
    },

    saveSerialCode() {
      TrackingService.sendEvent('click:当選メッセージの管理|シルアルコード追加|保存')

      if (this.isSubmit) return

      const serialCodes = this.serialCode.split('\n').filter(code => code)

      this.postButton({
        campaignId: this.campaignId,
        prizeId: this.prizeId,
        serialCodes,
        type: this.status.value
      })
    },

    /**
     * 送信完了イベント
     */
    onSubmitSuccess() {
      this.reset()
      this.fetchSerialCodeList()
      this.setSerialCodeIsCreated(false)
    },

    /**
     * シリアルコードの新規作成
     * @param {Object} params 送信データ
     */
    async postButton(params: any) {
      this.isSubmit = true

      if (params.serialCodes.some(code => code.length > 256)) {
        this.isSubmit = false

        this.$notify({
          title: this.$gettext('最大文字数を超えるシリアルコードが含まれています。'),
          customClass: 'danger',
          duration: 5000
        })
        return
      }

      const response = await api.post(`/twitter_serial_codes`, params)

      setTimeout(() => {
        this.isSubmit = false
      }, 2000)

      if (response.data) {
        let title = this.$gettextInterpolate(this.$gettext('%{savedCount}件シリアルコードを保存しました。'), {
          savedCount: response.data.savedCount
        })

        if (response.data.duplicatedCount) {
          title = this.$gettextInterpolate(
            this.$gettext('%{savedCount}件シリアルコードを保存しました。(重複%{duplicatedCount}件)'),
            {
              savedCount: response.data.savedCount,
              duplicatedCount: response.data.duplicatedCount
            }
          )
        }

        this.$notify({
          title,
          customClass: 'success',
          duration: 5000
        })
        this.onSubmitSuccess()
        return
      }

      this.$notify({
        title: this.$gettext('シリアルコードの保存に失敗しました。'),
        message: this.$gettext('恐れ入りますが、時間をおいて再度お試しください。'),
        customClass: 'danger',
        duration: 5000
      })
    },

    onChangeSerialCodeBody() {
      TrackingService.sendEvent('input:当選メッセージの管理|シルアルコード追加|入力')
    }
  },
  validations: {
    serialCode: {
      required
    }
  }
}
