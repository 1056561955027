/* @flow */
import api from '@/client/core/api'
import {
  type TGetGetMessagesQueueByIdResult,
  type TGetInstantwinQueue,
  type TInstantwinMessage
} from '@/typings/api/twitter_instantwin_queues.type'

type TGetQueueMessageParams = {
  sortBy: string,
  limitCount: number,
  pagingNo: number,
  status: string
}

// mutations type
const RESET = 'RESET'
const SET_LOADING = 'SET_LOADING'
const SET_QUEUES_LIST = 'SET_QUEUES_LIST'
const SET_QUEUES_LIST_PARAMS = 'SET_QUEUES_LIST_PARAMS'
const SET_QUEUES_MESSAGE = 'SET_QUEUES_MESSAGE'
const SET_QUEUES_MESSAGE_PARAMS = 'SET_QUEUES_MESSAGE_PARAMS'
const SET_QUEUES_MESSAGE_ID = 'SET_QUEUES_MESSAGE_ID'

type StateStore = {
  loading: boolean,
  queues: {
    list: TGetInstantwinQueue[],
    params: { campaignId: number, sortBy: string }
  },
  queueMessages: {
    queueId: number,
    list: TInstantwinMessage[],
    total: number,
    params: TGetQueueMessageParams
  }
}

// ストアーcontext
type Context = {
  commit: Function,
  getters: any,
  state: StateStore,
  dispatch: Function
}

const initState: StateStore = {
  loading: true,
  queues: {
    list: [],
    params: {
      campaignId: 0,
      sortBy: '-actionDatetime'
    }
  },
  queueMessages: {
    queueId: 0,
    list: [],
    total: 2,
    params: {
      sortBy: '-actionDatetime',
      limitCount: 25,
      pagingNo: 1,
      status: ''
    }
  }
}

const state = Object.assign({}, initState)

// getters
const getters = {}

// actions
const actions = {
  /**
   * ストアをリセット
   * @param {Context} context
   */
  async reset(context: Context) {
    context.commit(RESET)
  },

  /**
   * Set value loading
   * @param {Context} context
   * @param {number} loading loading
   */
  setLoading(context: Context, loading: boolean) {
    context.commit(SET_LOADING, loading)
  },

  /**
   * Get queue list
   * @param {Context} context
   */
  async fetchQueueList(context: Context) {
    if (!context.state.queues.params.campaignId || !context.state.queues.params.sortBy) {
      return
    }

    context.commit(SET_LOADING, true)

    const queues = await api.get('/twitter_instantwin_queues', {
      params: context.state.queues.params
    })

    context.commit(SET_LOADING, false)

    if (!queues.data) {
      return
    }

    context.commit(SET_QUEUES_LIST, queues)
  },

  /**
   * Get queue messages
   * @param {Context} context
   */
  async fetchQueueMessage(context: Context) {
    if (!context.state.queueMessages.queueId) {
      return
    }

    context.commit(SET_LOADING, true)

    const queueId = context.state.queueMessages.queueId

    const queueMessages = await api.get(`/twitter_instantwin_queues/${queueId}/messages`, {
      params: context.state.queueMessages.params
    })

    context.commit(SET_LOADING, false)

    if (!queueMessages.data) {
      return
    }

    context.commit(SET_QUEUES_MESSAGE, queueMessages)
  },

  /**
   * Set parameters for queue list
   * @param {Context} context
   * @param {{ campaignId: number, sortBy: string }} params パラメーター
   */
  setQueueListParams(context: Context, params: { campaignId: number, sortBy: string }) {
    context.commit(SET_QUEUES_LIST_PARAMS, Object.assign({}, context.state.queues.params, params))
  },

  /**
   * Set parameters for queue message
   * @param {Context} context
   * @param {TGetQueueMessageParams} params パラメーター
   */
  setQueueMessageParams(context: Context, params: TGetQueueMessageParams) {
    context.commit(SET_QUEUES_MESSAGE_PARAMS, Object.assign({}, context.state.queueMessages.params, params))
  },

  /**
   * Set queue message id
   * @param {Context} context
   * @param {number} queueId
   */
  setQueueMessageId(context: Context, queueId: number) {
    context.commit(SET_QUEUES_MESSAGE_ID, queueId)
  }
}

// mutations
const mutations = {
  /**
   * ストアをリセット
   * @param {StateStore} state
   */
  [RESET](state: StateStore) {
    const initStateData: any = initState

    Object.keys(initStateData).forEach(key => {
      state[key] = initStateData[key]
    })

    state.queues = {
      list: [],
      params: {
        campaignId: 0,
        sortBy: '-actionDatetime'
      }
    }

    state.queueMessages = {
      queueId: 0,
      list: [],
      total: 0,
      params: {
        sortBy: '-actionDatetime',
        limitCount: 25,
        pagingNo: 1,
        status: ''
      }
    }
  },

  /**
   * Set loading
   * @param {StateStore} state
   * @param {Boolean} loading
   */
  [SET_LOADING](state: StateStore, loading: boolean) {
    state.loading = loading
  },

  /**
   * Set params to get queues
   * @param {StateStore} state
   * @param {{ campaignId: number, sortBy: string }} params パラメーター
   */
  [SET_QUEUES_LIST_PARAMS](state: StateStore, params: { campaignId: number, sortBy: string }) {
    state.queues.params = params
  },

  /**
   * Set params to get queue message
   * @param {StateStore} state
   * @param {TGetQueueMessageParams} params パラメーター
   */
  [SET_QUEUES_MESSAGE_PARAMS](state: StateStore, params: TGetQueueMessageParams) {
    state.queueMessages.params = params
  },

  /**
   * Set queue id
   * @param {StateStore} state
   * @param {number} queueId
   */
  [SET_QUEUES_MESSAGE_ID](state: StateStore, queueId: number) {
    state.queueMessages.queueId = queueId
  },

  /**
   * Set queues
   * @param {StateStore} state
   * @param {{data: TGetInstantwinQueue[]}} queues
   */
  [SET_QUEUES_LIST](state: StateStore, queues: { data: TGetInstantwinQueue[] }) {
    state.queues.list = queues.data
  },

  /**
   * Set queue message
   * @param {StateStore} state
   * @param {TGetGetMessagesQueueByIdResult} queues
   */
  [SET_QUEUES_MESSAGE](state: StateStore, queues: TGetGetMessagesQueueByIdResult) {
    state.queueMessages.list = queues.data.messages
    state.queueMessages.total = queues.data.dataCount
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
