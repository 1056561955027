/* @flow */
import { TrackingService } from '@/client/services'

export default {
  props: {
    total: {
      type: Number,
      default: 0
    },
    limitCount: {
      type: Number,
      default: 25
    },
    pagingNo: {
      type: Number,
      default: 1
    },
    type: {
      type: String,
      default: ''
    },
    onChangeLimitCount: Function,
    onChangePagingNo: Function
  },
  data() {
    return {
      currentPage: this.pagingNo,
      pagingSize: this.limitCount,
      pagingSizes: [10, 25, 50]
    }
  },
  watch: {
    limitCount() {
      this.pagingSize = this.limitCount
    },
    pagingNo() {
      this.currentPage = this.pagingNo
    }
  },
  methods: {
    changeLimitCount(value: number) {
      switch (this.type) {
        case 'twitter':
          TrackingService.sendEvent(`select:応募グループ一覧（X）|表示件数`)
          break
        case 'instagram':
          TrackingService.sendEvent(`select:応募グループ一覧（IG）|表示件数`)
          break
        case 'テンプレート':
          TrackingService.sendEvent(`select:グループ設定|テンプレート|表示件数`)
          break
        case 'NGアカウント':
          TrackingService.sendEvent(`select:グループ設定|NGアカウント|表示件数`)
          break
        case '利用実績':
          TrackingService.sendEvent(`select:グループ設定|利用実績|表示件数`)
          break
        case '応募履歴（X）':
          TrackingService.sendEvent(`select:応募履歴（X）|表示件数`)
          break
        case '応募履歴（IG）':
          TrackingService.sendEvent(`select:応募履歴（IG）|表示件数`)
          break
        case 'シルアルコード管理':
          TrackingService.sendEvent(`select:当選メッセージの管理|シルアルコード管理|表示件数`)
          break
        case '応募一覧（X）':
          TrackingService.sendEvent(`select:応募一覧（X）|表示件数`)
          break
        case '応募一覧（IG）':
          TrackingService.sendEvent(`select:応募一覧（IG）|表示件数`)
          break
        case '当落ページ管理（X）':
          TrackingService.sendEvent(`select:応募一覧（IG）|表示件数`)
          break
        default:
          break
      }

      if (typeof this.onChangeLimitCount === 'function') {
        this.onChangeLimitCount(value)
      }

      this.$emit('paging', { pagingNo: 1, limitCount: value })
    },

    changePagingNo(page: number) {
      let actionPaging

      if (page === this.pagingNo + 1) {
        actionPaging = '|次へ'
      } else if (page === this.pagingNo - 1) {
        actionPaging = '|前へ'
      } else actionPaging = ''

      switch (this.type) {
        case 'twitter':
          TrackingService.sendEvent(`select:応募グループ一覧（X）|ページネーション${actionPaging}`)
          break
        case 'instagram':
          TrackingService.sendEvent(`select:応募グループ一覧（IG）|ページネーション${actionPaging}`)
          break
        case 'テンプレート':
          TrackingService.sendEvent(`select:グループ設定|テンプレート|ページネーション${actionPaging}`)
          break
        case 'NGアカウント':
          TrackingService.sendEvent(`click:グループ設定|NGアカウント|ページネーション${actionPaging}`)
          break
        case '利用実績':
          TrackingService.sendEvent(`click:グループ設定|利用実績|ページネーション${actionPaging}`)
          break
        case '応募履歴（X）':
          TrackingService.sendEvent(`click:応募履歴（X）|ページネーション${actionPaging}`)
          break
        case '応募履歴（IG）':
          TrackingService.sendEvent(`click:応募履歴（IG）|ページネーション${actionPaging}`)
          break
        case 'シルアルコード管理':
          TrackingService.sendEvent(`click:当選メッセージの管理|シルアルコード管理|ページネーション${actionPaging}`)
          break
        case '応募一覧（X）':
          TrackingService.sendEvent(`click:応募一覧（X）|ページネーション${actionPaging}`)
          break
        case '応募一覧（IG）':
          TrackingService.sendEvent(`click:応募一覧（IG）|ページネーション${actionPaging}`)
          break
        case '当落ページ管理（X）':
          TrackingService.sendEvent(`click:当落ページ管理（X）|ページネーション${actionPaging}`)
          break
        default:
          break
      }

      if (typeof this.onChangePagingNo === 'function') {
        this.onChangePagingNo(page)
      }

      this.$emit('paging', { pagingNo: page })
    }
  }
}
