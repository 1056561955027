import moment from 'moment-timezone'
import { mapActions, mapState } from 'vuex'
import { z } from 'zod'

import Icon from '@/client/components/_atoms/Icon.vue'
import Account from '@/client/components/_molecules/Account.vue'
import SelectForm from '@/client/components/basic/select_form/'
import { TrackingService } from '@/client/services'

const dateSchema = z
  .object({
    startDate: z
      .string()
      .optional()
      .nullable(),
    startTime: z
      .string()
      .optional()
      .nullable(),
    endDate: z
      .string()
      .optional()
      .nullable(),
    endTime: z
      .string()
      .optional()
      .nullable()
  })
  .refine(data => !((data.startDate && !data.startTime) || (!data.startDate && data.startTime)), {
    path: ['startDatetime'],
    message: '日付と時間の両方を入力してください'
  })
  .refine(data => !(data.endDate && data.endTime && !data.startDate && !data.startTime), {
    path: ['startDatetime'],
    message: '開始日時を入力してください'
  })
  .refine(data => !((data.endDate && !data.endTime) || (!data.endDate && data.endTime)), {
    path: ['endDatetime'],
    message: '日付と時間の両方を入力してください'
  })
  .refine(data => !(data.startDate && data.startTime && !data.endDate && !data.endTime), {
    path: ['endDatetime'],
    message: '終了日時を入力してください'
  })
  .refine(
    data => {
      if (data.startDate && data.startTime && data.endDate && data.endTime) {
        const start = moment(`${data.startDate} ${data.startTime}`, 'YYYY-MM-DD HH:mm')
        const end = moment(`${data.endDate} ${data.endTime}`, 'YYYY-MM-DD HH:mm')
        return end.isAfter(start)
      }
      return true
    },
    {
      path: ['endDatetime'],
      message: '終了日時は開始日時より後に設定してください'
    }
  )

export default {
  components: {
    Icon,
    Account,
    SelectForm
  },
  data() {
    return {
      showCampaignFilter: false,
      account: {},
      accounts: [],
      title: '',
      status: undefined,
      targetSns: undefined,
      startDate: undefined,
      endDate: undefined,
      startTime: undefined,
      endTime: undefined,
      startDatetimeValidationMessage: '',
      endDatetimeValidationMessage: ''
    }
  },
  computed: {
    ...mapState('campaign_account', {
      apiAccounts: state => state.api_accounts
    }),
    targetSnsOptions() {
      return [
        {
          label: '指定なし',
          value: undefined
        },
        {
          label: 'X',
          value: 'twitter'
        },
        {
          label: 'Instagram',
          value: 'instagram'
        },
        {
          label: 'TikTok',
          value: 'tiktok'
        }
      ]
    },
    statusOptions() {
      return [
        {
          label: '指定なし',
          value: undefined
        },
        {
          label: '開催前',
          value: 'beforeEvent'
        },
        {
          label: '開催中',
          value: 'duringCollection'
        },
        {
          label: '開催終了',
          value: 'collectionCompleted'
        }
      ]
    },
    filteredAccounts() {
      return this.accounts.filter(account => {
        if (this.targetSns && !this.account.target_sns) {
          return account.target_sns === this.targetSns || account.name === '指定なし'
        }
        return true
      })
    },
    startDatetime() {
      return this.startDate && this.startTime
        ? moment(`${this.startDate} ${this.startTime}`).format('YYYY-MM-DD HH:mm:ss')
        : undefined
    },
    endDatetime() {
      return this.endDate && this.endTime
        ? moment(`${this.endDate} ${this.endTime}`).format('YYYY-MM-DD HH:mm:ss')
        : undefined
    },
    startDatePickerOptions() {
      return {
        disabledDate: date => {
          if (!this.endDate) return false

          const targetDate = moment(date.getTime())
          const endDate = moment(this.endDate)
          const sixMonthAgo = moment(
            moment(this.endDate)
              .subtract(6, 'months')
              .startOf('day')
          )

          return targetDate.isAfter(endDate) || targetDate.isBefore(sixMonthAgo)
        }
      }
    },
    endDatePickerOptions() {
      return {
        disabledDate: date => {
          if (!this.startDate) return false

          const targetDate = moment(date.getTime())
          const startDate = moment(this.startDate)
          const sixMonthLater = moment(this.startDate)
            .add(6, 'months')
            .endOf('day')

          return targetDate.isBefore(startDate) || targetDate.isAfter(sixMonthLater)
        }
      }
    },
    timePickerOptions() {
      return {
        start: '00:00',
        step: '01:00',
        end: '23:00'
      }
    },
    validationDatetime() {
      const result = dateSchema.safeParse({
        startDate: this.startDate,
        startTime: this.startTime,
        endDate: this.endDate,
        endTime: this.endTime
      })

      this.startDatetimeValidationMessage = ''
      this.endDatetimeValidationMessage = ''

      if (result.success) {
        return
      }

      if (result?.error?.issues[0]?.path[0] === 'startDatetime') {
        this.startDatetimeValidationMessage = result.error.issues[0].message
      }
      if (result?.error?.issues[0]?.path[0] === 'endDatetime') {
        this.endDatetimeValidationMessage = result.error.issues[0].message
      }
    },
    isEnabledSearchButton() {
      return this.startDatetimeValidationMessage === '' && this.endDatetimeValidationMessage === ''
    }
  },
  watch: {
    validationDatetime: {
      handler: 'validationDatetime'
    },
    account: {
      handler: 'selectedTargetSns'
    }
  },
  methods: {
    ...mapActions('campaign_account', {
      fetchCampaignAccounts: 'fetchCampaignAccounts'
    }),
    ...mapActions('campaign', ['fetchCampaigns', 'setParams', 'setIsFiltered']),
    updateTitleValue(value) {
      this.title = value
    },
    updateTargetSnsValue(value) {
      this.targetSns = value
    },
    updateStatusValue(value) {
      this.status = value
    },
    toggleShowCampaignFilter() {
      this.showCampaignFilter = !this.showCampaignFilter
      this.blurForCampaignFilterButton()
    },
    async selectedAccountIdForMyTargetSns(account) {
      switch (this.account.target_sns) {
        case 'twitter':
          return account.account_id
        case 'instagram':
          return account.ig_id
        case 'tiktok':
          return account.tt_account_id
        default:
          return undefined
      }
    },
    selectedTargetSns() {
      if (!this.account) {
        return
      }

      switch (this.account.target_sns) {
        case 'twitter':
          this.targetSns = 'twitter'
          break
        case 'instagram':
          this.targetSns = 'instagram'
          break
        case 'tiktok':
          this.targetSns = 'tiktok'
          break
      }
    },
    async searchCampaign() {
      TrackingService.sendEvent('click:キャンペーン一覧|キャンペーン検索|検索を実行')

      await this.setParams({
        targetSns: this.targetSns,
        campaignAccountId: await this.selectedAccountIdForMyTargetSns(this.account),
        title: this.title,
        status: this.status,
        startDatetime: this.startDatetime,
        endDatetime: this.endDatetime
      })
      await this.setIsFiltered(true)
      await this.fetchCampaigns()
    },
    blurForCampaignFilterButton() {
      this.$refs.campaignFilterButton.$el.blur()
    }
  },
  async created() {
    await this.fetchCampaignAccounts()

    const initialAccount = {
      name: '指定なし',
      picture_url: undefined,
      target_sns: undefined
    }
    this.accounts = [initialAccount, ...this.apiAccounts]
  }
}
