/* @flow */
class Util {
  /**
   * テーブルソート用ヘッダー描画
   * @param {*} creatElement
   * @param {string} label ラベル
   * @param {string} className クラス名
   */
  static renderHeader(creatElement: any, label: string, className: string = '', sortable: boolean = true) {
    if (sortable) {
      return creatElement(
        'span',
        {
          class: 'sortable ' + className
        },
        [
          label,
          creatElement(
            'span',
            {
              class: 'caret-wrapper'
            },
            [
              creatElement('i', {
                class: 'sort-caret ascending'
              }),
              creatElement('i', {
                class: 'sort-caret descending'
              })
            ]
          )
        ]
      )
    } else {
      return creatElement(
        'span',
        {
          class: className
        },
        [label, creatElement('span')]
      )
    }
  }

  /**
   * ソートクラスを取得
   * @param {string} sortBy
   * @param {string} prop
   */
  static getSortClass(sortBy: string, prop: string) {
    if (!sortBy || !prop) {
      return ''
    }

    if (prop.indexOf('-') === 0) {
      prop = prop.substring(1)
    }
    if (sortBy === prop) {
      return 'ascending'
    } else if (sortBy === '-' + prop) {
      return 'descending'
    } else {
      return ''
    }
  }

  /**
   * Set scroll to top
   */
  static scrollToTop() {
    setTimeout(function() {
      window.scrollTo({ top: 0, left: 0 })
    }, 300)
  }

  /**
   * クリップボードにコピーする
   * @param {string} text
   */
  static copyToClipboard(text: string) {
    const body: any = document.body
    const el = document.createElement('textarea')
    el.value = text
    body.appendChild(el)
    el.select()
    document.execCommand('copy')
    body.removeChild(el)
  }
}

export default Util
