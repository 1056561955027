/* @flow */
import api from '@/client/core/api'
import { type TGetSerialCodeParam, type TGetSerialCodeResponse } from '@/typings/api/twitter_serial_codes.type'

// mutations type
const RESET = 'RESET'
const SET_PRIZE_ID = 'SET_PRIZE_ID'
const SET_CAMPAIGN_ID = 'SET_CAMPAIGN_ID'
const SET_SERIAL_CODE_LIST = 'SET_SERIAL_CODE_LIST'
const SET_SERIAL_CODE_LIST_PARAMS = 'SET_SERIAL_CODE_LIST_PARAMS'
const SET_SERIAL_CODE_IS_CREATED = 'SET_SERIAL_CODE_IS_CREATED'

type StateStore = {
  campaignId: number,
  prizeId: number,
  serialCode: {
    loaded: boolean,
    list: any[],
    total: number,
    countAssign: number,
    countUnassign: number,
    params: TGetSerialCodeParam,
    isCreated: boolean
  }
}

// ストアーcontext
type Context = {
  commit: Function,
  getters: any,
  state: StateStore,
  dispatch: Function
}

// init state
const initState: StateStore = {
  campaignId: 0,
  prizeId: 0,
  serialCode: {
    loaded: false,
    list: [],
    total: 0,
    countAssign: 0,
    countUnassign: 0,
    params: {
      campaignId: 0,
      prizeId: 0,
      filter: '',
      sortBy: '-tw_campaign_message_id',
      pagingNo: 1,
      limitCount: 25,
      type: ''
    },
    isCreated: false
  }
}

const state = Object.assign({}, initState)

// getters
const getters = {}

// actions
const actions = {
  /**
   * ストアをリセット
   * @param {Context} context
   */
  async reset(context: Context) {
    context.commit(RESET)
  },

  /**
   * キャンペーンIDをセット
   * @param {Context} context
   * @param {number} campaignId キャンペーンID
   */
  setCampaignId(context: Context, campaignId: number) {
    context.commit(SET_CAMPAIGN_ID, campaignId)
  },

  /**
   * プライズIDをセット
   * @param {Context} context
   * @param {number} prizeId campaign prize id
   */
  setPrizeId(context: Context, prizeId: number) {
    context.commit(SET_PRIZE_ID, prizeId)
  },

  /**
   * シリアルコード一覧を取得
   * @param {Context} context
   */
  async fetchSerialCodeList(context: Context) {
    if (!context.state.prizeId || !context.state.campaignId) {
      return
    }

    const serial = await api.get('/twitter_serial_codes', {
      params: context.state.serialCode.params
    })

    if (!serial.data) {
      return
    }

    context.commit(SET_SERIAL_CODE_LIST, serial)
  },

  /**
   * シリアルコードのパラメーターをセット
   * @param {Context} context
   * @param {TGetSerialCodeParam} params パラメーター
   */
  setSerialCodeListParams(context: Context, params: any) {
    context.commit(SET_SERIAL_CODE_LIST_PARAMS, Object.assign({}, context.state.serialCode.params, params))
  },

  /**
   * シリアルコードの追加開始フラグをセット
   * @param {Context} context
   * @param {boolean} isCreated
   */
  setSerialCodeIsCreated(context: Context, isCreated: boolean) {
    context.commit(SET_SERIAL_CODE_IS_CREATED, isCreated)
  }
}

// mutations
const mutations = {
  /**
   * ストアをリセット
   * @param {StateStore} state
   */
  [RESET](state: StateStore) {
    const initStateData: any = initState
    Object.keys(initStateData).forEach(key => {
      state[key] = initStateData[key]
    })
    state.serialCode = {
      loaded: false,
      list: [],
      total: 0,
      countAssign: 0,
      countUnassign: 0,
      params: {
        campaignId: 0,
        prizeId: 0,
        filter: '',
        sortBy: '-tw_campaign_message_id',
        pagingNo: 1,
        limitCount: 25,
        type: ''
      },
      isCreated: false
    }
  },

  /**
   * キャンペーンIDをセット
   * @param {StateStore} state
   * @param {number} campaignId キャンペーンID
   */
  [SET_CAMPAIGN_ID](state: StateStore, campaignId: number) {
    state.campaignId = campaignId
    state.serialCode.params.campaignId = campaignId
  },

  /**
   * プライズIDをセット
   * @param {StateStore} state
   * @param {number} prizeId プライズID
   */
  [SET_PRIZE_ID](state: StateStore, prizeId: number) {
    state.prizeId = prizeId
    state.serialCode.params.prizeId = prizeId
  },

  /**
   * シリアルコードのパラメーターをセット
   * @param {SerialCodeListStore} state
   * @param {TGetSerialCodeParam} params パラメーター
   */
  [SET_SERIAL_CODE_LIST_PARAMS](state: StateStore, params: any) {
    state.serialCode.params = params
  },

  /**
   * シリアルコードのデータをセット
   * @param {SerialCodeListStore} state
   * @param {TGetSerialCodeResponse} params
   */
  [SET_SERIAL_CODE_LIST](state: StateStore, params: TGetSerialCodeResponse) {
    state.serialCode.list = params.data.serialCode
    state.serialCode.total = params.data.dataCount
    state.serialCode.countAssign = params.data.count.assign
    state.serialCode.countUnassign = params.data.count.unassign
  },

  /**
   * シリアルコードの追加開始フラグをセット
   * @param {SerialCodeListStore} state
   * @param {boolean} isCreated
   */
  [SET_SERIAL_CODE_IS_CREATED](state: any, isCreated: boolean) {
    state.serialCode.isCreated = isCreated
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
