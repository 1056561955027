import Icon from '@/client/components/basic/icon'
import PostDetail from '@/client/components/basic/post_detail'
import PostName from '@/client/components/basic/post_name'

export default {
  components: {
    Icon,
    PostName,
    PostDetail
  },
  props: {
    snsType: 'instagram' | 'twitter' | 'tiktok',
    post: Object,
    campaignType: String
  },
  data() {
    return {
      isShowDetail: false,
      isShowPopover: false
    }
  },
  computed: {
    imageStyle() {
      return { backgroundImage: `url(${this.post.mediaUrl || require('@/client/assets/images/no-photo.png')})` }
    }
  },
  methods: {
    /**
     * セットショーの詳細
     */
    toggleDetail(state: boolean) {
      if (this.isShowPopover) {
        this.isShowDetail = this.isShowPopover
        return
      }

      this.isShowDetail = state
    },

    /**
     * ポップオーバーが近いとき
     */
    hidePopover() {
      this.isShowDetail = false
    },

    /**
     * ポストテキストをフォーマット
     */
    tweetText(text: string): string {
      return this.$options.filters.tweetText(text)
    },

    /**
     * ウィジェットフラグを変更
     */
    changeIsWidget() {
      this.$emit('change_is_widget', {
        postId: this.post.id,
        isWidget: this.post.isWidget
      })
    },

    /**
     * 候補者フラグを変更
     */
    changeIsCandidate() {
      this.$emit('change_is_candidate', {
        applicantId: this.post.applicantId,
        isCandidate: this.post.isCandidate
      })
    },

    /**
     * 当選者フラグを変更
     */
    changeIsWinner() {
      this.$emit('change_is_winner', {
        prizeId: this.post.prizeId,
        applicantId: this.post.applicantId,
        isWinner: this.post.isWinner
      })
    }
  }
}
