export type ErrorCode = {
  status: number
  type: string
  message: string
  stack?: any
}

export const BAD_REQUEST: ErrorCode = {
  status: 400,
  type: 'BAD_REQUEST',
  message: 'Bad Request'
}

export const INTERNAL_SERVER_ERROR: ErrorCode = {
  status: 500,
  type: 'INTERNAL_SERVER_ERROR',
  message: 'Internal Server Error'
}

export const PARAMETER_INVALID: ErrorCode = {
  status: 400,
  type: 'PARAMETER_INVALID',
  message: 'The parameter is invalid.'
}

export const REQUIRED_PARAM_NOT_EXIST: ErrorCode = {
  status: 400,
  type: 'REQUIRED_PARAM_NOT_EXIST',
  message: 'Required parameter does not exist.'
}

export const NO_DATA_EXISTS: ErrorCode = {
  status: 400,
  type: 'NO_DATA_EXISTS',
  message: 'No data exists.'
}

export const PERMISSION_DENIED: ErrorCode = {
  status: 400,
  type: 'PERMISSION_DENIED',
  message: 'Permission denied.'
}

/**
 * データが重複している場合のエラー
 */
export const DUPLICATE: ErrorCode = {
  status: 400,
  type: 'DUPLICATE',
  message: 'Duplicate.'
}

export const MAX_CAMPAIGN_COUNT_OVER: ErrorCode = {
  status: 400,
  type: 'MAX_CAMPAIGN_COUNT_OVER',
  message: 'Max campaign count is over.'
}

export const MAX_WINNER_COUNT_OVER: ErrorCode = {
  status: 400,
  type: 'MAX_WINNER_COUNT_OVER',
  message: 'Max winner count is over.'
}

export const REMAIN_WINNER_OVER: ErrorCode = {
  status: 400,
  type: 'REMAIN_WINNER_OVER',
  message: 'Remain winner is over.'
}

export const MAX_COUNT_OVER: ErrorCode = {
  status: 400,
  type: 'MAX_COUNT_OVER',
  message: 'Max count is over.'
}

export const CURRENT_COUNT_OVER: ErrorCode = {
  status: 400,
  type: 'CURRENT_COUNT_OVER',
  message: 'Current count is over.'
}

export const CURRENT_CAMPAIGN_COUNT_OVER: ErrorCode = {
  status: 400,
  type: 'CURRENT_CAMPAIGN_COUNT_OVER',
  message: 'Current campaign count is over.'
}

export const CURRENT_MONTHLY_APPLICANT_COUNT_OVER: ErrorCode = {
  status: 400,
  type: 'CURRENT_MONTHLY_APPLICANT_COUNT_OVER',
  message: 'Current monthly applicant count is over.'
}

export const CURRENT_MONTHLY_DM_COUNT_OVER: ErrorCode = {
  status: 400,
  type: 'CURRENT_MONTHLY_DM_COUNT_OVER',
  message: 'Current monthly DM count is over.'
}

export const MESSAGE_QUEUE_COUNT_OVER: ErrorCode = {
  status: 400,
  type: 'MESSAGE_QUEUE_COUNT_OVER',
  message: 'Message queue count is over.'
}

export const CAMPAIGN_SCHEDULED: ErrorCode = {
  status: 400,
  type: 'CAMPAIGN_SCHEDULED',
  message: 'Campaign is scheduled.'
}

export const CAMPAIGN_START_DATETIME_INVALID: ErrorCode = {
  status: 400,
  type: 'CAMPAIGN_START_DATETIME_INVALID',
  message: 'Campaign start datetime is invalid.'
}

export const MAX_LIMIT_OVER: ErrorCode = {
  status: 400,
  type: 'MAX_LIMIT_OVER',
  message: 'Max limit is over.'
}

/**
 * QRコードが生成できない場合のエラー
 */
export const QRCODE_GENERATE_FAILED: ErrorCode = {
  status: 400,
  type: 'QRCODE_GENERATE_FAILED',
  message: 'Failed to generate qrcode.'
}

export const FACEBOOK_PAGE_NOT_FOUND: ErrorCode = {
  status: 400,
  type: 'FACEBOOK_PAGE_NOT_FOUND',
  message: 'Facebook page not found.'
}
