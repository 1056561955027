import moment from 'moment'
import { mapActions, mapState } from 'vuex'

import CsvDownload from '@/client/components/basic/csv_download/csv_download'
import CampaignAddWidgetDialog from '@/client/components/campaign/campaign_add_widget_dialog'
import InstagramPostFilter from '@/client/components/InstagramPost/InstagramPostFilter'
import InstagramPostGrid from '@/client/components/InstagramPost/InstagramPostGrid'
import InstagramPostTable from '@/client/components/InstagramPost/InstagramPostTable'
import api from '@/client/core/api'
import util from '@/client/core/util'
import { TrackingService } from '@/client/services'

export default {
  components: {
    CsvDownload,
    InstagramPostFilter,
    InstagramPostTable,
    InstagramPostGrid,
    CampaignAddWidgetDialog
  },
  data: () => {
    return {
      loading: false,
      loadingText: '',
      viewType: 'list',
      sortGrid: '-publishDatetime',
      sortGridOptions: [
        { value: '-publishDatetime', label: '日時(新しい順)' },
        { value: 'publishDatetime', label: '日時(古い順)' }
      ]
    }
  },
  computed: {
    ...mapState('instagramPosts', {
      error: state => state.error,
      firstLoaded: state => state.firstLoaded,
      params: state => state.params,
      entryCount: state => state.entryCount,
      posts: state => state.posts,
      prizes: state => state.prizes,
      campaign: state => {
        if (state.campaign) {
          return state.campaign
        }

        return {}
      },
      options: state => {
        return {
          total: state.total,
          pagingNo: state.params.pagingNo,
          limitCount: state.params.limitCount,
          sortBy: state.params.sortBy
        }
      },

      isListView() {
        return this.viewType === 'list'
      },

      isGridView() {
        return this.viewType === 'grid'
      },

      isLoading: state => state.loading
    }),
    isLoaded() {
      return !this.error && this.firstLoaded
    },

    lastProcessedDatetime() {
      if (!this.campaign.lastProcessedDatetime) {
        return '-'
      }

      return moment(this.campaign.lastProcessedDatetime).format('YYYY年MM月DD日 HH:mm')
    },

    widgetModified() {
      if (this.campaign.isWidgetCreated === 0) {
        return 'ウィジェット未作成'
      }

      if (!this.campaign.widgetModified) {
        return '-'
      }

      return moment(this.campaign.widgetModified).format('YYYY年MM月DD日 HH:mm')
    }
  },
  async created() {
    const params = {
      campaignId: this.$route.params.campaignId
    }

    this.setParams(params)

    await this.fetchCampaign()
    await this.fetchPrizes()

    this.setBreadcrumb([
      {
        name: this.$gettext('キャンペーン一覧'),
        link: '/campaigns'
      },
      {
        name: this.campaign.title,
        link: `/campaigns/${this.campaign.id}/instagram_posts`,
        iconName: this.campaign.account ? this.campaign.account.name : '削除されたアカウント',
        iconUrl: this.campaign.account ? this.campaign.account.pictureUrl : null,
        iconType: this.campaign.snsType,
        isIcon: true
      },
      {
        name: this.$gettext('応募履歴'),
        link: null
      }
    ])
  },
  watch: {
    params() {
      this.fetchPosts()
    }
  },
  methods: {
    ...mapActions('instagramPosts', [
      'fetchPosts',
      'fetchCampaign',
      'fetchPrizes',
      'setParams',
      'reset',
      'updateIsWidget',
      'updateIsCandidate',
      'updateIsWinner'
    ]),
    ...mapActions('navbar', ['setBreadcrumb']),

    /**
     * ウィジェットフラグを変更
     */
    async changeIsWidget(payload: { postId: string, isWidget: number }) {
      const result = await this.updateIsWidget(payload)

      if (!result || !result.data) {
        this.$notify({
          title: 'ウィジェットの保存に失敗しました。',
          customClass: 'danger',
          duration: 5000
        })
      }
    },

    /**
     * 候補者フラグを変更
     */
    async changeIsCandidate(payload: { applicantId: number, isCandidate: number }) {
      const result = await this.updateIsCandidate(payload)

      if (!result || !result.data) {
        this.$notify({
          title: '候補者の保存に失敗しました',
          customClass: 'danger',
          duration: 5000
        })
      }
    },

    /**
     * 当選者フラグを変更
     */
    async changeIsWinner(payload: { prizeId: number, applicantId: number, isWinner: number }) {
      const result = await this.updateIsWinner(payload)

      if (result.error && result.error.type === 'MAX_WINNER_COUNT_OVER') {
        this.$notify({
          title: '残り当選数に空きがありません。',
          customClass: 'danger',
          duration: 5000
        })
        return
      }

      if (!result || !result.data) {
        this.$notify({
          title: '当選者の保存に失敗しました',
          customClass: 'danger',
          duration: 5000
        })
      }
    },

    /**
     * ビュータイプの変更
     */
    changeViewType(value: string) {
      TrackingService.sendEvent(`click:応募履歴（IG）|${value === 'list' ? '一覧' : 'グリッド'}`)

      this.viewType = value
    },

    /**
     * 応募履歴一覧をCSVダウンロードできる
     * @returns {Promise<void>}
     */
    async downloadCsv() {
      TrackingService.sendEvent('click:応募履歴（IG）|CSVダウンロード')

      const posts = await this.getApplicantsForCsv()
      if (!posts.length) {
        return { error: 'ERROR_LOADING' }
      }

      const filename = `campaign_posts_${this.campaign.title}_${moment().format('YYYY_MM_DD')}`
      const csvData = []

      csvData.push([
        this.$gettext('応募日時'),
        this.$gettext('応募グループ'),
        this.$gettext('アカウント名'),
        this.$gettext('アカウントURL'),
        this.$gettext('投稿内容'),
        this.$gettext('投稿画像URL'),
        this.$gettext('投稿URL'),
        this.$gettext('フォロー中'),
        this.$gettext('NGアカウント'),
        this.$gettext('ウィジェット表示'),
        this.$gettext('候補者'),
        this.$gettext('当選者')
      ])

      // CSVデータを格納
      posts.forEach(post => {
        csvData.push([
          this.$options.filters.datetime(post.publishDatetime),
          post.prizeName,
          post.screenName,
          'https://www.instagram.com/' + post.screenName,
          post.message ? post.message.replace(/(\r\n|\n|\r)/gm, '') : '',
          post.mediaUrl,
          post.permalink,
          post.isFollowing,
          post.isNg,
          post.isWidget,
          post.isCandidate,
          post.isWinner
        ])
      })

      return {
        filename,
        data: csvData
      }
    },

    /**
     * CSV用応募履歴一覧を取得
     */
    async getApplicantsForCsv() {
      this.loading = true
      const { total } = this.options

      this.getTextLoading(0, total)

      const params = Object.assign({}, this.params, {
        pagingNo: 1,
        limitCount: 1000
      })

      let totalResults = []
      // 1000件ごとに取得する
      for (let count = 0; count < total; count += 1000) {
        const result = await api.get('/instagram_posts', { params })

        const surplus = (total - count) / 1000

        if (surplus < 1) {
          this.getTextLoading(total, total)
        } else {
          this.getTextLoading(count + 1000, total)
        }

        if (!result.data) {
          this.loading = false
          return []
        }

        totalResults = totalResults.concat(result.data.posts)
        params.pagingNo = params.pagingNo + 1
      }

      this.loading = false
      return totalResults
    },

    /**
     * ページを変更イベント
     * @param {number} page ページ
     */
    onPaging(payload: { pagingNo: number, limitCount: number }) {
      this.setParams(payload)
      util.scrollToTop()
    },

    /**
     * ソートを変更イベント
     * @param {string} sortBy ソート
     */
    onSorting(value: string) {
      if (this.params.sortBy === value) {
        return
      }

      this.sortGrid = value

      this.setParams({ sortBy: value })
    },

    /**
     * CSVダウンロードの時、進捗を表示する
     * @params {number} acquiredNumber
     * @params {number} totalNumber
     */
    getTextLoading(acquiredNumber: number, totalNumber: number) {
      this.loadingText = this.$gettextInterpolate(
        this.$gettext('CSVファイルの作成中です。しばらくお待ちください。\n %{acquiredNumber}件 / %{totalNumber}件'),
        {
          acquiredNumber,
          totalNumber
        }
      )
    },

    /**
     * 戻る
     */
    back() {
      TrackingService.sendEvent('click:応募履歴（IG）|戻る')

      this.$router.push('/instagram_campaigns/' + this.$route.params.campaignId + '/prizes')
    },

    openCampaignAddWiddetDialog() {
      TrackingService.sendEvent('click:応募履歴（IG）|ウィジェット管理')

      this.$refs.campaignAddWidgetDialog.open()
    }
  },
  beforeRouteLeave(to: any, from: any, next: any) {
    // キャンペーンストアをリセット
    this.reset()
    next()
  }
}
