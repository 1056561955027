import { render, staticRenderFns } from "./instagram_prize_table.vue?vue&type=template&id=2128b170&scoped=true"
import script from "./instagram_prize_table.component.js?vue&type=script&lang=js&external"
export * from "./instagram_prize_table.component.js?vue&type=script&lang=js&external"
import style0 from "./instagram_prize_table.scss?vue&type=style&index=0&id=2128b170&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2128b170",
  null
  
)

export default component.exports