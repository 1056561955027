import { render, staticRenderFns } from "./widget_preview_message.vue?vue&type=template&id=5a0914b2&scoped=true"
import script from "./widget_preview_message.component?vue&type=script&lang=js&external"
export * from "./widget_preview_message.component?vue&type=script&lang=js&external"
import style0 from "./widget_preview_message.vue?vue&type=style&index=0&id=5a0914b2&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5a0914b2",
  null
  
)

export default component.exports