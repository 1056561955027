


























import { defineComponent, PropType } from '@vue/composition-api'

import { PropPageSizeType } from './types'

export default defineComponent({
  name: 'AppPagination',
  props: {
    total: {
      type: Number,
      default: 0
    },
    pageSize: {
      type: Number as PropType<PropPageSizeType>,
      default: 25
    },
    currentPage: {
      type: Number,
      default: 1
    }
  },
  setup(props, context) {
    function onPageSize(pageSize: number) {
      context.emit('paging', {
        pageSize,
        currentPage: 1
      })
    }

    function onCurrentPage(currentPage: number) {
      context.emit('paging', {
        pageSize: props.pageSize,
        currentPage
      })
    }

    return {
      onPageSize,
      onCurrentPage
    }
  }
})
