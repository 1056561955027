






import { Component, Prop, Vue } from 'vue-property-decorator'

type TIconSize = 'small' | 'normal'
type TIconSpace = 'none' | 'left' | 'right' | 'both'
type TIconColor =
  | 'default'
  | 'white'
  | 'subtext'
  | 'disabled'
  | 'info'
  | 'primary'
  | 'link'
  | 'success'
  | 'danger'
  | 'warning'

@Component
export default class Icon extends Vue {
  @Prop({ type: String, default: '' })
  name!: string

  @Prop({ type: String, default: 'normal' })
  size!: TIconSize

  @Prop({ type: String, default: 'none' })
  space!: TIconSpace

  @Prop({ type: String, default: 'default' })
  color!: TIconColor
}
