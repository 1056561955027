<script>
import { Doughnut } from 'vue-chartjs'

export default {
  extends: Doughnut,
  props: {
    chartData: { type: Object, default: null },
    options: { type: Object, default: null }
  },
  mounted() {
    this.renderChart(this.chartData, this.options)
  },
  methods: {
    update() {
      // ライブラリが設定を上書きしないので強制で更新する
      this.$data._chart.options = {
        ...this.$data._chart.options,
        ...this.options
      }

      this.$data._chart.update()
    }
  }
}
</script>
