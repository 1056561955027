/* @flow */
import moment from 'moment'
import twitterText from 'twitter-text'
import { required } from 'vuelidate/lib/validators'
import { mapActions, mapState } from 'vuex'

import AccountSelect from '@/client/components/basic/account_select'
import TagInput from '@/client/components/basic/tag_input'
import { CAMPAIGN_STATUS } from '@/client/constant'
import api from '@/client/core/api'
import { TrackingService } from '@/client/services'

export default {
  components: {
    AccountSelect,
    TagInput
  },
  props: {
    mode: String,
    campaignId: Number,
    isCopy: Boolean,
    isChangeWithoutSubmit: Boolean
  },
  data() {
    return {
      isLoading: true,
      isSubmit: false,
      formValid: false,
      startDate: '',
      startTime: '',
      endDate: '',
      endTime: '',
      startDatetimeInvalid: false,
      endDatetimeInvalid: false,
      accounts: [],
      account: {
        id: null
      },
      campaign: {
        title: '',
        startDatetime: '',
        status: CAMPAIGN_STATUS.BEFORE_EVENT,
        endDatetime: '',
        entryKeyword: [],
        entryActionType: 'retweet',
        requireFollow: true,
        excludeHunterAccount: true,
        excludeMultiWinner: true,
        excludeOtherCampaignWinner: true,
        excludeAccountCreateDateWithinPeriod: true,
        excludeDefaultProfileImage: true,
        excludeNoDescription: true,
        minFollowers: 0,
        dailySendLimitCount: 10,
        oldDailySendLimitCount: 10,
        minTweets: 0,
        excludeDescriptionKeyword: [],
        isRequireMedia: false
      },
      rawCampaignData: {},
      options: [
        { label: this.$gettext('公式リポスト'), value: 'retweet' },
        { label: this.$gettext('引用'), value: 'quote_tweet' },
        { label: this.$gettext('公式リポスト & 引用'), value: 'retweet_and_quote_tweet' },
        { label: this.$gettext('実施アカウントへのメンション'), value: 'mention' }
      ],
      datePickerOption: {
        disabledDate(time: any) {
          return moment(time.getTime()).format('YYYY-MM-DD') < moment().format('YYYY-MM-DD')
        }
      },
      timePickerOption: {
        start: '00:00',
        step: '01:00',
        end: '23:00'
      }
    }
  },

  mounted() {
    this.init()
  },

  watch: {
    campaignId: function(newVal: number, oldVal: number) {
      this.init()
    },
    'campaign.entryActionType'() {
      if (this.campaign.entryActionType === 'retweet' || this.campaign.entryActionType === 'retweet_and_quote_tweet') {
        this.campaign.isRequireMedia = false
      }
    },
    isFormChanged(value: boolean) {
      this.$emit('update:isChangeWithoutSubmit', value)
    }
  },

  computed: {
    ...mapState('system', {
      currentGroupId: state => state.currentGroup.id,
      allowCreateInstantwin: state => Boolean(state.currentGroup.allowCreateInstantwin),
      isSystemAdmin: state => Boolean(state.user.isSystemAdmin)
    }),
    isBeforeStart() {
      return this.campaign.status === CAMPAIGN_STATUS.BEFORE_EVENT
    },
    isStarting() {
      return [CAMPAIGN_STATUS.BEFORE_COLLECTION, CAMPAIGN_STATUS.DURING_COLLECTION].includes(this.campaign.status)
    },
    isFinished() {
      return [CAMPAIGN_STATUS.DURING_FINAL_COLLECTION, CAMPAIGN_STATUS.COLLECTION_COMPLETED].includes(
        this.campaign.status
      )
    },
    isDisabledRequireMedia() {
      return this.campaign.entryActionType === 'retweet' || this.campaign.entryActionType === 'retweet_and_quote_tweet'
    },
    isFormChanged() {
      return JSON.stringify(this.campaignData) !== JSON.stringify(this.rawCampaignData)
    },
    isCampaignPeriodEmpty() {
      return !this.startDate || !this.startTime || !this.endDate || !this.endTime
    },
    campaignData() {
      const startDatetimeString = this.startTime ? this.startDate + ' ' + this.startTime : this.startDate
      const endDatetimeString = this.endTime ? this.endDate + ' ' + this.endTime : this.endDate

      const startDatetime = moment(startDatetimeString).format()
      const endDatetime = moment(endDatetimeString).format()

      const campaignData = this.setCampaignData(startDatetime, endDatetime)

      return campaignData
    },
    existAccounts() {
      return this.accounts.length > 0
    },
    canNotEditInstantwin() {
      return !this.isSystemAdmin && !this.allowCreateInstantwin
    }
  },

  methods: {
    ...mapActions('campaign', ['fetchCampaigns']),

    /**
     * 初期化
     */
    async init() {
      this.account = {
        id: null
      }
      this.isLoading = true
      this.accounts = []
      this.isSubmit = false
      this.$v.$reset()
      if (!this.campaignId) {
        this.formValid = false
        this.datetimeValid = false
        this.startDatetimeInvalid = false
        this.endDatetimeInvalid = false
        this.startDate = ''
        this.startTime = ''
        this.endDate = ''
        this.endTime = ''
        this.campaign = {
          title: '',
          status: CAMPAIGN_STATUS.BEFORE_EVENT,
          startDatetime: '',
          endDatetime: '',
          entryKeyword: [],
          entryActionType: 'retweet',
          requireFollow: true,
          excludeHunterAccount: true,
          excludeMultiWinner: true,
          excludeOtherCampaignWinner: true,
          excludeAccountCreateDateWithinPeriod: true,
          excludeDefaultProfileImage: true,
          excludeNoDescription: true,
          minFollowers: 0,
          oldMinFollowers: 0,
          dailySendLimitCount: 10,
          oldDailySendLimitCount: 10,
          minTweets: 0,
          oldMinTweets: 0,
          excludeDescriptionKeyword: [],
          isRequireMedia: false
        }
      } else {
        if (!this.isCopy) {
          this.formValid = true
          this.datetimeValid = true
        }
        this.startDatetimeInvalid = false
        this.endDatetimeInvalid = false
        // キャンペーンデータを取得
        await this.getCampaign()

        if (!this.isCopy) {
          this.startDate = moment(this.campaign.startDatetime).format('YYYY-MM-DD')
          this.startTime = moment(this.campaign.startDatetime).format('HH:mm')
          this.endDate = moment(this.campaign.endDatetime).format('YYYY-MM-DD')
          this.endTime = moment(this.campaign.endDatetime).format('HH:mm')
        }
      }
      // キャンアカウント一覧を取得
      await this.getCampaignAccounts()

      const startDatetime = moment(this.campaign.startDatetime).format()
      const endDatetime = moment(this.campaign.endDatetime).format()

      this.rawCampaignData = this.setCampaignData(startDatetime, endDatetime)
      this.isLoading = false
    },

    /**
     * Set campaign data
     * @param {string} startDatetime
     * @param {string} endDatetime
     * @returns {Object} campaign data
     */
    setCampaignData(startDatetime: string, endDatetime: string) {
      return {
        snsType: this.account.snsType,
        groupId: this.currentGroupId,
        campaign: {
          title: this.campaign.title,
          startDatetime,
          endDatetime,
          type: 'instantwin',
          setting: {
            entryKeyword: JSON.stringify(this.campaign.entryKeyword),
            entryActionType: this.campaign.entryActionType,
            requireFollow: this.campaign.requireFollow ? 1 : 0,
            excludeHunterAccount: this.campaign.excludeHunterAccount ? 1 : 0,
            excludeMultiWinner: this.campaign.excludeMultiWinner ? 1 : 0,
            excludeOtherCampaignWinner: this.campaign.excludeOtherCampaignWinner ? 1 : 0,
            excludeAccountCreateDateWithinPeriod: this.campaign.excludeAccountCreateDateWithinPeriod ? 1 : 0,
            excludeDefaultProfileImage: this.campaign.excludeDefaultProfileImage ? 1 : 0,
            excludeNoDescription: this.campaign.excludeNoDescription ? 1 : 0,
            minFollowers: this.campaign.minFollowers,
            oldMinFollowers: this.campaign.minFollowers,
            dailySendLimitCount: this.campaign.dailySendLimitCount,
            minTweets: this.campaign.minTweets,
            oldMinTweets: this.campaign.minTweets,
            excludeDescriptionKeyword:
              this.campaign.excludeDescriptionKeyword && this.campaign.excludeDescriptionKeyword.length > 0
                ? JSON.stringify(this.campaign.excludeDescriptionKeyword)
                : null,
            isRequireMedia: this.campaign.isRequireMedia ? 1 : 0
          }
        },
        account: {
          id: this.account.id
        }
      }
    },

    /**
     * キャンアカウント一覧を取得
     */
    async getCampaignAccounts() {
      const params = {
        groupId: this.currentGroupId
      }
      const result = await api.get('/twitter_campaign_accounts', {
        params
      })
      if (!result.data || !result.data.accounts.length) {
        return
      }
      this.accounts = result.data.accounts
      if (!this.accounts.length) {
        this.account = {
          id: null
        }
        return
      }
      if (this.mode === 'add' && !this.isCopy) {
        this.account = this.accounts[0]
      } else if (this.mode === 'edit') {
        if (!this.account) {
          this.account = {
            id: null
          }
        }
        const account = this.accounts.find(account => {
          return account.id === this.account.id
        })
        if (account) {
          this.account = account
        } else {
          this.account = {
            id: null
          }
        }
      }
    },

    /**
     * キャンペーンデータを取得
     */
    async getCampaign() {
      if (!this.campaignId) {
        return
      }
      const result = await api.get('/campaigns/' + this.campaignId)
      if (!result || !result.data) {
        this.$notify({
          title: 'キャンペーンの取得に失敗しました。',
          message: this.$gettext('恐れ入りますが、時間をおいて再度お試しください。'),
          customClass: 'danger',
          duration: 5000
        })
        this.$emit('actionSuccess')
        return
      }

      const campaign = result.data

      this.campaign = {
        title: campaign.title,
        startDatetime: !this.isCopy ? campaign.startDatetime : '',
        endDatetime: !this.isCopy ? campaign.endDatetime : '',
        entryActionType: campaign.setting.entryActionType,
        entryKeyword: JSON.parse(campaign.setting.entryKeyword),
        excludeDescriptionKeyword: campaign.setting.excludeDescriptionKeyword
          ? JSON.parse(campaign.setting.excludeDescriptionKeyword)
          : [],
        requireFollow: Boolean(campaign.setting.requireFollow),
        excludeHunterAccount: Boolean(campaign.setting.excludeHunterAccount),
        excludeMultiWinner: Boolean(campaign.setting.excludeMultiWinner),
        excludeOtherCampaignWinner: Boolean(campaign.setting.excludeOtherCampaignWinner),
        excludeAccountCreateDateWithinPeriod: Boolean(campaign.setting.excludeAccountCreateDateWithinPeriod),
        excludeDefaultProfileImage: Boolean(campaign.setting.excludeDefaultProfileImage),
        excludeNoDescription: Boolean(campaign.setting.excludeNoDescription),
        minFollowers: campaign.setting.minFollowers,
        oldMinFollowers: campaign.setting.minFollowers,
        dailySendLimitCount: campaign.setting.dailySendLimitCount,
        minTweets: campaign.setting.minTweets,
        oldMinTweets: campaign.setting.minTweets,
        status: !this.isCopy ? campaign.status : CAMPAIGN_STATUS.BEFORE_EVENT,
        isRequireMedia: Boolean(campaign.setting.isRequireMedia)
      }

      if (this.isDisabledRequireMedia) {
        this.campaign.isRequireMedia = false
      }

      if (campaign.account) {
        this.account = campaign.account

        if (this.isCopy) {
          this.account.snsType = campaign.snsType
        }
      }
    },

    /**
     * フォーム検証チェック
     */
    checkFormIsValid() {
      this.$v.$touch()
      // キャンペーン期間検証チェック
      this.checkDatetimeIsValid()
      if (!this.datetimeValid) {
        this.formValid = false
        return
      }
      if (!this.campaign.title) {
        this.formValid = false
        return
      }
      if (!this.campaign.entryKeyword.length) {
        this.formValid = false
        return
      }
      this.formValid = true
    },

    /**
     * キャンペーン期間検証チェック
     */
    checkDatetimeIsValid() {
      // 開始日時チェック
      if (this.isBeforeStart && this.startDate && this.startTime) {
        const startDatetime = this.startDate + ' ' + this.startTime
        if (startDatetime < moment().format('YYYY-MM-DD HH:mm')) {
          this.startDatetimeInvalid = true
          this.datetimeValid = false
          return
        } else {
          this.startDatetimeInvalid = false
        }
      }

      // 期間チェック
      if (this.startDate && this.startTime && this.endDate && this.endTime) {
        const startDatetime = this.startDate + ' ' + this.startTime
        const endDatetime = this.endDate + ' ' + this.endTime
        if (startDatetime >= endDatetime) {
          this.datetimeValid = false
          this.endDatetimeInvalid = true
        } else {
          this.datetimeValid = true
          this.endDatetimeInvalid = false
        }
      } else {
        this.datetimeValid = false
      }
    },

    /**
     * エントリの変更キーワード
     * @param {string[]} keywords
     */
    onChangeEntryKeyword(keywords: string[]) {
      if (this.campaign.entryKeyword.length < keywords.length) {
        TrackingService.sendEvent('click:キャンペーンの新規作成(IW)|キャンペーン|ハッシュタグ追加')
      } else TrackingService.sendEvent('click:キャンペーンの新規作成(IW)|キャンペーン|削除')

      const origin = Object.assign([], this.campaign.entryKeyword)
      keywords = this.formatKeyword(keywords)
      if (this.isDuplicated(keywords, origin)) {
        this.campaign.entryKeyword = Object.assign([], origin)
        return
      }
      this.campaign.entryKeyword = keywords
      if (this.isKeywordsOver(keywords)) {
        const message = this.$gettext('キャンペーンハッシュタグの合計を100文字以下にしてください。')
        this.campaign.entryKeyword = Object.assign([], origin)
        this.showKeywordsOverNotify(message)
      }
      if (!this.isKeywordCorrect(keywords)) {
        const message = this.$gettext('ハッシュタグに利用できない文字列が含まれています。')
        this.campaign.entryKeyword = Object.assign([], origin)
        this.showKeywordsOverNotify(message)
      }
      this.checkFormIsValid()
    },

    /**
     * 説明キーワードを除外
     * @param {string[]} keywords
     */
    onChangeExcludeDescriptionKeyword(keywords: string[]) {
      if (this.campaign.excludeDescriptionKeyword.length < keywords.length) {
        TrackingService.sendEvent('click:キャンペーンの新規作成(IW)|キーワード含む場合、落選|追加')
      } else TrackingService.sendEvent('click:キャンペーンの新規作成(IW)|キーワード含む場合、落選|削除')

      const origin = [...this.campaign.excludeDescriptionKeyword]

      const uniqueKeywords = Array.from(
        new Set(
          keywords.flatMap(v =>
            v
              .split(',')
              .map(v => v.trim())
              .filter(Boolean)
          )
        )
      )

      this.campaign.excludeDescriptionKeyword = uniqueKeywords
      if (this.isKeywordsOver(uniqueKeywords)) {
        const message = this.$gettext('自己紹介に次のキーワード合計を100文字以下にしてください。')
        this.campaign.excludeDescriptionKeyword = origin
        this.showKeywordsOverNotify(message)
      }
      this.checkFormIsValid()
    },

    /**
     * キーワード数が100文字を超えるチェック
     */
    isKeywordsOver(keywords: string[]) {
      if (keywords.join('').length > 100) {
        return true
      }
      return false
    },

    /**
     * Check input hashtag is duplicate
     * @param {string[]} keywords
     * @param {string[]} origin
     * @returns {boolean} is duplicate or not
     */
    isDuplicated(keywords: string[], origin: string[]): boolean {
      // If action is delete hashtag
      if (origin.length > keywords.length) {
        return false
      }
      // Get new hashtag
      const latestInput = keywords[keywords.length - 1]
      if (origin.includes(latestInput)) {
        return true
      }
      return false
    },

    /**
     * Reformat keyword
     * Remove space in head and tail
     * Add # to head
     * @param {string[]} keywords
     * @returns {string[]} new keywords
     */
    formatKeyword(keywords: string[]): string[] {
      const newKeywords = []

      for (let keyword of keywords) {
        keyword = keyword.trim()
        if (keyword[0] !== '#') {
          keyword = '#' + keyword
        }
        newKeywords.push(keyword)
      }
      return newKeywords
    },

    /**
     * Check if keyword is correct
     * @param {string[]} keywords
     * @returns {boolean} correct
     */
    isKeywordCorrect(keywords: string[]): boolean {
      for (const keyword of keywords) {
        const isHashTag = twitterText.isValidHashtag(keyword)
        if (!isHashTag) {
          return false
        }
      }
      return true
    },

    /**
     * キーワード数が100文字を超える通知を表示
     */
    showKeywordsOverNotify(message: string) {
      this.$notify({
        title: message,
        customClass: 'danger',
        duration: 5000
      })
    },

    submit() {
      // validations
      this.$v.$touch()
      if (this.$v.$invalid === true) {
        return
      }
      if (!this.startDate) {
        this.$refs.startDate.focus()
        return
      }
      if (!this.startTime) {
        this.$refs.startTime.focus()
        return
      }
      if (!this.endDate) {
        this.$refs.endDate.focus()
        return
      }
      if (!this.endTime) {
        this.$refs.endTime.focus()
        return
      }
      this.checkFormIsValid()
      if (this.formValid === false) {
        return
      }
      if (this.isSubmit) {
        return
      }

      if (this.mode === 'add') {
        TrackingService.sendEvent('click:キャンペーンの新規作成(IW)|新規作成')

        this.createCampaign()
      } else if (this.mode === 'edit') {
        this.updateCampaign()
      }
    },

    /**
     * キャンペーンの新規作成
     */
    async createCampaign() {
      this.isSubmit = true

      // キャンペーンを保存する
      const result = await api.post('/campaigns', this.campaignData)

      setTimeout(() => {
        this.isSubmit = false
      }, 2000)

      if (result.error && result.error.type === 'MAX_CAMPAIGN_COUNT_OVER') {
        this.$notify({
          title: this.$gettext('同時開催可能件数を超えるため、指定の期間ではキャンペーンを開催できません。'),
          customClass: 'danger',
          duration: 5000
        })

        return
      }

      // 成功メッセージを表示
      this.$notify({
        title: this.$gettext('キャンペーンを作成しました。'),
        customClass: 'success',
        duration: 5000
      })
      // キャンペーン一覧を取得
      this.$emit('actionSuccess')

      this.fetchCampaigns()
    },

    /**
     * キャンペーンの変更
     */
    async updateCampaign() {
      this.isSubmit = true

      // キャンペーンを保存する
      const result = await api.put('/campaigns/' + this.campaignId, this.campaignData)
      setTimeout(() => {
        this.isSubmit = false
      }, 2000)

      if (result.error && result.error.type === 'NOT_EXISTS') {
        this.$notify({
          title: this.$gettext('キャンペーン実施アカウントが登録されていません。'),
          customClass: 'danger',
          duration: 5000
        })
        // キャンペーン一覧を取得
        this.fetchCampaigns()
        this.$emit('actionSuccess')
        return
      }

      if (result.error && result.error.type === 'MAX_CAMPAIGN_COUNT_OVER') {
        this.$notify({
          title: this.$gettext('同時開催可能件数を超えるため、指定の期間ではキャンペーンを開催できません。'),
          customClass: 'danger',
          duration: 5000
        })
        return
      }
      // エラーがある場合はエラーメッセージを表示
      if (!result.data) {
        this.$notify({
          title: this.$gettext('キャンペーンの保存に失敗しました。'),
          message: this.$gettext('恐れ入りますが、時間をおいて再度お試しください。'),
          customClass: 'danger',
          duration: 5000
        })
        return
      }

      // 成功メッセージを表示
      this.$notify({
        title: this.$gettext('キャンペーンを保存しました。'),
        customClass: 'success',
        duration: 5000
      })

      // キャンペーン一覧を取得
      this.fetchCampaigns()
      this.$emit('actionSuccess')
    },

    /**
     * キャンペーンを削除
     */
    async deleteCampaign() {
      const confirm = window.confirm(this.$gettext('キャンペーンを削除してよろしいですか？'))
      if (!confirm) {
        return
      }
      this.isSubmit = true
      // キャンペーンを保存する
      const result = await api.delete('/campaigns/' + this.campaignId)
      setTimeout(() => {
        this.isSubmit = false
      }, 2000)

      if (result.error && result.error.type === 'NOT_EXISTS') {
        this.$notify({
          title: this.$gettext('対象のキャンペーンはすでに削除されています。'),
          customClass: 'danger',
          duration: 5000
        })
        this.fetchCampaigns()
        this.$emit('actionSuccess')
        return
      }
      // エラーがある場合はエラーメッセージを表示
      if (!result.data) {
        this.$notify({
          title: this.$gettext('キャンペーンの削除に失敗しました。'),
          message: this.$gettext('恐れ入りますが、時間をおいて再度お試しください。'),
          customClass: 'danger',
          duration: 5000
        })
        return
      }

      // 成功メッセージを表示
      this.$notify({
        title: this.$gettext('キャンペーンを削除しました。'),
        customClass: 'success',
        duration: 5000
      })

      // キャンペーン一覧を取得
      this.fetchCampaigns()
      this.$emit('actionSuccess')
    },

    /**
     * キャンペーンアカウント変更イベント
     */
    onChangeCampaignAccount(accountId: string) {
      TrackingService.sendEvent('select:キャンペーンの新規作成(IW)|実施アカウント')

      this.account = this.accounts.find(account => {
        return account.id === accountId
      })
    },

    onChangeCampaignTitle() {
      TrackingService.sendEvent('input:キャンペーンの新規作成(IW)|キャンペーンタイトル')
    },

    onChangeStartDate() {
      TrackingService.sendEvent('select:キャンペーンの新規作成(IW)|開始日')

      this.checkFormIsValid()
    },

    onChangeStartTime() {
      TrackingService.sendEvent('select:キャンペーンの新規作成(IW)|開始時間')

      this.checkFormIsValid()
    },

    onChangeEndDate() {
      TrackingService.sendEvent('select:キャンペーンの新規作成(IW)|終了日')

      this.checkFormIsValid()
    },

    onChangeEndTime() {
      TrackingService.sendEvent('select:キャンペーンの新規作成(IW)|終了時間')

      this.checkFormIsValid()
    },

    onChangeDailySendLimitCount() {
      if (this.campaign.dailySendLimitCount === this.campaign.oldDailySendLimitCount + 1) {
        TrackingService.sendEvent('input:キャンペーン新規作成(IW)|同一応募者1日の最大返信数|UP')
      } else if (this.campaign.dailySendLimitCount === this.campaign.oldDailySendLimitCount - 1) {
        TrackingService.sendEvent('input:キャンペーン新規作成(IW)|同一応募者1日の最大返信数|DOWN')
      } else TrackingService.sendEvent('input:キャンペーン新規作成(IW)|同一応募者1日の最大返信数')

      this.campaign.oldDailySendLimitCount = this.campaign.dailySendLimitCount
    },

    onChangeEntryActionType() {
      TrackingService.sendEvent('select:キャンペーンの新規作成(IW)|アクション種類')
    },

    onChangeRequireMedia() {
      TrackingService.sendEvent('switch:キャンペーンの新規作成(IW)|メディア付きポスト')
    },

    onChangeRequireFollow() {
      TrackingService.sendEvent('switch:キャンペーンの新規作成(IW)|フォローしていない場合、落選')
    },

    onChangeExcludeMultiWinner() {
      TrackingService.sendEvent('switch:キャンペーンの新規作成(IW)|当選実績がある場合、落選')
    },

    onChangeExcludeOtherCampaignWinner() {
      TrackingService.sendEvent('switch:キャンペーンの新規作成(IW)|当選実績がある場合、落選(IW)')
    },

    onChangeExcludeHunterAccount() {
      TrackingService.sendEvent('switch:キャンペーンの新規作成(IW)|懸賞アカウントの場合、落選にする')
    },

    onChangeExcludeAccountCreateDateWithinPeriod() {
      TrackingService.sendEvent('switch:キャンペーンの新規作成(IW)|開設日が期間内の場合、落選')
    },

    onChangeExcludeDefaultProfileImage() {
      TrackingService.sendEvent('switch:キャンペーンの新規作成(IW)|初期状態の場合、落選にする')
    },

    onChangeExcludeNoDescription() {
      TrackingService.sendEvent('switch:キャンペーンの新規作成(IW)|自己紹介が未入力の場合、落選')
    },

    onChangeMinFollowers() {
      if (this.campaign.minFollowers === this.campaign.oldMinFollowers + 1) {
        TrackingService.sendEvent('click:キャンペーンの新規作成(IW)|フォロワーが未満で落選|UP')
      } else if (this.campaign.minFollowers === this.campaign.oldMinFollowers - 1) {
        TrackingService.sendEvent('click:キャンペーンの新規作成(IW)|フォロワーが未満で落選|DOWN')
      } else TrackingService.sendEvent('input:キャンペーンの新規作成(IW)|フォロワーが未満で落選')

      this.campaign.oldMinFollowers = this.campaign.minFollowers
    },

    onChangeMinTweets() {
      if (this.campaign.minTweets === this.campaign.oldMinTweets + 1) {
        TrackingService.sendEvent('click:キャンペーンの新規作成(IW)|ポストが未満で落選|UP')
      } else if (this.campaign.minTweets === this.campaign.oldMinTweets - 1) {
        TrackingService.sendEvent('click:キャンペーンの新規作成(IW)|ポストが未満で落選|DOWN')
      } else TrackingService.sendEvent('input:キャンペーンの新規作成(IW)|ポストが未満で落選')

      this.campaign.oldMinTweets = this.campaign.minTweets
    }
  },

  validations: {
    campaign: {
      title: {
        required
      },
      entryActionType: {
        required
      }
    }
  }
}
