/* @flow */
import Icon from '@/client/components/basic/icon'
export default {
  components: {
    Icon
  },
  props: {
    selectedAccountId: Number,
    accounts: Array,
    onChange: Function,
    disabled: {
      type: Boolean,
      default: false
    }
  },
  created() {
    this.setSelectedAccount()
  },
  data() {
    return {
      selectedAccount: {},
      currentAccountId: this.selectedAccountId
    }
  },
  watch: {
    selectedAccountId() {
      this.currentAccountId = this.selectedAccountId
      this.setSelectedAccount()
    }
  },
  methods: {
    /**
     * アカウント変更
     * @param {string} accountId アカウントID
     */
    changeAccount(accountId: string) {
      this.currentAccountId = accountId
      this.setSelectedAccount()
      if (typeof this.onChange === 'function') {
        this.onChange(this.currentAccountId)
      }
    },
    /**
     * 選択したアカウントをセット
     */
    setSelectedAccount() {
      if (!this.currentAccountId) {
        this.selectedAccount = this.accounts[0]
      } else {
        let selectedAccount = this.accounts.find(account => {
          return account.id === this.currentAccountId
        })
        if (!selectedAccount) {
          selectedAccount = this.accounts[0]
        }
        this.selectedAccount = selectedAccount
      }
    }
  }
}
