/* @flow */
import moment from 'moment-timezone'
import { mapState } from 'vuex'

import Icon from '@/client/components/basic/icon'
import { CAMPAIGN_STATUS } from '@/client/constant'

export default {
  components: { Icon },
  computed: {
    ...mapState('instagramAnalyticsPublic', {
      campaign: function(state) {
        const campaign = { ...state.campaign }

        if (!campaign.account || !campaign.account.name) {
          campaign.account = {
            name: this.$gettext('削除されたアカウント'),
            pictureUrl: require('@/client/assets/images/instagram_default_profile.png')
          }
        }

        return campaign
      }
    }),

    isCampaignFinalCollection() {
      return this.campaign.status === CAMPAIGN_STATUS.DURING_FINAL_COLLECTION
    },

    isCampaignCollectionCompleted() {
      return this.campaign.status === CAMPAIGN_STATUS.COLLECTION_COMPLETED
    },

    collectCompletedAt() {
      // 分析バッチの実行完了を考慮し、キャンペーン終了時刻の1時間後を表示する
      return moment(this.campaign.endDatetime)
        .add(1, 'hours')
        .format('YYYY年MM月DD日 HH:mm')
    }
  }
}
