
































import { Component, Vue } from 'vue-property-decorator'
import { mapActions, mapGetters, mapState } from 'vuex'

import Icon from '@/client/components/_atoms/Icon.vue'
import CampaignCreateAccount from '@/client/components/_organisms/CampaignCreateAccount.vue'
import CampaignCreateInstagramCondition from '@/client/components/_organisms/CampaignCreateInstagramCondition.vue'
import CampaignCreatePeriod from '@/client/components/_organisms/CampaignCreatePeriod.vue'
import CampaignCreateTitle from '@/client/components/_organisms/CampaignCreateTitle.vue'
import {
  CampaignDialogActions,
  CampaignDialogGetters,
  CampaignDialogState
} from '@/client/store/campaign_dialog.module'

@Component({
  components: {
    Icon,
    CampaignCreateTitle,
    CampaignCreateAccount,
    CampaignCreatePeriod,
    CampaignCreateInstagramCondition
  },
  computed: {
    ...mapState('campaign_dialog', ['api_campaign', 'form', 'mode']),
    ...mapGetters('campaign_dialog', ['is_edit_disabled', 'is_form_change'])
  },
  methods: {
    ...mapActions('campaign_dialog', [
      'setCampaignId',
      'fetchCampaign',
      'fetchCampaignAccounts',
      'updateCampaign',
      'deleteCampaign',
      'setMode',
      'reset'
    ]),
    ...mapActions('campaign', ['fetchCampaigns'])
  }
})
export default class DialogInstagramCampaignEdit extends Vue {
  api_campaign!: CampaignDialogState['api_campaign']
  form!: CampaignDialogState['form']
  mode!: CampaignDialogState['mode']
  is_edit_disabled!: CampaignDialogGetters['is_edit_disabled']
  is_form_change!: CampaignDialogGetters['is_form_change']
  setCampaignId!: CampaignDialogActions['setCampaignId']
  fetchCampaign!: CampaignDialogActions['fetchCampaign']
  fetchCampaignAccounts!: CampaignDialogActions['fetchCampaignAccounts']
  updateCampaign!: CampaignDialogActions['updateCampaign']
  deleteCampaign!: CampaignDialogActions['deleteCampaign']
  setMode!: CampaignDialogActions['setMode']
  reset!: CampaignDialogActions['reset']
  fetchCampaigns!: () => void

  visible = false

  /**
   * ダイアログキャンペーンを開く
   */
  async open(campaign_id: number) {
    await this.setCampaignId(campaign_id)
    await this.setMode('edit')
    const response = await this.fetchCampaign()

    if (!response.data) {
      this.$notify({
        title: 'キャンペーンの取得に失敗しました。',
        message: '恐れ入りますが、時間をおいて再度お試しください。',
        customClass: 'danger'
      })

      this.visible = false
      this.reset()
      return
    }

    await this.fetchCampaignAccounts()

    this.visible = true
  }

  /**
   * ダイアログキャンペーンを閉じる
   */
  close() {
    if (!this.is_form_change) {
      this.visible = false
      this.reset()
      return
    }

    const confirm = window.confirm('編集内容が保存されていません。ダイアログを閉じますか？')

    if (!confirm) {
      return
    }

    this.visible = false
    this.reset()
  }

  /**
   * 保存ボタン
   */
  async onSave() {
    const result = await this.updateCampaign()

    if (result.error && result.error.type === 'MAX_CAMPAIGN_COUNT_OVER') {
      return this.$notify({
        title: 'キャンペーンの変更に失敗しました。',
        message: '同時開催可能件数を超えるため、指定の期間ではキャンペーンを開催できません。',
        customClass: 'danger'
      })
    }

    if (!result.data) {
      return this.$notify({
        title: 'キャンペーンの変更に失敗しました。',
        message: '恐れ入りますが、時間をおいて再度お試しください。',
        customClass: 'danger'
      })
    }

    this.$notify({
      title: 'キャンペーンを変更しました。',
      message: '',
      customClass: 'success',
      duration: 5000
    })

    this.fetchCampaigns()

    this.visible = false
    this.reset()
  }

  /**
   * 削除ボタン
   */
  async onDelete() {
    const confirm = window.confirm('キャンペーンを削除してよろしいですか？')

    if (!confirm) {
      return
    }

    const result = await this.deleteCampaign()

    if (result.error && result.error.type === 'NO_DATA_EXISTS') {
      return this.$notify({
        title: '対象のキャンペーンはすでに削除されています。',
        message: '',
        customClass: 'danger'
      })
    }

    if (!result.data) {
      return this.$notify({
        title: 'キャンペーンの削除に失敗しました。',
        message: '',
        customClass: 'danger'
      })
    }

    this.fetchCampaigns()

    this.$notify({
      title: 'キャンペーンを削除しました。',
      message: '',
      customClass: 'success',
      duration: 5000
    })

    this.visible = false
    this.reset()
  }
}
