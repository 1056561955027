/* @flow */
import moment from 'moment-timezone'
import { mapState } from 'vuex'

import AnalyticsChartDoughnut from '@/client/components/basic/analytics_chart_doughnut'
import AnalyticsChartVertical from '@/client/components/basic/analytics_chart_vertical'
import AnalyticsTotal from '@/client/components/basic/analytics_total'
import CsvDownload from '@/client/components/basic/csv_download'
import Icon from '@/client/components/basic/icon'
import { CAMPAIGN_STATUS } from '@/client/constant'
import api from '@/client/core/api'
import Util from '@/client/core/util'
import { TrackingService } from '@/client/services'
import { type TGetCampaignAnalyticsId } from '@/typings/api/twitter_campaign_analytics.type'

export default {
  components: {
    Icon,
    AnalyticsChartVertical,
    AnalyticsChartDoughnut,
    AnalyticsTotal,
    CsvDownload
  },
  data() {
    return {
      activeTab: 'campaigns',
      visible: false,
      loading: true,
      campaignId: null,
      chartData: [],
      followerData: [],
      labels: [],
      interval: 'hour',
      value1: 'follower',
      value2: 'applicant',
      data: [],
      reachCount: 0,
      applicantCount: 0,
      followerUpDown: 0,
      entryCount: 0,
      newApplicantCount: 0,
      newFollowerCount: 0,
      winnerCount: 0,
      prizeOptions: [{ name: '全ての応募グループ', id: null }],
      prizeId: null,
      dateTimeRange: [],
      defaultDateTimeRange: [],
      datePickerOption: {
        disabledDate(time: any) {
          return false
        }
      },
      hash: '',
      isBeginAtZero: false,
      chartStyles: {
        margin: '0 auto'
      },
      isPublicAnalyticsPage: 0,
      isPublicAnalyticsApi: 0,
      entryActionType: ''
    }
  },
  computed: {
    ...mapState('campaign', {
      campaign(state) {
        const campaigns = state.campaigns.find(value => value.id === this.campaignId)

        return {
          id: campaigns ? campaigns.id : null,
          name: campaigns ? campaigns.title : null,
          status: campaigns ? campaigns.status : null,
          pictureUrl: campaigns ? campaigns.account.pictureUrl : null,
          type: campaigns ? campaigns.type : null,
          iconType: 'twitter',
          startDatetime: campaigns ? campaigns.startDatetime : null,
          endDatetime: campaigns ? campaigns.endDatetime : null
        }
      }
    }),

    isCampaignStartingIncludeRetweets() {
      return (
        this.campaign.status === CAMPAIGN_STATUS.DURING_COLLECTION &&
        ['keyword', 'web_instantwin'].includes(this.campaign.type) &&
        ['retweet_and_quote_tweet', 'retweet'].includes(this.entryActionType)
      )
    },

    isCampaignFinalCollection() {
      return this.campaign.status === CAMPAIGN_STATUS.DURING_FINAL_COLLECTION
    },

    isCampaignCollectionCompleted() {
      return this.campaign.status === CAMPAIGN_STATUS.COLLECTION_COMPLETED
    },

    collectCompletedAt() {
      // フォロワー取得バッチが深夜1時に実行されるため、次のバッチ実行日の午前10時を表示する
      return moment(this.campaign.endDatetime)
        .subtract(1, 'hours')
        .add(1, 'days')
        .format('YYYY年MM月DD日 10:00')
    },

    values() {
      if (this.interval === 'hour') {
        return [
          { label: this.$gettext('指定なし'), value: '' },
          { label: this.$gettext('応募数'), value: 'entry' },
          { label: this.$gettext('応募者数'), value: 'applicant' },
          { label: this.$gettext('新規応募者数'), value: 'newApplicant' },
          { label: this.$gettext('当選数'), value: 'winner' },
          { label: this.$gettext('推定リーチ'), value: 'reach' },
          { label: this.$gettext('フォロワー'), value: 'follower' }
        ]
      }

      return [
        { label: this.$gettext('指定なし'), value: '' },
        { label: this.$gettext('応募数'), value: 'entry' },
        { label: this.$gettext('応募者数'), value: 'applicant' },
        { label: this.$gettext('新規応募者数'), value: 'newApplicant' },
        { label: this.$gettext('当選数'), value: 'winner' },
        { label: this.$gettext('推定リーチ'), value: 'reach' },
        { label: this.$gettext('フォロワー'), value: 'follower' },
        { label: this.$gettext('新規獲得フォロワー'), value: 'newFollower' }
      ]
    },

    intervals() {
      return [
        { text: this.$gettext('1時間'), value: 'hour' },
        { text: this.$gettext('1日'), value: '1_day' },
        { text: this.$gettext('7日'), value: '7_day' },
        { text: this.$gettext('1ヶ月'), value: '1_month' }
      ]
    },

    time_range() {
      const timeRange = []

      if (!this.data.length) {
        return timeRange
      }

      const addNumber = Number(this.interval.match(/\d+/g))

      let timeEnd
      let timeStart = moment(moment(this.data[0].datetime).format('YYYY-MM-DD 00:00:00')).unix()
      const endDateUnix = moment(moment(this.data[this.data.length - 1].datetime).format('YYYY-MM-DD HH:mm:00')).unix()

      while (timeStart <= endDateUnix) {
        if (
          this.interval === '1_day' ||
          this.interval === '7_day' ||
          this.interval === '14_day' ||
          this.interval === '28_day'
        ) {
          // 日数のunix値: 日数 x 一日のunix値 (一日のunix値: 86400)
          timeEnd = timeStart + addNumber * 86400 - 1
        } else if (this.interval === '1_month') {
          timeEnd =
            moment
              .unix(timeStart)
              .add(addNumber, 'months')
              .startOf('month')
              .unix() - 1
        }

        if (timeEnd > endDateUnix) {
          timeEnd = endDateUnix
        }

        timeRange.push({
          start: timeStart,
          end: timeEnd
        })

        timeStart = timeEnd + 1
      }

      if (timeRange.length) {
        timeRange[0].start = moment(this.data[0].datetime).unix()
      } else {
        timeRange.push({ start: endDateUnix, end: endDateUnix })
      }

      return timeRange
    },

    time_range_for_label() {
      const timeRange = []

      if (!this.data.length) {
        return timeRange
      }

      const addNumber = Number(this.interval.match(/\d+/g))

      let timeEnd
      let timeStart = moment(moment(this.data[0].datetime).format('YYYY-MM-DD 00:00:00')).unix()
      const endDateUnix = moment(moment(this.data[this.data.length - 1].datetime).format('YYYY-MM-DD HH:mm:00')).unix()

      while (timeStart <= endDateUnix) {
        if (
          this.interval === '1_day' ||
          this.interval === '7_day' ||
          this.interval === '14_day' ||
          this.interval === '28_day'
        ) {
          // 日数のunix値: 日数 x 一日のunix値 (一日のunix値: 86400)
          timeEnd = timeStart + addNumber * 86400 - 1
        } else if (this.interval === '1_month') {
          timeEnd =
            moment
              .unix(timeStart)
              .add(addNumber, 'months')
              .startOf('month')
              .unix() - 1
        }

        if (timeEnd > endDateUnix) {
          timeEnd = endDateUnix
        }

        timeRange.push({
          start: timeStart,
          end: timeEnd
        })

        timeStart = timeEnd + 1
      }

      if (timeRange.length) {
        timeRange[0].start = moment(this.data[0].datetime).unix()
      } else {
        timeRange.push({ start: endDateUnix, end: endDateUnix })
      }

      return timeRange
    },

    analytics() {
      const data = {
        applicant: [],
        reach: [],
        follower: [],
        entry: [],
        newApplicant: [],
        newFollower: [],
        winner: []
      }

      if (!this.data.length) {
        return data
      }

      if (this.interval === 'hour') {
        const lastIndex = this.data.length - 1
        this.data.forEach((value, index) => {
          data.reach.push(value.reach_count)
          data.follower.push(value.follower_count)
          data.newFollower.push(value.new_follower_count)

          if (index !== lastIndex) {
            data.applicant.push(value.applicant_count)
            data.entry.push(value.entry_count)
            data.newApplicant.push(value.new_applicant_count)
            data.winner.push(value.winner_count)
          }
        })
        return data
      }

      const endDateUnix = moment(this.data[this.data.length - 1].datetime)
        .startOf('minute')
        .unix()
      this.time_range.forEach((time, index) => {
        // 該当する期間の分析データを取得
        let analytics = this.data
          .filter(analytic => {
            return moment(analytic.datetime).unix() >= time.start && moment(analytic.datetime).unix() <= time.end
          })
          .map(analytic => analytic)

        if (this.interval === '1_day') {
          const momentDate = index < this.time_range.length - 1 ? moment.unix(time.start) : moment.unix(time.end)
          const datetime = momentDate.startOf('hour').format('YYYY-MM-DD HH:mm')

          data.follower.push(this.getValueByHour(analytics, datetime, 'follower_count'))
          data.newFollower.push(this.getValueByHour(analytics, datetime, 'new_follower_count'))
          data.reach.push(this.getValueByHour(analytics, datetime, 'reach_count'))
        } else {
          data.reach.push(this.getValueByPeriod(analytics, 'reach_count'))
          data.follower.push(this.getValueByPeriod(analytics, 'follower_count'))
          data.newFollower.push(this.getValueByPeriod(analytics, 'new_follower_count'))
        }

        if (time.end === endDateUnix) {
          analytics = analytics.filter(v => moment(v.datetime).unix() !== endDateUnix)
        }

        data.applicant.push(this.getTotal(analytics, 'applicant_count'))
        data.entry.push(this.getTotal(analytics, 'entry_count'))
        data.newApplicant.push(this.getTotal(analytics, 'new_applicant_count'))
        data.winner.push(this.getTotal(analytics, 'winner_count'))
      })

      return data
    },

    isEmptyApplicantFollower() {
      return this.followerData.every(follower => follower.count === 0)
    },

    publicPageUrl() {
      return `https://${window.location.host}/publics/twitter_campaign_analytics?hash=${this.hash}`
    },

    publicApiUrl() {
      if (this.prizeId) {
        return `https://${window.location.host}/api/publics/applicant_count?hash=${this.hash}&prizeId=${this.prizeId}`
      }

      return `https://${window.location.host}/api/publics/applicant_count?hash=${this.hash}`
    }
  },
  methods: {
    /**
     * ダイアログを開く
     */
    async open(campaignId: number) {
      this.activeTab = 'campaigns'
      this.visible = true
      this.loading = true
      this.campaignId = campaignId
      this.value1 = 'follower'
      this.value2 = 'applicant'
      this.interval = '1_day'
      this.labels = []
      this.chartData = []
      this.followerData = []
      this.prizeId = null
      this.prizeOptions = [{ name: '全ての応募グループ', id: null }]
      this.entryActionType = ''
      try {
        await this.getCampaignAnalyticsId(this.campaignId)
        await this.getPrizes()
        await this.getCampaign()
        await this.getApplicantFollower()
        this.changeChart('')
        this.loading = false
      } catch (e) {
        this.loading = false
        this.alert()
        this.close()
      }
    },

    /**
     * キャンペーンデータを取得
     */
    async getCampaign() {
      if (!this.campaignId) {
        return
      }

      const result = await api.get('/campaigns/' + this.campaignId)

      if (!result || !result.data) {
        return
      }

      const campaign: any = result.data
      this.dateTimeRange = [
        campaign.startDatetime,
        moment(campaign.endDatetime)
          .subtract(1, 'hour')
          .startOf('day')
      ]
      this.defaultDateTimeRange = [
        campaign.startDatetime,
        moment(campaign.endDatetime)
          .subtract(1, 'hour')
          .startOf('day')
      ]
      this.hash = campaign.hash
      this.isPublicAnalyticsPage = campaign.isPublicAnalyticsPage
      this.isPublicAnalyticsApi = campaign.isPublicAnalyticsApi
      this.datePickerOption = {
        disabledDate(time: any) {
          const start = moment(campaign.startDatetime).startOf('day')
          const end = moment(campaign.endDatetime)
            .subtract(1, 'hour')
            .startOf('day')
          return moment(time).isBefore(start) || moment(time).isAfter(end)
        }
      }
      this.entryActionType = campaign.setting ? campaign.setting.entryActionType : ''
    },

    /**
     * 日時を変更する
     */
    async changeDatetimerange(value: any) {
      TrackingService.sendEvent('select:キャンペーン分析（X）|カレンダー')

      if (!value) {
        this.dateTimeRange = this.defaultDateTimeRange
      } else {
        this.dateTimeRange = value
      }
      this.loading = true
      await this.getCampaignAnalyticsId(this.campaignId)
      this.changeChart('')
      await this.getApplicantFollower()
      this.loading = false

      if (!this.dateTimeRange) {
        this.dateTimeRange = this.defaultDateTimeRange
      }
    },

    /**
     * 応募グループ分析データの取得
     */
    async getAnalyticByPrize(): Promise<void> {
      TrackingService.sendEvent('select:キャンペーン分析（X）|全ての応募グループ')

      this.loading = true
      await this.getCampaignAnalyticsId(this.campaignId)
      this.changeChart('')
      await this.getApplicantFollower()
      this.loading = false
    },

    /**
     * Get applicant follower
     */
    async getApplicantFollower() {
      if (!this.campaignId) {
        return
      }

      const params: any = {}

      if (this.prizeId && ['keyword', 'web_instantwin'].includes(this.campaign.type)) {
        params.prizeId = this.prizeId
      }

      if (this.dateTimeRange && this.dateTimeRange.length) {
        params.startDatetime = moment(this.dateTimeRange[0]).format('YYYY-MM-DD')
        params.endDatetime = moment(this.dateTimeRange[1]).format('YYYY-MM-DD')
      }

      const result = await api.get('/twitter_campaign_analytics/' + this.campaignId + '/followers', { params })

      if (!result || !result.data) {
        this.alert()
        this.close()
        return
      }

      this.followerData = result.data
    },

    /**
     * ダイアログを閉じる
     */
    close() {
      this.visible = false
      this.loading = true
      this.campaignId = null
      this.dateTimeRange = []
    },

    /**
     * ダイアログを閉じる
     */
    beforeClose() {
      TrackingService.sendEvent('click:キャンペーン分析（X）|閉じる')

      this.visible = false
      this.dateTimeRange = []
      this.defaultDateTimeRange = []
    },

    /**
     * ソートする値の変更イベント
     */
    changeChart(selectedValue: string) {
      if (selectedValue === 'value1') {
        TrackingService.sendEvent('select:キャンペーン分析（X）|キャンペーン|値1')
      } else if (selectedValue === 'value2') TrackingService.sendEvent('select:キャンペーン分析（X）|キャンペーン|値2')

      this.chartData = this.getChartData()
      this.labels = this.getLabels()
    },

    changeInterval(interval: string) {
      this.interval = interval
      if (interval === 'hour') {
        TrackingService.sendEvent('click:キャンペーン分析（X）|キャンペーン|1時間')

        if (this.value1 === 'newFollower') {
          this.value1 = 'follower'
        }
        if (this.value2 === 'newFollower') {
          this.value2 = 'applicant'
        }
      } else if (interval === '1_day') {
        TrackingService.sendEvent('click:キャンペーン分析（X）|キャンペーン|1日')
      } else if (interval === '7_day') {
        TrackingService.sendEvent('click:キャンペーン分析（X）|キャンペーン|7日')
      } else TrackingService.sendEvent('click:キャンペーン分析（X）|キャンペーン|1ヶ月')

      this.changeChart('')
    },

    getLabels() {
      let labels = []

      switch (this.interval) {
        case 'hour':
          labels = []
          this.data.forEach((value, index) => {
            const date = moment(value.datetime)
              .startOf('hour')
              .format('YYYY/MM/DD HH:mm')
            labels.push(date)
          })
          return labels
        case '1_day':
          return this.time_range_for_label.map((time, index) => {
            if (index === this.time_range_for_label.length - 1) {
              return moment
                .unix(time.end)
                .startOf('hour')
                .format('YYYY/MM/DD')
            }

            return moment
              .unix(time.start)
              .startOf('hour')
              .format('YYYY/MM/DD')
          })
        case '7_day':
        case '14_day':
        case '28_day':
          return this.time_range_for_label.map(time => {
            return `${moment.unix(time.start).format('MM/DD')}-${moment.unix(time.end).format('MM/DD')}`
          })
        case '1_month':
          return this.time_range_for_label.map(time => {
            return moment.unix(time.start).format('YYYY/MM')
          })
      }
    },

    getChartData() {
      const data = []

      if (this.value1) {
        const label = this.values.find(value => value.value === this.value1)
        data.push({
          metric: this.value1,
          label: label.label,
          data: this.analytics[this.value1]
        })
      }

      if (this.value2) {
        const label = this.values.find(value => value.value === this.value2)
        data.push({
          metric: this.value2,
          label: label.label,
          data: this.analytics[this.value2]
        })
      }

      return data
    },

    /**
     * CSVファイルをダウンロード
     */
    downloadCsv() {
      TrackingService.sendEvent('click:キャンペーン分析（X）|キャンペーン|CSVダウンロード')

      const filename = `${this.campaign.name}_分析`

      const data = []

      let header = [
        this.$gettext('日付'),
        this.$gettext('応募数'),
        this.$gettext('応募者数'),
        this.$gettext('新規応募者数'),
        this.$gettext('当選数'),
        this.$gettext('推定リーチ'),
        this.$gettext('フォロワー'),
        this.$gettext('新規獲得フォロワー')
      ]

      if (this.interval === 'hour') {
        header = [
          this.$gettext('日付'),
          this.$gettext('応募数'),
          this.$gettext('応募者数'),
          this.$gettext('新規応募者数'),
          this.$gettext('当選数'),
          this.$gettext('推定リーチ'),
          this.$gettext('フォロワー')
        ]
      }

      data.push(header)

      this.labels.forEach((label, index) => {
        if (this.interval === '1_day') {
          label = moment(label)
            .startOf('hour')
            .format('YYYY/MM/DD')
        }

        const applicant = isNaN(this.analytics.applicant[index]) ? null : this.analytics.applicant[index]
        const reach = isNaN(this.analytics.reach[index]) ? null : this.analytics.reach[index]
        const follower = isNaN(this.analytics.follower[index]) ? null : this.analytics.follower[index]
        const entry = isNaN(this.analytics.entry[index]) ? null : this.analytics.entry[index]
        const newApplicant = isNaN(this.analytics.newApplicant[index]) ? null : this.analytics.newApplicant[index]
        const newFollower = isNaN(this.analytics.newFollower[index]) ? null : this.analytics.newFollower[index]
        const winner = isNaN(this.analytics.winner[index]) ? null : this.analytics.winner[index]

        let values = [label, entry, applicant, newApplicant, winner, reach, follower, newFollower]

        if (this.interval === 'hour') {
          values = [label, entry, applicant, newApplicant, winner, reach, follower]
        }

        data.push(values)
      })

      return { filename, data }
    },

    /**
     * CSVダウンロード
     */
    downloadCsvFollowers() {
      TrackingService.sendEvent('click:キャンペーン分析（X）|応募者のフォロワー数|CSVダウンロード')

      const filename = `${this.campaign.name}_応募者のフォロワー分析`
      const data = []
      const header = [this.$gettext('ラベル'), this.$gettext('値'), this.$gettext('割合')]

      data.push(header)
      const totalData = this.followerData.map(follower => follower.count).reduce((count, current) => count + current, 0)

      this.followerData.forEach(follower => {
        const percentage = !totalData ? 0 : Math.round((follower.count / totalData) * Math.pow(100, 2)) / 100
        data.push([follower.label, follower.count, percentage])
      })

      return { filename, data }
    },

    /**
     * キャンペーン分析を取得
     */
    async getCampaignAnalyticsId(campaignId: number) {
      let params: any = {}

      if (this.prizeId && ['keyword', 'web_instantwin'].includes(this.campaign.type)) {
        params = {
          prizeId: this.prizeId
        }
      }

      if (this.dateTimeRange.length) {
        params.startDatetime = moment(this.dateTimeRange[0]).format('YYYY-MM-DD')
        params.endDatetime = moment(this.dateTimeRange[1]).format('YYYY-MM-DD')
      }

      const response = await api.get(`/twitter_campaign_analytics/${campaignId}`, { params })

      if (!response.data) {
        this.alert()
        this.close()
        return
      }

      this.data = response.data

      const firstFollowerCount = this.getFirstValueExceptNull(this.data, 'follower_count')
      const firstReachCount = this.data[0] ? this.data[0].reach_count : 0
      const columnData = this.data.slice(0, this.data.length - 1)

      this.applicantCount = this.getTotal(columnData, 'applicant_count')
      this.newApplicantCount = this.getTotal(columnData, 'new_applicant_count')
      this.newFollowerCount = this.getNewFollowerCount()
      this.followerUpDown = this.getLastValue(this.data, 'follower_count') - firstFollowerCount
      this.reachCount = this.getLastValue(this.data, 'reach_count') - firstReachCount
      this.entryCount = this.getTotal(columnData, 'entry_count')
      this.winnerCount = this.getTotal(columnData, 'winner_count')
    },

    /**
     * 応募グループ一覧を取得する
     */
    async getPrizes() {
      const params = {
        campaignId: this.campaignId,
        forCsv: 1
      }

      const result = await api.get(`/twitter_campaign_prizes`, { params })

      if (!result || !result.data || !result.data.prizes) {
        return
      }

      const prizes = result.data.prizes.map(prize => ({ id: prize.id, name: prize.name }))

      this.prizeOptions = [...this.prizeOptions, ...prizes]
    },

    getTotal(data: TGetCampaignAnalyticsId[], metric: string) {
      return this.addition(this.getMetricArray(data, metric))
    },

    /**
     * 分析データから値を取得
     */
    getMetricValue(insight: any, metric: string): any | null {
      return isNaN(insight[metric]) ? null : insight[metric]
    },

    /**
     * 分析データから値の配列を取得
     */
    getMetricArray(insights: any[], metric: string): any[] {
      return insights.map(insight => this.getMetricValue(insight, metric))
    },

    /**
     * 数値変換
     */
    toNumber(value: any): number {
      return !isNaN(value) ? value : 0
    },

    /**
     * 足し算
     */
    addition(numbers: number[] = []): number | null {
      const values = numbers.filter(v => v !== null)

      if (!values.length) {
        return null
      }

      return numbers.map(v => this.toNumber(v)).reduce((a, c) => a + c, 0)
    },

    getNewFollowerCount() {
      const data = this.data.filter(analytic => analytic.new_follower_count)

      if (moment(this.data[0].datetime).isSame(this.campaign.startDatetime, 'day')) {
        return data.length ? data[data.length - 1].new_follower_count : 0
      }

      if (data.length === 1) {
        return 0
      }

      if (data.length > 1) {
        return data[data.length - 1].new_follower_count - data[0].new_follower_count
      }

      return null
    },

    getFirstValueExceptNull(analytics: any[], value: string) {
      const data = analytics.find(analytic => analytic[value] !== null)

      if (!data) {
        return 0
      }

      return data[value]
    },

    getLastValue(analytics: any[], value: string) {
      const data = analytics.filter(analytic => analytic[value] !== null)

      if (data.length === 0) {
        return null
      }

      return data[data.length - 1][value]
    },

    getValueByPeriod(analytics: any[], value: string): number | null {
      const data = analytics.filter(analytic => analytic[value] !== null)

      if (data.length === 0) {
        return null
      }

      // If there is only one period, return last value
      if (analytics.length === this.data.length) {
        return data[data.length - 1][value]
      }

      // If this is the first period, return first value
      if (moment(analytics[0].datetime).isSame(this.data[0].datetime)) {
        return data[0][value]
      }

      return data[data.length - 1][value]
    },

    getValueByHour(analytics: any[], datetime: string, value: string) {
      const data = analytics.find(analytic => moment(analytic.datetime).isSame(datetime, 'hour'))

      if (!data) {
        return null
      }

      return data[value]
    },

    /**
     * キャンペーン分析公開フラグを更新する
     */
    async changePublicAnalyticsFlags(value: string) {
      TrackingService.sendEvent(
        `click:キャンペーン分析（X）|${value === '分析結果の共有' ? '分析結果の共有' : 'APIとして公開'}`
      )

      const params: any = {
        isPublicAnalyticsPage: this.isPublicAnalyticsPage,
        isPublicAnalyticsApi: this.isPublicAnalyticsApi
      }

      const result = await api.put(`campaigns/${this.campaignId}/publics`, params)

      if (!result.data) {
        this.$notify({
          title: this.$gettext('キャンペーン分析公開フラグの変更に失敗しました。'),
          customClass: 'danger',
          duration: 5000
        })
      }
    },

    /**
     * クリップボードにコピーする
     */
    copyToClipboard(text: string) {
      Util.copyToClipboard(text)
      this.$message({
        message: this.$gettext('クリップボードにURLをコピーしました。'),
        type: 'success'
      })
    },

    /**
     * エラー通知
     */
    alert() {
      this.$notify({
        title: this.$gettext('キャンペーン分析の取得に失敗しました。'),
        message: this.$gettext('恐れ入りますが、時間をおいて再度お試しください。'),
        customClass: 'danger',
        duration: 5000
      })
    },

    changeTab() {
      if (this.activeTab === 'campaigns') {
        TrackingService.sendEvent('click:キャンペーン分析（X）|キャンペーン')
      } else TrackingService.sendEvent('click:キャンペーン分析（X）|応募者のフォロワー数')
    },

    changeIsBeginAtZero() {
      TrackingService.sendEvent('switch:キャンペーン分析（X）|キャンペーン|0から表示')
    }
  }
}
