/* @flow */
import { mapActions, mapState } from 'vuex'

import SerialCodeCreate from '@/client/components/twitter_applicant/serial_code_create'
import SerialCodeTable from '@/client/components/twitter_applicant/serial_code_table'
import { TrackingService } from '@/client/services'

export default {
  components: {
    SerialCodeTable,
    SerialCodeCreate
  },
  data() {
    return {
      visible: false,
      title: this.$gettext('シリアルコードの管理'),
      messageQueueId: 0,
      queueCreate: false,
      queuesTargets: false,
      queuesTargetsStatus: '',
      messageTemplates: [],
      isChangeWithoutSubmit: false,
      serialCodeCreate: false
    }
  },
  computed: {
    ...mapState('system', {
      currentGroup: state => state.currentGroup
    }),
    ...mapState('twitterSerialCodeDialog', {
      serialCodeListParams: state => state.serialCode.params,
      serialCodeIsCreated: state => state.serialCode.isCreated
    })
  },
  watch: {
    // シリアルコードパラメタを変更する際、シリアルコード一覧を取得
    serialCodeListParams() {
      this.fetchSerialCodeList()
    }
  },
  methods: {
    ...mapActions('twitterSerialCodeDialog', [
      'reset',
      'setPrizeId',
      'setCampaignId',
      'fetchSerialCodeList',
      'setSerialCodeListParams',
      'setSerialCodeIsCreated'
    ]),

    /**
     * シリアルコード一覧ソートを変更イベント
     * @param {string} sortBy ソート
     */
    onChangeSerialCodeListSortBy(sortBy: string) {
      if (this.serialCodeListParams.sortBy === sortBy) {
        return
      }
      this.setSerialCodeListParams({ sortBy })
    },

    /**
     * シリアルコード一覧
     */
    onChangeSerialCodeList() {
      this.fetchSerialCodeList()
    },

    /**
     * シリアルコード追加ボタンのクリックイベント
     */
    onClickSerialCodeCreate() {
      this.setSerialCodeIsCreated(true)
      this.serialCodeCreate = true
    },

    /**
     * ダイアログを開く
     * @param {number} キャンペーンID
     */
    open(prizeId: number, campaignId: number) {
      this.visible = true
      this.setPrizeId(prizeId)
      this.setCampaignId(campaignId)
      this.fetchSerialCodeList()

      if (this.$refs.serialCodeTable) {
        this.$refs.serialCodeTable.reset()
      }
    },

    /**
     * ダイアログを閉じる
     */
    close() {
      // 一括のメッセージストアをリセット
      this.serialCodeCreate = false
      this.isChangeWithoutSubmit = false
      this.reset()
    },

    /**
     * ダイアログ閉じるイベント
     */
    beforeClose(done) {
      TrackingService.sendEvent(
        `click:シリアルコードの管理|シルアルコード${this.serialCodeCreate ? '追加' : '管理'}|閉じる`
      )

      if (!this.isChangeWithoutSubmit) {
        this.close()
        return done()
      }

      const confirm = window.confirm(this.$gettext('編集内容が保存されていません。ダイアログを閉じてよろしいですか?'))

      if (!confirm) {
        return
      }

      this.close()
      done()
    }
  }
}
