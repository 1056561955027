/* @flow */
import api from '@/client/core/api'
import { type TCampaign, type TCampaignPrize } from '@/typings/api/publics/campaigns.type'
import {
  type TCampaignAnalytic,
  type TFollowerAnalytic,
  type TTwitterCampaignAnalyticsParams
} from '@/typings/api/publics/twitter_campaign_analytics.type'

// mutations type
const SET_CAMPAIGN_ANALYTICS = 'SET_CAMPAIGN_ANALYTICS'
const SET_FOLLOWER_ANALYTICS = 'SET_FOLLOWER_ANALYTICS'
const SET_CAMPAIGN = 'SET_CAMPAIGN'
const SET_PRIZES = 'SET_PRIZES'
const SET_PARAMS = 'SET_PARAMS'
const SET_ERROR = 'SET_ERROR'
const SET_LOADING = 'SET_LOADING'
const RESET = 'RESET'

type TState = {
  campaignAnalytics: TCampaignAnalytic[],
  followerAnalytics: TFollowerAnalytic[],
  campaign: TCampaign | null,
  prizes: TCampaignPrize[],
  params: TTwitterCampaignAnalyticsParams,
  error: boolean,
  loading: boolean
}

// ストアーcontext
type Context = {
  commit: Function,
  getters: any,
  state: TState,
  dispatch: Function
}

// init state
const initState: TState = {
  campaignAnalytics: [],
  followerAnalytics: [],
  campaign: null,
  prizes: [],
  params: {
    hash: '',
    prizeId: '',
    startDatetime: '',
    endDatetime: ''
  },
  error: false,
  loading: false
}

const state = Object.assign({}, initState)

// getters
const getters = {}

// actions
const actions = {
  /**
   * キャンペーン分析データを取得
   */
  async fetchCampaignAnalytics(context: Context) {
    const params = context.state.params

    context.commit(SET_ERROR, false)
    context.commit(SET_LOADING, true)

    // システムストアのローディングをセット
    context.dispatch('system/setLoading', true, { root: true })

    const result = await api.get('/publics/twitter_campaign_analytics', { params })

    context.commit(SET_LOADING, false)

    // システムストアのローディングをセット
    context.dispatch('system/setLoading', false, { root: true })

    if (!result.data) {
      context.commit(SET_ERROR, true)
      return
    }

    context.commit(SET_CAMPAIGN_ANALYTICS, result.data)
  },

  /**
   * フォロワー分析データを取得
   */
  async fetchFollowerAnalytics(context: Context) {
    context.commit(SET_ERROR, false)
    context.commit(SET_LOADING, true)

    // システムストアのローディングをセット
    context.dispatch('system/setLoading', true, { root: true })

    const params = {
      hash: context.state.params.hash,
      startDatetime: context.state.params.startDatetime,
      endDatetime: context.state.params.endDatetime
    }

    const result = await api.get('/publics/twitter_campaign_analytics/followers', { params })

    context.commit(SET_LOADING, false)

    // システムストアのローディングをセット
    context.dispatch('system/setLoading', false, { root: true })

    if (!result.data) {
      context.commit(SET_ERROR, true)
      return
    }

    context.commit(SET_FOLLOWER_ANALYTICS, result.data)
  },

  /**
   * キャンペーン情報の取得
   */
  async fetchCampaign(context: Context) {
    context.commit(SET_ERROR, false)

    const result = await api.get('/publics/campaigns/' + context.state.params.hash)

    if (!result.data) {
      context.commit(SET_ERROR, true)
      return
    }

    context.commit(SET_CAMPAIGN, result.data)
  },

  /**
   * 応募グループ一覧の取得
   */
  async fetchPrizes(context: Context) {
    context.commit(SET_ERROR, false)

    const result = await api.get(`/publics/campaigns/${context.state.params.hash}/prizes`)

    if (!result.data) {
      context.commit(SET_ERROR, true)
      return
    }

    context.commit(SET_PRIZES, result.data)
  },

  /**
   * パラメーターを設定
   */
  setParams(context: Context, params: TTwitterCampaignAnalyticsParams) {
    context.commit(SET_PARAMS, { ...context.state.params, ...params })
  },

  /**
   * ステータスを初期化にする
   */
  async reset(context: Context) {
    context.commit(RESET)
  }
}

// mutations
const mutations = {
  /**
   * キャンペーン分析データをセット
   */
  [SET_CAMPAIGN_ANALYTICS](state: TState, payload: TCampaignAnalytic[]) {
    state.campaignAnalytics = payload
  },

  /**
   * フォロワー分析データをセット
   */
  [SET_FOLLOWER_ANALYTICS](state: TState, payload: TFollowerAnalytic[]) {
    state.followerAnalytics = payload
  },

  /**
   * キャンペーン情報の取得
   */
  [SET_CAMPAIGN](state: TState, payload: TCampaign) {
    state.campaign = payload
  },

  /**
   * 応募グループ一覧の取得
   */
  [SET_PRIZES](state: TState, payload: TCampaignPrize[]) {
    state.prizes = payload
  },

  /**
   * パラメーターをセット
   */
  [SET_PARAMS](state: TState, payload: TTwitterCampaignAnalyticsParams) {
    state.params = payload
  },

  /**
   * エラーフラグをセット
   */
  [SET_ERROR](state: TState, payload: boolean) {
    state.error = payload
  },

  /**
   * ローディングフラグをセット
   */
  [SET_LOADING](state: TState, payload: boolean) {
    state.loading = payload
  },

  /**
   * ストアをリセット
   */
  [RESET](state: TState) {
    const initStateData: any = initState
    Object.keys(initStateData).forEach(key => {
      state[key] = initStateData[key]
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
