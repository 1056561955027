/* @flow */
import { mapActions, mapState } from 'vuex'

import api from '@/client/core/api'

export default {
  data() {
    return {
      password: '',
      qrcode_url: '',
      is_submit: false,
      visible: false,
      is_approval_failed: false
    }
  },
  computed: {
    ...mapState('system', {
      user: state => state.user
    }),

    isDisabled() {
      const regex = /\b\d{6}\b/g

      return !this.password.trim() || !this.password.match(regex) || this.password.length !== 6
    }
  },
  methods: {
    ...mapActions('system', {
      setSystemUser: 'setUser'
    }),

    /**
     * 2段階認証の設定をする
     */
    async checkTwoFactor() {
      if (this.is_submit) {
        return
      }

      const params = {
        code: this.password
      }

      this.is_submit = true

      const result = await api.post('v1/two_factor/approval', params)

      this.is_submit = false

      if (result && result.data) {
        this.visible = false

        // システムユーザーストアに変更
        const user = { ...this.user, isTwoFactor: 1 }

        this.setSystemUser(user)

        return this.$notify({
          title: this.$gettext('2段階認証を設定しました。'),
          customClass: 'success',
          duration: 5000
        })
      }

      if (result && result.error && result.error.type === 'PERMISSION_DENIED') {
        this.is_approval_failed = true
        return
      }

      this.is_approval_failed = false

      this.$notify({
        title: this.$gettext('2段階認証の設定に失敗しました。'),
        customClass: 'danger',
        duration: 5000
      })
    },

    /**
     * ダイアログをあく
     */
    async open() {
      this.is_approval_failed = false
      this.visible = true

      const result = await api.post('v1/two_factor')

      if (result && result.data) {
        this.qrcode_url = result.data.qrcode_url
      }
    },

    /**
     * データをリセットする
     */
    reset() {
      this.password = ''
      this.qrcode_url = ''
    },

    /**
     * ダイアログを閉じる
     */
    close() {
      this.reset()
      this.visible = false
    }
  }
}
