







import { Component, Vue } from 'vue-property-decorator'
import { mapActions } from 'vuex'

import DialogProfileKeywordSetting from '@/client/components/_organisms/DialogProfileKeywordSetting.vue'
import ProfileKeywordSettingTable from '@/client/components/_organisms/ProfileKeywordSettingTable.vue'
import { SettingGroupActions } from '@/client/store/setting_group.module'

@Component({
  components: {
    ProfileKeywordSettingTable,
    DialogProfileKeywordSetting
  },
  methods: mapActions('setting_group', ['fetchProfileKeywords'])
})
export default class ProfileKeywordSettingLayout extends Vue {
  fetchProfileKeywords!: SettingGroupActions['fetchProfileKeywords']

  $refs!: {
    DialogProfileKeywordSetting: DialogProfileKeywordSetting
  }

  async created() {
    await this.fetchProfileKeywords()
  }

  /**
   * キーワード判定セットの追加
   */
  onAdd() {
    this.$refs.DialogProfileKeywordSetting.open({ is_copy: false })
  }

  /**
   * プロファイルのキーワード設定を編集
   */
  onEdit(setting_id: number) {
    this.$refs.DialogProfileKeywordSetting.open({ setting_id, is_copy: false })
  }

  /**
   * プロファイルのキーワード設定をコピー
   */
  onCopy(setting_id: number) {
    this.$refs.DialogProfileKeywordSetting.open({ setting_id, is_copy: true })
  }
}
