/* @flow */
import { mapActions, mapState } from 'vuex'

import ApplicantName from '@/client/components/basic/applicant_name'
import Icon from '@/client/components/basic/icon'
import Pagination from '@/client/components/basic/pagination'
import ApplicantDuplicateEntries from '@/client/components/instagram_applicant/applicant_duplicate_entries'
import EntryHistoryDialog from '@/client/components/instagram_applicant/entry_history_dialog'
import Util from '@/client/core/util'
import { TrackingService } from '@/client/services'

export default {
  props: {
    applicants: Array,
    options: Object,
    campaignAccountId: {
      type: String,
      default: ''
    },
    campaignId: Number,
    campaignStartDatetime: String
  },
  components: {
    Pagination,
    ApplicantName,
    Icon,
    EntryHistoryDialog,
    ApplicantDuplicateEntries
  },
  data() {
    return {
      isSubmit: false
    }
  },
  computed: {
    ...mapState('system', {
      contractStatus: state => state.currentGroup.contractStatus
    })
  },
  methods: {
    ...mapActions('instagramApplicant', ['fetchApplicants']),

    /**
     * テキストをフォーマット
     * @param {string} text
     * @return {string} フォーマットしたテキスト
     */
    instagramText(text: string): string {
      return this.$options.filters.instagramText(text)
    },

    /**
     * ヘッダーをクリックするとソートする
     * @param {*} column
     */
    headerClick(column: any) {
      switch (column.label) {
        case 'アカウント':
          TrackingService.sendEvent('sort:応募一覧（IG）|アカウント')
          break
        case '応募日時/投稿内容':
          TrackingService.sendEvent('sort:応募一覧（IG）|応募日時/投稿内容')
          break
        case 'フォロワー':
          TrackingService.sendEvent('sort:応募一覧（IG）|フォロワー')
          break
        case '応募数':
          TrackingService.sendEvent('sort:応募一覧（IG）|応募数')
          break
        case '累計参加数':
          TrackingService.sendEvent('sort:応募一覧（IG）|累計参加数')
          break
        case '累計当選数':
          TrackingService.sendEvent('sort:応募一覧（IG）|累計当選数')
          break
        default:
          break
      }

      if (!column.sortBy) {
        return
      }

      if (column.sortBy.indexOf('-') === 0 && this.options.sortBy === column.sortBy) {
        this.$emit('sorting', column.sortBy.substring(1))
        this.fetchApplicants()
        return
      }

      if (column.sortBy.indexOf('-') !== 0 && this.options.sortBy === column.sortBy) {
        this.$emit('sorting', `-${column.sortBy}`)
        this.fetchApplicants()
        return
      }

      this.$emit('sorting', column.sortBy)
      this.fetchApplicants()
    },

    /**
     * ヘッダー描画
     * @param {*} createElement
     * @param {*} { column }
     */
    renderHeader(createElement: any, { column }: any) {
      return Util.renderHeader(createElement, column.label, Util.getSortClass(this.options.sortBy, column.sortBy))
    },

    /**
     * 対象応募者のフラグの変更
     */
    changeFlag(applicantId: number, type: string, value: number) {
      switch (type) {
        case 'is_follower':
          TrackingService.sendEvent('click:応募一覧（IG）|フォローに追加')
          break
        case 'is_candidate':
          TrackingService.sendEvent('click:応募一覧（IG）|候補者に追加')
          break
        case 'is_winner':
          TrackingService.sendEvent('click:応募一覧（IG）|当選者に追加')
          break
        case 'is_responder':
          TrackingService.sendEvent('click:応募一覧（IG）|回答者に追加')
          break
        case 'is_winner_contact':
          TrackingService.sendEvent('click:応募一覧（IG）|当選連絡に追加')
          break
        default:
          break
      }

      value = value === 1 ? 0 : 1
      const payload = { applicantId, type, value }

      this.$emit('change_flag', payload)
    },

    /**
     * ページング変更イベント
     */
    onChangePagingNo(page: number) {
      this.$emit('paging', { pagingNo: page })

      this.fetchApplicants()
    },

    /**
     * 表示件数変更イベント
     */
    onChangeLimitCount(limit: number) {
      this.$emit('paging', { limitCount: limit })

      this.fetchApplicants()
    },

    openApplicantDuplicateEntries(screenName: string, duplicateEntries: any) {
      TrackingService.sendEvent('click:応募一覧（IG）|応募数')

      this.$refs.applicantDuplicateEntries.open(screenName, duplicateEntries)
    },

    openEntryHistoryDialog(
      screenName: string,
      campaignAccountId: number,
      accountId: number,
      number: number,
      campaignId: number,
      campaignStartDatetime: string
    ) {
      TrackingService.sendEvent(`click:応募一覧（IG）|${number === 0 ? '累計参加数' : '累計当選数'}`)

      this.$refs.entryHistoryDialog.open(
        screenName,
        campaignAccountId,
        accountId,
        number,
        campaignId,
        campaignStartDatetime
      )
    },

    clickTwitterConfirmation() {
      TrackingService.sendEvent('click:応募一覧（IG）|Instagramで確認')
    }
  }
}
