/* @flow */
import { mapActions, mapState } from 'vuex'

import SettingUser from '@/client/components/setting_user'

export default {
  components: {
    SettingUser
  },
  created() {
    const isGuest = this.guestGroups.some(v => v.id === this.currentGroupId)

    this.setBreadcrumb([
      {
        name: !isGuest ? this.$gettext('キャンペーン一覧') : '',
        link: !isGuest ? '/campaigns' : null,
        iconUrl: null,
        iconType: null
      },
      {
        name: !isGuest ? this.$gettext('ユーザー設定') : '',
        link: null,
        iconUrl: null,
        iconType: null
      }
    ])
  },
  computed: {
    ...mapState('system', {
      currentGroupId: state => state.currentGroup.id,
      guestGroups: state => state.guestGroups
    })
  },
  methods: {
    ...mapActions('navbar', ['setBreadcrumb'])
  },
  beforeRouteLeave(to: any, from: any, next: any) {
    // 離脱チェック
    this.$refs.settingUser.checkBeforeLeave((err, isExit) => {
      if (err) {
        isExit = false
      }
      next(isExit)
    })
  }
}
