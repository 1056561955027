/* @flow */
import Papa from 'papaparse'
export default {
  props: {
    download: Function,
    disabled: {
      default: false,
      type: Boolean
    },
    isOneTimeDownloadable: {
      default: false,
      type: Boolean
    },
    text: {
      default: 'CSVダウンロード',
      type: String
    }
  },
  data() {
    return {
      isDisabled: this.disabled
    }
  },
  watch: {
    disabled() {
      this.isDisabled = this.disabled
    }
  },
  methods: {
    async downloadClick() {
      if (typeof this.download !== 'function') {
        return
      }
      if (this.isDisabled) {
        return
      }
      this.isDisabled = true
      const csvData = await this.download()
      if (!csvData) {
        this.isDisabled = false
        return
      }
      if (csvData.error) {
        this.$notify({
          title: this.$gettext('CSVダウンロードに失敗しました。'),
          message: this.$gettext('恐れ入りますが、時間をおいて再度お試しください。'),
          customClass: 'danger',
          duration: 5000
        })
        this.isDisabled = false
        return
      }
      if (!csvData.data || !csvData.filename) {
        this.isDisabled = false
        return
      }
      let filename = csvData.filename
      if (filename.indexOf('.csv') === -1) {
        filename = filename + '.csv'
      }

      const csvString = Papa.unparse(csvData.data)
      const blob = new Blob(['\ufeff', csvString])
      if (window.navigator.msSaveBlob) {
        // IE 10+
        window.navigator.msSaveBlob(blob, filename)
      } else {
        const link = document.createElement('a')
        if (link.download !== undefined) {
          // feature detection
          // Browsers that support HTML5 download attribute
          const url = URL.createObjectURL(blob)
          link.setAttribute('href', url)
          link.setAttribute('download', filename)
          link.style.visibility = 'hidden'
          window.document.body.appendChild(link)
          link.click()
          window.document.body.removeChild(link)
        }
      }
      if (!this.isOneTimeDownloadable) {
        this.isDisabled = false
      }
    }
  }
}
