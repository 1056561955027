export const WEB_INSTANTWIN_MEDIA_LOADING_URL =
  'https://atelu-assets.s3.ap-northeast-1.amazonaws.com/web-instantwin/during_the_lottery.gif'
export const WEB_INSTANTWIN_MEDIA_WIN_URL =
  'https://atelu-assets.s3.ap-northeast-1.amazonaws.com/web-instantwin/win.gif'
export const WEB_INSTANTWIN_MEDIA_LOSE_URL =
  'https://atelu-assets.s3.ap-northeast-1.amazonaws.com/web-instantwin/lose.gif'

export const WEB_INSTANTWIN_DEFAULT_WIN_MESSAGE =
  'この度は、プレゼントキャンペーンにご応募いただき誠にありがとうございました。\n抽選の結果、お客様はご当選されました！\nおめでとうございます！'
export const WEB_INSTANTWIN_DEFAULT_LOSE_MESSAGE =
  'この度は、プレゼントキャンペーンにご応募いただき誠にありがとうございました。\n抽選の結果、残念ながら落選となりました。\n\n本キャンペーンは、毎日応募が可能です。\nぜひ明日もご応募ください！\n\n今後とも、××をよろしくお願いいたします。'
export const WEB_INSTANTWIN_DEFAULT_LOADING_MESSAGE = '抽選を行なっています。\nこのままお待ちください。'

export const WEB_INSTANTWIN_IMAGE_MEDIA_STATUS = 0 as const
export const WEB_INSTANTWIN_VIDEO_MEDIA_STATUS = 1 as const

export const WEB_INSTANTWIN_LOSE_STATUS = 0 as const
export const WEB_INSTANTWIN_WIN_STATUS = 1 as const
