


































































































import moment from 'moment-timezone'
import { Component, Emit, Vue } from 'vue-property-decorator'
import { mapActions, mapGetters, mapState } from 'vuex'

import Message from '@/client/components/_atoms/Message.vue'
import Panel from '@/client/components/_atoms/Panel.vue'
import Tips from '@/client/components/_atoms/Tips.vue'
import FormItemLabel from '@/client/components/_molecules/FormItemLabel.vue'
import { CAMPAIGN_STATUS } from '@/client/constant'
import { TrackingService } from '@/client/services'
import {
  CampaignDialogActions,
  CampaignDialogGetters,
  CampaignDialogState
} from '@/client/store/campaign_dialog.module'

@Component({
  components: {
    FormItemLabel,
    Message,
    Panel,
    Tips
  },
  computed: {
    ...mapState('campaign_dialog', ['form', 'mode', 'api_campaign', 'is_max_concurrent_campaign']),
    ...mapGetters('campaign_dialog', [
      'is_start_datetime_error',
      'is_campaign_period_empty',
      'is_end_datetime_before_start_datetime',
      'is_end_date_time_before_now'
    ])
  },
  methods: mapActions('campaign_dialog', ['setForm', 'checkCampaign'])
})
export default class CampaignCreatePeriod extends Vue {
  api_campaign!: CampaignDialogState['api_campaign']
  form!: CampaignDialogState['form']
  mode!: CampaignDialogState['mode']
  is_max_concurrent_campaign!: CampaignDialogState['is_max_concurrent_campaign']
  is_start_datetime_error!: CampaignDialogGetters['is_start_datetime_error']
  is_campaign_period_empty!: CampaignDialogGetters['is_campaign_period_empty']
  is_end_datetime_before_start_datetime!: CampaignDialogGetters['is_end_datetime_before_start_datetime']
  is_end_date_time_before_now!: CampaignDialogGetters['is_end_date_time_before_now']
  setForm!: CampaignDialogActions['setForm']
  checkCampaign!: CampaignDialogActions['checkCampaign']

  get is_start_datetime_disabled() {
    if (!this.api_campaign || this.mode === 'create' || this.mode === 'copy') {
      return false
    }

    return this.api_campaign.status !== CAMPAIGN_STATUS.BEFORE_EVENT
  }

  get is_end_datetime_disabled() {
    if (!this.api_campaign || this.mode === 'create' || this.mode === 'copy') {
      return false
    }

    return [CAMPAIGN_STATUS.DURING_FINAL_COLLECTION, CAMPAIGN_STATUS.COLLECTION_COMPLETED].includes(
      this.api_campaign.status
    )
  }

  get start_date_picker_options() {
    return {
      disabledDate: date => {
        if (this.form.end_date) {
          return (
            moment(date.getTime()).isBefore(moment().startOf('day')) ||
            moment(date.getTime()).isAfter(moment(this.form.end_date))
          )
        }
        return moment(date.getTime()).isBefore(moment().startOf('day'))
      }
    }
  }

  get end_date_picker_options() {
    return {
      disabledDate: date => {
        if (this.form.start_date) {
          return (
            moment(date.getTime()).isBefore(moment().startOf('day')) ||
            moment(date.getTime()).isBefore(moment(this.form.start_date))
          )
        }
        return moment(date.getTime()).isBefore(moment().startOf('day'))
      }
    }
  }

  get time_picker_options() {
    return {
      start: '00:00',
      step: '01:00',
      end: '23:00'
    }
  }

  get is_campaign_max_over() {
    if (!this.form.start_date || !this.form.start_time || !this.form.end_date || !this.form.end_time) {
      return false
    }

    return this.is_max_concurrent_campaign
  }

  get is_twitter() {
    return this.form.target_sns === 'twitter'
  }

  get is_instagram() {
    return this.form.target_sns === 'instagram'
  }

  get is_tiktok() {
    return this.form.target_sns === 'tiktok'
  }

  @Emit('cancel')
  cancel(payload) {
    return payload
  }

  /**
   * 開始日の変更
   */
  async changeStartDate(value: string) {
    TrackingService.sendEvent('select:キャンペーンの新規作成|期間|開始日')

    await this.setForm({
      ...this.form,
      start_date: value
    })

    await this.checkIsMaxConcurrentCampaign()
  }

  /**
   * 開始時間の変更
   */
  async changeStartTime(value: string) {
    TrackingService.sendEvent('select:キャンペーンの新規作成|期間|開始時間')

    await this.setForm({
      ...this.form,
      start_time: value
    })

    await this.checkIsMaxConcurrentCampaign()
  }

  /**
   * 終了日の変更
   */
  async changeEndDate(value: string) {
    TrackingService.sendEvent('select:キャンペーンの新規作成|期間|終了日')

    await this.setForm({
      ...this.form,
      end_date: value
    })

    await this.checkIsMaxConcurrentCampaign()
  }

  /**
   * 終了時間の変更
   */
  async changeEndTime(value: string) {
    TrackingService.sendEvent('select:キャンペーンの新規作成|期間|終了時間')

    await this.setForm({
      ...this.form,
      end_time: value
    })

    await this.checkIsMaxConcurrentCampaign()
  }

  /**
   * 同時開催数上限を超えることをチェック
   */
  async checkIsMaxConcurrentCampaign() {
    if (
      this.is_start_datetime_error ||
      this.is_campaign_period_empty ||
      this.is_end_datetime_before_start_datetime ||
      this.is_end_date_time_before_now ||
      this.form.campaign_type === 'web_instantwin' ||
      this.mode !== 'edit'
    ) {
      return
    }

    const payload = {
      start_datetime: `${this.form.start_date} ${this.form.start_time}`,
      end_datetime: `${this.form.end_date} ${this.form.end_time}`,
      exclude_campaign_id: this.mode === 'edit' && this.api_campaign ? this.api_campaign.id : 0
    }

    await this.checkCampaign(payload)
  }
}
