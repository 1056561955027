













































import { isValidHashtag } from 'twitter-text'
import { Component, Vue } from 'vue-property-decorator'
import { mapActions, mapState } from 'vuex'

import Panel from '@/client/components/_atoms/Panel.vue'
import Tags from '@/client/components/_molecules/Tags.vue'
import { TIKTOK_ENTRY_ACTION_TYPES } from '@/client/constant'
import { CAMPAIGN_STATUS } from '@/client/constant'
import { TrackingService } from '@/client/services'
import { CampaignDialogActions, CampaignDialogState } from '@/client/store/campaign_dialog.module'

@Component({
  components: {
    Panel,
    Tags
  },
  computed: mapState('campaign_dialog', ['form', 'api_campaign', 'mode']),
  methods: mapActions('campaign_dialog', ['setForm'])
})
export default class CampaignCreateTikTokCondition extends Vue {
  api_campaign!: CampaignDialogState['api_campaign']
  form!: CampaignDialogState['form']
  mode!: CampaignDialogState['mode']
  setForm!: CampaignDialogActions['setForm']

  get campaign_type_options() {
    return TIKTOK_ENTRY_ACTION_TYPES.label
  }

  created() {
    const form = {
      ...this.form,
      campaign_type: 'keyword',
      entry_action_type: TIKTOK_ENTRY_ACTION_TYPES.value
    }

    this.setForm(form)
  }

  async changeKeywords(value: string[]) {
    if (this.form.keywords?.length < value.length) {
      TrackingService.sendEvent('click:キャンペーンの新規作成|応募条件(TikTok)|全てを含む|追加')
    } else TrackingService.sendEvent('click:キャンペーンの新規作成|応募条件(TikTok)|全てを含む|削除')

    const is_valid = this.checkoutValidKeyword(value)

    if (!is_valid) {
      return
    }

    const form = {
      ...this.form,
      keywords: value
    }

    await this.setForm(form)
  }

  checkoutValidKeyword(keywords: string[]) {
    const is_valid = keywords.every(keyword => isValidHashtag(keyword))

    if (!is_valid) {
      this.$notify({
        title: 'ハッシュタグに利用できない文字列が含まれています。',
        message: '',
        customClass: 'danger'
      })
    }

    return is_valid
  }

  get is_condition_disabled() {
    return (
      this.api_campaign &&
      [CAMPAIGN_STATUS.DURING_FINAL_COLLECTION, CAMPAIGN_STATUS.COLLECTION_COMPLETED].includes(
        this.api_campaign.status
      ) &&
      this.mode === 'edit'
    )
  }

  get keywords() {
    return this.form.keywords
  }
}
