/* @flow */
import { mapActions, mapState } from 'vuex'

import ConfirmDialog from '@/client/components/basic/confirm_dialog'
import SettingUserTwoFactorDialog from '@/client/components/setting_user/setting_user_two_factor_dialog'
import api from '@/client/core/api'
import { TrackingService } from '@/client/services'

export default {
  components: {
    ConfirmDialog,
    SettingUserTwoFactorDialog
  },
  data() {
    return {
      is_submit: false
    }
  },
  computed: {
    ...mapState('system', {
      user: state => state.user
    })
  },
  methods: {
    ...mapActions('system', {
      setSystemUser: 'setUser'
    }),

    /**
     * 2段階ユーザー設定ダイアログを開く
     */
    openTwoFactorDialog() {
      TrackingService.sendEvent('click:ユーザー設定|2段階認証を有効化')

      this.$refs.settingUserTwoFactorDialog.open()
    },

    /**
     * 2段階認証を解除する
     */
    removeTwoFactor() {
      if (this.is_submit) {
        return
      }

      this.$refs.confirmDialog.open(
        this.$gettext('2段階認証を解除してよろしいですか？'),
        '',
        'default',
        async confirm => {
          if (!confirm) {
            return
          }

          this.is_submit = true

          // ユーザー設定を変更
          const result = await api.delete('v1/two_factor')

          this.is_submit = false

          if (result && result.data) {
            // システムユーザーストアに変更
            const user = { ...this.user, isTwoFactor: 0 }

            this.setSystemUser(user)

            this.$notify({
              title: this.$gettext('2段階認証を解除しました。'),
              customClass: 'success',
              duration: 5000
            })

            return
          }

          // エラーメッセージを表示
          this.$notify({
            title: this.$gettext('2段階認証の解除に失敗しました。'),
            customClass: 'danger',
            duration: 5000
          })
        }
      )
    }
  }
}
