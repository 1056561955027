/* @flow */
import api from '@/client/core/api'
import { type TCampaign } from '@/typings/api/campaigns.type'
import {
  type TGetMessageHistoryParams,
  type TGetMessageHistoryResponse,
  type TMessageHistoryMessage,
  type TPutMessageHistoryParams
} from '@/typings/api/twitter_instantwin_message_history.type'

// mutations type
const RESET = 'RESET'
const SET_MESSAGE_HISTORY_LIST = 'SET_MESSAGE_HISTORY_LIST'
const SET_MESSAGE_HISTORY_PARAM = 'SET_MESSAGE_HISTORY_PARAM'
const SET_MESSAGE_HISTORY_LOADED = 'SET_MESSAGE_HISTORY_LOADED'
const SET_CAMPAIGN = 'SET_CAMPAIGN'
const UPDATE_RESPONDER = 'UPDATE_RESPONDER'
const SET_MESSAGES = 'SET_MESSAGES'
const SET_ERROR = 'SET_ERROR'

type MessageHistoryStore = {
  loaded: boolean,
  campaign: TCampaign | null,
  messages: { id: number, name: string, messageType: string }[],
  histories: TMessageHistoryMessage[],
  total: number,
  params: TGetMessageHistoryParams,
  error: boolean
}
// ストアーcontext
type Context = {
  commit: Function,
  getters: any,
  state: MessageHistoryStore,
  dispatch: Function
}

// init state
const initState: MessageHistoryStore = {
  loaded: false,
  total: 0,
  campaign: null,
  messages: [],
  histories: [],
  params: {
    campaignId: 0,
    sortBy: '-entryDatetime',
    filterType: null,
    filterMessage: null,
    filterLoseReason: null,
    filterResponder: -1,
    pagingNo: 1,
    limitCount: 25
  },
  error: false
}
const state = Object.assign({}, initState)

// getters
const getters = {}

// actions
const actions = {
  /**
   * ストアをリセット
   * @param {Context} context
   */
  async resetMessageHistory(context: Context) {
    context.commit(RESET)
  },

  /**
   * キャンペーンデータを取得
   * @param {Context} context
   */
  async fetchCampaign(context: Context) {
    const result = await api.get('/campaigns/' + context.state.params.campaignId)
    if (result.data) {
      context.commit(SET_CAMPAIGN, result.data)
    }
  },

  /**
   * Get history list
   * @param {Context} context
   */
  async fetchHistoryList(context: Context) {
    context.dispatch('system/setLoading', true, { root: true })
    context.commit(SET_ERROR, false)
    const result = await api.get('/twitter_instantwin_message_histories', {
      params: context.state.params
    })
    context.commit(SET_MESSAGE_HISTORY_LOADED, true)
    context.dispatch('system/setLoading', false, { root: true })
    if (!result.data) {
      context.commit(SET_ERROR, true)
      return
    }
    context.commit(SET_MESSAGE_HISTORY_LIST, result)
  },

  /**
   * メッセージ一覧を取得する
   * @param {Context} context
   */
  async fetchMessages(context: Context, params: { campaignId: number }) {
    const result = await api.get('/twitter_instantwin_messages', {
      params
    })

    // データがない場合はエラーフラグをセット
    if (!result.data) {
      return
    }

    // メッセージ一覧をセット
    context.commit(SET_MESSAGES, result.data.messages)
  },

  /**
   * Set parameters for MESSAGE history
   * @param {Context} context
   * @param {TGetMessageHistoryParams} params パラメーター
   */
  setHistoryParams(context: Context, params: TGetMessageHistoryParams) {
    if (!params.pagingNo) {
      params.pagingNo = 1
    }
    context.commit(SET_MESSAGE_HISTORY_PARAM, Object.assign({}, context.state.params, params))
  },

  /**
   * 対象応募者の回答者フラグの変更
   * @param {Context} context
   * @param {TPutMessageHistoryParams} payload 応募者パラメーター
   */
  async updateResponder(context: Context, payload: TPutMessageHistoryParams) {
    const result = await api.put('/twitter_instantwin_applicants/' + payload.applicantId, {
      isResponder: payload.isResponder
    })

    if (result.data) {
      context.commit(UPDATE_RESPONDER, payload)
    }

    if (context.state.messages.length === 0) {
      context.dispatch('fetchHistoryList')
    }

    return result
  }
}

// mutations
const mutations = {
  /**
   * ストアをリセット
   * @param {MessageHistoryStore} state
   */
  [RESET](state: MessageHistoryStore) {
    const initStateData: any = initState
    Object.keys(initStateData).forEach(key => {
      state[key] = initStateData[key]
    })
  },

  /**
   * 応募者データをセット
   * @param {MessageHistoryStore} state
   * @param {TCampaign} campaign キャンペーンデータ
   */
  [SET_CAMPAIGN](state: MessageHistoryStore, campaign: TCampaign) {
    state.campaign = campaign
  },

  /**
   * Set message history parameter
   * @param {MessageHistoryStore} state
   * @param {TGetMessageHistoryParams} params パラメーター
   */
  [SET_MESSAGE_HISTORY_PARAM](state: MessageHistoryStore, params: TGetMessageHistoryParams) {
    state.params = params
  },

  /**
   * Set history list
   * @param {MessageHistoryStore} state
   * @param {TGetMessageHistoryResponse} messageHistory messageHistory list
   */
  [SET_MESSAGE_HISTORY_LIST](state: MessageHistoryStore, messageHistory: TGetMessageHistoryResponse) {
    state.histories = messageHistory.data.histories
    if (state.params.pagingNo === 1) {
      state.total = messageHistory.data.dataCount
    }
  },

  /**
   * Set message list
   * @param  state
   * @param  messages メッセージ一覧
   */
  [SET_MESSAGES](state: MessageHistoryStore, messages: { id: number, name: string, messageType: string }[]) {
    state.messages = messages.map(message => {
      return { id: message.id, name: message.name, messageType: message.messageType }
    })
  },

  /**
   * Set loaded flag
   * @param {MessageHistoryStore} state
   * @param {boolean} loaded ロードされたフラグ
   */
  [SET_MESSAGE_HISTORY_LOADED](state: MessageHistoryStore, loaded: boolean) {
    state.loaded = loaded
  },

  /**
   * 回答者を更新
   * @param {MessageHistoryStore} state
   * @param {TPutMessageHistoryParams} payload
   */
  [UPDATE_RESPONDER](state: MessageHistoryStore, payload: TPutMessageHistoryParams) {
    state.histories.forEach((history, index) => {
      if (history.applicant.id === payload.applicantId) {
        history.applicant.isResponder = payload.isResponder
        return true
      }
    })

    if (state.campaign && payload.isResponder === 1) {
      state.campaign.responderCount++
    }

    if (state.campaign && payload.isResponder === 0) {
      state.campaign.responderCount--
    }
  },

  /**
   * エラーフラグをセット
   * @param {MessageHistoryStore} state
   * @param {boolean} error エラーフラグ
   */
  [SET_ERROR](state: MessageHistoryStore, error: boolean) {
    state.error = error
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
