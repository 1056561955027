/* @flow */
import api from '@/client/core/api'
import { type TCampaign } from '@/typings/api/campaigns.type'
import {
  type TCampaignInfo,
  type TGetInstantwinMessagesParams as Params,
  type TInstantwinMessage
} from '@/typings/api/twitter_instantwin_messages.type'

const SET_INSTANTWIN_MESSAGE_DATA = 'SET_INSTANTWIN_MESSAGE_DATA'
const SET_IS_LOADING_INSTANTWIN_MESSAGES = 'SET_IS_LOADING_INSTANTWIN_MESSAGES'
const SET_IS_LOADING_CAMPAIGN = 'SET_IS_LOADING_CAMPAIGN'
const SET_PARAMS = 'SET_PARAMS'
const SET_ERROR = 'SET_ERROR'
const RESET = 'RESET'
const SET_CAMPAIGN = 'SET_CAMPAIGN'

type TInstantwinMessageStore = {
  messages: TInstantwinMessage[],
  campaignInfo: TCampaignInfo,
  total: number,
  error: any,
  isLoadingInstantwinMessages: boolean,
  isLoadingCampaign: boolean,
  campaign: TCampaign | null,
  params: {
    campaignId: number,
    sortBy: string,
    pagingNo: number,
    limitCount: number
  }
}

// ストアーcontext
type Context = {
  commit: Function,
  getters: any,
  state: TInstantwinMessageStore,
  dispatch: Function
}

// getters
const getters = {}

// init state
const initState: TInstantwinMessageStore = {
  messages: [],
  total: 0,
  isLoadingInstantwinMessages: true,
  isLoadingCampaign: true,
  params: {
    campaignId: 0,
    sortBy: '-status',
    pagingNo: 1,
    limitCount: 25
  },
  campaign: null,
  error: null,
  campaignInfo: {
    totalApplicantCount: 0,
    totalEntryCount: 0,
    totalWinnerCount: 0,
    totalLotteryCount: 0,
    currentWinnerCount: 0
  }
}
const state = Object.assign({}, initState)

// actions
const actions = {
  async fetchInstantwinMessages(context: Context) {
    context.commit(SET_ERROR, false)
    // システムストアのローディングをセット
    context.dispatch('system/setLoading', true, { root: true })
    context.commit(SET_IS_LOADING_INSTANTWIN_MESSAGES, true)
    const result = await api.get('/twitter_instantwin_messages', {
      params: context.state.params
    })

    // システムストアのローディングをセット
    context.dispatch('system/setLoading', false, { root: true })
    context.commit(SET_IS_LOADING_INSTANTWIN_MESSAGES, false)
    // データがない場合はエラーフラグをセット
    if (!result.data) {
      context.commit(SET_ERROR, true)
      return
    }
    // キャンペーン一覧をセット
    context.commit(SET_INSTANTWIN_MESSAGE_DATA, {
      messages: result.data.messages,
      total: result.data.total,
      campaignInfo: result.data.campaignInfo
    })
  },

  /**
   * パラメーターをセット
   * @param {Context} context
   * @param {TGetInstantwinMessagesParams} params パラメーター
   */
  setParams(context: Context, params: Params) {
    if (!params.pagingNo) {
      params.pagingNo = 1
    }
    context.commit(SET_PARAMS, Object.assign({}, context.state.params, params))
  },

  /**
   * キャンペーンデータを取得
   * @param {Context} context
   */
  async fetchCampaign(context: Context) {
    context.commit(SET_IS_LOADING_CAMPAIGN, true)
    const result = await api.get('/campaigns/' + context.state.params.campaignId)
    if (result.data) {
      context.commit(SET_CAMPAIGN, result.data)
    }

    context.commit(SET_IS_LOADING_CAMPAIGN, false)
  },

  /**
   * ストアをリセット
   * @param {Context} context
   */
  async reset(context: Context) {
    context.commit(RESET)
  }
}

// mutations
const mutations = {
  [SET_INSTANTWIN_MESSAGE_DATA](
    state: TInstantwinMessageStore,
    data: { messages: TInstantwinMessage[], total: number, campaignInfo: TCampaignInfo }
  ) {
    state.messages = data.messages
    state.campaignInfo = data.campaignInfo
    state.total = data.total
  },

  [SET_IS_LOADING_INSTANTWIN_MESSAGES](state: TInstantwinMessageStore, isLoadingInstantwinMessages: boolean) {
    state.isLoadingInstantwinMessages = isLoadingInstantwinMessages
  },

  [SET_IS_LOADING_CAMPAIGN](state: TInstantwinMessageStore, isLoadingCampaign: boolean) {
    state.isLoadingCampaign = isLoadingCampaign
  },

  [SET_ERROR](state: TInstantwinMessageStore, error: boolean) {
    state.error = error
  },

  [SET_PARAMS](state: TInstantwinMessageStore, params: Params) {
    state.params = params
  },

  /**
   * 応募者データをセット
   * @param {ApplicantStore} state
   * @param {TCampaign} campaign キャンペーンデータ
   */
  [SET_CAMPAIGN](state: TInstantwinMessageStore, campaign: TCampaign) {
    state.campaign = campaign
  },

  /**
   * ストアをリセット
   * @param {CampaignStore} state
   */
  [RESET](state: TInstantwinMessageStore) {
    const initStateData: any = initState
    Object.keys(initStateData).forEach(key => {
      state[key] = initStateData[key]
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
