import { COUNTRIES } from '@/client/constant'

/**
 * 国名を日本語で表示するフィルター
 */
export function filterCountryName(input: string): string {
  const value = COUNTRIES.find(v => v.value === input)

  return value ? value.label : ''
}
