/* @flow */
import api from '@/client/core/api'
import { type TCampaign } from '@/typings/api/campaigns.type'
import { type TPutCandidateParams, type TPutWinnerParams } from '@/typings/api/instagram_applicants.type'
import { type TPrize } from '@/typings/api/instagram_campaign_prizes.type'
import {
  type TGetInstagramPostsParams,
  type TInstagramPost,
  type TPutInstagramPostsParams
} from '@/typings/api/instagram_posts.type'

const SET_POSTS = 'SET_POSTS'
const SET_PARAMS = 'SET_PARAMS'
const SET_CAMPAIGN = 'SET_CAMPAIGN'
const SET_PRIZES = 'SET_PRIZES'
const SET_ENTRY_COUNT = 'SET_ENTRY_COUNT'
const SET_ERROR = 'SET_ERROR'
const SET_LOADING = 'SET_LOADING'
const SET_FIRST_LOADED = 'SET_FIRST_LOADED'
const RESET = 'RESET'

type TInstagramPostStore = {
  posts: TInstagramPost[],
  total: number,
  entryCount: number,
  campaign: TCampaign | null,
  params: TGetInstagramPostsParams,
  prizes: TPrize[],
  error: boolean,
  loading: boolean,
  firstLoaded: boolean
}

type Context = {
  commit: Function,
  getters: any,
  state: TInstagramPostStore,
  dispatch: Function
}

const initState: TInstagramPostStore = {
  posts: [],
  total: 0,
  entryCount: 0,
  campaign: null,
  params: {
    campaignId: 0,
    sortBy: '-publishDatetime',
    pagingNo: 1,
    limitCount: 25,
    prizeId: '',
    isFollowing: '',
    isCandidate: '',
    isWidget: '',
    isWinner: '',
    accountName: ''
  },
  prizes: [],
  error: false,
  loading: false,
  firstLoaded: false
}

// state
const state = { ...initState }

// getters
const getters = {}

// actions
const actions = {
  /**
   * 応募履歴一覧の取得
   * @param {Context} context
   */
  async fetchPosts(context: Context) {
    context.commit(SET_ERROR, false)
    context.commit(SET_LOADING, true)

    context.dispatch('system/setLoading', true, { root: true })

    const result = await api.get('/instagram_posts', {
      params: context.state.params
    })

    context.dispatch('system/setLoading', false, { root: true })

    context.commit(SET_LOADING, false)

    if (!result.data) {
      context.commit(SET_ERROR, true)
      return
    }

    if (!context.state.firstLoaded) {
      context.commit(SET_FIRST_LOADED, true)
      context.commit(SET_ENTRY_COUNT, result.data.dataCount)
    }

    context.commit(SET_POSTS, {
      posts: result.data.posts,
      total: result.data.dataCount
    })
  },

  /**
   * キャンペーン情報の取得
   * @param {Context} context
   */
  async fetchCampaign(context: Context) {
    context.commit(SET_ERROR, false)

    const result = await api.get(`/campaigns/${context.state.params.campaignId}`)

    if (!result.data) {
      context.commit(SET_ERROR, true)
      return
    }

    context.commit(SET_CAMPAIGN, result.data)
  },

  /**
   * 応募グループ一覧の取得
   * @param {Context} context
   */
  async fetchPrizes(context: Context) {
    context.commit(SET_ERROR, false)

    const result = await api.get('/instagram_campaign_prizes', {
      params: {
        campaignId: context.state.params.campaignId,
        limitCount: -1
      }
    })

    if (!result.data) {
      context.commit(SET_ERROR, true)
      return
    }

    context.commit(SET_PRIZES, result.data.prizes)
  },

  /**
   * パラメーターを更新
   * @param {Context} context
   * @param {TGetInstagramPostsParams} params
   */
  setParams(context: Context, params: TGetInstagramPostsParams) {
    if (!params.pagingNo) {
      params.pagingNo = 1
    }

    context.commit(SET_PARAMS, { ...context.state.params, ...params })
  },

  /**
   * ストアをリセット
   * @param {Context} context
   */
  async reset(context: Context) {
    context.commit(RESET)
  },

  /**
   * ウイジェットを変更
   */
  async updateIsWidget(context: Context, payload: TPutInstagramPostsParams) {
    const result = await api.put('/instagram_posts/' + payload.postId, {
      campaignId: context.state.params.campaignId,
      isWidget: payload.isWidget ? 0 : 1
    })

    if (result.data) {
      await context.dispatch('fetchPosts')
    }

    return result
  },

  /**
   * 候補者フラグを変更
   */
  async updateIsCandidate(context: Context, payload: TPutCandidateParams) {
    const result = await api.put('/instagram_applicants/' + payload.applicantId, {
      isCandidate: payload.isCandidate ? 0 : 1
    })

    if (result.data) {
      await context.dispatch('fetchPosts')
    }

    return result
  },

  /**
   * 当選者フラグを変更
   */
  async updateIsWinner(context: Context, payload: TPutWinnerParams) {
    const result = await api.put('/instagram_applicants/' + payload.applicantId, {
      prizeId: payload.prizeId,
      isWinner: payload.isWinner ? 0 : 1,
      wasWinner: payload.wasWinner
    })

    if (result.data) {
      await context.dispatch('fetchPosts')
    }

    return result
  }
}

// mutations
const mutations = {
  /**
   * ストアをリセット
   * @param {TInstagramPostStore} state
   */
  [RESET](state: TInstagramPostStore) {
    const initStateData: any = initState

    Object.keys(initStateData).forEach(key => {
      state[key] = initStateData[key]
    })
  },

  /**
   * 応募履歴一覧の取得
   * @param {TInstagramPostStore} state
   * @param {{posts: TInstagramPost[], total: number}} payload
   */
  [SET_POSTS](state: TInstagramPostStore, payload: { posts: TInstagramPost[], total: number }) {
    state.posts = payload.posts

    if (payload.total !== undefined) {
      state.total = payload.total
    }
  },

  /**
   * キャンペーン情報の取得
   * @param {TInstagramPostStore} state
   * @param {TCampaign} payload
   */
  [SET_CAMPAIGN](state: TInstagramPostStore, payload: TCampaign) {
    state.campaign = payload
  },

  /**
   * 応募グループ一覧の取得
   * @param {TInstagramPostStore} state
   * @param {TPrize[]} payload
   */
  [SET_PRIZES](state: TInstagramPostStore, payload: TPrize[]) {
    state.prizes = payload
  },

  /**
   * 応募総数
   */
  [SET_ENTRY_COUNT](state: TInstagramPostStore, payload: number) {
    state.entryCount = payload
  },

  /**
   * エラーフラグをセット
   * @param {TInstagramPostStore} state
   * @param {boolean} error エラーフラグ
   */
  [SET_ERROR](state: TInstagramPostStore, error: boolean) {
    state.error = error
  },

  /**
   * ローディングフラグをセット
   * @param {TInstagramPostStore} state
   * @param {boolean} loading ローディングフラグ
   */
  [SET_LOADING](state: TInstagramPostStore, loading: boolean) {
    state.loading = loading
  },

  /**
   * 初回ロード終了フラグをセット
   * @param {TInstagramPostStore} state
   * @param {boolean} loaded 初回ロード終了フラグ
   */
  [SET_FIRST_LOADED](state: TInstagramPostStore, loaded: boolean) {
    state.firstLoaded = loaded
  },

  /**
   * パラメーターを更新
   * @param {TInstagramPostStore} state
   * @param {TGetInstagramPostsParams} params パラメーター
   */
  [SET_PARAMS](state: TInstagramPostStore, params: TGetInstagramPostsParams) {
    state.params = params
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
