import { mapActions, mapState } from 'vuex'

import api from '@/client/core/api'

export default {
  data() {
    return {
      type: '',
      visible: false,
      loading: false,
      isSubmit: false,
      excludeSentMessage: 1
    }
  },
  computed: {
    ...mapState('twitterApplicant', {
      prizeId: state => state.campaignPrize.id
    }),
    displayText() {
      let displayText = this.type
      switch (displayText) {
        case 'winner':
          return (displayText = '当選者')

        case 'responder':
          return (displayText = '回答者')

        case 'candidate':
          return (displayText = '候補者')
      }
    },
    isWinnerType() {
      return this.type === 'winner'
    }
  },
  methods: {
    ...mapActions('twitterApplicant', ['fetchApplicants', 'fetchCampaignPrize']),

    /**
     * ダイアログをリセット
     */
    reset() {
      this.loading = false
      this.isSubmit = false
    },

    /**
     * フォームのサブミット
     */
    async submit() {
      if (this.isSumbit) {
        return
      }

      this.isSubmit = true
      this.loading = true

      const params = {
        prizeId: this.prizeId,
        type: this.type
      }

      if (this.type === 'winner') {
        params.excludeSentMessage = this.excludeSentMessage === 1
      }

      const result = await api.post('/twitter_applicants/resets', params)

      this.loading = false
      setTimeout(() => {
        this.isSubmit = false
      }, 2000)

      // 失敗した場合はエラーを表示
      if (!result.data) {
        this.$notify({
          title: this.$gettext(`${this.displayText}の一括解除に失敗しました。`),
          message: this.$gettext('恐れ入りますが、時間を置いて再度お試しください。'),
          customClass: 'danger',
          duration: 5000
        })
        return
      }

      // 成功メッセージを表示
      this.$notify({
        title: this.$gettext(`${this.displayText}を一括解除しました。`),
        customClass: 'success',
        duration: 5000
      })

      this.fetchCampaignPrize()
      this.fetchApplicants()
      this.close()
    },

    open(type: string) {
      this.type = type
      this.visible = true
      this.reset()
    },
    close() {
      this.visible = false
    }
  }
}
