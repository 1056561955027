/* @flow */
import { CAMPAIGN_STATUS } from '@/client/constant'
export default {
  props: {
    status: Number
  },
  data() {
    return {
      CAMPAIGN_STATUS
    }
  }
}
