/* @flow */
import moment from 'moment'
import { mapActions, mapState } from 'vuex'

import DialogTikTokFollowCheck from '@/client/components/_organisms/DialogTikTokFollowCheck.vue'
import CsvDownload from '@/client/components/basic/csv_download'
import ApplicantAdvanceFilter from '@/client/components/tiktok_applicant/applicant_advance_filter'
import ApplicantFilter from '@/client/components/tiktok_applicant/applicant_filter'
import ApplicantGrid from '@/client/components/tiktok_applicant/applicant_grid'
import ApplicantImportDialog from '@/client/components/tiktok_applicant/applicant_import_dialog'
import ApplicantRandomDialog from '@/client/components/tiktok_applicant/applicant_random_dialog'
import ApplicantResetDialog from '@/client/components/tiktok_applicant/applicant_reset_dialog'
import ApplicantTable from '@/client/components/tiktok_applicant/applicant_table'
import api from '@/client/core/api'
import util from '@/client/core/util'
import { TrackingService } from '@/client/services'
import { type TApplicant } from '@/typings/api/tiktok_applicants.type'

export default {
  components: {
    CsvDownload,
    ApplicantFilter,
    ApplicantAdvanceFilter,
    ApplicantTable,
    ApplicantGrid,
    DialogTikTokFollowCheck,
    ApplicantImportDialog,
    ApplicantRandomDialog,
    ApplicantResetDialog
  },
  data() {
    return {
      loading: false,
      loadingText: '',
      is_submit: false,
      actionDialogs: [
        {
          label: this.$gettext('フォローインポート'),
          value: 'import_follower'
        },
        {
          label: this.$gettext('候補者インポート'),
          value: 'import_candidate'
        },
        {
          label: this.$gettext('当選者インポート'),
          value: 'import_winner'
        },
        {
          label: this.$gettext('回答者インポート'),
          value: 'import_responder'
        },
        {
          label: this.$gettext('当選連絡インポート'),
          value: 'import_winner_contact'
        },
        {
          label: this.$gettext('候補者解除'),
          value: 'reset_candidate',
          divided: true
        },
        {
          label: this.$gettext('当選者解除'),
          value: 'reset_winner'
        },
        {
          label: this.$gettext('回答者解除'),
          value: 'reset_responder'
        },
        {
          label: this.$gettext('フォロー解除'),
          value: 'reset_follower'
        }
      ],
      viewType: 'list'
    }
  },
  async created() {
    this.setParams({ campaignId: this.$route.params.campaignId, prizeId: this.$route.params.prizeId })
    // キャンペーンデータを取得
    // eslint-disable-next-line flowtype-errors/show-errors
    await Promise.allSettled([this.fetchCampaign(), this.fetchCampaignPrize(), this.fetchApplicants()])

    if (this.campaign && this.campaign.id && this.currentGroupId !== this.campaign.groupId) {
      this.setCurrentGroup(this.campaign.groupId)
    }

    this.setBreadcrumb([
      {
        name: this.$gettext('キャンペーン'),
        link: '/campaigns'
      },
      {
        name: this.campaign.title,
        link: `/tiktok_campaigns/${this.campaign.id}/prizes`,
        iconName: this.campaign.account ? this.campaign.account.name : '削除されたアカウント',
        iconUrl: this.campaign.account.pictureUrl,
        iconType: this.campaign.snsType,
        isIcon: true
      },
      {
        name: this.campaignPrize.name,
        link: null
      }
    ])
  },
  computed: {
    ...mapState('system', {
      currentGroupId: state => state.currentGroup.id,
      contractStatus: state => state.currentGroup.contractStatus
    }),
    ...mapState('tiktokApplicant', {
      params: state => state.params,
      applicants: state => state.applicants,
      campaign: state => {
        if (state.campaign) {
          return state.campaign
        }
        return {}
      },
      campaignAccountId: state => {
        if (state.campaign && state.campaign.account) {
          return +state.campaign.account.id
        }

        return 0
      },
      campaignPrize: state => {
        if (state.campaignPrize) {
          return state.campaignPrize
        }
        return {}
      },
      options: state => {
        return {
          total: state.total,
          pagingNo: state.params.pagingNo,
          limitCount: state.params.limitCount,
          sortBy: state.params.sortBy
        }
      },
      firstLoaded: state => state.firstLoaded,
      error: state => state.error,
      showAdvanceFilter: state => state.showAdvanceFilter,
      isLoading: state => state.isLoading
    })
  },
  methods: {
    ...mapActions('system', ['setCurrentGroup']),
    ...mapActions('navbar', ['setBreadcrumb']),
    ...mapActions('tiktokApplicant', [
      'reset',
      'fetchApplicants',
      'fetchCampaignPrize',
      'fetchCampaign',
      'setParams',
      'updateWinner',
      'updateResponder',
      'updateWinnerContact',
      'updateFollower',
      'updateCandidate'
    ]),

    /**
     * Open dialog
     * @param {string} value
     */
    openDialog(value: string) {
      TrackingService.sendEvent('select:応募一覧（TT）|一括操作')

      if (value.indexOf('reset') !== -1) {
        const type = value.replace('reset_', '')
        this.$refs.applicantResetDialog.open(type)
      } else {
        const type = value.replace('import_', '')
        this.$refs.applicantImportDialog.open(type)
      }
    },

    /**
     * ページを変更イベント
     * @param {number} page ページ
     */
    onPaging(payload: { pagingNo: number, limitCount: number }) {
      this.setParams(payload)
      util.scrollToTop()
    },

    /**
     * ソートを変更イベント
     * @param {string} sortBy ソート
     */
    onSorting(value: string) {
      if (this.params.sortBy === value) {
        return
      }

      this.setParams({ sortBy: value })
    },

    /**
     * 対象応募者のフラグの変更
     */
    async changeFlag(payload: { applicantId: number, type: string, value: number }) {
      const { applicantId, type, value } = payload

      let text = ''
      let result: any
      this.is_submit = true

      switch (type) {
        case 'is_follower':
          result = await this.updateFollower({
            applicantId,
            isFollowing: value
          })
          text = 'フォロー'
          break
        case 'is_candidate':
          result = await this.updateCandidate({
            applicantId,
            isCandidate: value
          })
          text = '候補者'
          break
        case 'is_winner':
          result = await this.updateWinner({
            applicantId,
            isWinner: value,
            wasWinner: 1
          })
          text = '当選者'
          break
        case 'is_responder':
          result = await this.updateResponder({
            applicantId,
            isResponder: value
          })
          text = '回答者'
          break
        case 'is_winner_contact':
          text = '当選連絡'
          result = await this.updateWinnerContact({
            applicantId,
            isWinnerContact: value
          })
          break
      }

      if (!result) {
        this.is_submit = false
        return
      }

      if (result.error && result.error.type === 'MAX_WINNER_COUNT_OVER') {
        this.showError('残り当選数に空きがありません。')
        this.is_submit = false
        return
      }

      if (!result.data) {
        this.showError(`${text}の保存に失敗しました`)
        this.is_submit = false
        return
      }

      this.is_submit = false
    },

    /**
     * グリッドの切替
     * @param {string} viewType
     */
    changeViewType(viewType: string) {
      TrackingService.sendEvent(`click:応募一覧（TT）|${viewType === 'list' ? '一覧' : 'グリッド'}`)

      this.viewType = viewType
    },

    /**
     * エラーを表示
     * @params {string} title タイトル
     * @params {string} message
     */
    showError(title: string, message: string = '') {
      this.$notify({
        title: this.$gettext(title),
        message: this.$gettext(message),
        customClass: 'danger',
        duration: 5000
      })
    },

    /**
     * ランダム抽選ダイアログを開く
     */
    openApplicantRandomDialog() {
      TrackingService.sendEvent('click:応募一覧（TT）|ランダム抽選')

      this.$refs.applicantRandomDialog.open()
    },

    /**
     * フォローチェックダイアログを開く
     */
    openFollowCheckDialog() {
      TrackingService.sendEvent('click:応募一覧（TT）|フォローチェック')

      this.$refs.dialogTikTokFollowCheck.open()
    },

    /**
     * 当選者解除ダイアログを開く
     */
    openApplicantResetWinnerDialog() {
      this.$refs.applicantResetWinnerDialog.open()
    },

    /**
     * 当選者解除ダイアログを開く
     */
    openApplicantResetResponderDialog() {
      this.$refs.applicantResetResponderDialog.open()
    },

    /**
     * CSVダウンロード
     */
    async downloadCsv() {
      TrackingService.sendEvent('click:応募一覧（TT）|CSVダウンロード')

      const applicants: TApplicant[] = await this.getApplicantsForCsv()
      if (!applicants.length) {
        return { error: 'ERROR_LOADING' }
      }

      const filename = `campaign_applicants_${moment().format('YYYY_MM_DD')}`

      // フィルター条件がある場合CSVファイルの一番上に追加する
      const filterCondition = []
      let filterConditionIsFollowing = ''
      let filterConditionIsWinner = ''
      let filterConditionIsResponder = ''
      let filterConditionIsWinnerContact = ''
      const params = this.params

      // フォローでフィルター条件がある場合は、フィルター条件に追加する
      switch (params.isFollowing) {
        case 1:
          filterConditionIsFollowing = this.$gettext('フォローあり')
          break
        case 0:
          filterConditionIsFollowing = this.$gettext('フォローなし')
          break
      }

      if (filterConditionIsFollowing) {
        filterCondition.push([filterConditionIsFollowing])
      }

      // 当選者でフィルター条件がある場合は、フィルター条件に追加する
      switch (params.isWinner) {
        case 1:
          filterConditionIsWinner = this.$gettext('当選フラグあり')
          break
        case 0:
          filterConditionIsWinner = this.$gettext('当選フラグなし')
          break
      }

      if (filterConditionIsWinner) {
        filterCondition.push([filterConditionIsWinner])
      }

      // 回答者でフィルター条件がある場合は、フィルター条件に追加する
      switch (params.isResponder) {
        case 1:
          filterConditionIsResponder = this.$gettext('回答フラグあり')
          break
        case 0:
          filterConditionIsResponder = this.$gettext('回答フラグなし')
          break
      }

      if (filterConditionIsResponder) {
        filterCondition.push([filterConditionIsResponder])
      }

      // 当選連絡でフィルター条件がある場合は、フィルター条件に追加する
      switch (params.isWinnerContact) {
        case 1:
          filterConditionIsWinnerContact = this.$gettext('当選連絡フラグあり')
          break
        case 0:
          filterConditionIsWinnerContact = this.$gettext('当選連絡フラグなし')
          break
      }

      if (filterConditionIsWinnerContact) {
        filterCondition.push([filterConditionIsWinnerContact])
      }

      // 累計参加数フィルター条件がある場合、フィルター条件に追加する
      const filterConditionTotalCampaignEntryCount = this.createFilterConditionText(
        params.totalCampaignEntryCount[0],
        params.totalCampaignEntryCount[1],
        this.$gettext('累計参加数')
      )

      if (filterConditionTotalCampaignEntryCount) {
        filterCondition.push([filterConditionTotalCampaignEntryCount])
      }

      // 累計当選数フィルター条件がある場合、フィルター条件に追加する
      const filterConditionTotalCampaignWinningCount = this.createFilterConditionText(
        params.totalCampaignWinningCount[0],
        params.totalCampaignWinningCount[1],
        this.$gettext('累計当選数')
      )

      if (filterConditionTotalCampaignWinningCount) {
        filterCondition.push([filterConditionTotalCampaignWinningCount])
      }

      // アカウント名検索でフィルター条件がある場合は、フィルター条件に追加する
      if (params.accountName) {
        filterCondition.push([this.$gettext('アカウント名') + '[' + params.accountName + ']'])
      }

      const csvData = []

      // フィルター条件がある場合は、一番最初の行にフィルター条件を追加する
      if (filterCondition.length > 0) {
        csvData.push([this.$gettext('フィルター条件:'), filterCondition.join('/')])
      }

      csvData.push([
        this.$gettext('No'),
        this.$gettext('ユーザー名'),
        this.$gettext('アカウントURL'),
        this.$gettext('アカウント画像'),
        this.$gettext('応募日時'),
        this.$gettext('投稿内容'),
        this.$gettext('投稿URL'),
        this.$gettext('応募数'),
        this.$gettext('累計参加数'),
        this.$gettext('累計当選数'),
        this.$gettext('NGアカウント'),
        this.$gettext('候補者'),
        this.$gettext('当選者'),
        this.$gettext('回答者'),
        this.$gettext('当選連絡')
      ])

      // CSVデータを格納
      applicants.forEach((applicant, index) => {
        csvData.push([
          index + 1,
          applicant.screenName,
          'https://www.tiktok.com/@' + applicant.screenName,
          applicant.pictureUrl,
          this.$options.filters.datetime(applicant.post.publishDatetime),
          applicant.post.message ? applicant.post.message.replace(/(\r\n|\n|\r)/gm, '') : '',
          applicant.post.shareUrl,
          applicant.postsCount,
          applicant.totalCampaignEntryCount,
          applicant.totalCampaignWinningCount,
          applicant.isNg,
          applicant.isCandidate,
          applicant.isWinner,
          applicant.isResponder,
          applicant.isWinnerContact
        ])
      })
      return {
        filename,
        data: csvData
      }
    },

    /**
     * CSVファイルに追加する累計参加数、累計当選数のフィルター条件のテキスト
     * @param {number} overNumber 以上の数
     * @param {number} lessNumber 未満の数
     * @param {string} conditionText フィルター条件のテキスト
     */
    createFilterConditionText(overNumber: any, lessNumber: any, conditionText: string) {
      // 累計参加数、累計当選数のフィルター条件が未入力の場合はリターン
      if ((overNumber === undefined && lessNumber === undefined) || (overNumber === '' && lessNumber === '')) {
        return
      }
      return (
        conditionText +
        String(overNumber).replace(/(\d)(?=(\d{3})+\b)/g, '$1,') +
        '~' +
        String(lessNumber).replace(/(\d)(?=(\d{3})+\b)/g, '$1,')
      )
    },

    /**
     * CSV用応募者一覧を取得
     */
    async getApplicantsForCsv() {
      this.loading = true
      const { total } = this.options
      this.getTextLoading(0, total)

      const params = Object.assign({}, this.params, {
        pagingNo: 1,
        limitCount: 1000,
        forCsv: 1
      })

      let totalResults = []
      // 1000件ごとに取得する
      for (let count = 0; count < total; count += 1000) {
        const result = await api.get('/tiktok_applicants', { params })

        const surplus = (total - count) / 1000

        if (surplus < 1) {
          this.getTextLoading(total, total)
        } else {
          this.getTextLoading(count + 1000, total)
        }

        if (!result.data) {
          this.loading = false
          return []
        }

        totalResults = totalResults.concat(result.data.applicants)
        params.pagingNo = params.pagingNo + 1
      }

      this.loading = false
      return totalResults
    },

    /**
     * CSVダウンロードの時、進捗を表示する
     * @params {number} acquiredNumber
     * @params {number} totalNumber
     */
    getTextLoading(acquiredNumber: number, totalNumber: number) {
      this.loadingText = this.$gettextInterpolate(
        this.$gettext('CSVファイルの作成中です。しばらくお待ちください。\n %{acquiredNumber}件 / %{totalNumber}件'),
        {
          acquiredNumber,
          totalNumber
        }
      )
    }
  },
  beforeRouteLeave(to: any, from: any, next: any) {
    // 応募者ストアをリセット
    this.reset()
    next()
  }
}
