/* @flow */
import { mapActions } from 'vuex'

import MessageQueueCreate from '@/client/components/twitter_instantwin_message/message_queue_create'
import MessageQueueMessageTable from '@/client/components/twitter_instantwin_message/message_queue_message_table'
import MessageQueueTable from '@/client/components/twitter_instantwin_message/message_queue_table'
import MessageQueueTargets from '@/client/components/twitter_instantwin_message/message_queue_targets'

export default {
  data() {
    return {
      tab: '',
      title: this.$gettext('一括配信メッセージの管理'),
      visible: false,
      isChangeWithoutSubmit: false
    }
  },
  components: {
    MessageQueueTable,
    MessageQueueCreate,
    MessageQueueMessageTable,
    MessageQueueTargets
  },
  computed: {
    isQueueTable() {
      return this.tab === 'queue-list'
    },

    isQueueMessageTable() {
      return this.tab === 'queue-message-table'
    },

    isQueueCreate() {
      return this.tab === 'queue-create'
    },

    isMessageQueueTargets() {
      return this.tab === 'message-queue-targets'
    }
  },
  methods: {
    ...mapActions('twitterInstantwinQueue', ['reset']),

    /**
     * ダイアログをあく
     */
    open() {
      this.visible = true
      this.isChangeWithoutSubmit = false
      this.reset()
      this.tab = 'queue-list'
    },

    /**
     * Switch tab
     * @param {string} tab
     */
    changeTab(tab: string) {
      this.reset()
      this.tab = tab
    },

    /**
     * ダイアログ閉じるイベント
     */
    beforeClose(done: any) {
      if (!this.isChangeWithoutSubmit) {
        this.tab = ''
        return done()
      }

      const confirm = window.confirm(this.$gettext('編集内容が保存されていません。ダイアログを閉じてよろしいですか?'))

      if (!confirm) {
        return
      }

      this.tab = ''
      done()
    }
  }
}
