/* @flow */
import { mapActions, mapState } from 'vuex'

import GroupBillingForm from '@/client/components/_organisms/GroupBillingForm.vue'
import PreviewGroupBilling from '@/client/components/setting_group/group_billing'
import { COUNTRY_DEFAULT } from '@/client/constant'
import { EMAIL_FORMAT, TELEPHONE_FORMAT, ZIP_CODE_FORMAT } from '@/client/constant/regex'
import api from '@/client/core/api'
export default {
  components: {
    PreviewGroupBilling,
    GroupBillingForm
  },
  data() {
    return {
      isPreviewStep: false,
      isConfirmSubmit: false,
      isSubmit: false,
      groupBilling: {
        companyName: '',
        departName: '',
        personName: '',
        email: '',
        countryCode: COUNTRY_DEFAULT,
        zipCode: '',
        address: '',
        telephone: ''
      }
    }
  },
  computed: {
    ...mapState('system', ['currentGroup', 'groups']),

    showFormGroupBilling() {
      return !this.currentGroup.contractConfirmAt && this.currentGroup.isContractManager
    },

    messageGroupBilling() {
      if (!this.currentGroup?.id)
        return {
          className: 'message-warning',
          content:
            'グループに所属していないため、データを表示できません。<br />ご利用される方は、右下のサポートチャットよりお問い合わせください。'
        }

      const messageInfo = {
        className: 'message-info',
        content: ''
      }

      if (this.showFormGroupBilling && this.isPreviewStep) {
        messageInfo.content =
          '登録内容をご確認の上、よろしければ[上記のご契約内容で同意]にチェックのうえ、[この内容で登録する]ボタンをクリックしてください。'
      } else
        messageInfo.content =
          'このグループは、ご契約情報の入力が完了していません。<br />代表者による入力が完了後、サービスをご利用いただけます。'

      return messageInfo
    },

    captionGroupBilling() {
      if (this.isPreviewStep) return '契約情報の確認'
      return '契約情報の入力'
    },

    isNextDisabled() {
      return (
        !this.groupBilling.companyName ||
        !this.groupBilling.personName ||
        !this.groupBilling.email ||
        !EMAIL_FORMAT.test(this.groupBilling.email) ||
        !this.groupBilling.countryCode ||
        !this.groupBilling.zipCode ||
        !ZIP_CODE_FORMAT.test(this.groupBilling.zipCode) ||
        !this.groupBilling.address ||
        !this.groupBilling.telephone ||
        !TELEPHONE_FORMAT.test(this.groupBilling.telephone)
      )
    }
  },
  async created() {
    if (this.currentGroup?.id) await this.fetchGroupBilling(this.currentGroup.id)
  },
  methods: {
    ...mapActions('system', ['setCurrentGroup', 'setGroups']),
    nextStep() {
      this.$refs.groupBillingFormRef
        .validateForm()
        .then(() => {
          this.isPreviewStep = true
        })
        .catch(() => {
          this.isPreviewStep = false
        })
    },

    prevStep() {
      this.isPreviewStep = false
      this.isConfirmSubmit = false
    },

    /**
     * 支払い情報を登録する
     */
    async updateGroupBilling() {
      this.isSubmit = true

      // グループ請求データを保存
      const result = await api.put(`/v1/group_billings/${this.currentGroup.id}`, this.groupBilling)
      setTimeout(() => {
        this.isSubmit = false
      }, 2000)

      if (result.error || !result.data) {
        this.$notify({
          title: 'ご契約内容の登録に失敗しました',
          message: '恐れ入りますが、時間をおいて再度お試しください。',
          customClass: 'danger error-group-billing',
          duration: 5000
        })

        return
      }

      // 成功メッセージを表示
      this.$notify({
        title: 'ご契約内容の登録が完了しました',
        message: 'サービスをご利用いただけます。<br> まずはアカウントを登録しましょう。',
        customClass: 'success',
        dangerouslyUseHTMLString: true,
        duration: 5000
      })

      const newGroups = this.groups.map(group => {
        if (group.id === this.currentGroup.id) {
          return { ...group, contractConfirmAt: new Date().toISOString() }
        }
        return group
      })

      // グループ一覧を更新する
      this.setGroups(newGroups)

      // 現在のグループを更新する
      this.setCurrentGroup(this.currentGroup.id)

      return this.$router.push('/campaigns')
    },

    async fetchGroupBilling(groupId: number) {
      const result = await api.get(`/v1/group_billings/${groupId}`)
      // グループ管理者権限がない場合、キャンペーン画面に移動
      if (!result.data && result.error && result.error.type === 'PERMISSION_DENIED') {
        return
      }

      if (result.data) {
        // グループ請求先情報を設定する
        this.groupBilling = {
          companyName: result.data.companyName,
          departName: result.data.departName,
          personName: result.data.personName,
          email: result.data.email,
          address: result.data.address,
          countryCode: result.data.countryCode || COUNTRY_DEFAULT,
          zipCode: result.data.zipCode,
          telephone: result.data.telephone
        }
      }
    }
  }
}
