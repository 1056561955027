/* @flow */
import api from '@/client/core/api'
import { type PostTwoFactorAuthParams } from '@/typings/two_factor'

export default {
  props: {
    userId: Number
  },
  data() {
    return {
      code: '',
      is_permission_denied: false
    }
  },
  computed: {
    isDisabled() {
      const regex = /\b\d{6}\b/g

      return !this.code.trim() || !this.code.match(regex) || this.code.length !== 6
    }
  },
  methods: {
    /**
     * 認証コードを確認
     */
    async checkTwoFactorCode() {
      const params: PostTwoFactorAuthParams = {
        user_id: this.userId,
        code: this.code
      }

      this.is_permission_denied = false

      const result = await api.post('v1/two_factor/auth', params)

      if (result && result.data) {
        this.$emit('login', result.data)
        return
      }

      if (result.error && result.error.type === 'PERMISSION_DENIED') {
        this.is_permission_denied = true
        return
      }

      this.$notify({
        title: this.$gettext('2段階認証に失敗しました。'),
        message: this.$gettext('エラーが発生しました。恐れ入りますが時間を置いて再ログインしてください。'),
        customClass: 'danger',
        duration: 10000
      })
    },

    /**
     * ログイン画面に戻す
     */
    back() {
      this.$emit('back')
    }
  }
}
