/* @flow */
export default {
  data() {
    return {
      title: '',
      visible: false,
      posts: Array
    }
  },
  methods: {
    /**
     * ダイアログを開く
     * @param {string} accountName account name
     * @param {any} posts list post
     */
    open(accountName: string, posts: any) {
      this.visible = true
      this.posts = posts
      this.title =
        accountName +
        ' ' +
        this.$gettext('さんの重複応募詳細') +
        `(${this.posts ? this.posts.length : 0}${this.$gettext('件')})`
    },

    /**
     * ダイアログ閉じるイベント
     * @param {any} done done event
     */
    beforeClose(done: any) {
      this.title = ''
      done()
    }
  }
}
