import moment from 'moment'
import { mapActions, mapState } from 'vuex'

import CsvDownload from '@/client/components/basic/csv_download/csv_download'
import CampaignPrizeDialog from '@/client/components/twitter_campaign_prize/campaign_prize_dialog'
import TwitterPrizeTable from '@/client/components/twitter_campaign_prize/twitter_prize_table'
import { CAMPAIGN_STATUS } from '@/client/constant'
import api from '@/client/core/api'
import util from '@/client/core/util'
import { TrackingService } from '@/client/services'

export default {
  components: {
    CsvDownload,
    TwitterPrizeTable,
    CampaignPrizeDialog
  },
  data: () => {
    return {
      isLeave: false
    }
  },
  computed: {
    ...mapState('system', {
      currentGroupId: state => state.currentGroup.id
    }),
    ...mapState('twitterCampaignPrize', {
      prizes: state => state.prizes,
      error: state => state.error,
      params: state => state.params,
      loaded: state => state.firstLoaded,
      campaignInfo: state => state.campaignInfo,
      campaign: state => {
        if (state.campaign) {
          return state.campaign
        }
        return {}
      },
      options: state => {
        return {
          total: state.total,
          pagingNo: state.params.pagingNo,
          limitCount: state.params.limitCount,
          sortBy: state.params.sortBy
        }
      },

      lastProcessedData() {
        if (['tweet', 'tweet_media', 'tweet_conversational'].includes(this.campaign.setting?.entryActionType)) {
          return 'ポスト取得日時'
        }
        return 'データ取得日時'
      },

      lastProcessedDatetime() {
        if (
          ['quote_tweet', 'retweet', 'retweet_and_quote_tweet', 'mention'].includes(
            this.campaign.setting?.entryActionType
          ) &&
          this.campaign.account.enterpriseToken
        ) {
          return 'リアルタイム反映'
        }

        if (!this.campaign.lastProcessedDatetime) {
          return '-'
        }

        return moment(this.campaign.lastProcessedDatetime).format('YYYY年MM月DD日 HH:mm')
      }
    }),

    campaignStartDatetime() {
      return moment(this.campaign.startDatetime).format('YYYY年MM月DD日 HH:mm')
    },

    collectCompletedAt() {
      // フォロワー取得バッチが深夜1時に実行されるため、次のバッチ実行日の午前10時を表示する
      return moment(this.campaign.endDatetime)
        .subtract(1, 'hours')
        .add(1, 'days')
        .format('YYYY年MM月DD日 10:00')
    },

    isCampaignBeforeStart() {
      return this.campaign.status === CAMPAIGN_STATUS.BEFORE_EVENT
    },

    isCampaignBeforeCollect() {
      return (
        ['tweet', 'tweet_media', 'tweet_conversational'].includes(this.campaign.setting?.entryActionType) &&
        this.campaign.status === CAMPAIGN_STATUS.BEFORE_COLLECTION
      )
    },

    isCampaignFinalCollection() {
      return this.campaign.status === CAMPAIGN_STATUS.DURING_FINAL_COLLECTION
    },

    isCampaignCollectionCompleted() {
      return this.campaign.status === CAMPAIGN_STATUS.COLLECTION_COMPLETED
    }
  },
  async mounted() {
    const params = {
      campaignId: this.$route.params.campaignId
    }

    this.setParams(params)

    await this.fetchCampaign()

    if (this.isLeave || !this.campaign || !this.campaign.id) {
      return
    }

    if (this.currentGroupId !== this.campaign.groupId) {
      this.setCurrentGroup(this.campaign.groupId)
    }

    this.setBreadcrumb([
      {
        name: this.$gettext('キャンペーン一覧'),
        link: '/campaigns'
      },
      {
        name: this.campaign.title,
        link: null,
        iconName: this.campaign.account ? this.campaign.account.name : '削除されたアカウント',
        iconUrl: this.campaign.account ? this.campaign.account.pictureUrl : null,
        iconType: this.campaign.snsType,
        label: this.campaign.type === 'instantwin' ? 'インスタントウィン' : null,
        isIcon: true
      }
    ])
  },
  watch: {
    params() {
      this.fetchPrizes()
    }
  },
  methods: {
    ...mapActions('system', ['setCurrentGroup']),
    ...mapActions('twitterCampaignPrize', ['fetchPrizes', 'setParams', 'fetchCampaign', 'reset']),
    ...mapActions('navbar', ['setBreadcrumb']),

    /**
     * CSVダウンロード
     */
    async downloadCsv() {
      TrackingService.sendEvent('click:応募グループ一覧（X）|CSVダウンロード')

      const params = Object.assign({}, this.params, {
        forCsv: 1
      })
      const result = await api.get('/twitter_campaign_prizes', {
        params
      })

      if (!result.data) {
        return {
          error: 'ERROR_LOADING'
        }
      }

      const prizes = result.data.prizes
      const filename = `campaign_prize_${this.campaign.title}_applicants_${moment().format('YYYY_MM_DD')}`
      const csvData = []
      csvData.push([
        this.$gettext('応募グループ名'),
        this.$gettext('応募キーワード'),
        this.$gettext('応募者数'),
        this.$gettext('当選者数'),
        this.$gettext('当選者数(残り)'),
        this.$gettext('推定リーチ')
      ])

      // CSVデータを格納
      prizes.forEach(prize => {
        csvData.push([
          prize.name,
          prize.entryKeyword,
          prize.applicantCount,
          prize.winnerCount,
          prize.maxWinnerCount - prize.winnerCount,
          prize.estimatedReachCount
        ])
      })

      return {
        filename,
        data: csvData
      }
    },

    /**
     * ページを変更イベント
     * @param {number} page ページ
     */
    onChangePagingNo(page: number) {
      if (this.params.pagingNo === page) {
        return
      }
      this.setParams({ pagingNo: page })
      util.scrollToTop()
    },

    /**
     * リミット数を変更イベント
     * @param {number} limitCount リミット数
     */
    onChangeLimitCount(limitCount: number) {
      if (this.params.limitCount === limitCount) {
        return
      }
      this.setParams({ limitCount })
    },

    /**
     * ソートを変更イベント
     * @param {string} sortBy ソート
     */
    onChangeSortBy(sortBy: string) {
      if (this.params.sortBy === sortBy) {
        return
      }
      this.setParams({ sortBy })
    },

    onChangeBackEvent() {
      this.$router.push('/campaigns')

      TrackingService.sendEvent('click:応募グループ一覧（X）|戻る')
    },

    onChangeCampaignPrizeDialogEvent() {
      this.$refs.campaignPrizeDialog.open(this.campaign.id)

      TrackingService.sendEvent('click:応募グループ一覧（X）|応募グループの新規作成')
    },

    onChangeTwitterPostEvent() {
      this.$router.push({ name: 'twitter_posts', params: { campaignId: this.campaign.id } })

      TrackingService.sendEvent('click:応募グループ一覧（X）|応募履歴')
    }
  },
  beforeRouteLeave(to: any, from: any, next: any) {
    // キャンペーンストアをリセット
    this.reset()
    this.isLeave = true
    next()
  }
}
