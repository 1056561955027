/* @flow */
import moment from 'moment'
import twitterText from 'twitter-text'
import { required } from 'vuelidate/lib/validators'
import { mapState } from 'vuex'

import Message from '@/client/components/_atoms/Message.vue'
import Editable from '@/client/components/basic/editable'
import MessageConvert from '@/client/components/basic/message_convert'
import api from '@/client/core/api'
import TwitterUpload from '@/client/core/twitter_upload'

export default {
  props: {
    isChangeWithoutSubmit: Boolean
  },
  data() {
    return {
      type: 0,
      sentAccountFilterType: 0,
      answeredAccountFilterType: 1,
      body: '',
      startDate: null,
      startDateOption: {
        disabledDate(time: any) {
          return moment(time.getTime()).format('YYYY-MM-DD') < moment().format('YYYY-MM-DD')
        }
      },
      sendLimitPerDay: 0,
      startTime: null,
      startTimeOption: {
        start: '00:00',
        step: '00:30',
        end: '23:30'
      },
      endTime: null,
      endTimeOption: {
        start: '00:00',
        step: '00:30',
        end: '23:30'
      },
      isDisabledInput: false,
      isSubmit: false,
      media: null,
      isLoadMedia: false,
      uploadTimer: null,
      oldTemplate: '',
      template: '',
      messageTemplates: [],
      messageId: null,
      remainMonthlyDmCount: 0,
      remainMonthlyDmCountDefault: 0,
      maxMonthlyDmCount: 0,
      sendCount: 0,
      isSendCountLoading: false,
      rawData: {},
      isSerialCodeConfigured: false
    }
  },
  components: {
    Editable,
    MessageConvert,
    Message
  },
  beforeDestroy() {
    if (this.uploadTimer) {
      clearInterval(this.uploadTimer)
    }

    this.reset()
  },
  async mounted() {
    this.getMessageTemplates()

    if (this.queueId) {
      await this.getMessageQueueById()
    }

    this.getSendCount()

    this.getSerialCodeSetting()

    this.rawData = this.getMessageData()
  },

  watch: {
    isFormChanged(value: boolean) {
      this.$emit('update:isChangeWithoutSubmit', value)
    },

    startDate(value: string) {
      if (value) {
        this.updateRemainMonthlyDmCount()
      }
    },

    maxSendLimitPerDay(value: number) {
      if (value < this.sendLimitPerDay) this.sendLimitPerDay = 0
    }
  },

  computed: {
    ...mapState('system', {
      currentGroup: state => state.currentGroup
    }),

    ...mapState('twitterInstantwinMessage', {
      campaign: state => state.campaign,
      messages: state => state.messages.filter(message => message.messageType === 'win')
    }),

    ...mapState('twitterInstantwinQueue', {
      queueId: state => state.queueMessages.queueId
    }),

    types() {
      const types = [
        {
          label: this.$gettext('当選通知'),
          value: 0,
          text: this.$gettext('一度も当選メッセージを配信していない未回答の当選者全員')
        },
        {
          label: this.$gettext('リマインド'),
          value: 2,
          text: this.$gettext('当選通知を配信したことのある、未回答の当選者全員')
        },
        {
          label: this.$gettext('シリアルコード未使用のリマインド'),
          value: 3,
          text: this.$gettext(
            'シリアルコードが未使用の当選者全員(使用済みシリアルコードをシリアルコードの管理にインポートできます。)'
          )
        }
      ]

      if (this.messageId === null) {
        types.push({
          label: this.$gettext('配信失敗した応募者への再配信'),
          value: 4,
          text: this.$gettext('配信に失敗した応募者')
        })
      }

      return types
    },

    /**
     * 新規作成か編集かのチェック
     */
    isEdit() {
      return this.queueId > 0
    },

    /**
     * 種別が配信失敗した応募者への再配信の場合は、全てのメッセージにしてdisabledにする
     */
    isAllMessageDisabled() {
      return this.type === 4
    },

    /**
     * ヘルプテキスト取得
     */
    typeText() {
      let text = null

      this.types.forEach(type => {
        if (type.value === this.type) text = type.text
      })

      return text
    },

    /**
     * ハイライト定義データ
     */
    highlights() {
      return [{ type: 'info', keywords: ['_account_name_', '_screen_name_', '_account_id_', '_serial_code_'] }]
    },

    /**
     * メッセージ内容の文字数取得
     */
    bodyLength() {
      if (!this.body) {
        return 0
      }

      return twitterText.parseTweet(this.body).weightedLength
    },

    /**
     * メッセージ最大長
     */
    maxLength() {
      return 4000
    },

    hasSerialCodeInMessageBody() {
      return /_serial_code_/.test(this.body)
    },

    hasUnsetSerialCodeMessage() {
      return this.messages.some(v => v.countSerialCode === 0)
    },

    hasSetSerialCodeMessage() {
      return this.messages.some(v => v.countSerialCode > 0)
    },

    disabledSubmit() {
      if (
        !this.startDate ||
        !this.startTime ||
        this.body === '' ||
        this.bodyLength > this.maxLength ||
        this.body.trim().length === 0 ||
        this.isLoadMedia
      ) {
        return true
      }

      if (this.isDateInput || this.isStartTimeInput || this.isEndTimeInput || this.isDatetimeOver || this.isTimeOver) {
        return true
      }

      return false
    },

    isImage() {
      const reg = /[.](jpeg|png|gif|pjpeg|jpg)$/i
      return reg.test(this.media.mediaUrl)
    },

    isSendCountEmpty() {
      return this.sendCount === 0
    },

    isLimitSendCount() {
      return this.remainMonthlyDmCount !== null
    },

    isRemainEmpty() {
      return this.remainMonthlyDmCount === 0
    },

    isSendCountOver() {
      if (this.remainMonthlyDmCount === null) return false
      return this.sendCount > this.remainMonthlyDmCount
    },

    isDateInput() {
      return !this.startDate
    },

    isStartTimeInput() {
      return !this.startTime
    },

    isEndTimeInput() {
      return this.startTime && !this.endTime
    },

    isDatetimeOver() {
      const datetime = moment(`${this.startDate} ${this.startTime}`, 'YYYY-MM-DD HH:mm')
      const limitDatetime = moment().add(5, 'minute')

      return datetime.unix() < limitDatetime.unix()
    },

    isTimeOver() {
      return this.startTime && this.startTime >= this.endTime
    },

    messageData() {
      return {
        messageId: this.messageId,
        campaignId: this.campaign.id,
        type: this.type,
        body: this.body,
        actionDatetime: this.getActionDatetime(),
        endTime: this.endTime,
        sendLimitPerDay: this.sendLimitPerDay,
        sentAccountFilterType: this.sentAccountFilterType,
        answeredAccountFilterType: this.answeredAccountFilterType,
        media: this.media
      }
    },

    isFormChanged() {
      return JSON.stringify(this.messageData) !== JSON.stringify(this.rawData)
    },

    maxSendLimitPerDay() {
      if (this.startTime && this.endTime) {
        const startTime = moment(this.startTime, 'HH:mm')
        const endTime = moment(this.endTime, 'HH:mm')

        const differenceInMinutes = endTime.diff(startTime, 'minutes')

        // 30分と比較して
        if (differenceInMinutes <= 30) {
          return 20
        }

        // 1時間と比較
        if (differenceInMinutes <= 60) {
          return 40
        }

        // 1.5時間と比較して
        if (differenceInMinutes <= 90) {
          return 60
        }

        // 2時間と比較してください
        if (differenceInMinutes <= 120) {
          return 80
        }
      }

      return 100
    },

    isUnlimitedOrLimit100() {
      return [0, 100].includes(this.sendLimitPerDay)
    },

    isLimitOneHourToTwoHoursMessage() {
      if ([20, 40, 60, 80].includes(this.maxSendLimitPerDay)) {
        return this.sendLimitPerDay === this.maxSendLimitPerDay
      }

      return false
    },

    limitOneHourToTwoHoursMessage() {
      let time = ''

      if (this.maxSendLimitPerDay === 20) {
        time = '30分間'
      }

      if (this.maxSendLimitPerDay === 40) {
        time = '1時間'
      }

      if (this.maxSendLimitPerDay === 60) {
        time = '1時間半'
      }

      if (this.maxSendLimitPerDay === 80) {
        time = '2時間'
      }

      return `リスク回避のため、ATELUでは${time}で最大${this.maxSendLimitPerDay}件のペースで送信する制限を設けてます。`
    }
  },
  methods: {
    reset() {
      this.type = null
      this.sentAccountFilterType = 0
      this.answeredAccountFilterType = 1
      this.body = ''
      this.startDate = null
      this.startTime = null
      this.endTime = null
      this.sendLimitPerDay = 0
      this.isDisabledInput = false
      this.isSubmit = false
      this.$v.body.$reset()
      this.oldTemplate = ''
      this.template = ''
      this.messageTemplates = []
      this.messageId = null
      this.isLoadMedia = false
    },

    /**
     * Get message queue by id
     */
    async getMessageQueueById() {
      const queue = await api.get(`/twitter_instantwin_queues/${this.queueId}`)

      if (!queue || !queue.data) {
        return
      }

      const { status, type, body, actionDatetime, media, messageId, endTime, sendLimitPerDay } = queue.data
      const isDisabledStatus = status === 'sent' || status === 'sending' || status === 'paused'

      this.isDisabledInput = isDisabledStatus
      this.type = type
      this.body = body
      this.messageId = messageId
      this.sendLimitPerDay = sendLimitPerDay
      this.startDate = moment(actionDatetime).format('YYYY-MM-DD')
      this.startTime = moment(actionDatetime).format('HH:mm')
      this.endTime = endTime ? moment(endTime, 'HH:mm:ss').format('HH:mm') : null

      this.media = media ? Object.assign({}, media) : null

      if (!isDisabledStatus) {
        this.onChangeType()
      }
    },

    /**
     * Get list message template
     */
    async getMessageTemplates() {
      const params = {
        groupId: this.currentGroup.id,
        sortBy: 'name'
      }

      const messageTemplates = await api.get('/message_templates', {
        params
      })

      if (messageTemplates && messageTemplates.data && messageTemplates.data.templates) {
        this.messageTemplates = messageTemplates.data.templates
      }
    },

    /**
     * 種別の変更
     */
    onChangeType() {
      switch (this.type) {
        case 0:
          this.sentAccountFilterType = 0
          this.answeredAccountFilterType = 1
          break
        case 1:
          this.sentAccountFilterType = 1
          this.answeredAccountFilterType = 1
          break
        case 2:
          this.sentAccountFilterType = 2
          this.answeredAccountFilterType = 1
          break
      }

      this.getSendCount()
    },

    /**
     * メッセージの変更
     */
    onMessageChange() {
      this.getSendCount()
      this.getSerialCodeSetting()
    },

    /**
     * テンプレート
     * @param {number} templateId
     */
    onChangeTemplate(templateId: number) {
      const currentTemplate = this.messageTemplates.find(template => template.id === templateId)
      const body = currentTemplate.body

      if (!this.body) {
        this.body = body
        this.oldTemplate = templateId
        return
      }

      const confirmChange = confirm(
        this.$gettext('メッセージがすでに入力されています。テンプレートの内容に置き換えてよろしいですか?')
      )

      if (!confirmChange) {
        this.template = this.oldTemplate
        return
      }

      this.oldTemplate = templateId
      this.body = body
    },

    /**
     * 配信対象数と残り配信可能数の取得処理
     * @returns {void}
     */
    async getSendCount() {
      this.sendCount = 0
      this.remainMonthlyDmCount = 0
      this.maxMonthlyDmCount = 0

      const params = {
        type: this.type,
        campaignId: this.campaign.id,
        messageId: this.messageId
      }

      this.isSendCountLoading = true

      const result = await api.get(`/twitter_instantwin_queues/send_counts`, { params })

      this.isSendCountLoading = false

      if (!result || !result.data) {
        return
      }

      const { sendCount, remainMonthlyDmCount, maxMonthlyDmCount } = result.data

      this.sendCount = sendCount
      this.remainMonthlyDmCount = remainMonthlyDmCount
      this.remainMonthlyDmCountDefault = remainMonthlyDmCount
      this.maxMonthlyDmCount = maxMonthlyDmCount
      this.updateRemainMonthlyDmCount()
    },

    /**
     * 残り配信可能数を更新
     */
    updateRemainMonthlyDmCount() {
      if (this.maxMonthlyDmCount === 0) return

      const today = moment()
      const isAfter = moment(this.startDate, 'YYYY-MM').isAfter(today, 'month')

      this.remainMonthlyDmCount = isAfter ? this.maxMonthlyDmCount : this.remainMonthlyDmCountDefault
    },

    /**
     * シリアルコードの設定有無の取得
     */
    getSerialCodeSetting() {
      if (this.messageId) {
        const selectMessage = this.messages.find(v => v.id === this.messageId)

        this.isSerialCodeConfigured = selectMessage?.countSerialCode > 0
      }
    },

    /**
     * Set params
     * @param {string} type
     * @returns {any} params
     */
    getParams(type: string) {
      return { ...this.messageData, status: type }
    },

    /**
     * 下書き保存（既存）
     */
    saveDraft() {
      if (this.isSubmit) return

      const params = this.getParams('draft')
      this.putButton(params)
    },

    /**
     * スケジュール保存（既存）
     */
    saveScheduled() {
      if (this.isSubmit) return

      const params = this.getParams('scheduled')
      this.putButton(params)
    },

    /**
     * 下書き保存（新規）
     */
    createDraft() {
      if (this.isSubmit) return

      const params = this.getParams('draft')
      this.postButton(params)
    },

    /**
     * スケジュール保存（新規）
     */
    createScheduled() {
      if (this.isSubmit) return

      const params = this.getParams('scheduled')
      this.postButton(params)
    },

    /**
     * メッセージ配信開始日時の取得
     */
    getActionDatetime() {
      return moment.tz(`${this.startDate} ${this.startTime}`, 'YYYY-MM-DD HH:mm', 'Asia/Tokyo').format()
    },

    /**
     * メッセージキューの更新
     * @param {Object} params 配信データ
     */
    async putButton(params: any) {
      this.isSubmit = true

      const response = await api.put(`/twitter_instantwin_queues/${this.queueId}`, params)

      setTimeout(() => {
        this.isSubmit = false
      }, 2000)

      if (response.data) {
        this.$notify({
          title: this.$gettext('メッセージを保存しました。'),
          customClass: 'success',
          duration: 5000
        })

        this.$emit('update:isChangeWithoutSubmit', false)
        this.$nextTick(() => this.changeTab('queue-list'))
        return
      }

      if (response.error && response.error.type === 'NOT_EXISTS') {
        const title = this.$gettext('対象のメッセージが見つかりません。')
        const message = this.$gettext('恐れ入りますが、時間をおいて再度お試しください。')
        this.showError(title, message)
        return
      }

      if (response.error && response.error.type === 'MESSAGE_QUEUE_COUNT_OVER') {
        const title = this.$gettext('同じ時間にスケジュールされている一括配信があります。')
        const message = this.$gettext('30分以上間隔を空けるようにしてください。')
        this.showError(title, message)
        return
      }

      const title = this.$gettext('メッセージの保存に失敗しました。')
      const message = this.$gettext('恐れ入りますが、時間をおいて再度お試しください。')
      this.showError(title, message)
    },

    /**
     * メッセージキューの新規作成
     * @param {Object} params 配信データ
     */
    async postButton(params: any) {
      this.isSubmit = true

      const response = await api.post(`/twitter_instantwin_queues`, params)

      setTimeout(() => {
        this.isSubmit = false
      }, 2000)

      if (response.data) {
        this.$notify({
          title: this.$gettext('メッセージを作成しました。'),
          customClass: 'success',
          duration: 5000
        })

        this.$emit('update:isChangeWithoutSubmit', false)
        this.$nextTick(() => {
          this.changeTab('queue-list')
        })
        return
      }

      if (response.error && response.error.type === 'DUPLICATE') {
        const title = this.$gettext('同じ時間にスケジュールされている一括配信があります。')
        const message = this.$gettext('30分以上間隔を空けるようにしてください。')
        this.showError(title, message)
        return
      }

      const title = this.$gettext('メッセージの作成に失敗しました。')
      const message = this.$gettext('恐れ入りますが、時間をおいて再度お試しください。')
      this.showError(title, message)
    },

    /**
     * メンションのメディアファイル削除
     */
    removeMedia() {
      this.media = null
    },

    /**
     * エラーを表示
     * @param {string} title
     * @param {string} message
     */
    showError(title: string, message: string) {
      this.$notify({
        title,
        message,
        customClass: 'danger',
        dangerouslyUseHTMLString: true,
        duration: 5000
      })
    },

    /**
     * ファイルのメンションを変更
     */
    async handleFileChange(e: any) {
      if (!e.target.files || !e.target.files[0]) {
        return
      }

      const file = e.target.files[0]
      this.$refs.mediaInput.value = null

      const validFormat = ['video/mp4', 'image/jpeg', 'image/png', 'image/gif', 'image/pjpeg', 'image/gif']

      // メディアをチェック
      const checkValidMedia = await TwitterUpload.checkValidMedia(file, validFormat)

      if (checkValidMedia.error) {
        const error = checkValidMedia.error

        let title = ''
        let message = ''

        switch (error) {
          case 'INVALID_MEDIA':
            title = this.$gettext('Please upload valid format!')
            message = validFormat.join(', ')
            break
          case 'MAX_SIZE_VIDEO':
            title = this.$gettext(
              '25MB以上のビデオを利用することはできません。 サイズを調整してからアップロードしてください。'
            )
            break
          case 'MAX_SIZE_IMAGE':
            title = this.$gettext(
              '5MB以上の画像を利用することはできません。サイズを調整してからアップロードしてください。'
            )
            break
          case 'INVALID_DIMENSION':
            title = this.$gettext('画像・動画アップロードに失敗しました。')
            message = this.$gettext(
              '詳しくは<a target="_blank" href="https://help-atelu.comnico.jp/available-image-and-video">こちら</a>をご確認ください。'
            )
            break
        }

        this.showError(title, message)

        return
      }

      this.isLoadMedia = true

      const formData = new FormData()
      formData.append('file', file)

      if (file.type.includes('video')) {
        const mediaCheck = await TwitterUpload.checkMedia(formData)

        if (!mediaCheck) {
          const title = this.$gettext('画像・動画アップロードに失敗しました。')
          const message = this.$gettext(
            '詳しくは<a target="_blank" href="https://help-atelu.comnico.jp/available-image-and-video">こちら</a>をご確認ください。'
          )

          this.showError(title, message)
          this.isLoadMedia = false

          return
        }
      }

      formData.append('accountId', this.campaign.account.id)
      formData.append('forDM', 'true')

      const result = await TwitterUpload.uploadMedia(formData)

      // アップロードプログレスを取得
      const progressResult = await this.getUploadProgress(result.data.filename)

      if (progressResult) {
        const media = {
          mediaOriginName: file.name,
          mediaUrl: progressResult.mediaUrl,
          twMediaId: progressResult.mediaId,
          expiresAfterSecs: progressResult.expiresAfterSecs
        }

        this.media = this.media ? Object.assign(this.media, media) : media
      }

      this.isLoadMedia = false
    },

    /**
     * アップロードプログレスを取得
     * @param {string} filename
     */
    getUploadProgress(filename: string): Promise<any> {
      return new Promise((resolve, reject) => {
        this.uploadTimer = setInterval(async () => {
          const progress = await api.get(`/twitter_uploads/progresses?filename=${filename}`)
          if (progress.data) {
            clearInterval(this.uploadTimer)
            resolve(progress.data)
          }

          if (progress.error) {
            if (progress.error.statusCode === 400) {
              const title = this.$gettext('画像・動画アップロードに失敗しました。')
              const message = this.$gettext(
                '詳しくは<a target="_blank" href="https://help-atelu.comnico.jp/available-image-and-video">こちら</a>をご確認ください。'
              )

              this.showError(title, message)
            } else {
              const title = this.$gettext('画像・動画アップロードに失敗しました。')
              const message = this.$gettext('恐れ入りますが、時間をおいて再度お試しください。')

              this.showError(title, message)
            }

            clearInterval(this.uploadTimer)
            resolve(null)
          }
        }, 3000)
      })
    },

    /**
     * Change screen
     * @param {string} tab
     */
    changeTab(tab: string) {
      if (!this.isChangeWithoutSubmit) {
        return this.$emit('change_tab', tab)
      }

      const confirm = window.confirm(this.$gettext('編集内容が保存されていません。ダイアログを閉じてよろしいですか?'))

      if (!confirm) {
        return
      }

      this.$emit('update:isChangeWithoutSubmit', false)
      this.$emit('change_tab', tab)
    },

    /**
     * メッセージのデータを取得
     */
    getMessageData() {
      return {
        messageId: this.messageId,
        campaignId: this.campaign.id,
        type: this.type,
        body: this.body,
        actionDatetime: this.getActionDatetime(),
        endTime: this.endTime,
        sendLimitPerDay: this.sendLimitPerDay,
        sentAccountFilterType: this.sentAccountFilterType,
        answeredAccountFilterType: this.answeredAccountFilterType,
        media: this.media
      }
    }
  },
  validations: {
    body: {
      required
    }
  }
}
