import ChartDoughnut from '../chart_doughnut'

export default {
  components: { ChartDoughnut },
  props: {
    data: Array,
    height: {
      type: Number,
      default: 0
    },
    chartStyles: {
      type: Object,
      default: () => ({})
    },
    isTikTok: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    data() {
      this.update()
    }
  },
  computed: {
    isEmpty() {
      return this.data.every(follower => follower.count === 0)
    }
  },
  data() {
    return {
      options: {
        title: {
          display: false,
          text: this.isTikTok ? 'トラフィックソース' : '応募者のフォロワー数'
        },
        legend: {
          display: true,
          hidden: true,
          position: 'right',
          labels: {
            fontSize: 18,
            padding: 8,
            boxWidth: 64,
            generateLabels: function(data) {
              const chartData = data.tooltip._data
              const chartDataset = chartData.datasets[0]
              const chartLabels = chartData.labels
              const legendArray = []

              const totalData = chartData.datasets[0].data.reduce((total, current) => total + current, 0)

              for (let i = 0; i < chartLabels.length; i++) {
                const eachLabel = chartLabels[i]
                const eachData = chartDataset.data[i]
                const eachPercent = !totalData ? 0 : Math.round((eachData / totalData) * Math.pow(100, 2)) / 100
                const backgroundColor = chartDataset.backgroundColor[i]

                const eachLengend = {
                  text: `${eachLabel}：${eachData.toLocaleString()}人(${eachPercent}%)`,
                  fillStyle: backgroundColor,
                  strokeStyle: 'white'
                }

                legendArray.push(eachLengend)
              }

              return legendArray
            }
          }
        },
        tooltips: {
          callbacks: {
            label: function(tooltipItem, data) {
              const label = data.labels[tooltipItem.index]
              const value = data.datasets[0].data[tooltipItem.index]
              return `${label}: ${value.toLocaleString()}人`
            }
          }
        }
      },
      chartData: {
        labels: null,
        datasets: [
          {
            backgroundColor: [
              'rgba(189,189,189,0.8)',
              'rgba(92,107,192,0.8)',
              'rgba(2,119,189,0.8)',
              'rgba(38,166,154,0.8)',
              'rgba(255,167,38,0.8)',
              'rgba(239,83,80,0.8)',
              'rgba(242, 91,141,0.8)',
              'rgba(188,42,141,1)'
            ],
            data: null
          }
        ]
      }
    }
  },
  mounted() {
    this.update()
  },
  methods: {
    update() {
      const labels = this.data.map(d => d.label)
      const data = this.data.map(d => d.count)

      this.chartData.labels = labels
      this.chartData.datasets[0].data = data

      if (this.$refs.chart) {
        if (this.height) {
          this.options.maintainAspectRatio = false
          this.$el.querySelector('canvas#doughnut-chart').style.height = this.height + 'px'
        }

        this.$refs.chart.update()
      }
    }
  }
}
