
















import { computed, defineComponent, PropType } from '@vue/composition-api'

import { AppLabel } from '@/client/components/_atoms'
import { getTimeFormat as f } from '@/client/utils/filters'

import { PropStreamType } from './types'

export default defineComponent({
  name: 'ApplicantStreamItem',
  components: {
    AppLabel
  },
  props: {
    stream: {
      type: Object as PropType<PropStreamType>,
      required: true
    }
  },
  setup(props) {
    const status = computed(() => {
      if (props.stream.status === 'sent') return 'success'
      if (props.stream.status === 'failed') return 'danger'
      return 'default'
    })

    const sendType = computed(() => {
      let text = ''

      if (props.stream.send_type === 'DM') text += 'DM配信'
      if (props.stream.send_type === 'mention') text += 'メンション配信'
      if (props.stream.status === 'sent') text += '成功'
      if (props.stream.status === 'failed') text += '失敗'

      return text
    })

    return {
      status,
      sendType,
      f
    }
  }
})
