import { mapActions, mapState } from 'vuex'

import EntryHistoryTable from '@/client/components/basic/entry_history_table'
import Pagination from '@/client/components/basic/pagination'
import Util from '@/client/core/util'

export default {
  data() {
    return {
      title: '',
      accountId: 0,
      isWinner: 0,
      visible: false
    }
  },
  components: {
    EntryHistoryTable,
    Pagination
  },
  computed: {
    ...mapState('system', {
      groupId: state => state.currentGroup.id
    }),
    ...mapState('twitterInstantwinEntryHistory', {
      params: state => state.params,
      options: state => {
        return {
          sortBy: state.params.sortBy,
          pagingNo: state.params.pagingNo,
          limitCount: state.params.limitCount,
          total: state.total
        }
      },
      loading: state => state.loading,
      entryHistories: state => state.entryHistories,
      error: state => state.error
    })
  },
  watch: {
    params() {
      this.fetchEntryHistory()
    },
    error(err) {
      if (err) {
        this.visible = false

        this.$notify({
          title: this.$gettext('データ取得に失敗しました。'),
          message: this.$gettext('恐れ入りますが、時間を置いて再度お試しください。'),
          customClass: 'danger',
          duration: 5000
        })
      }
    }
  },
  methods: {
    ...mapActions('twitterInstantwinEntryHistory', ['reset', 'fetchEntryHistory', 'setParams']),
    /**
     * 変更イベント
     * @param {any} payload ページ
     */
    onChangePaging(payload: any) {
      this.setParams(payload)
      Util.scrollToTop()
    },

    /**
     * ソートを変更イベント
     * @param {any} payload ソート
     */
    onChangeSortBy(payload: any) {
      if (this.params.sortBy === payload.sortBy) {
        return
      }
      this.setParams(payload)
    },

    /**
     * ダイアログをあく
     * @param {string} accountName account name
     * @param {string} accountId account id
     * @param {number} isWinner is winner
     */
    open(accountName: string, accountId: string, isWinner: number) {
      this.visible = true
      this.accountId = accountId
      this.isWinner = isWinner

      if (isWinner) {
        this.title = accountName + ' ' + this.$gettext('さんの当選履歴')
      } else {
        this.title = accountName + ' ' + this.$gettext('さんの応募履歴')
      }

      this.setParams({ accountId: this.accountId, groupId: this.groupId, isWinner: this.isWinner })
    },

    /**
     * ダイアログ閉じるイベント
     */
    beforeClose(done) {
      this.title = ''
      this.accountId = 0
      this.isWinner = 0
      this.reset()
      done()
    }
  }
}
