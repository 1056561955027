/* @flow */
import InstantwinMessageAddEdit from '@/client/components/twitter_instantwin_message/instantwin_message_add_edit'

export default {
  components: {
    InstantwinMessageAddEdit
  },
  data() {
    return {
      visible: false,
      title: '',
      mode: '',
      messageId: 0,
      messageType: '',
      isChangeWithoutSubmit: false
    }
  },
  methods: {
    /**
     * ダイアログを開く
     */
    async open(type: string, messageId: number, messageType: string = '') {
      this.visible = true
      this.messageType = messageType
      switch (type) {
        case 'edit':
          this.mode = 'edit'
          this.messageId = messageId
          this.title = 'test'
          if (messageType === 'win') {
            this.title = this.$gettext('自動配信メッセージ(当選)の編集')
          } else {
            this.title = this.$gettext('自動配信メッセージ(落選)の編集')
          }
          break
        case 'copy':
          this.mode = 'add'
          this.messageId = messageId
          this.title = this.$gettext('自動配信メッセージ')
          this.changeMessageType(this.messageType)
          break
        default:
          this.mode = 'add'
          this.title = this.$gettext('自動配信メッセージ')
          this.changeMessageType(this.messageType)
          break
      }
    },

    close() {
      this.visible = false
      this.title = ''
      this.mode = ''
      this.messageId = 0
      this.messageType = ''
      this.isChangeWithoutSubmit = false
    },

    beforeClose() {
      if (!this.isChangeWithoutSubmit) {
        return this.close()
      }

      const confirm = window.confirm(this.$gettext('編集内容が保存されていません。ダイアログを閉じてよろしいですか?'))

      if (!confirm) {
        return
      }

      this.close()
    },

    /**
     * 種別選択
     * @param {string} type
     */
    changeMessageType(type: string) {
      this.messageType = type
      if (type === 'win') {
        this.title = this.title + this.$gettext('(当選)の新規作成')
      } else if (type === 'lose') {
        this.title = this.title + this.$gettext('(落選)の新規作成')
      }
    }
  }
}
