/* @flow */
import moment from 'moment'
import twitterText from 'twitter-text'
import { required } from 'vuelidate/lib/validators'
import { mapActions, mapState } from 'vuex'

import Message from '@/client/components/_atoms/Message.vue'
import Panel from '@/client/components/_atoms/Panel.vue'
import FormItemLabel from '@/client/components/_molecules/FormItemLabel.vue'
import Editable from '@/client/components/basic/editable'
import MessageConvert from '@/client/components/basic/message_convert'
import TagInput from '@/client/components/basic/tag_input'
import { CAMPAIGN_STATUS, CARD_TYPE, MEDIA_TYPE } from '@/client/constant'
import api from '@/client/core/api'
import TwitterUpload from '@/client/core/twitter_upload'

export default {
  components: {
    TagInput,
    Editable,
    MessageConvert,
    Message,
    Panel,
    FormItemLabel
  },
  props: {
    mode: String,
    messageId: Number,
    messageType: String,
    visible: Boolean,
    isChangeWithoutSubmit: Boolean
  },
  data() {
    return {
      startDate: '',
      startTime: '',
      endDate: '',
      endTime: '',
      uploadingMentionMedia: false,
      uploadingMessageMedia: false,
      uploadMessageTimer: null,
      uploadMentionTimer: null,
      isSubmit: false,
      maxMentionLength: 263,
      maxMessageLength: 4000,
      message: {
        id: '',
        name: '',
        messageType: '',
        winnerCount: 1,
        winningRate: 0.01,
        winningRateChangeType: 'none',
        dailyWinnerCount: 0,
        dailyWinnerChangeTime: '00:00',
        elapsedRate: 1,
        elapsedWinningRate: 1,
        weightCount: 1,
        entryKeyword: [],
        commentKeyword: [],
        replyType: 'mention',
        mentionBody: '',
        messageBody: '',
        mentionMedia: null,
        messageMedia: null,
        mentionCard: null,
        isDailyLottery: 0,
        excludeMessageMultiWinner: 1,
        excludeOtherMessageMultiWinner: 1,
        isPublicReply: 0,
        mentionMediaType: 'none',
        messageMediaType: 'none',
        isSerialCodeConfigured: false
      },
      errors: {
        notInputStartDate: false,
        lessThanCurrentTime: false,
        lessThanStartTime: false,
        notInputEndDate: false,
        mentionNotContainLink: false
      },
      datePickerOption: {
        disabledDate(time: any) {
          return moment(time.getTime()).format('YYYY-MM-DD') < moment().format('YYYY-MM-DD')
        }
      },
      timePickerOption: {
        start: '00:00',
        step: '01:00',
        end: '23:00'
      },
      mentionCardOptions: {
        campaign_id: null,
        keyword: '',
        card_type: 'all',
        media_type: 'image',
        next_cursor: ''
      },
      messageTemplates: [],
      oldMentionTemplate: '',
      mentionTemplate: '',
      oldMessageTemplate: '',
      messageTemplate: '',
      highlights: [{ type: 'info', keywords: ['_account_name_', '_screen_name_', '_account_id_', '_serial_code_'] }],
      isMessageValidating: false,
      isMentionValidating: false,
      rawData: {},
      timeout: null,
      mentionCardId: '',
      mentionCards: []
    }
  },
  computed: {
    ...mapState('twitterInstantwinMessage', {
      campaign: state => {
        if (state.campaign) {
          return state.campaign
        }

        return {}
      }
    }),

    ...mapState('system', {
      currentGroup: state => state.currentGroup,
      allowCreateInstantwin: state => Boolean(state.currentGroup.allowCreateInstantwin),
      isSystemAdmin: state => Boolean(state.user.isSystemAdmin)
    }),

    cardTypeList() {
      return CARD_TYPE
    },

    mediaTypeList() {
      return MEDIA_TYPE
    },

    cardType() {
      const card = this.cardTypeList.find(card => card.value === this.mentionCardOptions.card_type)

      return card ? card.label : ''
    },

    excludeMessageMultiWinnerDisabled() {
      return (this.message.excludeMessageMultiWinner && !!this.campaign.setting.excludeMultiWinner) || this.isFinished
    },

    excludeOtherMessageMultiWinnerTooltip() {
      if (this.campaign.setting.excludeMultiWinner) {
        return 'OFFに変更したい場合、キャンペーン設定の当キャンペーンで当選実績がある場合、落選にするフラグをOFFにしてください。'
      }
      return '当キャンペーン内に別メッセージですでに当選している場合、落選となります。'
    },

    excludeOtherMessageMultiWinnerDisabled() {
      return (
        (this.message.excludeOtherMessageMultiWinner && !!this.campaign.setting.excludeMultiWinner) || this.isFinished
      )
    },

    mentionBodyCount() {
      return twitterText.parseTweet(this.message.mentionBody).weightedLength
    },

    dailySendLimitCount() {
      return this.campaign.setting.dailySendLimitCount
    },

    messageBodyCount() {
      return twitterText.parseTweet(this.message.messageBody).weightedLength
    },

    hasSerialCodeInMessageBody() {
      return /_serial_code_/.test(this.message.messageBody)
    },

    hasMentionBody() {
      return (
        (this.message.replyType === 'mention' || this.message.replyType === 'mention-and-dm') &&
        (this.message.messageType === 'win' || this.message.messageType === 'lose')
      )
    },

    hasMessageBody() {
      return (
        (this.message.replyType === 'dm' || this.message.replyType === 'mention-and-dm') &&
        this.message.messageType === 'win'
      )
    },

    hasAdsAccountId() {
      if (this.campaign && this.campaign.account && this.campaign.account.adsAccountId) {
        return true
      }

      return false
    },

    hasMessageCards() {
      return Array.isArray(this.mentionCards)
    },

    hasNextCursor() {
      return !!this.mentionCardOptions.next_cursor
    },

    isPreStart() {
      if (this.campaign) {
        return this.campaign.status === CAMPAIGN_STATUS.BEFORE_EVENT
      }

      return false
    },

    isStarting() {
      if (this.campaign) {
        return [CAMPAIGN_STATUS.BEFORE_COLLECTION, CAMPAIGN_STATUS.DURING_COLLECTION].includes(this.campaign.status)
      }

      return false
    },

    isFinished() {
      if (this.campaign) {
        return [CAMPAIGN_STATUS.DURING_FINAL_COLLECTION, CAMPAIGN_STATUS.COLLECTION_COMPLETED].includes(
          this.campaign.status
        )
      }

      return false
    },

    isMessageImageVideo() {
      return this.message.messageMediaType === 'image_video'
    },

    isMentionImageVideo() {
      return this.message.mentionMediaType === 'image_video'
    },

    isMentionCard() {
      return this.message.mentionMediaType === 'card'
    },

    isPreviewMentionCard() {
      return this.message.mentionCard && !this.mentionCardId
    },

    formValid() {
      if (
        this.campaign &&
        [CAMPAIGN_STATUS.DURING_FINAL_COLLECTION, CAMPAIGN_STATUS.COLLECTION_COMPLETED].includes(this.campaign.status)
      ) {
        return true
      }

      if (!this.timeValid) {
        return false
      }

      this.$v.$touch()

      if (!this.message.messageType) {
        return false
      }

      if (
        (this.mentionBodyCount > this.maxMentionLength || !this.message.mentionBody.trim()) &&
        (this.message.replyType === 'mention' || this.message.replyType === 'mention-and-dm')
      ) {
        return false
      }

      if (
        (this.messageBodyCount > this.maxMessageLength || !this.message.messageBody.trim()) &&
        (this.message.replyType === 'dm' || this.message.replyType === 'mention-and-dm')
      ) {
        return false
      }

      for (const key in this.errors) {
        if (this.errors[key]) {
          if (key === 'mentionNotContainLink' && this.message.replyType === 'dm') {
            continue
          }

          if (
            key === 'mentionNotContainLink' &&
            (this.message.replyType === 'mention' || this.message.replyType === 'mention-and-dm') &&
            this.dailySendLimitCount < 2
          ) {
            continue
          }

          return false
        }
      }

      return true
    },

    timeValid() {
      const startTime = this.startTime || '00:00'
      const endTime = this.endTime || '00:00'
      const startDatetime = `${this.startDate} ${startTime}`
      const endDatetime = `${this.endDate} ${endTime}`

      if (this.startTime && !this.startDate) {
        this.errors.notInputStartDate = true
      } else {
        this.errors.notInputStartDate = false
      }

      if (this.startDate && this.campaign.status === CAMPAIGN_STATUS.BEFORE_EVENT) {
        this.errors.lessThanCurrentTime = startDatetime < moment().format('YYYY-MM-DD HH:mm')
        const campaignStartTime = moment(this.campaign.startDatetime)
        if (startDatetime < campaignStartTime.format('YYYY-MM-DD HH:mm') && !this.errors.lessThanCurrentTime) {
          this.startDate = campaignStartTime.format('YYYY-MM-DD')
          this.startTime = campaignStartTime.format('HH:mm')
        }
      } else {
        this.errors.lessThanCurrentTime = false
      }

      if (this.endTime && !this.endDate) {
        this.errors.notInputEndDate = true
      } else {
        this.errors.notInputEndDate = false
      }

      if (this.endDate && this.campaign.status !== 0) {
        if (this.startDate) {
          this.errors.lessThanStartTime = endDatetime < startDatetime
        } else {
          this.errors.lessThanStartTime = false
        }

        const campaignEndDateTime = moment(this.campaign.endDatetime)
        if (endDatetime > campaignEndDateTime.format('YYYY-MM-DD HH:mm') && !this.errors.lessThenStartTime) {
          this.endDate = campaignEndDateTime.format('YYYY-MM-DD')
          this.endTime = campaignEndDateTime.format('HH:mm')
        }
      } else {
        this.errors.lessThanStartTime = false
      }

      return (
        !this.errors.notInputStartDate &&
        !this.errors.lessThanCurrentTime &&
        !this.errors.notInputEndDate &&
        !this.errors.lessThanStartTime
      )
    },

    submitData() {
      const data: any = {
        message: {
          campaignId: this.campaign.id,
          name: this.message.name,
          messageType: this.messageType,
          weightCount: this.message.weightCount,
          entryKeyword:
            this.message.entryKeyword && this.message.entryKeyword.length
              ? JSON.stringify(this.message.entryKeyword)
              : null,
          commentKeyword:
            this.message.commentKeyword && this.message.commentKeyword.length
              ? JSON.stringify(this.message.commentKeyword)
              : null
        }
      }

      const messageContent: any = {
        replyType: this.message.replyType
      }

      if (this.message.replyType.includes('mention')) {
        messageContent.mentionMediaType = this.message.mentionMediaType
        messageContent.mentionBody = this.message.mentionBody

        if (this.message.mentionMediaType === 'image_video') {
          if (this.message.mentionMedia) {
            messageContent.mentionMedia = this.message.mentionMedia
          } else {
            messageContent.mentionMediaType = 'none'
          }
        }

        if (this.message.mentionMediaType === 'card') {
          if (this.message.mentionCard) {
            messageContent.mentionCard = { ...this.message.mentionCard }
          } else {
            messageContent.mentionMediaType = 'none'
          }
        }
      }

      if (this.message.replyType.includes('dm')) {
        messageContent.messageMediaType = this.message.messageMediaType
        messageContent.messageBody = this.message.messageBody

        if (this.message.messageMediaType === 'image_video') {
          if (this.message.messageMedia) {
            messageContent.messageMedia = this.message.messageMedia
          } else {
            messageContent.messageMediaType = 'none'
          }
        }
      }

      data.messageContent = messageContent

      if (this.startDate) {
        const startTime = this.startTime || '00:00'
        data.message.startDatetime = moment(`${this.startDate} ${startTime}`).format()
      } else {
        data.message.startDatetime = null
      }

      if (this.endDate) {
        const endTime = this.endTime || '00:00'
        data.message.endDatetime = moment(`${this.endDate} ${endTime}`).format()
      } else {
        data.message.endDatetime = null
      }

      if (this.message.messageType === 'win') {
        data.message.winnerCount = this.message.winnerCount
        data.message.winningRate = this.message.winningRate
        data.message.winningRateChangeType = this.message.winningRateChangeType
        data.message.dailyWinnerCount = this.message.dailyWinnerCount
        data.message.dailyWinnerChangeTime = `${this.message.dailyWinnerChangeTime}:00`

        data.message.elapsedRate = this.message.winningRateChangeType === 'time' ? this.message.elapsedRate : null
        data.message.elapsedWinningRate =
          this.message.winningRateChangeType === 'time' ? this.message.elapsedWinningRate : null
      } else {
        data.message.winnerCount = 0
        data.message.winningRate = 0
        data.message.winningRateChangeType = 'none'
        data.message.dailyWinnerCount = 0
        data.message.dailyWinnerChangeTime = ''

        data.message.elapsedRate = null
        data.message.elapsedWinningRate = null
      }

      if (this.message.replyType === 'dm') {
        data.message.isPublicReply = 0
      } else {
        data.message.isPublicReply = this.message.isPublicReply
      }

      if (!this.hasAdsAccountId) {
        data.message.isPublicReply = 1
      }

      data.message.isDailyLottery = this.message.isDailyLottery
      data.message.excludeMessageMultiWinner = this.message.excludeMessageMultiWinner
      data.message.excludeOtherMessageMultiWinner = this.message.excludeOtherMessageMultiWinner

      return data
    },

    messageLoadingText() {
      if (this.isMessageValidating) {
        return '画像・動画を検証しています。 しばらくお待ちください。'
      }

      return '画像・動画をアップロードしています。 しばらくお待ちください。'
    },

    mentionLoadingText() {
      if (this.isMentionValidating) {
        return '画像・動画を検証しています。 しばらくお待ちください。'
      }

      return '画像・動画をアップロードしています。 しばらくお待ちください。'
    },

    isFormChanged() {
      return JSON.stringify(this.submitData) !== JSON.stringify(this.rawData)
    },

    isIncludeQuoteTweets() {
      if (!this.campaign || !this.campaign.setting || !this.campaign.setting.entryActionType) {
        return false
      }

      return ['retweet_and_quote_tweet', 'quote_tweet'].includes(this.campaign.setting.entryActionType)
    },

    quoteKeywordText() {
      if (!this.campaign || !this.campaign.setting || !this.campaign.setting.entryActionType) {
        return ''
      }

      return this.campaign.setting.entryActionType === 'quote_tweet'
        ? '引用のコメントに以下のキーワードを含む'
        : '引用の場合、コメントに以下のキーワードを含む'
    },
    canNotEditInstantwin() {
      return !this.isSystemAdmin && !this.allowCreateInstantwin
    }
  },
  watch: {
    visible() {
      if (this.uploadMentionTimer) {
        clearInterval(this.uploadMentionTimer)
      }
      if (this.uploadMessageTimer) {
        clearInterval(this.uploadMessageTimer)
      }
    },

    'message.messageType'(value: string) {
      if (value === 'lose') {
        this.message.replyType = 'mention'
      }
    },

    'message.mentionBody'(value: string) {
      // URLを出力する。TwitterカードURLの場合は、除外する
      const urls: string[] = twitterText
        .extractUrls(value)
        .filter(url => url.indexOf('cards.twitter.com/cards/') === -1)

      if (!value.trim() || urls.length) {
        this.errors.mentionNotContainLink = false
      } else {
        this.errors.mentionNotContainLink = true
      }
    },

    isFormChanged(value: boolean) {
      this.$emit('update:isChangeWithoutSubmit', value)
    }
  },

  async created() {
    this.resetForm()

    if (this.messageId) {
      await this.getDataById(this.messageId)
    }

    await this.getMessageTemplates()

    this.rawData = this.submitData
    this.mentionCardOptions.campaign_id = this.campaign.id

    await this.getCardList()
  },
  methods: {
    ...mapActions('twitterInstantwinMessage', ['fetchInstantwinMessages']),

    /**
     * URLがビデオかどうかを判定する
     */
    isUrlVideo(mediaUrl: string) {
      if (!mediaUrl) return ''

      // media_typeだとcarouselの場合に一つ目のメディアが画像か動画かを判定できないため、URLから判定する
      const url = new URL(mediaUrl)

      return /\.mp4$/.test(url.pathname)
    },

    /**
     * カードを作成
     */
    cardCreated(date: string) {
      return moment(date).format('YYYY/MM/DD')
    },

    /**
     * カードタイプ表示
     */
    cardTypeLabel(type: string) {
      const cardType = CARD_TYPE.find(v => v.value === type)

      if (cardType) return cardType.label

      return ''
    },

    /**
     * カードの変更を処理
     */
    handleCardChange() {
      const card = this.mentionCards.find(card => card.card_id === this.mentionCardId)

      this.message.mentionCard = card
        ? {
            cardId: card.card_id,
            cardUri: card.card_uri,
            cardName: card.card_name,
            cardType: this.mentionCardOptions.card_type,
            mediaUrl: card.media_url,
            mediaType: card.media_type,
            createdAt: card.created
          }
        : null
    },

    async getMessageTemplates() {
      const params = {
        groupId: this.currentGroup.id,
        sortBy: 'name'
      }

      const messageTemplates = await api.get('/message_templates', {
        params
      })

      if (messageTemplates && messageTemplates.data && messageTemplates.data.templates) {
        this.messageTemplates = messageTemplates.data.templates
      }
    },

    /**
     * メッセージを作成
     */
    async createMessage() {
      if (this.isSubmit || !this.formValid) {
        return
      }

      this.isSubmit = true

      let uploadHasError = false
      // メンションメディアをアップロード
      if (this.message.mentionMedia && !this.message.mentionMedia.mediaId) {
        this.uploadingMentionMedia = true
        const data = {
          accountId: this.campaign.account.id,
          mediaUrl: this.message.mentionMedia.mediaUrl
        }

        uploadHasError = await this.uploadMedia(data, this.uploadMentionTimer, 'mention')
        this.uploadingMentionMedia = false
      }

      // メッセージメディアをアップロード
      if (this.message.messageMedia && !this.message.messageMedia.mediaId) {
        this.uploadingMessageMedia = true
        const data = {
          accountId: this.campaign.account.id,
          mediaUrl: this.message.messageMedia.mediaUrl
        }

        uploadHasError = await this.uploadMedia(data, this.uploadMessageTimer, 'message')
        this.uploadingMessageMedia = false
      }

      if (uploadHasError) {
        this.isSubmit = false
        return
      }

      const data = this.submitData
      const result = await api.post('/twitter_instantwin_messages', data)
      setTimeout(() => {
        this.isSubmit = false
      }, 2000)

      if (!result.data) {
        this.$notify({
          title: this.$gettext('インスタントウィンメッセージの作成に失敗しました。'),
          customClass: 'danger',
          duration: 5000
        })
        return
      }

      // 成功メッセージを表示
      this.$notify({
        title: this.$gettext('インスタントウィンメッセージを作成しました。'),
        customClass: 'success',
        duration: 5000
      })

      this.$emit('update:isChangeWithoutSubmit', false)

      // インスタントウィンメッセージ一覧を取得
      this.fetchInstantwinMessages()

      this.$emit('close')
    },

    /**
     * Execute upload media
     * @param {{ accountId: number, mediaUrl: string }} data
     * @param {any} timer
     * @param {string} typeMedia
     */
    async uploadMedia(data: { accountId: number, mediaUrl: string }, timer, typeMedia: string) {
      const media = await api.post('/twitter_uploads', data, {
        headers: {
          'Content-Type': undefined
        }
      })

      // アップロードプログレスを取得
      const progressResult = await this.getUploadProgress(media.data.filename, typeMedia)
      if (progressResult) {
        if (typeMedia === 'mention') {
          progressResult.mediaOriginName = this.message.mentionMedia.mediaOriginName
          this.message.mentionMedia = progressResult
        } else {
          progressResult.mediaOriginName = this.message.messageMedia.mediaOriginName
          this.message.messageMedia = progressResult
        }

        return false
      }

      return true
    },

    /**
     * Delete message
     */
    async deleteMessage() {
      const confirm = window.confirm(this.$gettext('メッセージを削除してよろしいですか？'))
      if (!confirm) {
        return
      }
      const result = await api.delete('/twitter_instantwin_messages/' + this.message.id)

      if (result.error && result.error.type === 'NOT_EXISTS') {
        this.$notify({
          title: this.$gettext('対象のメッセージはすでに削除されています。'),
          customClass: 'danger',
          duration: 5000
        })
        this.$emit('close')
        // インスタントウィンメッセージ一覧を取得
        await this.fetchInstantwinMessages()
        return
      }

      // エラーがある場合はエラーメッセージを表示
      if (!result.data) {
        this.$notify({
          title: this.$gettext('メッセージの削除に失敗しました。'),
          message: this.$gettext('恐れ入りますが、時間をおいて再度お試しください。'),
          customClass: 'danger',
          duration: 5000
        })
        return
      }

      // 成功メッセージを表示
      this.$notify({
        title: this.$gettext('メッセージを削除しました。'),
        customClass: 'success',
        duration: 5000
      })

      this.$emit('update:isChangeWithoutSubmit', false)

      // インスタントウィンメッセージ一覧を取得
      this.fetchInstantwinMessages()

      this.$emit('close')
    },

    /**
     * メッセージを更新
     */
    async updateMessage() {
      if (this.isSubmit || !this.formValid) {
        return
      }

      const data = this.submitData
      this.isSubmit = true
      const result = await api.put(`/twitter_instantwin_messages/${this.message.id}`, data)
      setTimeout(() => {
        this.isSubmit = false
      }, 2000)

      if (!result.data) {
        this.$notify({
          title: this.$gettext('インスタントウィンメッセージの更新に失敗しました。'),
          customClass: 'danger',
          duration: 5000
        })
        return
      }

      // 成功メッセージを表示
      this.$notify({
        title: this.$gettext('インスタントウィンメッセージを更新しました。'),
        customClass: 'success',
        duration: 5000
      })

      this.$emit('update:isChangeWithoutSubmit', false)

      // インスタントウィンメッセージ一覧を取得
      this.fetchInstantwinMessages()

      this.$emit('close')
    },

    /**
     * カードリストを取得
     */
    async getCardList(type?: string) {
      if (type === 'more') {
        if (!this.hasNextCursor) {
          return
        }

        const result = await this.getCardsFromApi()

        this.mentionCards = [...this.mentionCards, ...result.cards]
        this.mentionCardOptions.next_cursor = result.next_cursor

        return
      }

      if (type === 'input') {
        if (this.timeout) {
          clearTimeout(this.timeout)
        }

        this.timeout = setTimeout(async () => {
          this.mentionCardOptions.next_cursor = ''

          const result = await this.getCardsFromApi()

          this.mentionCards = result.cards
          this.mentionCardOptions.next_cursor = result.next_cursor
        }, 500)

        return
      }

      if (!type || type === 'select') {
        const result = await this.getCardsFromApi()

        this.mentionCards = result.cards
        this.mentionCardOptions.next_cursor = result.next_cursor
      }
    },

    async getCardsFromApi() {
      const result = await api.get('v1/twitter_instantwin_cards', {
        params: this.mentionCardOptions
      })

      return result.data
    },

    /**
     * データを埋める
     * @param {number} messageId
     */
    async getDataById(messageId: number) {
      const result = await api.get(`/twitter_instantwin_messages/${messageId}`)

      if (result.error && result.error.type === 'NOT_EXISTS') {
        this.$notify({
          title: this.$gettext('対象のメッセージはすでに削除されています。'),
          customClass: 'danger',
          duration: 5000
        })
        this.$emit('close')
        // インスタントウィンメッセージ一覧を取得
        await this.fetchInstantwinMessages()
        return
      }

      const message = result.data

      this.message.id = message.id
      this.message.name = message.name
      if (message.startDatetime) {
        this.startDate = moment(message.startDatetime).format('YYYY-MM-DD')
        this.startTime = moment(message.startDatetime).format('HH:mm')
      }

      if (message.endDatetime) {
        this.endDate = moment(message.endDatetime).format('YYYY-MM-DD')
        this.endTime = moment(message.endDatetime).format('HH:mm')
      }

      this.message.messageType = message.messageType
      this.message.winnerCount = message.winnerCount
      this.message.winningRate = message.winningRate
      this.message.winningRateChangeType = message.winningRateChangeType
      this.message.dailyWinnerCount = message.dailyWinnerCount || 0
      this.message.dailyWinnerChangeTime = message.dailyWinnerChangeTime
        ? message.dailyWinnerChangeTime.substring(0, 5)
        : '00:00'

      this.message.elapsedRate = message.elapsedRate
      this.message.elapsedWinningRate = message.elapsedWinningRate
      this.message.weightCount = message.weightCount
      this.message.entryKeyword = message.entryKeyword ? JSON.parse(message.entryKeyword) : []
      this.message.commentKeyword = message.commentKeyword ? JSON.parse(message.commentKeyword) : []
      this.message.isDailyLottery = message.isDailyLottery
      this.message.excludeMessageMultiWinner = message.excludeMessageMultiWinner
      this.message.excludeOtherMessageMultiWinner = message.excludeOtherMessageMultiWinner
      this.message.isPublicReply = message.isPublicReply
      this.message.mediaType = message.tw_instantwin_message_contents[0].mediaType
      this.message.isSerialCodeConfigured = message.isSerialCodeConfigured

      switch (message.tw_instantwin_message_contents.length) {
        case 2:
          this.message.replyType = 'mention-and-dm'
          break
        case 1:
          this.message.replyType = message.tw_instantwin_message_contents[0].replyType
          break
        default:
          this.message.replyType = ''
          break
      }

      for (const content of message.tw_instantwin_message_contents) {
        if (content.replyType.includes('mention')) {
          this.message.mentionBody = content.body
          this.message.mentionMediaType = content.mediaType

          if (content.mediaType === 'image_video' && content.tw_instantwin_message_content_medias.length) {
            const media = content.tw_instantwin_message_content_medias[0]
            this.message.mentionMedia = media
              ? { mediaUrl: media.mediaUrl, mediaOriginName: media.mediaOriginName }
              : null
          }

          if (content.mediaType === 'card' && content.tw_instantwin_message_content_card) {
            this.message.mentionCard = content.tw_instantwin_message_content_card
          }
        }

        if (content.replyType.includes('dm')) {
          this.message.messageBody = content.body
          this.message.messageMediaType = content.mediaType

          if (content.mediaType === 'image_video' && content.tw_instantwin_message_content_medias.length) {
            const media = content.tw_instantwin_message_content_medias[0]
            this.message.messageMedia = media
              ? { mediaUrl: media.mediaUrl, mediaOriginName: media.mediaOriginName }
              : null
          }
        }
      }
    },

    /**
     * 入力番号の変更
     * @param {number} value
     * @param {string} inputName
     */
    handleInputNumberChange(value: ?number, inputName: string) {
      // 1日の最大当選数が当選数より多い場合はリセットする
      if (inputName === 'dailyWinnerCount') {
        if (value > this.message.winnerCount) {
          this.resetNumber(inputName)
        }
        return
      }

      if (!value) {
        this.resetNumber(inputName)
      }

      if (inputName === 'winnerCount' && value <= this.message.dailyWinnerCount) {
        this.resetNumber('dailyWinnerCount')
      }
    },

    /**
     * キーワードの変更
     * @param {string[]} keywords
     */
    handleKeywordsChange(keywords: string[]) {
      const origin = [...this.message.entryKeyword]
      this.message.entryKeyword = keywords

      if (keywords.join('').length > 50) {
        this.message.entryKeyword = [...origin]
        this.$notify({
          title: 'キーワードの合計文字数は、50文字以下にしてください。',
          customClass: 'danger',
          duration: 5000
        })
      }

      const keywordsText = keywords.join('')
      const symbol = /[!"$%&'()*+-.,/:;<=>?@[\\\]^`{|}~¥]/g

      if (keywordsText.match(symbol)) {
        this.message.entryKeyword = [...origin]
        this.$notify({
          title: 'キーワードに記号が含まれています。',
          customClass: 'danger',
          duration: 5000
        })
      }
    },

    /**
     * コメント内キーワードの変更
     */
    handleCommentKeywordsChange(commentKeywords: string[]) {
      const origin = [...this.message.commentKeyword]
      this.message.commentKeyword = commentKeywords

      if (commentKeywords.join('').length > 50) {
        this.message.commentKeyword = [...origin]
        this.$notify({
          title: 'キーワードの合計文字数は、50文字以下にしてください。',
          customClass: 'danger',
          duration: 5000
        })
      }

      const keywordsText = commentKeywords.join('')
      const symbol = /[!"$%&'()*+-.,/:;<=>?@[\\\]^`{|}~¥]/g

      if (keywordsText.match(symbol)) {
        this.message.commentKeyword = [...origin]
        this.$notify({
          title: 'キーワードに記号が含まれています。',
          customClass: 'danger',
          duration: 5000
        })
      }
    },

    /**
     * メディアのエラーを表示する
     * @param {string} error
     * @param {string[]} validFormat
     */
    showError(error: string, validFormat: string[]) {
      let title = ''
      let message = ''

      switch (error) {
        case 'INVALID_MEDIA':
          title = this.$gettext('Please upload valid format!')
          message = validFormat.join(', ')
          break
        case 'MAX_SIZE_VIDEO':
          title = this.$gettext(
            '25MB以上のビデオを利用することはできません。 サイズを調整してからアップロードしてください。'
          )
          break
        case 'MAX_SIZE_IMAGE':
          title = this.$gettext(
            '5MB以上の画像を利用することはできません。サイズを調整してからアップロードしてください。'
          )
          break
        case 'INVALID_DIMENSION':
          title = this.$gettext('画像・動画アップロードに失敗しました。')
          message = this.$gettext(
            '詳しくは<a target="_blank" href="https://help-atelu.comnico.jp/available-image-and-video">こちら</a>をご確認ください。'
          )
          break
      }

      this.$notify({
        title,
        message,
        customClass: 'danger',
        dangerouslyUseHTMLString: true,
        duration: 5000
      })
    },

    /**
     * ファイルのメンションを変更
     */
    async handleMentionFileChange(e) {
      if (!e.target.files || !e.target.files[0]) {
        return
      }

      const file = e.target.files[0]
      this.$refs.mentionMediaInput.value = null

      const validFormat = ['video/mp4', 'image/jpeg', 'image/png', 'image/gif', 'image/pjpeg', 'image/gif']

      // メディアをチェック
      const checkValidMedia = await TwitterUpload.checkValidMedia(file, validFormat)
      if (checkValidMedia.error) {
        this.showError(checkValidMedia.error, validFormat)
        return
      }

      this.uploadingMentionMedia = true

      const formData = new FormData()
      formData.append('file', file)

      this.isMentionValidating = true

      if (file.type.includes('video')) {
        const checkMedia = await TwitterUpload.checkMedia(formData)
        if (!checkMedia) {
          this.$notify({
            title: this.$gettext('画像・動画アップロードに失敗しました。'),
            message: this.$gettext(
              '詳しくは<a target="_blank" href="https://help-atelu.comnico.jp/available-image-and-video">こちら</a>をご確認ください。'
            ),
            customClass: 'danger',
            dangerouslyUseHTMLString: true,
            duration: 5000
          })
          this.uploadingMentionMedia = false
          return
        }
      }

      this.isMentionValidating = false

      formData.append('accountId', this.campaign.account.id)

      const result = await TwitterUpload.uploadMedia(formData)

      // アップロードプログレスを取得
      const progressResult = await this.getUploadProgress(result.data.filename, 'mention')
      if (progressResult) {
        this.message.mentionMedia = progressResult
        this.message.mentionMedia.mediaOriginName = file.name
      }

      this.uploadingMentionMedia = false
    },

    /**
     * メッセージファイルを変更
     */
    async handleMessageFileChange(e) {
      if (!e.target.files || !e.target.files[0]) {
        return
      }

      const file = e.target.files[0]
      this.$refs.messageMediaInput.value = null

      const validFormat = ['video/mp4', 'image/jpeg', 'image/png', 'image/gif', 'image/pjpeg', 'image/gif']

      // メディアをチェック
      const checkValidMedia = await TwitterUpload.checkValidMedia(file, validFormat)
      if (checkValidMedia.error) {
        this.showError(checkValidMedia.error, validFormat)
        return
      }

      const formData = new FormData()
      formData.append('file', file)

      this.uploadingMessageMedia = true

      if (file.type.includes('video')) {
        const checkMedia = await TwitterUpload.checkMedia(formData)
        if (!checkMedia) {
          this.$notify({
            title: this.$gettext('画像・動画アップロードに失敗しました。'),
            message: this.$gettext(
              '詳しくは<a target="_blank" href="https://help-atelu.comnico.jp/available-image-and-video">こちら</a>をご確認ください。'
            ),
            customClass: 'danger',
            dangerouslyUseHTMLString: true,
            duration: 5000
          })
          this.uploadingMessageMedia = false
          return
        }
      }

      this.isMessageValidating = true

      this.isMessageValidating = false

      formData.append('accountId', this.campaign.account.id)
      formData.append('forDM', 'true')

      const result = await TwitterUpload.uploadMedia(formData)

      // アップロードプログレスを取得
      const progressResult = await this.getUploadProgress(result.data.filename, 'message')
      if (progressResult) {
        this.message.messageMedia = progressResult
        this.message.messageMedia.mediaOriginName = file.name
      }

      this.uploadingMessageMedia = false
    },

    /**
     * change message template
     * @param {number} templateId
     */
    onChangeMessageTemplate(templateId: number) {
      const currentTemplate = this.messageTemplates.find(template => template.id === templateId)
      const body = currentTemplate.body

      if (!this.message.messageBody) {
        this.message.messageBody = body
        this.oldMessageTemplate = templateId
        return
      }

      const confirmChange = confirm(
        this.$gettext('メッセージがすでに入力されています。テンプレートの内容に置き換えてよろしいですか?')
      )
      if (!confirmChange) {
        this.messageTemplate = this.oldMessageTemplate
        return
      }
      this.oldMessageTemplate = templateId
      this.message.messageBody = body
    },

    /**
     * change mention template
     * @param {number} templateId
     */
    onChangeMentionTemplate(templateId: number) {
      const currentTemplate = this.messageTemplates.find(template => template.id === templateId)
      const body = currentTemplate.body

      if (!this.message.mentionBody) {
        this.message.mentionBody = body
        this.oldMentionTemplate = templateId
        return
      }

      const confirmChange = confirm(
        this.$gettext('メッセージがすでに入力されています。テンプレートの内容に置き換えてよろしいですか?')
      )
      if (!confirmChange) {
        this.mentionTemplate = this.oldMentionTemplate
        return
      }
      this.oldMentionTemplate = templateId
      this.message.mentionBody = body
    },

    /**
     * アップロードプログレスを取得
     * @param {string} filename
     */
    getUploadProgress(filename: string, type: string) {
      return new Promise((resolve, reject) => {
        const timer = setInterval(async () => {
          const progress = await api.get(`/twitter_uploads/progresses?filename=${filename}`)

          if (progress.data) {
            clearInterval(timer)
            resolve(progress.data)
          }

          if (progress.error) {
            if (progress.error.statusCode === 400) {
              this.$notify({
                title: this.$gettext('画像・動画アップロードに失敗しました。'),
                message: this.$gettext(
                  '詳しくは<a target="_blank" href="https://help-atelu.comnico.jp/available-image-and-video">こちら</a>をご確認ください。'
                ),
                customClass: 'danger',
                dangerouslyUseHTMLString: true,
                duration: 5000
              })
            } else {
              this.$notify({
                title: this.$gettext('画像・動画アップロードに失敗しました。'),
                message: this.$gettext('恐れ入りますが、時間をおいて再度お試しください。'),
                customClass: 'danger',
                duration: 5000
              })
            }

            clearInterval(timer)
            resolve(null)
          }
        }, 3000)

        if (type === 'mention') {
          this.uploadMentionTimer = timer
        } else {
          this.uploadMessageTimer = timer
        }
      })
    },

    /**
     * メンションのメディアファイル削除
     */
    removeMentionMedia() {
      this.message.mentionMedia = null
    },

    /**
     * ダイレクトメッセージのメディアファイル削除
     */
    removeMessageMedia() {
      this.message.messageMedia = null
    },

    /**
     * メンションカードを削除
     */
    removeMentionCard() {
      this.message.mentionCard = null
    },

    /**
     * リセット
     * @param {string} field
     */
    resetNumber(field: string) {
      setTimeout(() => {
        this.message[field] = 1
      }, 100)
    },

    /**
     * フォームをリセット
     */
    resetForm() {
      this.message = {
        id: '',
        name: '',
        messageType: this.messageType,
        winnerCount: 1,
        winningRate: 0.01,
        winningRateChangeType: 'none',
        dailyWinnerCount: 0,
        dailyWinnerChangeTime: '00:00',
        elapsedRate: 1,
        elapsedWinningRate: 1,
        weightCount: 1,
        entryKeyword: [],
        commentKeyword: [],
        replyType: 'mention',
        mentionBody: '',
        messageBody: '',
        mentionMedia: null,
        messageMedia: null,
        mentionCard: null,
        isDailyLottery: 0,
        excludeMessageMultiWinner: 1,
        excludeOtherMessageMultiWinner: 1,
        isPublicReply: 0,
        mentionMediaType: 'none',
        messageMediaType: 'none'
      }

      this.errors = {
        notInputStartDate: false,
        lessThanCurrentTime: false,
        lessThanStartTime: false,
        notInputEndDate: false
      }

      this.mentionCardOptions = {
        campaign_id: null,
        keyword: '',
        card_type: 'all',
        media_type: 'image',
        next_cursor: ''
      }

      this.$v.$reset()
      this.startDate = ''
      this.startTime = ''
      this.endDate = ''
      this.endTime = ''
      this.mentionCardId = ''
      this.timeout = null
      this.uploadingMentionMedia = false
      this.uploadingMessageMedia = false
      // テンプレート
      this.oldMentionTemplate = ''
      this.mentionTemplate = ''
      this.oldMessageTemplate = ''
      this.messageTemplate = ''
    },

    isImage(url: string) {
      const reg = /[.](jpeg|png|gif|pjpeg|jpg)$/i
      return reg.test(url)
    }
  },
  validations: {
    message: {
      name: {
        required
      }
    }
  }
}
