/* @flow */

export default {
  props: {
    value: [String, Number],
    options: {
      type: Array,
      default: () => []
    },
    label: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    onChange: {
      type: Function,
      default: () => {}
    },
    clearable: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: undefined
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selectedValue: this.value
    }
  },
  watch: {
    value() {
      this.selectedValue = this.value
    }
  }
}
