import { mapState } from 'vuex'

export default {
  props: {
    snsType: 'instagram' | 'twitter' | 'tiktok',
    applicant: Object,
    campaignType: String
  },
  data() {
    return {}
  },
  computed: {
    ...mapState('system', {
      isEndContract: state => state.currentGroup.contractStatus === 3
    })
  },
  methods: {
    /**
     * メッセージ配信履歴を開く
     * @param {number} applicantId
     */
    openMessageHistory(applicantId: number) {
      this.$emit('open_message_history', {
        applicantId
      })
    },

    /**
     * フラグ変更処理
     * @param {string} type
     */
    updateFlg(type: string) {
      const payload = {
        type,
        value: {}
      }

      switch (type) {
        case 'winner':
          payload.value = {
            applicantId: this.applicant.id,
            isWinner: this.applicant.isWinner === 0 ? 1 : 0,
            wasWinner: 1
          }
          break
        case 'responder':
          payload.value = {
            applicantId: this.applicant.id,
            isResponder: this.applicant.isResponder === 0 ? 1 : 0
          }
          break
        case 'candidate':
          payload.value = {
            applicantId: this.applicant.id,
            isCandidate: this.applicant.isCandidate === 0 ? 1 : 0
          }
          break
        case 'follower':
          payload.value = {
            applicantId: this.applicant.id,
            isFollowing: this.applicant.isFollowing === 0 ? 1 : 0
          }
          break
        case 'winnerContact':
          payload.value = {
            applicantId: this.applicant.id,
            isWinnerContact: this.applicant.isWinnerContact === 0 ? 1 : 0
          }
          break
      }

      this.$emit('change_flag', payload)
    }
  }
}
