import { render, staticRenderFns } from "./widget_preview_item.vue?vue&type=template&id=5aaed89f&scoped=true"
import script from "./widget_preview_item.component?vue&type=script&lang=js&external"
export * from "./widget_preview_item.component?vue&type=script&lang=js&external"
import style0 from "./widget_preview_item.vue?vue&type=style&index=0&id=5aaed89f&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5aaed89f",
  null
  
)

export default component.exports