/* @flow */
export default {
  props: {
    post: Object,
    isViewMessage: Boolean,
    snsType: String
  },
  computed: {
    message() {
      return this.post.message.replace(/https:\/\/twitter.com\/(\w+)\/(\w+)\/(\w+)/, '')
    },

    backgroundUrl() {
      if (this.post.mediaUrl) {
        return `url(${this.post.mediaUrl})`
      }

      if (!this.isViewMessage) {
        return `url(${require('@/client/assets/images/no-photo.png')})`
      }

      return ''
    },

    classIcon() {
      if (!this.post.type) return ''

      let icon = ''

      switch (this.post.type) {
        case 'image':
          icon = 'image'
          break
        case 'carousel':
          icon = 'image-multiple'
          break
        case 'video':
          icon = 'movie'
          break
        default:
          icon = 'format-text'
      }

      return `mdi mdi-${icon}`
    }
  }
}
