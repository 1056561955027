/* @flow */
import { mapActions, mapState } from 'vuex'

import api from '@/client/core/api'
import Util from '@/client/core/util'

export default {
  data() {
    return {
      isSubmit: false
    }
  },
  computed: {
    ...mapState('twitterInstantwinQueue', {
      queues: state => state.queues.list,
      params: state => state.queues.params,
      loading: state => state.loading
    })
  },
  async mounted() {
    const params = {
      campaignId: this.$route.params.campaignId
    }

    await this.setQueueListParams(params)
  },
  watch: {
    params() {
      this.fetchQueueList()
    }
  },
  methods: {
    ...mapActions('twitterInstantwinQueue', ['fetchQueueList', 'setQueueListParams', 'setQueueMessageId']),

    /**
     * メッセージキューの削除
     * @param {number} queueId
     */
    async deleteQueue(queueId: number) {
      const confirm = window.confirm(this.$gettext('メッセージ配信スケジュールを削除してもよろしいですか?'))

      if (!confirm) {
        return
      }

      const result = await api.delete('/twitter_instantwin_queues/' + queueId)

      // 対象キャンペーンレコードが存在しない場合
      if (result.error && result.error.type === 'NOT_EXISTS') {
        this.$notify({
          title: this.$gettext('対象のメッセージ配信スケジュールはすでに削除されています。'),
          customClass: 'danger',
          duration: 5000
        })

        await this.fetchQueueList()

        return
      }

      // 削除に失敗した場合
      if (!result.data) {
        this.$notify({
          title: this.$gettext('メッセージ配信スケジュールの削除に失敗しました。'),
          message: this.$gettext('恐れ入りますが、時間を置いて再度お試しください。'),
          customClass: 'danger',
          duration: 5000
        })

        return
      }

      // 削除に成功した場合
      this.$notify({
        title: this.$gettext('メッセージ配信スケジュールを削除しました。'),
        customClass: 'success',
        duration: 5000
      })

      await this.fetchQueueList()
    },

    /**
     * Change screen
     * @param {string} tab
     * @param {number} queueId
     */
    changeTab(tab: string, queueId: number) {
      this.$emit('change_tab', tab)
      this.setQueueMessageId(queueId)
    },

    /**
     * ソートを変更イベント
     * @param {string} sortBy ソート
     */
    onChangeSortBy(sortBy: string) {
      if (this.params.sortBy === sortBy) {
        return
      }

      this.setQueueListParams({ sortBy })
    },

    /**
     * ヘッダーをクリックするとソートする
     * @param {*} column
     */
    headerClick(column: any) {
      if (!column.sortBy) {
        return
      }

      if (column.sortBy.indexOf('-') === 0) {
        if (this.params.sortBy === column.sortBy) {
          this.onChangeSortBy(column.sortBy.substring(1))
        } else {
          this.onChangeSortBy(column.sortBy)
        }
      } else {
        if (this.params.sortBy === column.sortBy) {
          this.onChangeSortBy('-' + column.sortBy)
        } else {
          this.onChangeSortBy(column.sortBy)
        }
      }
    },

    /**
     * ヘッダー描画
     * @param {*} createElement
     * @param {*} { column }
     */
    renderHeader(createElement: any, { column }: any) {
      return Util.renderHeader(
        createElement,
        column.label,
        Util.getSortClass(this.params.sortBy, column.sortBy),
        !!column.sortBy
      )
    },

    /**
     * 配信停止
     * @param {number} queueId
     */
    async stopSend(queueId: number) {
      let message = `${this.$gettext('メッセージの配信を停止してもよろしいですか？')}\n`
      message += this.$gettext('配信停止の取り消しは行なえません。')

      const confirm = window.confirm(message)

      if (!confirm) {
        return
      }

      this.isSubmit = true

      let messageId = null
      const queue = await api.get(`/twitter_instantwin_queues/${queueId}`)

      if (queue && queue.data) {
        messageId = queue.data.messageId
      }

      const response = await api.put(`/twitter_instantwin_queues/${queueId}`, {
        campaignId: this.params.campaignId,
        messageId,
        isSendStop: 1
      })

      setTimeout(() => {
        this.isSubmit = false
      }, 2000)

      if (!response.data || !queue.data) {
        this.$notify({
          title: this.$gettext('配信停止に失敗しました。'),
          message: this.$gettext('恐れ入りますが、時間をおいて再度お試しください。'),
          customClass: 'danger',
          duration: 5000
        })

        return
      }

      this.$notify({
        title: this.$gettext('メッセージを配信停止しました。'),
        message: this.$gettext('配信停止まで、5分ほどかかる場合がございます。'),
        customClass: 'success',
        duration: 5000
      })

      await this.fetchQueueList()
    }
  }
}
