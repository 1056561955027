import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'

import { APIGetTwitterLotteryPageByIdResponse } from '@/server/api/twitter_lottery_pages/get_lottery_page_by_id.api'
import { APIError } from '@/typings/api/error'

import { API } from '../utils/api'
import { RootState } from './index'

const SET_LOTTERY_PAGE_DATA = 'SET_LOTTERY_PAGE_DATA'
const SET_PARAMS = 'SET_PARAMS'
const SET_ERROR = 'SET_ERROR'
const SET_FORM = 'SET_FORM'
const SET_MODE = 'SET_MODE'

export type Form = {
  campaignId?: number
  twCampaignPrizeId?: number
  twWebInstantwinLotteryPageSettingId?: number
  name: string
  lotteryType: 0 | 1
  lotteryText: string
  mediaType: 0 | 1
  mediaUrl: string | null
}

export type TLotteryPageSetting = {
  id: number
  name: string
  previewUrl: string | null
  lotteryType: 0 | 1
}

type FetchLotteryPageSettingsParams = {
  campaignId: number
  prizeId: number
  sort?: 'ASC' | 'DESC'
  pagingNo?: number
  limitCount?: number
}

type PutLotteryPageSettingsParams = {
  campaignId: number
  twWebInstantwinLotteryPageSettingId: number
} & Form

type PostLotteryPageSettingsParams = {
  campaignId: number
  twCampaignPrizeId: number
} & Form

export type GetLotteryPageSettingsResponse = {
  data?: {
    lotteryPages: TLotteryPageSetting[]
    total: number
  }
  error?: APIError
}
export type PostLotteryPageSettingsResponse = {
  data?: {
    id: number
  }
  error?: APIError
}

export type PutLotteryPageSettingsResponse = {
  data?: boolean
  error?: APIError
}

export type GetLotteryPagesLotteryPagesResponse = {
  data: APIGetTwitterLotteryPageByIdResponse
  error: APIError
}

export type LotteryPageSettingState = {
  lotteryPages: TLotteryPageSetting[]
  form: Form
  error: boolean
  mode: 'settings' | 'create' | 'edit_win' | 'edit_lose' | 'copy'
  total: number
  params: FetchLotteryPageSettingsParams
  isLoading: boolean
}

export type LotteryPageSettingActions = {
  reset: () => Promise<void>
  fetchLotteryPages: () => Promise<void>
  setParams: (payload: FetchLotteryPageSettingsParams) => Promise<void>
  setError: (payload: APIError) => Promise<void>
  setForm: (payload: Form) => Promise<void>
  setMode: (payload: 'settings' | 'create' | 'edit_win' | 'edit_lose' | 'copy') => Promise<void>
  createLotteryPageSetting: (payload: PostLotteryPageSettingsParams) => Promise<PostLotteryPageSettingsResponse>
  updateLotteryPageSetting: (payload: PutLotteryPageSettingsParams) => Promise<PutLotteryPageSettingsResponse>
}

const initialForm: Form = {
  name: '',
  lotteryType: 0,
  lotteryText: '',
  mediaType: 0,
  mediaUrl: ''
}

const initState: LotteryPageSettingState = {
  lotteryPages: [],
  form: initialForm,
  error: false,
  mode: 'settings',
  total: 0,
  params: {
    campaignId: 0,
    prizeId: 0,
    pagingNo: 1,
    limitCount: 25
  },
  isLoading: false
}

const state: LotteryPageSettingState = { ...initState }

const getters: GetterTree<LotteryPageSettingState, RootState> = {}

const mutations: MutationTree<LotteryPageSettingState> = {
  [SET_LOTTERY_PAGE_DATA](state, payload: GetLotteryPageSettingsResponse['data']) {
    state.lotteryPages = payload?.lotteryPages || []
    state.total = payload?.total || 0
  },
  [SET_PARAMS](state, payload: FetchLotteryPageSettingsParams) {
    state.params = payload
  },
  [SET_FORM](state, payload: Form) {
    state.form = payload
  },
  [SET_ERROR](state, payload: boolean) {
    state.error = payload
  },
  [SET_MODE](state, payload: 'settings' | 'create' | 'edit_win' | 'edit_lose' | 'copy') {
    state.mode = payload
  }
}

const actions: ActionTree<LotteryPageSettingState, RootState> = {
  /**
   * ストアをリセット
   */
  async reset(context) {
    context.commit('SET_LOTTERY_PAGE_DATA', [])
    context.commit('SET_ERROR', false)
    context.commit('SET_MODE', 'settings')
    context.commit('SET_FORM', initialForm)
  },

  /**
   * 当落ページ取得APIのパラメータをセット
   */
  async setParams(context, params: FetchLotteryPageSettingsParams) {
    context.commit(SET_PARAMS, { ...context.state.params, ...params })
  },

  /**
   * フォーム情報の変更
   */
  async setForm(context, payload: Form) {
    context.commit(SET_FORM, payload)
  },

  async setMode(context, payload: 'settings' | 'create' | 'edit_win' | 'edit_lose' | 'copy') {
    context.commit(SET_MODE, payload)
  },

  /**
   * 当落選ページデータを取得
   */
  async fetchLotteryPages(context) {
    context.commit(SET_MODE, 'settings')
    context.commit(SET_ERROR, false)

    const params = context.state.params

    const response = await API.get('/twitter_lottery_pages', { params })

    if (!response.data) {
      return context.commit(SET_ERROR, true)
    }

    context.commit(SET_LOTTERY_PAGE_DATA, {
      lotteryPages: response.data.lotteryPages,
      total: response.data.total
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
} as Module<LotteryPageSettingState, RootState>
