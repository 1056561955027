/* @flow */
import twitterText from 'twitter-text'
export default class Highlight {
  /**
   * ハイライトテキストの取得
   * @param {highlights} ハイライト定義
   * @param {string} text
   * @returns {string} ハイライトテキスト
   */
  static getHighlightText(highlights: { type: string, keywords: string[] }[], text: string) {
    if (!highlights || !highlights.length) {
      return text
    }

    highlights = [].concat(highlights)
    let highlightData = highlights.sort((a, b) => {
      if (a.type === 'link') {
        return -1
      }
      return 1
    })

    const queries = []
      .concat(...highlightData.map(highlight => highlight.keywords))
      .filter(query => query !== undefined)

    highlightData = highlightData.map(highlight => {
      const linkKeyword: string[] = twitterText.extractUrls(text)

      const type = highlight.type
      const originKeywords = highlight.keywords || []

      const newQueries = queries.filter(query => originKeywords.indexOf(query) === -1)

      const keywords = originKeywords.filter(keyword => newQueries.every(query => query.indexOf(keyword) === -1))

      return { type, keywords: type === 'link' ? linkKeyword : keywords }
    })

    highlightData.forEach(highlight => {
      if (highlight.keywords.length === 0) {
        return
      }
      const pattern = `(${highlight.keywords.map(keyword => keyword.replace(/\?/gi, '\\?')).join('|')})(?![^<]*>)`
      const search = new RegExp(pattern, 'g')
      text = text.replace(search, match => {
        if (highlight.type === 'link') {
          return `<a href="${match}" target="_blank">${match}</a>`
        }
        return `<span class="highlight highlight-${highlight.type}">${match}</span>`
      })
    })

    return text
  }
}
