/* @flow */
import axiosLib from 'axios'

import SessionError from './session_error'

export const axios = axiosLib.create({
  baseURL: '/api',
  validateStatus: status => {
    return status >= 200 && status <= 500
  }
})

/**
 * SEND GET API
 * @param {string} endpoint エンドポイント
 * @param {*} options オプション
 * @returns
 */
async function get(endpoint: string, options: any) {
  try {
    const response = await axios.get(endpoint, options)
    const data = response.data || null
    if (response.status === 401) {
      SessionError.showNotify()
    }
    return data
  } catch (err) {
    if (err.response && err.response.status === 401) {
      SessionError.showNotify()
    }
    return err
  }
}

/**
 * SEND POST API
 * @param {string} endpoint エンドポイント
 * @param {*} params パラメーター
 * @param {*} options オプション
 * @returns
 */
async function post(endpoint: string, params: any, options: any) {
  try {
    const response = await axios.post(endpoint, params, options)
    const data = response.data || null
    if (response.status === 401) {
      SessionError.showNotify()
    }
    return data
  } catch (err) {
    if (err.response && err.response.status === 401) {
      SessionError.showNotify()
    }
    return err
  }
}

/**
 * SEND PUT API
 * @param {string} endpoint エンドポイント
 * @param {*} params パラメーター
 * @param {*} options オプション
 * @returns
 */
async function put(endpoint: string, params: any, options: any) {
  try {
    const response = await axios.put(endpoint, params, options)
    const data = response.data || null
    if (response.status === 401) {
      SessionError.showNotify()
    }
    return data
  } catch (err) {
    if (err.response && err.response.status === 401) {
      SessionError.showNotify()
    }
    return err
  }
}

/**
 * SEND DELETE API
 * @param {string} endpoint エンドポイント
 * @param {*} options オプション
 * @returns
 */
async function sendDelete(endpoint: string, options: any) {
  try {
    const response = await axios.delete(endpoint, options)
    const data = response.data || null
    if (response.status === 401) {
      SessionError.showNotify()
    }
    return data
  } catch (err) {
    if (err.response && err.response.status === 401) {
      SessionError.showNotify()
    }
    return err
  }
}

export default {
  get,
  post,
  put,
  delete: sendDelete
}
