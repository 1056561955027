/* @flow */
export default {
  props: {
    name: String,
    pictureUrl: String,
    iconType: String
  },
  computed: {
    picture() {
      if (!this.pictureUrl && this.iconType === 'twitter') {
        return require('@/client/assets/images/twitter_default_profile.png')
      } else if (!this.pictureUrl && this.iconType === 'instagram') {
        return require('@/client/assets/images/instagram_default_profile.png')
      } else if (!this.pictureUrl && this.iconType === 'tiktok') {
        return require('@/client/assets/images/tiktok_default_profile.png')
      } else {
        return this.pictureUrl
      }
    }
  }
}
