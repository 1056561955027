import Vue from 'vue'
import Vuex from 'vuex'

import campaign from './campaign.store'
import campaign_account from './campaign_account.module'
import campaign_dialog from './campaign_dialog.module'
import instagramAnalyticsPublic from './instagram_analytics_public.store'
import instagramApplicant from './instagram_applicant.store'
import instagramCampaignEntryHistory from './instagram_campaign_entry_history.store'
import instagramCampaignPrize from './instagram_campaign_prize.store'
import instagramPosts from './instagram_posts.store'
import lotteryPageSettingDialog from './lottery_page_setting_dialog.store'
import navbar from './navbar.store'
import sample from './sample.module'
import setting_group from './setting_group.module'
import settingGroup from './setting_group.store'
import system from './system.store'
import tiktokAnalyticsPublic from './tiktok_analytics_public.store'
import tiktokApplicant from './tiktok_applicant.store'
import tiktokCampaignEntryHistory from './tiktok_campaign_entry_history.store'
import tiktokCampaignPrize from './tiktok_campaign_prize.store'
import tiktokPosts from './tiktok_posts.store'
import twitterAnalyticsPublic from './twitter_analytics_public.store'
import twitterApplicant from './twitter_applicant.store'
import twitterCampaignEntryHistory from './twitter_campaign_entry_history.store'
import twitterCampaignPrize from './twitter_campaign_prize.store'
import twitterDirectMessageDialog from './twitter_direct_message_dialog.store'
import twitterInstantwinEntryHistory from './twitter_instantwin_entry_histories.store'
import twitterInstantwinLoseReason from './twitter_instantwin_lose_reasons.store'
import twitterInstantwinMessage from './twitter_instantwin_message.store'
import twitterInstantwinMessageHistory from './twitter_instantwin_message_history.store'
import twitterInstantwinQueue from './twitter_instantwin_queue.store'
import twitterInstantwinSerialCode from './twitter_instantwin_serial_code.store'
import twitterPosts from './twitter_posts.store'
import twitterSerialCodeDialog from './twitter_serial_code_dialog.store'

export type RootState = {
  system: any
}

Vue.use(Vuex)

export default new Vuex.Store<RootState>({
  strict: process.env.NODE_ENV !== 'production',
  modules: {
    campaign,
    campaign_account,
    campaign_dialog,
    instagramAnalyticsPublic,
    instagramApplicant,
    instagramCampaignEntryHistory,
    instagramCampaignPrize,
    instagramPosts,
    lotteryPageSettingDialog,
    tiktokPosts,
    navbar,
    sample,
    setting_group,
    settingGroup,
    system,
    tiktokCampaignPrize,
    tiktokCampaignEntryHistory,
    twitterAnalyticsPublic,
    tiktokAnalyticsPublic,
    twitterApplicant,
    tiktokApplicant,
    twitterCampaignEntryHistory,
    twitterCampaignPrize,
    twitterDirectMessageDialog,
    twitterSerialCodeDialog,
    twitterInstantwinEntryHistory,
    twitterInstantwinLoseReason,
    twitterInstantwinMessageHistory,
    twitterInstantwinMessage,
    twitterInstantwinQueue,
    twitterInstantwinSerialCode,
    twitterPosts
  },
  plugins: []
})
