/* @flow */
import { mapActions, mapState } from 'vuex'

import Icon from '@/client/components/basic/icon'
import api from '@/client/core/api'
import { TrackingService } from '@/client/services'

export default {
  components: {
    Icon
  },
  computed: {
    ...mapState('system', {
      user: state => state.user,
      groups: state => state.groups,
      currentGroup: state => state.currentGroup,
      guestGroups: state => state.guestGroups
    }),
    ...mapState('navbar', {
      breadcrumbs: state => state.breadcrumbs
    })
  },
  methods: {
    ...mapActions('system', ['setCurrentGroup', 'setUser']),
    /**
     * 選択中グループを変更
     * @param {number} groupId 選択中グループID
     */
    changeCurrentGroup(groupId: number) {
      if (!groupId || this.currentGroup.id === groupId) {
        return
      } else TrackingService.sendEvent('click:グループ名')

      this.setCurrentGroup(groupId)

      // ゲストグループの場合はアカウント設定画面に遷移
      if (this.guestGroups.some(v => v.id === this.currentGroup.id)) {
        this.$router.push('/settings/group#accounts')
        return
      }

      if (!this.currentGroup.contractConfirmAt) {
        this.$router.push({ path: '/settings/group_billing' })
      }

      if (this.$route.name !== 'campaigns') {
        this.$router.push({ name: 'campaigns' })
      }
    },
    /**
     * ログアウトする
     */
    async logout() {
      TrackingService.sendEvent('click:ログアウト')

      await api.delete('/me/session')

      this.setUser(null)

      this.$router.push('/login')
    },

    onClickLogo() {
      TrackingService.sendEvent('click:ロゴ')
    },

    handleLinkClick() {
      TrackingService.sendEvent('click:ヘルプ')
    },

    onChangeSetting(route: string) {
      TrackingService.sendEvent(`click:${route === '/settings/user' ? 'ユーザー' : 'グループ'}設定`)

      this.$router.push(route)
    },

    handleClickAccount() {
      TrackingService.sendEvent(`select:アカウント情報`)
    }
  }
}
