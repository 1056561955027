/* @flow */
import moment from 'moment-timezone'
import { mapState } from 'vuex'

import AnalyticsChartVertical from '@/client/components/basic/analytics_chart_vertical'
import CsvDownload from '@/client/components/basic/csv_download'

export default {
  components: {
    CsvDownload,
    AnalyticsChartVertical
  },
  data() {
    return {
      chartData: [],
      labels: [],
      interval: '1_day',
      value1: 'follower',
      value2: 'applicant',
      isBeginAtZero: false
    }
  },
  computed: {
    ...mapState('tiktokAnalyticsPublic', {
      campaignAnalytics: state => state.campaignAnalytics,
      params: state => state.params,
      campaign: state => state.campaign
    }),
    values() {
      const values = [
        { label: this.$gettext('指定なし'), value: '' },
        { label: this.$gettext('応募数'), value: 'entry' },
        { label: this.$gettext('応募者数'), value: 'applicant' },
        { label: this.$gettext('新規応募者数'), value: 'newApplicant' },
        { label: this.$gettext('フォロワー'), value: 'follower' },
        { label: this.$gettext('当選数'), value: 'winner' }
      ]

      return values
    },
    intervals() {
      return [
        { label: this.$gettext('1時間'), value: 'hour' },
        { label: this.$gettext('1日'), value: '1_day' },
        { label: this.$gettext('7日'), value: '7_day' },
        { label: this.$gettext('1ヶ月'), value: '1_month' }
      ]
    },
    time_range() {
      const timeRange = []

      if (!this.campaignAnalytics.length) {
        return timeRange
      }

      const addNumber = Number(this.interval.match(/\d+/g))

      let timeEnd
      let timeStart = moment(moment(this.campaignAnalytics[0].datetime).format('YYYY-MM-DD 00:00:00')).unix()

      const endDateUnix = moment(
        moment(this.campaignAnalytics[this.campaignAnalytics.length - 1].datetime).format('YYYY-MM-DD HH:mm:00')
      ).unix()

      while (timeStart <= endDateUnix) {
        if (
          this.interval === '1_day' ||
          this.interval === '7_day' ||
          this.interval === '14_day' ||
          this.interval === '28_day'
        ) {
          // 日数のunix値: 日数 x 一日のunix値 (一日のunix値: 86400)
          timeEnd = timeStart + addNumber * 86400 - 1
        } else if (this.interval === '1_month') {
          timeEnd =
            moment
              .unix(timeStart)
              .add(addNumber, 'months')
              .startOf('month')
              .unix() - 1
        }

        if (timeEnd > endDateUnix) {
          timeEnd = endDateUnix
        }

        timeRange.push({
          start: timeStart,
          end: timeEnd
        })

        timeStart = timeEnd + 1
      }

      if (timeRange.length) {
        timeRange[0].start = moment(this.campaignAnalytics[0].datetime).unix()
      } else {
        timeRange.push({ start: endDateUnix, end: endDateUnix })
      }

      return timeRange
    },
    time_range_for_label() {
      const timeRange = []

      if (!this.campaignAnalytics.length) {
        return timeRange
      }

      const addNumber = Number(this.interval.match(/\d+/g))

      let timeEnd
      let timeStart = moment(moment(this.campaignAnalytics[0].datetime).format('YYYY-MM-DD 00:00:00')).unix()

      const endDateUnix = moment(
        moment(this.campaignAnalytics[this.campaignAnalytics.length - 1].datetime).format('YYYY-MM-DD HH:mm:00')
      ).unix()

      while (timeStart <= endDateUnix) {
        if (
          this.interval === '1_day' ||
          this.interval === '7_day' ||
          this.interval === '14_day' ||
          this.interval === '28_day'
        ) {
          // 日数のunix値: 日数 x 一日のunix値 (一日のunix値: 86400)
          timeEnd = timeStart + addNumber * 86400 - 1
        } else if (this.interval === '1_month') {
          timeEnd =
            moment
              .unix(timeStart)
              .add(addNumber, 'months')
              .startOf('month')
              .unix() - 1
        }

        if (timeEnd > endDateUnix) {
          timeEnd = endDateUnix
        }

        timeRange.push({
          start: timeStart,
          end: timeEnd
        })

        timeStart = timeEnd + 1
      }

      if (timeRange.length) {
        timeRange[0].start = moment(this.campaignAnalytics[0].datetime).unix()
      } else {
        timeRange.push({ start: endDateUnix, end: endDateUnix })
      }

      return timeRange
    },
    analytics() {
      const data = {
        applicant: [],
        follower: [],
        entry: [],
        newApplicant: [],
        winner: []
      }

      if (!this.campaignAnalytics.length) {
        return data
      }

      if (this.interval === 'hour') {
        const lastIndex = this.campaignAnalytics.length - 1

        this.campaignAnalytics.forEach((value, index) => {
          data.follower.push(value.followerCount)

          if (index !== lastIndex) {
            data.applicant.push(value.applicantCount)
            data.entry.push(value.entryCount)
            data.newApplicant.push(value.newApplicantCount)
            data.winner.push(value.winnerCount)
          }
        })

        return data
      }

      const endDateUnix = moment(this.campaignAnalytics[this.campaignAnalytics.length - 1].datetime)
        .startOf('minute')
        .unix()

      this.time_range.forEach((time, index) => {
        // 該当する期間の分析データを取得
        let analytics = this.campaignAnalytics
          .filter(analytic => {
            return moment(analytic.datetime).unix() >= time.start && moment(analytic.datetime).unix() <= time.end
          })
          .map(analytic => analytic)

        if (this.interval === '1_day') {
          const momentDate = index < this.time_range.length - 1 ? moment.unix(time.start) : moment.unix(time.end)
          const datetime = momentDate.startOf('hour').format('YYYY-MM-DD HH:mm')

          data.follower.push(this.getValueByHour(analytics, datetime, 'followerCount'))
        } else {
          data.follower.push(this.getValueByPeriod(analytics, 'followerCount'))
        }

        if (time.end === endDateUnix) {
          analytics = analytics.filter(v => moment(v.datetime).unix() !== endDateUnix)
        }

        data.applicant.push(this.getTotal(analytics, 'applicantCount'))
        data.entry.push(this.getTotal(analytics, 'entryCount'))
        data.newApplicant.push(this.getTotal(analytics, 'newApplicantCount'))
        data.winner.push(this.getTotal(analytics, 'winnerCount'))
      })

      return data
    }
  },
  watch: {
    analytics() {
      this.changeChart()
    }
  },
  methods: {
    /**
     * 足し算
     */
    addition(numbers: number[] = []): number | null {
      const values = numbers.filter(v => v !== null)

      if (!values.length) {
        return null
      }

      return numbers.map(v => this.toNumber(v)).reduce((a, c) => a + c, 0)
    },

    /**
     * ソートする値の変更イベント
     */
    changeChart(): void {
      this.chartData = this.getChartData()
      this.labels = this.getLabels()
    },

    /**
     * change Interval
     */
    changeInterval(interval: string): void {
      this.interval = interval

      if (interval === 'hour') {
        if (this.value1 === 'newFollower') {
          this.value1 = 'follower'
        }
        if (this.value2 === 'newFollower') {
          this.value2 = 'applicant'
        }
      }

      this.changeChart()
    },

    /**
     * CSVダウンロード
     */
    downloadCsv(): { filename: string, data: any } {
      const filename = `${this.campaign.title}_分析`

      const data = []

      const header = [
        this.$gettext('日付'),
        this.$gettext('応募数'),
        this.$gettext('応募者数'),
        this.$gettext('新規応募者数'),
        this.$gettext('当選数'),
        this.$gettext('フォロワー')
      ]

      data.push(header)

      this.labels.forEach((label, index) => {
        if (this.interval === '1_day') {
          label = moment(label)
            .startOf('hour')
            .format('YYYY/MM/DD')
        }

        const applicant = isNaN(this.analytics.applicant[index]) ? null : this.analytics.applicant[index]
        const follower = isNaN(this.analytics.follower[index]) ? null : this.analytics.follower[index]
        const entry = isNaN(this.analytics.entry[index]) ? null : this.analytics.entry[index]
        const newApplicant = isNaN(this.analytics.newApplicant[index]) ? null : this.analytics.newApplicant[index]
        const winner = isNaN(this.analytics.winner[index]) ? null : this.analytics.winner[index]

        data.push([label, entry, applicant, newApplicant, winner, follower])
      })

      return { filename, data }
    },

    /**
     * Get chart data
     */
    getChartData() {
      const data = []

      if (this.value1) {
        const label = this.values.find(value => value.value === this.value1)
        data.push({
          metric: this.value1,
          label: label.label,
          data: this.analytics[this.value1]
        })
      }

      if (this.value2) {
        const label = this.values.find(value => value.value === this.value2)
        data.push({
          metric: this.value2,
          label: label.label,
          data: this.analytics[this.value2]
        })
      }

      return data
    },

    /**
     * Get label name
     */
    getLabels(): any {
      let labels = []

      switch (this.interval) {
        case 'hour':
          labels = []
          this.campaignAnalytics.forEach((value, index) => {
            const date = moment(value.datetime)
              .startOf('hour')
              .format('YYYY/MM/DD HH:mm')
            labels.push(date)
          })
          return labels
        case '1_day':
          return this.time_range_for_label.map((time, index) => {
            if (index === this.time_range_for_label.length - 1) {
              return moment
                .unix(time.end)
                .startOf('hour')
                .format('YYYY/MM/DD')
            }

            return moment
              .unix(time.start)
              .startOf('hour')
              .format('YYYY/MM/DD')
          })
        case '7_day':
        case '14_day':
        case '28_day':
          return this.time_range_for_label.map(time => {
            return `${moment.unix(time.start).format('MM/DD')}-${moment.unix(time.end).format('MM/DD')}`
          })
        case '1_month':
          return this.time_range_for_label.map(time => {
            return moment.unix(time.start).format('YYYY/MM')
          })
      }
    },

    /**
     * 分析データから値の配列を取得
     */
    getMetricArray(insights: any[], metric: string): any[] {
      return insights.map(insight => this.getMetricValue(insight, metric))
    },

    /**
     * 分析データから値を取得
     */
    getMetricValue(insight: any, metric: string): any | null {
      return isNaN(insight[metric]) ? null : insight[metric]
    },

    getValueByPeriod(analytics: any[], value: string): number | null {
      const data = analytics.filter(analytic => analytic[value] !== null)

      if (data.length === 0) {
        return null
      }

      // If there is only one period, return last value
      if (analytics.length === this.campaignAnalytics.length) {
        return data[data.length - 1][value]
      }

      // If this is the first period, return first value
      if (moment(analytics[0].datetime).isSame(this.campaignAnalytics[0].datetime)) {
        return data[0][value]
      }

      return data[data.length - 1][value]
    },

    /**
     * Get value by hour
     */
    getValueByHour(analytics: any[], datetime: string, value: string): number | null {
      const data = analytics.find(analytic => moment(analytic.datetime).isSame(datetime, 'hour'))

      if (!data) {
        return null
      }

      return data[value]
    },

    /**
     * 数値変換
     */
    toNumber(value: any): number {
      return !isNaN(value) ? value : 0
    },

    /**
     * Get total
     */
    getTotal(data: any, metric: string): number {
      return this.addition(this.getMetricArray(data, metric))
    }
  }
}
