import ApplicantName from '@/client/components/basic/applicant_name'
import Pagination from '@/client/components/basic/pagination'
import Util from '@/client/core/util'
import { TrackingService } from '@/client/services'
import { type TTikTokPost } from '@/typings/api/tiktok_posts.type'

export default {
  components: { ApplicantName, Pagination },
  props: {
    posts: Array,
    options: Object
  },
  methods: {
    /**
     * 候補者コンテンツを取得
     */
    candidateTooltipContent(post: TTikTokPost) {
      if (post.isNg && !post.isCandidate) {
        return 'NGアカウント'
      }

      return post.isCandidate ? '候補者を解除' : '候補者に追加'
    },

    /**
     * 当選者コンテンツを取得
     */
    winnerTooltipContent(post: TTikTokPost) {
      if (post.isNg && !post.isWinner) {
        return 'NGアカウント'
      }

      return post.isWinner ? '当選者を解除' : '当選者に追加'
    },

    /**
     * 候補者フラグを変更
     */
    changeIsCandidate(post: TTikTokPost) {
      TrackingService.sendEvent('click:応募履歴（IG）|候補者に追加')

      if (post.isNg && !post.isCandidate) {
        return
      }

      this.$emit('change_is_candidate', {
        applicantId: post.applicantId,
        isCandidate: post.isCandidate
      })
    },

    /**
     * 当選者フラグを変更
     */
    changeIsWinner(post: TTikTokPost) {
      TrackingService.sendEvent('click:応募履歴（TT）|当選者に追加')

      if (post.isNg && !post.isWinner) {
        return
      }

      this.$emit('change_is_winner', {
        prizeId: post.prizeId,
        applicantId: post.applicantId,
        isWinner: post.isWinner
      })
    },

    /**
     * ヘッダーをクリックするとソートする
     * @param {*} column
     * @returns {void}
     */
    headerClick(column: any): void {
      TrackingService.sendEvent('sort:応募履歴（TT）|応募日時')

      if (!column.sortBy) {
        return
      }

      if (column.sortBy.indexOf('-') === 0 && this.options.sortBy === column.sortBy) {
        this.$emit('sorting', column.sortBy.substring(1))
        return
      }

      if (column.sortBy.indexOf('-') !== 0 && this.options.sortBy === column.sortBy) {
        this.$emit('sorting', `-${column.sortBy}`)
        return
      }

      this.$emit('sorting', column.sortBy)
    },

    /**
     * ページング変更イベント
     * @param {any} payload
     * @returns {void}
     */
    onPaging(payload: any): void {
      this.$emit('paging', payload)
    },

    /**
     * ヘッダー描画
     * @param {*} createElement
     * @param {*} { column }
     */
    renderHeader(createElement: any, { column }: any): any {
      return Util.renderHeader(createElement, column.label, Util.getSortClass(this.options.sortBy, column.sortBy))
    },

    clickTikTokConfirmation() {
      TrackingService.sendEvent('click:応募履歴（TT）|TikTokで確認')
    }
  }
}
