import { render, staticRenderFns } from "./InstagramPost.vue?vue&type=template&id=591b87d1&scoped=true"
import script from "./InstagramPost.component?vue&type=script&lang=js&external"
export * from "./InstagramPost.component?vue&type=script&lang=js&external"
import style0 from "./InstagramPost.vue?vue&type=style&index=0&id=591b87d1&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "591b87d1",
  null
  
)

export default component.exports