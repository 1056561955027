export default {
  props: {
    metric: String,
    value: {
      type: Number,
      default: 0
    },
    tooltip: {
      type: String,
      default: null
    },
    isUpDown: {
      type: Boolean,
      default: false
    },
    notationText: {
      type: String,
      default: null
    },
    isTikTok: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    is_value_up() {
      return this.isUpDown && this.value > 0
    },

    is_value_down() {
      return this.isUpDown && this.value < 0
    },

    display_value() {
      if (this.is_value_up) {
        return `+${this.$options.filters.number(this.value)}`
      }

      return this.$options.filters.number(this.value)
    }
  }
}
