import { required } from 'vuelidate/lib/validators'
import { mapActions, mapState } from 'vuex'

import api from '@/client/core/api'

export default {
  data() {
    return {
      serialCode: '',
      isSubmit: false,
      isSubmited: false
    }
  },
  computed: {
    ...mapState('twitterInstantwinSerialCode', {
      campaignId: state => state.campaignId
    }),
    isFormChanged() {
      return Boolean(this.serialCode.trim())
    },
    maxSerialCodeError() {
      if (!this.serialCode.trim()) {
        return false
      }

      return this.serialCode.trim().split('\n').length > 10000
    },
    disabled() {
      return !this.serialCode || !this.serialCode.trim() || this.maxSerialCodeError || this.isSubmit
    }
  },
  watch: {
    isFormChanged(value) {
      this.$emit('update:isFormChanged', value)
    }
  },
  methods: {
    ...mapActions('twitterInstantwinSerialCode', ['fetchSerialCodeList']),
    ...mapActions('twitterInstantwinMessage', ['fetchInstantwinMessages']),

    /**
     * Change screen
     * @param {string} tab
     */
    changeTab(tab: string) {
      if (this.isFormChanged && !this.isSubmited) {
        const message = this.$gettext('編集内容が保存されていません。ダイアログを閉じてよろしいですか?')
        const confirm = window.confirm(message)

        if (!confirm) {
          return
        }
      }

      this.$emit('change_tab', tab)
    },

    /**
     * Save serial code
     */
    saveSerialCode() {
      if (this.isSubmit) return
      const makeListSerialCodes = this.serialCode
        .replace(/^\s*[\r\n]/gm, '')
        .replace(/^\s+|\s+$/g, '')
        .replace(/@/g, '')
        .split('\n')
      this.postButton({
        campaignId: this.campaignId,
        serialCodes: makeListSerialCodes
      })
    },

    /**
     * 使用済みコードのインポート
     * @param {Object} params 送信データ
     */
    async postButton(params: any) {
      const value = this.serialCode
      const applicants = value
        .replace(/^\s*[\r\n]/gm, '')
        .replace(/^\s+|\s+$/g, '')
        .replace(/@/g, '')
        .split('\n')

      const confirm = window.confirm(
        this.$gettextInterpolate(
          this.$gettext(
            '%{inputRecord}件の使用済みコードをインポートします。\n処理を実行してよろしいですか?\nこの操作は取り消しできません。'
          ),
          {
            inputRecord: applicants.length
          }
        )
      )

      if (!confirm) {
        return
      }

      this.isSubmit = true

      const response = await api.post(`/twitter_instantwin_serial_codes/used_imports`, params)

      setTimeout(() => {
        this.isSubmit = false
      }, 2000)

      if (response.data || response.data === 0) {
        const title = this.$gettextInterpolate(this.$gettext('%{savedCount}件の使用済みコードをインポートします。'), {
          savedCount: response.data
        })

        this.$notify({
          title,
          customClass: 'success',
          duration: 5000
        })

        this.isSubmited = true

        this.changeTab('serial-code-table')
        await this.fetchInstantwinMessages()
        return
      }

      this.$notify({
        title: this.$gettext('使用済みコードのインポートに失敗しました。'),
        message: this.$gettext('恐れ入りますが、時間を置いて再度お試しください。'),
        customClass: 'danger',
        duration: 5000
      })
    }
  },
  validations: {
    serialCode: {
      required
    }
  }
}
