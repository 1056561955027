/* @flow */
import api from '@/client/core/api'
import {
  type TGetLoseReason as TLoseReason,
  type TGetLoseReasonParams as TParams
} from '@/typings/api/twitter_instantwin_message_history.type'

// mutations type
const RESET = 'RESET'
const SET_ERROR = 'SET_ERROR'
const SET_LOADING = 'SET_LOADING'
const SET_LOSE_REASON_DATA = 'SET_LOSE_REASON_DATA'
const SET_PARAMS = 'SET_PARAMS'

// 応募者ストア
type LoseReasonStore = {
  error: boolean,
  loseReasons: TLoseReason[],
  loading: boolean,
  params: TParams
}

// ストアーcontext
type Context = {
  commit: Function,
  getters: any,
  state: LoseReasonStore,
  dispatch: Function
}

// init state
const initState: LoseReasonStore = {
  error: false,
  loseReasons: [],
  loading: false,
  params: {
    campaignId: 0,
    sortBy: '-count'
  }
}

const state = Object.assign({}, initState)

// getters
const getters = {}

// actions
const actions = {
  /**
   * ストアをリセット
   * @param {Context} context
   */
  async reset(context: Context) {
    context.commit(RESET)
  },

  /**
   * 落選理由を取得
   * @param {Context} context
   */
  async fetchLoseReasons(context: Context) {
    const params = context.state.params

    if (params.campaignId === 0) {
      return
    }

    context.commit(SET_ERROR, false)
    context.commit(SET_LOADING, true)

    const result = await api.get('/twitter_instantwin_message_histories/lose_reasons', { params })

    context.commit(SET_LOADING, false)
    // データがない場合はエラーフラグをセット
    if (!result.data) {
      context.commit(SET_ERROR, true)
      return
    }

    // 落選理由の一覧をセット
    context.commit(SET_LOSE_REASON_DATA, result.data.loseReasons)
  },

  /**
   * パラメーターをセット
   * @param {Context} context
   * @param {TParams} params パラメーター
   */
  setParams(context: Context, params: TParams) {
    context.commit(SET_PARAMS, Object.assign({}, context.state.params, params))
  }
}

// mutations
const mutations = {
  /**
   * ストアをリセット
   * @param {LoseReasonStore} state
   */
  [RESET](state: LoseReasonStore) {
    const initStateData: any = initState
    Object.keys(initStateData).forEach(key => {
      state[key] = initStateData[key]
    })
  },

  /**
   * 落選理由の件数データをセット
   * @param {LoseReasonStore} state
   * @param {TLoseReason[]} loseReasons 落選理由一覧
   */
  [SET_LOSE_REASON_DATA](state: LoseReasonStore, loseReasons: TLoseReason[]) {
    state.loseReasons = loseReasons
  },

  /**
   * 落選理由の件数パラメータをセット
   * @param {LoseReasonStore} state
   * @param {TParams} params パラメーター
   */
  [SET_PARAMS](state: LoseReasonStore, params: TParams) {
    state.params = params
  },

  /**
   * エラーフラグをセット
   * @param {LoseReasonStore} state
   * @param {boolean} error エラーフラグ
   */
  [SET_ERROR](state: LoseReasonStore, error: boolean) {
    state.error = error
  },

  /**
   * Set loading
   * @param {LoseReasonStore} state
   * @param {boolean} loading
   */
  [SET_LOADING](state: LoseReasonStore, loading: boolean) {
    state.loading = loading
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
