import { required } from 'vuelidate/lib/validators'
import { mapActions, mapState } from 'vuex'

import api from '@/client/core/api'

export default {
  data() {
    return {
      serialCode: '',
      messageId: '',
      isSubmit: false,
      isSubmited: false
    }
  },
  props: {
    isEdit: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState('twitterInstantwinSerialCode', {
      currentMessageId: state => state.messageId,
      campaignId: state => state.campaignId
    }),
    ...mapState('twitterInstantwinMessage', {
      messages: state => state.messages.filter(message => message.messageType === 'win')
    }),
    isFormChanged() {
      return Boolean(this.serialCode.trim())
    },
    maxSerialCodeError() {
      if (!this.serialCode.trim()) {
        return false
      }

      return this.serialCode.trim().split('\n').length > 10000
    },
    disabled() {
      return this.$v.$invalid || !this.serialCode.trim() || this.maxSerialCodeError || this.isSubmit || !this.messageId
    }
  },
  watch: {
    isFormChanged(value) {
      this.$emit('update:isFormChanged', value)
    }
  },
  created() {
    if (this.isEdit) {
      this.messageId = this.currentMessageId
    }
  },
  methods: {
    ...mapActions('twitterInstantwinSerialCode', ['fetchSerialCodeList']),
    ...mapActions('twitterInstantwinMessage', ['fetchInstantwinMessages']),

    /**
     * Change screen
     * @param {string} tab
     */
    changeTab(tab: string) {
      if (this.isFormChanged && !this.isSubmited) {
        const message = this.$gettext('編集内容が保存されていません。ダイアログを閉じてよろしいですか?')
        const confirm = window.confirm(message)

        if (!confirm) {
          return
        }
      }

      this.$emit('change_tab', tab)
    },

    /**
     * Save serial code
     */
    saveSerialCode() {
      if (this.isSubmit) return

      const serialCodes = this.serialCode.split('\n').filter(code => code)

      this.postButton({
        campaignId: this.campaignId,
        serialCodes,
        messageId: this.messageId
      })
    },

    /**
     * シリアルコードの新規作成
     * @param {Object} params 送信データ
     */
    async postButton(params: any) {
      this.isSubmit = true

      if (params.serialCodes.some(code => code.length > 256)) {
        this.isSubmit = false

        this.$notify({
          title: this.$gettext('最大文字数を超えるシリアルコードが含まれています。'),
          customClass: 'danger',
          duration: 5000
        })
        return
      }

      const response = await api.post(`/twitter_instantwin_serial_codes`, params)

      setTimeout(() => {
        this.isSubmit = false
      }, 2000)

      if (response.data) {
        let title = this.$gettextInterpolate(this.$gettext('%{savedCount}件シリアルコードを保存しました。'), {
          savedCount: response.data.savedCount
        })

        if (response.data.duplicatedCount) {
          title = this.$gettextInterpolate(
            this.$gettext('%{savedCount}件シリアルコードを保存しました。(重複%{duplicatedCount}件)'),
            {
              savedCount: response.data.savedCount,
              duplicatedCount: response.data.duplicatedCount
            }
          )
        }

        this.$notify({
          title,
          customClass: 'success',
          duration: 5000
        })

        this.isSubmited = true

        this.changeTab('serial-code-table')
        await this.fetchSerialCodeList()
        await this.fetchInstantwinMessages()
        return
      }

      this.$notify({
        title: this.$gettext('シリアルコードの保存に失敗しました。'),
        message: this.$gettext('恐れ入りますが、時間をおいて再度お試しください。'),
        customClass: 'danger',
        duration: 5000
      })
    }
  },
  validations: {
    serialCode: {
      required
    }
  }
}
