<template>
  <el-form
    :model="groupBilling"
    :rules="rules"
    ref="ruleForm"
    label-position="left"
    label-width="184px"
    :hide-required-asterisk="true"
  >
    <el-form-item prop="companyName" class="group-billing-form-item">
      <template #label>
        会社名 <span class="form-item-label-badge form-item-label-badge-required">必須</span>
      </template>
      <el-input v-model="groupBilling.companyName" autocomplete="off" placeholder="会社名"></el-input>
    </el-form-item>
    <el-form-item prop="departName" class="group-billing-form-item">
      <template #label>
        担当部署名 <span class="form-item-label-badge form-item-label-badge-optional">任意</span>
      </template>
      <el-input v-model="groupBilling.departName" autocomplete="off" placeholder="担当部署名"></el-input>
    </el-form-item>
    <el-form-item prop="personName" class="group-billing-form-item">
      <template #label>
        担当者名 <span class="form-item-label-badge form-item-label-badge-required">必須</span>
      </template>
      <el-input v-model="groupBilling.personName" placeholder="担当者名"></el-input>
    </el-form-item>
    <el-form-item prop="email" class="group-billing-form-item">
      <template #label>
        メールアドレス <span class="form-item-label-badge form-item-label-badge-required">必須</span>
      </template>
      <el-input v-model="groupBilling.email" placeholder="メールアドレス"></el-input>
    </el-form-item>
    <el-form-item prop="countryCode" class="group-billing-form-item">
      <template #label> 国 <span class="form-item-label-badge form-item-label-badge-required">必須</span> </template>
      <el-select v-model="groupBilling.countryCode" placeholder="国">
        <el-option v-for="country in COUNTRIES" :key="country.value" :label="country.label" :value="country.value" />
      </el-select>
    </el-form-item>
    <el-form-item prop="zipCode" class="group-billing-form-item">
      <template #label>
        郵便番号 <span class="form-item-label-badge form-item-label-badge-required">必須</span>
      </template>
      <el-input v-model="groupBilling.zipCode" @blur="getAddress()" placeholder="郵便番号"></el-input>
    </el-form-item>
    <el-form-item prop="address" class="group-billing-form-item">
      <template #label> 住所 <span class="form-item-label-badge form-item-label-badge-required">必須</span> </template>
      <el-input v-model="groupBilling.address" placeholder="住所"></el-input>
    </el-form-item>
    <el-form-item prop="telephone" class="group-billing-form-item">
      <template #label>
        電話番号 <span class="form-item-label-badge form-item-label-badge-required">必須</span>
      </template>
      <el-input v-model="groupBilling.telephone" placeholder="電話番号"></el-input>
    </el-form-item>
  </el-form>
</template>

<script>
import { COUNTRIES } from '@/client/constant'
import { EMAIL_FORMAT, TELEPHONE_FORMAT, ZIP_CODE_FORMAT } from '@/client/constant/regex'
import api from '@/client/core/api'

export default {
  model: {
    prop: 'groupBilling',
    event: 'input'
  },
  props: {
    groupBilling: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      COUNTRIES,
      rules: {
        companyName: [
          { required: true, trigger: 'change', message: '会社名を入力してください。' },
          { max: 200, trigger: 'change', message: '会社名は200文字以下に設定してください。' }
        ],
        departName: [{ max: 200, trigger: 'change', message: '担当部署名は200文字以下に設定してください。' }],
        personName: [
          { required: true, trigger: 'change', message: '担当者名を入力してください。' },
          { max: 200, trigger: 'change', message: '担当者名は200文字以下に設定してください。' }
        ],
        email: [
          { required: true, trigger: 'change', message: 'メールアドレスを入力してください。' },
          { validator: this.validateMail, trigger: 'change' },
          { max: 200, trigger: 'change', message: 'メールアドレスは200文字以下に設定してください。' }
        ],
        countryCode: [{ required: true, trigger: 'change', message: '国を入力してください' }],
        zipCode: [
          { required: true, trigger: 'change', message: '郵便番号を入力してください。' },
          { validator: this.validateZipCode, trigger: 'change' }
        ],
        address: [
          { required: true, trigger: 'change', message: '住所を入力してください。' },
          { max: 200, trigger: 'change', message: '住所は200文字以下に設定してください。' }
        ],
        telephone: [
          { required: true, trigger: 'change', message: '電話番号を入力してください。' },
          { max: 200, trigger: 'change', message: '電話番号は20文字以下に設定してください。' },
          { validator: this.validateTelephone, trigger: 'change' }
        ]
      }
    }
  },
  methods: {
    validateMail(rule, value, callback) {
      if (!EMAIL_FORMAT.test(value)) {
        return callback(new Error('メールアドレスの形式が不正です。'))
      } else {
        callback()
      }
    },

    validateZipCode(rule, value, callback) {
      if (!ZIP_CODE_FORMAT.test(value)) {
        return callback(new Error('正しい郵便番号を入力してください。'))
      } else {
        callback()
      }
    },

    validateTelephone(rule, value, callback) {
      if (!TELEPHONE_FORMAT.test(value)) {
        return callback(new Error('正しい電話番号を入力してください。'))
      } else {
        callback()
      }
    },

    async validateForm() {
      return new Promise((resolve, reject) => {
        this.$refs.ruleForm.validate(valid => {
          if (valid) {
            resolve(true)
          } else {
            reject(new Error('エラーデータ'))
          }
        })
      })
    },

    async getAddress() {
      if (this.groupBilling.zipCode) {
        const response = await api.get(`/v1/zipaddress/${this.groupBilling.zipCode}`)

        if (response?.data?.address) this.groupBilling.address = response?.data?.address
      }
    }
  }
}
</script>

<style lang="scss">
.group-billing-form-item {
  height: 34px !important;
  margin-bottom: 2 * $spacing-unit !important;

  .el-input__inner {
    height: 34px !important;
    line-height: 34px !important;
  }

  &.is-error {
    margin-bottom: 36px !important;

    .el-form-item__error {
      padding-top: 9px !important;
    }
  }
}

.group-billing-form-submit {
  display: flex;
  justify-content: end;
  margin-top: 4 * $spacing-unit !important;
  margin-bottom: 0 !important;

  .el-form-item__content {
    line-height: 4 * $spacing-unit !important;
  }
}
</style>
<style lang="scss" scoped>
.form-item-label-badge {
  padding: 2px 4px;
  margin-left: $spacing-unit;
  font-size: 12px;
  border-radius: 4 * $round;
}

.form-item-label-badge-required {
  color: #fff;
  background: $danger-color;
}

.form-item-label-badge-optional {
  border: 1px solid $border-color;
}
</style>
