/* @flow */
import TwitterInstantwinAddEdit from '@/client/components/campaign/twitter_instantwin_add_edit'
import { TrackingService } from '@/client/services'

export default {
  components: {
    TwitterInstantwinAddEdit
  },

  data() {
    return {
      tab: '',
      mode: 'add',
      title: '',
      campaignId: null,
      isCopy: false,
      visible: false,
      isChangeWithoutSubmit: false
    }
  },

  methods: {
    /**
     * ダイアログを開く
     * @param {string} type タイプ 'add' | 'edit'
     * @param {number} campaignId キャンペーンID
     * @param {boolean} isCopy
     */
    open(type: string, campaignId: number, campaignType: string, isCopy: boolean = false) {
      switch (type) {
        case 'addTwitter':
          this.tab = 'twitter-instantwin-campaign'
          this.title = this.$gettext('キャンペーンの新規作成(インスタントウィン)')
          this.mode = 'add'
          break
        case 'editTwitter':
          this.tab = 'twitter-instantwin-campaign'
          this.title = this.$gettext('キャンペーンの編集(インスタントウィン)')
          this.mode = 'edit'
          break
        case 'copyTwitter':
          this.tab = 'twitter-instantwin-campaign'
          this.title = this.$gettext('キャンペーンの新規作成(インスタントウィン)')
          this.mode = 'add'
          break
      }

      this.visible = true
      this.campaignId = campaignId
      this.isCopy = isCopy
    },

    /**
     * キャンペーンを保存してダイアログを閉じる
     * @returns {void} void
     */
    savedCampaign(): void {
      this.close(true)
    },

    /**
     * ダイアログを閉じる
     * @param {boolean} isSubmit
     * @returns {void} void
     */
    close(isSubmit: boolean = false): void {
      TrackingService.sendEvent('click:キャンペーンの新規作成(IW)|閉じる')

      if (this.tab === 'twitter-instantwin-campaign' && this.isChangeWithoutSubmit && !isSubmit) {
        const message = this.$gettext('編集内容が保存されていません。ダイアログを閉じてよろしいですか?')
        const confirm = window.confirm(message)

        if (!confirm) {
          return
        }
      }

      this.visible = false
      this.tab = ''
      this.isFormChange = false
    }
  }
}
