import { mapActions, mapState } from 'vuex'

import SerialCodeCreate from '@/client/components/twitter_instantwin_message/serial_code_create'
import SerialCodeTable from '@/client/components/twitter_instantwin_message/serial_code_table'
import SerialCodeUnassignedDelete from '@/client/components/twitter_instantwin_message/serial_code_unassigned_delete'
import SerialCodeUsedImport from '@/client/components/twitter_instantwin_message/serial_code_used_import'
import SerialCodeUsedResets from '@/client/components/twitter_instantwin_message/serial_code_used_resets'

export default {
  data() {
    return {
      title: '',
      visible: false,
      tab: '',
      isEdit: false,
      isFormChanged: false
    }
  },
  components: {
    SerialCodeTable,
    SerialCodeCreate,
    SerialCodeUsedImport,
    SerialCodeUsedResets,
    SerialCodeUnassignedDelete
  },
  computed: {
    ...mapState('twitterInstantwinSerialCode', {
      loading: state => state.loading
    })
  },
  methods: {
    ...mapActions('twitterInstantwinSerialCode', ['fetchSerialCodeList', 'setCampaignId', 'reset']),

    changeTab(tab: string) {
      this.tab = tab
      this.isFormChanged = false
    },

    /**
     * ダイアログを開く
     * @param {number} campaignId
     * @param {string} messageName
     * @param {boolean} isEdit
     */
    open(campaignId: number, messageName: string, isEdit: boolean = false) {
      this.visible = true
      this.title = this.$gettext('シリアルコードの管理')
      this.isEdit = isEdit
      this.tab = 'serial-code-table'

      this.setCampaignId(campaignId)

      if (messageName) {
        this.title += `(${messageName})`
      }
    },

    /**
     * ダイアログ閉じるイベント
     */
    close() {
      const confirmTabs = ['serial-code-create', 'serial-code-used-import']

      if (this.isFormChanged && confirmTabs.includes(this.tab)) {
        const message = this.$gettext('編集内容が保存されていません。ダイアログを閉じてよろしいですか?')
        const confirm = window.confirm(message)

        if (!confirm) {
          return
        }
      }

      this.visible = false
      this.tab = ''
      if (this.$refs.serialCodeTable) {
        this.$refs.serialCodeTable.changeMessageId(0)
        this.$refs.serialCodeTable.changeStatus('')
      }
      this.reset()
    }
  }
}
