/* @flow */
import api from '@/client/core/api'
import { type TCampaign } from '@/typings/api/campaigns.type'
import { type TPutApplicantParams } from '@/typings/api/twitter_applicants.type'
import { type TPrize } from '@/typings/api/twitter_campaign_prizes.type'
import {
  type TGetTwitterPostsParams,
  type TPutTwitterPostsParams,
  type TTwitterPost
} from '@/typings/api/twitter_posts.type'

const SET_POSTS = 'SET_POSTS'
const SET_PARAMS = 'SET_PARAMS'
const SET_CAMPAIGN = 'SET_CAMPAIGN'
const SET_PRIZES = 'SET_PRIZES'
const SET_ENTRY_COUNT = 'SET_ENTRY_COUNT'
const SET_ERROR = 'SET_ERROR'
const SET_LOADING = 'SET_LOADING'
const SET_FIRST_LOADED = 'SET_FIRST_LOADED'
const RESET = 'RESET'

type TState = {
  posts: TTwitterPost[],
  total: number,
  entryCount: number,
  campaign: TCampaign | null,
  params: TGetTwitterPostsParams,
  prizes: TPrize[],
  error: boolean,
  loading: boolean,
  firstLoaded: boolean
}

type Context = {
  commit: Function,
  getters: any,
  state: TState,
  dispatch: Function
}

const initState: TState = {
  posts: [],
  total: 0,
  entryCount: 0,
  campaign: null,
  params: {
    campaignId: 0,
    sortBy: '-publishDatetime',
    pagingNo: 1,
    limitCount: 25,
    prizeId: '',
    postType: '',
    isFollowing: '',
    isMedia: '',
    isCandidate: '',
    isWidget: '',
    isWinner: '',
    isWebInstantwinWinner: '',
    accountName: ''
  },
  prizes: [],
  error: false,
  loading: false,
  firstLoaded: false
}

// state
const state = { ...initState }

// getters
const getters = {}

// actions
const actions = {
  /**
   * 応募履歴一覧の取得
   */
  async fetchPosts(context: Context) {
    context.commit(SET_ERROR, false)
    context.commit(SET_LOADING, true)

    context.dispatch('system/setLoading', true, { root: true })

    const result = await api.get('/twitter_posts', {
      params: context.state.params
    })

    context.dispatch('system/setLoading', false, { root: true })

    context.commit(SET_LOADING, false)

    if (!result.data) {
      context.commit(SET_ERROR, true)
      return
    }

    if (!context.state.firstLoaded) {
      context.commit(SET_FIRST_LOADED, true)
      context.commit(SET_ENTRY_COUNT, result.data.dataCount)
    }

    context.commit(SET_POSTS, {
      posts: result.data.posts,
      total: result.data.dataCount
    })
  },

  /**
   * キャンペーン情報の取得
   */
  async fetchCampaign(context: Context) {
    context.commit(SET_ERROR, false)

    const result = await api.get(`/campaigns/${context.state.params.campaignId}`)

    if (!result.data) {
      context.commit(SET_ERROR, true)
      return
    }

    context.commit(SET_CAMPAIGN, result.data)
  },

  /**
   * 応募グループ一覧の取得
   */
  async fetchPrizes(context: Context) {
    context.commit(SET_ERROR, false)

    const result = await api.get('/twitter_campaign_prizes', {
      params: {
        campaignId: context.state.params.campaignId,
        limitCount: -1
      }
    })

    if (!result.data) {
      context.commit(SET_ERROR, true)
      return
    }

    context.commit(SET_PRIZES, result.data.prizes)
  },

  /**
   * パラメーターを更新
   */
  setParams(context: Context, params: TGetTwitterPostsParams) {
    if (!params.pagingNo) {
      params.pagingNo = 1
    }

    context.commit(SET_PARAMS, { ...context.state.params, ...params })
  },

  /**
   * ステータスを初期化にする
   */
  async reset(context: Context) {
    context.commit(RESET)
  },

  /**
   * ウイジェットを変更
   */
  async updateIsWidget(context: Context, payload: TPutTwitterPostsParams) {
    const result = await api.put('/twitter_posts/' + payload.postId, {
      campaignId: context.state.params.campaignId,
      isWidget: payload.isWidget ? 0 : 1
    })

    if (result.data) {
      await context.dispatch('fetchPosts')
    }

    return result
  },

  /**
   * 候補者フラグを変更
   */
  async updateIsCandidate(context: Context, payload: TPutApplicantParams) {
    const result = await api.put('/twitter_applicants/' + payload.applicantId, {
      isCandidate: payload.isCandidate ? 0 : 1
    })

    if (result.data) {
      await context.dispatch('fetchPosts')
    }

    return result
  },

  /**
   * 当選者フラグを変更
   */
  async updateIsWinner(context: Context, payload: TPutApplicantParams) {
    const result = await api.put('/twitter_applicants/' + payload.applicantId, {
      prizeId: payload.prizeId,
      isWinner: payload.isWinner ? 0 : 1,
      wasWinner: payload.wasWinner
    })

    if (result.data) {
      await context.dispatch('fetchPosts')
    }

    return result
  }
}

// mutations
const mutations = {
  /**
   * 応募履歴一覧の取得
   */
  [SET_POSTS](state: TState, payload: { posts: TTwitterPost[], total: number }) {
    state.posts = payload.posts

    if (payload.total !== undefined) {
      state.total = payload.total
    }
  },

  /**
   * パラメーターを更新
   */
  [SET_PARAMS](state: TState, payload: TGetTwitterPostsParams) {
    state.params = payload
  },

  /**
   * キャンペーン情報の取得
   */
  [SET_CAMPAIGN](state: TState, payload: TCampaign) {
    state.campaign = payload
  },

  /**
   * 応募グループ一覧の取得
   */
  [SET_PRIZES](state: TState, payload: TPrize[]) {
    state.prizes = payload
  },

  /**
   * 応募総数
   */
  [SET_ENTRY_COUNT](state: TState, payload: number) {
    state.entryCount = payload
  },

  /**
   * エラーフラグをセット
   */
  [SET_ERROR](state: TState, payload: boolean) {
    state.error = payload
  },

  /**
   * ローディングフラグをセット
   */
  [SET_LOADING](state: TState, payload: boolean) {
    state.loading = payload
  },

  /**
   * 初回ローディングしたフラグをセット
   */
  [SET_FIRST_LOADED](state: TState, payload: boolean) {
    state.firstLoaded = payload
  },

  /**
   * ストアをリセット
   */
  [RESET](state: TState) {
    const initStateData: any = initState

    Object.keys(initStateData).forEach(key => {
      state[key] = initStateData[key]
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
