/* @flow */
import { mapState } from 'vuex'

import api from '@/client/core/api'
import { TrackingService } from '@/client/services'

export default {
  data() {
    return {
      notifications: {
        is_campaign_start_end: true,
        is_applicant_not_exists: true,
        is_message_queue_send_finished: true,
        is_campaign_report_daily: true,
        is_campaign_report_hourly: false,
        is_campaign_collection_completed: false,
        is_sns_account_added: true
      }
    }
  },
  computed: {
    ...mapState('system', {
      groups: state => state.groups,
      currentGroup: state => state.currentGroup
    })
  },
  async created() {
    const notifications = await api.get('/user_notifications')

    if (notifications.data) {
      this.notifications = { ...this.notifications, ...notifications.data }
    }
  },

  methods: {
    /**
     * 通知メール設定を変更する
     */
    async changeNotifications(value: string) {
      switch (value) {
        case 'is_campaign_start_end':
          TrackingService.sendEvent('switch:ユーザー情報|キャンペーン開始時と終了時')
          break
        case 'is_applicant_not_exists':
          TrackingService.sendEvent('switch:ユーザー情報|キャンペーン開始1時間後に応募者が0件のとき')
          break
        case 'is_message_queue_send_finished':
          TrackingService.sendEvent('switch:ユーザー情報|メッセージの一括配信が完了')
          break
        case 'is_campaign_report_daily':
          TrackingService.sendEvent('switch:ユーザー情報|キャンペーン経過報告（1日ごと）')
          break
        case 'is_campaign_report_hourly':
          TrackingService.sendEvent('switch:ユーザー情報|キャンペーン経過報告（1時間ごと）')
          break
        case 'is_campaign_collection_completed':
          TrackingService.sendEvent('switch:ユーザー情報|キャンペーンの最終集計が完了しとき')
          break
        case 'is_sns_account_added':
          TrackingService.sendEvent('switch:ユーザー情報|SNSアカウント追加されたとき')
          break
        default:
          break
      }

      const result = await api.put('/user_notifications', this.notifications)

      if (!result.data) {
        this.$notify({
          title: this.$gettext('通知メール設定の変更に失敗しました。'),
          customClass: 'danger',
          duration: 5000
        })
        return
      }

      this.$notify({
        title: this.$gettext('通知メール設定を変更しました。'),
        customClass: 'success',
        duration: 5000
      })
    }
  }
}
