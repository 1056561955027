/* @flow */
import api from '@/client/core/api'
import router from '@/client/router'
import {
  type TBillingHistory,
  type TGetBillingHistory,
  type TGetBillingHistoryParams
} from '@/typings/api/billing_histories.type'
import { type TGroupBilling } from '@/typings/api/group_billings.type'
import { type TGuestUser, type TUser } from '@/typings/api/group_users.type'
import { type TGroup } from '@/typings/api/groups.type'
import { type TGetTemplateParams, type TTemplate } from '@/typings/api/message_template.type'
import { type TCampaignAccount } from '@/typings/api/twitter_campaign_accounts.type'
import { type TGetNgApplicantParams, type TNgApplicant } from '@/typings/api/twitter_ng_applicants.type'

// mutations type
const RESET = 'RESET'
const SET_ACCOUNT_DATA = 'SET_ACCOUNT_DATA'
const SET_USER_DATA = 'SET_USER_DATA'
const SET_GUEST_USER_DATA = 'SET_GUEST_USER_DATA'
const SET_TEMPLATE_DATA = 'SET_TEMPLATE_DATA'
const SET_TEMPLATE_PARAM = 'SET_TEMPLATE_PARAM'
const SET_NG_APPLICANT_DATA = 'SET_NG_APPLICANT_DATA'
const SET_NG_APPLICANT_PARAM = 'SET_NG_APPLICANT_PARAM'
const SET_GROUP = 'SET_GROUP'
const SET_BILLING_HISTORY_DATA = 'SET_BILLING_HISTORY_DATA'
const SET_GROUP_BILLING_DATA = 'SET_GROUP_BILLING_DATA'
const SET_BILLING_HISTORY_PARAM = 'SET_BILLING_HISTORY_PARAM'
const SET_ERROR = 'SET_ERROR'

// グループ設定ストア
type SettingGroupStore = {
  accounts: TCampaignAccount[],
  groupSettingUnauthenticatedAccounts: TCampaignAccount[],
  remainAccountsCount: number,
  users: TUser[],
  guestUsers: TGuestUser[],
  remainUsersCount: number,
  remainGuestUsersCount: number,
  templates: TTemplate[],
  templateTotal: number,
  templateParam: TGetTemplateParams,
  ngApplicants: TNgApplicant[],
  ngApplicantParam: TGetNgApplicantParams,
  group: TGroup | null,
  ngApplicantTotal: number,
  billingHistories: TBillingHistory[],
  groupBilling: TGroupBilling,
  billingHistoryTotal: number,
  billingHistoryParam: TGetBillingHistoryParams,
  billingHistoryStartMonth: Date | null,
  billingHistoryEndMonth: Date | null,
  error: boolean
}

// ストアーcontext
type Context = {
  commit: Function,
  getters: any,
  state: SettingGroupStore,
  dispatch: Function
}

// init state
const initState: SettingGroupStore = {
  accounts: [],
  groupSettingUnauthenticatedAccounts: [],
  remainAccountsCount: 0,
  users: [],
  remainUsersCount: 0,
  guestUsers: [],
  remainGuestUsersCount: 0,
  templates: [],
  templateTotal: 0,
  templateParam: {
    groupId: 0,
    sortBy: '-name',
    pagingNo: 1,
    limitCount: 25
  },
  ngApplicantTotal: 0,
  ngApplicants: [],
  ngApplicantParam: {
    groupId: 0,
    sortBy: '-created',
    pagingNo: 1,
    limitCount: 25,
    screenName: null
  },
  group: null,
  billingHistories: [],
  groupBilling: {
    groupId: 0,
    group: {
      name: ''
    },
    companyName: '',
    departName: null,
    personName: null,
    email: null,
    address: null,
    countryCode: null,
    zipCode: null,
    telephone: null
  },
  billingHistoryTotal: 0,
  billingHistoryParam: {
    sortBy: '-target_month',
    pagingNo: 1,
    limitCount: 25
  },
  billingHistoryStartMonth: null,
  billingHistoryEndMonth: null,
  error: false
}
const state = Object.assign({}, initState)

// getters
const getters = {}

// actions
const actions = {
  /**
   * ストアをリセット
   * @param {Context} context
   */
  async reset(context: Context) {
    context.commit(RESET)
  },

  /**
   * 対象グループのアカウント一覧を取得
   * @param {Context} context
   * @param {number} groupId グループID
   */
  async fetchAccounts(context: Context, groupId: number) {
    context.commit(SET_ERROR, false)
    const result = await api.get('/campaign_accounts', {
      params: {
        groupId
      }
    })

    // グループ管理者権限がない場合、キャンペーン画面に移動
    if (!result.data && result.error && result.error.type === 'PERMISSION_DENIED') {
      router.push('/campaigns')
      return
    }

    // データがない場合はエラーフラグをセット
    if (!result.data) {
      context.commit(SET_ERROR, true)
      return
    }
    // アカウント一覧をセット
    context.commit(SET_ACCOUNT_DATA, result.data)
  },

  /**
   * 対象グループのユーザー一覧を取得
   * @param {Context} context
   * @param {number} groupId グループID
   */
  async fetchUsers(context: Context, groupId: number) {
    context.commit(SET_ERROR, false)
    const result = await api.get('/group_users', {
      params: {
        groupId
      }
    })

    // グループ管理者権限がない場合、キャンペーン画面に移動
    if (!result.data && result.error && result.error.type === 'PERMISSION_DENIED') {
      router.push('/campaigns')
      return
    }

    // データがない場合はエラーフラグをセット
    if (!result.data) {
      context.commit(SET_ERROR, true)
      return
    }
    // ユーザー一覧をセット
    context.commit(SET_USER_DATA, result.data)
  },

  /**
   * 対象グループのゲストユーザー一覧を取得
   * @param {Context} context
   * @param {number} groupId グループID
   */
  async fetchGuestUsers(context: Context, groupId: number) {
    context.commit(SET_ERROR, false)
    const result = await api.get('/group_guest_users', {
      params: {
        groupId
      }
    })

    // グループ管理者権限がない場合、キャンペーン画面に移動
    if (!result.data && result.error && result.error.type === 'PERMISSION_DENIED') {
      router.push('/campaigns')
      return
    }

    // データがない場合はエラーフラグをセット
    if (!result.data) {
      context.commit(SET_ERROR, true)
      return
    }
    // ユーザー一覧をセット
    context.commit(SET_GUEST_USER_DATA, result.data)
  },

  /**
   * get template datas
   * @param {Context} context
   * @param {number} groupId グループID
   */
  async fetchTemplates(context: Context, groupId: number) {
    context.commit(SET_ERROR, false)

    const result = await api.get('/message_templates', {
      params: context.state.templateParam
    })

    // グループ管理者権限がない場合、キャンペーン画面に移動
    if (!result.data && result.error && result.error.type === 'GROUP_ADMIN_ROLE_NOT_EXISTS') {
      router.push('/campaigns')
      return
    }

    // データがない場合はエラーフラグをセット
    if (!result.data) {
      context.commit(SET_ERROR, true)
      return
    }
    // ユーザー一覧をセット
    context.commit(SET_TEMPLATE_DATA, result.data)
  },

  /**
   * NGアカウント一覧を取得
   * @param {Context} context
   * @param {number} groupId グループID
   */
  async fetchNgApplicants(context: Context, snsType: string) {
    context.commit(SET_ERROR, false)
    const result = await api.get(`/${snsType}_ng_applicants`, {
      params: context.state.ngApplicantParam
    })

    // グループ管理者権限がない場合、キャンペーン画面に移動
    if (!result.data && result.error && result.error.type === 'PERMISSION_DENIED') {
      router.push('/campaigns')
      return
    }

    // データがない場合はエラーフラグをセット
    if (!result.data) {
      context.commit(SET_ERROR, true)
      return
    }
    // アカウント一覧をセット
    context.commit(SET_NG_APPLICANT_DATA, result.data)
  },

  /**
   * グループを取得
   * @param {Context} context
   * @param {number} groupId グループID
   */
  async fetchGroup(context: Context, groupId: number) {
    context.commit(SET_ERROR, false)

    const result = await api.get(`/groups/${groupId}`)

    // データがない場合はエラーフラグをセット
    if (!result.data) {
      context.commit(SET_ERROR, true)
      return
    }

    // グルプをセット
    context.commit(SET_GROUP, result.data)
  },

  /**
   * Get billing histories
   * @param {Context} context
   * @param {number} groupId グループID
   */
  async fetchBillingHistories(context: Context, groupId: number) {
    context.commit(SET_ERROR, false)

    const result = await api.get(`/billing_histories/${groupId}`, {
      params: Object.assign(context.state.billingHistoryParam)
    })

    // グループ管理者権限がない場合、キャンペーン画面に移動
    if (!result.data && result.error && result.error.type === 'PERMISSION_DENIED') {
      router.push('/campaigns')
      return
    }

    // ユーザー一覧をセット
    context.commit(SET_BILLING_HISTORY_DATA, result.data)
  },

  /**
   * グループ請求先情報を取得
   * @param {Context} context
   * @param {number} groupId グループID
   */
  async fetchGroupBilling(context: Context, groupId: number) {
    context.commit(SET_ERROR, false)

    const result = await api.get(`/v1/group_billings/${groupId}`)
    // グループ管理者権限がない場合、キャンペーン画面に移動
    if (!result || !result?.data || result?.error) {
      router.push('/campaigns')
      return
    }

    if (result.data) {
      // グループ請求先情報を設定する
      return context.commit(SET_GROUP_BILLING_DATA, result.data)
    }
  },

  /**
   * set param template
   * @param {Context} context
   * @param {TGetTemplateParams} params パラメーター
   */
  setParamTemplate(context: Context, params: TGetTemplateParams) {
    if (!params.pagingNo) {
      params.pagingNo = 1
    }

    context.commit(SET_TEMPLATE_PARAM, Object.assign({}, context.state.templateParam, params))
  },

  /**
   * set param template
   * @param {Context} context
   * @param {TGetNgApplicantsParams} params パラメーター
   */
  setParamNgApplicant(context: Context, params: TGetNgApplicantParams) {
    if (!params.pagingNo) {
      params.pagingNo = 1
    }

    context.commit(SET_NG_APPLICANT_PARAM, Object.assign({}, context.state.ngApplicantParam, params))
  },

  /**
   * set param billing history
   * @param {Context} context
   * @param {TGetBillingHistoryParams} params パラメーター
   */
  setParamBillingHistory(context: Context, params: TGetBillingHistoryParams) {
    if (!params.pagingNo) {
      params.pagingNo = 1
    }

    context.commit(SET_BILLING_HISTORY_PARAM, Object.assign({}, context.state.billingHistoryParam, params))
  }
}

// mutations
const mutations = {
  /**
   * ストアをリセット
   * @param {SettingGroupStore} state
   */
  [RESET](state: SettingGroupStore) {
    const initStateData: any = initState
    Object.keys(initStateData).forEach(key => {
      state[key] = initStateData[key]
    })
  },

  /**
   * アカウント一覧をセット
   * @param {SettingGroupStore} state
   * @param {TC[]} accounts アカウント一覧
   */
  [SET_ACCOUNT_DATA](
    state: SettingGroupStore,
    accountData: {
      remainAccountsCount: number,
      accounts: TCampaignAccount[],
      unauthenticatedAccounts: TCampaignAccount[]
    }
  ) {
    state.accounts = accountData.accounts
    state.remainAccountsCount = accountData.remainAccountsCount
    state.groupSettingUnauthenticatedAccounts = accountData.unauthenticatedAccounts
  },

  /**
   * ユーザー一覧をセット
   * @param {SettingGroupStore} state
   * @param {{ remainUsersCount: number, users: TUser[] }} user ユーザー一覧
   */
  [SET_USER_DATA](state: SettingGroupStore, userData: { remainUsersCount: number, users: TUser[] }) {
    state.users = userData.users
    state.remainUsersCount = userData.remainUsersCount
  },

  /**
   * ゲストユーザー一覧をセット
   * @param {SettingGroupStore} state
   * @param {{ remainGuestUsersCount: number, guestUsers: TGuestUser[] }} user ゲストユーザー一覧
   */
  [SET_GUEST_USER_DATA](
    state: SettingGroupStore,
    userData: { remainGuestUsersCount: number, guestUsers: TGuestUser[] }
  ) {
    state.guestUsers = userData.guestUsers
    state.remainGuestUsersCount = userData.remainGuestUsersCount
  },

  /**
   * Set template datas
   * @param {SettingGroupStore} state
   * @param {{ dataCount: number, templates: TTemplate[] }} templateData
   */
  [SET_TEMPLATE_DATA](state: SettingGroupStore, templateData: { dataCount: number, templates: TTemplate[] }) {
    state.templates = templateData.templates
    state.templateTotal = templateData.dataCount
  },

  /**
   * Set param
   * @param {SettingGroupStore} state
   * @param {TGetTemplateParams} params パラメーター
   */
  [SET_TEMPLATE_PARAM](state: SettingGroupStore, params: TGetTemplateParams) {
    state.templateParam = params
  },

  /**
   * Set ng applicants datas
   * @param {SettingGroupStore} state
   * @param {{ dataCount: number, ngApplicants: TNgApplicant[] }} ngApplicantData
   */
  [SET_NG_APPLICANT_DATA](
    state: SettingGroupStore,
    ngAccountData: { dataCount: number, ngApplicants: TNgApplicant[] }
  ) {
    state.ngApplicants = ngAccountData.ngApplicants
    state.ngApplicantTotal = ngAccountData.dataCount
  },

  /**
   * Set param
   * @param {SettingGroupStore} state
   * @param {TGetNgApplicantsParams} params パラメーター
   */
  [SET_NG_APPLICANT_PARAM](state: SettingGroupStore, params: TGetNgApplicantParams) {
    state.ngApplicantParam = params
  },

  [SET_BILLING_HISTORY_DATA](state: SettingGroupStore, billingHistoryData: TGetBillingHistory) {
    state.billingHistories = billingHistoryData.billingHistories
    state.billingHistoryTotal = billingHistoryData.dataCount
    state.billingHistoryStartMonth = billingHistoryData.startContractMonth
    state.billingHistoryEndMonth = billingHistoryData.endContractMonth
  },

  /**
   * グループ請求先情報をセット
   * @param {SettingGroupStore} state
   * @param {TGroupBilling} params パラメーター
   */
  [SET_GROUP_BILLING_DATA](state: SettingGroupStore, groupBillingsData: TGroupBilling) {
    state.groupBilling = groupBillingsData
  },

  [SET_BILLING_HISTORY_PARAM](state: SettingGroupStore, params: TGetBillingHistoryParams) {
    state.billingHistoryParam = params
  },

  /**
   * Set group data
   * @param {SettingGroupStore} state
   * @param {{ data: TGroup }} group
   */
  [SET_GROUP](state: SettingGroupStore, group: TGroup) {
    state.group = group
  },

  /**
   * エラーフラグをセット
   * @param {SettingGroupStore} state
   * @param {boolean} error エラーフラグ
   */
  [SET_ERROR](state: SettingGroupStore, error: boolean) {
    state.error = error
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
