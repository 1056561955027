/* @flow */
import Highlight from '@/client/core/highlight'

export default {
  props: {
    text: {
      type: String,
      default: ''
    },
    highlights: {
      type: Array
    }
  },
  computed: {
    highlightText() {
      return Highlight.getHighlightText(this.highlights, this.text)
    }
  }
}
