/* @flow */
import api from '@/client/core/api'
import {
  type TGetEntryHistory,
  type TGetEntryHistoryParams
} from '@/typings/api/instagram_campaign_entry_histories.type'

// mutations type
const RESET = 'RESET'
const SET_ENTRY_HISTORY_DATA = 'SET_ENTRY_HISTORY_DATA'
const SET_ERROR = 'SET_ERROR'
const SET_LOADING = 'SET_LOADING'
const SET_PARAMS = 'SET_PARAMS'

// 応募者ストア
type EntryHistoryStore = {
  error: boolean,
  total: number,
  loading: boolean,
  entryHistories: TGetEntryHistory[],
  params: TGetEntryHistoryParams
}

// ストアーcontext
type Context = {
  commit: Function,
  getters: any,
  state: EntryHistoryStore,
  dispatch: Function
}

// init state
const initState: EntryHistoryStore = {
  error: false,
  entryHistories: [],
  total: 0,
  loading: false,
  params: {
    groupId: 0,
    campaignAccountId: 0,
    accountId: 0,
    isWinner: 0,
    sortBy: '-lastPostDatetime',
    pagingNo: 1,
    limitCount: 25,
    campaignId: 0,
    campaignStartDatetime: ''
  }
}
const state = Object.assign({}, initState)

// getters
const getters = {}

// actions
const actions = {
  /**
   * ストアをリセット
   * @param {Context} context
   */
  async reset(context: Context) {
    context.commit(RESET)
  },

  /**
   * get entry history
   * @param {Context} context
   */
  async fetchEntryHistory(context: Context) {
    const params = context.state.params

    if (params.groupId === 0) {
      return
    }

    context.commit(SET_ERROR, false)
    context.commit(SET_LOADING, true)

    const result = await api.get('/instagram_campaign_entry_histories', {
      params
    })

    context.commit(SET_LOADING, false)
    // データがない場合はエラーフラグをセット
    if (!result.data) {
      context.commit(SET_ERROR, true)
      return
    }

    // 応募グループ一覧をセット
    context.commit(SET_ENTRY_HISTORY_DATA, {
      entryHistories: result.data.entryHistories,
      total: result.data.dataCount
    })
  },

  /**
   * パラメーターをセット
   * @param {Context} context
   * @param {TGetEntryHistoryParams} params パラメーター
   */
  setParams(context: Context, params: TGetEntryHistoryParams) {
    if (!params.pagingNo) {
      params.pagingNo = 1
    }
    context.commit(SET_PARAMS, Object.assign({}, context.state.params, params))
  }
}

// mutations
const mutations = {
  /**
   * ストアをリセット
   * @param {EntryHistoryStore} state
   */
  [RESET](state: EntryHistoryStore) {
    const initStateData: any = initState
    Object.keys(initStateData).forEach(key => {
      state[key] = initStateData[key]
    })
  },

  /**
   * 応募グループデータをセット
   * @param {EntryHistoryStore} state
   * @param {TGetEntryHistory[]} entryHistory 応募者一覧
   */
  [SET_ENTRY_HISTORY_DATA](
    state: EntryHistoryStore,
    entryHistoryData: { total: number, entryHistories: TGetEntryHistory[] }
  ) {
    state.entryHistories = entryHistoryData.entryHistories
    state.total = entryHistoryData.total
  },

  /**
   * 応募グループパラメーターをセット
   * @param {EntryHistoryStore} state
   * @param {TGetEntryHistoryParams} params パラメーター
   */
  [SET_PARAMS](state: EntryHistoryStore, params: TGetEntryHistoryParams) {
    state.params = params
  },

  /**
   * エラーフラグをセット
   * @param {EntryHistoryStore} state
   * @param {boolean} error エラーフラグ
   */
  [SET_ERROR](state: EntryHistoryStore, error: boolean) {
    state.error = error
  },

  /**
   * Set loading
   * @param {EntryHistoryStore} state
   * @param {boolean} loading
   */
  [SET_LOADING](state: EntryHistoryStore, loading: boolean) {
    state.loading = loading
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
