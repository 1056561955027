/* flow */
import { mapState } from 'vuex'

import AnalyticsTotal from '@/client/components/basic/analytics_total'

export default {
  components: { AnalyticsTotal },
  computed: {
    ...mapState('instagramAnalyticsPublic', {
      campaignAnalytics: state => state.campaignAnalytics,
      columnChartData: state => state.campaignAnalytics.slice(0, state.campaignAnalytics.length - 1)
    }),
    entryCount() {
      return this.getTotal(this.columnChartData, 'entry_count')
    },
    applicantCount() {
      return this.getTotal(this.columnChartData, 'applicant_count')
    },
    newApplicantCount() {
      return this.getTotal(this.columnChartData, 'new_applicant_count')
    },
    winnerCount() {
      return this.getTotal(this.columnChartData, 'winner_count')
    },
    followerUpDown() {
      const firstFollowerCount = this.getFirstValueExceptNull(this.campaignAnalytics, 'follower_count')

      return this.getLastValue(this.campaignAnalytics, 'follower_count') - firstFollowerCount
    }
  },
  methods: {
    /**
     * 足し算
     */
    addition(numbers: number[] = []): number | null {
      const values = numbers.filter(v => v !== null)

      if (!values.length) {
        return null
      }

      return numbers.map(v => this.toNumber(v)).reduce((a, c) => a + c, 0)
    },

    getFirstValueExceptNull(analytics: any[], value: string) {
      const data = analytics.find(analytic => analytic[value] !== null)

      if (!data) {
        return 0
      }

      return data[value]
    },

    /**
     * 最後の値を取得
     */
    getLastValue(analytics: any[], value: string) {
      const data = analytics.filter(analytic => analytic[value] !== null)

      if (data.length === 0) {
        return null
      }

      return data[data.length - 1][value]
    },

    /**
     * 分析データから値の配列を取得
     */
    getMetricArray(insights: any[], metric: string): any[] {
      return insights.map(insight => this.getMetricValue(insight, metric))
    },

    /**
     * 分析データから値を取得
     */
    getMetricValue(insight: any, metric: string): any | null {
      return isNaN(insight[metric]) ? null : insight[metric]
    },

    /**
     * 合計値を取得
     */
    getTotal(data: any, metric: string): number | null {
      return this.addition(this.getMetricArray(data, metric))
    },

    /**
     * 数値変換
     */
    toNumber(value: any): number {
      return !isNaN(value) ? value : 0
    }
  }
}
