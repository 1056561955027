











































import { Component, Emit, Vue } from 'vue-property-decorator'
import { mapActions, mapState } from 'vuex'

import Message from '@/client/components/_atoms/Message.vue'
import Panel from '@/client/components/_atoms/Panel.vue'
import api from '@/client/core/api'
import { SystemActions, SystemState } from '@/client/store/system.store'

@Component({
  components: {
    Message,
    Panel
  },
  computed: mapState('system', ['user']),
  methods: mapActions('system', ['setUser'])
})
export default class CampaignCreateIntro extends Vue {
  user!: SystemState['user']
  setUser!: SystemActions['setUser']

  is_check = true

  @Emit('close')
  async onClose() {
    if (this.is_check) {
      const result = await api.put(
        '/me/settings',
        { settings: { isShowCampaignCreateIntro: Number(!this.is_check) } },
        {}
      )

      if (result.data) {
        const user = { ...this.user, isFirstDisplay: result.data.isShowCampaignCreateIntro }
        this.setUser(user)
      }
    }
  }
}
