import { ActionTree, Module, MutationTree } from 'vuex'

import { API } from '@/client/utils/api'
import { CampaignAccount, GetCampaignAccountData, GetCampaignAccountsResponse } from '@/typings/campaign_accounts'

import { RootState } from './index'

const SET_API_ACCOUNTS = 'SET_API_ACCOUNTS'

export type CampaignAccountState = {
  api_accounts: CampaignAccount[]
}

export type CampaignAccountActions = {
  fetchCampaignAccounts: () => Promise<GetCampaignAccountData>
}

const state: CampaignAccountState = {
  api_accounts: []
}

const mutations: MutationTree<CampaignAccountState> = {
  [SET_API_ACCOUNTS](state, payload: CampaignAccount[]) {
    state.api_accounts = payload
  }
}

const actions: ActionTree<CampaignAccountState, RootState> = {
  /**
   * キャンペーンアカウント一覧を取得
   */
  async fetchCampaignAccounts(context) {
    const response: GetCampaignAccountsResponse = await API.get('v1/campaign_accounts', {
      params: {
        group_id: context.rootState.system.currentGroup.id
      }
    })

    if (response.data) {
      context.commit(SET_API_ACCOUNTS, response.data)
    }

    return response
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
} as Module<CampaignAccountState, RootState>
