/* @flow */
import { mapActions, mapState } from 'vuex'

import InputRangeForm from '@/client/components/basic/input_range_form'
import SelectForm from '@/client/components/basic/select_form'
import { TrackingService } from '@/client/services'

export default {
  components: {
    SelectForm,
    InputRangeForm
  },
  created() {
    this.accountName.value = this.params.accountName
    this.winnerContact.value = this.params.isWinnerContact
    this.postsCount.value = this.params.postsCount
    this.totalCampaignEntryCount.value = this.params.totalCampaignEntryCount
    this.totalCampaignWinningCount.value = this.params.totalCampaignWinningCount
  },
  data() {
    return {
      // アカウント名検索
      accountName: {
        value: '',
        label: this.$gettext('アカウント名検索'),
        placeholder: this.$gettext('検索(完全一致)'),
        onChange: (value: string) => {
          TrackingService.sendEvent('input:応募一覧（TikTok）|アカウント名検索')

          this.accountName.value = value
        }
      },
      // 当選連絡
      winnerContact: {
        value: '',
        label: this.$gettext('当選連絡'),
        size: 'small',
        icon: '',
        options: [
          {
            label: this.$gettext('指定なし'),
            value: ''
          },
          {
            label: this.$gettext('当選連絡フラグあり'),
            value: 1
          },
          {
            label: this.$gettext('当選連絡フラグなし'),
            value: 0
          }
        ],
        onChange: (value: string | number) => {
          TrackingService.sendEvent('select:応募一覧（TikTok）|当選連絡')

          this.winnerContact.value = value
        }
      },
      // 応募数
      postsCount: {
        value: ['', '']
      },
      // 累計参加数
      totalCampaignEntryCount: {
        value: ['', '']
      },
      // 累計当選数
      totalCampaignWinningCount: {
        value: ['', '']
      }
    }
  },
  computed: {
    ...mapState('tiktokApplicant', {
      params: state => state.params,
      campaignAccountId: state => (state.campaign && state.campaign.account ? state.campaign.account.id : ''),
      isLoading: state => state.isLoading
    })
  },
  methods: {
    ...mapActions('tiktokApplicant', ['setParams', 'fetchApplicants']),
    changePostsCount(val: number[]) {
      if (this.postsCount.value[0] !== val[0]) {
        TrackingService.sendEvent('input:応募一覧（TikTok）|応募数|以上')
      } else if (this.postsCount.value[1] !== val[1]) TrackingService.sendEvent('input:応募一覧（TikTok）|応募数|未満')

      this.postsCount.value = val
    },
    changeTotalCampaignEntryCount(val: number[]) {
      if (this.totalCampaignEntryCount.value[0] !== val[0]) {
        TrackingService.sendEvent('input:応募一覧（TikTok）|累計参加数|以上')
      } else if (this.totalCampaignEntryCount.value[1] !== val[1])
        TrackingService.sendEvent('input:応募一覧（TikTok）|累計参加数|未満')

      this.totalCampaignEntryCount.value = val
    },
    changeTotalCampaignWinningCount(val: number[]) {
      if (this.totalCampaignWinningCount.value[0] !== val[0]) {
        TrackingService.sendEvent('input:応募一覧（TikTok）|累計当選数|以上')
      } else if (this.totalCampaignWinningCount.value[1] !== val[1])
        TrackingService.sendEvent('input:応募一覧（TikTok）|累計当選数|未満')

      this.totalCampaignWinningCount.value = val
    },
    async changeAdvanceFilter() {
      TrackingService.sendEvent('click:応募一覧（TikTok）|詳細条件を追加して検索')

      await this.setParams({
        accountName: this.accountName.value,
        isWinnerContact: this.winnerContact.value,
        postsCount: this.postsCount.value,
        totalCampaignEntryCount: this.totalCampaignEntryCount.value,
        totalCampaignWinningCount: this.totalCampaignWinningCount.value
      })

      await this.fetchApplicants()
    }
  }
}
