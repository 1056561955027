/* @flow */
import WidgetPreviewGrid from '@/client/components/basic/widget_preview_grid'
import WidgetPreviewMessage from '@/client/components/basic/widget_preview_message'
import api from '@/client/core/api'
import { TrackingService } from '@/client/services'

export default {
  components: {
    WidgetPreviewGrid,
    WidgetPreviewMessage
  },

  props: {
    campaign: Object
  },

  data() {
    return {
      visible: false,
      loadMoreText: '続きを表示',
      defaultLimitCount: 12,
      maxLimitCount: 32,
      headCode: '',
      widgetCode: '',
      widgets: [],
      isSubmit: false,
      displayCount: 12,
      isViewMessage: false
    }
  },

  watch: {
    loadMoreText: function() {
      this.renderCode()
    },
    defaultLimitCount: function() {
      this.renderCode()

      this.displayCount = this.defaultLimitCount
    },
    isViewMessage: function() {
      this.renderCode()

      this.displayCount = this.defaultLimitCount
    },
    'campaign.isWidgetCreated': function() {
      this.renderCode()
    }
  },

  computed: {
    preview_widgets() {
      return this.widgets.slice(0, this.displayCount)
    },
    textarea_rows() {
      if (this.campaign.isWidgetCreated) {
        return 13
      }

      return 1
    },
    snsType() {
      return this.campaign.snsType
    }
  },

  methods: {
    /**
     * ダイアログを開く
     */
    open() {
      this.visible = true

      if (this.campaign.isWidgetCreated === 1) {
        this.renderCode()

        this.getWidgets()
      } else {
        this.headCode = 'ウィジェットデータを生成するため、上記ウィジェットデータの作成ボタンをクリックしてください。'
        this.widgetCode = 'ウィジェットデータを生成するため、上記ウィジェットデータの作成ボタンをクリックしてください。'
      }
    },

    /**
     * コードをレンダリング
     */
    async renderCode() {
      let headCode = '<!-- ATELU Widget Code -->\n'
      headCode += '<script>\n'
      headCode += '(function(d, s, id) {\n'
      headCode += '  var js, fjs = d.getElementsByTagName(s)[0];\n'
      headCode += '  if (d.getElementById(id)) return;\n'
      headCode += '  js = d.createElement(s); js.id = id;\n'
      headCode += "  js.src = 'https://widget-atelu.comnico.jp/widget.js';\n"
      headCode += '  fjs.parentNode.insertBefore(js, fjs);\n'
      headCode += "}(document, 'script', 'atelu-widget-js'));\n"
      headCode += '</script>\n'
      headCode += '<!-- End ATELU Widget Code -->'

      if (this.isViewMessage) {
        headCode += '\n\n'
        headCode += '<!-- Widget Settings https://github.com/bigbite/macy.js -->\n'
        headCode += '<script>\n'
        headCode += ' window.widgetMacySetting = {\n'
        headCode += '   margin: {\n'
        headCode += '     x: 8,\n'
        headCode += '     y: 8\n'
        headCode += '   },\n'
        headCode += '   columns: 4,\n'
        headCode += '   breakAt: {\n'
        headCode += '     "768": 2\n'
        headCode += '   }\n'
        headCode += ' }\n'
        headCode += `</script>\n`
        headCode += '<!-- End Widget Settings -->'
      }
      this.headCode = headCode

      let widgetCode = `<div class="atelu_widget" id="atelu_widget_${this.campaign.hash}"`

      if (this.loadMoreText !== '続きを表示') {
        widgetCode += ` data-readmore="${this.loadMoreText}"`
      }

      if (this.defaultLimitCount !== 12) {
        widgetCode += ` data-limit="${this.defaultLimitCount}"`
      }

      if (this.isViewMessage) {
        widgetCode += ' data-is-view-message="true"'
      }

      widgetCode += '></div>'

      this.widgetCode = widgetCode
    },

    /**
     * ウィジェットデータを取得
     */
    async getWidgets() {
      const { data } = await api.get('/widgets', {
        params: {
          campaignId: this.campaign.id,
          limitCount: this.maxLimitCount
        }
      })

      if (data) {
        this.widgets = data
      }
    },

    /**
     * ウィジェットデータの作成
     */
    async createWidget() {
      TrackingService.sendEvent(
        `click:応募履歴|ウィジェット管理(${this.snsType === 'twitter' ? 'X' : 'IG'})|ウィジェットデータの生成`
      )

      if (this.isSubmit) {
        return
      }

      this.isSubmit = true

      const result = await api.post('/widgets', {
        campaignId: this.campaign.id
      })

      setTimeout(() => {
        this.isSubmit = false
      }, 2000)

      if (result.error || !result.data) {
        this.isSubmit = false
        this.$notify({
          title: this.$gettext('ウィジェットデータの作成に失敗しました。'),
          message: this.$gettext('恐れ入りますが、時間をおいて再度お試しください。'),
          customClass: 'danger',
          duration: 5000
        })
        return
      }

      this.$notify({
        title: this.$gettext('ウィジェットデータを作成しました。'),
        customClass: 'success',
        duration: 5000
      })

      this.$emit('widget_added')

      await this.getWidgets()
    },

    /**
     * リセット
     */
    reset() {
      this.widgets = []
      this.isSubmit = false
      this.defaultLimitCount = 12
    },

    /**
     * 閉じる前にデータをリセット
     */
    beforeClose() {
      TrackingService.sendEvent(`select:応募履歴|ウィジェット管理(${this.snsType === 'twitter' ? 'X' : 'IG'})|閉じる`)

      this.visible = false
      this.reset()
    },

    /**
     * 続きを表示をクリック
     */
    clickLoadMore() {
      this.displayCount = this.displayCount + this.defaultLimitCount
    },

    handleDefaultLimitCount() {
      if (this.defaultLimitCount === this.displayCount + 1) {
        TrackingService.sendEvent(
          `click:応募履歴|ウィジェット管理(${this.snsType === 'twitter' ? 'X' : 'IG'})|表示件数|UP`
        )
      } else if (this.defaultLimitCount === this.displayCount - 1) {
        TrackingService.sendEvent(
          `click:応募履歴|ウィジェット管理(${this.snsType === 'twitter' ? 'X' : 'IG'})|表示件数|DOWN`
        )
      } else
        TrackingService.sendEvent(
          `input:応募履歴|ウィジェット管理(${this.snsType === 'twitter' ? 'X' : 'IG'})|表示件数`
        )
    },

    onChangeLoadMoreText() {
      TrackingService.sendEvent(
        `input:応募履歴|ウィジェット管理(${this.snsType === 'twitter' ? 'X' : 'IG'})|続きを表示ボタンのテキスト`
      )
    }
  }
}
