/* @flow */
import Util from '@/client/core/util'
import { TrackingService } from '@/client/services'

export default {
  props: {
    isWebInstantwin: {
      type: Boolean,
      default: false
    },
    isWebIwRemindDm: {
      type: Boolean,
      default: false
    },
    isWebIwWinDm: {
      type: Boolean,
      default: false
    },
    isCampaignRemindDm: {
      type: Boolean,
      default: false
    },
    isInstantwin: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: ''
    }
  },
  methods: {
    /**
     * クリップボードにコピーする
     * @param {string} text
     */
    copyToClipboard(text: string) {
      switch (text) {
        case '_account_name_':
          if (this.type === 'templateDialog') {
            TrackingService.sendEvent('click:テンプレート|テンプレートの編集|account_name')
          } else TrackingService.sendEvent('click:当選メッセージの管理|メッセージの新規作成|account_name')
          break
        case '_screen_name_':
          if (this.type === 'templateDialog') {
            TrackingService.sendEvent('click:テンプレート|テンプレートの編集|screen_name')
          } else TrackingService.sendEvent('click:当選メッセージの管理|メッセージの新規作成|screen_name')
          break
        case '_account_id_':
          if (this.type === 'templateDialog') {
            TrackingService.sendEvent('click:テンプレート|テンプレートの編集|account_id')
          } else TrackingService.sendEvent('click:当選メッセージの管理|メッセージの新規作成|account_id')
          break
        case '_serial_code_':
          if (this.type === 'templateDialog') {
            TrackingService.sendEvent('click:テンプレート|テンプレートの編集|serial_code')
          } else TrackingService.sendEvent('click:当選メッセージの管理|メッセージの新規作成|serial_code')
          break
        default:
          break
      }

      Util.copyToClipboard(text)
      this.$message({
        message: `${text}をクリップボードにコピーしました。`,
        type: 'success'
      })
    }
  }
}
