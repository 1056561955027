































import { Component, Vue } from 'vue-property-decorator'

import Message from '@/client/components/_atoms/Message.vue'
import { TrackingService } from '@/client/services'

@Component({
  components: {
    Message
  }
})
export default class TwitterWebInstantwinPreview extends Vue {
  visible = false
  preview = [] as { title: string; url: string; value: string }[]

  /**
   * ダイアログキャンペーンを開く
   */
  async open(preview: { beginUrl: string; endUrl: string; currentUrl: string }) {
    this.visible = true
    this.preview = [
      {
        title: '応募ページ(キャンペーン開始前)',
        url: preview.beginUrl,
        value: 'begin'
      },
      {
        title: '応募ページ(キャンペーン期間中)',
        url: preview.currentUrl,
        value: 'current'
      },
      {
        title: '応募ページ(キャンペーン開始後)',
        url: preview.endUrl,
        value: 'end'
      }
    ]
  }

  /**
   * ダイアログキャンペーンを閉じる
   */
  close() {
    this.visible = false
    this.preview = []
  }

  clickTrackingEvent(value: string) {
    switch (value) {
      case 'begin':
        TrackingService.sendEvent('click: WEB応募ページのプレビュー|応募ページ(キャンペーン開始前)')
        break
      case 'current':
        TrackingService.sendEvent('click: WEB応募ページのプレビュー|応募ページ(キャンペーン期間中)')
        break
      case 'end':
        TrackingService.sendEvent('click: WEB応募ページのプレビュー|応募ページ(キャンペーン開始後)')
        break
      default:
        break
    }
  }
}
