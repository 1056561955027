import moment from 'moment'
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'

import { CAMPAIGN_STATUS } from '@/client/constant'
import { API } from '@/client/utils/api'
import { CampaignAccount, GetCampaignAccountData, GetCampaignAccountsResponse } from '@/typings/campaign_accounts'
import {
  Campaign,
  DeleteCampaignResponse,
  GetCampaignByIdResponse,
  GetValidationResponse,
  PostCampaignResponse,
  PutCampaignResponse
} from '@/typings/campaigns'
import { GetProfileKeywordSettingsResponse } from '@/typings/profile_keyword_settings'

import { TrackingService } from '../services'
import { RootState } from './index'

const SET_API_ACCOUNTS = 'SET_API_ACCOUNTS'
const SET_API_CAMPAIGN = 'SET_API_CAMPAIGN'
const SET_CAMPAIGN_ID = 'SET_CAMPAIGN_ID'
const SET_FORM = 'SET_FORM'
const SET_MODE = 'SET_MODE'
const SET_STEP = 'SET_STEP'
const SET_IS_LOADING = 'SET_IS_LOADING'
const SET_IS_MAX_CONCURRENT_CAMPAIGN = 'SET_IS_MAX_CONCURRENT_CAMPAIGN'
const SET_IS_VALIDATING = 'SET_IS_VALIDATING'

export type CampaignForm = {
  title: string
  account_id: string | number
  target_sns: string
  start_date: string | null
  start_time: string | null
  end_date: string | null
  end_time: string | null
  entry_action_type: string
  campaign_type?: string
  keywords: string[]
  or_keywords?: string[] | null
  positive_keyword?: string[]
  negative_keyword?: string[]
  is_include_mention?: boolean
  is_include_reply?: boolean
  is_keyword_judgment?: boolean
  require_follow?: boolean
  exclude_hunter_account?: boolean
  exclude_account_create_date_within_period?: boolean
  exclude_default_profile_image?: boolean
  exclude_no_description?: boolean
  min_followers?: number
  daily_send_limit_count?: number
  min_tweets?: number
  exclude_description_keyword?: string[]
  page_main_image_url?: string | null
  page_title?: string
  page_text?: string
  page_rules?: string
  page_hash?: string
}

export type CampaignDialogState = {
  api_accounts: CampaignAccount[]
  api_campaign: Campaign | null
  campaign_id: number | null
  form: CampaignForm
  mode: 'create' | 'edit' | 'copy'
  step: number
  is_loading: boolean
  is_max_concurrent_campaign: boolean
  is_validating: boolean
}

export type CampaignDialogGetters = {
  is_form_change: boolean
  is_title_max_length: boolean
  is_next_disabled: boolean
  is_campaign_period_empty: boolean
  is_start_datetime_error: boolean
  is_end_datetime_before_start_datetime: boolean
  is_end_date_time_before_now: boolean
  is_condition_disabled: boolean
  is_edit_disabled: boolean
}

export type CampaignDialogActions = {
  checkCampaign: (payload: {
    start_datetime: string
    end_datetime: string
    exclude_campaign_id?: number
  }) => Promise<void>
  createCampaign: () => Promise<PostCampaignResponse>
  deleteCampaign: () => Promise<DeleteCampaignResponse>
  fetchCampaign: () => Promise<GetCampaignByIdResponse>
  fetchCampaignAccounts: () => Promise<GetCampaignAccountData>
  nextStep: () => Promise<void>
  prevStep: () => Promise<void>
  reset: () => Promise<void>
  getDefaultKeywords: () => Promise<void>
  setCampaignId: (payload: number) => Promise<void>
  setForm: (payload: CampaignForm) => Promise<void>
  setMode: (payload: 'create' | 'edit' | 'copy') => Promise<void>
  updateCampaign: () => Promise<PutCampaignResponse>
}

let page_rules = '・特典の進呈はお一人様1回までとなります。複数アカウントの利用はご遠慮ください。\n'
page_rules +=
  '・不正に複数アカウントを利用し参加されたことが発覚した場合は、商品を無効化するなどの対応を取る場合がございます。ご了承ください。\n'
page_rules +=
  '・ギフトは、ギフト詳細に記載された有効期限までに商品と交換してください。有効期限を過ぎた場合は無効となります。\n'
page_rules += '・商品の譲渡・転売・換金することを固く禁止いたします。\n'
page_rules += '・本キャンペーンは予告なく中止、終了、内容の変更等をする場合がございます。\n'
page_rules += '・当社は、本キャンペーンに関連して応募者、その他第三者に発生した損害等について、一切責任を負いません。\n'

const initial_form: CampaignForm = {
  title: '',
  account_id: '',
  target_sns: 'twitter',
  start_date: null,
  start_time: null,
  end_date: null,
  end_time: null,
  campaign_type: 'keyword',
  entry_action_type: 'retweet',
  keywords: [],
  or_keywords: [],
  positive_keyword: [],
  negative_keyword: [],
  is_include_mention: true,
  is_include_reply: true,
  is_keyword_judgment: true,
  require_follow: true,
  exclude_hunter_account: true,
  exclude_account_create_date_within_period: true,
  exclude_default_profile_image: true,
  exclude_no_description: true,
  min_followers: 0,
  daily_send_limit_count: 10,
  min_tweets: 0,
  exclude_description_keyword: [],
  page_title: '',
  page_main_image_url: '',
  page_text: '',
  page_rules
}

let default_positive_keyword: string[] = []
let default_negative_keyword: string[] = []

const state: CampaignDialogState = {
  api_accounts: [],
  api_campaign: null,
  campaign_id: null,
  form: initial_form,
  mode: 'create',
  step: 0,
  is_loading: false,
  is_max_concurrent_campaign: false,
  is_validating: false
}

const getters: GetterTree<CampaignDialogState, RootState> = {
  is_form_change(state): CampaignDialogGetters['is_form_change'] {
    if (state.mode === 'create') {
      const init_form = {
        ...initial_form,
        account_id: state.api_accounts.length ? state.api_accounts[0].id : '',
        positive_keyword: default_positive_keyword,
        negative_keyword: default_negative_keyword
      }

      const is_change =
        init_form.title !== state.form.title ||
        init_form.account_id !== state.form.account_id ||
        init_form.target_sns !== state.form.target_sns ||
        init_form.start_date !== state.form.start_date ||
        init_form.start_time !== state.form.start_time ||
        init_form.end_date !== state.form.end_date ||
        init_form.end_time !== state.form.end_time ||
        init_form.campaign_type !== state.form.campaign_type ||
        init_form.entry_action_type !== state.form.entry_action_type ||
        init_form.keywords !== state.form.keywords ||
        init_form.or_keywords !== state.form.or_keywords ||
        init_form.positive_keyword !== state.form.positive_keyword ||
        init_form.negative_keyword !== state.form.negative_keyword ||
        init_form.is_include_mention !== state.form.is_include_mention ||
        init_form.is_include_reply !== state.form.is_include_reply

      return is_change
    }

    if (!state.api_campaign) {
      return false
    }

    const is_edit_change =
      state.api_campaign.title !== state.form.title ||
      moment(state.api_campaign.start_datetime).format('YYYY-MM-DD') !== state.form.start_date ||
      moment(state.api_campaign.start_datetime).format('HH:mm') !== state.form.start_time ||
      moment(state.api_campaign.end_datetime).format('YYYY-MM-DD') !== state.form.end_date ||
      moment(state.api_campaign.end_datetime).format('HH:mm') !== state.form.end_time

    const is_copy_change =
      state.api_campaign.title !== state.form.title ||
      (state.api_campaign.account && state.api_campaign.account.id !== state.form.account_id) ||
      state.form.start_date !== null ||
      state.form.start_time !== null ||
      state.form.end_date !== null ||
      state.form.end_time !== null

    if (state.api_campaign.target_sns === 'twitter') {
      const tw_setting = state.api_campaign.tw_setting

      const is_tw_setting_change = Boolean(
        (tw_setting && tw_setting.entry_action_type !== state.form.entry_action_type) ||
          (tw_setting && tw_setting.search_keyword !== state.form.keywords) ||
          (tw_setting && tw_setting.or_keyword !== state.form.or_keywords) ||
          (tw_setting && tw_setting.positive_keyword !== state.form.positive_keyword) ||
          (tw_setting && tw_setting.negative_keyword !== state.form.negative_keyword) ||
          (tw_setting && tw_setting.is_include_mention !== state.form.is_include_mention) ||
          (tw_setting && tw_setting.is_include_reply !== state.form.is_include_reply)
      )

      return state.mode === 'edit' ? is_edit_change || is_tw_setting_change : is_copy_change || is_tw_setting_change
    }

    if (state.api_campaign.target_sns === 'instagram') {
      const in_setting = state.api_campaign.in_setting

      const is_in_setting_change = Boolean(
        (in_setting && in_setting.entry_action_type !== state.form.entry_action_type) ||
          (in_setting && in_setting.search_keyword !== state.form.keywords)
      )

      return state.mode === 'edit' ? is_edit_change || is_in_setting_change : is_copy_change || is_in_setting_change
    }

    if (state.api_campaign.target_sns === 'tiktok') {
      const tt_setting = state.api_campaign.tt_setting

      const is_tt_setting_change = Boolean(
        (tt_setting && tt_setting.entry_action_type !== state.form.entry_action_type) ||
          (tt_setting && tt_setting.search_keyword !== state.form.keywords)
      )

      return state.mode === 'edit' ? is_edit_change || is_tt_setting_change : is_copy_change || is_tt_setting_change
    }

    return false
  },

  is_next_disabled(state, getters): CampaignDialogGetters['is_next_disabled'] {
    if (state.is_validating) return true

    const account = state.api_accounts.find(account => account.id === state.form.account_id)

    if (state.step === 0 && (!account || account.is_token_expired)) {
      return true
    }

    const title = state.form.title.trim()

    if (state.step === 1 && (title.length === 0 || title.length > 50)) {
      return true
    }

    const is_invalid_period =
      state.is_max_concurrent_campaign ||
      getters.is_campaign_period_empty ||
      getters.is_start_datetime_error ||
      getters.is_end_datetime_before_start_datetime ||
      getters.is_end_date_time_before_now

    if (state.step === 2 && is_invalid_period) {
      return true
    }

    const twitter_action_types = [
      'retweet',
      'quote_tweet',
      'retweet_and_quote_tweet',
      'tweet',
      'tweet_media',
      'tweet_conversational',
      'mention'
    ]

    const instagram_action_types = ['mention', 'comment']

    const tiktok_action_types = ['comment']

    if (
      state.step === 3 &&
      state.form.target_sns === 'twitter' &&
      state.form.entry_action_type != 'web_instantwin' &&
      (!state.form.keywords.length ||
        !twitter_action_types.includes(state.form.entry_action_type) ||
        state.form.entry_action_type === 'tweet_conversational')
    ) {
      return true
    }

    if (
      state.step === 3 &&
      state.form.target_sns === 'twitter' &&
      state.form.campaign_type === 'web_instantwin' &&
      (!state.form.keywords.length ||
        !state.form.page_title ||
        state.form.page_title.length > 50 ||
        !state.form.page_text ||
        !state.form.page_rules ||
        !state.form.page_main_image_url)
    ) {
      return true
    }

    if (
      state.step === 3 &&
      state.form.target_sns === 'instagram' &&
      (!state.form.keywords.length || !instagram_action_types.includes(state.form.entry_action_type))
    ) {
      return true
    }

    if (
      state.step === 3 &&
      state.form.target_sns === 'tiktok' &&
      (!state.form.keywords.length || !tiktok_action_types.includes(state.form.entry_action_type))
    ) {
      return true
    }

    return false
  },

  is_edit_disabled(state, getters): CampaignDialogGetters['is_edit_disabled'] {
    if (
      state.api_campaign &&
      [CAMPAIGN_STATUS.DURING_FINAL_COLLECTION, CAMPAIGN_STATUS.COLLECTION_COMPLETED].includes(
        state.api_campaign.status
      )
    ) {
      return state.api_campaign.title === state.form.title
    }

    const twitter_action_types = [
      'retweet',
      'quote_tweet',
      'retweet_and_quote_tweet',
      'tweet',
      'tweet_media',
      'tweet_conversational',
      'mention'
    ]

    const instagram_action_types = ['mention', 'comment']

    const is_invalid_period =
      state.is_max_concurrent_campaign ||
      getters.is_campaign_period_empty ||
      getters.is_start_datetime_error ||
      getters.is_end_datetime_before_start_datetime ||
      getters.is_end_date_time_before_now

    const is_account_deleted = Boolean(!state.api_campaign || !state.api_campaign.account)

    const is_web_instantwin_valid =
      state.form.campaign_type === 'web_instantwin' &&
      (!state.form.page_rules ||
        !state.form.page_text ||
        !state.form.page_main_image_url ||
        !state.form.page_title ||
        state.form.page_title.length > 50)

    return (
      !state.form.title.trim() ||
      state.form.title.trim().length > 50 ||
      is_account_deleted ||
      is_invalid_period ||
      is_web_instantwin_valid ||
      !state.form.keywords.length ||
      (state.form.target_sns === 'twitter' && !twitter_action_types.includes(state.form.entry_action_type)) ||
      state.form.entry_action_type === 'tweet_conversational' ||
      (state.form.target_sns === 'instagram' && !instagram_action_types.includes(state.form.entry_action_type))
    )
  },

  is_start_datetime_error(state): CampaignDialogGetters['is_start_datetime_error'] {
    if (!state.form.start_date || !state.form.start_time) {
      return false
    }

    if (state.mode === 'edit' && state.api_campaign && state.api_campaign.status !== CAMPAIGN_STATUS.BEFORE_EVENT) {
      return false
    }

    const start_datetime = moment(`${state.form.start_date} ${state.form.start_time}`, 'YYYY-MM-DD HH:mm')

    return start_datetime.isBefore(moment())
  },

  is_campaign_period_empty(state): CampaignDialogGetters['is_campaign_period_empty'] {
    return !state.form.start_date || !state.form.start_time || !state.form.end_date || !state.form.end_time
  },

  is_end_datetime_before_start_datetime(state): CampaignDialogGetters['is_end_datetime_before_start_datetime'] {
    if (!state.form.start_date || !state.form.start_time || !state.form.end_date || !state.form.end_time) {
      return false
    }

    const start_datetime = moment(`${state.form.start_date} ${state.form.start_time}`, 'YYYY-MM-DD HH:mm')
    const end_datetime = moment(`${state.form.end_date} ${state.form.end_time}`, 'YYYY-MM-DD HH:mm')

    return end_datetime.isSameOrBefore(start_datetime)
  },

  is_end_date_time_before_now(state): CampaignDialogGetters['is_end_date_time_before_now'] {
    if (!state.form.end_date || !state.form.end_time) {
      return false
    }

    const end_datetime = moment(`${state.form.end_date} ${state.form.end_time}`, 'YYYY-MM-DD HH:mm')

    return end_datetime.isSameOrBefore(moment())
  }
}

const mutations: MutationTree<CampaignDialogState> = {
  [SET_API_ACCOUNTS](state, payload: CampaignAccount[]) {
    state.api_accounts = payload
  },

  [SET_API_CAMPAIGN](state, payload: Campaign) {
    state.api_campaign = payload
  },

  [SET_CAMPAIGN_ID](state, payload: number) {
    state.campaign_id = payload
  },

  [SET_FORM](state, payload: CampaignForm) {
    state.form = payload
  },

  [SET_MODE](state, payload: 'create' | 'edit' | 'copy') {
    state.mode = payload
  },

  [SET_STEP](state, payload: number) {
    state.step = payload
  },

  [SET_IS_LOADING](state, payload: boolean) {
    state.is_loading = payload
  },

  [SET_IS_MAX_CONCURRENT_CAMPAIGN](state, payload: boolean) {
    state.is_max_concurrent_campaign = payload
  },

  [SET_IS_VALIDATING](state, payload: boolean) {
    state.is_validating = payload
  }
}

const actions: ActionTree<CampaignDialogState, RootState> = {
  /**
   * キャンペーン情報を取得
   */
  async fetchCampaign(context) {
    context.commit(SET_IS_LOADING, true)

    const response: GetCampaignByIdResponse = await API.get(`v1/campaigns/${context.state.campaign_id}`)

    context.commit(SET_IS_LOADING, false)

    if (!response || !response.data) {
      return response
    }

    const data = response.data

    const settingMap = {
      twitter: data.tw_setting,
      instagram: data.in_setting,
      tiktok: data.tt_setting
    }

    const setting = settingMap[data.target_sns]

    let form_data: CampaignForm = {
      title: data.title,
      account_id: data.account ? data.account.id : '',
      target_sns: data.target_sns,
      campaign_type: data.type,
      start_date: context.state.mode === 'copy' ? null : moment(data.start_datetime).format('YYYY-MM-DD'),
      start_time: context.state.mode === 'copy' ? null : moment(data.start_datetime).format('HH:mm'),
      end_date: context.state.mode === 'copy' ? null : moment(data.end_datetime).format('YYYY-MM-DD'),
      end_time: context.state.mode === 'copy' ? null : moment(data.end_datetime).format('HH:mm'),
      entry_action_type: setting ? setting.entry_action_type : '',
      keywords: setting ? setting.search_keyword : []
    }

    if (data.target_sns === 'twitter' && data.tw_setting) {
      form_data = {
        ...form_data,
        or_keywords: data.tw_setting.or_keyword,
        positive_keyword: data.tw_setting.positive_keyword,
        negative_keyword: data.tw_setting.negative_keyword,
        is_include_mention: data.tw_setting.is_include_mention,
        is_include_reply: data.tw_setting.is_include_reply,
        is_keyword_judgment: Boolean(
          (data.tw_setting.positive_keyword && data.tw_setting.positive_keyword.length) ||
            (data.tw_setting.negative_keyword && data.tw_setting.negative_keyword.length)
        ),
        require_follow: data.tw_setting.require_follow,
        exclude_hunter_account: data.tw_setting.exclude_hunter_account,
        exclude_account_create_date_within_period: data.tw_setting.exclude_account_create_date_within_period,
        exclude_default_profile_image: data.tw_setting.exclude_default_profile_image,
        exclude_no_description: data.tw_setting.exclude_no_description,
        min_followers: data.tw_setting.min_followers,
        daily_send_limit_count: data.tw_setting.daily_send_limit_count,
        min_tweets: data.tw_setting.min_tweets,
        exclude_description_keyword: data.tw_setting.exclude_description_keyword,
        page_main_image_url: data.tw_setting.page_main_image_url,
        page_title: data.tw_setting.page_title,
        page_text: data.tw_setting.page_text,
        page_rules: data.tw_setting.page_rules,
        page_hash: data.tw_setting.page_hash
      }
    }

    context.commit(SET_FORM, form_data)
    context.commit(SET_API_CAMPAIGN, data)

    return response
  },

  /**
   * キャンペーンアカウント一覧を取得
   */
  async fetchCampaignAccounts(context) {
    const response: GetCampaignAccountsResponse = await API.get('v1/campaign_accounts', {
      params: {
        group_id: context.rootState.system.currentGroup.id
      }
    })

    if (response.data) {
      context.commit(SET_API_ACCOUNTS, response.data)
    }

    return response
  },

  /**
   * キーワード判定セットを取得
   */
  async getDefaultKeywords(context) {
    const response: GetProfileKeywordSettingsResponse = await API.get('v1/profile_keyword_settings', {
      params: {
        group_id: context.rootState.system.currentGroup.id,
        is_active: true
      }
    })

    if (!response || !response.data) {
      return
    }

    const active_keywords = response.data.filter(v => v.is_active)

    const no_keywords: string[] = []

    default_positive_keyword = active_keywords
      .reduce((acc, v) => [...acc, ...v.positive_keyword], no_keywords)
      .filter((v, index, keywords) => keywords.indexOf(v) === index)

    default_negative_keyword = active_keywords
      .reduce((acc, v) => [...acc, ...v.negative_keyword], no_keywords)
      .filter((v, index, keywords) => keywords.indexOf(v) === index)

    context.commit(SET_FORM, {
      ...context.state.form,
      is_keyword_judgment: true,
      positive_keyword: default_positive_keyword,
      negative_keyword: default_negative_keyword
    })
  },

  /**
   * キャンペーンIDをセット
   */
  async setCampaignId(context, payload: number) {
    context.commit(SET_CAMPAIGN_ID, payload)
  },

  /**
   * フォーム情報の変更
   */
  async setForm(context, payload: CampaignForm) {
    context.commit(SET_FORM, payload)
  },

  /**
   * モードをセット
   */
  async setMode(context, payload: 'create' | 'edit' | 'copy') {
    context.commit(SET_MODE, payload)
  },

  /**
   * キャンペーン手続きの次のステップに進む
   */
  async nextStep(context) {
    switch (state.step) {
      case 0:
        TrackingService.sendEvent('click:キャンペーンの新規作成|アカウント|次へ')
        break
      case 1:
        TrackingService.sendEvent('click:キャンペーンの新規作成|タイトル|次へ')
        break
      case 2:
        TrackingService.sendEvent('click:キャンペーンの新規作成|期間|次へ')
        break
      case 3:
        if (state.form.target_sns === 'twitter') {
          TrackingService.sendEvent(`click:キャンペーンの新規作成|応募条件(X)|次へ`)
        } else TrackingService.sendEvent(`click:キャンペーンの新規作成|応募条件(IG)|次へ`)
        break
      default:
        break
    }

    if (context.state.step >= 4) {
      return
    }

    context.commit(SET_STEP, context.state.step + 1)
  },

  /**
   * キャンペーン手続きの前のステップに戻る
   */
  async prevStep(context) {
    switch (state.step) {
      case 1:
        TrackingService.sendEvent('click:キャンペーンの新規作成|タイトル|戻る')
        break
      case 2:
        TrackingService.sendEvent('click:キャンペーンの新規作成|期間|戻る')
        break
      case 3:
        if (state.form.target_sns === 'twitter') {
          TrackingService.sendEvent(`click:キャンペーンの新規作成|応募条件(X)|戻る`)
        } else TrackingService.sendEvent(`click:キャンペーンの新規作成|応募条件(IG)|戻る`)
        break
      case 4:
        TrackingService.sendEvent('click:キャンペーンの新規作成|確認|戻る')
        break
      default:
        break
    }

    if (context.state.step <= 0) {
      return
    }

    context.commit(SET_STEP, context.state.step - 1)
  },

  /**
   * キャンペーンを保存する
   */
  async createCampaign(context) {
    const { form } = context.state

    let settings = {}

    if (form.target_sns === 'twitter') {
      const account = context.state.api_accounts.find(v => v.id === form.account_id)
      const account_screen_name = account ? account.screen_name : null

      settings = {
        entry_action_type: form.entry_action_type,
        search_keyword: form.keywords,
        or_keyword: form.or_keywords,
        positive_keyword: form.is_keyword_judgment ? form.positive_keyword : [],
        negative_keyword: form.is_keyword_judgment ? form.negative_keyword : [],
        is_include_mention: form.is_include_mention,
        is_include_reply: form.is_include_reply,
        campaign_account_screen_name: account_screen_name
      }

      if (form.campaign_type === 'web_instantwin') {
        settings = {
          ...settings,
          require_follow: form.require_follow,
          exclude_hunter_account: form.exclude_hunter_account,
          exclude_account_create_date_within_period: form.exclude_account_create_date_within_period,
          exclude_default_profile_image: form.exclude_default_profile_image,
          exclude_no_description: form.exclude_no_description,
          min_followers: form.min_followers,
          min_tweets: form.min_tweets,
          exclude_description_keyword: form.exclude_description_keyword,
          page_main_image_url: form.page_main_image_url,
          page_title: form.page_title,
          page_text: form.page_text,
          page_rules: form.page_rules
        }
      }
    } else {
      settings = {
        entry_action_type: form.entry_action_type,
        search_keyword: form.keywords
      }
    }

    const settingMap = {
      twitter: 'tw_setting',
      instagram: 'in_setting',
      tiktok: 'tt_setting'
    }

    const params = {
      group_id: context.rootState.system.currentGroup.id,
      account_id: form.account_id,
      target_sns: form.target_sns,
      title: form.title,
      start_datetime: moment(`${form.start_date} ${form.start_time}`, 'YYYY-MM-DD HH:mm').format(),
      end_datetime: moment(`${form.end_date} ${form.end_time}`, 'YYYY-MM-DD HH:mm').format(),
      campaign_type: form.campaign_type,
      [settingMap[form.target_sns]]: settings
    }

    context.commit(SET_IS_LOADING, true)

    const response: PostCampaignResponse = await API.post('v1/campaigns', params)

    context.commit(SET_IS_LOADING, false)

    if (response.data && response.data.id) {
      context.dispatch('setCampaignId', response.data.id)
    }

    return response
  },

  /**
   * キャンペーンを変更する
   */
  async updateCampaign(context) {
    const { form } = context.state

    let settings = {}

    if (form.target_sns === 'twitter') {
      settings = {
        entry_action_type: form.entry_action_type,
        search_keyword: form.keywords,
        or_keyword: form.or_keywords,
        positive_keyword: form.is_keyword_judgment ? form.positive_keyword : [],
        negative_keyword: form.is_keyword_judgment ? form.negative_keyword : [],
        is_include_mention: form.is_include_mention,
        is_include_reply: form.is_include_reply
      }

      if (form.campaign_type === 'web_instantwin') {
        settings = {
          ...settings,
          require_follow: form.require_follow,
          exclude_hunter_account: form.exclude_hunter_account,
          exclude_account_create_date_within_period: form.exclude_account_create_date_within_period,
          exclude_default_profile_image: form.exclude_default_profile_image,
          exclude_no_description: form.exclude_no_description,
          min_followers: form.min_followers,
          min_tweets: form.min_tweets,
          exclude_description_keyword: form.exclude_description_keyword,
          page_main_image_url: form.page_main_image_url,
          page_title: form.page_title,
          page_text: form.page_text,
          page_rules: form.page_rules
        }
      }
    } else {
      settings = {
        entry_action_type: form.entry_action_type,
        search_keyword: form.keywords
      }
    }

    const settingMap = {
      twitter: 'tw_setting',
      instagram: 'in_setting',
      tiktok: 'tt_setting'
    }

    const params = {
      target_sns: form.target_sns,
      title: form.title,
      start_datetime: moment(`${form.start_date} ${form.start_time}`, 'YYYY-MM-DD HH:mm').format(),
      end_datetime: moment(`${form.end_date} ${form.end_time}`, 'YYYY-MM-DD HH:mm').format(),
      campaign_type: form.campaign_type,
      [settingMap[form.target_sns]]: settings
    }

    context.commit(SET_IS_LOADING, true)

    const response: PutCampaignResponse = await API.put(`v1/campaigns/${context.state.campaign_id}`, params)

    context.commit(SET_IS_LOADING, false)

    return response
  },

  /**
   * キャンペーンを削除する
   */
  async deleteCampaign(context) {
    context.commit(SET_IS_LOADING, true)

    const response: DeleteCampaignResponse = await API.delete(`v1/campaigns/${context.state.campaign_id}`)

    context.commit(SET_IS_LOADING, false)

    return response
  },

  /**
   * キャンペーンが同時開催数上限を超えるかチェック
   */
  async checkCampaign(
    context,
    payload: { start_datetime: string; end_datetime: string; exclude_campaign_id?: number }
  ) {
    const params = {
      campaign_id: context.state.campaign_id,
      start_datetime: payload.start_datetime,
      end_datetime: payload.end_datetime,
      group_id: context.rootState.system.currentGroup.id,
      type: 'keyword',
      exclude_campaign_id: payload.exclude_campaign_id ? payload.exclude_campaign_id : 0
    }

    context.commit(SET_IS_VALIDATING, true)

    const response: GetValidationResponse = await API.get('v1/campaigns/validations', { params })

    context.commit(SET_IS_VALIDATING, false)
    if (response.data) {
      context.commit(SET_IS_MAX_CONCURRENT_CAMPAIGN, false)
      return
    }

    context.commit(SET_IS_MAX_CONCURRENT_CAMPAIGN, true)
  },

  /**
   * 状態をリセット
   */
  async reset(context) {
    context.commit(SET_API_ACCOUNTS, [])
    context.commit(SET_CAMPAIGN_ID, null)
    context.commit(SET_FORM, initial_form)
    context.commit(SET_IS_MAX_CONCURRENT_CAMPAIGN, false)
    context.commit(SET_MODE, 'create')
    context.commit(SET_STEP, 0)
    context.commit(SET_IS_LOADING, false)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
} as Module<CampaignDialogState, RootState>
