/* @flow */
import WidgetPreviewItem from '@/client/components/basic/widget_preview_item'

export default {
  components: {
    WidgetPreviewItem
  },
  props: {
    posts: Array,
    isViewMessage: Boolean,
    snsType: String
  }
}
