import HyperDX from '@hyperdx/browser'

import store from '@/client/store'

export class TrackingService {
  /**
   * 初期化
   */
  static init(): void {
    if (process.env.NODE_ENV === 'production') {
      HyperDX.init({
        apiKey: 'bc1138eb-8bc9-456b-9034-82a16966685e',
        service: 'atelu',
        tracePropagationTargets: [/atelu.comnico.jp\/api/i],
        consoleCapture: true,
        advancedNetworkCapture: true
      })
    } else {
      // HyperDXは別途料金が発生するため、開発環境の動作確認時のみコメントアウト
      // HyperDX.init({
      //   apiKey: 'bc1138eb-8bc9-456b-9034-82a16966685e',
      //   service: 'atelu-dev',
      //   tracePropagationTargets: [/dev-atelu.comnico.jp\/api/i],
      //   consoleCapture: true,
      //   advancedNetworkCapture: true
      // })
    }
  }

  /**
   * イベント送信
   */
  static sendEvent(name: string) {
    if (!store.state.system.user.id || !store.state.system.currentGroup.id) return

    // GoogleAnalyticsにイベントを送信
    gtag('event', name, {
      event_category: store.state.system.currentGroup.name,
      event_label: store.state.system.user.name
    })

    // HyperDXは別途料金が発生するため、本番環境だけ送信。開発環境の動作確認時にコメントアウト
    if (process.env.NODE_ENV !== 'production') return

    // HyperDXにイベントを送信
    HyperDX.addAction(name, {})
  }

  /**
   * ログイン情報の送信
   */
  static sendLoginData() {
    if ($crisp) {
      if (!store.state.system.user.id || !store.state.system.currentGroup.id) return

      // crisp(チャットサポートツール)にユーザーデータを送信
      $crisp.push(['safe', true])
      $crisp.push(['set', 'user:email', store.state.system.user.email])
      $crisp.push(['set', 'user:nickname', store.state.system.user.name])
      $crisp.push(['set', 'session:data', ['atelu_user_id', store.state.system.user.id]])
      $crisp.push(['set', 'session:data', ['atelu_recent_group_id', store.state.system.currentGroup.id]])
      $crisp.push(['set', 'session:data', ['atelu_recent_group_name', store.state.system.currentGroup.name]])
      $crisp.push(['set', 'session:segments', [['atelu_user']]])
      $crisp.push(['set', 'session:event', ['atelu:login']])
    }

    // HyperDXは別途料金が発生するため、本番環境だけ送信。開発環境の動作確認時にコメントアウト
    if (process.env.NODE_ENV !== 'production') return

    // HyperDXにユーザーデータを送信。規定値が文字列のため、数値を文字列に変換
    HyperDX.setGlobalAttributes({
      userId: store.state.system.user.id.toString(),
      userEmail: store.state.system.user.email,
      userName: store.state.system.user.name,
      groupId: store.state.system.currentGroup.id.toString(),
      groupName: store.state.system.currentGroup.name,
      groupContractStatus: store.state.system.currentGroup.contractStatus.toString()
    })

    if ($crisp) {
      // crispにHyperDXのセッションURLを関連付ける
      $crisp.push(['set', 'session:data', [[['SessionURL', HyperDX.getSessionUrl()]]]])
    }
  }
}
