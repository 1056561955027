/* @flow */

export default {
  props: {
    tags: {
      type: Array,
      default: () => []
    },
    addTagText: String,
    disabled: {
      type: Boolean,
      default: false
    },
    onChange: Function
  },
  created() {
    if (!this.addTagText) {
      this.addTagText = this.$gettext('追加')
    }
  },
  data() {
    return {
      inputVisible: false,
      inputValue: '',
      tagArray: Object.assign([], this.tags)
    }
  },
  watch: {
    tags() {
      this.tagArray = Object.assign([], this.tags)
    }
  },
  methods: {
    handleRemove(tag: string) {
      this.tagArray.splice(this.tagArray.indexOf(tag), 1)
      this.onChange(this.tagArray)
    },
    handleInputConfirm() {
      const inputValue = this.inputValue.trim()
      if (!this.inputValue || this.tagArray.indexOf(inputValue) >= 0) {
        this.inputVisible = false
        return
      }
      this.tagArray.push(inputValue)
      this.inputVisible = false
      this.inputValue = ''
      this.onChange(this.tagArray)
    },
    showInput() {
      this.inputVisible = true
      this.$nextTick(() => {
        this.$refs.saveTagInput.$refs.input.focus()
      })
    }
  }
}
