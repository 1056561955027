/* @flow */
import moment from 'moment'
import { mapState } from 'vuex'

import ApplicantName from '@/client/components/basic/applicant_name'
import CsvDownload from '@/client/components/basic/csv_download'
import Pagination from '@/client/components/basic/pagination'
import api from '@/client/core/api'

export default {
  components: {
    ApplicantName,
    CsvDownload,
    Pagination
  },
  data() {
    return {
      messages: [],
      loading: false,
      is_download: false,
      total: 0,
      messageId: null,
      status: null
    }
  },
  computed: {
    ...mapState('twitterInstantwinMessage', {
      campaignId: state => state.campaign.id
    }),

    ...mapState('twitterInstantwinQueue', {
      queueId: state => state.queueMessages.queueId
    })
  },
  async mounted() {
    if (this.queueId) {
      await this.getMessageQueueById()
      await this.getMessageTargets()
    }
  },
  methods: {
    /**
     * メッセージを取得
     */
    async getMessageQueueById() {
      const queue = await api.get(`/twitter_instantwin_queues/${this.queueId}`)

      if (!queue || !queue.data) {
        return
      }

      const { status, messageId } = queue.data
      this.messageId = messageId
      this.status = status
    },

    /**
     * 配信対象を取得
     */
    async getMessageTargets() {
      this.loading = true
      const params: any = { campaignId: this.campaignId }

      if (this.messageId) {
        params.messageId = this.messageId
      }

      const result = await api.get(`/twitter_instantwin_queues/targets/${this.queueId}`, { params })
      this.loading = false

      if (!result.data) {
        this.messages = []
        this.total = 0
        return
      }

      this.messages = result.data
      this.total = result.data.length
    },

    /**
     * Change screen
     * @param {string} tab
     */
    changeTab(tab: string) {
      this.$emit('change_tab', tab)
    },

    /**
     * CSVダウンロード
     */
    async downloadCsv() {
      this.is_download = true

      const params: any = { campaignId: this.campaignId }

      if (this.messageId) {
        params.messageId = this.messageId
      }

      const result = await api.get(`/twitter_instantwin_queues/targets/${this.queueId}`, { params })

      this.is_download = false

      if (!result.data) {
        this.$notify({
          title: this.$gettext('CSVダウンロードに失敗しました。'),
          message: this.$gettext('恐れ入りますが、時間をおいて再度お試しください。'),
          customClass: 'danger',
          duration: 5000
        })
        return
      }

      const messages = result.data
      const filename = `instantwin_message_target_${moment().format('YYYY_MM_DD')}`
      const csvData = []
      csvData.push([
        this.$gettext('ステータス'),
        this.$gettext('アカウント名'),
        this.$gettext('アカウントスクリーンネーム'),
        this.$gettext('アカウント画像')
      ])

      const status = this.status === 'scheduled' ? this.$gettext('スケジュール済み') : this.$gettext('下書き')

      // CSVデータを格納
      messages.forEach(message => {
        csvData.push([status, message.name, message.screenName, message.pictureUrl])
      })

      return {
        filename,
        data: csvData
      }
    }
  }
}
