/* @flow */
import moment from 'moment'
import { mapActions, mapState } from 'vuex'

import CsvDownload from '@/client/components/basic/csv_download'
import CampaignAddWidgetDialog from '@/client/components/campaign/campaign_add_widget_dialog'
import TwitterPostFilter from '@/client/components/TwitterPost/TwitterPostFilter'
import TwitterPostGrid from '@/client/components/TwitterPost/TwitterPostGrid'
import TwitterPostTable from '@/client/components/TwitterPost/TwitterPostTable'
import api from '@/client/core/api'
import util from '@/client/core/util'
import { TrackingService } from '@/client/services'

export default {
  components: { TwitterPostFilter, TwitterPostTable, TwitterPostGrid, CsvDownload, CampaignAddWidgetDialog },
  data() {
    return {
      loading: false,
      loadingText: '',
      viewType: 'list',
      sortGrid: '-publishDatetime',
      sortGridOptions: [
        { value: '-publishDatetime', label: '日時(新しい順)' },
        { value: 'publishDatetime', label: '日時(古い順)' }
      ]
    }
  },
  computed: {
    ...mapState('twitterPosts', {
      posts: state => state.posts,
      params: state => state.params,
      entryCount: state => state.entryCount,
      campaign: state => {
        if (state.campaign) {
          return state.campaign
        }

        return {}
      },
      options: state => {
        return {
          total: state.total,
          pagingNo: state.params.pagingNo,
          limitCount: state.params.limitCount,
          sortBy: state.params.sortBy
        }
      },
      error: state => state.error,
      firstLoaded: state => state.firstLoaded,
      isLoading: state => state.loading
    }),

    isLoaded() {
      return !this.error && this.firstLoaded
    },

    isListView() {
      return this.viewType === 'list'
    },

    isGridView() {
      return this.viewType === 'grid'
    },

    lastProcessedData() {
      if (['tweet', 'tweet_media', 'tweet_conversational'].includes(this.campaign.setting.entryActionType)) {
        return 'ポスト取得日時'
      }
      return 'データ取得日時'
    },

    lastProcessedDatetime() {
      if (
        ['quote_tweet', 'retweet', 'retweet_and_quote_tweet', 'mention'].includes(
          this.campaign.setting.entryActionType
        ) &&
        this.campaign.account.enterpriseToken
      ) {
        return 'リアルタイム反映'
      }

      if (!this.campaign.lastProcessedDatetime) {
        return '-'
      }

      return moment(this.campaign.lastProcessedDatetime).format('YYYY年MM月DD日 HH:mm')
    },

    widgetModified() {
      if (this.campaign.isWidgetCreated === 0) {
        return 'ウィジェット未作成'
      }

      if (!this.campaign.widgetModified) {
        return '-'
      }

      return moment(this.campaign.widgetModified).format('YYYY年MM月DD日 HH:mm')
    }
  },
  async mounted() {
    const params = {
      campaignId: this.$route.params.campaignId
    }

    this.setParams(params)

    await this.fetchCampaign()
    await this.fetchPrizes()

    this.setBreadcrumb([
      {
        name: this.$gettext('キャンペーン一覧'),
        link: '/campaigns'
      },
      {
        name: this.campaign.title,
        link: `/twitter_campaigns/${this.campaign.id}/prizes`,
        iconName: this.campaign.account ? this.campaign.account.name : '削除されたアカウント',
        iconUrl: this.campaign.account ? this.campaign.account.pictureUrl : null,
        iconType: this.campaign.snsType,
        isIcon: true
      },
      {
        name: this.$gettext('応募履歴'),
        link: null
      }
    ])
  },
  watch: {
    params() {
      this.fetchPosts()
    }
  },
  methods: {
    ...mapActions('twitterPosts', [
      'setParams',
      'fetchPosts',
      'fetchCampaign',
      'fetchPrizes',
      'reset',
      'updateIsWidget',
      'updateIsCandidate',
      'updateIsWinner'
    ]),
    ...mapActions('navbar', ['setBreadcrumb']),

    /**
     */
    async downloadCsv() {
      TrackingService.sendEvent('click:応募履歴（X）|CSVダウンロード')

      const confirm = window.confirm(
        '現在の検索条件に一致する応募者を対象に、5万件までの応募データをCSVダウンロードできます。\n' +
          'ダウンロードはキャンペーンごとに、応募者一覧または応募履歴のどちらかで、1日1回のみです。\n' +
          'ダウンロードを開始してよろしいですか？'
      )

      if (!confirm) {
        return
      }

      await api.put(`campaigns/${this.$route.params.campaignId}/status`)

      const posts = await this.getApplicantsForCsv()
      if (!posts.length) {
        return { error: 'ERROR_LOADING' }
      }

      const filename = `campaign_posts_${this.campaign.title}_${moment().format('YYYY_MM_DD')}`
      const csvData = []

      const head = [
        this.$gettext('応募日時'),
        this.$gettext('応募グループ'),
        this.$gettext('ポスト種別'),
        this.$gettext('アカウント名'),
        this.$gettext('アカウントスクリーン名'),
        this.$gettext('アカウントURL'),
        this.$gettext('投稿内容'),
        this.$gettext('投稿画像URL'),
        this.$gettext('投稿URL'),
        this.$gettext('フォロー中'),
        this.$gettext('web当選者'),
        this.$gettext('NGアカウント')
      ]

      if (this.campaign.type !== 'web_instantwin') {
        head.push(this.$gettext('ウィジェット表示'))
      }

      head.push(...[this.$gettext('候補者'), this.$gettext('当選者')])

      csvData.push(head)

      // CSVデータを格納
      posts.forEach(post => {
        const data = [
          this.$options.filters.datetime(post.publishDatetime),
          post.prizeName,
          this.getType(post.postType),
          post.name,
          post.screenName,
          'https://www.x.com/' + post.screenName,
          post.message ? post.message.replace(/(\r\n|\n|\r)/gm, '') : '',
          post.mediaUrl,
          `https://www.x.com/${post.screenName}/status/${post.id}`,
          post.isFollowing,
          post.isWebInstantwinWinner,
          post.isNg
        ]

        if (this.campaign.type !== 'web_instantwin') {
          data.push(post.isWidget)
        }

        data.push(...[post.isCandidate, post.isWinner])

        csvData.push(data)
      })

      return {
        filename,
        data: csvData
      }
    },

    /**
     * CSV用応募履歴一覧を取得
     */
    async getApplicantsForCsv() {
      this.loading = true
      const { total } = this.options

      const appliciantLimit = 50000
      const csvInfoTotal = Math.min(total, appliciantLimit)

      this.getTextLoading(0, csvInfoTotal)

      const params = Object.assign({}, this.params, {
        pagingNo: 1,
        limitCount: 1000
      })

      let totalResults = []
      // 1000件ごとに取得する
      for (let count = 0; count < csvInfoTotal; count += 1000) {
        const result = await api.get('/twitter_posts', { params })

        const surplus = (csvInfoTotal - count) / 1000

        if (surplus < 1) {
          this.getTextLoading(csvInfoTotal, csvInfoTotal)
        } else {
          this.getTextLoading(count + 1000, csvInfoTotal)
        }

        if (!result.data) {
          this.loading = false
          return []
        }

        totalResults = totalResults.concat(result.data.posts)
        params.pagingNo = params.pagingNo + 1
      }

      this.loading = false
      return totalResults
    },

    /**
     * ページを変更イベント
     */
    onPaging(payload: { pagingNo: number, limitCount: number }) {
      this.setParams(payload)
      util.scrollToTop()
    },

    /**
     * ソートを変更イベント
     */
    onSorting(value: string) {
      if (this.params.sortBy === value) {
        return
      }

      this.sortGrid = value

      this.setParams({ sortBy: value })
    },

    /**
     * ポスト種別を取得
     */
    getType(type: string) {
      switch (type) {
        case 'tweet':
          return 'ポスト'
        case 'retweet':
          return '公式リポスト'
        case 'quote_tweet':
          return '引用'
        case 'mention':
          return 'メンション'
        case 'reply':
          return '返信'
        default:
          return ''
      }
    },

    /**
     * 戻る
     */
    back() {
      TrackingService.sendEvent('click:応募履歴（X）|戻る')

      this.$router.push(`/twitter_campaigns/${this.campaign.id}/prizes`)
    },

    /**
     * CSVダウンロードの時、進捗を表示する
     * @params {number} acquiredNumber
     * @params {number} totalNumber
     */
    getTextLoading(acquiredNumber: number, totalNumber: number) {
      this.loadingText = this.$gettextInterpolate(
        this.$gettext('CSVファイルの作成中です。しばらくお待ちください。\n %{acquiredNumber}件 / %{totalNumber}件'),
        {
          acquiredNumber,
          totalNumber
        }
      )
    },

    /**
     * ビュータイプの変更
     */
    changeViewType(value: string) {
      TrackingService.sendEvent(`click:応募履歴（X）|${value === 'list' ? '一覧' : 'グリッド'}`)

      this.viewType = value
    },

    /**
     * ウィジェットフラグを変更
     */
    async changeIsWidget(payload: { postId: string, isWidget: number }) {
      const result = await this.updateIsWidget(payload)

      if (!result || !result.data) {
        this.showError('ウィジェットの保存に失敗しました')
      }
    },

    /**
     * 候補者フラグを変更
     */
    async changeIsCandidate(payload: { applicantId: number, isCandidate: number }) {
      const result = await this.updateIsCandidate(payload)

      if (!result || !result.data) {
        this.showError('候補者の保存に失敗しました')
      }
    },

    /**
     * 当選者フラグを変更
     */
    async changeIsWinner(payload: { prizeId: number, applicantId: number, isWinner: number }) {
      const result = await this.updateIsWinner(payload)

      if (result.error && result.error.type === 'MAX_WINNER_COUNT_OVER') {
        this.showError('残り当選数に空きがありません。')
        return
      }

      if (!result || !result.data) {
        this.showError('当選者の保存に失敗しました')
      }
    },

    showError(title: string, message: string) {
      if (!message) {
        this.$notify({
          title: this.$gettext(title),
          customClass: 'danger',
          duration: 5000
        })
      } else {
        this.$notify({
          title: this.$gettext(title),
          message: this.$gettext(message),
          customClass: 'danger',
          duration: 5000
        })
      }
    },

    openCampaignAddWiddetDialog() {
      TrackingService.sendEvent('click:応募履歴（X）|ウィジェット管理')

      this.$refs.campaignAddWidgetDialog.open()
    }
  },
  beforeRouteLeave(to: any, from: any, next: any) {
    this.reset()
    next()
  }
}
