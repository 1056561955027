import Vue from 'vue'
import VueRouter from 'vue-router'

import Campaign from '@/client/pages/campaign'
import Index from '@/client/pages/index'
import InstagramApplicant from '@/client/pages/instagram_applicant'
import InstagramCampaignPrize from '@/client/pages/instagram_campaign_prize'
import InstagramAnalyticsPublic from '@/client/pages/InstagramAnalyticsPublic'
import InstagramPost from '@/client/pages/InstagramPost'
import Invitation from '@/client/pages/invitation'
import Login from '@/client/pages/login'
import Password from '@/client/pages/password'
import SettingGroup from '@/client/pages/setting_group'
import SettingGroupBilling from '@/client/pages/setting_group_billing'
import SettingUser from '@/client/pages/setting_user'
import TikTokApplicant from '@/client/pages/tiktok_applicant'
import TikTokCampaignPrize from '@/client/pages/tiktok_campaign_prize'
import TikTokAnalyticsPublic from '@/client/pages/TikTokAnalyticsPublic'
import TikTokPost from '@/client/pages/TikTokPost'
import TwitterApplicant from '@/client/pages/twitter_applicant'
import TwitterCampaignPrize from '@/client/pages/twitter_campaign_prize'
import TwitterInstantwinMessage from '@/client/pages/twitter_instantwin_message'
import TwitterInstantwinMessageHistory from '@/client/pages/twitter_instantwin_message_history'
import TwitterAnalyticsPublic from '@/client/pages/TwitterAnalyticsPublic'
import TwitterPost from '@/client/pages/TwitterPost'
import { TrackingService } from '@/client/services'
import store from '@/client/store'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/',
      component: Index,
      children: [
        {
          path: '',
          redirect: '/campaigns'
        },
        {
          path: '/campaigns',
          name: 'campaigns',
          component: Campaign
        },
        {
          path: '/campaigns/:campaignId/instagram_posts',
          name: 'instagram_posts',
          component: InstagramPost
        },
        {
          path: '/campaigns/:campaignId/tiktok_posts',
          name: 'tiktok_posts',
          component: TikTokPost
        },
        {
          path: '/twitter_campaigns/:campaignId',
          redirect: '/twitter_campaigns/:campaignId/prizes'
        },
        {
          path: '/twitter_campaigns/:campaignId/prizes/:prizeId/applicants',
          name: 'twitter_applicants',
          component: TwitterApplicant
        },
        {
          path: '/instagram_campaigns/:campaignId/prizes/:prizeId/applicants',
          name: 'instagram_applicants',
          component: InstagramApplicant
        },
        {
          path: '/tiktok_campaigns/:campaignId/prizes/:prizeId/applicants',
          name: 'tiktok_applicants',
          component: TikTokApplicant
        },
        {
          path: '/twitter_campaigns/:campaignId/prizes',
          name: 'twitter_campaigns_prizes',
          component: TwitterCampaignPrize
        },
        {
          path: '/twitter_instantwins/:campaignId',
          redirect: '/twitter_instantwins/:campaignId/messages'
        },
        {
          path: '/twitter_instantwins/:campaignId/messages',
          name: 'twitter_instantwins_messages',
          component: TwitterInstantwinMessage
        },
        {
          path: '/twitter_instantwins/:campaignId/message_histories',
          name: 'twitter_instantwins_message_histories',
          component: TwitterInstantwinMessageHistory
        },
        {
          path: '/instagram_campaigns/:campaignId',
          name: 'instagram_campaigns_prizes',
          redirect: '/instagram_campaigns/:campaignId/prizes'
        },
        {
          path: '/instagram_campaigns/:campaignId/prizes',
          name: 'instagram_campaigns_prizes',
          component: InstagramCampaignPrize
        },
        {
          path: '/tiktok_campaigns/:campaignId',
          name: 'tiktok_campaigns_prizes',
          redirect: '/tiktok_campaigns/:campaignId/prizes'
        },
        {
          path: '/tiktok_campaigns/:campaignId/prizes',
          name: 'tiktok_campaigns_prizes',
          component: TikTokCampaignPrize
        },
        {
          path: '/settings/user',
          name: 'setting_user',
          component: SettingUser
        },
        {
          path: '/settings/group',
          name: 'setting_group',
          component: SettingGroup
        },
        {
          path: '/settings/group_billing',
          name: 'group_billing',
          component: SettingGroupBilling,
          beforeEnter(to, from, next) {
            if (store.state.system.currentGroup.contractConfirmAt) return next('/campaigns')
            next()
          }
        },
        {
          path: '/campaigns/:campaignId/twitter_posts',
          name: 'twitter_posts',
          component: TwitterPost
        },
        {
          path: '/authentication',
          beforeEnter: to => {
            let twitterAuth: any = {}
            if (to.query.snsType === 'twitter') {
              twitterAuth = {
                id: to.query.id,
                name: decodeURI(decodeURIComponent(to.query.name as string)),
                screenName: to.query.screenName,
                pictureUrl: to.query.pictureUrl,
                token: to.query.token,
                secret: to.query.secret,
                enterpriseToken: to.query.enterpriseToken || null,
                enterpriseSecret: to.query.enterpriseSecret || null,
                verified: Number(to.query.verified)
              }
            }

            if (window.opener) {
              window.opener.onAuth(twitterAuth)
            }
          }
        },
        {
          path: '/tiktok_callback',
          name: 'tiktok_callback',
          meta: {
            auth: true
          },
          beforeEnter(to, from, next) {
            if (window.opener) {
              window.opener.onTikTokAuth(to.query)
            }
            next()
          }
        }
      ],
      // ユーザーデータを取得
      beforeEnter: async (to, from, next) => {
        if (!store.state.system.user) {
          await store.dispatch('system/fetchUserData')

          // ユーザー情報がある場合、外部にログイン情報を送信
          if (store.state.system.user) {
            TrackingService.init()
            TrackingService.sendLoginData()
          }
          if (
            !store.state.system.currentGroup.contractConfirmAt &&
            !['/settings/group_billing', '/settings/user'].includes(to.path)
          ) {
            return next({
              path: '/settings/group_billing'
            })
          }
        }
        if (!store.state.system.user) {
          return next({
            path: '/login'
          })
        }
        next()
      }
    },
    {
      path: '/invitations',
      name: 'invitations',
      component: Invitation
    },
    {
      path: '/login',
      component: Login
    },
    {
      path: '/password',
      component: Password
    },
    {
      path: '/publics/twitter_campaign_analytics',
      component: TwitterAnalyticsPublic
    },
    {
      path: '/publics/tiktok_campaign_analytics',
      component: TikTokAnalyticsPublic
    },
    {
      path: '/publics/instagram_campaign_analytics',
      component: InstagramAnalyticsPublic
    },
    {
      path: '*',
      redirect: { path: '/' }
    }
  ]
})

router.beforeEach((to, from, next) => {
  // 支払い情報が利用可能かどうかを確認
  if (
    store.state.system.user &&
    !store.state.system.currentGroup.contractConfirmAt &&
    !['/settings/group_billing', '/settings/user'].includes(to.path)
  ) {
    return next({
      path: '/settings/group_billing'
    })
  }
  next()
})

export default router
