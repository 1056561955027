/* @flow */
import api from '@/client/core/api'
import LocalStorage from '@/client/core/local_storage'

// mutations type
const SET_USER = 'SET_USER'
const SET_CURRENT_GROUP = 'SET_CURRENT_GROUP'
const GET_CURRENT_GROUP = 'GET_CURRENT_GROUP'
const SET_GROUPS = 'SET_GROUPS'
const SET_LANGUAGE = 'SET_LANGUAGE'
const SET_LOADING = 'SET_LOADING'
const SET_GUEST_GROUP = 'SET_GUEST_GROUP'

// 型定義: グループ
type Group = {
  id: number,
  name: string,
  isUserAdmin: number,
  contractStatus: number,
  allowInstantwin: number,
  allowCreateInstantwin: number,
  allowWebInstantwin: number,
  currentMonthlyApplicantCount: number,
  currentMonthlyDmCount: number,
  maxMonthlyApplicantCount: number,
  maxMonthlyDmCount: number,
  currentCampaignCount: number,
  maxCampaignCount: number,
  currentInstantwinCount: number,
  maxInstantwinCount: number,
  contractConfirmAt: string,
  isContractManager: number
}

type GuestGroup = Group

// 型定義: ユーザー
type User = {
  id: number,
  name: string,
  pictureUrl: string,
  email: string,
  isSystemAdmin: number,
  isTwoFactor: number,
  created: string,
  isFirstDisplay: number
}

// 型定義: システムstate
export type SystemState = {
  user: User,
  currentGroup: Group,
  groups: Group[],
  guestGroups: GuestGroup[],
  language: string,
  loading: boolean
}

// 型定義: ストアーcontext
type Context = {
  commit: Function,
  getters: any,
  state: SystemState,
  dispatch: Function
}

export type SystemActions = {
  fetchUserData: () => Promise<any>,
  getCurrentGroup: () => Promise<void>,
  setCurrentGroup: (groupId: number) => Promise<void>,
  setGuestGroups: (guestGroup: GuestGroup[]) => Promise<void>,
  setGroups: (groups: Group[]) => Promise<void>,
  setLanguage: (language: string) => Promise<void>,
  setLoading: (loading: boolean) => Promise<void>,
  setUser: (user: User) => Promise<void>
}

// init state
const state = {
  user: null,
  currentGroup: {},
  groups: [],
  guestGroups: [],
  language: '',
  loading: false
}

// getters
const getters = {}

// actions
const actions = {
  /**
   * ユーザーストアーデータを取得
   * @param {Context} context
   */
  async fetchUserData(context: Context) {
    let userData = null

    try {
      const result = await api.get('/me')

      if (!result.data) {
        return false
      }
      userData = result.data
    } catch (error) {
      return false
    }
    // ユーザーをセット
    const user = {
      id: userData.id,
      name: userData.name,
      pictureUrl: userData.pictureUrl,
      email: userData.email,
      isSystemAdmin: userData.isSystemAdmin,
      isTwoFactor: userData.isTwoFactor,
      created: userData.created,
      isFirstDisplay: userData.isShowCampaignCreateIntro
    }
    context.commit(SET_USER, user)
    // グループ一覧をセット
    const groups = userData.group_user_maps.map(v => {
      return {
        id: v.group.id,
        name: v.group.name,
        isUserAdmin: v.isAdmin,
        contractStatus: v.group.contractStatus,
        allowInstantwin: v.group.allowInstantwin,
        allowCreateInstantwin: v.group.allowCreateInstantwin,
        allowWebInstantwin: v.group.allowWebInstantwin,
        contractConfirmAt: v.group.contractConfirmAt,
        isContractManager: v.isContractManager
      }
    })

    context.commit(SET_GROUPS, groups)

    const guestGroups = userData.group_guest_user_maps.map(v => {
      return {
        id: v.group.id,
        name: v.group.name,
        contractStatus: v.group.contractStatus,
        contractConfirmAt: v.group.contractConfirmAt
      }
    })
    context.commit(SET_GUEST_GROUP, guestGroups)

    // 現在のグループをセット
    let currentGroupId = null
    const currentGroupLocal = LocalStorage.getCurrentGroup()
    if (currentGroupLocal && currentGroupLocal[userData.id]) {
      currentGroupId = currentGroupLocal[userData.id]
    }

    context.commit(SET_CURRENT_GROUP, currentGroupId)
  },

  /**
   * ユーザーデータをセット
   * @param {Context} context
   * @param {User} user ユーザーデータ
   */
  setUser(context: Context, user: User) {
    context.commit(SET_USER, user)
  },

  /**
   * グループ一覧をセット
   * @param {*} context
   * @param {Group[]} groups グループ一覧
   */
  setGroups(context: Context, groups: Group[]) {
    context.commit(SET_GROUPS, groups)
  },

  /**
   * ゲストグループ一覧をセット
   * @param {*} context
   * @param {GuestGroup[]} groups ゲストグループ一覧
   */
  setGuestGroups(context: Context, guestGroups: GuestGroup[]) {
    context.commit(SET_GUEST_GROUP, guestGroups)
  },

  /**
   * 現在のグループをセット
   * @param {*} context
   * @param {number} groupId グループID
   */
  setCurrentGroup(context: Context, groupId: number) {
    context.commit(SET_CURRENT_GROUP, groupId)
  },

  /**
   * 現在のグループを取得
   * @param {*} context
   */
  async getCurrentGroup(context: Context) {
    const groupId = context.state.currentGroup ? context.state.currentGroup.id : null

    let group = {}

    try {
      if (!groupId) return

      const result = await api.get(`/groups/${groupId}`)

      if (!result.data) return

      group = result.data
    } catch (error) {
      return
    }

    context.commit(GET_CURRENT_GROUP, group)
  },

  /**
   * 言語をセット
   * @param {Context} context
   * @param {string} language 言語
   */
  setLanguage(context: Context, language: string) {
    context.commit(SET_LANGUAGE, language)
  },

  /**
   * ローディングフラグをセット
   * @param {Context} context
   * @param {boolean} loading ローディングフラグ
   */
  setLoading(context: Context, loading: boolean) {
    context.commit(SET_LOADING, loading)
  }
}

// mutations
const mutations = {
  /**
   * ユーザーデータをセット
   * @param {SystemState} state
   * @param {User} user ユーザーデータ
   */
  [SET_USER](state: SystemState, user: User) {
    state.user = user
  },

  /**
   * グループ一覧をセット
   * @param {SystemState} state
   * @param {User} user ユーザーデータ
   */
  [SET_GROUPS](state: SystemState, groups: Group[]) {
    state.groups = groups
  },

  /**
   * ゲストグループ一覧をセット
   * @param {SystemState} state
   * @param {GuestGroup} guestGroups ユーザーデータ
   */
  [SET_GUEST_GROUP](state: SystemState, guestGroups: GuestGroup[]) {
    state.guestGroups = guestGroups
  },

  /**
   * 現在のグループをセット
   * @param {SystemState} state
   * @param {number} groupId グループID
   */
  [SET_CURRENT_GROUP](state: SystemState, groupId: number) {
    if (!state.groups.length && !state.guestGroups.length) return

    const currentGroup =
      state.groups.find(group => group.id === groupId) ||
      state.guestGroups.find(v => v.id === groupId) ||
      state.guestGroups[0] ||
      state.groups[0]

    // ローカルストレージに現在のグループを保存する
    const currentGroupLocal = LocalStorage.getCurrentGroup() || {}
    currentGroupLocal[state.user.id] = currentGroup.id
    LocalStorage.setCurrentGroup(currentGroupLocal)

    state.currentGroup = currentGroup
  },

  /**
   * 現在のグループを取得
   * @param {SystemState} state
   * @param {Group} group グループ
   */
  [GET_CURRENT_GROUP](state: SystemState, group: Group) {
    state.currentGroup = Object.assign({}, state.currentGroup, group)
  },

  /**
   * 言語をセット
   * @param {Context} context
   * @param {string} language 言語
   */
  [SET_LANGUAGE](state: SystemState, language: string) {
    state.language = language
  },

  /**
   * 言語をセット
   * @param {Context} context
   * @param {boolean} loading ローディングフラグ
   */
  [SET_LOADING](state: SystemState, loading: boolean) {
    state.loading = loading
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
