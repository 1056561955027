/* @flow */
import moment from 'moment'
import { mapActions, mapState } from 'vuex'

import Icon from '@/client/components/_atoms/Icon.vue'
import { DrawerTwitterApplicant } from '@/client/components/_organisms'
import CsvDownload from '@/client/components/basic/csv_download'
import ApplicantAdvanceFilter from '@/client/components/twitter_applicant/applicant_advance_filter'
import ApplicantFilter from '@/client/components/twitter_applicant/applicant_filter'
import ApplicantGrid from '@/client/components/twitter_applicant/applicant_grid'
import ApplicantImportDialog from '@/client/components/twitter_applicant/applicant_import_dialog'
import ApplicantRandomDialog from '@/client/components/twitter_applicant/applicant_random_dialog'
import ApplicantResetDialog from '@/client/components/twitter_applicant/applicant_reset_dialog'
import ApplicantTable from '@/client/components/twitter_applicant/applicant_table'
import DirectMessageDialog from '@/client/components/twitter_applicant/direct_message_dialog'
import DirectMessageHistoryDialog from '@/client/components/twitter_applicant/direct_message_history_dialog'
import LotteryPageDialog from '@/client/components/twitter_applicant/lottery_page_dialog'
import SerialCodeDialog from '@/client/components/twitter_applicant/serial_code_dialog'
import api from '@/client/core/api'
import util from '@/client/core/util'
import { TrackingService } from '@/client/services'
import { type TApplicant } from '@/typings/api/twitter_applicants.type'

export default {
  components: {
    CsvDownload,
    ApplicantAdvanceFilter,
    ApplicantFilter,
    ApplicantGrid,
    ApplicantImportDialog,
    ApplicantRandomDialog,
    ApplicantResetDialog,
    ApplicantTable,
    DirectMessageDialog,
    SerialCodeDialog,
    DirectMessageHistoryDialog,
    DrawerTwitterApplicant,
    LotteryPageDialog,
    Icon
  },
  data() {
    return {
      loading: false,
      loadingText: '',
      actionDialogs: [
        {
          label: this.$gettext('候補者インポート'),
          value: 'import_candidate'
        },
        {
          label: this.$gettext('当選者インポート'),
          value: 'import_winner'
        },
        {
          label: this.$gettext('回答者インポート'),
          value: 'import_responder'
        },
        {
          label: this.$gettext('候補者解除'),
          value: 'reset_candidate',
          divided: true
        },
        {
          label: this.$gettext('当選者解除'),
          value: 'reset_winner'
        },
        {
          label: this.$gettext('回答者解除'),
          value: 'reset_responder'
        }
      ],
      viewType: 'list'
    }
  },
  async created() {
    this.setParams({ campaignId: this.$route.params.campaignId, prizeId: this.$route.params.prizeId })
    // キャンペーンデータを取得
    await this.fetchCampaign()
    await this.fetchCampaignPrize()
    await this.fetchApplicants()

    if (this.campaign && this.campaign.id && this.currentGroupId !== this.campaign.groupId) {
      this.setCurrentGroup(this.campaign.groupId)
    }

    this.setBreadcrumb([
      {
        name: this.$gettext('キャンペーン'),
        link: '/campaigns'
      },
      {
        name: this.campaign.title,
        link: `/twitter_campaigns/${this.campaign.id}/prizes`,
        iconName: this.campaign.account ? this.campaign.account.name : '削除されたアカウント',
        iconUrl: this.campaign.account.pictureUrl,
        iconType: this.campaign.snsType,
        isIcon: true
      },
      {
        name: this.campaignPrize.name,
        link: null
      }
    ])
  },
  computed: {
    ...mapState('system', {
      currentGroupId: state => state.currentGroup.id,
      contractStatus: state => state.currentGroup.contractStatus
    }),
    ...mapState('twitterApplicant', {
      params: state => state.params,
      applicants: state => state.applicants,
      campaign: state => {
        if (state.campaign) {
          return state.campaign
        }
        return {}
      },
      campaignAccountId: state => {
        if (state.campaign && state.campaign.account) {
          return state.campaign.account.id
        }

        return 0
      },
      campaignPrize: state => {
        if (state.campaignPrize) {
          return state.campaignPrize
        }
        return {}
      },
      options: state => {
        return {
          total: state.total,
          pagingNo: state.params.pagingNo,
          limitCount: state.params.limitCount,
          sortBy: state.params.sortBy
        }
      },
      firstLoaded: state => state.firstLoaded,
      error: state => state.error,
      showAdvanceFilter: state => state.showAdvanceFilter,
      isLoading: state => state.isLoading
    })
  },
  methods: {
    ...mapActions('system', ['setCurrentGroup']),
    ...mapActions('navbar', ['setBreadcrumb']),
    ...mapActions('twitterApplicant', [
      'reset',
      'fetchApplicants',
      'fetchCampaignPrize',
      'fetchCampaign',
      'setParams',
      'updateApplicant',
      'openApplicantDrawer'
    ]),

    /**
     * Open dialog
     * @param {string} value
     */
    openDialog(value: string) {
      TrackingService.sendEvent('select:応募一覧（X）|一括操作')

      if (value.indexOf('reset') !== -1) {
        const type = value.replace('reset_', '')
        this.$refs.applicantResetDialog.open(type)
      } else {
        const type = value.replace('import_', '')
        this.$refs.applicantImportDialog.open(type)
      }
    },

    /**
     * ページを変更イベント
     * @param {{ pagingNo: number, limitCount: number }} page ページ
     */
    onPaging(payload: { pagingNo: number, limitCount: number }) {
      this.setParams(payload)
      util.scrollToTop()
    },

    /**
     * ソートを変更イベント
     * @param {{sortBy: string}} payload ソート
     */
    onSorting(payload: { sortBy: string }) {
      const sortBy = payload.sortBy
      if (this.params.sortBy === sortBy) {
        return
      }
      this.setParams({ sortBy })
    },

    openMessageHistory(payload) {
      this.$refs.directMessageHistoryDialog.open(payload.applicantId)
    },

    openLotteryPageDialog() {
      this.$refs.lotteryPageDialog.open(this.campaign.id, this.campaignPrize.id)
    },

    /**
     * フラグ変更処理
     * @param {{type: string, value: any}} payload
     */
    async changeFlag(payload) {
      const type = payload.type
      const value = payload.value
      let result

      switch (type) {
        case 'winner':
          result = await this.updateApplicant(value)

          // 当選数に空きがない場合はエラーメッセージを表示
          if (result.error && result.error.type === 'MAX_WINNER_COUNT_OVER') {
            this.showError(this.$gettext('残り当選数に空きがありません。'))
            return
          }

          // エラーがある場合はエラーメッセージを表示
          if (!result.data) {
            this.showError(this.$gettext('当選者の保存に失敗しました'))
          }
          break

        case 'responder':
          result = await this.updateApplicant(value)

          // エラーがある場合はエラーメッセージを表示
          if (!result.data) {
            this.showError(this.$gettext('回答者の保存に失敗しました'))
          }
          break

        case 'candidate':
          result = await this.updateApplicant(value)
          // エラーがある場合はエラーメッセージを表示
          if (!result.data) {
            this.showError(this.$gettext('候補者の保存に失敗しました'))
          }
          break
      }
    },

    /**
     * グリッドの切替
     * @param {string} viewType
     */
    changeViewType(viewType: string) {
      TrackingService.sendEvent(`click:応募一覧（X）|${viewType === 'list' ? '一覧' : 'グリッド'}`)

      this.viewType = viewType
    },

    /**
     * エラーを表示
     * @params {string} title タイトル
     * @param {string} message
     */
    showError(title: string, message: string) {
      if (!message) {
        this.$notify({
          title,
          customClass: 'danger',
          duration: 5000
        })
      } else {
        this.$notify({
          title,
          message,
          customClass: 'danger',
          duration: 5000
        })
      }
    },

    /**
     * メッセージの一括ダイアログを開く
     */
    openDirectMessageDialog() {
      TrackingService.sendEvent('click:応募一覧（X）|当選メッセージの管理')

      this.$refs.directMessageDialog.open(this.campaignPrize.id, this.campaign.id)
    },

    /**
     * シリアルコードの管理ダイアログを開く
     */
    openSerialCodeDialog() {
      TrackingService.sendEvent('click:応募一覧（X）|シリアルコードの管理')

      this.$refs.serialCodeDialog.open(this.campaignPrize.id, this.campaign.id)
    },

    /**
     * ランダム抽選ダイアログを開く
     */
    openApplicantRandomDialog() {
      TrackingService.sendEvent('click:応募一覧（X）|ランダム抽選')

      this.$refs.applicantRandomDialog.open()
    },

    /**
     * CSVファイルに追加するフォロワー数、ポスト数、累計参加数、累計当選数のフィルター条件のテキスト
     * @param {number} overNumber 以上の数
     * @param {number} lessNumber 未満の数
     * @param {string} conditionText フィルター条件のテキスト
     */
    createFiterConditionText(overNumber: any, lessNumber: any, conditionText: string) {
      // フォロワー数、ポスト数、累計参加数、累計当選数のフィルター条件が未入力の場合はリターン
      if ((overNumber === undefined && lessNumber === undefined) || (overNumber === '' && lessNumber === '')) {
        return
      }
      return (
        conditionText +
        String(overNumber).replace(/(\d)(?=(\d{3})+\b)/g, '$1,') +
        '~' +
        String(lessNumber).replace(/(\d)(?=(\d{3})+\b)/g, '$1,')
      )
    },

    /**
     * CSVダウンロード
     */
    async downloadCsv() {
      TrackingService.sendEvent('click:応募一覧（X）|CSVダウンロード')

      const confirm = window.confirm(
        '現在の検索条件に一致する応募者を対象に、5万件までの応募データをCSVダウンロードできます。\n' +
          'ダウンロードはキャンペーンごとに、応募者一覧または応募履歴のどちらかで、1日1回のみです。\n' +
          'ダウンロードを開始してよろしいですか？'
      )

      if (!confirm) {
        return
      }

      await api.put(`campaigns/${this.$route.params.campaignId}/status`)

      const applicants: TApplicant[] = await this.getApplicantsForCsv()
      if (!applicants.length) {
        return { error: 'ERROR_LOADING' }
      }

      const filename = `campaign_applicants_${moment().format('YYYY_MM_DD')}`

      // フィルター条件がある場合CSVファイルの一番上に追加する
      const filterCondition = []
      let filterConditionPostType = ''
      let filterConditionIsFollowing = ''
      let filterConditionIsWinner = ''
      let filterConditionIsResponder = ''
      let filterConditionIsWarning = ''
      let filterConditionIsJoinDatetime = ''
      let filterConditionIsProfileImage = ''
      let filterConditionIsDescription = ''
      const params = this.params

      // ポスト種別でフィルター条件がある場合は、フィルター条件に追加する
      switch (params.postType) {
        case 'tweet':
          filterConditionPostType = this.$gettext('ポスト')
          break
        case 'retweet':
          filterConditionPostType = this.$gettext('公式リポスト')
          break
        case 'quote_tweet':
          filterConditionPostType = this.$gettext('引用')
          break
        case 'mention':
          filterConditionPostType = this.$gettext('メンション')
          break
        case 'reply':
          filterConditionPostType = this.$gettext('返信')
          break
      }

      if (filterConditionPostType) {
        filterCondition.push([filterConditionPostType])
      }

      // フォローでフィルター条件がある場合は、フィルター条件に追加する
      switch (params.isFollowing) {
        case 1:
          filterConditionIsFollowing = this.$gettext('フォローあり')
          break
        case 0:
          filterConditionIsFollowing = this.$gettext('フォローなし')
          break
      }

      if (filterConditionIsFollowing) {
        filterCondition.push([filterConditionIsFollowing])
      }

      // 当選者でフィルター条件がある場合は、フィルター条件に追加する
      switch (params.isWinner) {
        case 1:
          filterConditionIsWinner = this.$gettext('当選フラグあり')
          break
        case 0:
          filterConditionIsWinner = this.$gettext('当選フラグなし')
          break
      }

      if (params.isInstantwinWinner) {
        filterConditionIsWinner = this.$gettext('')
      }

      if (filterConditionIsWinner) {
        filterCondition.push([filterConditionIsWinner])
      }

      // 回答者でフィルター条件がある場合は、フィルター条件に追加する
      switch (params.isResponder) {
        case 1:
          filterConditionIsResponder = this.$gettext('回答フラグあり')
          break
        case 0:
          filterConditionIsResponder = this.$gettext('回答フラグなし')
          break
      }

      if (filterConditionIsResponder) {
        filterCondition.push([filterConditionIsResponder])
      }

      // フォロワー数フィルター条件がある場合、フィルター条件に追加する
      const filterConditionFollowerCount = this.createFiterConditionText(
        params.followersCount[0],
        params.followersCount[1],
        this.$gettext('フォロワー数')
      )

      if (filterConditionFollowerCount) {
        filterCondition.push([filterConditionFollowerCount])
      }

      // ポスト数フィルター条件がある場合、フィルター条件に追加する
      const filterConditionTweetsCount = this.createFiterConditionText(
        params.tweetsCount[0],
        params.tweetsCount[1],
        this.$gettext('ポスト数')
      )

      if (filterConditionTweetsCount) {
        filterCondition.push([filterConditionTweetsCount])
      }

      // 累計参加数フィルター条件がある場合、フィルター条件に追加する
      const filterConditiontotalCampaignEntryCount = this.createFiterConditionText(
        params.totalCampaignEntryCount[0],
        params.totalCampaignEntryCount[1],
        this.$gettext('累計参加数')
      )

      if (filterConditiontotalCampaignEntryCount) {
        filterCondition.push([filterConditiontotalCampaignEntryCount])
      }

      // 累計当選数フィルター条件がある場合、フィルター条件に追加する
      const filterConditiontotalCampaignWinningCount = this.createFiterConditionText(
        params.totalCampaignWinningCount[0],
        params.totalCampaignWinningCount[1],
        this.$gettext('累計当選数')
      )

      if (filterConditiontotalCampaignWinningCount) {
        filterCondition.push([filterConditiontotalCampaignWinningCount])
      }

      // アカウント名検索でフィルター条件がある場合は、フィルター条件に追加する
      if (params.accountName) {
        filterCondition.push([this.$gettext('アカウント名') + '[' + params.accountName + ']'])
      }

      // 警告アカウントでフィルター条件がある場合は、フィルター条件に追加する
      switch (params.isWarning) {
        case 1:
          filterConditionIsWarning = this.$gettext('警告フラグあり')
          break
        case 0:
          filterConditionIsWarning = this.$gettext('警告フラグなし')
          break
      }

      if (filterConditionIsWarning) {
        filterCondition.push([filterConditionIsWarning])
      }

      // アカウント開設日でフィルター条件がある場合は、フィルター条件に追加する
      if (params.isJoinDatetime === 1) {
        filterConditionIsJoinDatetime = this.$gettext('キャンペーン開始より前')
      }

      if (filterConditionIsJoinDatetime) {
        filterCondition.push([filterConditionIsJoinDatetime])
      }

      switch (params.isProfileImage) {
        case 1:
          filterConditionIsProfileImage = this.$gettext('設定あり')
          break
        case 0:
          filterConditionIsProfileImage = this.$gettext('設定なし')
          break
      }

      if (filterConditionIsProfileImage) {
        filterCondition.push([filterConditionIsProfileImage])
      }

      switch (params.isDescription) {
        case 1:
          filterConditionIsDescription = this.$gettext('入力あり')
          break
        case 0:
          filterConditionIsDescription = this.$gettext('入力なし')
          break
      }

      if (filterConditionIsDescription) {
        filterCondition.push([filterConditionIsDescription])
      }

      const csvData = []

      // フィルター条件がある場合は、一番最初の行にフィルター条件を追加する
      if (filterCondition.length > 0) {
        csvData.push([this.$gettext('フィルター条件:'), filterCondition.join('/')])
      }

      csvData.push([
        this.$gettext('No'),
        this.$gettext('アカウントID'),
        this.$gettext('アカウント名'),
        this.$gettext('アカウントスクリーン名'),
        this.$gettext('アカウントURL'),
        this.$gettext('アカウント画像'),
        this.$gettext('アカウント説明文'),
        this.$gettext('アカウント開設日'),
        this.$gettext('応募日時'),
        this.$gettext('ポスト種別'),
        this.$gettext('ポスト内容'),
        this.$gettext('ポスト画像URL'),
        this.$gettext('ポストURL'),
        this.$gettext('フォロワー'),
        this.$gettext('フォロー'),
        this.$gettext('FF比率'),
        this.$gettext('ポスト'),
        this.$gettext('応募数'),
        this.$gettext('累計参加数'),
        this.$gettext('累計当選数'),
        this.$gettext('フォロー中'),
        this.$gettext('ポジティブアカウント'),
        this.$gettext('ネガティブアカウント'),
        this.$gettext('NGアカウント'),
        this.$gettext('候補者'),
        this.$gettext('当選者'),
        this.$gettext('web当選者'),
        this.$gettext('回答者'),
        this.$gettext('メッセージ件数')
      ])

      // CSVデータを格納
      applicants.forEach((applicant, index) => {
        let postType = ''
        switch (applicant.post.postType) {
          case 'tweet':
            postType = this.$gettext('ポスト')
            break
          case 'retweet':
            postType = this.$gettext('公式リポスト')
            break
          case 'quote_tweet':
            postType = this.$gettext('引用')
            break
          case 'mention':
            postType = this.$gettext('メンション')
            break
          case 'reply':
            postType = this.$gettext('返信')
            break
        }
        csvData.push([
          index + 1,
          applicant.accountId,
          applicant.name,
          applicant.screenName,
          'https://x.com/' + applicant.screenName,
          applicant.pictureUrl,
          applicant.description ? applicant.description.replace(/(\r\n|\n|\r)/gm, '') : '',
          this.$options.filters.datetime(applicant.joinDatetime),
          this.$options.filters.datetime(applicant.post.publishDatetime),
          postType,
          applicant.post.message ? applicant.post.message.replace(/(\r\n|\n|\r)/gm, '') : '',
          applicant.post.mediaUrl,
          'https://x.com/' + applicant.accountId + '/status/' + applicant.post.id,
          applicant.followersCount,
          applicant.followsCount,
          this.$options.filters.number(applicant.followsRate),
          applicant.tweetsCount,
          applicant.postsCount,
          applicant.totalCampaignEntryCount,
          applicant.totalCampaignWinningCount,
          applicant.isFollowing,
          applicant.isPositive,
          applicant.isWarning,
          applicant.isNg,
          applicant.isCandidate,
          applicant.isWinner,
          applicant.isWebInstantwinWinner,
          applicant.isResponder,
          applicant.messagesCount
        ])
      })

      await this.fetchCampaign()

      return {
        filename,
        data: csvData
      }
    },

    /**
     * CSV用応募者一覧を取得
     */
    async getApplicantsForCsv() {
      this.loading = true
      const { total } = this.options

      const appliciantLimit = 50000
      const csvInfoTotal = Math.min(total, appliciantLimit)

      this.getTextLoading(0, csvInfoTotal)

      const params = Object.assign({}, this.params, {
        pagingNo: 1,
        limitCount: 1000,
        forCsv: 1
      })

      let totalResults = []
      // 1000件ごとに取得する
      for (let count = 0; count < csvInfoTotal; count += 1000) {
        const result = await api.get('/twitter_applicants', { params }) // リクエストパラメータを渡す

        const surplus = (csvInfoTotal - count) / 1000

        if (surplus < 1) {
          this.getTextLoading(csvInfoTotal, csvInfoTotal)
        } else {
          this.getTextLoading(count + 1000, csvInfoTotal)
        }

        if (!result.data) {
          this.loading = false
          return []
        }

        totalResults = totalResults.concat(result.data.applicants)

        params.pagingNo = params.pagingNo + 1
      }

      this.loading = false
      return totalResults
    },

    /**
     * CSVダウンロードの時、進捗を表示する
     * @params {number} acquiredNumber
     * @params {number} totalNumber
     */
    getTextLoading(acquiredNumber: number, totalNumber: number) {
      this.loadingText = this.$gettextInterpolate(
        this.$gettext('CSVファイルの作成中です。しばらくお待ちください。\n %{acquiredNumber}件 / %{totalNumber}件'),
        {
          acquiredNumber,
          totalNumber
        }
      )
    }
  },
  beforeRouteLeave(to: any, from: any, next: any) {
    // 応募者ストアをリセット
    this.reset()
    next()
  }
}
