import { render, staticRenderFns } from "./post_detail.vue?vue&type=template&id=6baee453&scoped=true"
import script from "./post_detail.component.js?vue&type=script&lang=js&external"
export * from "./post_detail.component.js?vue&type=script&lang=js&external"
import style0 from "./post_detail.scss?vue&type=style&index=0&id=6baee453&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6baee453",
  null
  
)

export default component.exports