import { render, staticRenderFns } from "./applicant_table.vue?vue&type=template&id=0ba5f63a&scoped=true"
import script from "./applicant_table.component.js?vue&type=script&lang=js&external"
export * from "./applicant_table.component.js?vue&type=script&lang=js&external"
import style0 from "./applicant_table.scss?vue&type=style&index=0&id=0ba5f63a&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0ba5f63a",
  null
  
)

export default component.exports