/* @flow */
import { Tweet } from 'vue-tweet-embed'

export default {
  props: {
    id: String
  },
  components: {
    Tweet
  }
}
