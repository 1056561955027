import ApplicantDetail from '@/client/components/basic/applicant_detail'
import ApplicantName from '@/client/components/basic/applicant_name'
import Icon from '@/client/components/basic/icon'

export default {
  components: {
    Icon,
    ApplicantName,
    ApplicantDetail
  },
  props: {
    snsType: 'instagram' | 'twitter' | 'tiktok',
    applicant: Object,
    campaignType: String
  },
  data() {
    return {
      isShowDetail: false,
      isShowPopover: false
    }
  },
  computed: {
    imageStyle() {
      return {
        backgroundImage: `url(${this.applicant.post.mediaUrl || require('@/client/assets/images/no-photo.png')})`
      }
    }
  },
  methods: {
    /**
     * Set show detail
     * @param {boolean} state
     */
    toggleDetail(state: boolean) {
      if (this.isShowPopover) {
        this.isShowDetail = this.isShowPopover
        return
      }

      this.isShowDetail = state
    },

    /**
     * When popover is close
     */
    hidePopover() {
      this.isShowDetail = false
    },

    /**
     * ポストテキストをフォーマット
     * @param {string} text
     * @return {string} フォーマットしたテキスト
     */
    tweetText(text: string): string {
      return this.$options.filters.tweetText(text)
    },

    /**
     * フラグ変更処理
     * @param {{type: string, value: any}} payload
     */
    changeFlag(payload: { type: string, value: any }) {
      this.$emit('change_flag', payload)
    },

    /**
     * メッセージ配信履歴を開く
     * @param {{applicantId: number}} applicantId
     */
    openMessageHistory(payload: { applicantId: number }) {
      this.$emit('open_message_history', payload)
    }
  }
}
