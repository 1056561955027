/* @flow */
import { INSTANTWIN_MESSAGE_STATUS } from '@/client/constant'

export default {
  props: ['status'],
  data() {
    return {
      INSTANTWIN_MESSAGE_STATUS
    }
  }
}
