/* @flow */
export default {
  data() {
    return {
      visible: false,
      title: '',
      content: '',
      type: 'default',
      confirm: () => {}
    }
  },
  methods: {
    confirmClick(confirm: boolean) {
      this.visible = false
      this.confirm(confirm)
    },
    /**
     *
     * @param {string} title タイトル
     * @param {string} content 内容
     * @param {string} type タイプ default | with-title
     * @param {Function} callback コールバック
     */
    open(title: string, content: string, type: string, callback: Function) {
      this.title = title
      this.content = content
      this.type = type
      if (!this.type) {
        this.type = 'default'
      }
      this.confirm = callback
      this.visible = true
    },
    close() {
      this.visible = false
    }
  }
}
