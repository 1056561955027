/* @flow */
import Highlight from '@/client/components/basic/highlight'

export default {
  components: {
    Highlight
  },
  props: {
    value: String,
    highlights: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false
    },
    success: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      internalValue: '',
      htmlOutput: ''
    }
  },
  mounted() {
    this.internalValue = this.value
    this.htmlOutput = this.internalValue
    this.$refs.input.innerHTML = this.htmlOutput
  },
  watch: {
    value() {
      this.value = this.removeLastNewLine(this.value)
      if (this.internalValue !== this.value) {
        this.internalValue = this.value
        this.htmlOutput = this.internalValue
        this.$refs.input.innerHTML = this.htmlOutput
      }
    }
  },
  methods: {
    /**
     * 入力イベント
     * @param {*} event
     */
    sync(event: any) {
      if (this.internalValue === this.$refs.input.innerText) {
        return
      }
      this.htmlOutput = this.$refs.input.innerHTML
      this.internalValue = this.removeLastNewLine(this.$refs.input.innerText)

      this.$emit('input', this.internalValue)
    },

    /**
     * ドロップイベント
     */
    onDrop(event: any) {
      // ドロップをdisabledにする
      event.preventDefault()
      event.stopPropagation()
    },

    /**
     * 最後改行を削除する
     * @param {string} text テキスト
     * @returns {string} テキスト
     */
    removeLastNewLine(text: string): string {
      return text.replace(/\\n+$/, '')
    }
  }
}
