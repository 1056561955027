/* @flow */
import ApplicantName from '@/client/components/basic/applicant_name'
import Pagination from '@/client/components/basic/pagination'
import EntryHistoryDialog from '@/client/components/twitter_instantwin_message_history/entry_history_dialog'
import { INSTANTWIN_LOSE_REASON } from '@/client/constant'
import Util from '@/client/core/util'

export default {
  props: {
    data: Array,
    options: Object,
    paginations: Object,
    onChangePagingNo: Function,
    onChangeLimitCount: Function,
    onChangeSortBy: Function
  },
  components: {
    ApplicantName,
    Pagination,
    EntryHistoryDialog
  },
  methods: {
    /**
     * ヘッダーをクリックするとソートする
     * @param {*} column
     */
    headerClick(column: any) {
      if (!column.sortBy) {
        return
      }
      if (column.sortBy.indexOf('-') === 0) {
        if (this.options.sortBy === column.sortBy) {
          this.onChangeSortBy(column.sortBy.substring(1))
        } else {
          this.onChangeSortBy(column.sortBy)
        }
      } else {
        if (this.options.sortBy === column.sortBy) {
          this.onChangeSortBy('-' + column.sortBy)
        } else {
          this.onChangeSortBy(column.sortBy)
        }
      }
    },

    /**
     * ヘッダー描画
     * @param {*} createElement
     * @param {*} { column }
     */
    renderHeader(createElement: any, { column }: any) {
      return Util.renderHeader(
        createElement,
        column.label,
        Util.getSortClass(this.options.sortBy, column.sortBy),
        !!column.sortBy
      )
    },

    /**
     * 落選となった理由をツールチップで表示
     * @param {string | null} value 値
     */
    loseReasonTooltip(value: string | null) {
      const key = value === null ? 'other' : value
      return INSTANTWIN_LOSE_REASON[key]
    },

    /**
     * フラグ変更処理
     * @param {number} applicantId
     * @param {number} value
     * @param {string} type
     */
    updateFlag(applicantId: number, value: number, type: string) {
      const payload = {
        type,
        value: {}
      }

      switch (type) {
        case 'responder':
          payload.value = {
            applicantId,
            isResponder: value === 0 ? 1 : 0
          }
          break
      }
      this.$emit('change_flag', payload)
    },

    /**
     * メッセージ配信履歴を開く
     * @param {number} applicantId
     */
    openMessageHistory(applicantId: number) {
      this.$emit('open_message_history', {
        applicantId
      })
    }
  }
}
