











import { Component, Prop, Vue } from 'vue-property-decorator'

import Icon from '@/client/components/_atoms/Icon.vue'

type TKeywordType = 'positive' | 'warning'

@Component({ components: { Icon } })
export default class Keyword extends Vue {
  @Prop({ type: String, required: true })
  type!: TKeywordType

  @Prop({ type: String, required: true })
  text!: string

  get icon_name() {
    return this.type === 'positive' ? 'heart' : 'alert'
  }
}
