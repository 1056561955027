import api from '@/client/core/api'

export default class TwitterUpload {
  /**
   * メディアをチェック
   * @param {*} file
   * @param {string[]} validFormat
   * @returns {boolean}
   */
  static checkValidMedia(file, validFormat): boolean {
    if (!validFormat.includes(file.type)) {
      return {
        error: 'INVALID_MEDIA'
      }
    }

    if (file.type.includes('image')) {
      return this.checkValidImage(file)
    }

    return this.checkValidVideo(file)
  }

  /**
   * 動画をチェック
   * @param {any} file
   * @returns {boolean}
   */
  static checkValidVideo(file): boolean {
    const maxSize = 25 * 1024 * 1024
    if (file.size > maxSize) {
      return {
        error: 'MAX_SIZE_VIDEO'
      }
    }

    return {
      data: true
    }
  }

  /**
   * 画像の容量とサイズのチェック
   * @param {any} file
   * @returns {Promise<boolean>}
   */
  static async checkValidImage(file): Promise<boolean> {
    const maxSize = 5 * 1024 * 1024
    if (file.size > maxSize) {
      return {
        error: 'MAX_SIZE_IMAGE'
      }
    }

    const dimensions = await this.getImageDimension(file)
    let isError = dimensions.width < 5 || dimensions.width > 5000 || dimensions.height < 5 || dimensions.height > 5000

    if (!isError && file.type.match('gif')) {
      isError = dimensions.width > 2048 || dimensions.height > 2048
    }

    if (isError) {
      return {
        error: 'INVALID_DIMENSION'
      }
    }

    return {
      data: true
    }
  }

  /**
   * 画像サイズを取得
   * @param {any} file
   * @returns {Promise<{width: number, height: number}>}
   */
  static getImageDimension(file): Promise<{ width: number, height: number }> {
    const img = new Image()
    return this.readTemporaryFileReader(file).then(result => {
      img.src = result
      return new Promise(resolve => {
        img.onload = () => {
          resolve({ width: img.width, height: img.height })
        }
      })
    })
  }

  /**
   * ファイルの取得
   * @param {any} file
   * @returns {Promise<string | ArrayBuffer>}
   */
  static readTemporaryFileReader(file): Promise<string | ArrayBuffer> {
    const fr = new FileReader()
    return new Promise(resolve => {
      fr.readAsDataURL(file)
      fr.onload = () => {
        resolve(fr.result)
      }
    })
  }

  /**
   * メディアの検証
   * @param {*} formData
   * @returns {Promise<boolean>} result
   */
  static async checkMedia(formData): Promise<boolean> {
    const checkMedia = await api.post('/twitter_uploads/media_check', formData, {
      headers: {
        'Content-Type': undefined
      }
    })

    if (!checkMedia.data) {
      return false
    }

    return true
  }

  /**
   * メディアの検証
   * @param {File} file
   * @returns {Promise<boolean>} 結果
   */
  static async validateFile(file: File, adsAccountId: string) {
    const formData = new FormData()
    formData.append('files', file)

    const data = await api.post('/twitter_medias/validations', formData)

    if (!data || !data.data || !data.data.filename) {
      return { error: 'UPLOAD_FAILED' }
    }

    const delay = miliseconds => {
      return new Promise(resolve => {
        setTimeout(() => {
          resolve(true)
        }, miliseconds)
      })
    }

    while (true) {
      const result = await api.get(`/twitter_medias/validations_progresses?filename=${data.data.filename}`)

      if (result && result.data && result.data.mediaUrl) {
        return { data: result.data.mediaUrl }
      }

      if (result && result.error) {
        return { error: 'VALIDATION_FAILED' }
      }

      if (!result || !result.data) {
        return { error: 'CHECK_PROGRESSES_FAILED' }
      }

      await delay(3000)
    }
  }

  /**
   * メディアのアップロード
   * @param {*} formData
   * @returns {Promise<any>} result
   */
  static uploadMedia(formData): Promise<boolean> {
    return api.post('/twitter_uploads', formData, {
      headers: {
        'Content-Type': undefined
      }
    })
  }

  /**
   * メディアのアップロード
   * @param {any} params
   * @returns {Promise<any>} result
   */
  static uploadMediaFromUrl(params: any): Promise<boolean> {
    return api.post('/twitter_uploads', params)
  }
}
