















import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

import Icon from '@/client/components/_atoms/Icon.vue'

@Component({ components: { Icon } })
export default class Account extends Vue {
  @Prop({ type: String, required: true })
  name!: string

  @Prop({ type: Boolean, default: true })
  isDisplayName!: boolean

  @Prop({ type: String | null, required: true })
  image!: string | null

  @Prop({ type: String, default: '' })
  symbol!: 'twitter' | 'instagram' | 'tiktok' | ''

  @Prop({ type: Boolean, default: false })
  tooltip!: boolean

  @Prop({ type: Boolean, default: false })
  isHideSymbol!: boolean

  is_error = false

  @Watch('image', { immediate: true })
  watchImage() {
    this.checkImage()
  }

  get symbol_class() {
    return ['account-symbol', `symbol-${this.symbol}`]
  }

  get style_image() {
    if (this.is_error) return {}

    return { backgroundImage: `url(${this.image})` }
  }

  get image_class() {
    return {
      'is-twitter': this.symbol === 'X',
      'is-instagram': this.symbol === 'instagram',
      'is-tiktok': this.symbol === 'tiktok'
    }
  }

  /**
   * 画像の読み込み
   */
  checkImage() {
    this.is_error = false

    const img = new Image()

    img.onerror = () => {
      this.is_error = true
    }

    img.src = this.image
  }
}
