/* @flow */
import moment from 'moment-timezone'
import { mapState } from 'vuex'

import AnalyticsChartDoughnut from '@/client/components/basic/analytics_chart_doughnut'
import AnalyticsChartVertical from '@/client/components/basic/analytics_chart_vertical'
import AnalyticsTotal from '@/client/components/basic/analytics_total'
import CsvDownload from '@/client/components/basic/csv_download'
import Icon from '@/client/components/basic/icon'
import { CAMPAIGN_STATUS } from '@/client/constant'
import api from '@/client/core/api'
import Util from '@/client/core/util'
import { TrackingService } from '@/client/services'

export default {
  components: {
    Icon,
    AnalyticsChartVertical,
    AnalyticsChartDoughnut,
    CsvDownload,
    AnalyticsTotal
  },
  data() {
    return {
      activeTab: 'campaigns',
      visible: false,
      loading: true,
      campaignId: null,
      chartData: [],
      labels: [],
      interval: 'hour',
      value1: 'follower',
      value2: 'applicant',
      values: [
        { label: this.$gettext('指定なし'), value: '' },
        { label: this.$gettext('応募数'), value: 'entry' },
        { label: this.$gettext('応募者数'), value: 'applicant' },
        { label: this.$gettext('新規応募者数'), value: 'newApplicant' },
        { label: this.$gettext('フォロワー'), value: 'follower' },
        { label: this.$gettext('当選数'), value: 'winner' }
      ],
      data: [],
      trafficSourceData: [],
      entryCount: 0,
      applicantCount: 0,
      newApplicantCount: 0,
      reachCount: 0,
      followerUpDown: 0,
      winnerCount: 0,
      impressionTotalCount: 0,
      watchedFullVideoCount: 0,
      prizeOptions: [{ name: '全ての応募グループ', id: null }],
      prizeId: null,
      dateTimeRange: [],
      defaultDateTimeRange: [],
      datePickerOption: {
        disabledDate(time: any) {
          return false
        }
      },
      hash: '',
      isBeginAtZero: false,
      chartStyles: {
        margin: '0 auto'
      },
      isPublicAnalyticsPage: 0,
      isPublicAnalyticsApi: 0
    }
  },
  computed: mapState('campaign', {
    campaign(state) {
      const campaign = state.campaigns.find(value => value.id === this.campaignId)

      return {
        name: campaign ? campaign.title : null,
        status: campaign ? campaign.status : null,
        pictureUrl: campaign ? campaign.account.pictureUrl : null,
        iconType: 'tiktok',
        endDatetime: campaign ? campaign.endDatetime : null,
        startDatetime: campaign ? campaign.startDatetime : null
      }
    },

    isCampaignFinalCollection() {
      return this.campaign.status === CAMPAIGN_STATUS.DURING_FINAL_COLLECTION
    },

    isCampaignCollectionCompleted() {
      return this.campaign.status === CAMPAIGN_STATUS.COLLECTION_COMPLETED
    },

    campaignStatusText() {
      if (this.campaign.status === CAMPAIGN_STATUS.DURING_FINAL_COLLECTION) {
        return '最終データ取得待ち'
      }

      if (this.campaign.status === CAMPAIGN_STATUS.COLLECTION_COMPLETED) {
        return '集計完了'
      }

      return '開催中、24~48時間ごとに更新されます。'
    },

    collectCompletedAt() {
      // TikTokの分析Batchがキャンペーン終了後2日間まで実行されるため、集計中の状態を2日間継続する
      return moment(this.campaign.endDatetime)
        .add(2, 'days')
        .format('YYYY年MM月DD日 HH:mm')
    },

    intervals() {
      return [
        { text: this.$gettext('1時間'), value: 'hour' },
        { text: this.$gettext('1日'), value: '1_day' },
        { text: this.$gettext('7日'), value: '7_day' },
        { text: this.$gettext('1ヶ月'), value: '1_month' }
      ]
    },

    time_range() {
      const timeRange = []

      if (!this.data.length) {
        return timeRange
      }

      const addNumber = Number(this.interval.match(/\d+/g))

      let timeEnd
      let timeStart = moment(moment(this.data[0].datetime).format('YYYY-MM-DD 00:00:00')).unix()
      const endDateUnix = moment(moment(this.data[this.data.length - 1].datetime).format('YYYY-MM-DD HH:mm:00')).unix()

      while (timeStart <= endDateUnix) {
        if (
          this.interval === '1_day' ||
          this.interval === '7_day' ||
          this.interval === '14_day' ||
          this.interval === '28_day'
        ) {
          // 日数のunix値: 日数 x 一日のunix値 (一日のunix値: 86400)
          timeEnd = timeStart + addNumber * 86400 - 1
        } else if (this.interval === '1_month') {
          timeEnd =
            moment
              .unix(timeStart)
              .add(addNumber, 'months')
              .startOf('month')
              .unix() - 1
        }

        if (timeEnd > endDateUnix) {
          timeEnd = endDateUnix
        }

        timeRange.push({
          start: timeStart,
          end: timeEnd
        })

        timeStart = timeEnd + 1
      }

      if (timeRange.length) {
        timeRange[0].start = moment(this.data[0].datetime).unix()
      } else {
        timeRange.push({ start: endDateUnix, end: endDateUnix })
      }

      return timeRange
    },

    time_range_for_label() {
      const timeRange = []

      if (!this.data.length) {
        return timeRange
      }

      const addNumber = Number(this.interval.match(/\d+/g))

      let timeEnd
      let timeStart = moment(moment(this.data[0].datetime).format('YYYY-MM-DD 00:00:00')).unix()
      const endDateUnix = moment(moment(this.data[this.data.length - 1].datetime).format('YYYY-MM-DD HH:mm:00')).unix()

      while (timeStart <= endDateUnix) {
        if (
          this.interval === '1_day' ||
          this.interval === '7_day' ||
          this.interval === '14_day' ||
          this.interval === '28_day'
        ) {
          // 日数のunix値: 日数 x 一日のunix値 (一日のunix値: 86400)
          timeEnd = timeStart + addNumber * 86400 - 1
        } else if (this.interval === '1_month') {
          timeEnd =
            moment
              .unix(timeStart)
              .add(addNumber, 'months')
              .startOf('month')
              .unix() - 1
        }

        if (timeEnd > endDateUnix) {
          timeEnd = endDateUnix
        }

        timeRange.push({
          start: timeStart,
          end: timeEnd
        })

        timeStart = timeEnd + 1
      }

      if (timeRange.length) {
        timeRange[0].start = moment(this.data[0].datetime).unix()
      } else {
        timeRange.push({ start: endDateUnix, end: endDateUnix })
      }

      return timeRange
    },

    analytics() {
      const data = {
        applicant: [],
        follower: [],
        entry: [],
        newApplicant: [],
        winner: []
      }

      if (!this.data.length) {
        return data
      }

      if (this.interval === 'hour') {
        const lastIndex = this.data.length - 1

        this.data.forEach((value, index) => {
          data.follower.push(value.followerCount)

          if (index !== lastIndex) {
            data.applicant.push(value.applicantCount)
            data.entry.push(value.entryCount)
            data.newApplicant.push(value.newApplicantCount)
            data.winner.push(value.winnerCount)
          }
        })
        return data
      }

      const endDateUnix = moment(this.data[this.data.length - 1].datetime)
        .startOf('minute')
        .unix()

      this.time_range.forEach((time, index) => {
        // 該当する期間の分析データを取得
        let analytics = this.data
          .filter(analytic => {
            return moment(analytic.datetime).unix() >= time.start && moment(analytic.datetime).unix() <= time.end
          })
          .map(analytic => analytic)

        if (this.interval === '1_day') {
          const momentDate = index < this.time_range.length - 1 ? moment.unix(time.start) : moment.unix(time.end)
          const datetime = momentDate.startOf('hour').format('YYYY-MM-DD HH:mm')

          data.follower.push(this.getValueByHour(analytics, datetime, 'followerCount'))
        } else {
          data.follower.push(this.getValueByPeriod(analytics, 'followerCount'))
        }

        if (time.end === endDateUnix) {
          analytics = analytics.filter(v => moment(v.datetime).unix() !== endDateUnix)
        }

        data.applicant.push(this.getTotal(analytics, 'applicantCount'))
        data.entry.push(this.getTotal(analytics, 'entryCount'))
        data.newApplicant.push(this.getTotal(analytics, 'newApplicantCount'))
        data.winner.push(this.getTotal(analytics, 'winnerCount'))
      })

      return data
    },

    isEmptyTrafficSource() {
      return this.trafficSourceData.length > 0 && this.trafficSourceData.every(v => v.count === 0)
    },

    publicPageUrl() {
      return `https://${window.location.host}/publics/tiktok_campaign_analytics?hash=${this.hash}`
    },

    publicApiUrl() {
      if (this.prizeId) {
        return `https://${window.location.host}/api/publics/applicant_count?hash=${this.hash}&prizeId=${this.prizeId}`
      }

      return `https://${window.location.host}/api/publics/applicant_count?hash=${this.hash}`
    }
  }),
  methods: {
    /**
     * ダイアログを開く
     * @param {number} campaignId キャンペーンID
     */
    async open(campaignId: number) {
      this.activeTab = 'campaigns'
      this.visible = true
      this.loading = true
      this.campaignId = campaignId
      this.value1 = 'follower'
      this.value2 = 'applicant'
      this.interval = '1_day'
      this.labels = []
      this.chartData = []
      this.trafficSourceData = []
      this.prizeId = null
      this.prizeOptions = [{ name: '全ての応募グループ', id: null }]
      try {
        await this.getCampaignAnalyticsId(this.campaignId)
        await this.getPrizes()
        await this.getCampaign()
        this.changeChart('')
        this.loading = false
      } catch (e) {
        this.loading = false
        this.alert()
        this.close()
      }
    },

    /**
     * キャンペーンデータを取得
     */
    async getCampaign() {
      if (!this.campaignId) {
        return
      }

      const result = await api.get('/campaigns/' + this.campaignId)

      if (!result || !result.data) {
        return
      }

      const campaign: any = result.data
      this.dateTimeRange = [
        campaign.startDatetime,
        moment(campaign.endDatetime)
          .subtract(1, 'hour')
          .startOf('day')
      ]
      this.defaultDateTimeRange = [
        campaign.startDatetime,
        moment(campaign.endDatetime)
          .subtract(1, 'hour')
          .startOf('day')
      ]

      this.hash = campaign.hash
      this.isPublicAnalyticsPage = campaign.isPublicAnalyticsPage
      this.isPublicAnalyticsApi = campaign.isPublicAnalyticsApi
      this.datePickerOption = {
        disabledDate(time: any) {
          const start = moment(campaign.startDatetime).startOf('day')
          const end = moment(campaign.endDatetime)
            .subtract(1, 'hour')
            .startOf('day')
          return moment(time).isBefore(start) || moment(time).isAfter(end)
        }
      }
    },

    /**
     * 日時を変更する
     */
    async changeDatetimeRange(value: any) {
      TrackingService.sendEvent('select:キャンペーン分析（IG）|カレンダー')

      if (!value) {
        this.dateTimeRange = this.defaultDateTimeRange
      } else {
        this.dateTimeRange = value
      }
      this.loading = true
      await this.getCampaignAnalyticsId(this.campaignId)
      this.changeChart()
      this.loading = false

      if (!this.dateTimeRange) {
        this.dateTimeRange = this.defaultDateTimeRange
      }
    },

    async getAnalyticByPrize() {
      TrackingService.sendEvent('select:キャンペーン分析（IG）|全ての応募グループ')

      this.loading = true
      await this.getCampaignAnalyticsId(this.campaignId)
      this.changeChart('')
      this.loading = false
    },

    /**
     * ダイアログを閉じる
     */
    close() {
      this.visible = false
      this.loading = true
      this.campaignId = null
      this.dateTimeRange = []
      this.defaultDateTimeRange = []
    },

    /**
     * ダイアログを閉じる
     */
    beforeClose() {
      TrackingService.sendEvent('click:キャンペーン分析（IG）|閉じる')

      this.visible = false
      this.dateTimeRange = []
      this.defaultDateTimeRange = []
    },
    /**
     * ソートする値の変更イベント
     */
    changeChart(selectedValue: string) {
      if (selectedValue === 'value1') {
        TrackingService.sendEvent('select:キャンペーン分析（TikTok）|値1')
      } else if (selectedValue === 'value2') TrackingService.sendEvent('select:キャンペーン分析（TikTok）|値2')

      this.chartData = this.getChartData()
      this.labels = this.getLabels()
    },

    changeInterval(interval: string) {
      if (interval === 'hour') {
        TrackingService.sendEvent('click:キャンペーン分析（IG）|1時間')
      } else if (interval === '1_day') {
        TrackingService.sendEvent('click:キャンペーン分析（IG）|1日')
      } else if (interval === '7_day') {
        TrackingService.sendEvent('click:キャンペーン分析（IG）|7日')
      } else TrackingService.sendEvent('click:キャンペーン分析（IG）|キャンペーン|1ヶ月')

      this.interval = interval
      this.changeChart('')
    },

    getLabels() {
      let labels = []

      switch (this.interval) {
        case 'hour':
          labels = []
          this.data.forEach((value, index) => {
            const date = moment(value.datetime)
              .startOf('hour')
              .format('YYYY/MM/DD HH:mm')
            labels.push(date)
          })
          return labels
        case '1_day':
          return this.time_range_for_label.map((time, index) => {
            if (index === this.time_range_for_label.length - 1) {
              return moment
                .unix(time.end)
                .startOf('hour')
                .format('YYYY/MM/DD')
            }

            return moment
              .unix(time.start)
              .startOf('hour')
              .format('YYYY/MM/DD')
          })
        case '7_day':
        case '14_day':
        case '28_day':
          return this.time_range_for_label.map(time => {
            return `${moment.unix(time.start).format('MM/DD')}-${moment.unix(time.end).format('MM/DD')}`
          })
        case '1_month':
          return this.time_range_for_label.map(time => {
            return moment.unix(time.start).format('YYYY/MM')
          })
      }
    },

    getChartData() {
      const data = []

      if (this.value1) {
        const label = this.values.find(value => value.value === this.value1)
        data.push({
          metric: this.value1,
          label: label.label,
          data: this.analytics[this.value1]
        })
      }

      if (this.value2) {
        const label = this.values.find(value => value.value === this.value2)
        data.push({
          metric: this.value2,
          label: label.label,
          data: this.analytics[this.value2]
        })
      }

      return data
    },

    /**
     * CSVファイルをダウンロード
     */
    downloadCsv() {
      TrackingService.sendEvent('click:キャンペーン分析（IG）|CSVダウンロード')

      const filename = `${this.campaign.name}_分析`

      const data = []

      data.push([
        this.$gettext('日付'),
        this.$gettext('応募数'),
        this.$gettext('応募者数'),
        this.$gettext('新規応募者数'),
        this.$gettext('当選数'),
        this.$gettext('フォロワー')
      ])

      this.labels.forEach((label, index) => {
        if (this.interval === '1_day') {
          label = moment(label)
            .startOf('hour')
            .format('YYYY/MM/DD')
        }

        const applicant = isNaN(this.analytics.applicant[index]) ? null : this.analytics.applicant[index]
        const follower = isNaN(this.analytics.follower[index]) ? null : this.analytics.follower[index]
        const entry = isNaN(this.analytics.entry[index]) ? null : this.analytics.entry[index]
        const newApplicant = isNaN(this.analytics.newApplicant[index]) ? null : this.analytics.newApplicant[index]
        const winner = isNaN(this.analytics.winner[index]) ? null : this.analytics.winner[index]

        data.push([label, entry, applicant, newApplicant, winner, follower])
      })

      return { filename, data }
    },

    /**
     * トラフィックソースのCSVファイルをダウンロード
     */
    downloadCsvTrafficSources() {
      TrackingService.sendEvent('click:キャンペーン分析（TiKTok）|トラフィックソース|CSVダウンロード')

      const filename = `${this.campaign.name}_トラフィックソース分析`
      const data = []
      const header = [this.$gettext('ラベル'), this.$gettext('値'), this.$gettext('割合')]

      data.push(header)
      const totalData = this.trafficSourceData.map(v => v.count).reduce((count, current) => count + current, 0)

      this.trafficSourceData.forEach(v => {
        const percentage = !totalData ? 0 : Math.round((v.count / totalData) * Math.pow(100, 2)) / 100
        data.push([v.label, v.count, percentage])
      })

      return { filename, data }
    },

    /**
     * キャンペーン分析を取得
     */
    async getCampaignAnalyticsId(campaignId: number) {
      let params: any = {}

      if (this.prizeId) {
        params = {
          prizeId: this.prizeId
        }
      }

      if (this.dateTimeRange.length) {
        params.startDatetime = moment(this.dateTimeRange[0]).format('YYYY-MM-DD')
        params.endDatetime = moment(this.dateTimeRange[1]).format('YYYY-MM-DD')
      }
      const response = await api.get(`/tiktok_campaign_analytics/${campaignId}`, { params })

      if (!response.data) {
        this.alert()
        this.close()
        return
      }

      this.data = response.data.analytics
      this.trafficSourceData = response.data.impressions

      const firstFollowerCount = this.getFirstValueExceptNull(this.data, 'followerCount')
      const columnData = this.data.slice(0, this.data.length - 1)

      this.entryCount = this.getTotal(columnData, 'entryCount')
      this.applicantCount = this.getTotal(columnData, 'applicantCount')
      this.newApplicantCount = this.getTotal(columnData, 'newApplicantCount')
      this.winnerCount = this.getTotal(columnData, 'winnerCount')
      this.impressionTotalCount = this.getLastValue(this.data, 'impressionTotalCount')
      this.reachCount = this.getLastValue(this.data, 'reachCount')
      this.watchedFullVideoCount = this.getLastValue(this.data, 'watchedFullVideoCount')
      this.followerUpDown = this.getLastValue(this.data, 'followerCount') - firstFollowerCount
    },

    /**
     * 応募グループ一覧を取得する
     */
    async getPrizes() {
      const params = {
        campaignId: this.campaignId,
        forCsv: 1
      }

      const result = await api.get(`/tiktok_campaign_prizes`, { params })

      if (!result || !result.data || !result.data.prizes) {
        return
      }

      const prizes = result.data.prizes.map(prize => ({ id: prize.id, name: prize.name }))

      this.prizeOptions = [...this.prizeOptions, ...prizes]
    },

    getTotal(data: any, metric: string) {
      return this.addition(this.getMetricArray(data, metric))
    },

    /**
     * 分析データから値を取得
     * @param {any} insight 分析データ（単体）
     * @param {string} metric 指標名
     * @returns {R | null} 値
     */
    getMetricValue(insight: any, metric: string): any | null {
      return isNaN(insight[metric]) ? null : insight[metric]
    },

    /**
     * 分析データから値の配列を取得
     * @param {any[]} insights 分析データ（配列）
     * @param {string} metric 指標名
     * @returns {R[]} 指標データの配列
     */
    getMetricArray(insights: any[], metric: string): any[] {
      return insights.map(insight => this.getMetricValue(insight, metric))
    },

    /**
     * 数値変換
     * @param {any} value 対象
     * @returns {number} 数値
     */
    toNumber(value: any): number {
      return !isNaN(value) ? value : 0
    },

    /**
     * 足し算
     * @param {number[]} numbers 数値配列
     * @returns {number} 合計値
     */
    addition(numbers: number[] = []): number | null {
      const values = numbers.filter(v => v !== null)

      if (!values.length) {
        return null
      }

      return numbers.map(v => this.toNumber(v)).reduce((a, c) => a + c, 0)
    },

    getFirstValueExceptNull(analytics: any[], value: string) {
      const data = analytics.find(analytic => analytic[value] !== null)

      if (!data) {
        return 0
      }

      return data[value]
    },

    getLastValue(analytics: any[], value: string) {
      const data = analytics.filter(analytic => analytic[value] !== null)

      if (data.length === 0) {
        return null
      }

      return data[data.length - 1][value]
    },

    getValueByPeriod(analytics: any[], value: string): number | null {
      const data = analytics.filter(analytic => analytic[value] !== null)

      if (data.length === 0) {
        return null
      }

      // If there is only one period, return last value
      if (analytics.length === this.data.length) {
        return data[data.length - 1][value]
      }

      // If this is the first period, return first value
      if (moment(analytics[0].datetime).isSame(this.data[0].datetime)) {
        return data[0][value]
      }

      return data[data.length - 1][value]
    },

    getValueByHour(analytics: any[], datetime: string, value: string) {
      const data = analytics.find(analytic => moment(analytic.datetime).isSame(datetime, 'hour'))

      if (!data) {
        return null
      }

      return data[value]
    },

    /**
     * キャンペーン分析公開フラグを更新する
     */
    async changePublicAnalyticsFlags(value: string) {
      TrackingService.sendEvent(
        `click:キャンペーン分析（IG）|${value === '分析結果の共有' ? '分析結果の共有' : 'APIとして公開'}`
      )

      const params: any = {
        isPublicAnalyticsPage: this.isPublicAnalyticsPage,
        isPublicAnalyticsApi: this.isPublicAnalyticsApi
      }

      const result = await api.put(`campaigns/${this.campaignId}/publics`, params)

      if (!result.data) {
        this.$notify({
          title: this.$gettext('キャンペーン分析公開フラグの変更に失敗しました。'),
          customClass: 'danger',
          duration: 5000
        })
      }
    },

    /**
     * クリップボードにコピーする
     * @param {string} text
     */
    copyToClipboard(text: string) {
      Util.copyToClipboard(text)
      this.$message({
        message: this.$gettext('クリップボードにURLをコピーしました。'),
        type: 'success'
      })
    },

    /**
     * エラー通知
     */
    alert() {
      this.$notify({
        title: this.$gettext('キャンペーン分析の取得に失敗しました。'),
        message: this.$gettext('恐れ入りますが、時間をおいて再度お試しください。'),
        customClass: 'danger',
        duration: 5000
      })
    },

    changeTab() {
      if (this.activeTab === 'campaigns') {
        TrackingService.sendEvent('click:キャンペーン分析（TikTok）|キャンペーン')

        return
      }

      TrackingService.sendEvent('click:キャンペーン分析（TikTok）|トラフィックソース')
      this.trafficSourceData = this.trafficSourceData.map(v => v)
    },

    changeIsBeginAtZero() {
      TrackingService.sendEvent('switch:キャンペーン分析（IG）|0から表示')
    }
  }
}
