






import { Component, Prop, Vue } from 'vue-property-decorator'

type TMessageType =
  | 'default'
  | 'white'
  | 'subtext'
  | 'disabled'
  | 'info'
  | 'primary'
  | 'link'
  | 'success'
  | 'danger'
  | 'warning'

@Component
export default class Message extends Vue {
  @Prop({ type: String, default: 'default' })
  type!: TMessageType
}
