const scope = ['pages_manage_metadata', 'instagram_basic', 'instagram_manage_comments', 'business_management']

export function api<T = any>(path: string) {
  return new Promise<T>(resolve => {
    FB.api<T>(path, response => resolve(response))
  })
}

export function getLoginStatus() {
  return new Promise<fb.StatusResponse>(resolve => {
    FB.getLoginStatus(response => resolve(response), true)
  })
}

export function login() {
  return new Promise<fb.StatusResponse>(resolve => {
    FB.login(response => resolve(response), { scope: scope.join() })
  })
}
