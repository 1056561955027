/* @flow */
import twitterText from 'twitter-text'
import { required } from 'vuelidate/lib/validators'
import { mapActions, mapState } from 'vuex'

import Editable from '@/client/components/basic/editable'
import MessageConvert from '@/client/components/basic/message_convert'
import api from '@/client/core/api'
import { TrackingService } from '@/client/services'

export default {
  components: {
    MessageConvert,
    Editable
  },
  data() {
    return {
      title: '',
      visible: false,
      loading: false,
      templateId: 0,
      name: '',
      body: '',
      maxBodyLength: 4000,
      isSaving: false,
      highlights: [
        {
          type: 'info',
          keywords: ['_account_name_', '_screen_name_', '_account_id_', '_serial_code_']
        }
      ],
      rawData: {}
    }
  },
  computed: {
    ...mapState('system', {
      currentGroup: state => state.currentGroup
    }),
    /**
     * メッセージ内容の文字数取得
     */
    bodyLength() {
      return twitterText.parseTweet(this.body).weightedLength
    },
    /**
     * Check if is edit
     */
    isEdit() {
      if (this.templateId) {
        return true
      }

      return false
    },
    isFormValid() {
      if (
        this.name &&
        this.name.length <= 256 &&
        this.name.trim() &&
        this.body &&
        this.bodyLength <= this.maxBodyLength &&
        this.body.trim()
      ) {
        return true
      }

      return false
    },
    templateData() {
      return {
        groupId: this.currentGroup.id,
        name: this.name,
        body: this.body
      }
    },
    isFormChanged() {
      return JSON.stringify(this.templateData) !== JSON.stringify(this.rawData)
    }
  },
  methods: {
    ...mapActions('settingGroup', ['fetchTemplates']),
    /**
     * Open dialog, change title
     * @param {number} templateId
     */
    async open(templateId: number) {
      this.visible = true
      if (templateId) {
        this.templateId = templateId
        this.title = this.$gettext('テンプレートの編集')
        await this.fetchData()
      } else {
        this.title = this.$gettext('テンプレートの新規作成')
      }

      this.rawData = this.templateData
    },

    /**
     * Get template by id
     */
    async fetchData() {
      this.loading = true
      const result = await api.get('/message_templates/' + this.templateId)
      this.loading = false

      if (result && result.data) {
        this.name = result.data.name
        this.body = result.data.body
        return
      }

      this.$notify({
        title: this.$gettext('対象のテンプレートはすでに削除されています。'),
        customClass: 'danger',
        duration: 5000
      })
      this.reset()
      await this.fetchTemplates(this.currentGroup.id)
    },

    /**
     * テンプレートの新規作成
     */
    async createMessageTemplate() {
      if (!this.isFormValid) {
        return
      }

      this.isSaving = true
      const params = this.setParams()
      const result = await api.post('/message_templates', params)

      setTimeout(() => {
        this.isSaving = false
      }, 2000)

      if (!result.data) {
        this.$notify({
          title: this.$gettext('テンプレートの保存に失敗しました。'),
          message: this.$gettext('恐れ入りますが、時間をおいて再度お試しください。'),
          customClass: 'danger',
          duration: 5000
        })
        this.reset()
        return
      }

      this.$notify({
        title: this.$gettext('テンプレートを保存しました。'),
        customClass: 'success',
        duration: 5000
      })
      this.reset()
      await this.fetchTemplates(this.currentGroup.id)
    },

    /**
     * テンプレートの編集
     */
    async updateMessageTemplate() {
      TrackingService.sendEvent('click:テンプレート|テンプレートの編集|保存')

      if (!this.isFormValid) {
        return
      }

      this.isSaving = true
      const params = this.setParams()
      delete params.groupId

      const result = await api.put('/message_templates/' + this.templateId, params)

      setTimeout(() => {
        this.isSaving = false
      }, 2000)

      if (result && result.data) {
        this.$notify({
          title: this.$gettext('テンプレートを保存しました。'),
          customClass: 'success',
          duration: 5000
        })
        this.reset()
        await this.fetchTemplates(this.currentGroup.id)
        return
      }

      if (result && result.error && result.error.type === 'NOT_EXISTS') {
        this.$notify({
          title: this.$gettext('対象のテンプレートはすでに削除されています。'),
          customClass: 'danger',
          duration: 5000
        })
        this.reset()
        await this.fetchTemplates(this.currentGroup.id)
        return
      }

      this.$notify({
        title: this.$gettext('テンプレートの保存に失敗しました。'),
        message: this.$gettext('恐れ入りますが、時間をおいて再度お試しください。'),
        customClass: 'danger',
        duration: 5000
      })
      this.reset()
    },

    /**
     * Set params
     * @returns {{groupId: number, name: string, body: string}}
     */
    setParams(): { groupId: number, name: string, body: string } {
      return {
        groupId: this.currentGroup.id,
        name: this.name,
        body: this.body
      }
    },

    /**
     * Delete message template
     *
     */
    async deleteMessageTemplate() {
      TrackingService.sendEvent('click:テンプレート|テンプレートの編集|削除')

      const confirm = window.confirm(this.$gettext('テンプレートを削除してよろしいですか？'))
      if (!confirm) {
        return
      }

      const result = await api.delete('/message_templates/' + this.templateId)
      if (result && result.data) {
        this.$notify({
          title: this.$gettext('テンプレートを削除しました。'),
          customClass: 'success',
          duration: 5000
        })

        this.reset()
        await this.fetchTemplates(this.currentGroup.id)
        return
      }

      if (result && result.error && result.error.type === 'NOT_EXISTS') {
        this.$notify({
          title: this.$gettext('対象のテンプレートはすでに削除されています。'),
          customClass: 'danger',
          duration: 5000
        })
        this.reset()
        await this.fetchTemplates(this.currentGroup.id)
        return
      }

      this.$notify({
        title: this.$gettext('テンプレートの削除に失敗しました。'),
        message: this.$gettext('恐れ入りますが、時間をおいて再度お試しください。'),
        customClass: 'danger',
        duration: 5000
      })
      this.reset()
    },

    /**
     * Reset data before close
     */
    beforeClose() {
      TrackingService.sendEvent('click:テンプレート|テンプレートの編集|閉じる')

      if (!this.isFormChanged) {
        return this.reset()
      }

      const confirm = window.confirm(this.$gettext('編集内容が保存されていません。ダイアログを閉じてよろしいですか?'))

      if (!confirm) {
        return
      }

      this.reset()
    },

    /**
     * Reset data
     */
    reset() {
      this.templateId = 0
      this.name = ''
      this.body = ''
      this.visible = false
    },

    templateChangeName() {
      TrackingService.sendEvent('input:テンプレート|テンプレートの編集|テンプレート名')
    },

    templateChangeBody() {
      TrackingService.sendEvent('input:テンプレート|テンプレートの編集|内容')
    }
  },
  validations: {
    name: {
      required
    },
    body: {
      required
    }
  }
}
