import {
  APIGetOauthTiktokAccountQuery,
  GetOauthTikTokAccountsResponse,
  TikTokCallbackError,
  TikTokCallbackSuccess
} from '@/typings/tiktok'

import { axios } from '../utils/api'

type TikTokCallbackQuery = TikTokCallbackSuccess | TikTokCallbackError

export class TikTokAuthService {
  static async openAuthWindow() {
    const width = 600
    const height = 800
    const top = (screen.height - height) / 4
    const left = (screen.width - width) / 2

    const auth = window.open(
      '/api/oauth/tiktok',
      'oauth_tiktok',
      `width=${width},height=${height},top=${top},left=${left}`
    )

    const callback = await new Promise<TikTokCallbackQuery>(resolve => {
      window.onTikTokAuth = resolve
    })

    if (auth) auth.close()

    return callback
  }

  static async getAccountInfo(callback: TikTokCallbackSuccess, groupId: number) {
    const params: APIGetOauthTiktokAccountQuery = {
      ttAccountId: callback.account_id,
      groupId,
      accessToken: callback.access_token,
      refreshToken: callback.refresh_token
    }

    const accounts = await axios.get<GetOauthTikTokAccountsResponse>('oauth/tiktok/account', {
      params
    })

    return accounts.data
  }
}
