/* @flow */
import { mapActions, mapState } from 'vuex'

import Icon from '@/client/components/basic/icon'
import InstagramNextAuth from '@/client/components/setting_group/instagram_next_auth'
import api from '@/client/core/api'
import { TikTokAuthService } from '@/client/core/tiktok_auth'
import TwitterAuthService from '@/client/core/twitter_auth'
import { TrackingService } from '@/client/services'
import { type TCampaignAccount } from '@/typings/api/campaign_accounts.type'

type TAccount = {
  id?: string,
  token?: string,
  secret?: string,
  enterpriseToken?: string,
  enterpriseSecret?: string,
  name?: string,
  screenName?: string,
  pictureUrl?: string,
  isVerified?: boolean,
  verifiedType?: string
}

export default {
  props: {
    accounts: Array
  },
  components: {
    Icon,
    InstagramNextAuth
  },
  computed: {
    ...mapState('system', {
      currentGroupId: state => state.currentGroup.id,
      guestGroups: state => state.guestGroups,
      allowInstantwin: state => Boolean(state.currentGroup.allowInstantwin)
    })
  },
  methods: {
    ...mapActions('settingGroup', ['fetchAccounts']),

    /**
     * アカウントトークンの更新
     */
    async updateAccountToken(account: TCampaignAccount) {
      TrackingService.sendEvent('click:グループ設定|キャンペーン実施アカウント|アクセストークン更新')

      if (account.snsType === 'twitter') {
        await this.updateTwitterAccountToken(account)
      } else if (account.snsType === 'tiktok') {
        await this.updateTikTokAccountToken(account)
      } else if (account.snsType === 'instagram') {
        this.$refs.instagramNextAuth.open({ type: 'update', accountId: account.id })
      }

      // アカウント一覧を取得
      this.fetchAccounts(this.currentGroupId)
    },

    /**
     * Twitterアカウントトークンの更新
     */
    async updateTwitterAccountToken(apiAccount: TCampaignAccount) {
      if (!apiAccount.accountId) return

      let account: TAccount = {}

      account = await TwitterAuthService.execAuth()

      if (!account || !Object.keys(account).length) {
        this.$notify({
          title: this.$gettext('認証に失敗しました。'),
          message: this.$gettext('恐れ入りますが、時間を置いて再度お試しください。'),
          customClass: 'danger',
          duration: 5000
        })
        return
      }

      // 認証したアカウントが違う場合
      if (account.id !== apiAccount.accountId) {
        const errorText = '認証したアカウントが違うため、アクセストークンの更新に失敗しました。'

        this.$notify({
          title: this.$gettext(errorText),
          customClass: 'danger',
          duration: 5000
        })
        return
      }

      // Enterprise認証すると認証時のログインアカウント関係なく認証できてしまうため、ここより上でアカウントが正しいかのチェックを行う
      if (apiAccount.isEnterprise && apiAccount.accountId) {
        const enterpriseAuth = await TwitterAuthService.execEnterpriseAuth(apiAccount.accountId)

        if (enterpriseAuth) {
          account = {
            ...account,
            id: enterpriseAuth.id,
            enterpriseToken: enterpriseAuth.enterpriseToken,
            enterpriseSecret: enterpriseAuth.enterpriseSecret
          }
        }
      }

      const result = await api.put('/twitter_campaign_accounts/' + apiAccount.id, {
        token: account.token,
        secret: account.secret,
        enterpriseToken: account.enterpriseToken,
        enterpriseSecret: account.enterpriseSecret,
        name: account.name,
        screenName: account.screenName,
        pictureUrl: account.pictureUrl
      })

      if (!result.data) {
        const errorText = 'アクセストークン更新処理に失敗しました。'

        this.$notify({
          title: this.$gettext(errorText),
          message: this.$gettext('恐れ入りますが、時間を置いて再度お試しください。'),
          customClass: 'danger',
          duration: 5000
        })
        return
      }

      const successText = '%{name}のアクセストークンを更新しました。'

      // 成功メッセージを表示
      this.$notify({
        title: this.$gettextInterpolate(this.$gettext(successText), {
          name: account.name
        }),
        customClass: 'success',
        duration: 5000
      })
    },

    /**
     * TikTokアカウントトークンの更新
     */
    async updateTikTokAccountToken(apiAccount: TCampaignAccount) {
      if (!apiAccount.id) return

      const callback = await TikTokAuthService.openAuthWindow()

      if (callback.type !== 'SUCCESS') {
        this.$notify({
          title: this.$gettext('認証に失敗しました。'),
          message: this.$gettext('恐れ入りますが、時間を置いて再度お試しください。'),
          customClass: 'danger',
          duration: 5000
        })
        return
      }

      const ttAccount = await TikTokAuthService.getAccountInfo(callback, this.currentGroupId)

      if (!ttAccount && !ttAccount.data) {
        return this.$notify({
          title: this.$gettext('認証に失敗しました。'),
          message: this.$gettext('恐れ入りますが、時間を置いて再度お試しください。'),
          customClass: 'danger',
          duration: 5000
        })
      }

      if (ttAccount.data.ttAccountId !== apiAccount.ttAccountId) {
        const errorText = '認証したアカウントが違うため、アクセストークンの更新に失敗しました。'

        this.$notify({
          title: this.$gettext(errorText),
          customClass: 'danger',
          duration: 5000
        })
        return
      }

      const body = {
        groupId: this.currentGroupId,
        accessToken: ttAccount.data.accessToken,
        refreshToken: ttAccount.data.refreshToken
      }

      const result = await api.put('/tiktok_campaign_accounts/' + apiAccount.id, body)

      if (!result.data) {
        const errorText = 'アクセストークン更新処理に失敗しました。'

        this.$notify({
          title: this.$gettext(errorText),
          message: this.$gettext('恐れ入りますが、時間を置いて再度お試しください。'),
          customClass: 'danger',
          duration: 5000
        })
        return
      }

      const successText = '%{name}のアクセストークンを更新しました。'

      // 成功メッセージを表示
      this.$notify({
        title: this.$gettextInterpolate(this.$gettext(successText), {
          name: ttAccount.data.name
        }),
        customClass: 'success',
        duration: 5000
      })
    },

    /**
     * Twitterアカウントの削除
     * @param {string} accountId
     */
    async deleteAccount(accountId: string, snsType: string) {
      TrackingService.sendEvent('click:グループ設定|キャンペーン実施アカウント|削除')

      const confirm = window.confirm(
        this.$gettext('アカウントを登録解除してよろしいですか? \n この操作は取り消しできません。')
      )
      if (!confirm) {
        return
      }

      const apiEndpoints = {
        twitter: '/twitter_campaign_accounts/',
        instagram: '/instagram_campaign_accounts/',
        tiktok: '/tiktok_campaign_accounts/'
      }

      const result = await api.delete(apiEndpoints[snsType] + accountId)

      // 失敗した場合はエラーを表示
      if (result.error && (result.error.type === 'NOT_EXISTS' || result.error.type === 'NO_DATA_EXISTS')) {
        this.$notify({
          title: this.$gettext('対象のアカウントはすでに解除されています。'),
          customClass: 'danger',
          duration: 5000
        })

        // アカウント一覧を取得
        this.fetchAccounts(this.currentGroupId)

        return
      } else if (result.error && result.error.type === 'CAMPAIGN_SCHEDULED') {
        this.$notify({
          title: this.$gettext('キャンペーン開催予定のアカウントです。'),
          message: this.$gettext('対象のキャンペーンを削除後、再度お試しください。'),
          customClass: 'danger',
          duration: 5000
        })
        return
      } else if (!result.data) {
        this.$notify({
          title: this.$gettext('アカウントの登録解除に失敗しました。'),
          message: this.$gettext('恐れ入りますが、時間をおいて再度お試しください。'),
          customClass: 'danger',
          duration: 5000
        })
        return
      }

      // 成功メッセージを表示
      this.$notify({
        title: this.$gettext('アカウントの登録を解除しました。'),
        customClass: 'success',
        duration: 5000
      })

      // アカウント一覧を取得
      this.fetchAccounts(this.currentGroupId)
    }
  }
}
