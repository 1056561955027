/* @flow */
import moment from 'moment'
import twitterText from 'twitter-text'
import Vue from 'vue'

import store from '@/client/store'

/**
 * 翻訳フィルター
 */
Vue.filter('translate', (text: string) => {
  return Vue.prototype.$gettext(text)
})

/**
 * 日時をフォーマット
 */
Vue.filter('datetime', (datetime: string) => {
  if (!datetime) {
    return null
  }
  const language = store.state.system.language
  let format = 'YYYY年MM月DD日 HH:mm'
  if (language === 'en') {
    format = 'YYYY/MM/DD HH:mm'
  }
  const date = moment(datetime)
  if (!date.isValid()) {
    return null
  }
  return date.format(format)
})

/**
 * 日付をフォーマット
 */
Vue.filter('date', (datetime: string) => {
  const language = store.state.system.language
  let format = 'YYYY年MM月DD日'
  if (language === 'en') {
    format = 'YYYY/MM/DD'
  }
  return moment(datetime).format(format)
})

/**
 * 時間をフォーマット
 */
Vue.filter('time', (datetime: string) => {
  return moment(datetime).format('HH:mm')
})

/**
 * Convert year-month format
 */
Vue.filter('yearMonth', (datetime: string) => {
  const language = store.state.system.language
  let format = 'YYYY年MM月'
  if (language === 'en') {
    format = 'YYYY/MM'
  }
  const yearMonth = moment(datetime)
  if (!yearMonth.isValid()) {
    return null
  }
  return yearMonth.format(format)
})

/**
 * 数字フォーマット
 */
Vue.filter('number', (value: string | number) => {
  return Number(value).toLocaleString()
})

/**
 * ポストテキストをフォーマット
 */
Vue.filter('tweetText', text => {
  return twitterText.autoLink(text, {
    usernameIncludeSymbol: true,
    extractUrlsWithoutProtocol: true,
    targetBlank: true
  })
})
