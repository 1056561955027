import { render, staticRenderFns } from "./CampaignCreateInstagramConfirm.vue?vue&type=template&id=4cab6e8f&scoped=true"
import script from "./CampaignCreateInstagramConfirm.vue?vue&type=script&lang=ts"
export * from "./CampaignCreateInstagramConfirm.vue?vue&type=script&lang=ts"
import style0 from "./CampaignCreateInstagramConfirm.vue?vue&type=style&index=0&id=4cab6e8f&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4cab6e8f",
  null
  
)

export default component.exports