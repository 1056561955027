/* @flow */

type Group = {
  [key: number]: number
}

/**
 * ローカルストレージ管理
 */
class LocalStorage {
  /**
   * ローカルストレージのキーを削除
   * @param {string} key キー
   */
  static removeItem(key: string) {
    localStorage.removeItem(key)
  }

  /**
   * 選択中グループ情報取得
   * key: currentGroup
   * data type: Group
   * @return {Group | null} 選択中グループ情報
   */
  static getCurrentGroup(): Group | null {
    try {
      const currentGroup = localStorage.getItem('currentGroup')
      if (currentGroup) {
        return JSON.parse(currentGroup)
      }
      return null
    } catch (err) {
      return null
    }
  }

  /**
   * 選択中グループ情報をセット
   * @param {Group} group
   */
  static setCurrentGroup(group: Group) {
    localStorage.setItem('currentGroup', JSON.stringify(group))
  }
}

export default LocalStorage
