/* @flow */
import api from '@/client/core/api'
import {
  type TGetInstantwinSerialCode,
  type TGetInstantwinSerialCodeParams,
  type TInstantwinSerialCode
} from '@/typings/api/twitter_instantwin_serial_codes.type'

// mutations type
const RESET = 'RESET'
const SET_CAMPAIGN_ID = 'SET_CAMPAIGN_ID'
const SET_MESSAGE_ID = 'SET_MESSAGE_ID'
const SET_LOADING = 'SET_LOADING'
const SET_IS_CREATED = 'SET_IS_CREATED'
const SET_SERIAL_CODE_LIST = 'SET_SERIAL_CODE_LIST'
const SET_SERIAL_CODE_LIST_PARAMS = 'SET_SERIAL_CODE_LIST_PARAMS'

type StateStore = {
  campaignId: number,
  messageId: number,
  loading: boolean,
  isCreated: boolean,
  serialCode: {
    loaded: boolean,
    list: TInstantwinSerialCode[],
    total: number,
    countAssign: number,
    countUnassign: number,
    params: TGetInstantwinSerialCodeParams
  }
}

// ストアーcontext
type Context = {
  commit: Function,
  getters: any,
  state: StateStore,
  dispatch: Function
}

const initState: StateStore = {
  campaignId: 0,
  messageId: 0,
  loading: true,
  isCreated: false,
  serialCode: {
    loaded: false,
    list: [],
    total: 0,
    countAssign: 0,
    countUnassign: 0,
    params: {
      campaignId: 0,
      messageId: 0,
      filter: '',
      sortBy: '-status',
      pagingNo: 1,
      limitCount: 25
    }
  }
}

const state = Object.assign({}, initState)

// getters
const getters = {}

// actions
const actions = {
  /**
   * ストアをリセット
   * @param {Context} context
   */
  async reset(context: Context) {
    context.commit(RESET)
  },

  /**
   * キャンペーンIDをセット
   * @param {Context} context
   * @param {number} campaignId キャンペーンID
   */
  setCampaignId(context: Context, campaignId: number) {
    context.commit(SET_CAMPAIGN_ID, campaignId)
  },
  /**
   * Set value messageId
   * @param {Context} context
   * @param {number} messageId message id
   */
  setMessageId(context: Context, messageId: number) {
    context.commit(SET_MESSAGE_ID, messageId)
  },
  /**
   * Set value loading
   * @param {Context} context
   * @param {number} loading loading
   */
  setLoading(context: Context, loading: boolean) {
    context.commit(SET_LOADING, loading)
  },
  /**
   * Set value isCreated
   * @param {Context} context
   * @param {number} isCreated isCreated
   */
  setIsCreated(context: Context, isCreated: boolean) {
    context.commit(SET_IS_CREATED, isCreated)
  },

  /**
   * Get serial code list
   * @param {Context} context
   */
  async fetchSerialCodeList(context: Context) {
    if (!context.state.campaignId) {
      return
    }

    context.commit(SET_LOADING, true)

    const serial = await api.get('/twitter_instantwin_serial_codes', {
      params: context.state.serialCode.params
    })

    context.commit(SET_LOADING, false)

    if (!serial.data) {
      return
    }

    context.commit(SET_SERIAL_CODE_LIST, serial)
  },

  /**
   * Set parameters for serial code list
   * @param {Context} context
   * @param {TGetInstantwinSerialCodeParams} params パラメーター
   */
  setSerialCodeListParams(context: Context, params: TGetInstantwinSerialCodeParams) {
    context.commit(SET_SERIAL_CODE_LIST_PARAMS, Object.assign({}, context.state.serialCode.params, params))
  }
}

// mutations
const mutations = {
  /**
   * ストアをリセット
   * @param {StateStore} state
   */
  [RESET](state: StateStore) {
    const initStateData: any = initState
    Object.keys(initStateData).forEach(key => {
      state[key] = initStateData[key]
    })
    state.serialCode = {
      loaded: false,
      list: [],
      total: 0,
      countAssign: 0,
      countUnassign: 0,
      params: {
        campaignId: 0,
        messageId: 0,
        filter: '',
        sortBy: '-status',
        pagingNo: 1,
        limitCount: 25
      }
    }
  },

  /**
   * キャンペーンIDをセット
   * @param {StateStore} state
   * @param {number} campaignId キャンペーンID
   */
  [SET_CAMPAIGN_ID](state: StateStore, campaignId: number) {
    state.campaignId = campaignId
    state.serialCode.params.campaignId = campaignId
  },

  /**
   * Set message id
   * @param {StateStore} state
   * @param {number} messageId messageID
   */
  [SET_MESSAGE_ID](state: StateStore, messageId: number) {
    state.messageId = messageId
    state.serialCode.params.messageId = messageId
  },

  [SET_LOADING](state: StateStore, loading: boolean) {
    state.loading = loading
  },

  [SET_IS_CREATED](state: StateStore, isCreated: boolean) {
    state.isCreated = isCreated
  },

  /**
   * キューのパラメーターをセット
   * @param {StateStore} state
   * @param {TGetInstantwinSerialCodeParams} params パラメーター
   */
  [SET_SERIAL_CODE_LIST_PARAMS](state: StateStore, params: TGetInstantwinSerialCodeParams) {
    state.serialCode.params = params
  },

  /**
   * キューのキュー一覧をセット
   * @param {StateStore} state
   * @param {TGetInstantwinSerialCode} params
   */
  [SET_SERIAL_CODE_LIST](state: StateStore, params: TGetInstantwinSerialCode) {
    state.serialCode.list = params.data.serialCode
    state.serialCode.total = params.data.dataCount
    state.serialCode.countAssign = params.data.count.assign
    state.serialCode.countUnassign = params.data.count.unassign
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
