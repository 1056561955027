import { render, staticRenderFns } from "./instantwin_message_add_edit.vue?vue&type=template&id=eadd3c38&scoped=true"
import script from "./instantwin_message_add_edit.component?vue&type=script&lang=js&external"
export * from "./instantwin_message_add_edit.component?vue&type=script&lang=js&external"
import style0 from "./instantwin_message_add_edit.scss?vue&type=style&index=0&id=eadd3c38&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "eadd3c38",
  null
  
)

export default component.exports