/* @flow */
import moment from 'moment-timezone'
import { mapActions, mapState } from 'vuex'

export default {
  data() {
    return {
      datePickerOptions: {
        disabledDate(time: any) {
          return false
        }
      }
    }
  },
  computed: {
    ...mapState('tiktokAnalyticsPublic', {
      campaign: state => state.campaign,
      params: state => state.params,
      prizes: function(state) {
        return [{ id: '', name: this.$gettext('全ての応募グループ') }, ...state.prizes]
      }
    }),
    prizeId: {
      get: function() {
        return this.params.prizeId
      },
      set: function(prizeId: number) {
        if (prizeId === this.params.prizeId) {
          return
        }

        this.setParams({ prizeId })

        this.fetchCampaignAnalytics()
      }
    },
    dateTimeRange: {
      get: function() {
        return [this.params.startDatetime, this.params.endDatetime]
      },
      set: function(datetime: any) {
        if (!datetime) {
          datetime = [
            moment(this.campaign.startDatetime)
              .startOf('hour')
              .format('YYYY-MM-DD HH:mm'),
            moment(this.campaign.endDatetime)
              .startOf('hour')
              .format('YYYY-MM-DD HH:mm')
          ]
        }

        const startDatetime = moment(datetime[0])
        const endDatetime = moment(datetime[1])

        const paramStartDatetime = moment(this.params.startDatetime)
        const paramEndDatetime = moment(this.params.endDatetime)

        this.setParams({
          startDatetime: startDatetime.format('YYYY-MM-DD'),
          endDatetime: endDatetime.format('YYYY-MM-DD')
        })

        if (paramStartDatetime.isSame(startDatetime) && paramEndDatetime.isSame(endDatetime)) {
          return
        }

        this.fetchCampaignAnalytics()
      }
    }
  },
  mounted() {
    const { campaign } = this

    this.datePickerOptions = {
      disabledDate(time: any) {
        if (!campaign || !campaign.id) {
          return false
        }

        const start = moment(campaign.startDatetime).startOf('day')
        const end = moment(campaign.endDatetime)
          .subtract(1, 'hour')
          .startOf('day')

        return moment(time).isBefore(start) || moment(time).isAfter(end)
      }
    }
  },
  methods: {
    ...mapActions('tiktokAnalyticsPublic', ['fetchCampaignAnalytics', 'setParams'])
  }
}
