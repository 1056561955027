






























































































































































































import { setInterval } from 'timers'
import { Component, Vue, Watch } from 'vue-property-decorator'
import { mapActions, mapState } from 'vuex'

import { AppLabel, AppTextHighlight } from '@/client/components/_atoms'
import Icon from '@/client/components/_atoms/Icon.vue'
import { ApplicantHistoryTable, ApplicantStreamItem } from '@/client/components/_molecules'
import Account from '@/client/components/_molecules/Account.vue'
import ButtonIcon from '@/client/components/_molecules/ButtonIcon.vue'
import TwitterPostEmbed from '@/client/components/basic/twitter_post_embed'
import TwitterTimelineWidget from '@/client/components/basic/twitter_timeline_widget'
import { TrackingService } from '@/client/services'
import { TwitterApplicantActions, TwitterApplicantState } from '@/client/store/twitter_applicant.store'
import { getTimeFormat as f } from '@/client/utils/filters'

const ENTRY_HISTORY_EXCEPT_TABLE_HEIGHT = 256
const STREAM_HISTORY_EXCEPT_STREAM_HEIGHT = 240

@Component({
  components: {
    Icon,
    Account,
    AppLabel,
    AppTextHighlight,
    ButtonIcon,
    ApplicantHistoryTable,
    ApplicantStreamItem,
    TwitterPostEmbed,
    TwitterTimelineWidget
  },
  props: {
    campaignType: String
  },
  computed: {
    ...mapState('twitterApplicant', [
      'applicants',
      'total',
      'params',
      'isApplicantDrawer',
      'applicantDrawerLoading',
      'drawerApplicantId',
      'apiApplicantDrawer',
      'applicantDrawerTab'
    ])
  },
  methods: {
    ...mapActions('twitterApplicant', [
      'closeApplicantDrawer',
      'changeApplicantDrawerTab',
      'updateApplicant',
      'prevApplicantDrawer',
      'nextApplicantDrawer'
    ])
  }
})
export default class DrawerTwitterApplicant extends Vue {
  campaignType!: string
  applicants!: TwitterApplicantState['applicants']
  total!: TwitterApplicantState['total']
  params!: TwitterApplicantState['params']
  isApplicantDrawer!: TwitterApplicantState['isApplicantDrawer']
  applicantDrawerLoading!: TwitterApplicantState['applicantDrawerLoading']
  drawerApplicantId!: TwitterApplicantState['drawerApplicantId']
  apiApplicantDrawer!: TwitterApplicantState['apiApplicantDrawer']
  applicantDrawerTab!: TwitterApplicantState['applicantDrawerTab']
  closeApplicantDrawer!: TwitterApplicantActions['closeApplicantDrawer']
  changeApplicantDrawerTab!: TwitterApplicantActions['changeApplicantDrawerTab']
  updateApplicant!: TwitterApplicantActions['updateApplicant']
  prevApplicantDrawer!: TwitterApplicantActions['prevApplicantDrawer']
  nextApplicantDrawer!: TwitterApplicantActions['nextApplicantDrawer']
  getTimeFormat = f

  $refs: {
    postEmbed: HTMLDivElement
    footer: HTMLDivElement
  }

  async mounted() {
    window.addEventListener('resize', this.handleResize)
    window.addEventListener('click', this.closeDrawer)
  }

  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize)
    window.removeEventListener('click', this.closeDrawer)
  }

  @Watch('drawerApplicantId', { immediate: true })
  async watchApiApplicantDrawer() {
    await this.$nextTick()

    // UI描画前だと、正しい高さを取得できないため、1秒遅延させる
    setInterval(() => this.getScrollHeight(), 1000)
  }

  @Watch('applicantDrawerTab')
  async watchApplicantDrawerTab() {
    await this.$nextTick()
    this.getScrollHeight()
  }

  profile_type = 'post'
  tweets_height = 0
  table_height = window.innerHeight - ENTRY_HISTORY_EXCEPT_TABLE_HEIGHT
  stream_height = window.innerHeight - STREAM_HISTORY_EXCEPT_STREAM_HEIGHT

  get is_candidate_diabled() {
    return this.apiApplicantDrawer && !this.apiApplicantDrawer.is_candidate && this.apiApplicantDrawer.is_ng
  }

  get candidate_button_type() {
    return this.apiApplicantDrawer && this.apiApplicantDrawer.is_candidate ? 'primary' : 'default'
  }

  get candidate_button_tooltip() {
    if (this.apiApplicantDrawer && this.apiApplicantDrawer.is_candidate) return '候補者を解除'

    return this.apiApplicantDrawer && this.apiApplicantDrawer.is_ng ? 'NGアカウント' : '候補者に追加'
  }

  get is_winner_diabled() {
    return (
      (this.apiApplicantDrawer && !this.apiApplicantDrawer.is_winner && this.apiApplicantDrawer.is_ng) ||
      this.campaignType === 'web_instantwin'
    )
  }

  get winner_button_type() {
    return this.apiApplicantDrawer && this.apiApplicantDrawer.is_winner ? 'primary' : 'default'
  }

  get winner_button_tooltip() {
    if (this.apiApplicantDrawer && this.apiApplicantDrawer.is_winner) return '当選者を解除'

    return this.apiApplicantDrawer && this.apiApplicantDrawer.is_ng ? 'NGアカウント' : '当選者に追加'
  }

  get is_responder_diabled() {
    return this.apiApplicantDrawer && !this.apiApplicantDrawer.is_responder && this.apiApplicantDrawer.is_ng
  }

  get responder_button_type() {
    return this.apiApplicantDrawer && this.apiApplicantDrawer.is_responder ? 'primary' : 'default'
  }

  get responder_button_tooltip() {
    if (this.apiApplicantDrawer && this.apiApplicantDrawer.is_responder) return '回答者を解除'

    return this.apiApplicantDrawer && this.apiApplicantDrawer.is_ng ? 'NGアカウント' : '回答者に追加'
  }

  get ng_button_type() {
    return this.apiApplicantDrawer && this.apiApplicantDrawer.is_ng ? 'primary' : 'default'
  }

  get ng_button_tooltip() {
    return this.apiApplicantDrawer && this.apiApplicantDrawer.is_ng ? 'NGアカウントを解除' : 'NGアカウントに追加'
  }

  get current_page() {
    const prev_applicant_count = (this.params.pagingNo - 1) * this.params.limitCount

    const applicant_ids = this.applicants.map(v => v.id)

    const index = applicant_ids.indexOf(this.drawerApplicantId)

    return prev_applicant_count + index + 1
  }

  get style_tweets_scroll() {
    return { height: `${this.tweets_height}px`, overflowY: 'auto' }
  }

  get style_stream_scroll() {
    return { height: `${this.stream_height}px`, overflowY: 'auto' }
  }

  /**
   * スクロールの高さを取得する
   */
  getScrollHeight() {
    if (!this.$refs.postEmbed || !this.$refs.footer) return

    const postEmbed = this.$refs.postEmbed.getBoundingClientRect()

    const footer = this.$refs.footer.getBoundingClientRect()

    this.tweets_height = footer.top - postEmbed.top
  }

  /**
   * タブを変更する
   */
  async changeTab(event) {
    switch (event.name) {
      case 'profile':
        TrackingService.sendEvent('click:応募一覧|ユーザー詳細|プロフィール')
        break
      case 'entryHistory':
        TrackingService.sendEvent('click:応募一覧|ユーザー詳細|参加履歴')
        break
      case 'winHistory':
        TrackingService.sendEvent('click:応募一覧|ユーザー詳細|当選履歴')
        break
      case 'streamHistory':
        TrackingService.sendEvent('click:応募一覧|ユーザー詳細|配信履歴')
        break
      default:
        break
    }

    await this.changeApplicantDrawerTab(event.name)
  }

  /**
   * 候補者フラグを更新する
   */
  async updateCandidate() {
    TrackingService.sendEvent('click:ユーザー詳細|プロフィール|候補者に追加')

    if (!this.drawerApplicantId || !this.apiApplicantDrawer) return

    const option = {
      applicantId: this.drawerApplicantId,
      isCandidate: !this.apiApplicantDrawer.is_candidate
    }

    const result = await this.updateApplicant(option)

    if (!result.data) {
      this.$notify({
        title: '候補者の保存に失敗しました',
        message: '',
        customClass: 'danger'
      })

      return
    }

    const applicant_ids = this.applicants.map(v => v.id)

    if (!applicant_ids.includes(this.drawerApplicantId)) {
      await this.closeApplicantDrawer()
    }
  }

  /**
   * 当選者フラグを更新する
   */
  async updateWinner() {
    TrackingService.sendEvent('click:ユーザー詳細|プロフィール|当選者に追加')

    if (!this.drawerApplicantId || !this.apiApplicantDrawer) return

    const option: any = {
      applicantId: this.drawerApplicantId,
      isWinner: !this.apiApplicantDrawer.is_winner
    }

    if (option.isWinner) {
      option.wasWinner = true
    }

    const result = await this.updateApplicant(option)

    if (result.error && result.error.type === 'MAX_WINNER_COUNT_OVER') {
      this.$notify({
        title: '残り当選数に空きがありません。',
        message: '',
        customClass: 'danger'
      })

      return
    }

    if (!result.data) {
      this.$notify({
        title: '当選者の保存に失敗しました',
        message: '',
        customClass: 'danger'
      })

      return
    }

    const applicant_ids = this.applicants.map(v => v.id)

    if (!applicant_ids.includes(this.drawerApplicantId)) {
      await this.closeApplicantDrawer()
    }
  }

  /**
   * 回答者フラグを更新する
   */
  async updateResponder() {
    TrackingService.sendEvent('click:ユーザー詳細|プロフィール|回答者に追加')

    if (!this.drawerApplicantId || !this.apiApplicantDrawer) return

    const option = {
      applicantId: this.drawerApplicantId,
      isResponder: !this.apiApplicantDrawer.is_responder
    }

    const result = await this.updateApplicant(option)

    if (!result.data) {
      this.$notify({
        title: '回答者の保存に失敗しました',
        message: '',
        customClass: 'danger'
      })

      return
    }

    const applicant_ids = this.applicants.map(v => v.id)

    if (!applicant_ids.includes(this.drawerApplicantId)) {
      await this.closeApplicantDrawer()
    }
  }

  /**
   * NGアカウントを更新する
   */
  async updateNgAccount() {
    TrackingService.sendEvent('click:ユーザー詳細|プロフィール|NGアカウントに追加')

    if (!this.drawerApplicantId || !this.apiApplicantDrawer) return

    if (!this.apiApplicantDrawer.is_ng) {
      const confirm = window.confirm('NGアカウントに追加すると、この応募者は抽選対象から除外されます。よろしいですか？')

      if (!confirm) return
    }

    const option = {
      applicantId: this.drawerApplicantId,
      isNg: !this.apiApplicantDrawer.is_ng
    }

    const result = await this.updateApplicant(option)

    if (!result.data) {
      return this.$notify({
        title: this.$gettext('NGアカウントの更新に失敗しました。'),
        message: this.$gettext('恐れ入りますが、時間をおいて再度お試しください。'),
        customClass: 'danger',
        duration: 5000
      })
    }

    const applicant_ids = this.applicants.map(v => v.id)

    if (!applicant_ids.includes(this.drawerApplicantId)) {
      await this.closeApplicantDrawer()
    }
  }

  /**
   * windowリサイズ時の高さ検知
   */
  handleResize() {
    this.getScrollHeight()
    this.table_height = window.innerHeight - ENTRY_HISTORY_EXCEPT_TABLE_HEIGHT
    this.stream_height = window.innerHeight - STREAM_HISTORY_EXCEPT_STREAM_HEIGHT
  }

  /**
   * ドロワー関連以外の場所をクリックした場合にドロワーを閉じる
   */
  async closeDrawer(event) {
    const nodeList = document.querySelectorAll('[data-drawer-not-close]')

    let isDrawerElement = false
    for (const node of nodeList) {
      isDrawerElement = node.contains(event.target)

      if (isDrawerElement) break
    }

    if (!isDrawerElement) {
      await this.closeApplicantDrawer()
    }
  }

  handleRadioChange(value) {
    TrackingService.sendEvent(`radio:ユーザー詳細|プロフィール|${value === 'post' ? '応募ポスト' : '最新のポスト'}`)
  }

  async clickCloseApplicantDrawer() {
    TrackingService.sendEvent('click:応募一覧|ユーザー詳細|閉じる')

    await this.closeApplicantDrawer()
  }
}
