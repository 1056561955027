/* @flow */
import { email, required } from 'vuelidate/lib/validators'
import { mapActions, mapState } from 'vuex'

import api from '@/client/core/api'

export default {
  data() {
    return {
      email: '',
      isUserAdmin: false,
      visible: false,
      isSubmit: false,
      isGuest: false,
      groupId: null
    }
  },
  computed: {
    ...mapState('system', {
      currentGroup: state => state.currentGroup
    }),
    addUserButtonTitle() {
      return this.isGuest ? 'ゲストユーザーの追加' : 'ユーザーの追加'
    }
  },
  methods: {
    ...mapActions('campaign', ['fetchCampaigns']),
    /**
     * 招待情報を保存
     */
    async inviteUser() {
      if (this.isSubmit) {
        return
      }

      this.isSubmit = true
      // 招待情報を保存
      const result = await api.post(
        '/invitations',
        this.isGuest
          ? {
              groupId: this.groupId ?? this.currentGroup.id,
              email: this.email,
              isGuest: true
            }
          : {
              groupId: this.groupId ?? this.currentGroup.id,
              email: this.email,
              isUserAdmin: this.isUserAdmin ? 1 : 0
            }
      )

      setTimeout(() => {
        this.isSubmit = false
      }, 2000)

      if (result.error && result.error.type === 'GROUP_USER_EXISTS') {
        this.$notify({
          title: this.$gettext('対象のユーザーはすでに追加されています。'),
          customClass: 'danger',
          duration: 5000
        })
        this.close()
        return
      }

      if (result.error && result.error.type === 'GROUP_MAX_USER_COUNT_OVER') {
        this.$notify({
          title: this.$gettext('これ以上ユーザーを追加できません。'),
          message: this.$gettext('グループの登録可能ユーザー数を超えています。'),
          customClass: 'danger',
          duration: 5000
        })
        this.close()
        return
      }

      // エラーがある場合はエラーメッセージを表示
      if (!result.data) {
        this.$notify({
          title: this.$gettext('ユーザー招待処理に失敗しました。'),
          message: this.$gettext('恐れ入りますが、時間をおいて再度お試しください。'),
          customClass: 'danger',
          duration: 5000
        })
        this.close()
        return
      }

      // 成功メッセージを表示
      this.$notify({
        title: this.$gettext('招待メールを送信しました。'),
        customClass: 'success',
        duration: 5000
      })
      this.close()
    },
    /**
     * ダイアログを開く
     */
    open(group: any) {
      this.$v.$reset()
      this.email = ''
      this.isUserAdmin = false
      this.isSubmit = false
      this.visible = true
      this.isGuest = group?.isGuest ?? false

      // 契約管理からの場合は選択したグループID、グループ設定からの場合は現在のグループID
      if (group.id) {
        this.groupId = group.id
      } else {
        this.groupId = this.currentGroup.id
      }
    },
    /**
     * ダイアログを閉じる
     */
    close() {
      this.visible = false
    }
  },
  validations: {
    email: {
      required,
      email
    }
  }
}
