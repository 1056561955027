








import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class Steps extends Vue {
  @Prop({ type: Number, required: true })
  active!: number

  @Prop({ type: Array, default: () => [] })
  options!: string[]
}
