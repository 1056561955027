/* @flow */
import Vue from 'vue'
import { email, minLength, required } from 'vuelidate/lib/validators'
import { mapActions, mapState } from 'vuex'

import ConfirmDialog from '@/client/components/basic/confirm_dialog'
import SettingUserNotifications from '@/client/components/setting_user/setting_user_notifications'
import SettingUserTwoFactor from '@/client/components/setting_user/setting_user_two_factor'
import api from '@/client/core/api'
import { TrackingService } from '@/client/services'
import { getLoginStatus, login } from '@/client/utils/facebook-sdk'

export default {
  components: {
    ConfirmDialog,
    SettingUserNotifications,
    SettingUserTwoFactor
  },
  data() {
    return {
      settings: {
        name: '',
        email: '',
        password: '********',
        facebook: null
      },
      originSettings: {
        name: '',
        email: '',
        password: '********',
        facebook: null
      },
      facebook: {},
      editMode: false,
      isInputPassword: false,
      isInput: false,
      isSubmit: false,
      dialog: {
        title: ''
      }
    }
  },
  async created() {
    const result = await api.get('/me/settings')

    if (result.data) {
      this.settings = Object.assign(this.settings, result.data.settings)
      this.originSettings = Object.assign(this.originSettings, result.data.settings)
    }

    window.onbeforeunload = () => {
      if (this.isInput) {
        return ''
      }
    }
  },
  destroyed() {
    window.onbeforeunload = null
  },
  computed: {
    ...mapState('system', {
      user: state => state.user
    })
  },
  methods: {
    ...mapActions('system', {
      setSystemUser: 'setUser'
    }),
    /**
     * submit
     */
    async submit() {
      // validations
      this.$v.$touch()
      if (this.$v.$invalid === true) {
        return
      }
      if (this.isSubmit) {
        return
      }
      this.isSubmit = true
      const params: any = {
        settings: {
          name: this.settings.name,
          email: this.settings.email,
          facebook: this.settings.facebook
        }
      }
      if (this.isInputPassword) {
        params.settings.password = this.settings.password
      }

      // ユーザー設定を変更
      const result = await api.put('/me/settings', params)
      this.isSubmit = false

      if (result.error && result.error.type === 'DUPLICATE') {
        // エラーメッセージを表示
        this.$notify({
          title: this.$gettext('すでに登録済のメールアドレスです。'),
          customClass: 'danger',
          duration: 5000
        })
        return
      }
      if (!result.data) {
        // エラーメッセージを表示
        this.$notify({
          title: this.$gettext('ユーザー情報の更新に失敗しました。'),
          customClass: 'danger',
          duration: 5000
        })
        return
      }

      // 成功メッセージを表示
      this.$notify({
        title: this.$gettext('ユーザー情報を更新しました。'),
        customClass: 'success',
        duration: 5000
      })
      this.originSettings = Object.assign(this.originSettings, params.settings)
      this.cancel()

      // システムユーザーストアに変更
      const user = Object.assign({}, this.user, {
        name: this.settings.name,
        email: this.settings.email
      })
      this.setSystemUser(user)
    },

    /**
     * Facebook連携ボタンクリック
     */
    async facebookConnect() {
      TrackingService.sendEvent('click:ユーザー設定|Facebookと連携')

      let response = await getLoginStatus()

      if (response.status !== 'connected') {
        response = await login()
      }

      if (response.status !== 'connected') {
        this.$notify({
          title: this.$gettext('Facebook認証に失敗しました。'),
          customClass: 'danger',
          duration: 5000
        })

        return
      }

      this.facebook = {
        userId: response.authResponse.userID
      }

      // Facebookとの連携を行う
      return this.execFacebookConnect()
    },

    /**
     * Facebookとの連携を行う
     */
    async execFacebookConnect() {
      if (this.isSubmit) {
        return
      }
      if (!this.facebook.userId) {
        return
      }
      const params = {
        settings: {
          facebook: {
            userId: this.facebook.userId
          }
        }
      }
      // ユーザー設定を変更
      const result = await api.put('/me/settings', params)
      this.isSubmit = false

      if (result.error && result.error.type === 'DUPLICATE') {
        // エラーメッセージを表示
        this.$notify({
          title: this.$gettext('別のユーザーで連携済みの為、連携は行なえません。'),
          customClass: 'danger',
          duration: 5000
        })
        return
      }

      if (!result.data) {
        // エラーメッセージを表示
        this.$notify({
          title: this.$gettext('Facebookログイン連携設定に失敗しました。'),
          customClass: 'danger',
          duration: 5000
        })
        return
      }

      // 成功メッセージを表示
      this.$notify({
        title: this.$gettext('Facebookログイン連携を設定しました。'),
        customClass: 'success',
        duration: 5000
      })
      this.settings.facebook = {
        userId: this.facebook.userId
      }
      this.originSettings = Object.assign(this.originSettings, params.settings)
      // システムユーザーストアに変更
      const user = Object.assign({}, this.user, {
        pictureUrl: result.data.pictureUrl
      })
      this.setSystemUser(user)
    },

    /**
     * Facebookとの連携を解除
     */
    async facebookRemoveConnect() {
      if (this.isSubmit) {
        return
      }
      this.$refs.confirmDialog.open(
        this.$gettext('Facebookログイン連携を解除してよろしいですか？'),
        '',
        'default',
        async confirm => {
          if (!confirm) {
            return
          }
          const params = {
            settings: {}
          }
          // ユーザー設定を変更
          const result = await api.put('/me/settings', params)
          this.isSubmit = false

          if (!result.data) {
            // エラーメッセージを表示
            this.$notify({
              title: this.$gettext('Facebookログイン連携解除に失敗しました。'),
              customClass: 'danger',
              duration: 5000
            })
            return
          }

          // 成功メッセージを表示
          this.$notify({
            title: this.$gettext('Facebookログイン連携を解除しました。'),
            customClass: 'success',
            duration: 5000
          })
          this.settings.facebook = null
          this.originSettings = Object.assign(this.originSettings, params.settings)

          // システムユーザーストアに変更
          const user = Object.assign({}, this.user, {
            pictureUrl: result.data.pictureUrl
          })
          this.setSystemUser(user)
        }
      )
    },

    /**
     * パスワード変更イベント
     * @param {any} event イベント
     */
    passwordOnKeydown(event: any) {
      TrackingService.sendEvent('input:ユーザー設定|パスワード')

      // validate
      this.$v.settings.password.$touch()
      // 最初入力する場合は、パスワードを''にセットし、パスワード変更フラグをtrueにセット
      if (this.isInputPassword === false) {
        Vue.nextTick(() => {
          if (event.key.length === 1) {
            this.isInputPassword = true
          }
        })
      }
      this.isInput = true
    },

    /**
     * input変更イベント
     * @param {string} name
     */
    inputChange(name: string) {
      if (name === 'name') {
        TrackingService.sendEvent('input:ユーザー設定|お名前')
      } else TrackingService.sendEvent('input:ユーザー設定|メールアドレス')

      // validate
      this.$v.settings[name].$touch()
      this.isInput = true
    },

    /**
     * キャンセル
     */
    cancel() {
      this.editMode = false
      this.isInput = false
      this.isInputPassword = false
      this.settings = Object.assign({}, this.originSettings)
    },

    /**
     * 離脱チェック
     */
    checkBeforeLeave(callback: Function) {
      if (!this.isInput) {
        return callback(null, true)
      }
      this.$refs.confirmDialog.open(
        this.$gettext('未保存の変更があります。このまま移動しますか？'),
        '',
        'default',
        confirm => {
          if (!confirm) {
            return callback(null, false)
          }
          callback(null, true)
        }
      )
    },

    onChangeBackEvent() {
      TrackingService.sendEvent('click:ユーザー設定|戻る')

      this.$router.push('/campaigns')
    },

    onChangeEditMode() {
      TrackingService.sendEvent('click:ユーザー設定|編集')

      this.editMode = true
    }
  },
  validations: {
    settings: {
      email: {
        required,
        email
      },
      name: {
        required
      },
      password: {
        required,
        // パスワードは8文字以上が必要
        minLength: minLength(8)
      }
    }
  }
}
