/* @flow */
import { mapActions, mapState } from 'vuex'

import api from '@/client/core/api'

export default {
  data() {
    return {
      randomCount: 1,
      visible: false,
      loading: false,
      isExcludeWasWinner: true,
      isExcludeWinnerCampaigns: false,
      isSubmit: false,
      randomType: 'winner'
    }
  },
  computed: {
    ...mapState('tiktokApplicant', {
      applicantCount: state => state.total,
      campaignId: state => state.campaign.id,
      campaignAccountId: state => state.campaign.account.id,
      prizeId: state => state.campaignPrize.id,
      applicantParams: state => state.params,
      remainWinnerCount: state => {
        if (!state.campaignPrize) {
          return 0
        }
        return state.campaignPrize.maxWinnerCount - state.campaignPrize.winnerCount
      }
    }),
    maxRandomCount() {
      if (this.randomType === 'candidate') {
        return this.applicantCount < 999 ? this.applicantCount : 999
      }

      return this.applicantCount < this.remainWinnerCount ? this.applicantCount : this.remainWinnerCount
    },
    url() {
      return this.randomType === 'candidate'
        ? '/tiktok_applicants/random_candidates'
        : '/tiktok_applicants/random_winners'
    },
    disabled() {
      return this.randomType === 'winner' && this.remainWinnerCount <= 0
    }
  },
  watch: {
    randomType() {
      if (this.randomCount <= this.maxRandomCount) {
        return
      }

      this.randomCount = this.maxRandomCount
    }
  },
  methods: {
    ...mapActions('tiktokApplicant', ['fetchApplicants', 'fetchCampaignPrize']),
    /**
     * ダイアログリセット
     */
    reset() {
      this.randomCount = 1
      this.loading = false
      this.isSubmit = false
      this.closeOnClickModal = true
      this.randomType = 'winner'
    },

    /**
     * フォームサブミット
     */
    async submit() {
      if (this.isSubmit) {
        return
      }
      const confirm = window.confirm(this.$gettext('この操作は取り消しできません、実行してもよろしいですか?'))
      if (!confirm) {
        return
      }
      this.isSubmit = true
      this.loading = true

      const applicantParamsClone = { ...this.applicantParams }

      // 過去に当選したことのある応募者を除外する
      if (this.isExcludeWasWinner) {
        applicantParamsClone.wasWinner = 0
      }

      let params = {
        campaignId: this.campaignId,
        prizeId: this.prizeId,
        randomCount: this.randomCount,
        isExcludeWinnerCampaigns: this.isExcludeWinnerCampaigns
      }

      params = { ...applicantParamsClone, ...params }

      const result = await api.post(this.url, params)
      this.loading = false
      setTimeout(() => {
        this.isSubmit = false
      }, 2000)
      // 残り当選者数が足りない場合はエラーを表示
      if (result && result.error && result.error.type === 'MAX_WINNER_COUNT_OVER') {
        this.$notify({
          title: this.$gettext('残り当選者数が足りません。'),
          message: this.$gettext('当選者数を変更して、再度お試しください。'),
          customClass: 'danger',
          duration: 5000
        })
        this.fetchCampaignPrize()
        this.fetchApplicants()
        return
      }

      // 失敗した場合はエラーを表示
      if (!result || isNaN(result.data)) {
        this.$notify({
          title: this.$gettext('ランダム抽選に失敗しました。'),
          message: this.$gettext('恐れ入りますが、時間をおいて再度お試しください。'),
          customClass: 'danger',
          duration: 5000
        })
        return
      }

      // 成功メッセージを表示
      this.$notify({
        title: this.$gettext('ランダム抽選が完了しました。'),
        message: this.$gettextInterpolate(this.$gettext('%{randomCount}人の応募者を当選者にしました。'), {
          randomCount: result.data
        }),
        customClass: 'success',
        duration: 5000
      })

      this.fetchCampaignPrize()
      this.fetchApplicants()
      this.close()
    },

    /**
     * 応募者数変更イベント
     * @param {number} value バリュー
     */
    onChangeRandomCount(value: number) {
      if (!value) {
        setTimeout(() => {
          this.randomCount = 1
        }, 100)
      }
    },

    open() {
      this.visible = true
      this.reset()
    },
    close() {
      this.visible = false
    }
  }
}
