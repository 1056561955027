/* @flow */
import { mapActions, mapState } from 'vuex'

import DirectMessageEdit from '@/client/components/twitter_applicant/direct_message_edit'
import DirectMessageMessageTable from '@/client/components/twitter_applicant/direct_message_message_table'
import DirectMessageQueueTable from '@/client/components/twitter_applicant/direct_message_queue_table'
import DirectMessageQueuesTargets from '@/client/components/twitter_applicant/direct_message_queues_targets'
import api from '@/client/core/api'
import { TrackingService } from '@/client/services'

export default {
  components: {
    DirectMessageQueueTable,
    DirectMessageEdit,
    DirectMessageQueuesTargets,
    DirectMessageMessageTable
  },
  data() {
    return {
      visible: false,
      title: this.$gettext('当選メッセージの管理'),
      messageQueueId: 0,
      queueCreate: false,
      queuesTargets: false,
      queuesTargetsStatus: '',
      messageTemplates: [],
      isChangeWithoutSubmit: false,
      isCopy: false
    }
  },
  computed: {
    ...mapState('system', {
      currentGroup: state => state.currentGroup
    }),
    ...mapState('twitterDirectMessageDialog', {
      queueListQueues(state) {
        return state.queueList.queues
      },
      queueListParams: state => state.queueList.params,
      queueListOptions: state => {
        return {
          sortBy: state.queueList.params.sortBy
        }
      },
      queueListIsShowDetail: state => state.queueList.isShowDetail
    })
  },
  watch: {
    // メッセージキューパラメーターを変更する際、キュー一覧を取得
    queueListParams() {
      this.fetchQueueList()
    }
  },
  methods: {
    ...mapActions('twitterDirectMessageDialog', [
      'reset',
      'setPrizeId',
      'setCampaignId',
      'fetchQueueList',
      'setQueueListParams',
      'setQueueListIsShowDetail'
    ]),
    ...mapActions('twitterSerialCodeDialog', {
      setSerialPrizeId: 'setPrizeId',
      setSerialCampaignId: 'setCampaignId',
      fetchSerialCodeList: 'fetchSerialCodeList'
    }),

    /**
     * メッセージキューソートを変更イベント
     * @param {string} sortBy ソート
     */
    onChangeQueueListSortBy(sortBy: string) {
      if (this.queueListParams.sortBy === sortBy) {
        return
      }
      this.setQueueListParams({ sortBy })
    },

    /**
     * メッセージキューを削除するイベント
     */
    onChangeMessageQueue() {
      this.fetchQueueList()
    },

    /**
     * メッセージキュー作成イベント
     */
    onClickMessageQueueCreate() {
      TrackingService.sendEvent('click:当選メッセージの管理|メッセージ管理|新規作成')

      this.messageQueueId = 0
      this.queueCreate = true
      this.isCopy = false
    },

    /**
     * 配信対象を取得する
     * @param {number} messageQueueId メッセージキューID
     * @param {string} status
     */
    onClickGetMessageQueuesId(messageQueueId: number, status: string) {
      this.messageQueueId = messageQueueId
      this.queuesTargetsStatus = status
      this.queuesTargets = true
    },

    /**
     * メッセージキュー編集イベント
     * @param {number} messageQueueId メッセージキューID
     */
    onClickMessageQueueEdit(messageQueueId: number, isCopy: boolean) {
      this.messageQueueId = messageQueueId
      this.queueCreate = true
      this.isCopy = isCopy
    },

    /**
     * メッセージキュー詳細ボタンをクリックするイベント
     * @param {number} messageQueueId メッセージキューID
     */
    onClickMessageQueueDetail(messageQueueId: number) {
      this.messageQueueId = messageQueueId
      this.setQueueListIsShowDetail(true)
    },

    /**
     * メッセージ作成or編集の完了イベント
     */
    successMessageQueueEdit() {
      this.messageQueueId = 0
      this.queueCreate = false
    },

    /**
     * メッセージキュー一覧を表示する
     */
    backToMessageQueuesList() {
      this.messageQueueId = 0
      this.queuesTargets = false
    },

    async getMessageTemplates() {
      const params = {
        groupId: this.currentGroup.id,
        sortBy: 'name'
      }

      const messageTemplates = await api.get('/message_templates', {
        params
      })

      if (messageTemplates && messageTemplates.data && messageTemplates.data.templates) {
        this.messageTemplates = messageTemplates.data.templates
      }
    },

    /**
     * ダイアログを開く
     * @param {number} キャンペーンID
     */
    open(prizeId: number, campaignId: number) {
      this.visible = true
      this.setPrizeId(prizeId)
      this.setCampaignId(campaignId)
      this.setSerialPrizeId(prizeId)
      this.setSerialCampaignId(campaignId)
      this.fetchQueueList()
      this.fetchSerialCodeList()
      this.getMessageTemplates()
    },

    /**
     * ダイアログを閉じる
     */
    close() {
      // 一括のメッセージストアをリセット
      this.messageQueueId = 0
      this.queueCreate = false
      this.queuesTargets = false
      this.isChangeWithoutSubmit = false
      this.reset()
    },

    /**
     * ダイアログ閉じるイベント
     */
    beforeClose(done) {
      TrackingService.sendEvent(`click:当選メッセージの管理|メッセージ${this.queueCreate ? '新規作成' : '管理'}|閉じる`)

      if (!this.isChangeWithoutSubmit) {
        this.close()
        return done()
      }

      const confirm = window.confirm(this.$gettext('編集内容が保存されていません。ダイアログを閉じてよろしいですか?'))

      if (!confirm) {
        return
      }

      this.close()
      done()
    }
  }
}
