import { render, staticRenderFns } from "./TwitterAnalyticsPublicChart.vue?vue&type=template&id=568c107b&scoped=true"
import script from "./TwitterAnalyticsPublicChart.component?vue&type=script&lang=js&external"
export * from "./TwitterAnalyticsPublicChart.component?vue&type=script&lang=js&external"
import style0 from "./TwitterAnalyticsPublicChart.vue?vue&type=style&index=0&id=568c107b&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "568c107b",
  null
  
)

export default component.exports