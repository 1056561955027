/* @flow */
import moment from 'moment'
import { mapActions, mapState } from 'vuex'

import ApplicantName from '@/client/components/basic/applicant_name'
import CsvDownload from '@/client/components/basic/csv_download'
import Pagination from '@/client/components/basic/pagination'
import api from '@/client/core/api'

export default {
  props: {
    messageQueueId: Number,
    messageQueueStatus: String,
    backToMessageQueuesList: Function
  },
  components: {
    ApplicantName,
    CsvDownload,
    Pagination
  },
  data() {
    return {
      messages: [],
      loading: false,
      is_download: false,
      total: 0
    }
  },
  created() {
    // メッセージ一覧を取得
    this.getMessages()
  },
  computed: {
    ...mapState('twitterDirectMessageDialog', ['campaignId', 'prizeId'])
  },
  methods: {
    ...mapActions('twitterDirectMessageDialog', ['fetchQueueList', 'setQueueListIsShowDetail']),

    /**
     * メッセージ一覧を取得
     */
    async getMessages() {
      if (!this.messageQueueId) {
        return
      }

      this.loading = true
      const params = { campaignId: this.campaignId, prizeId: this.prizeId }
      const result = await api.get(`/twitter_message_queues/targets/${this.messageQueueId}`, {
        params
      })
      this.loading = false

      if (!result.data) {
        this.messages = []
        this.total = 0
        return
      }

      this.messages = result.data
      this.total = result.data.length
    },

    /**
     * メッセージキューに戻る
     */
    backToQueueList() {
      this.fetchQueueList()
      this.setQueueListIsShowDetail(false)
      this.backToMessageQueuesList()
    },

    /**
     * CSVダウンロード
     */
    async downloadCsv() {
      this.is_download = true

      const params = { campaignId: this.campaignId, prizeId: this.prizeId }
      const result = await api.get(`/twitter_message_queues/targets/${this.messageQueueId}`, {
        params
      })

      this.is_download = false

      if (!result.data) {
        this.$notify({
          title: this.$gettext('CSVダウンロードに失敗しました。'),
          message: this.$gettext('恐れ入りますが、時間をおいて再度お試しください。'),
          customClass: 'danger',
          duration: 5000
        })
        return
      }

      const messages = result.data
      const filename = `campaign_messages_targets_${moment().format('YYYY_MM_DD')}`
      const csvData = []
      csvData.push([
        this.$gettext('ステータス'),
        this.$gettext('アカウント名'),
        this.$gettext('アカウントスクリーンネーム'),
        this.$gettext('アカウント画像')
      ])

      const status =
        this.messageQueueStatus === 'scheduled' ? this.$gettext('スケジュール済み') : this.$gettext('下書き')

      // CSVデータを格納
      messages.forEach(message => {
        csvData.push([status, message.name, message.screenName, message.pictureUrl])
      })

      return {
        filename,
        data: csvData
      }
    }
  }
}
