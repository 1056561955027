






import { computed, defineComponent, PropType } from '@vue/composition-api'

import { PropColorType } from './types'

export default defineComponent({
  name: 'AppLabel',
  props: {
    color: {
      type: String as PropType<PropColorType>,
      default: 'success'
    }
  },
  setup(props) {
    const className = computed(() => 'app-label-' + props.color)

    return {
      className
    }
  }
})
