/* @flow */
import { mapState } from 'vuex'

import AnalyticsChartDoughnut from '@/client/components/basic/analytics_chart_doughnut'
import CsvDownload from '@/client/components/basic/csv_download'

export default {
  components: {
    CsvDownload,
    AnalyticsChartDoughnut
  },
  data() {
    return {
      chartStyles: {
        margin: '0 auto'
      }
    }
  },
  computed: {
    ...mapState('twitterAnalyticsPublic', {
      followerAnalytics: state => state.followerAnalytics,
      campaign: state => state.campaign
    }),
    isEmpty() {
      return this.followerAnalytics.every(follower => follower.count === 0)
    }
  },
  methods: {
    downloadCsv() {
      const filename = `${this.campaign.title}_応募者のフォロワー分析`
      const data = []
      const header = [this.$gettext('ラベル'), this.$gettext('値'), this.$gettext('割合')]

      data.push(header)
      const totalData = this.followerAnalytics
        .map(follower => follower.count)
        .reduce((count, current) => count + current, 0)

      this.followerAnalytics.forEach(follower => {
        const percentage = !totalData ? 0 : Math.round((follower.count / totalData) * Math.pow(100, 2)) / 100
        data.push([follower.label, follower.count, percentage])
      })

      return { filename, data }
    }
  }
}
