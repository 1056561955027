




















import { computed, defineComponent, PropType, ref } from '@vue/composition-api'

import { AppPagination } from '@/client/components/_atoms'
import { TrackingService } from '@/client/services'
import { getTimeFormat as f } from '@/client/utils/filters'

import { PropHistoryType } from './types'

export default defineComponent({
  name: 'ApplicantHistoryTable',
  components: {
    AppPagination
  },
  props: {
    histories: {
      type: Array,
      default: () => [] as PropType<PropHistoryType[]>
    },
    maxHeight: {
      type: Number,
      default: null
    },
    type: {
      type: String,
      default: ''
    }
  },
  setup(props) {
    const currentPage = ref<number>(1)
    const pageSize = ref<number>(25)
    const sortProp = ref<'datetime' | 'campaign_name'>('datetime')
    const sortOrder = ref<'ascending' | 'descending' | null>('descending')

    const data = computed(() => {
      const start = (currentPage.value - 1) * pageSize.value
      const end = currentPage.value * pageSize.value

      const copy = props.histories.slice()

      copy.sort((a, b) => {
        const v1 = a[sortProp.value]
        const v2 = b[sortProp.value]

        if (sortOrder.value === 'ascending') {
          if (v1 < v2) return -1
          if (v1 > v2) return 1
          return 0
        }

        if (sortOrder.value === 'descending') {
          if (v1 < v2) return 1
          if (v1 > v2) return -1
          return 0
        }

        return 0
      })

      return copy.slice(start, end)
    })

    const sort = computed(() => ({
      prop: sortProp.value,
      order: sortOrder.value
    }))

    function pagingChange(event: { pageSize: number; currentPage: number }) {
      if (pageSize.value !== event.pageSize)
        TrackingService.sendEvent(
          `select:ユーザー詳細|${props.type === 'entryHistory' ? '参加履歴' : '当選履歴'}|表示件数`
        )

      if (currentPage.value < event.currentPage) {
        TrackingService.sendEvent(
          `click:ユーザー詳細|${props.type === 'entryHistory' ? '参加履歴' : '当選履歴'}|表示件数|次へ`
        )
      } else if (currentPage.value > event.currentPage) {
        TrackingService.sendEvent(
          `click:ユーザー詳細|${props.type === 'entryHistory' ? '参加履歴' : '当選履歴'}|表示件数|前へ`
        )
      }

      pageSize.value = event.pageSize
      currentPage.value = event.currentPage
    }

    function sortChange(event: { prop: string; order: string }) {
      TrackingService.sendEvent(
        `sort:ユーザー詳細|${props.type === 'entryHistory' ? '参加履歴' : '当選履歴'}|${
          event.prop === 'datetime' ? '日時' : 'キャンペーン名'
        }`
      )

      sortProp.value = event.prop
      sortOrder.value = event.order
    }

    return {
      currentPage,
      pageSize,
      data,
      sort,
      pagingChange,
      sortChange,
      f
    }
  }
})
