/* @flow */
import { mapActions, mapState } from 'vuex'

import SelectForm from '@/client/components/basic/select_form'
import { INSTANTWIN_LOSE_REASON } from '@/client/constant'

export default {
  components: {
    SelectForm
  },
  data() {
    return {
      type: {
        label: this.$gettext('種別'),
        value: null,
        icon: 'folder-multiple-outline',
        placeholder: this.$gettext('種別'),
        options: [
          { label: this.$gettext('指定なし'), value: null },
          { label: this.$gettext('当選'), value: 'win' },
          { label: this.$gettext('落選'), value: 'lose' }
        ],
        onChange: (value: string) => {
          if (this.params.filterType === value) return

          this.type.value = value
          this.message.value = null
          this.loseReason.value = null

          this.setHistoryParams({ filterType: value, filterMessage: null, filterLoseReason: null, pagingNo: 1 })
        }
      },
      message: {
        value: null,
        label: this.$gettext('メッセージ'),
        icon: 'email',
        placeholder: this.$gettext('メッセージ'),
        onChange: (value: string) => {
          if (this.params.filterMessage === value) return

          this.message.value = value

          this.setHistoryParams({ filterMessage: value, pagingNo: 1 })
        }
      },
      loseReason: {
        value: null,
        label: this.$gettext('落選となった理由'),
        icon: 'magnify-close',
        placeholder: this.$gettext('落選となった理由'),
        onChange: (value: string) => {
          if (this.params.filterLoseReason === value) return

          this.loseReason.value = value

          this.setHistoryParams({ filterLoseReason: value, pagingNo: 1 })
        }
      },
      isResponder: {
        value: -1,
        label: this.$gettext('回答者'),
        icon: 'clipboard-check',
        placeholder: this.$gettext('回答者'),
        options: [
          { label: this.$gettext('指定なし'), value: -1 },
          { label: this.$gettext('回答フラグあり'), value: 1 },
          { label: this.$gettext('回答フラグなし'), value: 0 }
        ],
        onChange: (value: string) => {
          if (this.params.filterResponder === value) return

          this.isResponder.value = value

          this.setHistoryParams({ filterResponder: value, pagingNo: 1 })
        }
      }
    }
  },
  computed: {
    ...mapState('twitterInstantwinMessageHistory', {
      params: state => state.params,
      listMessage: state => {
        const defaultOption = [{ label: '指定なし', value: null }]
        let messages = state.messages
        if (state.params.filterType) {
          messages = messages.filter(message => {
            return message.messageType === state.params.filterType
          })
        }

        const listMessages = messages.map(message => ({
          label: message.name,
          value: message.id
        }))

        return defaultOption.concat(listMessages)
      }
    }),
    listLoseReason() {
      const defaultOption = [{ label: '指定なし', value: null }]

      if (this.type.value === 'win') return defaultOption

      const loseReasons = Object.keys(INSTANTWIN_LOSE_REASON).map(v => ({
        label: INSTANTWIN_LOSE_REASON[v],
        value: v
      }))

      return defaultOption.concat(loseReasons)
    }
  },
  methods: {
    ...mapActions('twitterInstantwinMessageHistory', ['fetchHistoryList', 'setHistoryParams'])
  }
}
