/* @flow */

// mutations type
const SET_BREADCRUMB = 'SET_BREADCRUMB'

// パンくず
type Breadcrumb = {
  name: string,
  link: string,
  iconName: string,
  iconUrl: string,
  iconType: string
}

type NavbarStore = {
  breadcrumbs: Breadcrumb[]
}

// 型定義: ストアーcontext
type Context = {
  commit: Function,
  getters: any,
  state: NavbarStore
}

// init state
const state = {
  breadcrumbs: []
}

// getters
const getters = {}

// actions
const actions = {
  /**
   * ブレッドクラムをセット
   * @param {Context} context
   * @param {Breadcrumb[]} breadcrumbs ブレッドクラム
   */
  setBreadcrumb(context: Context, breadcrumbs: Breadcrumb[]) {
    context.commit(SET_BREADCRUMB, breadcrumbs)
  }
}

// mutations
const mutations = {
  /**
   * ブレッドクラムをセット
   * @param {NavbarStore} state
   * @param {Breadcrumb[]} breadcrumbs ブレッドクラム
   */
  [SET_BREADCRUMB](state: NavbarStore, breadcrumbs: Breadcrumb[]) {
    state.breadcrumbs = breadcrumbs
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
