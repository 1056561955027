import { render, staticRenderFns } from "./message_queue_create.vue?vue&type=template&id=b1e47d70&scoped=true"
import script from "./message_queue_create.component?vue&type=script&lang=js&external"
export * from "./message_queue_create.component?vue&type=script&lang=js&external"
import style0 from "./message_queue_create.scss?vue&type=style&index=0&id=b1e47d70&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b1e47d70",
  null
  
)

export default component.exports