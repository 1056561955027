/* @flow */
import { email, required } from 'vuelidate/lib/validators'

import api from '@/client/core/api'

export default {
  data() {
    return {
      email: ''
    }
  },
  methods: {
    /**
     * ログインサブミット
     * @return {void}
     */
    submit: async function($v: any) {
      // validations
      $v.$touch()
      if ($v.$invalid === true) {
        return
      }

      const params = {
        email: this.email
      }
      const result = await api.post('/users/new_password_requests', params)
      // エラーがある場合は表示
      if (result.error && result.error.type === 'NOT_EXISTS') {
        this.$notify({
          title: this.$gettext('メールアドレスが登録されていません。'),
          customClass: 'danger',
          duration: 10000
        })
        this.$router.push('/login')
        return
      }
      if (result.data !== true) {
        this.$notify({
          title: this.$gettext('エラーが発生しました。'),
          message: this.$gettext('恐れ入りますが、時間をおいて再度お試しください。'),
          customClass: 'danger',
          duration: 10000
        })
        return
      }

      // 成功する場合
      this.$notify({
        title: this.$gettext('確認メールを送信しました。'),
        message: this.$gettext('メールが届かない場合は、再度確認メールを送信してください。'),
        customClass: 'success',
        duration: 10000
      })
      this.$router.push('/login')
    }
  },
  validations: {
    email: {
      required,
      email
    }
  }
}
