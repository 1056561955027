






















































































import { Component, Vue } from 'vue-property-decorator'
import { mapActions, mapGetters, mapState } from 'vuex'

import Icon from '@/client/components/_atoms/Icon.vue'
import Message from '@/client/components/_atoms/Message.vue'
import Steps from '@/client/components/_atoms/Steps.vue'
import { CampaignCreateTikTokConfirm } from '@/client/components/_organisms'
import CampaignCreateAccount from '@/client/components/_organisms/CampaignCreateAccount.vue'
import CampaignCreateCompleted from '@/client/components/_organisms/CampaignCreateCompleted.vue'
import CampaignCreateInstagramCondition from '@/client/components/_organisms/CampaignCreateInstagramCondition.vue'
import CampaignCreateInstagramConfirm from '@/client/components/_organisms/CampaignCreateInstagramConfirm.vue'
import CampaignCreateIntro from '@/client/components/_organisms/CampaignCreateIntro.vue'
import CampaignCreatePeriod from '@/client/components/_organisms/CampaignCreatePeriod.vue'
import CampaignCreateTikTokCondition from '@/client/components/_organisms/CampaignCreateTikTokCondition/Component.vue'
import CampaignCreateTitle from '@/client/components/_organisms/CampaignCreateTitle.vue'
import CampaignCreateTwitterCondition from '@/client/components/_organisms/CampaignCreateTwitterCondition.vue'
import CampaignCreateTwitterConfirm from '@/client/components/_organisms/CampaignCreateTwitterConfirm.vue'
import { TrackingService } from '@/client/services'
import {
  CampaignDialogActions,
  CampaignDialogGetters,
  CampaignDialogState
} from '@/client/store/campaign_dialog.module'
import { SystemState } from '@/client/store/system.store'

@Component({
  components: {
    Icon,
    Message,
    Steps,
    CampaignCreateIntro,
    CampaignCreateTitle,
    CampaignCreateAccount,
    CampaignCreatePeriod,
    CampaignCreateTikTokConfirm,
    CampaignCreateTwitterCondition,
    CampaignCreateTikTokCondition,
    CampaignCreateTwitterConfirm,
    CampaignCreateInstagramCondition,
    CampaignCreateInstagramConfirm,
    CampaignCreateCompleted
  },
  computed: {
    ...mapState('campaign_dialog', ['form', 'mode', 'step']),
    ...mapGetters('campaign_dialog', ['is_next_disabled', 'is_form_change']),
    ...mapState('system', ['user'])
  },
  methods: {
    ...mapActions('campaign_dialog', [
      'setCampaignId',
      'setMode',
      'fetchCampaign',
      'fetchCampaignAccounts',
      'reset',
      'getDefaultKeywords',
      'nextStep',
      'prevStep'
    ]),
    ...mapActions('campaign', ['fetchCampaigns'])
  }
})
export default class DialogCampaignCreate extends Vue {
  form!: CampaignDialogState['form']
  mode!: CampaignDialogState['mode']
  step!: CampaignDialogState['step']
  is_next_disabled!: CampaignDialogGetters['is_next_disabled']
  is_form_change!: CampaignDialogGetters['is_form_change']
  setCampaignId!: CampaignDialogActions['setCampaignId']
  setMode!: CampaignDialogActions['setMode']
  fetchCampaign!: CampaignDialogActions['fetchCampaign']
  fetchCampaignAccounts!: CampaignDialogActions['fetchCampaignAccounts']
  getDefaultKeywords!: CampaignDialogActions['getDefaultKeywords']
  reset!: CampaignDialogActions['reset']
  nextStep!: CampaignDialogActions['nextStep']
  prevStep!: CampaignDialogActions['prevStep']
  user!: SystemState['user']
  fetchCampaigns!: () => void

  visible = false
  is_campaign_created = false
  is_first_display_closed = false
  is_loading = false

  get title() {
    if (this.is_first_display) {
      return 'はじめに'
    }

    return 'キャンペーンの新規作成'
  }

  get step_options() {
    return ['アカウント', 'タイトル', '期間', '応募条件', '確認']
  }

  get is_create() {
    return this.mode === 'create'
  }

  get is_first_display() {
    return this.user && this.user.isFirstDisplay && !this.is_first_display_closed
  }

  get is_twitter() {
    return this.form.target_sns === 'twitter'
  }

  get is_instagram() {
    return this.form.target_sns === 'instagram'
  }

  get is_tiktok() {
    return this.form.target_sns === 'tiktok'
  }

  get is_show_footer() {
    return this.step < 4
  }

  /**
   * ステップ
   */
  isStep(step: number) {
    return this.step === step
  }

  /**
   * ダイアログキャンペーンを開く
   */
  async open(campaign_id?: number) {
    this.visible = true
    this.is_loading = true

    await this.fetchCampaignAccounts()

    if (!campaign_id) {
      await this.setMode('create')
      await this.getDefaultKeywords()
    }

    if (campaign_id) {
      await this.setCampaignId(campaign_id)
      await this.setMode('copy')
      const response = await this.fetchCampaign()

      if (!response.data) {
        this.$notify({
          title: 'キャンペーンの取得に失敗しました。',
          message: '恐れ入りますが、時間をおいて再度お試しください。',
          customClass: 'danger'
        })

        this.visible = false
        this.is_campaign_created = false
        this.is_first_display_closed = false
        this.reset()
        return
      }
    }

    this.is_loading = false
  }

  /**
   * ダイアログキャンペーンを閉じる
   */
  close(value: string) {
    switch (this.step) {
      case 0:
        TrackingService.sendEvent(
          `click:キャンペーンの新規作成|アカウント|${value === 'cancel' ? 'キャンセル' : '閉じる'}`
        )
        break
      case 1:
        TrackingService.sendEvent(
          `click:キャンペーンの新規作成|タイトル|${value === 'cancel' ? 'キャンセル' : '閉じる'}`
        )
        break
      case 2:
        TrackingService.sendEvent(`click:キャンペーンの新規作成|期間|${value === 'cancel' ? 'キャンセル' : '閉じる'}`)
        break
      case 3:
        TrackingService.sendEvent(
          `click:キャンペーンの新規作成|応募条件(${this.form.target_sns === 'twitter' ? 'X' : 'IG'})|${
            value === 'cancel' ? 'キャンセル' : '閉じる'
          }`
        )
        break
      case 4:
        if (typeof value === 'function') TrackingService.sendEvent(`click:キャンペーンの新規作成|確認|閉じる`)
        break
      default:
        break
    }

    if (!this.is_form_change || this.is_campaign_created) {
      this.visible = false
      this.is_campaign_created = false
      this.is_first_display_closed = false
      this.reset()
      return
    }

    const confirm = window.confirm('編集内容が保存されていません。ダイアログを閉じますか？')

    if (!confirm) {
      return
    }

    this.visible = false
    this.is_campaign_created = false
    this.is_first_display_closed = false
    this.reset()
  }

  /**
   * キャンペーンの作成を完了
   */
  completedCreateCampaign() {
    this.fetchCampaigns()
    this.is_campaign_created = true

    if (this.form.campaign_type === 'web_instantwin') {
      this.close('')
    }
  }

  /**
   * キャンペーンの紹介を閉じる
   */
  closeCampaignCreateIntro() {
    this.is_first_display_closed = true
  }
}
