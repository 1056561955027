import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'

import { RootState } from './index'

const SET_COUNT = 'SET_COUNT'

export type SampleState = {
  count: number
}

export type SampleActions = {
  increment: (payload: number) => Promise<void>
  decrement: () => Promise<void>
}

const state: SampleState = {
  count: 0
}

const getters: GetterTree<SampleState, RootState> = {}

const mutations: MutationTree<SampleState> = {
  [SET_COUNT](state, payload: number) {
    state.count = payload
  }
}

const actions: ActionTree<SampleState, RootState> = {
  /**
   * インクリメント
   */
  async increment(context, payload: number) {
    context.commit(SET_COUNT, context.state.count + payload)
  },

  /**
   * デクリメント
   */
  async decrement(context) {
    context.commit(SET_COUNT, context.state.count - 1)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
} as Module<SampleState, RootState>
