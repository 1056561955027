/* @flow */
import { mapActions, mapState } from 'vuex'

import InputRangeForm from '@/client/components/basic/input_range_form'
import SelectForm from '@/client/components/basic/select_form'
import { TrackingService } from '@/client/services'

export default {
  components: {
    SelectForm,
    InputRangeForm
  },
  created() {
    this.accountName.value = this.params.accountName
    this.isJoinDatetime.value = this.params.isJoinDatetime
    this.isProfileImage.value = this.params.isProfileImage
    this.isDescription.value = this.params.isDescription
    this.isNewFollower.value = this.params.isNewFollower
    this.isPositive.value = this.params.isPositive
    this.isWarning.value = this.params.isWarning
    this.postType.value = this.params.postType
    this.isMedia.value = this.params.isMedia
    this.followersCount.value = this.params.followersCount
    this.followsRate.value = this.params.followsRate
    this.tweetsCount.value = this.params.tweetsCount
    this.postsCount.value = this.params.postsCount
    this.totalCampaignEntryCount.value = this.params.totalCampaignEntryCount
    this.totalCampaignWinningCount.value = this.params.totalCampaignWinningCount
  },
  data() {
    return {
      // アカウント名検索
      accountName: {
        value: '',
        label: this.$gettext('アカウント名検索'),
        placeholder: this.$gettext('検索(完全一致)'),
        onChange: (value: string) => {
          TrackingService.sendEvent('input:応募一覧（X）|アカウント名検索')

          this.accountName.value = value
        }
      },
      // 新規獲得フォロワー
      isNewFollower: {
        value: '',
        label: this.$gettext('新規獲得フォロワー'),
        icon: '',
        options: [
          {
            label: this.$gettext('指定なし'),
            value: ''
          },
          {
            label: this.$gettext('獲得フラグあり'),
            value: 1
          },
          {
            label: this.$gettext('獲得フラグなし'),
            value: 0
          }
        ],
        onChange: (value: string | number) => {
          TrackingService.sendEvent('select:応募一覧（X）|新規獲得フォロワー')

          this.isNewFollower.value = value
        }
      },
      // ポスト種別
      postType: {
        value: '',
        label: this.$gettext('ポスト種別'),
        icon: '',
        options: [
          {
            label: this.$gettext('指定なし'),
            value: ''
          },
          {
            label: this.$gettext('ポスト'),
            value: 'tweet'
          },
          {
            label: this.$gettext('公式リポスト'),
            value: 'retweet'
          },
          {
            label: this.$gettext('引用'),
            value: 'quote_tweet'
          },
          {
            label: this.$gettext('メンション'),
            value: 'mention'
          },
          {
            label: this.$gettext('返信'),
            value: 'reply'
          }
        ],
        onChange: (value: string) => {
          TrackingService.sendEvent('select:応募一覧（X）|ポスト種別')

          this.postType.value = value
        }
      },
      // メディア
      isMedia: {
        value: '',
        label: this.$gettext('メディア'),
        icon: '',
        options: [
          {
            label: this.$gettext('指定なし'),
            value: ''
          },
          {
            label: this.$gettext('メディアあり'),
            value: 1
          },
          {
            label: this.$gettext('メディアなし'),
            value: 0
          }
        ],
        onChange: (value: string | number) => {
          TrackingService.sendEvent('select:応募一覧（X）|メディア')

          this.isMedia.value = value
        }
      },
      // ネガティブ判定
      isWarning: {
        value: '',
        label: this.$gettext('ネガティブ判定'),
        icon: '',
        options: [
          {
            label: this.$gettext('指定なし'),
            value: ''
          },
          {
            label: this.$gettext('判定あり'),
            value: 1
          },
          {
            label: this.$gettext('判定なし'),
            value: 0
          }
        ],
        onChange: (value: string | number) => {
          TrackingService.sendEvent('select:応募一覧（X）|ネガティブ判定')

          this.isWarning.value = value
        }
      },
      // ポジティブ判定
      isPositive: {
        value: '',
        label: this.$gettext('ポジティブ判定'),
        icon: '',
        options: [
          {
            label: this.$gettext('指定なし'),
            value: ''
          },
          {
            label: this.$gettext('判定あり'),
            value: 1
          },
          {
            label: this.$gettext('判定なし'),
            value: 0
          }
        ],
        onChange: (value: string | number) => {
          TrackingService.sendEvent('select:応募一覧（X）|ポジティブ判定')

          this.isPositive.value = value
        }
      },
      // アカウント開設日
      isJoinDatetime: {
        value: '',
        label: this.$gettext('アカウント開設日'),
        icon: '',
        options: [
          {
            label: this.$gettext('指定なし'),
            value: ''
          },
          {
            label: this.$gettext('キャンペーン開始より前'),
            value: 1
          }
        ],
        onChange: (value: string | number) => {
          TrackingService.sendEvent('select:応募一覧（X）|アカウント開設日')

          this.isJoinDatetime.value = value
        }
      },
      // プロフィール画像
      isProfileImage: {
        value: '',
        label: this.$gettext('プロフィール画像'),
        icon: '',
        options: [
          {
            label: this.$gettext('指定なし'),
            value: ''
          },
          {
            label: this.$gettext('設定あり'),
            value: 1
          },
          {
            label: this.$gettext('設定なし'),
            value: 0
          }
        ],
        onChange: (value: string | number) => {
          TrackingService.sendEvent('select:応募一覧（X）|プロフィール画像')

          this.isProfileImage.value = value
        }
      },
      // 自己紹介
      isDescription: {
        value: '',
        label: this.$gettext('自己紹介'),
        icon: '',
        options: [
          {
            label: this.$gettext('指定なし'),
            value: ''
          },
          {
            label: this.$gettext('入力あり'),
            value: 1
          },
          {
            label: this.$gettext('入力なし'),
            value: 0
          }
        ],
        onChange: (value: string | number) => {
          TrackingService.sendEvent('select:応募一覧（X）|自己紹介')

          this.isDescription.value = value
        }
      },
      // フォロワー数
      followersCount: {
        value: ['', '']
      },
      // FF比率
      followsRate: {
        value: ['', '']
      },
      // ポスト数
      tweetsCount: {
        value: ['', '']
      },
      // 応募数
      postsCount: {
        value: ['', '']
      },
      // 累計参加数
      totalCampaignEntryCount: {
        value: ['', '']
      },
      // 累計当選数
      totalCampaignWinningCount: {
        value: ['', '']
      },
      isCheckHunterAccount: false,
      hunterFilteringStrength: 5
    }
  },
  computed: {
    ...mapState('twitterApplicant', {
      params: state => state.params,
      campaignAccountId: state => (state.campaign && state.campaign.account ? state.campaign.account.id : ''),
      isLoading: state => state.isLoading
    })
  },
  methods: {
    ...mapActions('twitterApplicant', ['setParams', 'fetchApplicants']),
    changeFollowersCount(val: number[]) {
      if (this.followersCount.value[0] !== val[0]) {
        TrackingService.sendEvent('input:応募一覧（X）|フォロワー数|以上')
      } else if (this.followersCount.value[1] !== val[1])
        TrackingService.sendEvent('input:応募一覧（X）|フォロワー数|未満')

      this.followersCount.value = val
    },
    changeFollowsRate(val: number[]) {
      if (this.followsRate.value[0] !== val[0]) {
        TrackingService.sendEvent('input:応募一覧（X）|FF比率|以上')
      } else if (this.followsRate.value[1] !== val[1]) TrackingService.sendEvent('input:応募一覧（X）|FF比率|未満')

      this.followsRate.value = val
    },
    changeTweetsCount(val: number[]) {
      if (this.tweetsCount.value[0] !== val[0]) {
        TrackingService.sendEvent('input:応募一覧（X）|ポスト数|以上')
      } else if (this.tweetsCount.value[1] !== val[1]) TrackingService.sendEvent('input:応募一覧（X）|ポスト数|未満')

      this.tweetsCount.value = val
    },
    changePostsCount(val: number[]) {
      if (this.postsCount.value[0] !== val[0]) {
        TrackingService.sendEvent('input:応募一覧（X）|応募数|以上')
      } else if (this.postsCount.value[1] !== val[1]) TrackingService.sendEvent('input:応募一覧（X）|応募数|未満')

      this.postsCount.value = val
    },
    changeTotalCampaignEntryCount(val: number[]) {
      if (this.totalCampaignEntryCount.value[0] !== val[0]) {
        TrackingService.sendEvent('input:応募一覧（X）|累計参加数|以上')
      } else if (this.totalCampaignEntryCount.value[1] !== val[1])
        TrackingService.sendEvent('input:応募一覧（X）|累計参加数|未満')

      this.totalCampaignEntryCount.value = val
    },
    changeTotalCampaignWinningCount(val: number[]) {
      if (this.totalCampaignWinningCount.value[0] !== val[0]) {
        TrackingService.sendEvent('input:応募一覧（X）|累計当選数|以上')
      } else if (this.totalCampaignWinningCount.value[1] !== val[1])
        TrackingService.sendEvent('input:応募一覧（X）|累計当選数|未満')

      this.totalCampaignWinningCount.value = val
    },
    async changeAdvanceFilter() {
      TrackingService.sendEvent('click:応募一覧（X）|詳細条件を追加して検索')

      const params: {
        accountName: string,
        isJoinDatetime: boolean,
        isProfileImage: boolean,
        isDescription: boolean,
        isNewFollower: boolean,
        isPositive: boolean,
        isWarning: boolean,
        postType: string,
        isMedia: boolean,
        followersCount: number,
        followsRate: number,
        tweetsCount: number,
        postsCount: number,
        totalCampaignEntryCount: number,
        totalCampaignWinningCount: number,
        hunterPoint?: number | null
      } = {
        accountName: this.accountName.value,
        isJoinDatetime: this.isJoinDatetime.value,
        isProfileImage: this.isProfileImage.value,
        isDescription: this.isDescription.value,
        isNewFollower: this.isNewFollower.value,
        isPositive: this.isPositive.value,
        isWarning: this.isWarning.value,
        postType: this.postType.value,
        isMedia: this.isMedia.value,
        followersCount: this.followersCount.value,
        followsRate: this.followsRate.value,
        tweetsCount: this.tweetsCount.value,
        postsCount: this.postsCount.value,
        totalCampaignEntryCount: this.totalCampaignEntryCount.value,
        totalCampaignWinningCount: this.totalCampaignWinningCount.value
      }

      if (this.isCheckHunterAccount) {
        params.hunterPoint = this.getHunterPoint(this.hunterFilteringStrength)
      } else params.hunterPoint = null

      await this.setParams(params)

      await this.fetchApplicants()
    },
    changeSwitchEvent() {
      TrackingService.sendEvent('click:応募一覧（X）|懸賞アカウントを除外する')
    },
    /**
     * 懸賞フィルタ強度を懸賞ポイントに変換
     */
    getHunterPoint(hunterFilteringStrength: number): number {
      switch (hunterFilteringStrength) {
        case 8:
          return -3
        case 7:
          return -2
        case 6:
          return -1
        case 5:
          return 0
        case 4:
          return 1
        case 3:
          return 2
        case 2:
          return 3
        case 1:
          return 4
        default:
          return 4
      }
    }
  }
}
