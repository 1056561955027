import { required } from 'vuelidate/lib/validators'
import { mapActions, mapState } from 'vuex'

import api from '@/client/core/api'

export default {
  data() {
    return {
      type: '',
      types: {
        follower: {
          text: 'フォロー'
        },
        winner: {
          text: '当選者'
        },
        candidate: {
          text: '候補者'
        },
        responder: {
          text: '回答者'
        },
        winner_contact: {
          text: '当選連絡'
        }
      },
      body: '',
      visible: false,
      loading: false,
      isSubmit: false
    }
  },
  computed: {
    ...mapState('tiktokApplicant', {
      prizeId: state => state.campaignPrize.id
    }),
    typeText() {
      if (!this.types[this.type]) {
        return ''
      }

      return this.types[this.type].text
    },
    isFormChanged() {
      return this.body.trim()
    },
    maxUserError() {
      if (!this.body.trim()) {
        return false
      }

      return this.body.trim().split('\n').length > 10000
    },
    disabled() {
      return this.$v.$invalid || !this.body.trim() || this.isSubmit || this.maxUserError
    }
  },
  methods: {
    ...mapActions('tiktokApplicant', ['fetchApplicants', 'fetchCampaignPrize']),

    /**
     * ダイアログをリセット
     */
    reset() {
      this.loading = false
      this.isSubmit = false
    },

    /**
     * フォームのサブミット
     */
    async submit() {
      if (this.isSubmit) {
        return
      }

      const value = this.body
      const applicants = value
        .replace(/^\s*[\r\n]/gm, '')
        .replace(/^\s+|\s+$/g, '')
        .replace(/ /g, '')
        .replace(/@/g, '')
        .split('\n')

      const confirm = window.confirm(
        this.$gettextInterpolate(
          this.$gettext(
            '%{inputRecord}件のレコードをインポートします。\n処理を実行してよろしいですか?\nこの操作は取り消しできません。'
          ),
          {
            inputRecord: applicants.length
          }
        )
      )

      if (!confirm) {
        return
      }
      this.isSubmit = true
      this.loading = true

      const params = {
        prizeId: this.prizeId,
        applicants,
        type: this.type
      }

      const result = await api.post('/tiktok_applicants/imports', params)

      this.loading = false
      setTimeout(() => {
        this.isSubmit = false
      }, 2000)

      // 残り当選者数が足りない場合はエラーを表示
      if (result && result.error && result.error.type === 'REMAIN_WINNER_OVER') {
        this.$notify({
          title: this.$gettext('残り当選者数が不足しています。'),
          message: this.$gettext('インポートする当選者数を確認してください。'),
          customClass: 'danger',
          duration: 5000
        })
        this.fetchCampaignPrize()
        this.fetchApplicants()
        return
      }

      // 失敗した場合はエラーを表示
      if (result.data === undefined) {
        this.$notify({
          title: this.$gettext(`${this.typeText}インポートに失敗しました。`),
          message: this.$gettext('恐れ入りますが、時間を置いて再度お試しください。'),
          customClass: 'danger',
          duration: 5000
        })
        return
      }

      // 成功メッセージを表示
      this.$notify({
        title: this.$gettextInterpolate(this.$gettext(`%{importCount}件の${this.typeText}をインポートしました。`), {
          importCount: result.data
        }),
        customClass: 'success',
        duration: 5000
      })

      this.fetchCampaignPrize()
      this.fetchApplicants()
      this.close()
      this.body = ''
    },

    /**
     * ダイアログを開く
     */
    open(type: string) {
      this.type = type
      this.body = ''
      this.$v.$reset()
      this.visible = true
      this.reset()
    },

    /**
     * ダイアログを閉じる
     */
    close() {
      this.visible = false
    },

    hide() {
      if (this.isFormChanged) {
        const message = this.$gettext('編集内容が保存されていません。ダイアログを閉じてよろしいですか?')
        const confirm = window.confirm(message)

        if (!confirm) {
          return
        }
      }

      this.visible = false
    }
  },
  validations: {
    body: {
      required
    }
  }
}
