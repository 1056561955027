/* @flow */
import { mapActions, mapState } from 'vuex'

import Icon from '@/client/components/basic/icon'
import api from '@/client/core/api'
import { TikTokAuthService } from '@/client/core/tiktok_auth'
import TwitterAuthService from '@/client/core/twitter_auth'

export default {
  components: {
    Icon
  },
  data() {
    return {
      visible: false,
      title: this.$gettext('キャンペーン実施アカウントの追加'),
      authType: '',
      twitterAccount: null,
      tiktokAccount: null,
      isAuth: false,
      isSubmit: false
    }
  },
  computed: {
    ...mapState('system', {
      currentGroupId: state => state.currentGroup.id,
      allowInstantwin: state => state.currentGroup.allowInstantwin
    }),
    canAddAccount() {
      if (this.isSubmit) {
        return false
      }

      if (this.authType === 'twitter' && this.twitterAccount) {
        return true
      }

      if (this.authType === 'tiktok' && this.tiktokAccount) {
        return true
      }

      return false
    }
  },
  methods: {
    ...mapActions('settingGroup', ['fetchAccounts']),
    /**
     * 認証を行う
     */
    async startTwitterAuth() {
      this.twitterAccount = null
      this.isAuth = false
      this.authType = 'twitter'
      const account = await TwitterAuthService.execAuth()

      if (!account) {
        this.$notify({
          title: this.$gettext('認証に失敗しました。'),
          message: this.$gettext('恐れ入りますが、時間を置いて再度お試しください。'),
          customClass: 'danger',
          duration: 5000
        })
        return
      }

      const result = await api.get('/twitter_campaign_accounts/' + account.id + '/exists', {
        params: { groupId: this.currentGroupId }
      })

      if (result.data === true) {
        return this.$notify({
          title: this.$gettextInterpolate(this.$gettext('%{name}はすでに登録済みです。'), { name: account.name }),
          customClass: 'danger',
          duration: 5000
        })
      }

      this.twitterAccount = account

      this.isAuth = true
    },
    /**
     * Tiktok認証を行う
     */
    async startTiktokAuth() {
      this.twitterAccount = null
      this.isAuth = false
      this.authType = 'tiktok'
      const callback = await TikTokAuthService.openAuthWindow()

      if (callback.type !== 'SUCCESS') {
        this.$notify({
          title: this.$gettext('認証に失敗しました。'),
          message: this.$gettext('恐れ入りますが、時間を置いて再度お試しください。'),
          customClass: 'danger',
          duration: 5000
        })
        return
      }

      const ttAccount = await TikTokAuthService.getAccountInfo(callback, this.currentGroupId)

      if (!ttAccount && !ttAccount.data) {
        return this.$notify({
          title: this.$gettext('認証に失敗しました。'),
          message: this.$gettext('恐れ入りますが、時間を置いて再度お試しください。'),
          customClass: 'danger',
          duration: 5000
        })
      }

      if (ttAccount.data.isExist) {
        return this.$notify({
          title: this.$gettextInterpolate(this.$gettext('%{name}はすでに登録済みです。'), {
            name: ttAccount.data.name
          }),
          customClass: 'danger',
          duration: 5000
        })
      }

      this.tiktokAccount = ttAccount.data

      this.isAuth = true
    },

    /**
     * アカウントを追加
     */
    async addAccount() {
      if (this.authType === 'twitter') {
        await this.addTwitterAccount()
      }

      if (this.authType === 'tiktok') {
        await this.addTikTokAccount()
      }

      // アカウント一覧を取得
      this.fetchAccounts(this.currentGroupId)
      this.close()
    },

    /**
     * キャンペーン実施TWアカウントを追加
     */
    async addTwitterAccount() {
      if (this.isSubmit) {
        return
      }

      const params = {
        ...this.twitterAccount,
        accountId: this.twitterAccount.id,
        groupId: this.currentGroupId
      }

      this.isSubmit = true

      const result = await api.post('/twitter_campaign_accounts', params)

      this.isSubmit = false

      if (result.error && result.error.type === 'DUPLICATE') {
        this.$notify({
          title: this.$gettextInterpolate(this.$gettext('%{name}はすでに登録済みです。'), {
            name: this.twitterAccount.name
          }),
          customClass: 'danger',
          duration: 5000
        })
        return
      }
      if (result.error && result.error.type === 'MAX_ACCOUNT_OVER') {
        this.$notify({
          title: this.$gettext('これ以上アカウントを追加できません。'),
          message: this.$gettext('グループの登録可能アカウント数を超えています。'),
          customClass: 'danger',
          duration: 5000
        })
        return
      }

      if (!result.data) {
        this.$notify({
          title: this.$gettext('アカウント追加処理に失敗しました。'),
          message: this.$gettext('恐れ入りますが、時間を置いて再度お試しください。'),
          customClass: 'danger',
          duration: 5000
        })
        return
      }

      // 成功メッセージを表示
      this.$notify({
        title: this.$gettext('アカウントを追加しました。'),
        message: this.$gettextInterpolate(this.$gettext('%{name}を追加しました。'), { name: this.twitterAccount.name }),
        customClass: 'success',
        duration: 5000
      })
    },

    /**
     * キャンペーン実施TikTokアカウントを追加
     */
    async addTikTokAccount() {
      if (this.isSubmit) {
        return
      }

      const body = {
        accessToken: this.tiktokAccount.accessToken,
        refreshToken: this.tiktokAccount.refreshToken,
        groupId: this.currentGroupId
      }

      this.isSubmit = true

      const result = await api.post('/tiktok_campaign_accounts', body)

      this.isSubmit = false

      if (result.error && result.error.type === 'DUPLICATE') {
        this.$notify({
          title: this.$gettextInterpolate(this.$gettext('%{name}はすでに登録済みです。'), {
            name: this.tiktokAccount.name
          }),
          customClass: 'danger',
          duration: 5000
        })
        return
      }
      if (result.error && result.error.type === 'MAX_ACCOUNT_OVER') {
        this.$notify({
          title: this.$gettext('これ以上アカウントを追加できません。'),
          message: this.$gettext('グループの登録可能アカウント数を超えています。'),
          customClass: 'danger',
          duration: 5000
        })
        return
      }

      if (!result.data) {
        this.$notify({
          title: this.$gettext('アカウント追加処理に失敗しました。'),
          message: this.$gettext('恐れ入りますが、時間を置いて再度お試しください。'),
          customClass: 'danger',
          duration: 5000
        })
        return
      }

      // 成功メッセージを表示
      this.$notify({
        title: this.$gettext('アカウントを追加しました。'),
        message: this.$gettextInterpolate(this.$gettext('%{name}を追加しました。'), { name: this.tiktokAccount.name }),
        customClass: 'success',
        duration: 5000
      })
    },

    /**
     * Instagram1回目の認証
     */
    async startInstagramAuth() {
      this.authType = 'instagram'
      this.$emit('next')
    },

    /**
     * ダイアログをあく
     */
    open() {
      this.visible = true
      this.twitterAccount = null
      this.isAuth = false
    },
    /**
     * ダイアログを閉じる
     */
    close() {
      this.visible = false
    }
  }
}
