import { render, staticRenderFns } from "./instantwin_message_dialog.vue?vue&type=template&id=92f27632&scoped=true"
import script from "./instantwin_message_dialog.component?vue&type=script&lang=js&external"
export * from "./instantwin_message_dialog.component?vue&type=script&lang=js&external"
import style0 from "./instantwin_message_dialog.scss?vue&type=style&index=0&id=92f27632&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "92f27632",
  null
  
)

export default component.exports