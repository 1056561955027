/* @flow */
import api from '@/client/core/api'
import { type TCampaign, type TCampaignPrize } from '@/typings/api/publics/campaigns.type'
import {
  type TGetTikTokCampaignAnalyticsParams as TParams,
  type TTikTokCampaignAnalytic,
  type TTikTokTrafficSource
} from '@/typings/api/publics/tiktok_campaign_analytics.type'

const SET_CAMPAIGN_ANALYTICS = 'SET_CAMPAIGN_ANALYTICS'
const SET_CAMPAIGN_TRAFFIC_SOURCE = 'SET_CAMPAIGN_TRAFFIC_SOURCE'
const SET_CAMPAIGN = 'SET_CAMPAIGN'
const SET_PRIZES = 'SET_PRIZES'
const SET_PARAMS = 'SET_PARAMS'
const SET_ERROR = 'SET_ERROR'
const SET_LOADING = 'SET_LOADING'
const RESET = 'RESET'

type TTikTokAnalyticsPublicStore = {
  campaignAnalytics: TTikTokCampaignAnalytic[],
  trafficSource: TTikTokTrafficSource[],
  campaign: TCampaign | null,
  prizes: TCampaignPrize[],
  params: TParams,
  error: boolean,
  loading: boolean
}

type Context = {
  commit: Function,
  getters: any,
  state: TTikTokAnalyticsPublicStore,
  dispatch: Function
}

const initState: TTikTokAnalyticsPublicStore = {
  campaignAnalytics: [],
  trafficSource: [],
  campaign: null,
  prizes: [],
  params: {
    hash: '',
    prizeId: '',
    startDatetime: '',
    endDatetime: ''
  },
  error: false,
  loading: false
}

// states
const state = { ...initState }

// getters
const getters = {}

// actions
const actions = {
  /**
   * キャンペーン分析データを取得
   * @param {Context} context
   */
  async fetchCampaignAnalytics(context: Context) {
    const params = context.state.params

    context.commit(SET_ERROR, false)
    context.commit(SET_LOADING, true)

    // システムストアのローディングをセット
    context.dispatch('system/setLoading', true, { root: true })

    const result = await api.get('/publics/tiktok_campaign_analytics', { params })

    context.commit(SET_LOADING, false)

    // システムストアのローディングをセット
    context.dispatch('system/setLoading', false, { root: true })

    if (!result.data) {
      context.commit(SET_ERROR, true)
      return
    }

    context.commit(SET_CAMPAIGN_ANALYTICS, result.data.analytics)
    context.commit(SET_CAMPAIGN_TRAFFIC_SOURCE, result.data.impressions)
  },

  /**
   * キャンペーン情報の取得
   * @param {Context} context
   */
  async fetchCampaign(context: Context) {
    context.commit(SET_ERROR, false)

    const result = await api.get('/publics/campaigns/' + context.state.params.hash)

    if (!result.data) {
      context.commit(SET_ERROR, true)
      return
    }

    context.commit(SET_CAMPAIGN, result.data)
  },

  /**
   * 応募グループ一覧の取得
   * @param {Context} context
   */
  async fetchPrizes(context: Context) {
    context.commit(SET_ERROR, false)

    const result = await api.get(`/publics/campaigns/${context.state.params.hash}/prizes`)

    if (!result.data) {
      context.commit(SET_ERROR, true)
      return
    }

    context.commit(SET_PRIZES, result.data)
  },

  /**
   * パラメーターを設定
   * @param {Context} context
   * @param {TParams} params
   */
  setParams(context: Context, params: TParams) {
    context.commit(SET_PARAMS, { ...context.state.params, ...params })
  },

  /**
   * ステータスを初期化にする
   * @param {Context} context
   */
  async reset(context: Context) {
    context.commit(RESET)
  }
}

// mutations
const mutations = {
  /**
   * キャンペーン分析データを取得
   * @param {TTikTokAnalyticsPublicStore} state
   * @param {TTikTokCampaignAnalytic[]} payload
   */
  [SET_CAMPAIGN_ANALYTICS](state: TTikTokAnalyticsPublicStore, payload: TTikTokCampaignAnalytic[]) {
    state.campaignAnalytics = payload
  },

  /**
   * キャンペーン分析データを取得
   * @param {TTikTokAnalyticsPublicStore} state
   * @param {TTikTokTrafficSource[]} payload
   */
  [SET_CAMPAIGN_TRAFFIC_SOURCE](state: TTikTokAnalyticsPublicStore, payload: TTikTokTrafficSource[]) {
    state.trafficSource = payload
  },

  /**
   * キャンペーン情報の取得
   * @param {TTikTokAnalyticsPublicStore} state
   * @param {TCampaign} payload
   */
  [SET_CAMPAIGN](state: TTikTokAnalyticsPublicStore, payload: TCampaign) {
    state.campaign = payload
  },

  /**
   * 応募グループ一覧の取得
   * @param {TTikTokAnalyticsPublicStore} state
   * @param {TCampaignPrize} payload
   */
  [SET_PRIZES](state: TTikTokAnalyticsPublicStore, payload: TCampaignPrize[]) {
    state.prizes = payload
  },

  /**
   * パラメーターを設定
   * @param {TTikTokAnalyticsPublicStore} state
   * @param {any} payload
   */
  [SET_PARAMS](state: TTikTokAnalyticsPublicStore, payload: any) {
    state.params = payload
  },

  /**
   * 初回ロード終了フラグをセット
   * @param {TTikTokAnalyticsPublicStore} state
   * @param {boolean} payload
   */
  [SET_ERROR](state: TTikTokAnalyticsPublicStore, payload: boolean) {
    state.error = payload
  },

  /**
   * エラーフラグをセット
   * @param {TTikTokAnalyticsPublicStore} state
   * @param {boolean} payload
   */
  [SET_LOADING](state: TTikTokAnalyticsPublicStore, payload: boolean) {
    state.error = payload
  },

  /**
   * ステータスを初期化にする
   * @param {TTikTokAnalyticsPublicStore} state
   */
  [RESET](state: TTikTokAnalyticsPublicStore) {
    const initStateData: any = initState
    Object.keys(initStateData).forEach(key => {
      state[key] = initStateData[key]
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
