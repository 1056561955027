/* @flow */
import { mapActions } from 'vuex'

import ApplicantGridItem from '@/client/components/basic/applicant_grid_item'
import ApplicantName from '@/client/components/basic/applicant_name'
import Icon from '@/client/components/basic/icon'
import Pagination from '@/client/components/basic/pagination'

export default {
  props: {
    applicants: Array,
    snsType: {
      type: String,
      default: 'instagram'
    },
    options: Object
  },
  components: {
    Pagination,
    ApplicantName,
    Icon,
    ApplicantGridItem
  },
  methods: {
    ...mapActions('instagramApplicant', ['fetchApplicants']),

    /**
     * フラグ変更処理
     * @param {{type: string, value: any}} payload
     */
    changeFlag(payload: { type: string, value: any }) {
      const newPayload = {}

      newPayload.applicantId = payload.value.applicantId
      switch (payload.type) {
        case 'candidate':
          newPayload.type = 'is_candidate'
          newPayload.value = payload.value.isCandidate
          break
        case 'follower':
          newPayload.type = 'is_follower'
          newPayload.value = payload.value.isFollowing
          break
        case 'winner':
          newPayload.type = 'is_winner'
          newPayload.value = payload.value.isWinner
          break
        case 'responder':
          newPayload.type = 'is_responder'
          newPayload.value = payload.value.isResponder
          break
        case 'winnerContact':
          newPayload.type = 'is_winner_contact'
          newPayload.value = payload.value.isWinnerContact
          break
      }
      this.$emit('change_flag', newPayload)
    },

    onPaging(payload: { pagingNo: number, limitCount?: number }) {
      this.$emit('paging', payload)

      this.fetchApplicants()
    }
  }
}
