/* @flow */
import TwitterAnalyticsPublicCampaign from '@/client/components/TwitterAnalyticsPublic/TwitterAnalyticsPublicCampaign'
import TwitterAnalyticsPublicChartFollower from '@/client/components/TwitterAnalyticsPublic/TwitterAnalyticsPublicChartFollower'

export default {
  components: { TwitterAnalyticsPublicCampaign, TwitterAnalyticsPublicChartFollower },
  data() {
    return {
      activeTab: 'campaigns'
    }
  }
}
