/* @flow */
import { mapActions, mapState } from 'vuex'

import SelectForm from '@/client/components/basic/select_form'
import { TrackingService } from '@/client/services'

export default {
  components: {
    SelectForm
  },
  data() {
    return {
      // フォロー
      isFollowing: {
        value: '',
        label: this.$gettext('フォロー'),
        icon: 'check-circle',
        size: 'small',
        options: [
          {
            label: this.$gettext('指定なし'),
            value: ''
          },
          {
            label: this.$gettext('フォローあり'),
            value: 1
          },
          {
            label: this.$gettext('フォローなし'),
            value: 0
          }
        ],
        onChange: (value: number) => {
          TrackingService.sendEvent('select:応募一覧（IG）|フォロー')

          this.isFollowing.value = value
          if (this.params.isFollowing === value) {
            return
          }
          this.setParams({ isFollowing: value })
          this.fetchApplicants()
        }
      },
      // 当選者
      isWinner: {
        value: '',
        label: this.$gettext('当選者'),
        icon: 'trophy',
        size: 'small',
        options: [
          {
            label: this.$gettext('指定なし'),
            value: ''
          },
          {
            label: this.$gettext('当選フラグあり'),
            value: 1
          },
          {
            label: this.$gettext('当選フラグなし'),
            value: 0
          }
        ],
        onChange: (value: number) => {
          TrackingService.sendEvent('select:応募一覧（IG）|当選者')

          this.isWinner.value = value
          if (this.params.isWinner === value) {
            return
          }
          this.setParams({ isWinner: value })
          this.fetchApplicants()
        }
      },
      // 回答者
      isResponder: {
        value: '',
        label: this.$gettext('回答者'),
        icon: 'clipboard-check',
        size: 'small',
        options: [
          {
            label: this.$gettext('指定なし'),
            value: ''
          },
          {
            label: this.$gettext('回答フラグあり'),
            value: 1
          },
          {
            label: this.$gettext('回答フラグなし'),
            value: 0
          }
        ],
        onChange: (value: number) => {
          TrackingService.sendEvent('select:応募一覧（IG）|回答者')

          this.isResponder.value = value
          if (this.params.isResponder === value) {
            return
          }
          this.setParams({ isResponder: value })
          this.fetchApplicants()
        }
      },
      // 候補者
      isCandidate: {
        value: '',
        label: this.$gettext('候補者'),
        icon: 'star',
        size: 'small',
        options: [
          {
            label: this.$gettext('指定なし'),
            value: ''
          },
          {
            label: this.$gettext('候補者フラグあり'),
            value: 1
          },
          {
            label: this.$gettext('候補者フラグなし'),
            value: 0
          }
        ],
        onChange: (value: number) => {
          TrackingService.sendEvent('select:応募一覧（IG）|候補者')

          this.isCandidate.value = value
          if (this.params.isCandidate === value) {
            return
          }
          this.setParams({ isCandidate: value })
          this.fetchApplicants()
        }
      }
    }
  },
  computed: {
    ...mapState('instagramApplicant', {
      showAdvanceFilter: state => state.showAdvanceFilter,
      params: state => state.params,
      campaign: state => {
        if (state.campaign) {
          return state.campaign
        }
        return {}
      }
    })
  },
  methods: {
    ...mapActions('instagramApplicant', ['toggleShowAdvanceFilter', 'setParams', 'fetchApplicants']),

    onChangeBackEvent() {
      TrackingService.sendEvent('click:応募一覧（X）|戻る')

      this.$router.push('/instagram_campaigns/' + this.$route.params.campaignId + '/prizes')
    }
  }
}
