import { mapActions, mapState } from 'vuex'

import api from '@/client/core/api'

export default {
  data() {
    return {
      isSubmit: false
    }
  },
  computed: {
    ...mapState('twitterInstantwinSerialCode', {
      campaignId: state => state.campaignId
    })
  },
  methods: {
    ...mapActions('twitterInstantwinSerialCode', ['fetchSerialCodeList']),
    ...mapActions('twitterInstantwinMessage', ['fetchInstantwinMessages']),

    /**
     * Change screen
     * @param {string} tab
     */
    changeTab(tab: string) {
      this.$emit('change_tab', tab)
    },

    /**
     * Resets serial code
     */
    resetsSerialCode() {
      if (this.isSubmit) return
      this.postButton({
        campaignId: this.campaignId
      })
    },

    /**
     * 使用済みシリアルコードの一括解除
     * @param {Object} params 送信データ
     */
    async postButton(params: any) {
      this.isSubmit = true

      const response = await api.post(`/twitter_instantwin_serial_codes/used_resets`, params)

      setTimeout(() => {
        this.isSubmit = false
      }, 2000)

      if (response.data) {
        const title = this.$gettextInterpolate(this.$gettext('使用済みのシリアルコードの割り当てを一括解除しました。'))

        this.$notify({
          title,
          customClass: 'success',
          duration: 5000
        })

        this.changeTab('serial-code-table')
        await this.fetchInstantwinMessages()
        return
      }

      this.$notify({
        title: this.$gettext('使用済みのシリアルコードの割り当て解除に失敗しました。'),
        message: this.$gettext('恐れ入りますが、時間をおいて再度お試しください。'),
        customClass: 'danger',
        duration: 5000
      })
    }
  }
}
