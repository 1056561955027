/* @flow */
import { mapState } from 'vuex'

import Icon from '@/client/components/basic/icon'
import api from '@/client/core/api'
import { TrackingService } from '@/client/services'

export default {
  components: {
    Icon
  },
  data() {
    return {
      isMaxCampaignCount: false,
      maxCampaignCount: 0,
      maxCampaignCountError: '',
      isMaxMonthlyApplicantCount: false,
      maxMonthlyApplicantCount: 0,
      maxMonthlyApplicantCountError: '',
      isMaxMonthlyDmCount: false,
      maxMonthlyDmCount: 0,
      maxMonthlyDmCountError: '',
      isSubmit: false,
      isLoading: false,
      oldMaxCampaignCount: 0,
      oldMaxMonthlyApplicantCount: 0,
      oldMaxMonthlyDmCount: 0
    }
  },
  computed: {
    ...mapState('settingGroup', {
      group: state => state.group
    }),
    isDisabled() {
      if (
        (this.isMaxCampaignCount && this.maxCampaignCountError) ||
        (this.isMaxMonthlyApplicantCount && this.maxMonthlyApplicantCountError) ||
        (this.isMaxMonthlyDmCount && this.maxMonthlyDmCountError)
      ) {
        return true
      }

      return false
    }
  },
  watch: {
    group() {
      this.isMaxCampaignCount = this.group.maxCampaignCount > 0
      this.isMaxMonthlyApplicantCount = this.group.maxMonthlyApplicantCount > 0
      this.isMaxMonthlyDmCount = this.group.maxMonthlyDmCount > 0
      this.maxCampaignCount = this.group.maxCampaignCount
      this.maxMonthlyApplicantCount = this.group.maxMonthlyApplicantCount
      this.maxMonthlyDmCount = this.group.maxMonthlyDmCount
    }
  },
  methods: {
    /**
     * キャンペーン同時開催数のスイッチの変更
     * @param {boolean} value
     */
    toggleIsMaxCampaign(value: boolean) {
      TrackingService.sendEvent('switch:グループ設定|利用制限|最大キャンペーン同時開催数を制限')

      if (value) {
        this.maxCampaignCount = this.group.currentCampaignCount || 1
        this.handleMaxCampaignChange(this.maxCampaignCount)
      }
    },

    /**
     * 月間最大収集応募者数のスイッチの変更
     * @param {boolean} value
     */
    toggleIsMaxMonthlyApplicant(value: boolean) {
      TrackingService.sendEvent('switch:グループ設定|利用制限|月間最大収集応募者件数を制限')

      if (value) {
        this.maxMonthlyApplicantCount =
          this.group.currentMonthlyApplicantCount > 100000 ? this.group.currentMonthlyApplicantCount : 100000
        this.handleMaxMonthlyApplicantChange(this.maxMonthlyApplicantCount)
      }
    },

    /**
     * 月間最大DM・メンション配信件数のスイッチの変更
     * @param {boolean} value
     */
    toggleIsMaxMonthlyDm(value: boolean) {
      TrackingService.sendEvent('switch:グループ設定|利用制限|月間最大DM配信件数を制限')

      if (value) {
        this.maxMonthlyDmCount = this.group.currentMonthlyDmCount > 100 ? this.group.currentMonthlyDmCount : 100
        this.handleMaxMonthlyDm(this.maxMonthlyDmCount)
      }
    },

    /**
     * キャンペーン同時開催数の変更
     * @param {number} value
     */
    handleMaxCampaignChange(value: number) {
      if (!this.oldMaxCampaignCount) this.oldMaxCampaignCount = this.group.maxCampaignCount

      if (value === this.oldMaxCampaignCount + 1) {
        TrackingService.sendEvent('click:グループ設定|利用制限|最大キャンペーン同時開催数を制限|UP')
      } else if (value === this.oldMaxCampaignCount - 1) {
        TrackingService.sendEvent('click:グループ設定|利用制限|最大キャンペーン同時開催数を制限|DOWN')
      } else TrackingService.sendEvent('input:グループ設定|利用制限|最大キャンペーン同時開催数を制限')

      this.oldMaxCampaignCount = value

      if (isNaN(value)) {
        setTimeout(() => {
          this.maxCampaignCount = this.group.currentCampaignCount
        }, 100)
        return
      }

      if (value === 0 && this.isMaxCampaignCount) {
        this.maxCampaignCountError = '0以上の数値を入力してください。'
        return
      }

      if (value < this.group.currentCampaignCount && this.isMaxCampaignCount) {
        this.maxCampaignCountError = '今月のキャンペーン同時開催数より大きい数値を設定してください。'
        return
      }

      this.maxCampaignCountError = ''
    },

    /**
     * 月間最大収集応募者数の変更
     * @param {number} value
     */
    handleMaxMonthlyApplicantChange(value: number) {
      if (!this.oldMaxMonthlyApplicantCount) this.oldMaxMonthlyApplicantCount = this.group.maxMonthlyApplicantCount

      if (value === this.oldMaxMonthlyApplicantCount + 100) {
        TrackingService.sendEvent('click:グループ設定|利用制限|月間最大収集応募者件数を制限|UP')
      } else if (value === this.oldMaxMonthlyApplicantCount - 100) {
        TrackingService.sendEvent('click:グループ設定|利用制限|月間最大収集応募者件数を制限|DOWN')
      } else TrackingService.sendEvent('input:グループ設定|利用制限|月間最大収集応募者件数を制限')

      this.oldMaxMonthlyApplicantCount = value

      if (isNaN(value)) {
        setTimeout(() => {
          this.maxMonthlyApplicantCount = this.group.currentMonthlyApplicantCount
        }, 100)
        return
      }

      if (value === 0) {
        this.maxMonthlyApplicantCountError = '0以上の数値を入力してください。'
        return
      }

      if (value < this.group.currentMonthlyApplicantCount) {
        this.maxMonthlyApplicantCountError = '今月の月間最大収集応募者件数より大きい数値を設定してください。'
        return
      }

      this.maxMonthlyApplicantCountError = ''
    },

    /**
     * 月間最大DM・メンション配信件数の変更
     * @param {number} value
     */
    handleMaxMonthlyDm(value: number) {
      if (!this.oldMaxMonthlyDmCount) this.oldMaxMonthlyDmCount = this.group.maxMonthlyDmCount

      if (value === this.oldMaxMonthlyDmCount + 10) {
        TrackingService.sendEvent('click:グループ設定|利用制限|月間最大DM配信件数を制限|UP')
      } else if (value === this.oldMaxMonthlyDmCount - 10) {
        TrackingService.sendEvent('click:グループ設定|利用制限|月間最大DM配信件数を制限|DOWN')
      } else TrackingService.sendEvent('input:グループ設定|利用制限|月間最大DM配信件数を制限')

      this.oldMaxMonthlyDmCount = value

      if (isNaN(value)) {
        setTimeout(() => {
          this.maxMonthlyDmCount = this.group.currentMonthlyDmCount
        }, 100)
        return
      }

      if (value === 0) {
        this.maxMonthlyDmCountError = '0以上の数値を入力してください。'
        return
      }

      if (value < this.group.currentMonthlyDmCount) {
        this.maxMonthlyDmCountError = '今月の月間最大DM配信件数より大きい数値を設定してください。'
        return
      }

      this.maxMonthlyDmCountError = ''
    },

    /**
     * グループ設定を保存
     */
    async save() {
      TrackingService.sendEvent('click:グループ設定|利用制限|保存')

      if (this.isSubmit) {
        return
      }

      const params = {
        maxCampaignCount: this.isMaxCampaignCount ? this.maxCampaignCount : 0,
        maxMonthlyApplicantCount: this.isMaxMonthlyApplicantCount ? this.maxMonthlyApplicantCount : 0,
        maxMonthlyDmCount: this.isMaxMonthlyDmCount ? this.maxMonthlyDmCount : 0
      }

      this.isSubmit = true
      this.isLoading = true

      const result = await api.put(`groups/${this.group.id}`, params)

      this.isSubmit = false
      this.isLoading = false

      if (!result.data || result.error) {
        this.$notify({
          title: this.$gettext('利用制限の変更に失敗しました。'),
          message: this.$gettext('恐れ入りますが、時間を置いて再度お試しください。'),
          customClass: 'danger',
          duration: 5000
        })
        return
      }

      this.$notify({
        title: this.$gettext('利用制限を変更しました。'),
        customClass: 'success',
        duration: 5000
      })
    }
  }
}
