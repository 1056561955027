






































































import moment from 'moment-timezone'
import { Component, Emit, Vue } from 'vue-property-decorator'
import { mapActions, mapState } from 'vuex'

import Icon from '@/client/components/_atoms/Icon.vue'
import Message from '@/client/components/_atoms/Message.vue'
import Panel from '@/client/components/_atoms/Panel.vue'
import Account from '@/client/components/_molecules/Account.vue'
import Tags from '@/client/components/_molecules/Tags.vue'
import { TIKTOK_ENTRY_ACTION_TYPES } from '@/client/constant'
import { TrackingService } from '@/client/services'
import { CampaignDialogActions, CampaignDialogState } from '@/client/store/campaign_dialog.module'
import { CAMPAIGN_START_DATETIME_INVALID, MAX_CAMPAIGN_COUNT_OVER } from '@/server/constants/error'

@Component({
  components: {
    Icon,
    Message,
    Panel,
    Account,
    Tags
  },
  computed: mapState('campaign_dialog', ['form', 'api_accounts']),
  methods: mapActions('campaign_dialog', ['prevStep', 'createCampaign'])
})
export default class CampaignCreateTikTokConfirm extends Vue {
  api_accounts!: CampaignDialogState['api_accounts']
  form!: CampaignDialogState['form']
  createCampaign!: CampaignDialogActions['createCampaign']

  is_submit = false

  get account() {
    return this.api_accounts.find(v => v.id === this.form.account_id && v.target_sns === 'tiktok')
  }

  get campaign_type_options() {
    return TIKTOK_ENTRY_ACTION_TYPES.label
  }

  get campaign_period() {
    const start_date = this.form.start_date
      ? moment(this.form.start_date).format('YYYY年MM月DD日') + ' ' + this.form.start_time
      : ''

    const end_date = this.form.end_date
      ? moment(this.form.end_date).format('YYYY年MM月DD日') + ' ' + this.form.end_time
      : ''

    return start_date + ' - ' + end_date
  }

  @Emit('cancel')
  cancel(payload) {
    return payload
  }

  /**
   * キャンペーンを保存する
   */
  async saveCampaign() {
    TrackingService.sendEvent('click:キャンペーンの新規作成|確認|保存')

    if (this.is_submit) {
      return
    }

    this.is_submit = true

    const result = await this.createCampaign()

    this.is_submit = false

    if (result.data && result.data.id) {
      this.$emit('complete', result.data.id)
      return
    }

    if (result.error && result.error.type === MAX_CAMPAIGN_COUNT_OVER.type) {
      this.$notify({
        title: 'キャンペーンの作成に失敗しました。',
        message: '同時開催可能件数を超えるため、指定の期間ではキャンペーンを開催できません。',
        customClass: 'danger'
      })
      return
    }

    if (result.error && result.error.type === CAMPAIGN_START_DATETIME_INVALID.type) {
      this.$notify({
        title: 'キャンペーンの作成に失敗しました。',
        message: 'キャンペーン開始日時は、現在日時より後を指定してください。',
        customClass: 'danger'
      })
      return
    }

    this.$notify({
      title: 'キャンペーンの作成に失敗しました。',
      message: '恐れ入りますが、時間をおいて再度お試しください。',
      customClass: 'danger'
    })
  }
}
