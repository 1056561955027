








































































































import { Component, Vue } from 'vue-property-decorator'
import { mapActions, mapState } from 'vuex'

import Icon from '@/client/components/_atoms/Icon.vue'
import Message from '@/client/components/_atoms/Message.vue'
import Panel from '@/client/components/_atoms/Panel.vue'
import Tips from '@/client/components/_atoms/Tips.vue'
import FormItemLabel from '@/client/components/_molecules/FormItemLabel.vue'
import API from '@/client/core/api'
import { ApplicantStore } from '@/client/store/tiktok_applicant.store'

@Component({
  components: {
    Icon,
    Message,
    Panel,
    Tips,
    FormItemLabel
  },
  computed: {
    ...mapState('tiktokApplicant', ['campaignPrize'])
  },
  methods: {
    ...mapActions('tiktokApplicant', ['fetchApplicants'])
  }
})
export default class DialogTikTokFollowCheck extends Vue {
  campaignPrize!: ApplicantStore['campaignPrize']
  fetchApplicants!: () => void

  $refs!: {
    upload: any
  }

  visible = false
  is_type_error = false
  is_read_error = false
  is_follow_check_error = false
  is_submit = false
  is_progress = false
  is_uploading = false
  temp_filename = ''
  temp_followers: string[] = []

  get disabled() {
    return !this.temp_followers.length && !this.is_submit
  }

  get loading() {
    return this.is_progress || this.is_uploading
  }

  get loading_text() {
    if (this.is_progress) return 'ファイルを検証しています。しばらくお待ちください。'
    if (this.is_uploading) return 'フォローチェックを実行しています。しばらくお待ちください。'
    return ''
  }

  /**
   * ダイアログを開く
   */
  open() {
    this.visible = true
  }

  /**
   * 有効なファイルかどうかを確認
   */
  async isValidFile(file) {
    this.is_read_error = false
    this.is_follow_check_error = false

    if (!file || !file.type || file.type !== 'application/json') {
      this.is_type_error = true
      this.is_progress = false

      return false
    }

    const file_data = await this.loadFile(file)

    if (!file_data) {
      this.is_type_error = true
      this.is_progress = false

      return false
    }

    const json = JSON.parse(file_data)

    if (!json) {
      this.is_type_error = true
      this.is_progress = false

      return false
    }

    return true
  }

  /**
   * ファイル読み込みエラー
   */
  errorReadFile() {
    this.is_type_error = false
    this.is_read_error = true
    this.is_follow_check_error = false
    this.is_progress = false
  }

  /**
   * ファイルの追加
   */
  async readFile(file) {
    this.is_progress = true

    const is_valid = await this.isValidFile(file.raw)

    if (!is_valid) {
      this.$refs.upload.clearFiles()
      return
    }

    const file_data = await this.loadFile(file.raw)

    const json = JSON.parse(file_data)

    this.temp_followers = json['Your Activity']?.['Follower']?.['FansList']?.map(v => v.UserName) || []

    if (this.temp_followers.length === 0) {
      this.is_type_error = true
      this.is_progress = false

      this.$refs.upload.clearFiles()
      return
    }

    this.temp_filename = file.name

    this.is_type_error = false
    this.is_read_error = false
    this.is_follow_check_error = false
    this.is_progress = false
  }

  /**
   * ファイル読み込み
   */
  loadFile(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader()

      fileReader.onload = (e: any) => {
        resolve(e.target.result)
      }

      fileReader.onerror = (e: any) => {
        reject(e)
      }

      fileReader.readAsText(file)
    })
  }

  /**
   * ファイルアップロードの取り消し
   */
  cancelFile() {
    this.$refs.upload.clearFiles()
    this.is_type_error = false
    this.is_read_error = false
    this.is_follow_check_error = false
    this.is_submit = false
    this.is_progress = false
    this.is_uploading = false
    this.temp_filename = ''
    this.temp_followers = []
  }

  /**
   * フォローチェックをインポートする
   */
  async importFollowCheck() {
    if (this.is_submit) {
      return
    }

    this.is_submit = true
    this.is_uploading = true

    const result = await API.put(`/v1/tiktok_applicants/${this.campaignPrize.id}/bulk_followers`, {
      followers: this.temp_followers
    })

    this.is_uploading = false

    setTimeout(() => {
      this.is_submit = false
    }, 2000)

    if (typeof result.data === 'undefined') {
      this.$notify({
        title: 'フォローチェックに失敗しました。',
        message: '',
        customClass: 'danger'
      })

      this.is_follow_check_error = true

      return
    }

    this.$notify({
      title: 'フォローチェックが完了しました。',
      message: '',
      customClass: 'success'
    })

    this.fetchApplicants()
    this.close()
  }

  /**
   * ダイアログを閉じる
   */
  close() {
    this.$refs.upload.clearFiles()
    this.is_type_error = false
    this.is_read_error = false
    this.is_follow_check_error = false
    this.is_submit = false
    this.is_progress = false
    this.is_uploading = false
    this.temp_filename = ''
    this.temp_followers = []
    this.visible = false
  }
}
