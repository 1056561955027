// キャンペーンステータス
export const CAMPAIGN_STATUS = {
  BEFORE_EVENT: 0,
  BEFORE_COLLECTION: 1,
  DURING_COLLECTION: 2,
  DURING_FINAL_COLLECTION: 3,
  COLLECTION_COMPLETED: 4
}

// インスタントウィンメッセージステータス
export const INSTANTWIN_MESSAGE_STATUS = {
  SCHEDULE: 0,
  ACTIVE: 1,
  FINISHED: 2
}

export const CAMPAIGN_TYPES = [
  { value: 'keyword', label: '通常キャンペーン' },
  { value: 'web_instantwin', label: 'WEBインスタントウィン' }
]

export const CAMPAIGN_APPLICATION_TYPES = [
  { value: 'retweet', label: 'フォロー&リポスト' },
  { value: 'tweet', label: 'フォロー&ポスト' },
  { value: 'mention', label: 'フォロー&メンション' }
]

export const WEB_INSTANTWIN_APPLICATION_TYPES = [
  { value: 'retweet', label: 'フォロー&リポスト&キャンペーン応募ページへの応募' },
  { value: 'mention', label: 'フォロー&メンション&キャンペーン応募ページへの応募' }
]

export const INSTAGRAM_ENTRY_ACTION_TYPES = [
  { value: 'mention', label: '画像投稿キャンペーン' },
  { value: 'comment', label: 'コメントキャンペーン' }
]

export const TWITTER_ENTRY_ACTION_TYPES = [
  { value: 'retweet', label: '公式リポスト' },
  { value: 'quote_tweet', label: '引用' },
  { value: 'retweet_and_quote_tweet', label: '全て' },
  { value: 'tweet', label: '通常' },
  { value: 'tweet_media', label: '画像または動画' },
  { value: 'tweet_conversational', label: 'カンバセーショナルカード' },
  { value: 'mention', label: '' }
]

export const TIKTOK_ENTRY_ACTION_TYPES = { value: 'comment', label: 'コメントキャンペーン' }

export const CARD_TYPE = [
  {
    value: 'all',
    label: '指定なし'
  },
  {
    value: 'website',
    label: 'ウェブサイト'
  },
  {
    value: 'app',
    label: 'アプリ'
  }
]

export const MEDIA_TYPE = [
  {
    value: 'image',
    label: '画像'
  },
  {
    value: 'video',
    label: '動画'
  },
  {
    value: 'carousel',
    label: 'カルーセル'
  }
]

// インスタントウィン落選となった理由
export const INSTANTWIN_LOSE_REASON = {
  exclude_account_create_date_within_period: 'アカウント開設日',
  exclude_default_profile_image: 'プロフィール画像なし',
  exclude_no_description: '自己紹介なし',
  min_followers: 'フォロワー数不足',
  min_tweets: 'ポスト数不足',
  exclude_description_keyword: '自己紹介キーワード',
  exclude_tweet_no_media: '応募ポストにメディアなし',
  is_ng: 'NGユーザー',
  exclude_multi_winner: '当キャンペーン当選実績あり',
  exclude_other_campaign_winner: '別キャンペーン当選実績あり',
  duplicate_entry: '重複応募',
  exclude_other_message_multi_winner: '別メッセージ当選実績あり',
  exclude_message_multi_winner: '当メッセージ当選実績あり',
  hunter_account: '懸賞アカウント',
  require_follow: 'フォローなし',
  send_dm_error: 'DM配信失敗',
  no_remain_winner: '当選空きなし',
  other: '抽選に落選'
}

export const COUNTRY_DEFAULT = 'JP'

export const WEB_INSTANTWIN_MEDIA_LOADING_URL =
  'https://atelu-assets.s3.ap-northeast-1.amazonaws.com/web-instantwin/during_the_lottery.gif'
export const WEB_INSTANTWIN_MEDIA_WIN_URL =
  'https://atelu-assets.s3.ap-northeast-1.amazonaws.com/web-instantwin/win.gif'
export const WEB_INSTANTWIN_MEDIA_LOSE_URL =
  'https://atelu-assets.s3.ap-northeast-1.amazonaws.com/web-instantwin/lose.gif'

export const WEB_INSTANTWIN_DEFAULT_WIN_MESSAGE =
  'この度は、プレゼントキャンペーンにご応募いただき誠にありがとうございました。\n抽選の結果、お客様はご当選されました！\nおめでとうございます！'
export const WEB_INSTANTWIN_DEFAULT_LOSE_MESSAGE =
  'この度は、プレゼントキャンペーンにご応募いただき誠にありがとうございました。\n抽選の結果、残念ながら落選となりました。\n\n本キャンペーンは、毎日応募が可能です。\nぜひ明日もご応募ください！\n\n今後とも、××をよろしくお願いいたします。'
export const WEB_INSTANTWIN_DEFAULT_LOADING_MESSAGE = '抽選を行なっています。\nこのままお待ちください。'

export const COUNTRIES = [
  { value: 'JP', label: '日本' },
  { value: 'IS', label: 'アイスランド' },
  { value: 'IE', label: 'アイルランド' },
  { value: 'AZ', label: 'アゼルバイジャン' },
  { value: 'AF', label: 'アフガニスタン' },
  { value: 'US', label: 'アメリカ合衆国' },
  { value: 'VI', label: 'アメリカ領ヴァージン諸島' },
  { value: 'AS', label: 'アメリカ領サモア' },
  { value: 'AE', label: 'アラブ首長国連邦' },
  { value: 'DZ', label: 'アルジェリア' },
  { value: 'AR', label: 'アルゼンチン' },
  { value: 'AW', label: 'アルバ' },
  { value: 'AL', label: 'アルバニア' },
  { value: 'AM', label: 'アルメニア' },
  { value: 'AI', label: 'アンギラ' },
  { value: 'AO', label: 'アンゴラ' },
  { value: 'AG', label: 'アンティグア・バーブーダ' },
  { value: 'AD', label: 'アンドラ' },
  { value: 'YE', label: 'イエメン' },
  { value: 'GB', label: 'イギリス' },
  { value: 'IO', label: 'イギリス領インド洋地域' },
  { value: 'VG', label: 'イギリス領ヴァージン諸島' },
  { value: 'IL', label: 'イスラエル' },
  { value: 'IT', label: 'イタリア' },
  { value: 'IQ', label: 'イラク' },
  { value: 'IR', label: 'イラン' },
  { value: 'IN', label: 'インド' },
  { value: 'ID', label: 'インドネシア' },
  { value: 'WF', label: 'ウォリス・フツナ' },
  { value: 'UG', label: 'ウガンダ' },
  { value: 'UA', label: 'ウクライナ' },
  { value: 'UZ', label: 'ウズベキスタン' },
  { value: 'UY', label: 'ウルグアイ' },
  { value: 'EC', label: 'エクアドル' },
  { value: 'EG', label: 'エジプト' },
  { value: 'EE', label: 'エストニア' },
  { value: 'ET', label: 'エチオピア' },
  { value: 'ER', label: 'エリトリア' },
  { value: 'SV', label: 'エルサルバドル' },
  { value: 'AU', label: 'オーストラリア' },
  { value: 'AT', label: 'オーストリア' },
  { value: 'AX', label: 'オーランド諸島' },
  { value: 'OM', label: 'オマーン' },
  { value: 'NL', label: 'オランダ' },
  { value: 'GH', label: 'ガーナ' },
  { value: 'CV', label: 'カーボベルデ' },
  { value: 'GG', label: 'ガーンジー' },
  { value: 'GY', label: 'ガイアナ' },
  { value: 'KZ', label: 'カザフスタン' },
  { value: 'QA', label: 'カタール' },
  { value: 'UM', label: '合衆国領有小離島' },
  { value: 'CA', label: 'カナダ' },
  { value: 'GA', label: 'ガボン' },
  { value: 'CM', label: 'カメルーン' },
  { value: 'GM', label: 'ガンビア' },
  { value: 'KH', label: 'カンボジア' },
  { value: 'MP', label: '北マリアナ諸島' },
  { value: 'GN', label: 'ギニア' },
  { value: 'GW', label: 'ギニアビサウ' },
  { value: 'CY', label: 'キプロス' },
  { value: 'CU', label: 'キューバ' },
  { value: 'CW', label: 'キュラソー島' },
  { value: 'GR', label: 'ギリシャ' },
  { value: 'KI', label: 'キリバス' },
  { value: 'KG', label: 'キルギス' },
  { value: 'GT', label: 'グアテマラ' },
  { value: 'GP', label: 'グアドループ' },
  { value: 'GU', label: 'グアム' },
  { value: 'KW', label: 'クウェート' },
  { value: 'CK', label: 'クック諸島' },
  { value: 'GL', label: 'グリーンランド' },
  { value: 'CX', label: 'クリスマス島' },
  { value: 'GE', label: 'グルジア' },
  { value: 'GD', label: 'グレナダ' },
  { value: 'HR', label: 'クロアチア' },
  { value: 'KY', label: 'ケイマン諸島' },
  { value: 'KE', label: 'ケニア' },
  { value: 'CI', label: 'コートジボワール' },
  { value: 'CC', label: 'ココス諸島' },
  { value: 'CR', label: 'コスタリカ' },
  { value: 'KM', label: 'コモロ' },
  { value: 'CO', label: 'コロンビア' },
  { value: 'CG', label: 'コンゴ共和国' },
  { value: 'CD', label: 'コンゴ民主共和国' },
  { value: 'SA', label: 'サウジアラビア' },
  { value: 'GS', label: 'サウスジョージア・サウスサンドウィッチ諸島' },
  { value: 'WS', label: 'サモア' },
  { value: 'ST', label: 'サントメ・プリンシペ' },
  { value: 'BL', label: 'サン・バルテルミー島' },
  { value: 'ZM', label: 'ザンビア' },
  { value: 'PM', label: 'サンピエール島・ミクロン島' },
  { value: 'SM', label: 'サンマリノ' },
  { value: 'MF', label: 'サン・マルタン' },
  { value: 'SL', label: 'シエラレオネ' },
  { value: 'DJ', label: 'ジブチ' },
  { value: 'GI', label: 'ジブラルタル' },
  { value: 'JE', label: 'ジャージー' },
  { value: 'JM', label: 'ジャマイカ' },
  { value: 'SY', label: 'シリア・アラブ共和国' },
  { value: 'SG', label: 'シンガポール' },
  { value: 'SX', label: 'シント・マールテン' },
  { value: 'ZW', label: 'ジンバブエ' },
  { value: 'CH', label: 'スイス' },
  { value: 'SE', label: 'スウェーデン' },
  { value: 'SD', label: 'スーダン' },
  { value: 'SJ', label: 'スヴァールバル諸島およびヤンマイエン島' },
  { value: 'ES', label: 'スペイン' },
  { value: 'SR', label: 'スリナム' },
  { value: 'LK', label: 'スリランカ' },
  { value: 'SK', label: 'スロバキア' },
  { value: 'SI', label: 'スロベニア' },
  { value: 'SZ', label: 'スワジランド' },
  { value: 'SC', label: 'セーシェル' },
  { value: 'GQ', label: '赤道ギニア' },
  { value: 'SN', label: 'セネガル' },
  { value: 'RS', label: 'セルビア' },
  { value: 'KN', label: 'セントクリストファー・ネイビス' },
  { value: 'VC', label: 'セントビンセント・グレナディーン' },
  {
    value: 'SH',
    label: 'セントヘレナ・アセンションおよびトリスタンダクーニャ'
  },
  { value: 'LC', label: 'セントルシア' },
  { value: 'SO', label: 'ソマリア' },
  { value: 'SB', label: 'ソロモン諸島' },
  { value: 'TC', label: 'タークス・カイコス諸島' },
  { value: 'TH', label: 'タイ王国' },
  { value: 'KR', label: '大韓民国' },
  { value: 'TW', label: '台湾' },
  { value: 'TJ', label: 'タジキスタン' },
  { value: 'TZ', label: 'タンザニア' },
  { value: 'CZ', label: 'チェコ' },
  { value: 'TD', label: 'チャド' },
  { value: 'CF', label: '中央アフリカ共和国' },
  { value: 'CN', label: '中華人民共和国' },
  { value: 'TN', label: 'チュニジア' },
  { value: 'KP', label: '朝鮮民主主義人民共和国' },
  { value: 'CL', label: 'チリ' },
  { value: 'TV', label: 'ツバル' },
  { value: 'DK', label: 'デンマーク' },
  { value: 'DE', label: 'ドイツ' },
  { value: 'TG', label: 'トーゴ' },
  { value: 'TK', label: 'トケラウ' },
  { value: 'DO', label: 'ドミニカ共和国' },
  { value: 'DM', label: 'ドミニカ国' },
  { value: 'TT', label: 'トリニダード・トバゴ' },
  { value: 'TM', label: 'トルクメニスタン' },
  { value: 'TR', label: 'トルコ' },
  { value: 'TO', label: 'トンガ' },
  { value: 'NG', label: 'ナイジェリア' },
  { value: 'NR', label: 'ナウル' },
  { value: 'NA', label: 'ナミビア' },
  { value: 'AQ', label: '南極' },
  { value: 'NU', label: 'ニウエ' },
  { value: 'NI', label: 'ニカラグア' },
  { value: 'NE', label: 'ニジェール' },
  { value: 'EH', label: '西サハラ' },
  { value: 'NC', label: 'ニューカレドニア' },
  { value: 'NZ', label: 'ニュージーランド' },
  { value: 'NP', label: 'ネパール' },
  { value: 'NF', label: 'ノーフォーク島' },
  { value: 'NO', label: 'ノルウェー' },
  { value: 'HM', label: 'ハード島とマクドナルド諸島' },
  { value: 'BH', label: 'バーレーン' },
  { value: 'HT', label: 'ハイチ' },
  { value: 'PK', label: 'パキスタン' },
  { value: 'VA', label: 'バチカン市国' },
  { value: 'PA', label: 'パナマ' },
  { value: 'VU', label: 'バヌアツ' },
  { value: 'BS', label: 'バハマ' },
  { value: 'PG', label: 'パプアニューギニア' },
  { value: 'BM', label: 'バミューダ諸島' },
  { value: 'PW', label: 'パラオ' },
  { value: 'PY', label: 'パラグアイ' },
  { value: 'BB', label: 'バルバドス' },
  { value: 'PS', label: 'パレスチナ' },
  { value: 'HU', label: 'ハンガリー' },
  { value: 'BD', label: 'バングラデシュ' },
  { value: 'TL', label: '東ティモール' },
  { value: 'PN', label: 'ピトケアン諸島' },
  { value: 'FJ', label: 'フィジー' },
  { value: 'PH', label: 'フィリピン' },
  { value: 'FI', label: 'フィンランド' },
  { value: 'BT', label: 'ブータン' },
  { value: 'BV', label: 'ブーベ島' },
  { value: 'PR', label: 'プエルトリコ' },
  { value: 'FO', label: 'フェロー諸島' },
  { value: 'FK', label: 'フォークランド諸島' },
  { value: 'BR', label: 'ブラジル' },
  { value: 'FR', label: 'フランス' },
  { value: 'GF', label: 'フランス領ギアナ' },
  { value: 'PF', label: 'フランス領ポリネシア' },
  { value: 'TF', label: 'フランス領南方・南極地域' },
  { value: 'BG', label: 'ブルガリア' },
  { value: 'BF', label: 'ブルキナファソ' },
  { value: 'BN', label: 'ブルネイ・ダルサラーム' },
  { value: 'BI', label: 'ブルンジ' },
  { value: 'VN', label: 'ベトナム' },
  { value: 'BJ', label: 'ベナン' },
  { value: 'VE', label: 'ベネズエラ・ボリバル共和国' },
  { value: 'BY', label: 'ベラルーシ' },
  { value: 'BZ', label: 'ベリーズ' },
  { value: 'PE', label: 'ペルー' },
  { value: 'BE', label: 'ベルギー' },
  { value: 'PL', label: 'ポーランド' },
  { value: 'BA', label: 'ボスニア・ヘルツェゴビナ' },
  { value: 'BW', label: 'ボツワナ' },
  { value: 'BQ', label: 'BES諸島' },
  { value: 'BO', label: 'ボリビア多民族国' },
  { value: 'PT', label: 'ポルトガル' },
  { value: 'HK', label: '香港' },
  { value: 'HN', label: 'ホンジュラス' },
  { value: 'MH', label: 'マーシャル諸島' },
  { value: 'MO', label: 'マカオ' },
  { value: 'MK', label: 'マケドニア共和国' },
  { value: 'MG', label: 'マダガスカル' },
  { value: 'YT', label: 'マヨット' },
  { value: 'MW', label: 'マラウイ' },
  { value: 'ML', label: 'マリ共和国' },
  { value: 'MT', label: 'マルタ' },
  { value: 'MQ', label: 'マルティニーク' },
  { value: 'MY', label: 'マレーシア' },
  { value: 'IM', label: 'マン島' },
  { value: 'FS', label: 'ミクロネシア連邦' },
  { value: 'ZA', label: '南アフリカ共和国' },
  { value: 'SS', label: '南スーダン' },
  { value: 'MM', label: 'ミャンマー' },
  { value: 'MX', label: 'メキシコ' },
  { value: 'MU', label: 'モーリシャス' },
  { value: 'MR', label: 'モーリタニア' },
  { value: 'MZ', label: 'モザンビーク' },
  { value: 'MC', label: 'モナコ' },
  { value: 'MV', label: 'モルディブ' },
  { value: 'MD', label: 'モルドバ' },
  { value: 'MA', label: 'モロッコ' },
  { value: 'MN', label: 'モンゴル国' },
  { value: 'ME', label: 'モンテネグロ' },
  { value: 'MS', label: 'モントセラト' },
  { value: 'JO', label: 'ヨルダン' },
  { value: 'LA', label: 'ラオス' },
  { value: 'LV', label: 'ラトビア' },
  { value: 'LT', label: 'リトアニア' },
  { value: 'LY', label: 'リビア' },
  { value: 'LI', label: 'リヒテンシュタイン' },
  { value: 'LR', label: 'リベリア' },
  { value: 'RO', label: 'ルーマニア' },
  { value: 'LU', label: 'ルクセンブルク' },
  { value: 'RW', label: 'ルワンダ' },
  { value: 'LS', label: 'レソト' },
  { value: 'LB', label: 'レバノン' },
  { value: 'RE', label: 'レユニオン' },
  { value: 'RU', label: 'ロシア' }
]
