import axiosLib, { AxiosRequestConfig } from 'axios'

import SessionError from '../core/session_error'

export const axios = axiosLib.create({
  baseURL: '/api',
  validateStatus: status => {
    return status >= 200 && status <= 500
  }
})

/**
 * SEND GET API
 */
async function get(url: string, config?: AxiosRequestConfig) {
  try {
    const response = await axios.get(url, config)
    const data = response.data || null

    if (response.status === 401) {
      SessionError.showNotify()
    }

    return data
  } catch (err) {
    if (err.response && err.response.status === 401) {
      SessionError.showNotify()
    }

    return err
  }
}

/**
 * SEND POST API
 */
async function post(url: string, data?: any, config?: AxiosRequestConfig) {
  try {
    const response = await axios.post(url, data, config)
    const response_data = response.data || null

    if (response.status === 401) {
      SessionError.showNotify()
    }

    return response_data
  } catch (err) {
    if (err.response && err.response.status === 401) {
      SessionError.showNotify()
    }

    return err
  }
}

/**
 * SEND PUT API
 */
async function put(url: string, data?: any, config?: AxiosRequestConfig) {
  try {
    const response = await axios.put(url, data, config)
    const response_data = response.data || null

    if (response.status === 401) {
      SessionError.showNotify()
    }

    return response_data
  } catch (err) {
    if (err.response && err.response.status === 401) {
      SessionError.showNotify()
    }

    return err
  }
}

/**
 * SEND DELETE API
 */
async function sendDelete(url: string, config?: AxiosRequestConfig) {
  try {
    const response = await axios.delete(url, config)
    const data = response.data || null

    if (response.status === 401) {
      SessionError.showNotify()
    }

    return data
  } catch (err) {
    if (err.response && err.response.status === 401) {
      SessionError.showNotify()
    }

    return err
  }
}

export const API = {
  get,
  post,
  put,
  delete: sendDelete
}
