/* @flow */
import { email, required } from 'vuelidate/lib/validators'

import TwoFactorForm from '@/client/components/login/two_factor_form'
import api from '@/client/core/api'
import LocalStorage from '@/client/core/local_storage'
import { getLoginStatus, login } from '@/client/utils/facebook-sdk'
import { type PostMeSession } from '@/typings/api/me_session.type'
import { type PostTwoFactorAuthData } from '@/typings/two_factor'

export default {
  components: {
    TwoFactorForm
  },
  props: {
    invitation: Object
  },
  data() {
    return {
      email: '',
      password: '',
      userId: null,
      facebook: {},
      isLoginAttemptsExceeded: false,
      isTwoFactor: false
    }
  },
  created() {
    if (this.invitation && this.invitation.code) {
      this.email = this.invitation.email
    }
  },
  methods: {
    /**
     * ログインサブミット
     * @return {void}
     */
    submit: async function($v: any) {
      // validations
      $v.$touch()
      if ($v.$invalid === true) {
        return
      }
      // ログインを行う
      this.login('default')
    },

    /**
     * FBログインボタンをクリックするイベント
     * @return {void}
     */
    async fbLoginClick() {
      let response = await getLoginStatus()

      if (response.status !== 'connected') {
        response = await login()
      }

      if (response.status !== 'connected') {
        this.openFailNotification('AUTH_FAIL', 'facebook')
        return
      }

      this.facebook = {
        userId: response.authResponse.userID,
        accessToken: response.authResponse.accessToken
      }

      // ログインを行う
      return this.login('facebook')
    },

    /**
     * ログイン
     * @param {('default' | 'facebook')} authType 認証タイプ
     * @return {void}
     */
    async login(authType: 'default' | 'facebook') {
      let params = {}
      if (authType === 'default') {
        params = {
          authType,
          email: this.email,
          password: this.password
        }
      } else if (authType === 'facebook') {
        params = {
          email: this.email,
          authType,
          fbUserId: this.facebook.userId,
          fbUserToken: this.facebook.accessToken
        }
      } else {
        return
      }

      try {
        const result: PostMeSession = await api.post('/me/session', params)
        if (result.error) {
          if (result.error.type === 'LOGIN_ATTEMPTS_EXCEEDED') {
            this.isLoginAttemptsExceeded = true
            return
          }

          this.openFailNotification(result.error.type, authType)
          return
        }
        // ログインが成功した場合は、キャンペーン画面に移動する
        if (result.data) {
          if (result.data.is_two_factor) {
            this.userId = result.data.id
            this.isTwoFactor = true
            return
          }

          await this.authorize(result.data)

          return
        }
        // 失敗した場合はエラーを表示する
        this.openFailNotification('REQUEST_FAIL')
      } catch (err) {
        // 失敗した場合はエラーを表示する
        this.openFailNotification('REQUEST_FAIL')
      }
    },

    /**
     * ログイン2要素
     */
    async loginTwoFactor(data: PostTwoFactorAuthData) {
      if (!data) {
        return
      }

      await this.authorize(data)
    },

    /**
     * キャンペーンに移動
     */
    async authorize(data: any) {
      // 招待情報がある場合は、招待処理を行う
      if (data && this.invitation && this.invitation.code) {
        await this.invitationAccept(data.id)
        this.$router.push('/')
        return
      }

      let originUrl = this.$route.query.originUrl || '/'
      originUrl += this.$route.hash || ''
      location.href = originUrl
    },

    /**
     * ログインフォームに戻る
     */
    back() {
      this.password = ''
      this.$v.$reset()
      this.isTwoFactor = false
    },

    /**
     * 招待処理を行う
     * @param {number} userId ユーザーID
     * @return {void}
     */
    async invitationAccept(userId: number) {
      const params = {
        code: this.invitation.code,
        type: 'exists_user',
        isGuest: this.invitation.isGuest
      }
      const result = await api.post('/invitations/accept', params)
      if (result.error && result.error.type === 'GROUP_MAX_USER_COUNT_OVER') {
        this.$notify({
          title: this.$gettext('これ以上ユーザーを追加できません。'),
          message: this.$gettext('グループの登録可能ユーザー数を超えています。'),
          customClass: 'danger',
          duration: 10000
        })
        this.$router.push('/')
        return
      }
      if (result.error) {
        this.$notify({
          title: this.$gettext('グループ参加処理に失敗しました。'),
          message: this.$gettext('恐れ入りますが、時間をおいて再度お試しください。'),
          customClass: 'danger',
          duration: 10000
        })
        this.$router.push('/')
        return
      }

      if (result.data) {
        this.$notify({
          title: this.$gettextInterpolate(this.$gettext('%{groupName} グループに参加しました。'), {
            groupName: this.invitation.group.name
          }),
          customClass: 'success',
          duration: 5000
        })
        // ローカルストレージに現在のグループを保存する
        let currentGroupLocal = LocalStorage.getCurrentGroup()
        if (!currentGroupLocal) {
          currentGroupLocal = {}
        }
        currentGroupLocal[userId] = this.invitation.group.id
        LocalStorage.setCurrentGroup(currentGroupLocal)
        // 契約情報入力画面に移動する
        this.$router.push('/settings/group_billing')
        return
      }

      // エラーを表示する
      this.$notify({
        title: this.$gettext('グループ参加処理に失敗しました。'),
        customClass: 'danger',
        duration: 10000
      })
    },

    /**
     * 失敗メッセージを表示する
     * @param {string} errorType エラータイプ
     * @param {('default' | 'facebook')} authType 認証タイプ
     * @return {void}
     */
    openFailNotification(errorType: string, authType: 'default' | 'facebook') {
      let title = ''
      let message = ''
      if (errorType === 'REQUEST_FAIL') {
        title = this.$gettext('ログインに失敗しました。')
        message = this.$gettext('エラーが発生しました。恐れ入りますが時間を置いて再ログインしてください。')
      } else {
        if (authType === 'default') {
          title = this.$gettext('ログインに失敗しました。')
          message = this.$gettext('正しいメールアドレスとパスワードの組み合わせを入力してください。')
        } else if (authType === 'facebook') {
          if (errorType === 'NOT_CONNECTED') {
            title = this.$gettext('Facebookアカウントが連携されていません。')
          } else {
            title = this.$gettext('Facebook認証に失敗しました。')
          }
        }
      }
      this.$notify({
        title,
        message,
        customClass: 'danger',
        duration: 10000
      })
    }
  },
  validations: {
    email: {
      required,
      email
    },
    password: {
      required
    }
  }
}
