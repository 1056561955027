/* @flow */
import { mapActions, mapState } from 'vuex'

import Pagination from '@/client/components/basic/pagination'
import InstantwinMessageDialog from '@/client/components/twitter_instantwin_message/instantwin_message_dialog'
import MessageStatus from '@/client/components/twitter_instantwin_message/message_status'
import SerialCodeManagement from '@/client/components/twitter_instantwin_message/serial_code_management'
import { CAMPAIGN_STATUS } from '@/client/constant'
import Util from '@/client/core/util'

export default {
  props: {
    data: Array,
    options: Object,
    onChangePagingNo: Function,
    onChangeLimitCount: Function,
    onChangeSortBy: Function
  },
  components: {
    MessageStatus,
    Pagination,
    InstantwinMessageDialog,
    SerialCodeManagement
  },

  computed: {
    ...mapState('twitterInstantwinMessage', {
      campaign: state => {
        if (state.campaign) {
          return state.campaign
        }
        return {}
      }
    }),
    ...mapState('system', {
      isSystemAdmin: state => Boolean(state.user.isSystemAdmin),
      allowCreateInstantwin: state => Boolean(state.currentGroup.allowCreateInstantwin)
    }),

    isFinished() {
      if (this.campaign) {
        return [CAMPAIGN_STATUS.DURING_FINAL_COLLECTION, CAMPAIGN_STATUS.COLLECTION_COMPLETED].includes(
          this.campaign.status
        )
      }

      return false
    }
  },

  methods: {
    ...mapActions('twitterInstantwinSerialCode', ['setMessageId']),

    /**
     * Open serial code management
     * @param {number} messageId
     * @param {string} messageName
     */
    openSerialCodeManagement(messageId: number, messageName: string) {
      this.setMessageId(messageId)
      this.$refs.serialCodeManagement.open(this.campaign.id, messageName, true)
    },

    /**
     * ヘッダーをクリックするとソートする
     * @param {*} column
     */
    headerClick(column: any) {
      if (!column.sortBy) {
        return
      }
      if (column.sortBy.indexOf('-') === 0) {
        if (this.options.sortBy === column.sortBy) {
          this.onChangeSortBy(column.sortBy.substring(1))
        } else {
          this.onChangeSortBy(column.sortBy)
        }
      } else {
        if (this.options.sortBy === column.sortBy) {
          this.onChangeSortBy('-' + column.sortBy)
        } else {
          this.onChangeSortBy(column.sortBy)
        }
      }
    },

    /**
     * ヘッダー描画
     * @param {*} createElement
     * @param {*} { column }
     */
    renderHeader(createElement: any, { column }: any) {
      return Util.renderHeader(createElement, column.label, Util.getSortClass(this.options.sortBy, column.sortBy))
    }
  }
}
