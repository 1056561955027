import { mapActions, mapState } from 'vuex'

import api from '@/client/core/api'

export default {
  data() {
    return {
      type: '',
      visible: false,
      loading: false,
      isSubmit: false
    }
  },
  computed: {
    ...mapState('twitterInstantwinMessageHistory', {
      campaign: state => state.campaign
    }),

    displayText() {
      if (this.type === 'winner') {
        return '当選者'
      }

      return '回答者'
    },
    isWinnerType() {
      return this.type === 'winner'
    }
  },
  methods: {
    ...mapActions('twitterInstantwinMessageHistory', ['fetchHistoryList', 'fetchCampaign']),

    /**
     * ダイアログをリセット
     */
    reset() {
      this.loading = false
      this.isSubmit = false
    },

    /**
     * フォームのサブミット
     */
    async submit() {
      if (this.isSumbit) {
        return
      }

      this.isSubmit = true
      this.loading = true
      const params = {
        campaignId: this.campaign.id,
        type: this.type
      }

      const result = await api.post('/twitter_instantwin_applicants/resets', params)
      this.loading = false
      setTimeout(() => {
        this.isSubmit = false
      }, 2000)

      // 失敗した場合はエラーを表示
      if (!result.data) {
        this.$notify({
          title: this.$gettext(`${this.displayText}の一括解除に失敗しました。`),
          message: this.$gettext('恐れ入りますが、時間を置いて再度お試しください。'),
          customClass: 'danger',
          duration: 5000
        })

        return
      }

      // 成功メッセージを表示
      this.$notify({
        title: this.$gettext(`${this.displayText}を一括解除しました。`),
        customClass: 'success',
        duration: 5000
      })

      this.fetchHistoryList()
      this.fetchCampaign()
      this.close()
    },

    /**
     * ダイアログを開く
     */
    open(type: string) {
      this.type = type
      this.visible = true
      this.reset()
    },

    /**
     * ダイアログを閉じる
     */
    close() {
      this.visible = false
    }
  }
}
