import moment from 'moment'
import { mapActions, mapState } from 'vuex'

import Message from '@/client/components/_atoms/Message.vue'
import { CampaignUnauthenticatedAccount } from '@/client/components/_organisms'
import CsvDownload from '@/client/components/basic/csv_download'
import AccountAddDialog from '@/client/components/setting_group/account_add_dialog'
import AccountList from '@/client/components/setting_group/account_list'
import BillingHistoryTable from '@/client/components/setting_group/billing_history_table'
import GroupBilling from '@/client/components/setting_group/group_billing'
import GroupSetting from '@/client/components/setting_group/group_setting'
import InstagramNextAuth from '@/client/components/setting_group/instagram_next_auth'
import MessageTemplateDialog from '@/client/components/setting_group/message_template_dialog'
import MessageTemplateTable from '@/client/components/setting_group/message_template_table'
import NgApplicantAddDialog from '@/client/components/setting_group/ng_applicant_add_dialog'
import NgApplicantTable from '@/client/components/setting_group/ng_applicant_table'
import UserInviteDialog from '@/client/components/setting_group/user_invite_dialog'
import UserList from '@/client/components/setting_group/user_list'
import ProfileKeywordSettingLayout from '@/client/components/templates/ProfileKeywordSettingLayout'
import api from '@/client/core/api'
import Util from '@/client/core/util'
import { TrackingService } from '@/client/services'

export default {
  components: {
    CsvDownload,
    AccountList,
    UserList,
    UserInviteDialog,
    AccountAddDialog,
    InstagramNextAuth,
    MessageTemplateTable,
    MessageTemplateDialog,
    NgApplicantTable,
    NgApplicantAddDialog,
    GroupSetting,
    BillingHistoryTable,
    GroupBilling,
    ProfileKeywordSettingLayout,
    CampaignUnauthenticatedAccount,
    Message
  },
  data() {
    return {
      snsType: 'twitter',
      activeTab: [
        'accounts',
        'users',
        'guest_users',
        'templates',
        'profile_keyword_settings',
        'ng_accounts',
        'setting',
        'billing_histories',
        'group_billing'
      ].includes(window.location.hash.substr(1))
        ? window.location.hash.substr(1)
        : 'accounts',
      ngApplicantFilterName: '',
      loading: false,
      loadingText: ''
    }
  },

  created() {
    if (this.currentGroup.isUserAdmin === 0) {
      this.activeTab = 'templates'
    }

    if (this.guestGroups.some(v => v.id === this.currentGroup.id)) {
      this.activeTab = 'accounts'
    }

    window.location.hash = this.activeTab
    const isGuest = !this.guestGroups.some(v => v.id === this.currentGroup.id)

    this.setBreadcrumb([
      {
        name: isGuest ? this.$gettext('キャンペーン一覧') : '',
        link: isGuest ? '/campaigns' : '',
        iconUrl: null,
        iconType: null
      },
      {
        name: isGuest ? this.$gettext('グループ設定') : '',
        link: null,
        iconUrl: null,
        iconType: null
      }
    ])
    this.fetchData()
  },
  computed: {
    ...mapState('system', {
      currentGroup: state => state.currentGroup,
      guestGroups: state => state.guestGroups
    }),
    ...mapState('settingGroup', {
      accounts: state => state.accounts,
      remainAccountsCount: state => state.remainAccountsCount,
      users: state => state.users,
      guestUsers: state => state.guestUsers,
      remainUsersCount: state => state.remainUsersCount,
      remainGuestUsersCount: state => state.remainGuestUsersCount,
      error: state => state.error,
      templates: state => state.templates,
      templateTotal: state => state.templateTotal,
      templateParam: state => state.templateParam,
      templateOption: state => {
        return {
          total: state.templateTotal,
          pagingNo: state.templateParam.pagingNo,
          limitCount: state.templateParam.limitCount,
          sortBy: state.templateParam.sortBy
        }
      },
      ngApplicants: state => state.ngApplicants,
      ngApplicantParam: state => state.ngApplicantParam,
      ngApplicantOption: state => {
        return {
          total: state.ngApplicantTotal,
          pagingNo: state.ngApplicantParam.pagingNo,
          limitCount: state.ngApplicantParam.limitCount,
          sortBy: state.ngApplicantParam.sortBy
        }
      },
      billingHistories: state => state.billingHistories,
      groupBilling: state => state.groupBilling,
      billingHistoryParams: state => state.billingHistoryParam,
      billingHistoryOptions: state => {
        return {
          total: state.billingHistoryTotal,
          pagingNo: state.billingHistoryParam.pagingNo,
          limitCount: state.billingHistoryParam.limitCount,
          sortBy: state.billingHistoryParam.sortBy,
          startMonth: state.billingHistoryStartMonth
            ? moment(state.billingHistoryStartMonth).format('YYYY年MM月')
            : '-',
          endMonth: state.billingHistoryEndMonth ? moment(state.billingHistoryEndMonth).format('YYYY年MM月') : '-'
        }
      }
    })
  },
  watch: {
    // 現在グループIDが変更された場合
    'currentGroup.id'() {
      this.fetchData()
    },
    activeTab() {
      window.location.hash = this.activeTab
    },
    templateParam() {
      this.fetchTemplates(this.currentGroup.id)
    },
    ngApplicantParam() {
      this.fetchNgApplicants(this.snsType)
    },
    billingHistoryParams() {
      this.fetchBillingHistories(this.currentGroup.id)
    },
    snsType() {
      this.fetchNgApplicants(this.snsType)
    }
  },
  methods: {
    ...mapActions('navbar', ['setBreadcrumb']),
    ...mapActions('settingGroup', [
      'reset',
      'fetchAccounts',
      'fetchUsers',
      'fetchGuestUsers',
      'fetchTemplates',
      'fetchNgApplicants',
      'fetchBillingHistories',
      'fetchGroupBilling',
      'setParamTemplate',
      'setParamNgApplicant',
      'fetchGroup',
      'setParamBillingHistory'
    ]),
    /**
     * タブを変更イベント
     */
    tabChange() {
      switch (this.activeTab) {
        case 'accounts':
          TrackingService.sendEvent('click:グループ設定|キャンペーン実施アカウント')
          break
        case 'users':
          TrackingService.sendEvent('click:グループ設定|ユーザー')
          break
        case 'guest_users':
          TrackingService.sendEvent('click:グループ設定|ゲストユーザー')
          break
        case 'templates':
          TrackingService.sendEvent('click:グループ設定|テンプレート')
          break
        case 'profile_keyword_settings':
          TrackingService.sendEvent('click:グループ設定|自己紹介キーワード設定')
          break
        case 'ng_accounts':
          TrackingService.sendEvent('click:グループ設定|NGアカウント')
          break
        case 'setting':
          TrackingService.sendEvent('click:グループ設定|利用制限')
          break
        case 'billing_histories':
          TrackingService.sendEvent('click:グループ設定|利用実績')
          break
        case 'group_billing':
          TrackingService.sendEvent('click:グループ設定|請求先情報')
          break
        default:
          break
      }

      this.fetchData()
    },

    /**
     * 選択中タブに該当するデータを取得
     */
    fetchData() {
      if (this.guestGroups.some(v => v.id === this.currentGroup.id) && this.activeTab !== 'accounts') {
        this.activeTab = 'accounts'
        this.fetchAccounts(this.currentGroup.id)
      }

      switch (this.activeTab) {
        case 'accounts':
          // グループのアカウント一覧を取得
          this.fetchAccounts(this.currentGroup.id)
          break
        case 'users':
          // グループのユーザー一覧を取得
          this.fetchUsers(this.currentGroup.id)
          break
        case 'guest_users':
          // グループのゲストユーザー一覧を取得
          this.fetchGuestUsers(this.currentGroup.id)
          break
        case 'templates':
          this.setParamTemplate({ groupId: this.currentGroup.id })
          break
        case 'ng_accounts':
          this.setParamNgApplicant({ groupId: this.currentGroup.id })
          break
        case 'setting':
          this.fetchGroup(this.currentGroup.id)
          break
        case 'billing_histories':
          this.fetchBillingHistories(this.currentGroup.id)
          break
        case 'group_billing':
          this.fetchGroupBilling(this.currentGroup.id)
          break
        default:
          break
      }
    },

    /**
     * 入力されたNGアカウント名からアカウント情報を取得
     */
    onChangeAccountName(value: string) {
      TrackingService.sendEvent('click:グループ設定|NGアカウント|検索')

      const params: { screenName: null | string } = { screenName: null }
      if (value !== '') {
        params.screenName = value
      }
      this.setParamNgApplicant(params)
    },

    /**
     * Open message template dialog
     * @param {number} templateId
     */
    openMessageTemplateDialog(templateId: number) {
      if (!templateId) {
        TrackingService.sendEvent('click:グループ設定|テンプレート|テンプレートの追加')
      } else TrackingService.sendEvent('click:グループ設定|テンプレート|編集')

      this.$refs.messageTemplateDialog.open(templateId)
    },

    /**
     * ページを変更イベント
     * @param {number} page ページ
     */
    onChangePagingNo(page: number) {
      switch (this.activeTab) {
        case 'templates':
          if (this.templateParam.pagingNo === page) {
            return
          }
          this.setParamTemplate({ pagingNo: page })
          break
        case 'ng_accounts':
          if (this.ngApplicantParam.pagingNo === page) {
            return
          }
          this.setParamNgApplicant({ pagingNo: page })
          break
        case 'billing_histories':
          if (this.billingHistoryParams.pagingNo === page) {
            return
          }
          this.setParamBillingHistory({ pagingNo: page })
          break
      }

      Util.scrollToTop()
    },

    /**
     * リミット数を変更イベント
     * @param {number} limitCount リミット数
     */
    onChangeLimitCount(limitCount: number) {
      switch (this.activeTab) {
        case 'templates':
          if (this.templateParam.limitCount === limitCount) {
            return
          }
          this.setParamTemplate({ limitCount })
          break
        case 'ng_accounts':
          if (this.ngApplicantParam.limitCount === limitCount) {
            return
          }
          this.setParamNgApplicant({ limitCount })
          break
        case 'billing_histories':
          if (this.billingHistoryParams.limitCount === limitCount) {
            return
          }
          this.setParamBillingHistory({ limitCount })
          break
      }
    },

    /**
     * ソートを変更イベント
     * @param {string} sortBy ソート
     */
    onChangeSortBy(sortBy: string) {
      switch (this.activeTab) {
        case 'templates':
          if (this.templateParam.sortBy === sortBy) {
            return
          }
          this.setParamTemplate({ sortBy })
          break
        case 'ng_accounts':
          if (this.ngApplicantParam.sortBy === sortBy) {
            return
          }
          this.setParamNgApplicant({ sortBy })
          break
        case 'billing_histories':
          if (this.billingHistoryParams.sortBy === sortBy) {
            return
          }
          this.setParamBillingHistory({ sortBy })
          break
      }
    },

    openUserInviteDialog() {
      TrackingService.sendEvent('click:グループ設定|ユーザー|ユーザーの追加')

      this.$refs.userInviteDialog.open()
    },

    openGuestUserInviteDialog() {
      TrackingService.sendEvent('click:グループ設定|ユーザー|ゲストユーザーの追加')

      this.$refs.userInviteDialog.open({ isGuest: true })
    },

    /**
     * キャンペーン実施アカウントダイアログを開く
     */
    async openAddAccountDialog() {
      TrackingService.sendEvent('click:グループ設定|キャンペーン実施アカウント|アカウントの追加')

      this.$refs.accountAddDialog.open()
    },

    openNextAuthDialog() {
      this.$refs.accountAddDialog.close()
      this.$refs.instagramNextAuth.open({ type: 'add' })
    },

    /**
     * NGアカウントの追加ダイアログを開く
     */
    openNgApplicantDialog() {
      this.$refs.ngApplicantAddDialog.open(this.snsType)
    },

    /**
     * CSVダウンロード
     * @returns {Promise<{filename?: string, data?: any, error?: string}>}
     */
    async downloadCsv(): Promise<{ filename?: string, data?: any, error?: string }> {
      TrackingService.sendEvent('click:グループ設定|NGアカウント|CSVダウンロード')

      const params = Object.assign({}, this.ngApplicantParam, {
        limitCount: 1000
      })

      const total = this.ngApplicantOption.total
      let ngApplicants = []

      this.loading = true

      // 1000件ごとに取得する
      for (let count = 0; count < total; count += 1000) {
        const result = await api.get(`/${this.snsType}_ng_applicants`, {
          params
        })

        if (!result.data) {
          this.loading = false
          return {
            error: 'ERROR_LOADING'
          }
        }

        ngApplicants = ngApplicants.concat(result.data.ngApplicants)

        this.loadingText = this.$gettextInterpolate(
          this.$gettext('CSVファイルの作成中です。しばらくお待ちください。\n %{acquiredNumber}件 / %{totalNumber}件'),
          {
            acquiredNumber: ngApplicants.length,
            totalNumber: total
          }
        )

        params.pagingNo = params.pagingNo + 1
      }

      const filename = `${this.snsType}_ng_applicants_${moment().format('YYYY_MM_DD')}`

      const csvData = []
      csvData.push([this.$gettext('ユーザー名'), this.$gettext('作成日時')])

      // CSVデータを格納
      ngApplicants.forEach(applicant => {
        const datetime = this.$options.filters.datetime(applicant.created)
        csvData.push([applicant.screenName, datetime])
      })

      this.loading = false

      return {
        filename,
        data: csvData
      }
    },

    onChangeBackEvent() {
      switch (this.activeTab) {
        case 'accounts':
          TrackingService.sendEvent('click:グループ設定|キャンペーン実施アカウント|戻る')
          break
        case 'users':
          TrackingService.sendEvent('click:グループ設定|ユーザー|戻る')
          break
        case 'guest_users':
          TrackingService.sendEvent('click:グループ設定|ゲストユーザー|戻る')
          break
        case 'templates':
          TrackingService.sendEvent('click:グループ設定|テンプレート|戻る')
          break
        case 'profile_keyword_settings':
          TrackingService.sendEvent('click:グループ設定|自己紹介キーワード設定|戻る')
          break
        case 'ng_accounts':
          TrackingService.sendEvent('click:グループ設定|NGアカウント|戻る')
          break
        case 'setting':
          TrackingService.sendEvent('click:グループ設定|利用制限|戻る')
          break
        case 'billing_histories':
          TrackingService.sendEvent('click:グループ設定|利用実績|戻る')
          break
        case 'group_billing':
          TrackingService.sendEvent('click:グループ設定|請求先情報|戻る')
          break
        default:
          break
      }

      this.$router.push('/campaigns')
    },

    handleRadioChange(value: string) {
      TrackingService.sendEvent(`radio:グループ設定|NGアカウント|${value === 'twitter' ? 'X' : value}`)
    }
  },
  beforeRouteLeave(to: any, from: any, next: any) {
    // グループ設定ストアをリセット
    this.reset()
    next()
  }
}
