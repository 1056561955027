import { mapState } from 'vuex'

export default {
  props: {
    snsType: 'instagram' | 'twitter',
    post: Object,
    campaignType: String
  },
  data() {
    return {}
  },
  computed: {
    ...mapState('system', {
      isEndContract: state => state.currentGroup.contractStatus === 3
    }),

    isWidgetDisabled() {
      return Boolean(this.isEndContract || (this.post.isNg && !this.post.isWidget))
    },

    isCandidateDisabled() {
      return Boolean(this.isEndContract || (this.post.isNg && !this.post.isCandidate))
    },

    isWinnerDisabled() {
      return Boolean(this.isEndContract || (this.post.isNg && !this.post.isWinner))
    },

    widgetTooltipContent() {
      if (this.post.isNg && !this.post.isWidget) {
        return 'NGアカウント'
      }

      return this.post.isWidget ? 'ウィジェットから削除' : 'ウィジェットに追加'
    },

    candidateTooltipContent() {
      if (this.post.isNg && !this.post.isCandidate) {
        return 'NGアカウント'
      }

      return this.post.isCandidate ? '候補者を解除' : '候補者に追加'
    },

    winnerTooltipContent() {
      if (this.post.isNg && !this.post.isWinner) {
        return 'NGアカウント'
      }

      return this.post.isWinner ? '当選者を解除' : '当選者に追加'
    }
  },
  methods: {
    /**
     * ウィジェットフラグを変更
     */
    changeIsWidget() {
      if ((this.post.isNg && !this.post.isWidget) || this.isEndContract) {
        return
      }

      this.$emit('change_is_widget')
    },

    /**
     * 候補者フラグを変更
     */
    changeIsCandidate() {
      if ((this.post.isNg && !this.post.isCandidate) || this.isEndContract) {
        return
      }

      this.$emit('change_is_candidate')
    },

    /**
     * 当選者フラグを変更
     */
    changeIsWinner() {
      if ((this.post.isNg && !this.post.isWinner) || this.isEndContract) {
        return
      }

      this.$emit('change_is_winner')
    }
  }
}
