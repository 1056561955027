/* @flow */
import { INSTANTWIN_LOSE_REASON } from '@/client/constant'
import Util from '@/client/core/util'

export default {
  props: {
    loseReasons: Array,
    sortBy: String
  },
  methods: {
    /**
     * 落選理由名称の取得
     * @param {string} name
     * @returns 落選理由名称
     */
    reasonName(name: string) {
      return INSTANTWIN_LOSE_REASON[name]
    },

    /**
     * ヘッダー描画
     * @param {*} createElement
     * @param {*} { column }
     */
    renderHeader(createElement: any, { column }: any) {
      return Util.renderHeader(
        createElement,
        column.label,
        Util.getSortClass(this.sortBy, column.sortBy),
        !!column.sortBy
      )
    },

    /**
     * ヘッダーをクリックするとソートする
     * @param {*} column
     */
    headerClick(column: any) {
      if (!column.sortBy) {
        return
      }
      let sortBy = column.sortBy

      if (column.sortBy.indexOf('-') === 0) {
        if (this.sortBy === column.sortBy) {
          sortBy = column.sortBy.substring(1)
          this.$emit('sorting', { sortBy })
        } else {
          sortBy = column.sortBy
          this.$emit('sorting', { sortBy })
        }
      } else {
        if (this.sortBy === column.sortBy) {
          sortBy = '-' + column.sortBy
          this.$emit('sorting', { sortBy })
        } else {
          this.$emit('sorting', { sortBy })
        }
      }
    }
  }
}
