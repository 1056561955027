







import { defineComponent } from '@vue/composition-api'
import { mapActions, mapState } from 'vuex'

import LotteryPageLose from '@/client/components/twitter_applicant/lottery_page_lose'
import LotteryPageTable from '@/client/components/twitter_applicant/lottery_page_table'
import LotteryPageWin from '@/client/components/twitter_applicant/lottery_page_win'

export default defineComponent<any>({
  name: 'LotteryPageDialog',
  components: {
    LotteryPageTable,
    LotteryPageLose,
    LotteryPageWin
  },
  data() {
    return {
      visible: false,
      title: '当落選ページの管理'
    }
  },
  computed: {
    ...mapState('lotteryPageSettingDialog', ['lotteryPages', 'mode']),
    setTitle(): string {
      switch (this.mode) {
        case 'settings':
          return '当落選ページの管理'
        case 'create':
          return '落選ページの作成'
        case 'edit_win':
          return '当選ページの編集'
        case 'edit_lose':
          return '落選ページの編集'
        case 'copy':
          return '落選ページの複製'
        default:
          return ''
      }
    }
  },

  methods: {
    ...mapActions('lotteryPageSettingDialog', ['fetchLotteryPages', 'reset', 'setParams', 'setMode']),

    /**
     * ダイアログを表示
     */
    async open(campaignId: number, prizeId: number) {
      this.visible = true

      this.setParams({ campaignId, prizeId })
      await this.fetchLotteryPages()
      this.setMode('settings')
    },

    /**
     * ダイアログを閉じる
     */
    close() {
      this.visible = false
      this.reset()
    }
  }
})
