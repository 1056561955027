































import { Component, Vue } from 'vue-property-decorator'
import { mapActions, mapState } from 'vuex'

import Icon from '@/client/components/_atoms/Icon.vue'
import Message from '@/client/components/_atoms/Message.vue'
import Tips from '@/client/components/_atoms/Tips.vue'
import Account from '@/client/components/_molecules/Account.vue'
import api from '@/client/core/api'
import TwitterAuthService from '@/client/core/twitter_auth'
import { TCampaignAccount } from '@/typings/api/campaign_accounts.type'
@Component({
  components: {
    Icon,
    Message,
    Tips,
    Account
  },
  props: {
    isGroupSetting: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState('campaign', ['unauthenticatedAccounts']),
    ...mapState('system', {
      currentGroup: state => state.currentGroup,
      guestGroups: state => state.guestGroups
    }),
    ...mapState('settingGroup', ['groupSettingUnauthenticatedAccounts'])
  },
  methods: {
    ...mapActions('campaign', ['fetchCampaigns']),
    ...mapActions('settingGroup', ['fetchAccounts'])
  }
})
export default class CampaignUnauthenticatedAccount extends Vue {
  twitter = 'twitter'

  /**
   * アカウントトークンの更新
   */
  async updateAccountToken(account: TCampaignAccount) {
    await this.updateTwitterAccountToken(account)

    if (this.isGroupSetting) {
      return this.fetchAccounts(this.currentGroup.id)
    }

    // キャンペーン一覧を取得
    this.fetchCampaigns()
  }

  /**
   * Twitterアカウントトークンの更新
   */
  async updateTwitterAccountToken(apiAccount: TCampaignAccount) {
    if (!apiAccount.accountId) return

    const enterpriseAuth = await TwitterAuthService.execEnterpriseAuth(apiAccount.accountId)

    if (!enterpriseAuth) {
      this.$notify({
        title: this.$gettext('認証に失敗しました。'),
        message: this.$gettext('恐れ入りますが、時間を置いて再度お試しください。'),
        customClass: 'danger',
        duration: 5000
      })
      return
    }

    const account = {
      id: enterpriseAuth.id,
      enterpriseToken: enterpriseAuth.enterpriseToken,
      enterpriseSecret: enterpriseAuth.enterpriseSecret
    }

    const result = await api.put('/twitter_campaign_accounts/' + apiAccount.id, {
      enterpriseToken: account.enterpriseToken,
      enterpriseSecret: account.enterpriseSecret
    })

    if (result?.error?.type === 'NOT_MATCH') {
      const errorText = '認証したアカウントが違うため、データ収集API認証に失敗しました。'

      this.$notify({
        title: this.$gettext(errorText),
        customClass: 'danger',
        duration: 5000
      })
      return
    }

    if (!result.data) {
      const errorText = 'データ収集API認証に失敗しました。'

      this.$notify({
        title: this.$gettext(errorText),
        message: this.$gettext('恐れ入りますが、時間を置いて再度お試しください。'),
        customClass: 'danger',
        duration: 5000
      })
      return
    }

    const successText = '%{name}のデータ収集API認証が完了しました。'

    // 成功メッセージを表示
    this.$notify({
      title: this.$gettextInterpolate(this.$gettext(successText), {
        name: apiAccount.name
      }),
      customClass: 'success',
      duration: 5000
    })
  }
}
