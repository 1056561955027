/* @flow */
import moment from 'moment'
import { mapActions, mapState } from 'vuex'

import ApplicantName from '@/client/components/basic/applicant_name'
import CsvDownload from '@/client/components/basic/csv_download'
import Pagination from '@/client/components/basic/pagination'
import api from '@/client/core/api'
import Util from '@/client/core/util'

export default {
  data() {
    return {
      isDownloading: false,
      status: {
        value: null,
        options: [
          {
            label: this.$gettext('指定なし'),
            value: ''
          },
          {
            label: this.$gettext('配信待ち'),
            value: 'unsent'
          },
          {
            label: this.$gettext('配信成功'),
            value: 'sent'
          },
          {
            label: this.$gettext('配信失敗'),
            value: 'failed'
          }
        ],
        onChange: (value: string) => {
          if (this.params.status === value) return

          this.setQueueMessageParams({ status: value, pagingNo: 1 })
        }
      }
    }
  },
  components: {
    ApplicantName,
    CsvDownload,
    Pagination
  },
  computed: {
    ...mapState('twitterInstantwinQueue', {
      queueMessages: state => state.queueMessages.list,
      total: state => state.queueMessages.total,
      params: state => state.queueMessages.params,
      queueId: state => state.queueMessages.queueId,
      loading: state => state.loading
    })
  },
  async mounted() {
    await this.fetchQueueMessage()
  },
  watch: {
    params() {
      this.fetchQueueMessage()
    }
  },
  methods: {
    ...mapActions('twitterInstantwinQueue', ['fetchQueueMessage', 'setQueueMessageParams']),

    /**
     * CSVダウンロード
     */
    async downloadCsv() {
      try {
        if (this.isDownloading) {
          return
        }

        const queueMessages = await this.getDataForCsv()
        const filename = `instantwin_sent_messages_${moment().format('YYYY_MM_DD')}`
        const csvData = []

        csvData.push([
          this.$gettext('配信開始日時'),
          this.$gettext('配信結果'),
          this.$gettext('アカウント名'),
          this.$gettext('アカウントスクリーンネーム'),
          this.$gettext('アカウント画像'),
          this.$gettext('フォロー中'),
          this.$gettext('警告アカウント'),
          this.$gettext('NGアカウント'),
          this.$gettext('新規獲得フォロワー')
        ])

        queueMessages.forEach((message, index) => {
          let status = ''
          switch (message.status) {
            case 'sent':
              status = this.$gettext('配信成功')
              break
            case 'failed':
              status = this.$gettext('配信失敗')
              break
            case 'unsent':
              status = this.$gettext('配信待ち')
              break
          }

          csvData.push([
            this.$options.filters.datetime(message.actionDatetime),
            status,
            message.applicant.name,
            message.applicant.screenName,
            message.applicant.pictureUrl,
            message.applicant.isFollowing ? 1 : 0,
            message.applicant.isWarning ? 1 : 0,
            message.applicant.isNg ? 1 : 0,
            message.applicant.isNewFollower && message.applicant.isFollowing ? 1 : 0
          ])
        })

        return {
          filename,
          data: csvData
        }
      } catch (e) {
        this.$notify({
          title: this.$gettext('CSVダウンロードに失敗しました。'),
          message: this.$gettext('恐れ入りますが、時間をおいて再度お試しください。'),
          customClass: 'danger',
          duration: 5000
        })
        this.isDownloading = false
      }
    },

    /**
     * Get queue message
     */
    async getDataForCsv() {
      this.isDownloading = true

      const params = Object.assign({}, this.params, {
        pagingNo: 1,
        limitCount: 1000
      })

      let totalResults = []

      while (true) {
        const result = await api.get(`/twitter_instantwin_queues/${this.queueId}/messages`, { params })

        if (result.error) {
          throw new Error('Download error!')
        }

        if (!result.data || !result.data.messages.length) {
          break
        }

        totalResults = totalResults.concat(result.data.messages)
        params.pagingNo = params.pagingNo + 1
      }

      setTimeout(() => {
        this.isDownloading = false
      }, 2000)

      return totalResults
    },

    /**
     * Change screen
     * @param {string} tab
     */
    changeTab(tab: string) {
      this.$emit('change_tab', tab)
    },

    /**
     * ページを変更イベント
     * @param {number} pagingNo ページング
     */
    onChangePagingNo(pagingNo: number) {
      this.setQueueMessageParams({
        pagingNo
      })

      Util.scrollToTop()
    },

    /**
     * ページを変更イベント
     * @param {number} limitCount リミット数
     */
    onChangeLimitCount(limitCount: number) {
      this.setQueueMessageParams({
        limitCount
      })
    },

    /**
     * ソートを変更イベント
     * @param {string} sortBy ソート
     */
    onChangeSortBy(sortBy: string) {
      if (this.params.sortBy === sortBy) {
        return
      }

      this.setQueueMessageParams({ sortBy })
    },

    /**
     * ヘッダーをクリックするとソートする
     * @param {*} column
     */
    headerClick(column: any) {
      if (!column.sortBy) {
        return
      }
      if (column.sortBy.indexOf('-') === 0) {
        if (this.params.sortBy === column.sortBy) {
          this.onChangeSortBy(column.sortBy.substring(1))
        } else {
          this.onChangeSortBy(column.sortBy)
        }
      } else {
        if (this.params.sortBy === column.sortBy) {
          this.onChangeSortBy('-' + column.sortBy)
        } else {
          this.onChangeSortBy(column.sortBy)
        }
      }
    },

    /**
     * ヘッダー描画
     * @param {*} createElement
     * @param {*} { column }
     */
    renderHeader(createElement: any, { column }: any) {
      return Util.renderHeader(
        createElement,
        column.label,
        Util.getSortClass(this.params.sortBy, column.sortBy),
        !!column.sortBy
      )
    }
  }
}
