/* @flow */
import { mapState } from 'vuex'

import NavBar from '@/client/components/basic/nav_bar'
import api from '@/client/core/api'
import SessionError from '@/client/core/session_error'

export default {
  components: {
    NavBar
  },
  computed: {
    ...mapState('system', {
      loading: state => state.loading
    })
  },
  data() {
    return {
      sessionExpired: false
    }
  },
  created() {
    // 1分毎にチェックする
    setInterval(() => {
      if (!this.sessionExpired) {
        this.checkSession()
      }
    }, 60000)
  },
  methods: {
    async checkSession() {
      const result = await api.get('/me/session')
      if (result.expired === true) {
        SessionError.showNotify()
      }
    }
  }
}
