import { required } from 'vuelidate/lib/validators'
import { mapActions, mapState } from 'vuex'

import api from '@/client/core/api'

export default {
  data() {
    return {
      type: '',
      types: {
        winner: {
          text: '当選者'
        },
        candidate: {
          text: '候補者'
        },
        responder: {
          text: '回答者'
        }
      },
      body: '',
      visible: false,
      loading: false,
      isSubmit: false,
      isSubmited: false
    }
  },
  computed: {
    ...mapState('twitterInstantwinMessageHistory', {
      campaignId: state => state.campaign.id
    }),
    typeText() {
      if (!this.types[this.type]) {
        return ''
      }

      return this.types[this.type].text
    },
    isFormChanged() {
      return Boolean(this.body.trim())
    },
    maxUserError() {
      if (!this.body.trim()) {
        return false
      }

      return this.body.trim().split('\n').length > 10000
    },

    disabled() {
      return this.$v.$invalid || !this.body.trim() || this.isSubmit || this.maxUserError
    }
  },
  methods: {
    ...mapActions('twitterInstantwinMessageHistory', ['fetchHistoryList', 'fetchCampaign']),

    /**
     * ダイアログをリセット
     */
    reset() {
      this.loading = false
      this.isSubmit = false
      this.isSubmited = false
      this.body = ''
    },

    /**
     * フォームのサブミット
     */
    async submit() {
      if (this.isSumbit) {
        return
      }

      const value = this.body
      const applicants = value
        .replace(/^\s*[\r\n]/gm, '')
        .replace(/^\s+|\s+$/g, '')
        .replace(/ /g, '')
        .replace(/@/g, '')
        .split('\n')

      const confirm = window.confirm(
        this.$gettextInterpolate(
          this.$gettext(
            '%{inputRecord}件のレコードをインポートします。\n処理を実行してよろしいですか?\nこの操作は取り消しできません。'
          ),
          {
            inputRecord: applicants.length
          }
        )
      )

      if (!confirm) {
        return
      }

      this.isSubmit = true
      this.loading = true

      const params = {
        campaignId: this.campaignId,
        applicants,
        type: this.type
      }

      const result = await api.post('/twitter_instantwin_applicants/imports', params)

      this.loading = false
      setTimeout(() => {
        this.isSubmit = false
      }, 2000)

      // 失敗した場合はエラーを表示
      if (!result.data && result.data !== 0) {
        this.$notify({
          title: this.$gettext(`${this.typeText}インポートに失敗しました。`),
          message: this.$gettext('恐れ入りますが、時間を置いて再度お試しください。'),
          customClass: 'danger',
          duration: 5000
        })
        return
      }

      // 成功メッセージを表示
      this.$notify({
        title: this.$gettextInterpolate(this.$gettext(`%{importCount}件の${this.typeText}をインポートしました。`), {
          importCount: result.data
        }),
        customClass: 'success',
        duration: 5000
      })

      this.fetchHistoryList()
      this.fetchCampaign()

      this.isSubmited = true
      this.close()

      this.body = ''
    },

    /**
     * ダイアログをあく
     * @param {string} type
     * @returns {void} void
     */
    open(type: string): void {
      this.type = type
      this.$v.$reset()
      this.visible = true
      this.reset()
    },

    /**
     * ダイアログを閉じる
     * @returns {void} void
     */
    close(): void {
      if (this.isFormChanged && !this.isSubmited) {
        const message = this.$gettext('編集内容が保存されていません。ダイアログを閉じてよろしいですか?')
        const confirm = window.confirm(message)

        if (!confirm) {
          return
        }
      }

      this.visible = false
      this.reset()
    }
  },
  validations: {
    body: {
      required
    }
  }
}
