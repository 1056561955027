import TwitterPostEmbed from '@/client/components/basic/twitter_post_embed'

type TDuplicateEntry = {
  id: number,
  postType: string,
  publishDatetime: string
}

export default {
  data() {
    return {
      title: '',
      visible: false,
      duplicateEntries: []
    }
  },
  components: {
    TwitterPostEmbed
  },
  methods: {
    open(accountName: string, duplicateEntries: TDuplicateEntry[]) {
      this.title = `${accountName} ${this.$gettext('さんの重複応募詳細')}(${duplicateEntries.length}${this.$gettext(
        '件'
      )})`
      this.duplicateEntries = duplicateEntries
      this.visible = true
    },

    close() {
      this.title = ''
      this.duplicateEntries = []
      this.visible = false
    }
  }
}
