/* @flow */
import { Timeline } from 'vue-tweet-embed'

export default {
  props: {
    id: String,
    tweetLimit: String
  },
  components: {
    Timeline
  },
  computed: {
    options() {
      return {
        id: `profile:${this.id}`,
        tweetLimit: this.tweetLimit,
        lang: 'ja',
        chrome: 'noheader nofooter'
      }
    }
  }
}
