/* @flow */
import Macy from 'macy'

import WidgetPreviewItem from '@/client/components/basic/widget_preview_item'

export default {
  components: {
    WidgetPreviewItem
  },
  props: {
    posts: Array,
    isViewMessage: Boolean,
    snsType: String,
    displayCount: Number
  },
  data() {
    return {
      macyInstance: null
    }
  },
  updated() {
    this.macyInstance.runOnImageLoad(() => {
      this.macyInstance.recalculate(true)
    }, true)
  },
  mounted() {
    this.macyInstance = Macy({
      container: '.widget-preview-message',
      trueOrder: true,
      waitForImages: true,
      columns: 4,
      margin: {
        x: 8,
        y: 8
      },
      breakAt: {
        '768': 2
      }
    })
  }
}
