/* @flow */
import api from '@/client/core/api'
import { type TCampaign, type TGetCampaignParams, type TUnauthenticatedAccount } from '@/typings/api/campaigns.type'

// mutations type
const RESET = 'RESET'
const SET_CAMPAIGN_DATA = 'SET_CAMPAIGN_DATA'
const SET_LOADING = 'SET_LOADING'
const SET_FIRST_LOADED = 'SET_FIRST_LOADED'
const SET_ERROR = 'SET_ERROR'
const SET_PARAMS = 'SET_PARAMS'
const SET_IS_FILTERED = 'SET_IS_FILTERED'

// キャンペーンストア
type CampaignStore = {
  loading: boolean,
  firstLoaded: boolean,
  error: boolean,
  total: number,
  campaigns: TCampaign[],
  unauthenticatedAccounts: TUnauthenticatedAccount[],
  params: TGetCampaignParams,
  isFiltered: boolean
}
// ストアーcontext
type Context = {
  commit: Function,
  getters: any,
  state: CampaignStore,
  dispatch: Function
}

// init state
const initState: CampaignStore = {
  loading: false,
  firstLoaded: false,
  error: false,
  campaigns: [],
  total: 0,
  unauthenticatedAccounts: [],
  params: {
    groupId: 0,
    sortBy: '-status',
    pagingNo: 1,
    limitCount: 25
  },
  isFiltered: false
}

// states
const state = { ...initState }

// getters
const getters = {}

// actions
const actions = {
  /**
   * ストアをリセット
   * @param {Context} context
   */
  async reset(context: Context) {
    context.commit(RESET)
  },

  /**
   * キャンペーン一覧を取得
   * @param {Context} context
   */
  async fetchCampaigns(context: Context) {
    context.commit(SET_LOADING, true)
    context.commit(SET_ERROR, false)
    // システムストアのローディングをセット
    context.dispatch('system/setLoading', true, { root: true })
    const result = await api.get('/campaigns', {
      params: context.state.params
    })

    // システムストアのローディングをセット
    context.dispatch('system/setLoading', false, { root: true })
    context.commit(SET_LOADING, false)
    context.commit(SET_FIRST_LOADED, true)
    // データがない場合はエラーフラグをセット
    if (!result.data) {
      context.commit(SET_ERROR, true)
      return
    }
    // キャンペーン一覧をセット
    context.commit(SET_CAMPAIGN_DATA, {
      campaigns: result.data.campaigns,
      total: result.data.dataCount,
      unauthenticatedAccounts: result.data.unauthenticatedAccounts
    })

    // 現在のグループデータを取得
    context.dispatch('system/getCurrentGroup', {}, { root: true })
  },

  /**
   * パラメーターをセット
   * @param {Context} context
   * @param {TGetCampaignParams} params パラメーター
   */
  setParams(context: Context, params: TGetCampaignParams) {
    if (!params.pagingNo) {
      params.pagingNo = 1
    }
    context.commit(SET_PARAMS, Object.assign({}, context.state.params, params))
  },

  /**
   * フィルターフラグをセット
   * @param {Context} context
   * @param {boolean} isFiltered フィルターフラグ
   */
  setIsFiltered(context: Context, isFiltered: boolean) {
    context.commit(SET_IS_FILTERED, isFiltered)
  }
}

// mutations
const mutations = {
  /**
   * ストアをリセット
   * @param {CampaignStore} state
   */
  [RESET](state: CampaignStore) {
    const initStateData: any = initState
    Object.keys(initStateData).forEach(key => {
      state[key] = initStateData[key]
    })
  },

  /**
   * キャンペーンデータをセット
   * @param {CampaignStore} state
   * @param {TCampaign[]} campaigns キャンペーン一覧
   */
  [SET_CAMPAIGN_DATA](
    state: CampaignStore,
    campaignData: { total: number, campaigns: TCampaign[], unauthenticatedAccounts: TUnauthenticatedAccount[] }
  ) {
    state.campaigns = campaignData.campaigns
    state.total = campaignData.total
    state.unauthenticatedAccounts = campaignData.unauthenticatedAccounts
  },

  /**
   * キャンペーンパラメーターをセット
   * @param {CampaignStore} state
   * @param {TGetCampaignParams} params パラメーター
   */
  [SET_PARAMS](state: CampaignStore, params: TGetCampaignParams) {
    state.params = params
  },

  /**
   * ローディングフラグをセット
   * @param {CampaignStore} state
   * @param {boolean} loading ローディングフラグ
   */
  [SET_LOADING](state: CampaignStore, loading: boolean) {
    state.loading = loading
  },

  /**
   * 初回ロード終了フラグをセット
   * @param {CampaignStore} state
   * @param {boolean} loaded 初回ロード終了フラグ
   */
  [SET_FIRST_LOADED](state: CampaignStore, loaded: boolean) {
    state.firstLoaded = loaded
  },

  /**
   * エラーフラグをセット
   * @param {CampaignStore} state
   * @param {boolean} error エラーフラグ
   */
  [SET_ERROR](state: CampaignStore, error: boolean) {
    state.error = error
  },

  /**
   * フィルターフラグをセット
   * @param {CampaignStore} state
   * @param {boolean} isFiltered フィルターフラグ
   */
  [SET_IS_FILTERED](state: CampaignStore, isFiltered: boolean) {
    state.isFiltered = isFiltered
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
