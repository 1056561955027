import { render, staticRenderFns } from "./CampaignCreateTwitterConfirm.vue?vue&type=template&id=1dca3eab&scoped=true"
import script from "./CampaignCreateTwitterConfirm.vue?vue&type=script&lang=ts"
export * from "./CampaignCreateTwitterConfirm.vue?vue&type=script&lang=ts"
import style0 from "./CampaignCreateTwitterConfirm.vue?vue&type=style&index=0&id=1dca3eab&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1dca3eab",
  null
  
)

export default component.exports