/* @flow */
import TikTokAnalyticsPublicCampaign from '@/client/components/TikTokAnalyticsPublic/TikTokAnalyticsPublicCampaign'
import TikTokAnalyticsPublicTrafficSource from '@/client/components/TikTokAnalyticsPublic/TikTokAnalyticsPublicTrafficSource'

export default {
  components: { TikTokAnalyticsPublicCampaign, TikTokAnalyticsPublicTrafficSource },
  data() {
    return {
      activeTab: 'campaigns'
    }
  }
}
