/* @flow */
import { mapActions, mapState } from 'vuex'

import Icon from '@/client/components/basic/icon'
import api from '@/client/core/api'
import { TrackingService } from '@/client/services'
import { type TGuestUser, type TUser } from '@/typings/api/group_users.type'

export default {
  props: {
    users: Array,
    isGuest: {
      type: Boolean,
      required: false
    }
  },
  components: {
    Icon
  },
  data() {
    return {
      isSubmit: false
    }
  },
  computed: {
    ...mapState('system', {
      meId: state => state.user.id,
      currentGroupId: state => state.currentGroup.id,
      groups: state => state.groups
    })
  },
  methods: {
    ...mapActions('settingGroup', ['fetchUsers', 'fetchGuestUsers']),
    /**
     * 対象グループのユーザーの管理者権限を変更
     * @param {TUser} user ユーザー情報
     * @param {string} isAdmin 管理者権限フラグ
     */
    async changeAdminFlg(user: TUser, isAdmin: string) {
      TrackingService.sendEvent('check:グループ設定|ユーザー|グループ管理者')

      if (this.isSubmit) {
        return
      }

      this.isSubmit = true
      // 対象グループのユーザーの管理者権限を変更
      const result = await api.post('/group_users', {
        groupId: this.currentGroupId,
        userId: user.id,
        isAdmin: Number(isAdmin)
      })
      setTimeout(() => {
        this.isSubmit = false
      }, 2000)

      if (result.error && result.error.type === 'NOT_EXISTS') {
        this.$notify({
          title: this.$gettext('対象のユーザーはすでに削除されています。'),
          customClass: 'danger',
          duration: 5000
        })
        this.fetchUsers()
        this.close()
        return
      }

      // エラーがある場合はエラーメッセージを表示
      if (!result.data) {
        this.$notify({
          title: this.$gettext('グループ管理者権限の保存に失敗しました'),
          message: this.$gettext('恐れ入りますが、時間をおいて再度お試しください。'),
          customClass: 'danger',
          duration: 5000
        })
        return
      }

      // 成功メッセージを表示
      this.$notify({
        title: this.$gettext('グループ管理者権限を保存しました。'),
        customClass: 'success',
        duration: 5000
      })

      // ユーザー一覧を取得
      this.fetchUsers(this.currentGroupId)
    },
    /**
     * 対象グループのユーザーを削除
     * @param {TUser} user ユーザー情報
     */
    async deleteUser(user: TUser) {
      TrackingService.sendEvent('click:グループ設定|ユーザー|削除')

      if (this.isSubmit) {
        return
      }

      const confirm = window.confirm(user.name + this.$gettext('をグループから削除してよろしいですか？'))
      if (!confirm) {
        window.document.getElementById('user-delete-' + user.id).blur()
        return
      }

      this.isSubmit = true
      // 対象グループのユーザーを削除
      const result = await api.delete('/group_users/' + user.id, {
        params: {
          groupId: this.currentGroupId
        }
      })
      setTimeout(() => {
        this.isSubmit = false
      }, 2000)

      if (result.error && result.error.type === 'NOT_EXISTS') {
        this.$notify({
          title: this.$gettext('対象のユーザーはすでに削除されています。'),
          customClass: 'danger',
          duration: 5000
        })
        this.fetchUsers()
        this.close()
        return
      }
      // エラーがある場合はエラーメッセージを表示
      if (!result.data) {
        this.$notify({
          title: this.$gettext('削除に失敗しました。'),
          message: this.$gettext('恐れ入りますが、時間をおいて再度お試しください。'),
          customClass: 'danger',
          duration: 5000
        })
        return
      }

      // 成功メッセージを表示
      this.$notify({
        title: this.$gettext('削除に成功しました。'),
        customClass: 'success',
        duration: 5000
      })

      // ユーザー一覧を取得
      this.fetchUsers(this.currentGroupId)
    },

    /**
     * 対象グループのユーザーを削除
     * @param {TGuestUser} user ユーザー情報
     */
    async deleteGuestUser(user: TGuestUser) {
      TrackingService.sendEvent('click:グループ設定|ユーザー|削除')

      if (this.isSubmit) {
        return
      }

      const confirm = window.confirm(user.name + this.$gettext('をグループから削除してよろしいですか？'))
      if (!confirm) {
        window.document.getElementById('user-delete-' + user.id).blur()
        return
      }

      this.isSubmit = true
      // 対象グループのユーザーを削除
      const result = await api.delete('/group_guest_users/' + user.id, {
        params: {
          groupId: this.currentGroupId
        }
      })
      setTimeout(() => {
        this.isSubmit = false
      }, 2000)

      if (result.error && result.error.type === 'NOT_EXISTS') {
        this.$notify({
          title: this.$gettext('対象のユーザーはすでに削除されています。'),
          customClass: 'danger',
          duration: 5000
        })
        this.fetchUsers()
        this.close()
        return
      }
      // エラーがある場合はエラーメッセージを表示
      if (!result.data) {
        this.$notify({
          title: this.$gettext('削除に失敗しました。'),
          message: this.$gettext('恐れ入りますが、時間をおいて再度お試しください。'),
          customClass: 'danger',
          duration: 5000
        })
        return
      }

      // 成功メッセージを表示
      this.$notify({
        title: this.$gettext('削除に成功しました。'),
        customClass: 'success',
        duration: 5000
      })

      // ゲストユーザー一覧を取得
      this.fetchGuestUsers(this.currentGroupId)
    }
  }
}
