import { render, staticRenderFns } from "./instantwin_message_table.vue?vue&type=template&id=c054fc6c&scoped=true"
import script from "./instantwin_message_table.component?vue&type=script&lang=js&external"
export * from "./instantwin_message_table.component?vue&type=script&lang=js&external"
import style0 from "./instantwin_message_table.scss?vue&type=style&index=0&id=c054fc6c&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c054fc6c",
  null
  
)

export default component.exports