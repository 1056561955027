import moment from 'moment'

import ChartBar from '../chart_bar'

export default {
  components: {
    ChartBar
  },
  props: {
    data: Array,
    xAxis: Array,
    interval: {
      value: String,
      default: 'hour'
    },
    isBeginAtZero: Boolean
  },
  watch: {
    data() {
      this.update()
    },
    isBeginAtZero() {
      this.update()
    }
  },
  data() {
    return {
      init: {
        scaleYAxes: {
          type: 'linear',
          display: true,
          ticks: {
            userCallback: (value: number) => value.toLocaleString(),
            beginAtZero: false
          }
        },
        dataset1: {
          label: '',
          data: null,
          fill: false,
          backgroundColor: '#f25b8d',
          borderColor: '#f25b8d',
          pointBorderColor: '#f25b8d',
          pointHoverBackgroundColor: '#f25b8d',
          pointHoverBorderColor: '#f25b8d',
          pointRadius: 1,
          pointHitRadius: 10,
          lineTension: 0,
          spanGaps: true,
          type: 'line'
        },
        dataset2: {
          label: '',
          data: null,
          fill: false,
          backgroundColor: '#26a69a',
          borderColor: '#26a69a',
          pointBorderColor: '#26a69a',
          pointHoverBackgroundColor: '#26a69a',
          pointHoverBorderColor: '#26a69a',
          pointRadius: 1,
          pointHitRadius: 10,
          lineTension: 0,
          spanGaps: true,
          type: 'line'
        }
      },
      chartData: {
        labels: null,
        datasets: []
      },
      options: {
        maintainAspectRatio: false,
        legend: {
          position: 'bottom',
          labels: {
            filter: function(label: any) {
              return label.text !== ''
            }
          }
        },
        hoverMode: 'index',
        stacked: false,
        scales: {
          yAxes: [],
          xAxes: [
            {
              offset: true,
              gridLines: {
                offsetGridLines: true
              },
              ticks: {
                userCallback: function(item: string, index: number, items: any) {
                  if (!item.includes(':')) {
                    return item
                  }

                  const inputDateFormat = 'YYYY-MM-DD HH:mm'

                  if (index === 0 || index === items.length - 1) {
                    return moment(item, inputDateFormat).format('MM/DD HH:mm')
                  }
                  const firstDay = moment(items[0], inputDateFormat).startOf('days')
                  const diff = moment(item, inputDateFormat).diff(firstDay, 'days')
                  if (((items.length >= 720 && diff % 2 === 0) || items.length < 720) && item.includes('00:00')) {
                    return moment(item, inputDateFormat).format('MM/DD')
                  }
                },
                autoSkip: false
              }
            }
          ]
        },
        tooltips: {
          callbacks: {
            title: (tooltipItem: any, data: Object) => {
              const inputDateFormat = 'YYYY-MM-DD HH:mm'
              const datasetIndex = tooltipItem[0].datasetIndex
              const chartType = data.datasets[datasetIndex].type

              if (this.interval === 'hour') {
                if (chartType === 'bar') {
                  return moment(this.xAxis[tooltipItem[0].index], inputDateFormat).format('YYYY/MM/DD HH:00 - HH:59')
                }

                return moment(this.xAxis[tooltipItem[0].index], inputDateFormat).format('YYYY/MM/DD HH:00')
              }

              if (this.interval === '1_day') {
                if (chartType === 'line') {
                  return moment(this.xAxis[tooltipItem[0].index], inputDateFormat).format('YYYY/MM/DD HH:00')
                }

                return moment(this.xAxis[tooltipItem[0].index], inputDateFormat).format('YYYY/MM/DD')
              }

              return this.xAxis[tooltipItem[0].index]
            },
            label: (tooltipItem: Object, data: Object) => {
              const label: string = data.datasets[tooltipItem.datasetIndex].label
              const value: number = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]

              return `${label}: ${value.toLocaleString()}`
            }
          }
        }
      }
    }
  },
  mounted() {
    this.update()
  },
  methods: {
    /**
     * ソートする値の変更イベント
     */
    update() {
      this.init.scaleYAxes.ticks.beginAtZero = this.isBeginAtZero

      if (this.interval === 'hour') {
        this.options.scales.xAxes[0].ticks.autoSkip = false
      } else {
        this.options.scales.xAxes[0].ticks.autoSkip = true
      }

      if (this.xAxis.length === 1) {
        this.options.scales.xAxes[0].gridLines.display = false
      } else {
        this.options.scales.xAxes[0].gridLines.display = true
      }

      // チャート用にデータ整形
      this.chartData.labels = this.xAxis

      const lineChart = ['reach', 'follower', 'newFollower']
      const constainsLineChart = this.data.map(v => v.metric).some(metric => lineChart.find(v => v === metric))

      // フラグデータをセット
      this.chartData.datasets = []
      this.options.scales.yAxes = [this.init.scaleYAxes]

      if (this.data.length === 1) {
        const dataset = this.data[0]

        this.chartData.datasets = [
          Object.assign({}, this.init.dataset1, {
            label: dataset.label,
            data: dataset.data,
            type: lineChart.indexOf(dataset.metric) !== -1 ? 'line' : 'bar'
          })
        ]
      } else if (this.data.length === 2) {
        const dataset1 = Object.assign({}, this.init.dataset1, {
          label: this.data[0].label,
          data: this.data[0].data,
          type: lineChart.indexOf(this.data[0].metric) !== -1 ? 'line' : 'bar'
        })

        const dataset2 = Object.assign({}, this.init.dataset2, {
          label: this.data[1].label,
          data: this.data[1].data,
          type: lineChart.indexOf(this.data[1].metric) !== -1 ? 'line' : 'bar'
        })

        // Lineグラフが含まれる場合は、yAxesをセット
        if (constainsLineChart) {
          dataset1.yAxisID = 'yAxis0'
          dataset2.yAxisID = 'yAxis1'

          this.options.scales.yAxes = [
            Object.assign(
              {
                id: 'yAxis0',
                position: 'left',
                scaleLabel: {
                  display: true,
                  labelString: dataset1.label
                }
              },
              this.init.scaleYAxes
            ),
            Object.assign(
              {
                id: 'yAxis1',
                position: 'right',
                scaleLabel: {
                  display: true,
                  labelString: dataset2.label
                }
              },
              this.init.scaleYAxes
            )
          ]
        }

        this.chartData.datasets = [dataset1, dataset2]
      }

      if (this.$refs.chart) {
        this.$refs.chart.update()
      }
    }
  }
}
