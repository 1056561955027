/* @flow */
import { api, getLoginStatus, login } from '@/client/utils/facebook-sdk'

class InstagramAuth {
  /**
   * Instagram認証を行う
   */
  static async startAuth() {
    let response = await getLoginStatus()

    if (response.status !== 'connected') {
      response = await login()
    }

    if (response.status !== 'connected') {
      return { error: { type: 'NOT_CONNECTED' } }
    }

    const hasPermission = await this.checkPermission()

    if (!hasPermission) {
      return { error: { type: 'PERMISSION_DENIED' } }
    }

    return response.authResponse.accessToken
  }

  /**
   * INアカウント一覧を取得
   */
  static async getAccounts(): Promise<any> {
    const result = await this.startAuth()

    if (!result || result.error) {
      return result
    }

    const response = await api(
      '/me/accounts?fields=instagram_business_account{ig_id,name,username,profile_picture_url}&limit=200'
    )

    if (response.data) {
      const accounts = response.data
        .filter(account => account.instagram_business_account)
        .map(account => account.instagram_business_account)

      return { token: result, accounts }
    }

    return null
  }

  /**
   * Facebook認証権限をチェック
   */
  static async checkPermission(): Promise<any> {
    const response = await api('/me/permissions')

    if (!response || !response.data) {
      return false
    }

    const permissions = response.data
    let isGrantedPagesManageMetadata = false
    let isGrantedInstagramBasic = false
    let isGrantedInstagramManageComments = false
    let isGrantedBusinessManagement = false

    // 認証権限をチェック
    permissions.forEach(permission => {
      if (permission.permission === 'pages_manage_metadata' && permission.status === 'granted') {
        isGrantedPagesManageMetadata = true
      }

      if (permission.permission === 'instagram_basic' && permission.status === 'granted') {
        isGrantedInstagramBasic = true
      }

      if (permission.permission === 'instagram_manage_comments' && permission.status === 'granted') {
        isGrantedInstagramManageComments = true
      }

      if (permission.permission === 'business_management' && permission.status === 'granted') {
        isGrantedBusinessManagement = true
      }
    })

    const hasPermission =
      isGrantedPagesManageMetadata &&
      isGrantedInstagramBasic &&
      isGrantedInstagramManageComments &&
      isGrantedBusinessManagement

    return hasPermission
  }
}

export default InstagramAuth
